import { IDisplayable } from "@/interfaces/Displayable";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import {
  ValidationArguments,
  ValidationOptions,
  registerDecorator,
} from "class-validator";
import { IDisplayableField } from "../DisplayableField";

type VeaValidatorContainer<
  D extends IDisplayable = IDisplayable,
  F extends IDisplayableField = IDisplayableField
> = {
  validate(field: F, object: D): boolean;
  message(field: F, object: D): string;
};

interface VeaValidationArguments<D extends object> extends ValidationArguments {
  object: D;
}

export function VeaValidate<
  D extends IDisplayable,
  F extends IDisplayableField
>(container: VeaValidatorContainer<D, F>) {
  return function (object: D, propertyName: string & KeyTo<D, F>): void {
    const options = {
      message: (args: VeaValidationArguments<D>) => {
        const field = getProp(args.object, propertyName);
        return container.message(field, args.object);
      },
    } as ValidationOptions;

    const validate = (field: F, args: VeaValidationArguments<D>) => {
      return container.validate(field, args.object);
    };

    registerDecorator({
      name: container.validate.name,
      target: object.constructor,
      propertyName,
      options,
      validator: { validate },
    });
  };
}
