import { ModelBase } from "./ModelBase";
import { CompetencyDisplayable } from "./displayable/CompetencyDisplayable";

export class Competency extends ModelBase<CompetencyDisplayable> {
  competencyId: number;
  competencyCategoryId: number;
  name: string;
  categoryName: string;

  constructor() {
    super();
    this.competencyId = 0;
    this.competencyCategoryId = 0;
    this.name = "";
    this.categoryName = "";
  }

  realize(partialModel: Competency): Competency {
    const newModel = new Competency();
    newModel.competencyId = partialModel.competencyId;
    newModel.competencyCategoryId = partialModel.competencyCategoryId;
    newModel.name = partialModel.name;
    newModel.categoryName = partialModel.categoryName;
    return newModel;
  }

  getId(): number {
    return this.competencyId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): CompetencyDisplayable {
    return new CompetencyDisplayable(this);
  }
}
