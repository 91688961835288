<template>
  <div class="container">
    <BaseTable
      :data="state.consultantData"
      :options="options"
      :grouped="true"
    />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import { VeaState } from "@/store";
import { ConsultantOptions } from "@/store/data/tableDataOptions/ConsultantOptions";
import { useStore } from "vuex";

defineOptions({
  name: "ConsultantTable",
});
const state = useStore<VeaState>().state;
const options = new ConsultantOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}
</style>
