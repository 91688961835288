import { DateKey } from "@/types/DateKey";

/**
 * Represents a generic error message.
 */
export abstract class VeaError<T> {
  protected messageBase: string;

  constructor(header: string) {
    this.messageBase = `${header}:`;
  }

  /**
   * Generates an error message for a required field that is left empty.
   */
  emptyError(): string {
    return `${this.messageBase} field may not be empty.`;
  }

  /**
   * Generates an error message for a value that does not satisfy a general constraint.
   *
   * @param input The value that was entered by the user.
   */
  valueError(input: T): string {
    return `${this.messageBase} value ${input} is not valid.`;
  }

  /**
   * Generates an error message for a value that does not satsify a specific constraint,
   * e.g. the value falls outside of an expected range.
   *
   * @param expected The constant that is being validated against.
   * @param input The value that was entered by the user.
   */
  valueMismatchError(expected: T, input: T): string {
    return `${this.messageBase} expected value ${expected} but encountered ${input}.`;
  }

  /**
   * Generates an error message for a value that does not satisfy a minimum value constraint.
   *
   * @param min The minimum value.
   * @param input The value that was entered by the user.
   */
  minValueError(min: T, input: T): string {
    return `${this.messageBase} value ${input} must be greater than or equal to ${min}.`;
  }

  /**
   * Generates an error message for a value that does not satisfy a minimum value constraint, when
   * the value has to be greater then but not equal to the minimum value.
   *
   * @param min The minimum value.
   * @param input The value that was entered by the user.
   */
  lowerExclusiveBoundError(min: T, input: T): string {
    return `${this.messageBase} value ${input} must be greater than ${min}.`;
  }

  /**
   * Generates an error message for a value that does not satisfy a maximum value constraint.
   *
   * @param min The maximum value.
   * @param input The value that was entered by the user.
   */
  maxValueError(max: T, input: T): string {
    return `${this.messageBase} value ${input} must be less than or equal to ${max}.`;
  }

  /**
   * Generates an error message for a value whose length is greater than desired.
   *
   * @param maxLength The maximum length of the input.
   */
  maxLengthError(maxLength: T): string {
    return `${this.messageBase} field has a max length of ${maxLength} characters.`;
  }

  notUniqueError(input: T): string {
    return `${this.messageBase} ${input} already exists.`;
  }

  notDifferentNameError(input: T): string {
    return `${this.messageBase} field must be different from customer name (${input}).`;
  }

  notUniqueAssignmentBudgetError(): string {
    return `An assignment budget already exists for this customer, company and year.`;
  }

  notUniqueRecruitmentError(): string {
    return `This consultant group already has a budget for that period.`;
  }

  consultantNotEmployedError(date: T, consultant: T): string {
    return `${consultant} is not employed on ${this.messageBase} ${date}.`;
  }

  consultantInvalidEndDateError(date: T, consultant: T): string {
    return `${consultant} has assignments or absences past ${date}.`;
  }

  /**
   * Generates an error message for an invalid name.
   * @param input The value that was entered by the user.
   */
  invalidNameError(input: string): string {
    const invalidLeading = /^\P{L}/u;
    const invalidTrailing = /\P{L}$/u;
    const invalidConsecutive = /(?<!\p{L})[- ]/u;
    const invalidSpecial = /[^\p{L}\- ]/u;

    if (input.match(invalidLeading) != null) {
      return `${this.messageBase} "${input}" must start with a letter."`;
    }

    if (input.match(invalidTrailing) != null) {
      return `${this.messageBase} "${input}" must end with a letter.`;
    }

    if (input.match(invalidConsecutive) != null) {
      return `${this.messageBase} "${input}" contains consecutive special characters.`;
    }

    if (input.match(invalidSpecial) != null) {
      return `${this.messageBase} "${input}" contains invalid special characters.`;
    }

    return `${this.messageBase} "${input}" is not a valid name.`;
  }

  afterTodayError(): string {
    return `${this.messageBase} may not be after today's date! Please choose a valid date.`;
  }

  before100YearsError(): string {
    return `${this.messageBase} may not be more than 100 years before today's date! Please choose a valid date.`;
  }

  beforeDateError(date: DateKey): string {
    return `${this.messageBase} can't be before ${date}.`;
  }
}
