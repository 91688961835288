import { HtmlSummaryBuilder } from "@/coveragePeriodReport/util/HtmlSummaryBuilder";
import { Assignment } from "@/models/Assignment";
import { Consultant } from "@/models/Consultant";
import { Customer } from "@/models/Customer";
import store from "@/store";

export class SummaryGenerator {
  public generateCustomerSummary(customer: Customer): string {
    const consultants: Consultant[] = store.state.consultantData.rows;
    const taskmasters = store.state.taskmasterData.rows.filter(
      (t) => t.customerId == customer.customerId
    );
    const assignments: Assignment[] = store.state.assignmentData.rows.filter(
      (assignment) => taskmasters.find(
        (taskmaster) => assignment.taskmasterId == taskmaster.taskmasterId) &&
      Date.parse(assignment.endDate) >= Date.now()
    );
    const currentAssignments: Assignment[] = assignments.filter(
      (assignment) => Date.parse(assignment.startDate) <= Date.now()
    );
    const futureAssignments: Assignment[] = assignments.filter(
      (assignment) => Date.parse(assignment.startDate) > Date.now()
    );
    const builder = new HtmlSummaryBuilder();
    if (currentAssignments.length > 0) {
      builder.addHeader("Current Assignments");
      for (let i = 0; i < currentAssignments.length; i++) {
        const consultant = consultants.find(
          (consultant) =>
            consultant.consultantId == currentAssignments[i].consultantId
        );
        builder.addField(
          consultant?.firstname + " " + consultant?.lastname,
          currentAssignments[i].name
        );
      }
    } else builder.addHeader("No Current Assignments");
    if (futureAssignments.length > 0) {
      builder.addHeader("Future Assignments");
      for (let i = 0; i < futureAssignments.length; i++) {
        const consultant = consultants.find(
          (consultant) =>
            consultant.consultantId == futureAssignments[i].consultantId
        );
        builder.addField(
          consultant?.firstname + " " + consultant?.lastname,
          futureAssignments[i].name
        );
      }
    } else builder.addHeader("No Future Assignments");
    return builder.toString();
  }
}
