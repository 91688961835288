import { CurrencyData } from "@/store/data/CurrencyData";
import { InvoiceData } from "@/store/data/InvoiceData";
import { NisAssignmentData } from "@/store/data/NisAssignmentData";
import { dateKey } from "@/types/DateKey";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Currency } from "../Currency";
import Invoice from "../Invoice";
import { NisAssignment } from "../NisAssignment";
import { CurrencyDisplayable } from "./CurrencyDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { NisAssignmentDisplayable } from "./NisAssignmentDisplayable";
import { DateField } from "./fields/DateField";
import { DropdownField } from "./fields/DropdownField";
import { NumberField } from "./fields/NumberField";
import { TextAreaField } from "./fields/TextAreaField";
import { DropdownOption } from "./fields/util/DropdownOption";

export class InvoiceDisplayable extends DisplayableBase<Invoice> {
  @ValidateNested()
  nisAssignment: DropdownField<NisAssignment, NisAssignmentDisplayable>;
  @ValidateNested()
  amount: NumberField;
  @ValidateNested()
  currency: DropdownField<Currency, CurrencyDisplayable>;
  @ValidateNested()
  date: DateField;
  @ValidateNested()
  description: TextAreaField;

  constructor(invoice: Invoice) {
    super();
    const invoiceData = container.resolve(InvoiceData);
    const nisAssignmentData = container.resolve(NisAssignmentData);
    const currencyData = container.resolve(CurrencyData);
    const { headers } = invoiceData;

    this.nisAssignment = new DropdownField(
      headers.nisAssignment,
      invoice.nisAssignmentName,
      nisAssignmentData,
      new DropdownOption(invoice.nisAssignmentId, invoice.nisAssignmentName)
    );
    const currency = currencyData.findById(invoice.currencyId);
    this.amount = new NumberField(headers.amount, invoice.amount, {
      prefix: currency.isPrefix ? `${currency.currencySymbol}` : undefined,
      suffix: currency.isPrefix ? undefined : ` ${currency.currencySymbol}`,
    });
    this.currency = new DropdownField(
      headers.currency,
      invoice.currencyCode,
      currencyData,
      new DropdownOption(invoice.currencyId, invoice.currencyCode)
    );
    this.date = new DateField(headers.date, invoice.date);
    this.description = new TextAreaField(
      headers.description,
      invoice.description,
      {
        maxLength: 700,
        rows: 2,
        placeholder: "Payment terms, additional details, etc...",
      }
    );
  }

  toModel(invoice: Invoice): void {
    invoice.nisAssignmentId = this.nisAssignment.selectedOption.id;
    invoice.amount = this.amount.modelValue();
    invoice.currencyId = this.currency.selectedOption.id;
    invoice.date = dateKey(this.date.modelValue());
    invoice.description = this.description.modelValue();
  }
}
