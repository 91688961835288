<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Reset Password</h4>
        <form>
          <div class="mb-3">
            <label class="form-label">
              Send a link for resetting my password to the following
              Email-address:
              <input
                v-model="state.form.email"
                type="email"
                class="form-control"
                id="formControlInput1"
                placeholder="name@example.com"
              />
            </label>
          </div>
          <div>
            <button
              type="submit"
              id="submit-btn"
              class="btn btn-primary"
              @click.prevent="onSubmit()"
              :disabled="state.buttonDisabled"
            >
              Submit
            </button>
          </div>
          <div class="return">
            <a href="/" class="reset-link" id="toLogin">Return to Login</a>
          </div>
        </form>
        <div v-show="state.badEmail" class="alert alert-danger" role="alert">
          The address format is invalid.
        </div>
        <div
          v-show="state.showSuccess"
          class="alert alert-success"
          role="alert"
        >
          Request sent. <br />Check your inbox/junk-folder.
        </div>
        <div v-show="state.showError" class="alert alert-danger" role="alert">
          Something went wrong... <br />You may want to try again later.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { reactive } from "vue";

const initialState = () => {
  return {
    badEmail: false,
    showSuccess: false,
    showError: false,
    form: { email: "" },
    buttonDisabled: false,
  };
};
const state = reactive(initialState());

const reset = () => {
  state.badEmail = false;
  state.showSuccess = false;
  state.showError = false;
  state.form.email = "";
  state.buttonDisabled = false;
};
const onSubmit = () => {
  // Matches any non-zero string, then @,
  // then any non-zero string, then period,
  // then any non-zero string.
  const regex = /^[^@]+@[^@]+\.[^@]+$/;
  if (regex.test(state.form.email)) {
    state.badEmail = false;
    axios
      .post("Password/ForgotPassword", { value: state.form.email })
      .then(() => {
        state.showSuccess = true;
      })
      .catch((error) => {
        console.error(error);
        state.showError = true;
      })
      .finally(() => {
        state.buttonDisabled = true;
        setTimeout(() => {
          reset();
        }, 5000);
      });
  } else {
    state.badEmail = true;
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";
.card {
  width: 19rem;
}
.alert {
  margin-top: 1rem;
  margin-bottom: 0;
}
.return {
  margin-top: 10px;
}
</style>
