import { IOpenDated } from "@/interfaces/Dated";
import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { LeaderDisplayable } from "./displayable/LeaderDisplayable";

export class Leader extends ModelBase<LeaderDisplayable> implements IOpenDated {
  leaderId: number;
  startDate: DateKey;
  endDate: DateKey | null;
  userId: number;
  locationId: number;

  firstname: string;
  lastname: string;
  locationName: string;
  corporationName: string;

  constructor() {
    super();

    this.leaderId = 0;
    this.startDate = "" as DateKey;
    this.endDate = null;
    this.userId = 0;
    this.locationId = 0;

    this.firstname = "";
    this.lastname = "";
    this.locationName = "";
    this.corporationName = "";
  }

  realize(partialModel: Leader): Leader {
    const newModel = new Leader();

    newModel.leaderId = partialModel.leaderId;
    newModel.startDate = partialModel.startDate;
    newModel.endDate = partialModel.endDate;
    newModel.userId = partialModel.userId;
    newModel.locationId = partialModel.locationId;

    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;
    newModel.locationName = partialModel.locationName;
    newModel.corporationName = partialModel.corporationName;

    return newModel;
  }

  getId(): number {
    return this.leaderId;
  }

  getName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  getDisplayable(): LeaderDisplayable {
    return new LeaderDisplayable(this);
  }

  getStartDate(): Date {
    return new Date(this.startDate);
  }

  getEndDate(): Date | null {
    return this.endDate ? new Date(this.endDate) : null;
  }
}
