import { Priority } from "@/models/Priority";
import { PriorityDisplayable } from "@/models/displayable/PriorityDisplayable";
import { container, singleton } from "tsyringe";
import { CustomerData } from "./CustomerData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { PriorityHeaders } from "./headers/PriorityHeaders";

@singleton()
export class PriorityData extends TableData<Priority, PriorityDisplayable> {
  constructor() {
    super(
      "Priorities",
      new PriorityHeaders(),
      "Priority",
      new Priority(),
      undefined,
      [
        new TablePointer(
          container.resolve(CustomerData),
          "priorityId",
          DeletePolicy.WithWarning
        ),
      ]
    );
    this._deleteWarning =
      "Warning! This priority still has customer(s) using it!" +
      "Are you sure you want to delete this priority? " +
      "This will set the priority of every customer using it to null";
  }
}
