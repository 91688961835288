<template>
  <div class="connections-container">
    <table v-if="children.length !== 0">
      <thead>
        <tr>
          <th></th>
          <th v-for="(_, column) of props.field.columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tr
        v-for="(child, i) of children"
        :key="i"
        :tooltip="props.field.tooltip(child) || undefined"
      >
        <td>
          <button
            @click="deleteChild(child.getId())"
            class="btn btn-sm btn-danger"
            style="padding: 2px; min-height: 0px; margin-right: 15px"
          >
            <fa-icon icon="remove" />
          </button>
        </td>
        <td
          v-for="(accessor, column) of props.field.columns"
          :key="column"
          @click="editChild(child.getId())"
          style="cursor: pointer; white-space: nowrap"
        >
          {{
            typeof accessor == "function" ? accessor(child) : child[accessor]
          }}
        </td>
        <td style="width: 100%" @click="editChild(child.getId())">
          <fa-icon icon="comment-dots" v-if="props.field.tooltip(child)" />
        </td>
      </tr>
    </table>
    <TableButtonAdd class="add-new-button" @click="emit('click-add')">
      Add New {{ props.field.childData.titleSingular }}
    </TableButtonAdd>
  </div>
  <BaseModalDelete
    :data="field.childData"
    v-if="
      field.childData.isDeleting() || !field.childData.hasFinishedDeleting()
    "
    @data-delete="emit('delete')"
  />
</template>

<script
  setup
  lang="ts"
  generic="M1 extends Model<D1>, D1 extends Displayable<M1>, M2 extends Model<D2>, D2 extends Displayable<M2>"
>
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { OneToManyField } from "@/models/displayable/fields/OneToManyField";
import { TableData } from "@/store/data/TableData";
import { Action } from "@/store/data/enum/Action";
import { getProp } from "@/types/getProp";
import { computed } from "vue";
import BaseModalDelete from "../BaseModalDelete.vue";
import TableButtonAdd from "../TableButtonAdd.vue";

const props = defineProps<{
  field: OneToManyField<M1, M2, D1, D2>;
  canCreateChildren?: boolean;
  parentData: TableData<M1, D1>;
  action: Action;
  onEditChild: (id: number) => void;
}>();

const emit = defineEmits(["click-add", "click-edit", "delete"]);

const children = computed(() =>
  props.field.childData
    .findMany(props.field.parentIdField, props.field.parentId)
    .sort(sortRows)
);

function sortRows(a: M2, b: M2) {
  const ap = getProp(a, props.field.sortingKey);
  const bp = getProp(b, props.field.sortingKey);
  if (typeof ap == "number") {
    return ap - (bp as number);
  } else {
    return ap.localeCompare(bp as string);
  }
}

function deleteChild(id: number) {
  props.field.childData.delete(id);
}

function editChild(id: number) {
  emit("click-edit", id);
  props.onEditChild(id);
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

tr {
  position: relative;
  width: 100%;
}
[tooltip]:has(td:hover)::after {
  position: absolute;
  content: attr(tooltip);
  border: 1px solid black;
  background-color: white;
  align-self: flex-start;
  color: black;
  padding: 0.25em;
  width: max-content;
  max-width: fit-content;
  left: 0%;
  z-index: 1;
  top: 100%;
}

table > tr:nth-child(even) {
  background-color: $table-row-alternate-color;
}

table > tr:nth-child(odd) {
  background-color: $table-row-primary-color;
}

table > tr:hover > td {
  background-color: $row-hover-bgc;
}

table tr td:nth-child(2) {
  padding-left: 5px;
}

td,
th {
  &:nth-child(n + 3) {
    padding-left: 20px;
  }
}

.connections-container {
  display: inline-block;
  border: 1px solid gray;
  text-align: left;
  padding: 5px;
  width: 100%;
}
</style>
