import {
  IClosedDated,
  IOpenDated,
  IOpenDatedDisplayable,
} from "@/interfaces/Dated";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { User } from "@/models/User";
import { ConsultantData } from "@/store/data/ConsultantData";
import { NisConsultantData } from "@/store/data/NisConsultantData";
import { TableData } from "@/store/data/TableData";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import { InjectionToken, container } from "tsyringe";
import { UserDisplayable } from "../../UserDisplayable";
import { DropdownField } from "../DropdownField";
import { NullableDateField } from "../NullableDateField";
import { VeaValidate } from "./VeaValidator";

export interface IEmployeeDisplayable extends IOpenDatedDisplayable {
  name: DropdownField<User, UserDisplayable>;
}

export function FieldValidConsultantEndDate<
  M extends Model<D> & IClosedDated,
  D extends Displayable<M>
>(
  periodInjectionToken: () => InjectionToken<TableData<M, D>>,
  periodEmployeeKey: KeyTo<M, number>
) {
  return FieldValidEmployeeEndDate(
    () => ConsultantData,
    periodInjectionToken,
    periodEmployeeKey
  );
}

export function FieldValidNisConsultantEndDate<
  M extends Model<D> & IClosedDated,
  D extends Displayable<M>
>(
  periodInjectionToken: () => InjectionToken<TableData<M, D>>,
  periodEmployeeKey: KeyTo<M, number>
) {
  return FieldValidEmployeeEndDate(
    () => NisConsultantData,
    periodInjectionToken,
    periodEmployeeKey
  );
}

export function FieldValidEmployeeEndDate<
  M extends Model<D> & IOpenDated,
  D extends Displayable<M> & IEmployeeDisplayable,
  PM extends Model<PD> & IClosedDated,
  PD extends Displayable<PM>
>(
  consultantToken: () => InjectionToken<TableData<M, D>>,
  periodToken: () => InjectionToken<TableData<PM, PD>>,
  periodConsultantKey: KeyTo<PM, number>
) {
  return VeaValidate({
    message(field: NullableDateField, object: D) {
      return field.error.consultantInvalidEndDateError(
        field.value,
        object.name.value
      );
    },
    validate(field, object) {
      if (field.modelValue() == null) {
        return true;
      }

      const employmentEndDate = object.employmentEnd.modelValue();
      if (!employmentEndDate) {
        return true;
      }

      const periodData = container.resolve(periodToken());
      const consultantData = container.resolve(consultantToken());

      const consultantId = consultantData.activeRow?.getId();
      if (!consultantId) {
        return true;
      }

      const periods = periodData.rows.filter(
        (period) => getProp(period, periodConsultantKey) == consultantId
      );
      return !periods?.some((period) => period.endDate > employmentEndDate);
    },
  });
}
