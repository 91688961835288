import * as d3 from "d3";
import { GanttGenerator } from "../GanttGenerator";
import { DiagramColors } from "../enum/DiagramColors";

export class GanttUtils {
  private generator: GanttGenerator;
  private static canvas: HTMLCanvasElement = document.createElement("canvas");

  constructor(generator: GanttGenerator) {
    this.generator = generator;
  }

  buildXAxisTop(): d3.Axis<Date | d3.NumberValue> {
    return d3
      .axisTop(this.generator.timeScale)
      .ticks(d3.timeMonth, 1)
      .tickSize(
        this.generator.ganttHeight() * -1 +
          this.generator.ganttOptions.topPadding
      )
      .tickFormat((d) => d3.timeFormat("%b")(d as Date));
  }

  buildXAxisBottom(): d3.Axis<Date | d3.NumberValue> {
    return d3
      .axisBottom(this.generator.timeScale)
      .ticks(d3.timeMonth, 1)
      .tickSize(0)
      .tickFormat((d) => d3.timeFormat("%b")(d as Date));
  }

  axisTopTranslation(): string {
    return `translate(${this.generator.ganttOptions.leftPadding}, ${this.generator.ganttOptions.topPadding})`;
  }

  axisBottomTranslation(): string {
    return `translate(${
      this.generator.ganttOptions.leftPadding
    }, ${this.generator.ganttHeight()})`;
  }

  setAxisText(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    axis: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    dy = -5
  ): void {
    axis
      .selectAll("text")
      .style("text-anchor", "start")
      .attr("fill", DiagramColors.BLACK)
      .attr("font-size", 16)
      .attr("font-weight", 600)
      .attr("dy", dy)
      .attr("dx", 6);
  }

  getTextWidth(text: string, font: string): number | undefined {
    // re-use canvas object for better performance
    const context = GanttUtils.canvas.getContext("2d");

    if (context != null) {
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }
  }

  getCssStyle(element: Element, prop: string): string {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }
}
