import { ConsultantUrl } from "@/models/ConsultantUrl";
import { ConsultantUrlDisplayable } from "@/models/displayable/ConsultantUrlDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { ConsultantUrlHeaders } from "./headers/ConsultantUrlHeaders";

@singleton()
export class ConsultantUrlData extends TableData<
  ConsultantUrl,
  ConsultantUrlDisplayable
> {
  constructor() {
    super(
      "Consultant Urls",
      new ConsultantUrlHeaders(),
      "ConsultantUrl",
      new ConsultantUrl(),
      "Consultant Url"
    );
    this._deleteError = "This Url may not be deleted!";
  }

  public getUrls(consultantId: number): ConsultantUrl[] {
    return this.findMany("consultantId", consultantId);
  }
}
