import { CompetencyCategoryData } from "@/store/data/CompetencyCategoryData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { CompetencyCategory } from "../CompetencyCategory";
import { DisplayableBase } from "./DisplayableBase";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class CompetencyCategoryDisplayable extends DisplayableBase<CompetencyCategory> {
  @ValidateNested()
  @FieldUniqueText<CompetencyCategory, CompetencyCategoryDisplayable>()
  nameField: UniqueTextField<CompetencyCategory, CompetencyCategoryDisplayable>;

  constructor(competencyCategory: CompetencyCategory) {
    super();
    const categoryData = container.resolve(CompetencyCategoryData);
    const { headers } = categoryData;
    this.nameField = new UniqueTextField(
      headers.nameField,
      competencyCategory.name,
      categoryData,
      {
        placeholder: "Enter a competency category name",
      }
    );
  }

  toModel(modelObject: CompetencyCategory): void {
    modelObject.name = this.nameField.modelValue();
  }
}
