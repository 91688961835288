<template v-if="consultantId">
  <Transition>
    <div v-if="profilePhotoDataUrl != ''" class="consultant-photo-container">
      <img
        id="consultant-photo"
        :class="{ 'consultant-photo-editable': canEditProfilePhoto }"
        :src="profilePhotoDataUrl"
      />
      <div
        class="consultant-photo-buttons-container"
        v-if="canEditProfilePhoto"
      >
        <button
          class="consultant-photo-button"
          @click="triggerUploadConsultantPhoto"
        >
          <fa-icon :icon="icons.upload" size="xl" />
          Replace
        </button>
        <button
          class="consultant-photo-button"
          @click="downloadConsultantPhoto"
        >
          <fa-icon :icon="icons.download" size="xl" />
          Download
        </button>
        <button class="consultant-photo-button" @click="deleteConsultantPhoto">
          <fa-icon :icon="icons.delete" size="xl" />
          Delete
        </button>
      </div>
    </div>
  </Transition>
  <FileForm
    v-if="canEditProfilePhoto"
    :class="{
      'file-container-hidden': shouldHidePhotoFileForm,
    }"
    inputId="upload-consultant-photo-input"
    :maxFileSizeMb="10"
    :acceptedFileTypes="['.jpeg', '.jpg', '.png']"
    :handleUpload="uploadConsultantPhoto"
  />
</template>

<script setup lang="ts">
import user from "@/auth/user";
import store from "@/store";
import FileData from "@/store/data/FileData";
import { valuesAre } from "@/types/valuesAre";
import { EventType } from "@/util/EventEmitter";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { computed, onBeforeMount, reactive } from "vue";
import FileForm from "./FileForm.vue";

const props = defineProps<{ userId?: number }>();

const profilePhotoDataUrl = defineModel<string>({
  default: "",
});

const {
  consultantData,
  consultantGroupData,
  locationData,
  leaderData,
  nisConsultantData,
  nisConsultantGroupData,
  events,
} = store.state;

const state = reactive({
  isLoadingProfilePhoto: false,
});

onBeforeMount(() =>
  events.subscribeOrRunIfDispatched(
    EventType.FinishedLoadingTableData,
    getUserPhoto
  )
);
defineExpose({ getUserPhoto, state });

const ownCorporationId = computed<number | null>(() =>
  corpIdForUser(user.state.profile.userId)
);

const corpIdForUser = (userId: number) => {
  let locationId = leaderData.find("userId", userId)?.locationId;

  if (!locationId) {
    const consultantGroupId = consultantData.find(
      "userId",
      userId
    )?.consultantGroupId;
    if (consultantGroupId) {
      locationId = consultantGroupData.findById(consultantGroupId).locationId;
    }
  }

  if (!locationId) {
    const nisConsultantGroupId = nisConsultantData.find(
      "userId",
      userId
    )?.nisConsultantGroupId;
    if (nisConsultantGroupId) {
      const leaderId =
        nisConsultantGroupData.findById(nisConsultantGroupId).leaderId;
      locationId = leaderData.findById(leaderId).locationId;
    }
  }

  if (!locationId) return null;

  return locationData.findById(locationId).corporationId;
};

const icons = valuesAre<IconName>()({
  upload: "upload",
  download: "file-download",
  delete: "trash-can",
});

const shouldHidePhotoFileForm = computed(
  () =>
    state.isLoadingProfilePhoto ||
    profilePhotoDataUrl.value != "" ||
    !canViewProfilePhoto.value
);

const canViewProfilePhoto = computed<boolean>(() => {
  if (!props.userId) return false;

  if (!ownCorporationId.value) return false;

  const profileCorporationId = corpIdForUser(props.userId);
  return ownCorporationId.value == profileCorporationId;
});

const canEditProfilePhoto = computed<boolean>(
  () => canViewProfilePhoto.value && user.getters.role == "Admin"
);

const photoFileData = new FileData(undefined, {
  validFileTypes: ["image/jpeg", "image/jpg", "image/png"],
  prependFolderNameToSubFolders: false,
});

function triggerUploadConsultantPhoto() {
  document.getElementById("upload-consultant-photo-input")?.click();
}

async function uploadConsultantPhoto(files: FileList) {
  if (!props.userId) return;

  state.isLoadingProfilePhoto = true;

  const file = files?.item(0);
  if (!file) return;

  await photoFileData
    .uploadFile(file, "user_photo", props.userId)
    .then(getUserPhoto)
    .catch(() => (state.isLoadingProfilePhoto = false));
}

async function getUserPhoto() {
  state.isLoadingProfilePhoto = true;
  if (!canViewProfilePhoto.value) {
    profilePhotoDataUrl.value = "";
    state.isLoadingProfilePhoto = false;
    return;
  }

  state.isLoadingProfilePhoto = true;

  if (!props.userId) {
    profilePhotoDataUrl.value = "";
    state.isLoadingProfilePhoto = false;
    return;
  }

  const blob = await photoFileData.getFile(
    "compressed.webp",
    "user_photo",
    props.userId,
    "image/webp"
  );

  if (!blob) {
    profilePhotoDataUrl.value = "";
    state.isLoadingProfilePhoto = false;
    return;
  }

  const reader = new FileReader();
  reader.onloadend = setConsultantPhoto;
  reader.readAsDataURL(blob as Blob);
}

function setConsultantPhoto(this: FileReader) {
  profilePhotoDataUrl.value = this.result as string;
  state.isLoadingProfilePhoto = false;
}

async function deleteConsultantPhoto() {
  if (!props.userId) return;
  await photoFileData.deleteSubFolder("user_photo", props.userId);
  profilePhotoDataUrl.value = "";
}

async function downloadConsultantPhoto() {
  if (!props.userId) return;
  const files = await photoFileData.getList("user_photo", props.userId);
  const file = files?.find((file) => file.fileName != "compressed.webp");
  if (!file) return;
  await photoFileData.downloadFile(file);
}
</script>

<style lang="scss">
@import "@/styles/global.scss";

.file-container-hidden {
  visibility: collapse;
  height: 0px;
}

.v-enter-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from {
  opacity: 0;
}

.consultant-photo-container {
  position: relative;
  display: inline-block;

  margin-top: 10px;
  margin-bottom: 10px;

  &:hover:has(.consultant-photo-editable) {
    min-width: 144px;
    min-height: 200px;
    border-radius: 10px;
    background-color: darkgray;

    .consultant-photo-editable {
      filter: brightness(70%);
    }
  }

  &:not(:hover) .consultant-photo-buttons-container {
    display: none;
  }

  #consultant-photo {
    border-radius: 10px;
    border: 1px solid darkgray;
  }

  .consultant-photo-buttons-container {
    top: 5%;
    bottom: 5%;
    position: absolute;

    .consultant-photo-button {
      background: transparent;
      border: none;
      color: white;
      padding: 10px;
      background-color: $vea-primary-color;
      border-radius: 10px;
      left: 5%;
      width: 80%;
      text-align: left;
      margin-bottom: 20px;

      &:hover {
        background-color: #91bdfe;
      }

      &:last-child {
        background-color: red;

        &:hover {
          background-color: #f0938e;
        }
      }
    }
  }
}
</style>
