import { User } from "@/models/User";
import { SettingsManager } from "@/store/SettingsManager";
import axios, { AxiosResponse } from "axios";
import { container } from "tsyringe";
import { encrypt } from "./cryptographer";
import userStore from "./user";

export type LoginDetails = { email: string; password: string };
export type LoginResponse = {
  isAuthenticated: boolean;
  jwtToken: string;
  failureMessage: string;
  profile: User;
  refreshToken: string;
};

const loginRoutine = (details: LoginDetails) =>
  new Promise<AxiosResponse<LoginResponse>>((resolve, reject) => {
    axios
      .post("Login", details)
      .then(async (resp: AxiosResponse<LoginResponse>) => {
        const token = resp.data.jwtToken;
        if (token) {
          localStorage.setItem("jwt", token); // use localstorage to persist login
          localStorage.setItem("user", details.email);
          localStorage.setItem("refreshToken", resp.data.refreshToken);
          localStorage.setItem(
            "profile",
            encrypt(JSON.stringify(resp.data.profile))
          );

          axios.defaults.headers.Authorization = `Bearer ${token}`; // set the token as default Authorization header to send with each request
          userStore.state.username = details.email;
          userStore.state.profile = new User().realize(resp.data.profile);
          const settingsManager = container.resolve(SettingsManager);
          settingsManager.loadFromLocalStorage();
        }
        resolve(resp);
      })
      .catch((err) => {
        // currently this will never occur, response status code always 200 OK
        localStorage.removeItem("jwt"); // if the request fails, remove any possible user token if possible
        reject(err);
      });
  });
export default loginRoutine;
