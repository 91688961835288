import { IDisplayable } from "@/interfaces/Displayable";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import { DateField } from "../DateField";
import { NullableDateField } from "../NullableDateField";
import { VeaValidate } from "./VeaValidator";

export function FieldMinDateOrNull<D extends IDisplayable>(
  relatedFieldKey: KeyTo<D, DateField>
) {
  return VeaValidate({
    validate(field: NullableDateField, object: D) {
      const relatedField = getProp(object, relatedFieldKey);
      return field.value == null || field.value > relatedField.value;
    },
    message(field, object) {
      return field.error.minValueError(
        getProp(object, relatedFieldKey).value,
        field.value
      );
    },
  });
}
