import { library } from "@fortawesome/fontawesome-svg-core";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import "reflect-metadata";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/global.scss";
import {
  AppInsightsPlugin,
  AppInsightsPluginOptions,
} from "./util/ApplicationInsights";

// icons in library may be used with the 'fa-icon' component
// list of all available icons: https://fontawesome.com/v4/icons/
library.add(fa.fas);

// axios config to use with api calls,
// allows us to just add the endpoint in axios requests, e.g. axios.get('Customer')
axios.defaults.baseURL = `https://${process.env.VUE_APP_API_URL}/`;

//get connection string and instance name from enviroment variable
const connectionString = process.env.APPLICATIONINSIGHTS_CONNECTION_STRING;
const cloudRoleInstance = process.env.CLOUD_ROLE_INSTANCE;

//options for app insights
const aiOptions: AppInsightsPluginOptions = {
  connectionString,
  router,
  trackAppErrors: true,
  cloudRoleInstance,
};

//use application insights if connectionstring is not null
const app = createApp(App)
  .component("fa-icon", FontAwesomeIcon)
  .use(store)
  .use(router);
if (connectionString) app.use(AppInsightsPlugin, aiOptions);
app.mount("#app");
