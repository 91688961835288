<template>
  <input
    :id="
      thisField.header.replace(' ', '-').toLocaleLowerCase() +
      '-' +
      thisField.dateType +
      '-field'
    "
    :type="thisField.dateType"
    class="date-table-form"
    :class="hasError && 'error'"
    max="2999-12-31"
    v-model="thisField.value"
    v-on:change="signalInputUpdate()"
    :placeholder="thisField.dateType == 'month' ? 'YYYY-MM' : ''"
  /><!-- the placeholder is temporary until all browsers support the field -->
</template>

<script setup lang="ts">
import { DateField } from "@/models/displayable/fields/DateField";
import { NullableDateField } from "@/models/displayable/fields/NullableDateField";
import { computed } from "vue";

const props = defineProps<{
  field: DateField | NullableDateField;
  hasError?: boolean;
}>();
const emit = defineEmits(["input-updated"]);
const signalInputUpdate = () => emit("input-updated");
const thisField = computed(() => props.field);
</script>
<style scoped lang="scss">
@import "@/styles/global.scss";
.error {
  @include error;
}
</style>
