import { VeaError } from "@/models/displayable/fields/error/VeaError";

export class DateError extends VeaError<string> {
  valueError(input: string): string {
    return `${this.messageBase} value ${input} is not a valid date.`;
  }

  minValueError(min: string, input: string): string {
    if (min === "") {
      min = "startdate";
    }
    return `${this.messageBase} the end date (${input}) must be after the start date (${min}).`;
  }
}
