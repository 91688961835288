export class ModalToggle {
  private _enabled = false;
  public get enabled() {
    return this._enabled;
  }

  public hide() {
    this._enabled = false;
  }

  public show() {
    this._enabled = true;
  }
}
