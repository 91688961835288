import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { TableData } from "@/store/data/TableData";
import { DisplayableFieldOptions } from "./DisplayableField";
import { TextField } from "./TextField";

export class UniqueTextField<
  M extends Model<D>,
  D extends Displayable<M>
> extends TextField {
  protected _tableData: TableData<M, D>;

  get tableData(): TableData<M, D> {
    return this._tableData;
  }

  constructor(
    header: string,
    data: string,
    tableData: TableData<M, D>,
    options?: DisplayableFieldOptions
  ) {
    super(header, data, options);
    this._tableData = tableData;
  }
}
