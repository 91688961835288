import { AbsenceCategory } from "@/models/AbsenceCategory";
import { AbsenceCategoryDisplayable } from "@/models/displayable/AbsenceCategoryDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class AbsenceCategoryOptions extends TableDataOptions<
  AbsenceCategory,
  AbsenceCategoryDisplayable
> {
  public constructor() {
    super({
      filterBoxKeys: ["name"],
    });
  }
}
