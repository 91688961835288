import userStore, { RoleName } from "@/auth/user";
import Changelog from "@/views/Changelog.vue";
import ErrorNotAuthorized from "@/views/ErrorNotAuthorized.vue";
import ErrorNotFound from "@/views/ErrorNotFound.vue";
import HourlyRateReport from "@/views/HourlyRates.vue";
import InvoiceForecast from "@/views/InvoiceForecast.vue";
import LogoutRedirect from "@/views/LogoutRedirect.vue";
import NumberOfAssignments from "@/views/NumberOfAssignments.vue";
import NumberOfCertificates from "@/views/NumberOfCertificates.vue";
import NumberOfEmployees from "@/views/NumberOfEmployees.vue";
import PasswordChangeForm from "@/views/PasswordChangeForm.vue";
import Profile from "@/views/Profile.vue";
import Recruitments from "@/views/Recruitments.vue";
import Services from "@/views/Services.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Absences from "../views/Absences.vue";
import AssignmentOverview from "../views/AssignmentOverview.vue";
import Assignments from "../views/Assignments.vue";
import Budgets from "../views/Budgets.vue";
import Certificates from "../views/Certificates.vue";
import Competencies from "../views/Competencies.vue";
import Consultants from "../views/Consultants.vue";
import Companies from "../views/Corporations.vue";
import CustomerOverview from "../views/CustomerOverview.vue";
import Customers from "../views/Customers.vue";
import FormLogin from "../views/FormLogin.vue";
import FormPasswordReset from "../views/FormPasswordReset.vue";
import Home from "../views/Home.vue";
import Leaders from "../views/Leaders.vue";
import MonthlyHours from "../views/MonthlyHours.vue";
import MonthlyReports from "../views/MonthlyReports.vue";
import NIS from "../views/NIS.vue";
import Users from "../views/Users.vue";

const routes: Array<RouteRecordRaw & { meta?: { allowedRoles?: RoleName[] } }> =
  [
    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/",
      name: "Login",
      component: FormLogin,
      meta: {},
    },
    {
      path: "/logout",
      name: "Logout",
      component: LogoutRedirect,
      meta: {},
    },
    {
      path: "/requestReset",
      name: "Password Reset",
      component: FormPasswordReset,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/changePassword",
      name: "Password Change",
      component: PasswordChangeForm,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/certificates",
      name: "Certificates",
      component: Certificates,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/consultants",
      name: "Consultants",
      component: Consultants,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/leaders",
      name: "Leaders",
      component: Leaders,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/assignments",
      name: "Assignments",
      component: Assignments,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/services",
      name: "Services",
      component: Services,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/companies",
      name: "Companies",
      component: Companies,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/customers",
      name: "Customers",
      component: Customers,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/absences",
      name: "Absences",
      component: Absences,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/users",
      name: "Users",
      component: Users,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/competencies",
      name: "Competencies",
      component: Competencies,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/budgets",
      name: "Budgets",
      component: Budgets,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/monthlyHours",
      name: "Monthly Hours",
      component: MonthlyHours,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/monthlyReports",
      name: "Monthly Reports",
      component: MonthlyReports,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/assignmentOverview",
      name: "Assignment Overview",
      component: AssignmentOverview,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/customerOverview",
      name: "Customer Overview",
      component: CustomerOverview,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/nis",
      name: "Neuroinclusion Services",
      component: NIS,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/hourlyRateReport",
      name: "Hourly Rate Report",
      component: HourlyRateReport,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/numberOfEmployees",
      name: "Number of Employees",
      component: NumberOfEmployees,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/numberOfAssignments",
      name: "Number of Assignments",
      component: NumberOfAssignments,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/numberOfCertificates",
      name: "Number of Certificates",
      component: NumberOfCertificates,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: `/profile`,
      name: "Profile",
      component: Profile,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/about",
      name: "About",
      component: Changelog,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: `/profile/:id`,
      name: "Consultant Profile",
      component: Profile,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/invoiceForecast",
      name: "Invoice Forecast",
      component: InvoiceForecast,
      meta: { allowedRoles: ["User", "Admin", "Admin Read-Only"] },
    },
    {
      path: "/recruitments",
      name: "Recruitments",
      component: Recruitments,
      meta: { allowedRoles: ["Admin", "Admin Read-Only"] },
    },
    {
      path: "/error/notAuthorized",
      name: "Not authorized",
      component: ErrorNotAuthorized,
      meta: {},
    },
    {
      path: "/error/notFound",
      name: "Not found",
      component: ErrorNotFound,
      meta: {},
    },
    {
      path: "/:pathMatch(.*)*",
      name: "Not found",
      component: ErrorNotFound,
      meta: {},
    },
  ];

export function canRouteTo(link: string): boolean {
  const route = routes.find((route) => route.path == link);
  if (!route) {
    return false;
  }
  return (
    !route.meta?.allowedRoles ||
    route.meta.allowedRoles.includes(userStore.getters.role)
  );
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, _from, next) => {
  // if using old domain, redirect to new domain
  const UrlDomain = window.location.hostname;
  if (UrlDomain.includes("vea-vue-prod")) {
    window.location.href = "https://unicus-vea.com/";
    return;
  }
  // create webpage title
  let nextPageFullTitle = "";
  const nextPageName = to.name?.toString();
  if (nextPageName) nextPageFullTitle += nextPageName + " – ";
  nextPageFullTitle += "Unicus VEA";
  document.title = nextPageFullTitle;
  // check user permission
  const allowedRoles = to.meta?.allowedRoles as RoleName[];
  if (allowedRoles && !allowedRoles.includes(userStore.getters.role)) {
    router.push("/error/notAuthorized");
  }
  // finish up
  next();
});

export default router;
