import { Competency } from "@/models/Competency";
import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Corporation } from "@/models/Corporation";
import { Role } from "@/models/Role";
import { User } from "@/models/User";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { RoleDisplayable } from "@/models/displayable/RoleDisplayable";
import { UserDisplayable } from "@/models/displayable/UserDisplayable";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { EditCompetenciesExtension } from "../tableDataOptionsExtensions/EditCompetenciesExtension";
import { ActiveFilter } from "../tableDataOptionsFilters/ActiveFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class UserOptions extends TableDataOptions<User, UserDisplayable> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;
  private consultantGroupDropdown: TableDataDropdown<
    ConsultantGroup,
    ConsultantGroupDisplayable
  >;
  private roleDropdown: TableDataDropdown<Role, RoleDisplayable>;

  public constructor() {
    const { consultantData, corporationData, consultantGroupData, roleData } =
      store.state;

    super({
      hiddenFields: ["firstName", "lastName"],
      radioFilters: [new ActiveFilter()],
      sorting: {
        "First name": {
          func: TableDataOptions.compareFirstNames,
          column: "fullName",
        },
        "Last name": TableDataOptions.compareLastNames,
      },
      editCompetenciesExtension: new EditCompetenciesExtension(
        (user) => user.userId,
        (user) => consultantData.some("userId", user.userId)
      ),
      filterBoxKeys: [
        "fullName",
        "email",
        "role",
        "competencies",
        "keyCompetencies",
        "position",
        "services",
      ],
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);
    this.consultantGroupDropdown = new TableDataDropdown(
      consultantGroupData,
      undefined,
      this.consultantGroupFilter.bind(this)
    );
    this.roleDropdown = new TableDataDropdown(roleData);

    this.dropdownFilters = [
      this.corporationDropdown,
      this.consultantGroupDropdown,
      this.roleDropdown,
    ];
  }

  private consultantGroupFilter(option: DropdownOption<string>) {
    const corporationFilter = this.corporationDropdown.selectedOption;
    if (corporationFilter == -1) {
      return true;
    }
    return (
      corporationFilter ==
      store.state.consultantGroupData.findById(option.id).corporationId
    );
  }

  override filterRows(
    rows: User[],
    radioFilters: BaseTableFilterMode[],
    _yearFilter: number,
    selectedCompetencies?: Competency[]
  ): User[] {
    const { consultantData, consultantGroupData, leaderData, locationData } =
      store.state;
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const consultantGroupFilter: number =
      this.consultantGroupDropdown.selectedOption;
    const roleFilter: number = this.roleDropdown.selectedOption;
    const [activeFilter] = radioFilters;
    const filters = new FilterBuilder<User>();

    switch (activeFilter) {
      case BaseTableFilterMode.ACTIVE:
        filters.add((user) => user.active);
        break;
      case BaseTableFilterMode.INACTIVE:
        filters.add((user) => !user.active);
        break;
    }

    if (roleFilter > -1) {
      filters.add((user) => user.roleId == roleFilter);
    }

    if (consultantGroupFilter != -1) {
      const consultantUserIds = consultantData
        .findMany("consultantGroupId", consultantGroupFilter)
        .map((c) => c.userId);
      const userIds = new Set(consultantUserIds);
      const { firstLeaderId, secondLeaderId } = consultantGroupData.findById(
        consultantGroupFilter
      );
      userIds.add(leaderData.findById(firstLeaderId).userId);
      if (secondLeaderId) {
        userIds.add(leaderData.findById(secondLeaderId).userId);
      }
      filters.add((user) => userIds.has(user.userId));
    } else if (corporationFilter != -1) {
      const groups = consultantGroupData.findMany(
        "corporationId",
        corporationFilter
      );
      const groupIds = new Set(groups.map((g) => g.consultantGroupId));
      const consultantUserIds = consultantData
        .findWithValuesInSet("consultantGroupId", groupIds)
        .map((c) => c.userId);
      const locationIds = locationData.findIds(
        "corporationId",
        corporationFilter
      );
      const leaderUserIds = leaderData
        .findWithValuesInSet("locationId", locationIds)
        .map((l) => l.userId);
      const userIds = new Set([...consultantUserIds, ...leaderUserIds]);
      filters.add((user) => userIds.has(user.userId));
    }

    if (selectedCompetencies && selectedCompetencies.length > 0) {
      filters.add(this.getSelectedCompetenciesFilter(selectedCompetencies));
    }

    return filters.filter(rows);
  }
}
