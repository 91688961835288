import { Displayable } from "@/interfaces/Displayable";
import { ITableData } from "@/interfaces/ITableData";
import { Model } from "@/interfaces/Model";
import { TableData } from "@/store/data/TableData";
import { Action } from "@/store/data/enum/Action";
import {
  FormEditingContext,
  IFormEditingContext,
} from "@/types/FormEditingContext";
import { IFormEditingContextParentKey } from "@/types/FormEditingContextParentKey";
import { KeyTo } from "@/types/KeyTo";
import { castKey, setProp } from "@/types/getProp";
import { DisplayableField } from "./DisplayableField";
import { DropdownField } from "./DropdownField";
import { HiddenError } from "./TableField";
import { FieldType } from "./enum/FieldType";

export interface IOneToManyField {
  createFormEditingContext(
    parent: IFormEditingContextParentKey,
    action: Action.Add | Action.Edit
  ): IFormEditingContext;
  readonly parentDropdownField: string | number | symbol;
  readonly childData: ITableData;
}

export class OneToManyField<
    M1 extends Model<D1>,
    M2 extends Model<D2>,
    D1 extends Displayable<M1>,
    D2 extends Displayable<M2>
  >
  extends DisplayableField<null, null>
  implements IOneToManyField
{
  protected _value = null;
  public value = null;
  modelValue(): null {
    return null;
  }

  constructor(
    header: string,
    public parentId: number,
    public parentName: string,
    public readonly parentIdField: KeyTo<M2, number>,
    public readonly parentNameField: KeyTo<M2, string>,
    public readonly parentDropdownField: KeyTo<D2, DropdownField<M1, D1>>,
    public readonly childData: TableData<M2, D2>,
    public readonly modelConstructor: new () => M2,
    public readonly displayableConstructor: new (model: M2) => D2,
    public readonly label: (model: M2) => string,
    public readonly tooltip: (model: M2) => string,
    public readonly sortingKey: KeyTo<M2, string | number>,
    public readonly columns: {
      [column: string]:
        | KeyTo<M2, string>
        | KeyTo<M2, number>
        | ((model: M2) => string | number);
    }
  ) {
    super(header, new HiddenError(), FieldType.ONE_TO_MANY, {
      hideInTable: true,
    });
  }

  createFormEditingContext(
    parent: IFormEditingContextParentKey,
    action: Action.Add | Action.Edit
  ): IFormEditingContext {
    const extraValues: Partial<M2> = {};
    if (action == Action.Add) {
      setProp(extraValues, this.parentIdField, this.parentId);
      setProp(extraValues, this.parentNameField, this.parentName);
    }
    return new FormEditingContext(
      this.childData,
      action,
      extraValues,
      parent,
      castKey(this.parentDropdownField)
    );
  }
}
