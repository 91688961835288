import { ModelBase } from "./ModelBase";
import { CompetencyCategoryDisplayable } from "./displayable/CompetencyCategoryDisplayable";

export class CompetencyCategory extends ModelBase<CompetencyCategoryDisplayable> {
  competencyCategoryId: number;
  name: string;

  constructor() {
    super();
    this.competencyCategoryId = 0;
    this.name = "";
  }

  realize(partialModel: CompetencyCategory): CompetencyCategory {
    const newModel = new CompetencyCategory();
    newModel.competencyCategoryId = partialModel.competencyCategoryId;
    newModel.name = partialModel.name;
    return newModel;
  }

  getId(): number {
    return this.competencyCategoryId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): CompetencyCategoryDisplayable {
    return new CompetencyCategoryDisplayable(this);
  }
}
