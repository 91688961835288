import IFormState from "../../../interfaces/IFormState";

export class FormBaseData {
  formState: IFormState;

  constructor() {
    this.formState = {
      fields: {},
      error: {
        show: false,
        text: [],
      },
      success: {
        show: false,
        text: "",
      },
    };
  }

  setStateFields(state: { [key: string]: string }): void {
    this.formState.fields = state;
  }

  setAndShowError(msg = ""): void {
    this.formState.error.show = true;

    if (msg != "") {
      if (!this.formState.error.text.includes(msg)) {
        this.formState.error.text.push(msg);
      }
    }
  }

  setAndShowSuccess(msg: string): void {
    this.formState.success.show = true;

    this.formState.success.text = msg;
  }

  resetMessages(): void {
    this.formState.success.show = false;
    this.formState.error.show = false;
    setTimeout(() => {
      this.formState.error.text = [];
    }, 1000);
  }

  addPropertyToState(propertyObj: Record<string, unknown>): void {
    this.formState = Object.assign(this.formState, propertyObj);
  }

  getFields(): { [key: string]: string } {
    return this.formState.fields;
  }

  resetForm(): void {
    Object.keys(this.formState.fields).forEach((index) => {
      this.formState.fields[index] = "";
    });
  }

  setProperty(propertyName: string, value: unknown): void {
    this.formState[propertyName] = value;
  }
}
