import { singleton } from "tsyringe";
import { FormBaseData } from "./FormBaseData";

@singleton()
export class LoginFormData extends FormBaseData {
  constructor() {
    super();
    this.setStateFields({
      email: "",
      password: "",
    });
  }
}
