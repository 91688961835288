<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <div class="toc active" @touchstart="onTouchStart" @touchmove="onTouchMove">
    <div
      class="arrow-div"
      @click="availableButton() ? toggleToc() : null"
    ></div>
    <h6>Quick Nav</h6>
    <template :ref="tables" v-for="table of tables" :key="table">
      <a
        id="btn"
        class="btn"
        :href="'#' + table"
        :class="{ current: isViewed(table) }"
      >
        {{ table.replaceAll("_", " ") }}
      </a>
    </template>
  </div>
</template>

<script setup lang="ts">
import { SettingsManager } from "@/store/SettingsManager";
import { container } from "tsyringe";
import { Ref, onMounted, onUnmounted, reactive, ref } from "vue";
export interface Props {
  query?: string;
}
type ElementInfo = {
  id: string;
  offset: number;
};
const props = withDefaults(defineProps<Props>(), {
  query: ".basetable",
});
const tables: Ref<string[]> = ref([]);
const state = reactive({
  screenPos: 0,
  defaultOpen: true,
});
const settings = container.resolve(SettingsManager);
const isTouchDevice = "ontouchstart" in window;

onMounted(() => {
  const toc = document.querySelector(".toc") as Element;
  const arrow = document.querySelector(".arrow-div") as Element;
  tables.value = [];
  document
    .querySelectorAll(props.query)
    .forEach((element) => tables.value.push(element.id));
  if (isTouchDevice) {
    toc.classList.add("touch");
    toc.classList.remove("active");
    arrow.classList.remove("clicked");
    arrow.classList.remove("clickable");
  } else {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouse);
  }
});

// We need to remove the eventlisteners when the component is unmounted otherwise it'll mess up the defaultOpen when navigating to another component that mounts this.
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("mousemove", handleMouse);
});

const availableButton = () => {
  if (!settings.navHover) {
    return true;
  } else {
    return state.defaultOpen;
  }
};

const isViewed = (table: string) => {
  const elements: ElementInfo[] = Array.from(
    document.querySelectorAll(props.query)
  ).map((element) => ({
    id: element.id,
    offset: element.offsetTop + element.scrollHeight,
  }));
  return table == findClosestTable(elements);
};

function findClosestTable(tables: ElementInfo[]) {
  let closestTable = null;
  let closestOffsetDiff = Infinity;

  for (let i = 0; i < tables.length; i++) {
    const elementInfo = tables[i];

    if (elementInfo.offset > state.screenPos) {
      const offsetDiff = Math.abs(elementInfo.offset - state.screenPos);

      if (offsetDiff < closestOffsetDiff) {
        closestOffsetDiff = offsetDiff;
        closestTable = elementInfo.id;
      }
    }
  }

  return closestTable;
}

const handleScroll = () => {
  state.screenPos = window.scrollY + window.screen.availHeight / 2;
};

const toggleToc = () => {
  const toc = document.querySelector(".toc") as Element;
  const arrow = document.querySelector(".arrow-div") as Element;
  const openToc = () => {
    toc.classList.add("active");
    arrow.classList.add("clicked");
  };
  const closeToc = () => {
    toc.classList.remove("active");
    arrow.classList.remove("clicked");
    arrow.classList.remove("clickable");
  };

  toc.classList.contains("active") ? closeToc() : openToc();
};

const handleMouse = (event: MouseEvent) => {
  const toc = document.querySelector(".toc") as Element;
  if (toc) {
    const arrow = document.querySelector(".arrow-div") as Element;
    const tocRect = toc.getBoundingClientRect();
    const arrowThreshold = 250;
    const tocThreshold = 100;
    const mouse = {
      yPos: event.clientY,
      xPos: event.clientX,
    };
    const distanceToTocX = () => {
      if (mouse.xPos < tocRect.left) {
        return Math.abs(mouse.xPos - tocRect.left);
      } else if (mouse.xPos > tocRect.left && mouse.xPos < tocRect.right) {
        return 0;
      } else if (mouse.xPos > tocRect.right) {
        return Math.abs(mouse.xPos - tocRect.right);
      } else {
        return 0;
      }
    };
    const offsetRect = (n: number) => {
      return n + tocRect.height / 2;
    };

    const distanceToTocY = () => {
      if (mouse.yPos < offsetRect(tocRect.top)) {
        return Math.abs(mouse.yPos - offsetRect(tocRect.top));
      } else if (
        mouse.yPos > offsetRect(tocRect.top) &&
        mouse.yPos < offsetRect(tocRect.bottom)
      ) {
        return 0;
      } else if (mouse.yPos > offsetRect(tocRect.bottom)) {
        return Math.abs(mouse.yPos - offsetRect(tocRect.bottom));
      } else {
        return 0;
      }
    };

    const isArrowActive =
      distanceToTocX() < arrowThreshold && distanceToTocY() < arrowThreshold;
    const isTocActive =
      distanceToTocX() < tocThreshold && distanceToTocY() < tocThreshold;

    if (isArrowActive) {
      arrow.classList.add("active");
      if (!settings.navHover) {
        arrow.classList.add("clickable");
      }
    } else {
      arrow.classList.remove("active");
    }

    if (settings.navHover && !state.defaultOpen) {
      if (isTocActive) {
        toc.classList.add("active");
      } else {
        toc.classList.remove("active");
      }
    } else if (state.defaultOpen) {
      setTimeout(() => {
        toc.classList.remove("active");
        state.defaultOpen = false;
      }, 1500);
    }
  }
};
let touchStartX = 0;
let touchEndX = 0;

const onTouchStart = (e: TouchEvent) => {
  touchStartX = e.touches[0].clientX;
};

const onTouchMove = (e: TouchEvent) => {
  touchEndX = e.touches[0].clientX;
  handleSwipe();
};

const handleSwipe = () => {
  const toc = document.querySelector(".toc") as Element;
  const swipeThreshold = 50;
  const swipeDistance = touchEndX - touchStartX;

  if (swipeDistance < swipeThreshold) {
    toc.classList.add("touch");
  } else if (swipeDistance > -swipeThreshold) {
    toc.classList.remove("touch");
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.toc {
  background: $vea-primary-color;
  border-radius: 10px;
  overflow: auto;
  text-align: center;
  padding: 5px;
  right: 0;
  top: 50%;
  transform: translateX(calc(100% - 10px));
  transition: transform 0.2s;
  overflow: visible;
  pointer-events: all;
  &.touch {
    transform: translateX(0);
  }

  h6 {
    color: $color-white;
    font-weight: bold;
  }
  .arrow-div {
    position: absolute;
    width: 15px;
    height: 15px;
    border-bottom: 2px solid $vea-primary-color;
    border-left: 2px solid $vea-primary-color;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    top: 50%;
    left: -30px;
    transform: translateY(-50%) rotate(225deg);
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s;
    &.touch {
      transform: translateY(-50%) rotate(45deg);
      opacity: 1;
    }
  }

  a {
    margin: 5px;
    align-self: self-end;
    padding: 0.25rem 0.5rem;
    display: block;
    color: $color-black;
    background-color: $color-white;
  }
  .current {
    background-color: $container-bgc;
  }
  a:hover {
    background-color: $color-grey-transparent;
  }
}
@media (hover) {
  .toc.active {
    transform: translateX(0);
    .arrow-div.clicked {
      transform: translateY(-50%) rotate(225deg);
    }
  }
  .arrow-div.active {
    transform: translateY(-50%) rotate(45deg);
    opacity: 1;
    &.clickable {
      cursor: pointer;
    }
  }
}
</style>
