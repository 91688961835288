<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="settingsOffcanvas"
    aria-labelledby="settingsOffcanvasLabel"
    aria-hidden="true"
  >
    <div class="offcanvas-header">
      <h1 class="offcanvas-title" id="settingsOffcanvasLabel">Settings</h1>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="submitStatus.isUpdated ? loadUpdate() : undefined"
      ></button>
    </div>
    <div class="offcanvas-body">
      <section class="filter-settings">
        <h2 class="offcanvas-title">Preset filters</h2>
        <p class="descriptive">
          Set filters to all views and tables where applicable to focus on a
          specific company or consultant group.
        </p>
        <div class="dropdown mt-3">
          <select
            id="corporation-selector"
            class="me-2"
            v-model="settings.corporationId"
            @change="validateConsultantGroup"
          >
            <option :key="-1" :value="-1">Any company</option>
            <option
              v-for="c in corporations"
              :key="c.corporationId"
              :value="c.corporationId"
            >
              {{ c.name }}
            </option>
          </select>
          <select
            id="consultant-group-selector"
            v-model="settings.consultantGroupId"
          >
            <option :key="-1" :value="-1">Any consultant group</option>
            <option
              v-for="cg in consultantGroups"
              :key="cg.consultantGroupId"
              :value="cg.consultantGroupId"
            >
              {{ cg.name }}
            </option>
          </select>
        </div>
      </section>
      <section class="navHover">
        <h2 class="offcanvas-title">Accessibility</h2>
        <p class="descriptive">Make VEA fit your preference</p>
        <div>
          <input
            class="checkbox"
            type="checkbox"
            id="navHover-checkbox"
            v-model="settings.navHover"
          />
          &nbsp;<label for="navHover-checkbox"
            >Enable hover-to-open menus</label
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import { EventType } from "@/util/EventEmitter";
import { computed, onBeforeMount, reactive } from "vue";

const { corporationData, consultantGroupData, settings } = store.state;

onBeforeMount(() => {
  store.state.events.subscribeOrRunIfDispatched(
    EventType.FinishedLoadingTableData,
    async () => await settings.setDefaultsFromUserConnections()
  );
});

const submitStatus = reactive({
  isUpdated: false,
});

function loadUpdate() {
  submitStatus.isUpdated = false;
  router.go(0);
}

function filterByCorporation() {
  const { corporationId } = settings;
  if (!corporationId || corporationId == -1) {
    return consultantGroupData.rows;
  }
  return consultantGroupData.findMany("corporationId", corporationId);
}

function validateConsultantGroup() {
  const { consultantGroupId, corporationId } = settings;

  if (consultantGroupId == -1 || corporationId == -1) {
    return;
  }

  const consultantGroup = consultantGroupData.find(
    "consultantGroupId",
    consultantGroupId
  );

  if (consultantGroup && consultantGroup.corporationId != corporationId) {
    settings.consultantGroupId = -1;
  }
}

// Note: A boolean toggle for a dark mode can be implemented using the same function + checkbox solution (using e.g. a class called "Colors" - ref. SettingData). Options for more than two color schemes can use strings + a dropdown list.

const corporations = computed(() => corporationData.rows);
const consultantGroups = computed(filterByCorporation);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.btn-close {
  background-color: $color-white-transparent;
}

.offcanvas {
  background-color: $base-color;
  color: $base-text-color;
  text-align: left;
  bottom: unset;
  top: unset;
  height: 100vh;
  right: 17px;
  @media only screen and (max-width: 417px) {
    right: 0;
  }

  .offcanvas-body {
    padding-top: 0;
  }

  section {
    margin-bottom: 2rem;
  }

  .dropdown {
    text-align: center;
    @include form-field;
  }
  .submit-container {
    @include submit-container;
    .submit-btn {
      @include submit-btn;
    }
  }
}
</style>
