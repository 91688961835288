import { Assignment } from "@/models/Assignment";
import { Corporation } from "@/models/Corporation";
import { Customer } from "@/models/Customer";
import { Leader } from "@/models/Leader";
import { Location } from "@/models/Location";
import { Priority } from "@/models/Priority";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { CustomerDisplayable } from "@/models/displayable/CustomerDisplayable";
import { LeaderDisplayable } from "@/models/displayable/LeaderDisplayable";
import { LocationDisplayable } from "@/models/displayable/LocationDisplayable";
import { PriorityDisplayable } from "@/models/displayable/PriorityDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { AssignmentFilter } from "../tableDataOptionsFilters/AssignmentFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { Filter, FilterBuilder } from "../types/FilterBuilder";

export class CustomerOptions extends TableDataOptions<
  Customer,
  CustomerDisplayable
> {
  private priorityDropdown: TableDataDropdown<Priority, PriorityDisplayable>;
  private leaderDropdown: TableDataDropdown<Leader, LeaderDisplayable>;
  private locationDropdown: TableDataDropdown<Location, LocationDisplayable>;
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  public constructor() {
    const { priorityData, leaderData, locationData, corporationData } =
      store.state;

    super({
      radioFilters: [new AssignmentFilter()],
      sorting: {
        Priority: { key: "priorityOrder", column: "priorityName" },
        Name: { key: "name", column: "name" },
        Responsible: { func: compareLeaderNames, column: "leaderName" },
      },
      filterBoxKeys: [
        "name",
        "leaderName",
        "priorityName",
        "locationName",
        "industryName",
        "corporationCustomerName",
      ],
    });

    this.priorityDropdown = new TableDataDropdown(priorityData);
    this.corporationDropdown = new TableDataDropdown(corporationData);
    this.locationDropdown = new TableDataDropdown(
      locationData,
      undefined,
      this.getDropdownFilterFromRelatedDropdown(
        locationData,
        this.corporationDropdown,
        "corporationId"
      )
    );
    this.leaderDropdown = new TableDataDropdown(
      leaderData,
      undefined,
      this.getDropdownFilterFromRelatedDropdowns(
        leaderData,
        this.locationDropdown,
        "locationId",
        locationData,
        this.corporationDropdown,
        "corporationId"
      )
    );

    this.dropdownFilters = [
      this.priorityDropdown,
      this.corporationDropdown,
      this.locationDropdown,
      this.leaderDropdown,
    ];
  }

  override filterRows(
    rows: Customer[],
    radioFilters: BaseTableFilterMode[]
  ): Customer[] {
    const priorityFilter = this.priorityDropdown.selectedOption;
    const leaderFilter = this.leaderDropdown.selectedOption;
    const locationFilter = this.locationDropdown.selectedOption;
    const corporationFilter = this.corporationDropdown.selectedOption;

    const { assignmentData, locationData, taskmasterData } = store.state;

    const now = Date.now();
    const filters = new FilterBuilder<Customer>();

    let assignmentFilter: undefined | Filter<Assignment>;

    if (radioFilters[0] == BaseTableFilterMode.CURRENT_ASSIGNMENTS) {
      assignmentFilter = TableDataOptions.isPeriodCurrent(now);
    } else if (radioFilters[0] == BaseTableFilterMode.PREVIOUS_ASSIGNMENTS) {
      assignmentFilter = TableDataOptions.isPeriodNotCurrent(now);
    }

    if (assignmentFilter) {
      const filteredAssignments = assignmentData.rows.filter(assignmentFilter);
      const taskmasterIds = new Set(
        filteredAssignments.map((a) => a.taskmasterId)
      );
      const taskmasters = taskmasterData.findWithValuesInSet(
        "taskmasterId",
        taskmasterIds
      );
      const customerIds = new Set(taskmasters.map((t) => t.customerId));
      filters.add((customer) => customerIds.has(customer.customerId));
    }

    if (priorityFilter != -1) {
      filters.add((customer) => customer.priorityId == priorityFilter);
    }

    if (leaderFilter != -1) {
      filters.add((customer) => customer.leaderId == leaderFilter);
    } else if (locationFilter != -1) {
      filters.add((customer) => customer.locationId == locationFilter);
    } else if (corporationFilter != -1) {
      const customers = store.state.corporationCustomerData.findMany(
        "corporationId",
        corporationFilter
      );
      const customerIds = new Set(customers.map((c) => c.customerId));

      const locationIds = locationData.findIds(
        "corporationId",
        corporationFilter
      );

      filters.add(
        (c) => customerIds.has(c.customerId) || locationIds.has(c.locationId)
      );
    }

    return filters.filter(rows);
  }
}

function compareLeaderNames(c1: Customer, c2: Customer) {
  return TableDataOptions.compareStrings(
    c1.getLeaderName(),
    c2.getLeaderName()
  );
}
