import { NonEmptyArray } from "@/util/NonEmptyArray";

// The contract phases should have numerical values corresponding to the database
export enum ContractPhase {
  PROBABLE_ASSIGNMENT = 0,
  ASSIGNMENT = 1,
  INTERNSHIP = 2,
  NON_BILLABLE = 3,
}

// Type representing the strings
export type ContractPhaseNames =
  | "Probable assignment"
  | "Assignment"
  | "Internship"
  | "Non billable/Internal work";

// Returns an array of the contract phase and their corresponding names
export function contractPhaseAndNames(): NonEmptyArray<
  [ContractPhaseNames, ContractPhase]
> {
  return [
    ["Probable assignment", ContractPhase.PROBABLE_ASSIGNMENT],
    ["Assignment", ContractPhase.ASSIGNMENT],
    ["Internship", ContractPhase.INTERNSHIP],
    ["Non billable/Internal work", ContractPhase.NON_BILLABLE],
  ];
}

// Converts a contract phase into the corresponding phase name
export function phaseName(phase: ContractPhase): ContractPhaseNames {
  switch (phase) {
    case ContractPhase.PROBABLE_ASSIGNMENT:
      return "Probable assignment";
    case ContractPhase.ASSIGNMENT:
      return "Assignment";
    case ContractPhase.INTERNSHIP:
      return "Internship";
    case ContractPhase.NON_BILLABLE:
      return "Non billable/Internal work";
  }
}
