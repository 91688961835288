import { CorporationData } from "@/store/data/CorporationData";
import { LeaderData } from "@/store/data/LeaderData";
import { LocationData } from "@/store/data/LocationData";
import { NisConsultantGroupData } from "@/store/data/NisConsultantGroupData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Corporation } from "../Corporation";
import { Leader } from "../Leader";
import { NisConsultantGroup } from "../NisConsultantGroup";
import { CorporationDisplayable } from "./CorporationDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { LeaderDisplayable } from "./LeaderDisplayable";
import { DropdownField } from "./fields/DropdownField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class NisConsultantGroupDisplayable extends DisplayableBase<NisConsultantGroup> {
  @ValidateNested()
  @FieldUniqueText<NisConsultantGroup, NisConsultantGroupDisplayable>()
  name: UniqueTextField<NisConsultantGroup, NisConsultantGroupDisplayable>;
  @ValidateNested()
  corporation: DropdownField<Corporation, CorporationDisplayable>;
  @ValidateNested()
  leader: DropdownField<Leader, LeaderDisplayable>;

  constructor(group: NisConsultantGroup) {
    super();
    const nisConsultantGroupData = container.resolve(NisConsultantGroupData);
    const corporationData = container.resolve(CorporationData);
    const locationData = container.resolve(LocationData);
    const leaderData = container.resolve(LeaderData);
    const { headers } = nisConsultantGroupData;

    this.name = new UniqueTextField<
      NisConsultantGroup,
      NisConsultantGroupDisplayable
    >(headers.name, group.name, nisConsultantGroupData);
    this.corporation = new DropdownField(
      headers.corporation,
      group.corporationName,
      corporationData,
      new DropdownOption(group.corporationId, group.corporationName)
    );
    const leaderFilter = (option: DropdownOption<string>) => {
      const corporationId = this.corporation.selectedOption.id;
      if (corporationId > 0) {
        const locationIds = locationData.findIds(
          "corporationId",
          corporationId
        );
        return leaderData
          .findWithValuesInSet("locationId", locationIds)
          .some((l) => l.leaderId == option.id);
      }
      return true;
    };
    this.leader = new DropdownField(
      headers.leader,
      group.leaderName,
      leaderData,
      new DropdownOption(group.leaderId, group.leaderName),
      {
        optionsFilter: leaderFilter,
      }
    );
  }

  toModel(nisConsultantGroup: NisConsultantGroup): void {
    nisConsultantGroup.name = this.name.modelValue();
    nisConsultantGroup.corporationId = this.corporation.selectedOption.id;
    nisConsultantGroup.leaderId = this.leader.selectedOption.id;
  }
}
