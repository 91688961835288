import { Recruitment } from "@/models/Recruitment";
import { RecruitmentDisplayable } from "@/models/displayable/RecruitmentDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { RecruitmentHeaders } from "./headers/RecruitmentHeaders";

@singleton()
export class RecruitmentData extends TableData<
  Recruitment,
  RecruitmentDisplayable
> {
  constructor() {
    super(
      "Recruitments",
      new RecruitmentHeaders(),
      "Recruitment",
      new Recruitment()
    );
  }
}
