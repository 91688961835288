import { ConsultantCompetency } from "@/models/ConsultantCompetency";
import { ConsultantCompetencyDisplayable } from "@/models/displayable/ConsultantCompetencyDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { ConsultantCompetencyHeaders } from "./headers/ConsultantCompetencyHeaders";

@singleton()
export class ConsultantCompetencyData extends TableData<
  ConsultantCompetency,
  ConsultantCompetencyDisplayable
> {
  constructor() {
    super(
      "Consultant Competencies",
      new ConsultantCompetencyHeaders(),
      "ConsultantCompetency",
      new ConsultantCompetency(),
      "Consultant Competency"
    );
  }
}
