import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { TableData } from "@/store/data/TableData";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import { InjectionToken, container } from "tsyringe";
import { DropdownField } from "../DropdownField";
import { VeaValidate } from "./VeaValidator";

export function FieldUniqueManyToManyConnection<
  M1 extends Model<D1>,
  M2 extends Model<D2>,
  M3 extends Model<D3>,
  D1 extends Displayable<M1>,
  D2 extends Displayable<M2>,
  D3 extends Displayable<M3>
>(
  parentDropdownFieldKey: KeyTo<D2, DropdownField<M1, D1>>,
  parentIdKey: KeyTo<M2, number>,
  childIdKey: KeyTo<M2, number>,
  injectionToken: () => InjectionToken<TableData<M2, D2>>
) {
  return VeaValidate({
    message(field: DropdownField<M3, D3>, object: D2) {
      return field.error.notUniqueError(
        `${getProp(object, parentDropdownFieldKey).value} ${field.value}`
      );
    },
    validate(field, object) {
      const parentDropdownField = getProp(object, parentDropdownFieldKey);
      const connectionData = container.resolve(injectionToken());
      const childId = field.selectedOption.id;
      const parentId: number | undefined =
        parentDropdownField.selectedOption.id;

      if (childId == undefined || parentId == undefined) {
        return false;
      }

      const { activeRow } = connectionData;
      const isAdding = activeRow != undefined && activeRow.getId() !== 0;

      if (
        isAdding &&
        (getProp(activeRow, parentIdKey) !== parentId ||
          getProp(activeRow, childIdKey) !== childId)
      ) {
        return false;
      }

      return (
        isAdding ||
        !connectionData.some([childIdKey, childId], [parentIdKey, parentId])
      );
    },
  });
}
