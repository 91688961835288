import { CertificateData } from "@/store/data/CertificateData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Certificate } from "../Certificate";
import { DisplayableBase } from "./DisplayableBase";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class CertificateDisplayable extends DisplayableBase<Certificate> {
  @ValidateNested()
  @FieldUniqueText<Certificate, CertificateDisplayable>()
  nameField: UniqueTextField<Certificate, CertificateDisplayable>;

  constructor(certificate: Certificate) {
    super();
    const certificateData = container.resolve(CertificateData);
    const { headers } = certificateData;
    this.nameField = new UniqueTextField(
      headers.nameField,
      certificate.name,
      certificateData,
      {
        placeholder: "Enter a certificate name",
      }
    );
  }

  toModel(certificate: Certificate): void {
    certificate.name = this.nameField.modelValue();
  }
}
