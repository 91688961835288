<template>
  <div
    class="modal staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    @keyup.esc="abort()"
  >
    <div @click.stop class="modal-dialog modal-dialog-centered" :class="size">
      <div class="modal-content" :class="{ error: error }">
        <div class="modal-header">
          <fa-icon
            id="icon-basemodal"
            :class="{ 'strong danger': danger }"
            :icon="modalIcon"
          />
          <h3
            cy-data="tbl-modal-title"
            class="modal-title"
            :class="{ 'strong danger': danger }"
            id="staticBackdropLabel"
          >
            {{ title }}
          </h3>
          <button
            type="button"
            class="btn-close"
            @click="abort()"
            aria-label="Close"
            cy-data="btn-close"
          ></button>
        </div>
        <div class="modal-body" :scrollTop.prop="error ? 1000000 : 0">
          <slot></slot>
        </div>
        <div
          class="modal-footer"
          :style="displayBackButton && 'justify-content: space-between'"
        >
          <button
            type="button"
            class="btn btn-secondary"
            @click="emit('back')"
            v-if="displayBackButton"
          >
            <fa-icon :icon="['fas', 'arrow-left']" />
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="abort()"
            v-if="aborttitle"
            cy-data="btn-abort"
          >
            {{ aborttitle }}
          </button>
          <button
            type="button"
            :class="button"
            :disabled="error || disableButton"
            @click="accept()"
            v-if="accepttitle"
            :cy-data="`btn-submit-${title}`"
          >
            {{ accepttitle }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { onBeforeRouteLeave } from "vue-router";
onBeforeRouteLeave(() => {
  props.abort();
});

const props = defineProps<{
  title: string;
  aborttitle?: string;
  abort: (delay?: number) => void;
  danger: boolean;
  accepttitle?: string;
  accept: (delay?: number) => void;
  error?: boolean;
  disableButton?: boolean;
  modalIcon: IconName;
  size?: "" | "modal-sm" | "modal-lg" | "modal-xl";
  displayBackButton?: boolean;
}>();

const emit = defineEmits(["back"]);

let button = "btn";
if (props.danger) {
  button = button + " btn-danger";
}
button = button + " btn-primary";
</script>

<style lang="scss">
@import "@/styles/global.scss";
.staticBackdrop {
  background-color: $color-grey-transparent;
}

.modal {
  display: block;
  color: $base-text-color;

  // Desktop
  @media screen and (min-width: 460px) {
    .modal-dialog {
      @include modal-dialog();
      min-width: 450px;
    }
  }
  .modal-dialog,
  .modal-dialog-centered {
    animation: reveal 300ms ease 0s 1;
  }
}

.error {
  @include error();
}

.fade-out > .modal-dialog {
  transform: translateY(50px);
  opacity: 0;
  transition: all 200ms ease;
}

.modal-header {
  .danger {
    font-weight: bold;
    font-size: 1.2rem;
    color: $color-mintgreen;
  }

  *:not(.danger) {
    margin-top: 0;
    color: #42b983;
  }
}

#icon-basemodal {
  width: 15px;
  height: 15px;
  margin-right: 10px;

  * {
    color: $color-mintgreen;
  }
}

@media screen and (min-height: 540px) {
  .modal-body {
    @include modal-body();
  }
}

@media screen and (max-height: 540px) {
  .modal-body {
    @include modal-body();
  }
}

.strong {
  @include strong();
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
