import { ReportEntry } from "../ReportEntry";

export class Period {
  readonly startDate: Date;
  readonly endDate: Date | null;

  constructor(startDate: Date, endDate: Date | null) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  static getPeriods = (entries: ReportEntry[]): Period[] =>
    entries.map(Period.from);

  static from = (entry: ReportEntry): Period =>
    new Period(entry.startDate, entry.endDate);

  //TODO: improve sort by taking period length & startDate into account
  static sortPeriodsAsc(periods: Period[]): Period[] {
    return periods.sort(Period.endDateCompare);
  }

  private static endDateCompare = (a: Period, b: Period) => {
    if (!a.endDate) return 1;
    if (!b.endDate) return -1;
    if (a.endDate > b.endDate) return 1;
    if (a.endDate < b.endDate) return -1;
    else return 0;
  };

  static isOpenEnded = (p: Period) => p.endDate === null;

  static findFirstDate(periods: Period[]): Date {
    const firstPeriod = periods.reduce((p, c) =>
      p.startDate < c.startDate ? p : c
    );
    return firstPeriod.startDate;
  }

  static findLastDate(periods: Period[]): Date | null {
    if (periods.some(Period.isOpenEnded)) return null;

    const lastPeriod = periods.reduce((previous, current) =>
      // eslint-disable-next-line
      previous.endDate! < current.endDate! ? current : previous
    );
    return lastPeriod.endDate;
  }
}
