export type Filter<T> = (item: T) => boolean | undefined;

/**
 * @example
 * ```ts
 * const filters = new FilterBuilder<number>();
 * filters.add((n) => n < 5);
 * filters.add((n) => n != 3);
 *
 * const numbers = filters.filter([1, 2, 3, 4, 5, 6]);
 * console.log(numbers); // [1, 2, 4]
 * ```
 *
 */
export class FilterBuilder<T> {
  /**
   *
   * @param filters
   */
  constructor(private readonly filters: Filter<T>[] = []) {}

  public isEmpty() {
    return this.filters.length == 0;
  }

  /**
   *
   * @param filter
   * @returns
   */
  public add(filter: Filter<T>): FilterBuilder<T> {
    this.filters.push(filter);
    return this;
  }

  /**
   *
   * @param values
   * @returns
   */
  public filter(values: T[]): T[] {
    if (this.filters.length == 0) {
      return values.slice(0);
    }
    return values.filter((value) =>
      this.filters.every((filter) => filter(value))
    );
  }
}
