import { NonEmptyArray } from "@/util/NonEmptyArray";
import { ConditionalOption } from "../enum/ConditionalOption";
import { VeaError } from "./VeaError";

export class SelectionError extends VeaError<ConditionalOption> {
  private _options: string;

  constructor(header: string, options: NonEmptyArray<string>) {
    super(header);

    // If there are at least 2 elements, format them as:
    //     "e0", "e1", ..., "eN-2" or "eN-1"
    // where e0, e1, ... are elements 0, 1, ...
    // For 2 elements it becomes: "e0" or "e1"
    // For 3 elements: "e0", "e1" or "e2"
    // and so on
    if (options.length >= 2) {
      const last = options[options.length - 1];
      const optionsExceptLast = options.slice(0, options.length - 1);
      this._options = `"${optionsExceptLast.join('", "')}" or "${last}"`;
    } else {
      // If there is only one selection option then show it and it alone
      this._options = `"${options[0]}"`;
    }
  }

  valueError(input: string): string {
    return `${this.messageBase} "${input}" must be ${this._options}.`;
  }
}
