import { Competency } from "@/models/Competency";
import { CompetencyDisplayable } from "@/models/displayable/CompetencyDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class CompetencyOptions extends TableDataOptions<
  Competency,
  CompetencyDisplayable
> {
  public constructor() {
    super({
      sorting: {
        Name: { key: "name", column: "nameField" },
        Category: { key: "categoryName", column: "categoryField" },
      },
      filterBoxKeys: ["nameField", "categoryField"],
    });
  }
}
