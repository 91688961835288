import { DateKey } from "@/types/DateKey";
import { IsISO8601, IsOptional, ValidationArguments } from "class-validator";
import { DisplayableFieldOptions } from "./DisplayableField";
import { NullableStringField } from "./NullableStringField";
import { DateType } from "./enum/DateType";
import { FieldType } from "./enum/FieldType";
import { DateError } from "./error/DateError";

export class NullableDateField extends NullableStringField {
  @IsOptional()
  @IsISO8601(
    { strict: true },
    {
      message: (args: ValidationArguments) =>
        (args.object as NullableDateField)._error.valueError(args.value),
    }
  )
  protected _value: DateKey | null;
  protected _dateType: DateType;

  get dateType(): string {
    return this._dateType;
  }

  modelValue(): DateKey | null {
    return this._value;
  }

  constructor(
    header: string,
    data: DateKey | null,
    dateType: DateType = "date", // defaults to selecting days
    options?: DisplayableFieldOptions
  ) {
    super(header, new DateError(header), FieldType.DATE, options);
    this._value = data;
    this._dateType = dateType;
  }
}
