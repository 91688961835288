import { KeyTo } from "./KeyTo";
import { getProp } from "./getProp";

export function avg<T>(items: T[], fieldKey: KeyTo<T, number>) {
  return items.length > 0 ? sum(items, fieldKey) / items.length : 0;
}

export function sum<T>(items: T[] | Iterable<T>, fieldKey: KeyTo<T, number>) {
  let sum = 0;
  for (const item of items) {
    sum += getProp(item, fieldKey);
  }
  return sum;
}

export function sumByFunc<T>(
  items: T[] | Iterable<T>,
  fieldKeyOrFunc: (i: T) => number
) {
  let sum = 0;
  for (const item of items) {
    sum += fieldKeyOrFunc(item);
  }
  return sum;
}
