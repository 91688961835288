import { TableHeaders } from "@/interfaces/TableHeaders";
import { UserDisplayable } from "@/models/displayable/UserDisplayable";

export class UserHeaders implements TableHeaders<UserDisplayable> {
  public readonly firstName = "First Name";
  public readonly lastName = "Last Name";
  public readonly fullName = "Full Name";
  public readonly position = "Position";
  public readonly role = "Role";
  public readonly address = "Address";
  public readonly phoneNr = "Phone";
  public readonly email = "Email";
  public readonly active = "Active";
  public readonly coverage = "Employment rate";
  public readonly keyCompetencies = "Key Competencies";
  public readonly competencies = "Competencies";
  public readonly services = "Services";
}
