<template>
  <!--Your Assignments-->
  <div class="table-container your-assignments" v-if="props.activeTab == 1">
    <h3 v-if="assignments.length == 0">
      {{ noAssignmentsLabel() }}
    </h3>
    <template v-else>
      <div class="table">
        <tr>
          <td class="assignment">Customer</td>
          <td>
            <div class="first line"></div>
          </td>
          <td>Service</td>
          <td>
            <div class="first line"></div>
          </td>
          <td>Start</td>
          <td>
            <div class="first line"></div>
          </td>
          <td>End</td>
        </tr>
        <tr v-for="assignment in assignments" :key="assignment.assignmentId">
          <td>
            {{ assignment.customerName }}
            <span class="current vea-blue italic" v-if="isCurrent(assignment)"
              >(Current)</span
            >
            <span class="future vea-blue italic" v-if="isFuture(assignment)"
              >(Future)</span
            >
          </td>
          <td>
            <div class="line"></div>
          </td>
          <td>{{ assignment.serviceName }}</td>
          <td>
            <div class="line"></div>
          </td>
          <td>{{ assignment.startDate }}</td>
          <td>
            <div class="line"></div>
          </td>
          <td>
            {{ assignment.endDate }}
          </td>
        </tr>
      </div>
      <div class="description">
        <label>{{ assignmentLabel() }}</label>
      </div>
    </template>
  </div>

  <!--Shared Customers-->
  <div class="table-container shared-customers" v-if="props.activeTab == 2">
    <h3
      v-if="!sharedCustomerInfo || sharedCustomerInfo.sharedCustomers.size == 0"
    >
      {{ noSharedCustomerLabel() }}
    </h3>
    <template v-else>
      <div class="table">
        <tr>
          <td>Customer</td>
          <td>
            <div class="first line"></div>
          </td>
          <td>Shared with</td>
          <td>
            <div class="first line"></div>
          </td>
          <td>Shared services with</td>
        </tr>
        <tr>
          <td>{{ sharedCustomerInfo.customerName }}</td>
          <td>
            <div class="line"></div>
          </td>
          <td
            :class="{
              clickable: sharedCustomerInfo.sharedCustomers.size !== 0,
            }"
            @mouseenter="hoveredColumn = Column.Shared"
            @mouseleave="hoveredColumn = Column.None"
          >
            {{ sharedCustomerInfo.sharedCustomers.size }} consultants
            <div
              class="consultant-tag"
              v-if="
                hoveredColumn == Column.Shared &&
                sharedCustomerInfo.sharedCustomers.size !== 0
              "
            >
              <ul>
                <li
                  v-for="[id, consultant] in sharedCustomerInfo.sharedCustomers"
                  :key="id"
                >
                  <a :href="`/profile/${consultant.userId}`">{{
                    consultant.getName()
                  }}</a>
                </li>
              </ul>
            </div>
          </td>
          <td>
            <div class="line"></div>
          </td>
          <td
            :class="{ clickable: sharedCustomerInfo.sharedServices.size !== 0 }"
            @mouseenter="hoveredColumn = Column.Service"
            @mouseleave="hoveredColumn = Column.None"
          >
            {{ sharedCustomerInfo.sharedServices.size }} of them
            <div
              class="consultant-tag"
              v-if="
                hoveredColumn == Column.Service &&
                sharedCustomerInfo.sharedServices.size !== 0
              "
              :id="`${sharedCustomerInfo.customerName}${Column.Service}`"
            >
              <ul>
                <li
                  v-for="[id, consultant] in sharedCustomerInfo.sharedServices"
                  :key="id"
                >
                  <a :href="`/profile/${consultant.userId}`">{{
                    consultant.getName()
                  }}</a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </div>
      <div class="description">
        <label>{{ customerLabel() }}</label>
      </div>
    </template>
  </div>

  <!--Shared Competencies-->
  <div class="table-container shared-competencies" v-if="props.activeTab == 3">
    <h3 v-if="sharedCompetencyInfo.size == 0">
      {{ noCompetenciesLabel() }}
    </h3>
    <template v-else>
      <div class="table">
        <tr>
          <td>Your Competencies</td>
          <td>
            <div class="first line"></div>
          </td>
          <td>Shared with</td>
          <td>
            <div class="first line"></div>
          </td>
          <td>Key competency shared with</td>
        </tr>
        <tr
          v-for="[competencyId, competencyInfo] in sharedCompetencyInfo"
          :key="competencyId"
        >
          <td>
            {{ competencyInfo.competencyName }}
          </td>
          <td>
            <div class="line"></div>
          </td>
          <td
            :class="{ clickable: competencyInfo.sharedCompetencies.size !== 0 }"
            @mouseenter="showCell(competencyId, Column.Shared)"
            @mouseleave="hoveredColumn = Column.None"
          >
            <span
              class="competency-span"
              v-if="competencyInfo.sharedCompetencies.size !== 0"
            >
              {{ competencyInfo.sharedCompetencies.size }} consultants
              <div
                class="consultant-tag"
                v-if="shouldShow(competencyId, Column.Shared)"
                :id="`${competencyInfo.competencyName}${Column.Shared}`"
              >
                <ul>
                  <li
                    v-for="[
                      consultantId,
                      consultant,
                    ] of competencyInfo.sharedCompetencies"
                    :key="consultantId"
                  >
                    <a :href="`/profile/${consultant.userId}`">{{
                      consultant.getName()
                    }}</a>
                  </li>
                </ul>
              </div>
            </span>
            <span class="not-shared" v-else
              >No other consultant have this as a competency</span
            >
          </td>
          <td>
            <div class="line"></div>
          </td>
          <td
            :class="{
              clickable: competencyInfo.sharedKeyCompetencies.size !== 0,
            }"
            @mouseenter="showCell(competencyId, Column.Key)"
            @mouseleave="hoveredColumn = Column.None"
          >
            <span
              class="competency-span"
              v-if="competencyInfo.sharedKeyCompetencies.size !== 0"
              >{{ competencyInfo.sharedKeyCompetencies.size }} of them
              <div
                class="consultant-tag"
                v-if="shouldShow(competencyId, Column.Key)"
                :id="`${competencyInfo.competencyName}${Column.Shared}`"
              >
                <ul>
                  <li
                    v-for="[
                      consultantId,
                      consultant,
                    ] of competencyInfo.sharedKeyCompetencies"
                    :key="consultantId"
                  >
                    <a :href="`/profile/${consultant.userId}`">{{
                      consultant.getName()
                    }}</a>
                  </li>
                </ul>
              </div>
            </span>
            <span class="not-shared" v-else
              >No other consultant have this as a key competency</span
            >
          </td>
        </tr>
      </div>
      <div class="description">
        <label>{{ competenciesLabel() }}</label>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { Assignment } from "@/models/Assignment";
import { Consultant } from "@/models/Consultant";
import { ContractPhase } from "@/models/enum/ContractPhase";
import store from "@/store";
import { computed, ref } from "vue";

const props = defineProps<{
  consultantId: number;
  isMyOwn: boolean;
  activeTab: number;
}>();

const { assignmentData, consultantData, consultantCompetencyData } =
  store.state;

export type SharedCustomerInfo = {
  customerName: string;
  serviceName: string;
  sharedCustomers: Map<number, Consultant>;
  sharedServices: Map<number, Consultant>;
};

type SharedCompetencyInfo = {
  competencyName: string;
  sharedCompetencies: Map<number, Consultant>;
  sharedKeyCompetencies: Map<number, Consultant>;
};

enum Column {
  None = "none",
  Shared = "shared",
  Service = "service",
  Key = "key",
}

const hoveredColumn = ref(Column.None);
const hoveredCompetencyId = ref<number | undefined>(undefined);

const assignments = computed(() =>
  assignmentData
    .findMany("consultantId", props.consultantId)
    .sort((a, b) => a.startDate.localeCompare(b.startDate))
);

const futureAssignments = computed(() => assignments.value.filter(isFuture));

const sharedCustomerInfo = computed(() => {
  if (!props.consultantId) {
    return undefined;
  }

  const today = new Date();
  const myAssignment = assignmentData.rows.find(
    (a) =>
      a.consultantId == props.consultantId &&
      a.phase != ContractPhase.INTERNSHIP &&
      a.getStartDate() <= today &&
      a.getEndDate() >= today
  );

  if (!myAssignment) {
    return undefined;
  }

  const { customerName, serviceName, customerId, serviceId } = myAssignment;

  const withMatchingCustomers = assignmentData
    .findMany("customerId", customerId)
    .filter(
      (a) =>
        a.consultantId != props.consultantId &&
        a.getStartDate() <= today &&
        a.getEndDate() >= today
    );

  const withMatchingServices = withMatchingCustomers.filter(
    (a) => a.serviceId == serviceId
  );

  const customerInfo: SharedCustomerInfo = {
    customerName,
    serviceName,
    sharedCustomers: new Map(),
    sharedServices: new Map(),
  };

  function addTo(map: Map<number, Consultant>, assignments: Assignment[]) {
    const consultantIds = new Set(assignments.map((a) => a.consultantId));
    const consultants = consultantData.findWithValuesInSet(
      "consultantId",
      consultantIds
    );
    for (const consultant of consultants) {
      map.set(consultant.consultantId, consultant);
    }
  }

  addTo(customerInfo.sharedCustomers, withMatchingCustomers);
  addTo(customerInfo.sharedServices, withMatchingServices);

  return customerInfo;
});

const sharedCompetencyInfo = computed<Map<number, SharedCompetencyInfo>>(() => {
  const consultantCompetencies = consultantCompetencyData
    .findMany("consultantId", props.consultantId)
    .sort((a, b) => a.competencyName.localeCompare(b.competencyName));

  const infoByCompetencyId = new Map<number, SharedCompetencyInfo>();

  for (const consultantCompetency of consultantCompetencies) {
    const { competencyId, competencyName } = consultantCompetency;
    const other = consultantCompetencyData
      .findMany("competencyId", competencyId)
      .filter((c) => c.consultantId != props.consultantId)
      .sort((a, b) =>
        a.getConsultantName().localeCompare(b.getConsultantName())
      );
    const info: SharedCompetencyInfo = {
      competencyName,
      sharedCompetencies: new Map(),
      sharedKeyCompetencies: new Map(),
    };

    for (const o of other) {
      const consultant = consultantData.findById(o.consultantId);
      info.sharedCompetencies.set(o.consultantId, consultant);
      if (o.isKey) {
        info.sharedKeyCompetencies.set(o.consultantId, consultant);
      }
    }

    infoByCompetencyId.set(competencyId, info);
  }

  return infoByCompetencyId;
});

function showCell(competencyId: number, column: Column) {
  hoveredCompetencyId.value = competencyId;
  hoveredColumn.value = column;
}

function shouldShow(competencyId: number, column: Column) {
  return (
    hoveredCompetencyId.value == competencyId && hoveredColumn.value == column
  );
}

function isCurrent(assignment: Assignment) {
  const today = new Date();
  return assignment.getStartDate() <= today && assignment.getEndDate() >= today;
}

function isFuture(assignment: Assignment) {
  const today = new Date();
  return assignment.getStartDate() >= today;
}

function noAssignmentsLabel() {
  const haventText = props.isMyOwn ? "You haven't" : "This consultant hasn't";
  return `${haventText} been assigned any assignments yet.`;
}

function assignmentLabel() {
  const haveText = props.isMyOwn ? "You've" : "This consultant has";
  function getAssignmentString(count: number) {
    return count == 1 ? "assignment" : "assignments";
  }
  const hasHave = props.isMyOwn ? "have" : "has";
  const futureCount = futureAssignments.value.length;
  const futureAssignmentS = getAssignmentString(futureCount);

  const future =
    futureCount > 0
      ? ` and ${hasHave} ${futureCount} future ${futureAssignmentS} planned`
      : "";

  const currentAndPastCount = assignments.value.length - futureCount;
  const currentAndPastAssignmentS = getAssignmentString(currentAndPastCount);

  return `${haveText} been on a total of ${currentAndPastCount} ${currentAndPastAssignmentS} ${future}`;
}

function noSharedCustomerLabel() {
  const dontText = props.isMyOwn ? "You don't" : "This consultant doesn't";
  return `${dontText} share any customers with other consultants`;
}

function customerLabel() {
  const youText = props.isMyOwn ? "you" : "this consultant";
  const x = sharedCustomerInfo.value?.sharedCustomers.size;
  const are = x == 1 ? "is" : "are";
  const s = x == 1 ? "" : "s";

  return `There ${are} ${x} other consultant${s} who share a customer with ${youText}`;
}

function noCompetenciesLabel() {
  const youDont = props.isMyOwn ? "You don't" : "This consultant doesn't";
  return `${youDont} share any competencies with other consultants.`;
}

function competenciesLabel() {
  const youShare = props.isMyOwn ? "You share" : "This consultant shares";
  const set = new Set<number>();
  for (const info of sharedCompetencyInfo.value.values()) {
    for (const consultantId of info.sharedCompetencies.keys()) {
      set.add(consultantId);
    }
  }
  const s = set.size == 1 ? "" : "s";
  return `${youShare} at least one competency with ${set.size} other consultant${s}`;
}
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.line {
  padding: 0;
}

.future,
.current,
.competency-span {
  background-color: transparent;
}
.your-assignments {
  overflow: auto;
  .table {
    span {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      font-size: 14px;
    }
  }
}

.shared-customers .shared-competencies {
  overflow: visible;
}

.table-container {
  @include profileTableContainer();
  .description {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .table {
    @include tablewrapper($margin-top: 0, $overflow: visible);
    @include profileTable();
    td {
      .italic {
        font-style: italic;
      }

      .vea-blue {
        color: $vea-primary-color;
      }
      .not-shared {
        font-size: 12px;
        font-style: italic;
      }
      &.clickable {
        pointer-events: all;
      }
      .line {
        &.first {
          border-color: $color-white;
        }
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 50%;
        border-left: 1px solid $color-grey;
      }
    }
  }
}
.consultant-tag {
  display: flex;
  position: absolute;
  width: max-content;
  height: max-content;
  background-color: $color-white;
  border: 1px solid $color-grey;
  border-radius: 5px;
  top: 20;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  max-height: 500px;
  overflow: auto;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .consultant-tag {
    max-height: 80vw;
  }
}
</style>
