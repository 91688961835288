<template>
  <base-modal
    :title="'An error occured in the database'"
    modalIcon="code"
    :abort="onClick"
    :danger="true"
    :accepttitle="'Dismiss'"
    :accept="onClick"
  >
    <div id="backend-userinfo">
      Please try again later.<br />If the problem persists, it could indicate a
      software&nbsp;bug.<br />You are welcome to report the details below to
      the&nbsp;VEA&nbsp;development&nbsp;team.<br />
    </div>
    <hr />
    <div id="backend-devinfo">
      <ul
        class="list-group"
        v-for="(errorType, key) in errorSet.errors"
        :key="key"
      >
        <li
          class="list-group item"
          v-for="(error, key) in errorType"
          :key="key"
        >
          {{ error }}
          <div class="modal-body error-detail-pane" v-if="error.response">
            {{ error.response }}
          </div>
        </li>
      </ul>
    </div>
  </base-modal>
</template>

<script setup lang="ts">
import { BackendErrorSet } from "@/store/data/error/BackendErrorSet";
import { onMounted } from "vue";
import BaseModal from "./BaseModal.vue";

const props = defineProps<{
  errorSet: BackendErrorSet;
  onClick: () => void;
  onMount: () => void;
}>();
onMounted(() => props.onMount());
</script>

<style lang="scss">
#backend-userinfo {
  font-style: italic;
}

#backend-devinfo {
  font-family: monospace;
  font-size: smaller;
}

.error-detail-pane {
  margin: auto;
  max-width: 90%;
  max-height: 150px;
  font-size: x-small;
}
</style>
