import { IOpenDatedDisplayable } from "@/interfaces/Dated";
import { ConsultantData } from "@/store/data/ConsultantData";
import { CorporationData } from "@/store/data/CorporationData";
import { NisAssignmentData } from "@/store/data/NisAssignmentData";
import { NisConsultantData } from "@/store/data/NisConsultantData";
import { NisConsultantGroupData } from "@/store/data/NisConsultantGroupData";
import { UserData } from "@/store/data/UserData";
import { dateKey } from "@/types/DateKey";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { NisConsultant } from "../NisConsultant";
import { NisConsultantGroup } from "../NisConsultantGroup";
import { User } from "../User";
import { DisplayableBase } from "./DisplayableBase";
import { NisConsultantGroupDisplayable } from "./NisConsultantGroupDisplayable";
import { UserDisplayable } from "./UserDisplayable";
import { DateField } from "./fields/DateField";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "./fields/DropdownField";
import { NullableDateField } from "./fields/NullableDateField";
import { TableField } from "./fields/TableField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldMinDateOrNull } from "./fields/validators/FieldMinDateOrNull";
import { FieldValidNisConsultantEndDate } from "./fields/validators/FieldValidEmployeeEndDate";

export class NisConsultantDisplayable
  extends DisplayableBase<NisConsultant>
  implements IOpenDatedDisplayable
{
  @ValidateNested()
  name: DropdownField<User, UserDisplayable>;
  @ValidateNested()
  group: DropdownField<NisConsultantGroup, NisConsultantGroupDisplayable>;
  corporation: TableField;
  @ValidateNested()
  employmentStart: DateField;
  @ValidateNested()
  @FieldMinDateOrNull("employmentStart")
  @FieldValidNisConsultantEndDate(() => NisAssignmentData, "nisConsultantId")
  employmentEnd: NullableDateField;

  constructor(nisConsultant: NisConsultant) {
    super();
    const nisConsultantData = container.resolve(NisConsultantData);
    const corporationData = container.resolve(CorporationData);
    const userData = container.resolve(UserData);
    const nisConsultantGroupData = container.resolve(NisConsultantGroupData);
    const consultantData = container.resolve(ConsultantData);
    const { headers } = nisConsultantData;
    const name = `${nisConsultant.firstname} ${nisConsultant.lastname}`;

    const userFilter = (selected: DropdownOption<string>) => {
      return (
        !consultantData.some("userId", selected.id) &&
        !nisConsultantData.rows.some(
          (c) => c.userId === selected.id && c.userId !== nisConsultant.userId
        )
      );
    };

    this.name = new DropdownField(
      "User",
      name,
      userData,
      new DropdownOption(nisConsultant.userId, name),
      {
        optionsFilter: userFilter,
      }
    );
    this.name.editImpossible = true;
    this.group = new DropdownField(
      headers.group,
      nisConsultant.nisConsultantGroupName,
      nisConsultantGroupData,
      new DropdownOption(
        nisConsultant.nisConsultantGroupId,
        nisConsultant.nisConsultantGroupName
      ),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          corporationData,
          "corporationId",
          "corporationName"
        ),
      }
    );
    this.corporation = new TableField(
      headers.corporation,
      nisConsultant.corporationName
    );
    this.employmentStart = new DateField(
      headers.employmentStart,
      nisConsultant.startDate
    );
    this.employmentEnd = new NullableDateField(
      headers.employmentEnd,
      nisConsultant.endDate
    );
  }

  toModel(nisConsultant: NisConsultant): void {
    nisConsultant.startDate = dateKey(this.employmentStart.modelValue());
    const employmentEnd = this.employmentEnd.modelValue();
    nisConsultant.endDate = employmentEnd ? dateKey(employmentEnd) : null;
    nisConsultant.nisConsultantGroupId = this.group.selectedOption.id;
    nisConsultant.userId = this.name.selectedOption.id;
  }
}
