import { CompetencyCategory } from "@/models/CompetencyCategory";
import { CompetencyCategoryDisplayable } from "@/models/displayable/CompetencyCategoryDisplayable";
import { container, singleton } from "tsyringe";
import { CompetencyData } from "./CompetencyData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { CompetencyCategoryHeaders } from "./headers/CompetencyCategoryHeaders";

@singleton()
export class CompetencyCategoryData extends TableData<
  CompetencyCategory,
  CompetencyCategoryDisplayable
> {
  constructor() {
    super(
      "Competency Categories",
      new CompetencyCategoryHeaders(),
      "CompetencyCategory",
      new CompetencyCategory(),
      "Competency Category",
      [
        new TablePointer(
          container.resolve(CompetencyData),
          "competencyCategoryId",
          DeletePolicy.NeverDelete
        ),
      ]
    );

    this._deleteError =
      "Warning! This category still has competencies connected to it!" +
      "Are you sure you want to delete this competency";
  }
}
