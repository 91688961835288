import FileFolder from "@/components/enum/FileFolder";
import { Assignment } from "@/models/Assignment";
import { Competency } from "@/models/Competency";
import { Consultant } from "@/models/Consultant";
import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Corporation } from "@/models/Corporation";
import { Customer } from "@/models/Customer";
import { AssignmentDisplayable } from "@/models/displayable/AssignmentDisplayable";
import { ConsultantDisplayable } from "@/models/displayable/ConsultantDisplayable";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { CustomerDisplayable } from "@/models/displayable/CustomerDisplayable";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import { ContractPhase } from "@/models/enum/ContractPhase";
import store from "@/store";
import { container } from "tsyringe";
import { TableDataOptions } from "../TableDataOptions";
import { TaskmasterData } from "../TaskmasterData";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { FilesExtension } from "../tableDataOptionsExtensions/FilesExtension";
import { RequiredCompetenciesExtension } from "../tableDataOptionsExtensions/RequiredCompetenciesExtension";
import { AssignmentPhaseFilter } from "../tableDataOptionsFilters/AssignmentPhaseFilter";
import { FilesFilter } from "../tableDataOptionsFilters/FilesFilter";
import { PeriodFilter } from "../tableDataOptionsFilters/PeriodFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class AssignmentOptions extends TableDataOptions<
  Assignment,
  AssignmentDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  private consultantGroupDropdown: TableDataDropdown<
    ConsultantGroup,
    ConsultantGroupDisplayable
  >;

  private consultantDropdown: TableDataDropdown<
    Consultant,
    ConsultantDisplayable
  >;

  private customerDropdown: TableDataDropdown<Customer, CustomerDisplayable>;

  customerFilter(option: DropdownOption<string>) {
    const selectedConsultantGroup = this.consultantGroupDropdown.selectedOption;
    const selectedCorporation = this.corporationDropdown.selectedOption;
    const selectedConsultant = this.consultantDropdown.selectedOption;
    const {
      assignmentData,
      consultantGroupData,
      consultantData,
      taskmasterData,
    } = store.state;

    const taskmasterIds = taskmasterData.findIds("customerId", option.id);

    if (selectedConsultant != -1) {
      return assignmentData
        .findWithValuesInSet("taskmasterId", taskmasterIds)
        .some((a) => a.consultantId == selectedConsultant);
    }

    let consultantIds: Set<number>;

    if (selectedConsultantGroup != -1) {
      consultantIds = consultantData.findIds(
        "consultantGroupId",
        selectedConsultantGroup
      );
    } else if (selectedCorporation != -1) {
      const consultantGroupIds = consultantGroupData.findIds(
        "corporationId",
        selectedCorporation
      );
      consultantIds = consultantData.findWithValuesInSetIds(
        "consultantGroupId",
        consultantGroupIds
      );
    } else {
      return true;
    }

    return assignmentData.someWithValuesInSet(
      ["taskmasterId", taskmasterIds],
      ["consultantId", consultantIds]
    );
  }

  protected override filterRows(
    rows: Assignment[],
    radioFilters: BaseTableFilterMode[],
    _yearFilter: number,
    selectedCompetencies?: Competency[] | undefined
  ): Assignment[] {
    const corporationFilter = this.corporationDropdown.selectedOption;
    const consultantFilter = this.consultantDropdown.selectedOption;
    const consultantGroupFilter = this.consultantGroupDropdown.selectedOption;
    const customerFilter = this.customerDropdown.selectedOption;
    const [periodFilter, assignmentPhaseFilter, filesFilter] = radioFilters;

    const assignmentFilters = TableDataOptions.getPeriodFilters(
      periodFilter,
      corporationFilter,
      consultantFilter,
      consultantGroupFilter
    );

    const filters = new FilterBuilder<Assignment>(assignmentFilters);

    if (customerFilter != -1) {
      const taskmasterData = container.resolve(TaskmasterData);
      const taskmastersForCustomerFilter = taskmasterData.findIds(
        "customerId",
        customerFilter
      );
      filters.add((a) => taskmastersForCustomerFilter.has(a.taskmasterId));
    }

    switch (assignmentPhaseFilter) {
      case BaseTableFilterMode.PROBABLE_ASSIGNMENT:
        filters.add((a) => a.phase == ContractPhase.PROBABLE_ASSIGNMENT);
        break;
      case BaseTableFilterMode.ASSIGNMENT:
        filters.add((a) => a.phase == ContractPhase.ASSIGNMENT);
        break;
      case BaseTableFilterMode.INTERNSHIP:
        filters.add((a) => a.phase == ContractPhase.INTERNSHIP);
        break;
      case BaseTableFilterMode.NON_BILLABLE:
        filters.add((a) => a.phase == ContractPhase.NON_BILLABLE);
        break;
    }

    switch (filesFilter) {
      case BaseTableFilterMode.HAS_FILES:
        filters.add((a) => this.filesExtension?.hasFiles(a));
        break;
      case BaseTableFilterMode.NO_FILES:
        filters.add((a) => !this.filesExtension?.hasFiles(a));
        break;
    }

    if (selectedCompetencies && selectedCompetencies.length > 0) {
      filters.add(this.getSelectedCompetenciesFilter(selectedCompetencies));
    }

    return filters.filter(rows);
  }

  public constructor() {
    const {
      corporationData,
      consultantData,
      consultantGroupData,
      customerData,
    } = store.state;

    super({
      radioFilters: [
        new PeriodFilter(),
        new AssignmentPhaseFilter(),
        new FilesFilter(),
      ],
      sorting: {
        Name: { key: "name", column: "name" },
        Start: { func: TableDataOptions.compareStart, column: "startDate" },
        Assignee: {
          func: TableDataOptions.compareFirstNames,
          column: "consultant",
        },
        Customer: { key: "customerName", column: "customer" },
        Service: { key: "serviceName", column: "service" },
      },
      filesExtension: new FilesExtension(
        (assignment) => assignment.assignmentId,
        FileFolder.Assignment
      ),
      requiredCompetenciesExtension: new RequiredCompetenciesExtension(
        (assignment) => assignment.assignmentId
      ),
      filterBoxKeys: [
        "name",
        "customer",
        "phase",
        "startDate",
        "endDate",
        "competencies",
        "taskmaster",
        "consultant",
        "service",
      ],
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);
    this.consultantGroupDropdown = new TableDataDropdown(
      consultantGroupData,
      undefined,
      this.getDropdownFilterFromRelatedDropdown(
        consultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );
    this.consultantDropdown = new TableDataDropdown(
      consultantData,
      undefined,
      this.getDropdownFilterFromRelatedDropdowns(
        consultantData,
        this.consultantGroupDropdown,
        "consultantGroupId",
        consultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );

    this.customerDropdown = new TableDataDropdown(
      customerData,
      undefined,
      this.customerFilter.bind(this)
    );

    this.dropdownFilters = [
      this.corporationDropdown,
      this.consultantGroupDropdown,
      this.consultantDropdown,
      this.customerDropdown,
    ];
  }
}
