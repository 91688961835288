import { ContainsEmail } from "@/interfaces/ContainsEmail";
import { ModelBase } from "./ModelBase";
import { UserDisplayable } from "./displayable/UserDisplayable";

export class User extends ModelBase<UserDisplayable> implements ContainsEmail {
  userId: number;
  firstname: string;
  lastname: string;
  address: string | null;
  phoneNr: string | null;
  email: string;
  password: string;
  active: boolean;
  roleId: number;
  roleName!: string;

  constructor() {
    super();

    this.userId = 0;
    this.firstname = "";
    this.lastname = "";
    this.address = null;
    this.phoneNr = null;
    this.email = "";
    this.password = "";
    this.active = false;
    this.roleId = 2;
    this.roleName = "User";
  }

  realize(partialModel: User): User {
    const newModel = new User();
    newModel.userId = partialModel.userId;
    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;
    newModel.address = partialModel.address;
    newModel.phoneNr = partialModel.phoneNr;
    newModel.email = partialModel.email;
    newModel.password = partialModel.password;
    newModel.active = partialModel.active;
    newModel.roleId = partialModel.roleId;
    newModel.roleName = partialModel.roleName;
    return newModel;
  }

  getId(): number {
    return this.userId;
  }

  getName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  getDisplayable(): UserDisplayable {
    return new UserDisplayable(this);
  }
}
