import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from "class-validator";

export interface HasSelection<T> {
  selectionOptions(): T[];
}

export function InSelection<T>(validationOptions?: ValidationOptions) {
  return function (object: HasSelection<T>, propertyName: string): void {
    registerDecorator({
      name: "InSelection",
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(field: T, args: ValidationArguments) {
          return (args.object as HasSelection<T>)
            .selectionOptions()
            .includes(field);
        },
      },
    });
  };
}
