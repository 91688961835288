import { CorporationData } from "@/store/data/CorporationData";
import { CurrencyData } from "@/store/data/CurrencyData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Corporation } from "../Corporation";
import { Currency } from "../Currency";
import { CurrencyDisplayable } from "./CurrencyDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class CorporationDisplayable extends DisplayableBase<Corporation> {
  @ValidateNested()
  @FieldUniqueText<Corporation, CorporationDisplayable>()
  name: UniqueTextField<Corporation, CorporationDisplayable>;

  @ValidateNested()
  currencyCode: DropdownField<Currency, CurrencyDisplayable>;

  constructor(corporation: Corporation) {
    super();
    const corporationData = container.resolve(CorporationData);
    const currencyData = container.resolve(CurrencyData);
    const { headers } = corporationData;
    this.name = new UniqueTextField(
      headers.name,
      corporation.name,
      corporationData
    );

    const { currencyId, currencyCode } =
      currencyData.getCompanyCurrencyOrDefault(corporation.corporationId);

    this.currencyCode = new DropdownField(
      headers.currencyCode,
      corporation.currencyCode,
      currencyData,
      new DropdownOption(currencyId, currencyCode),
      { isCompoundType: false }
    );
  }

  toModel(corporation: Corporation): void {
    corporation.name = this.name.modelValue();
    corporation.currencyId = this.currencyCode.selectedOption.id;
  }
}
