import { AbsenceCategory } from "@/models/AbsenceCategory";
import { AbsenceCategoryDisplayable } from "@/models/displayable/AbsenceCategoryDisplayable";
import { container, singleton } from "tsyringe";
import { AbsenceData } from "./AbsenceData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { AbsenceCategoryHeaders } from "./headers/AbsenceCategoryHeaders";

@singleton()
export class AbsenceCategoryData extends TableData<
  AbsenceCategory,
  AbsenceCategoryDisplayable
> {
  constructor() {
    super(
      "Absence Categories",
      new AbsenceCategoryHeaders(),
      "AbsenceCategory",
      new AbsenceCategory(),
      "Absence Category",
      [
        new TablePointer(
          container.resolve(AbsenceData),
          "absenceCategoryId",
          DeletePolicy.NeverDelete,
          (absence) =>
            `${absence.getName()} (${absence.startDate} ― ${absence.endDate})`
        ),
      ]
    );
    this._deleteError =
      "This absence category may not be deleted! " +
      "It still has absence(s) under it! " +
      "Reassign or remove each of its remaining absences";
  }
}
