<template>
  <div class="big-image">
    <BaseToast id="service-toast" />
    <div class="overlay">
      <div class="text-content">
        <header>
          <h1>Welcome to VEA</h1>
          <small>
            Assignments within Software Development / Testing / QA & Data
            Science
          </small>
          <h2>
            Our vision and purpose
            <small>To build a more inclusive world.</small>
            Our mission
            <small>
              Address the inequalities in employment for neurodivergent adults
              and showcase the strengths of neurodiversity in society.
            </small>
          </h2>
        </header>
        <article id="stat-container">
          <p
            v-for="(stat, i) in state.stats"
            :key="i"
            class="stat"
            :class="[state.currentStatIndex === i ? 'show' : 'hide']"
          >
            {{ stat }}
          </p>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseToast from "@/components/BaseToast.vue";
import store from "@/store";
import { EventType } from "@/util/EventEmitter";
import { onBeforeMount, reactive } from "vue";

onBeforeMount(() => {
  store.state.events.subscribeOrRunIfDispatched(
    EventType.FinishedLoadingTableData,
    () => {
      const stats = store.state.statistics.generateTidbits();
      state.stats = stats;
      setTimeout(() => {
        state.currentStatIndex = 0;
        setInterval(() => {
          state.currentStatIndex = (state.currentStatIndex + 1) % stats.length;
        }, 9000);
      }, 100);
    }
  );
});

const state = reactive({
  stats: Array<string>(),
  currentStatIndex: -1,
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/global.scss";

.text-content {
  background: $base-color;
  padding: 1rem;
  opacity: 0.85;
  color: $base-text-color;
  font-size: clamp(15px, 3vw, 26px);
  border-radius: 15px;
  width: Min(870px, 87vw);
  z-index: 1;
  font-family: "Roboto", sans-serif;
  margin: auto;

  h1 {
    font-size: 2.4em;
  }

  h2 {
    font-size: 1.4em;
    padding-top: 0.7em;
  }

  small {
    display: block;
    font-size: Max(0.5em, 15px);
    font-style: italic;
  }

  .stat {
    font-size: Max(0.8em, 17px);
    padding: 1.5em 0 1em;
  }
}

.big-image {
  position: relative;
  z-index: 0;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../public/welcome-background.jpg");

  .toast-container {
    position: absolute;
    margin: 0.25rem;
  }
}

.overlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#stat-container {
  position: relative;
  height: 80px;

  .stat {
    position: absolute;
    left: 0;
    right: 0;
    top: 8px;
    opacity: 0;
  }

  .show {
    opacity: 1;
    transform: translateY(-20px);
    transition: 2s opacity 0s, 1.5s transform 0s;
  }

  .hide {
    opacity: 0;
    transform: translateY(20px);
    transition: 0.8s opacity 0s, 0s transform 1.5s;
  }
}
</style>
