import { DateKey } from "@/types/DateKey";
import { IsISO8601, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { DateType } from "./enum/DateType";
import { FieldType } from "./enum/FieldType";
import { DateError } from "./error/DateError";

export class DateField extends DisplayableField<string, string> {
  @IsISO8601(
    { strict: true },
    {
      message: (args: ValidationArguments) =>
        (args.object as DateField)._error.valueError(args.value),
    }
  )
  protected _value: DateKey;
  protected _mandatory = true;
  /** How precise the date can be set by the user, with options like "date", "week", "month" */
  protected _dateType: DateType;

  get value(): DateKey {
    return this._value;
  }

  set value(input: DateKey) {
    this._value = input;
  }

  get mandatory(): boolean {
    return this._mandatory;
  }

  get dateType(): DateType {
    return this._dateType;
  }

  modelValue(): DateKey {
    return this._value;
  }

  constructor(
    header: string,
    data: DateKey,
    dateType: DateType = "date", // defaults to selecting days
    options?: DisplayableFieldOptions
  ) {
    super(header, new DateError(header), FieldType.DATE, options);
    this._value = data;
    this._dateType = dateType;
  }
}
