<template>
  <section class="accordion profile" :id="accordionId">
    <div class="accordion-item">
      <h2 class="accordion-header" :id="headingId">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#${collapseId}`"
          aria-expanded="true"
          :aria-controls="collapseId"
        >
          Files ({{ state.filesAvailable.length }})
        </button>
      </h2>
      <div
        :id="collapseId"
        class="accordion-collapse collapse"
        aria-labelledby="heading"
        :data-bs-parent="`#${accordionId}`"
      >
        <div class="accordion-body">
          <div v-if="hasFilesAvailable()" class="conditional-wrapper">
            <ul class="file-list">
              <li
                class="file-item"
                v-for="file in state.filesAvailable"
                :key="file.fileName"
              >
                <button
                  class="btn btn-sm btn-delete"
                  @click.prevent="handleDelete(file.fileName)"
                  v-if="isAuthorized"
                >
                  <fa-icon icon="minus-circle" class="del" />
                </button>
                <div
                  v-if="isDownloading(file.fileName)"
                  class="conditional-wrapper"
                >
                  <button class="btn btn-light btn-file" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Downloading...
                  </button>
                </div>
                <div v-else>
                  <button
                    class="btn btn-light btn-file"
                    @click.stop="handleDownload(file)"
                    :title="file.fileName"
                  >
                    <fa-icon icon="download"></fa-icon>
                    {{ handleFileNameLength(file.fileName) }}
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div v-else><p>No files here...</p></div>

          <FileForm
            v-if="isAuthorized"
            :inputId
            :uploadBtnId
            :maxFileSizeMb="10"
            :acceptedFileTypes="['.pdf', '.docx']"
            :multiple="false"
            :handleUpload
          />
        </div>
        <div v-if="state.error" class="err-msg">
          {{ state.error }}
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { AuthorizationManager } from "@/store/AuthorizationManager";
import FileData from "@/store/data/FileData";
import { FileDetailsMap } from "@/store/data/tableDataOptionsExtensions/FilesExtension";
import FileDetails from "@/store/data/types/FileDetails";
import { container } from "tsyringe";
import { computed, onBeforeMount, reactive } from "vue";
import FileForm from "./FileForm.vue";
import FileFolder from "./enum/FileFolder";

const props = defineProps<{
  id: number;
  folder: FileFolder;
  canEdit?: boolean;
  filesPromise?: Promise<FileDetailsMap>;
}>();
const emit = defineEmits(["edit", "file-upload", "file-delete"]);
const fileData = container.resolve(FileData);

const state = reactive({
  filesAvailable: Array<FileDetails>(),
  downloadItem: "",
  error: "",
  fileNameLength:
    window.innerWidth < 500 ? Math.round(window.innerWidth / 20) : 25,
});

let fileDetailsMap: FileDetailsMap;

async function getContent() {
  await fileData.getList(props.folder, props.id).then((list) => {
    list ??= [];
    fileDetailsMap?.setFileDetails(props.id, list);
    state.filesAvailable = list;
  });
}

onBeforeMount(async () => {
  if (props.filesPromise === undefined) {
    await getContent();
    return;
  }
  fileDetailsMap = await props.filesPromise;
  state.filesAvailable =
    fileDetailsMap?.getFileDetailsOrAddEmpty(props.id) ?? [];
});

window.onresize = () =>
  (state.fileNameLength = window.innerWidth < 500 ? 10 : 50);

async function handleDelete(fileName: string) {
  await fileData
    .deleteFile(fileName, props.folder, props.id)
    .then(getContent)
    .then(() => emit("file-delete"));
}

function isDownloading(item: string) {
  return state.downloadItem == item;
}

function toggleDownloading(item = "") {
  state.downloadItem = state.downloadItem ? "" : item;
}

async function handleDownload(fileDetails: FileDetails) {
  toggleDownloading(fileDetails.fileName);
  await fileData.downloadFile(fileDetails).finally(() => toggleDownloading());
}

async function handleUpload(files: FileList) {
  try {
    await Promise.all(
      Array.from(files).map((file) =>
        fileData.uploadFile(file, props.folder, props.id)
      )
    ).then(() => emit("file-delete"));
    getContent();
  } catch (error: unknown) {
    if (error instanceof Error && "message" in error) {
      handleError(error);
    } else {
      console.error("Problem uploading file: ", error);
    }
  }
}

function handleError({ message }: { message: string }) {
  state.error = message;
  setTimeout(() => {
    state.error = "";
  }, 2000);
}

function hasFilesAvailable() {
  return state.filesAvailable.length > 0;
}

const uid = Math.ceil(Math.random() * 1000).toString() + props.id;

const accordionId = `accordion${uid}`;
const collapseId = `collapse${uid}`;
const headingId = `heading${uid}`;
const inputId = `input${uid}`;
const uploadBtnId = `uploadBtn${uid}`;

function handleFileNameLength(fileName: string) {
  if (fileName.length > state.fileNameLength) {
    fileName =
      fileName.slice(0, state.fileNameLength / 2) +
      "..." +
      fileName.slice(fileName.length - state.fileNameLength / 2);
  }
  return fileName;
}

const isAuthorized = computed(
  () =>
    props.canEdit ||
    container.resolve(AuthorizationManager).editFiles.isAuthorized()
);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.accordion {
  padding: 3px;

  .accordion-button {
    padding: 0.3rem;
  }
  .accordion-item:focus-within {
    outline: 3px solid $vea-primary-color;
  }
  .accordion-body {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
  }

  .accordion-collapse {
    position: relative;
    background-color: $color-white;
  }
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0rem 0 0rem 0;

  .file-item {
    display: grid;
    gap: 10px;
    margin-bottom: 10px;
    grid-template-columns: 1fr minmax(0, 8fr);
    text-align: left;

    .btn-delete:hover {
      color: $color-darkred;
    }

    .btn-file {
      @include btn-file();
    }

    .btn-file:hover {
      outline-width: 2px;
    }
  }

  .upload-form {
    margin-top: 40px;
  }
}
.err-msg {
  padding-bottom: 10px;
  margin-top: -10px;
  font-weight: bold;
  color: $color-darkred;
}
</style>
