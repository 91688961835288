import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { AbsenceDisplayable } from "./displayable/AbsenceDisplayable";

export class Absence extends ModelBase<AbsenceDisplayable> {
  absenceId: number;
  coverage: number;
  startDate: DateKey;
  endDate: DateKey;
  approved: boolean;
  consultantId: number;
  absenceCategoryId: number;

  firstname: string;
  lastname: string;
  absenceCategoryName: string;
  requiredRequest: boolean;

  constructor() {
    super();

    this.absenceId = 0;
    this.coverage = 100;
    this.startDate = "" as DateKey;
    this.endDate = "" as DateKey;
    this.approved = false;
    this.consultantId = 0;
    this.absenceCategoryId = 0;

    this.firstname = "";
    this.lastname = "";
    this.absenceCategoryName = "";
    this.requiredRequest = true;
  }

  realize(partialModel: Absence): Absence {
    const newModel = new Absence();

    newModel.absenceId = partialModel.absenceId;
    newModel.coverage = partialModel.coverage;
    newModel.startDate = partialModel.startDate;
    newModel.endDate = partialModel.endDate;
    newModel.approved = partialModel.approved;
    newModel.consultantId = partialModel.consultantId;
    newModel.absenceCategoryId = partialModel.absenceCategoryId;

    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;
    newModel.absenceCategoryName = partialModel.absenceCategoryName;
    newModel.requiredRequest = partialModel.requiredRequest;

    return newModel;
  }

  getId(): number {
    return this.absenceId;
  }

  getName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  getDisplayable(): AbsenceDisplayable {
    return new AbsenceDisplayable(this);
  }
}
