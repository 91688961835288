import { Assignment } from "@/models/Assignment";
import { ContractPhase } from "@/models/enum/ContractPhase";
import store from "@/store";
import { MonthKey } from "@/types/DateKey";
import { isInSpan, monthAsDateSpan, periodSpan } from "@/types/DateSpan";

export type MonthlyAssignmentCounts = {
  month: Date;
  signed: number;
  signedFulltime: number;
  probable: number;
  probableFulltime: number;
  internships: number;
  nonBillable: number;
  uniqueCustomers: number;
};

export function calculateNumberOfAssignments(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number,
  options?: {
    includeNonBillable: boolean;
    includeInternships: boolean;
    includeProbable: boolean;
  }
): MonthlyAssignmentCounts {
  const {
    assignmentData,
    consultantData,
    consultantGroupData,
    taskmasterData,
  } = store.state;

  let assignments: Assignment[];
  if (consultantGroupId && consultantGroupId > 0) {
    const consultantIds = consultantData.findIds(
      "consultantGroupId",
      consultantGroupId
    );
    assignments = assignmentData.findWithValuesInSet(
      "consultantId",
      consultantIds
    );
  } else if (corporationId > 0) {
    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      corporationId
    );
    const consultantIds = consultantData.findWithValuesInSetIds(
      "consultantGroupId",
      consultantGroupIds
    );
    assignments = assignmentData.findWithValuesInSet(
      "consultantId",
      consultantIds
    );
  } else {
    assignments = assignmentData.rows;
  }

  const lastDayInMonth = monthAsDateSpan(month).end;
  assignments = assignments.filter((a) =>
    isInSpan(lastDayInMonth, periodSpan(a))
  );

  const results: MonthlyAssignmentCounts = {
    month: new Date(month),
    signed: 0,
    signedFulltime: 0,
    probable: 0,
    probableFulltime: 0,
    internships: 0,
    nonBillable: 0,
    uniqueCustomers: 0,
  };

  const uniqueCustomers = new Set<number>();

  for (const a of assignments) {
    switch (a.phase) {
      case ContractPhase.ASSIGNMENT:
        results.signed++;
        results.signedFulltime += a.coverage / 100;
        break;
      case ContractPhase.PROBABLE_ASSIGNMENT:
        if (!options?.includeProbable) continue;
        results.probable++;
        results.probableFulltime += a.coverage / 100;
        break;
      case ContractPhase.INTERNSHIP:
        if (!options?.includeInternships) continue;
        results.internships++;
        break;
      case ContractPhase.NON_BILLABLE:
        if (!options?.includeNonBillable) continue;
        results.nonBillable++;
        break;
    }

    if (a.phase != ContractPhase.INTERNSHIP) {
      uniqueCustomers.add(taskmasterData.findById(a.taskmasterId).customerId);
    }
  }

  results.uniqueCustomers = uniqueCustomers.size;

  return results;
}
