export class GanttOptions {
  // Gantt
  width: number;
  rowHeight: number;
  rowGap: number;
  topPadding: number;
  bottomPadding: number;
  leftPadding: number;
  labelPadding: number;
  rowLabelOffsetY: number;
  rowTextPadding: number;
  standardFontSize: number;
  headingFontSize: number;
  labelTextHeight: number;
  minBarTextWidth: number;
  reportMargin: number;

  // Transitions
  fastTransitionDuration: number;
  midTransitionDuration: number;
  slowTransitionDuration: number;

  // Date axes
  xAxisBottomOffset: number;
  axisFontSize: number;

  constructor() {
    this.width = this.maxWidth();
    this.leftPadding = 130;
    this.rowHeight = 50;
    this.rowGap = 5;
    this.topPadding = 50;
    this.bottomPadding = 100;
    this.labelPadding = 120;
    this.rowLabelOffsetY = 5;
    this.rowTextPadding = 15;
    this.standardFontSize = 14;
    this.headingFontSize = 18;
    this.labelTextHeight = 14;
    this.minBarTextWidth = 100;
    this.reportMargin = 15;

    this.fastTransitionDuration = 500;
    this.midTransitionDuration = 750;
    this.slowTransitionDuration = 1000;

    this.xAxisBottomOffset = 33;
    this.axisFontSize = 11;
  }

  public maxWidth = (): number => 1500;

  public height(rowCount: number): number {
    return this.topPadding + rowCount * (this.rowHeight + this.rowGap);
  }

  public innerLeft(): number {
    return this.width - this.leftPadding - 1;
  }

  rowY(row: number): number {
    return row * (this.rowHeight + this.rowGap) + this.topPadding;
  }

  getTextYOffset(): number {
    return this.rowHeight / 2 + 4;
  }
}
