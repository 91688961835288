import { ConsultantService } from "@/models/ConsultantService";
import { ConsultantServiceDisplayable } from "@/models/displayable/ConsultantServiceDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { ConsultantServiceHeaders } from "./headers/ConsultantServiceHeaders";

@singleton()
export class ConsultantServiceData extends TableData<
  ConsultantService,
  ConsultantServiceDisplayable
> {
  constructor() {
    super(
      "Consultant Services",
      new ConsultantServiceHeaders(),
      "ConsultantService",
      new ConsultantService(),
      "Consultant Service"
    );
    this._deleteError = "This service may not be deleted!";
  }
}
