import { CustomerDisplayable } from "./displayable/CustomerDisplayable";
import { ModelBase } from "./ModelBase";

export class Customer extends ModelBase<CustomerDisplayable> {
  customerId: number;
  name: string;
  leaderId: number | null;
  priorityId: number | null;
  locationId: number;
  industryId: number;
  potential: string | null;
  frameworkAgreement: boolean;

  priorityName: string;
  priorityOrder: number;
  locationName: string;
  industryName: string;
  leaderFirstName: string;
  leaderLastName: string;

  constructor() {
    super();

    this.customerId = 0;
    this.name = "";
    this.leaderId = 0;
    this.priorityId = 0;
    this.locationId = 0;
    this.industryId = 0;
    this.potential = "";
    this.frameworkAgreement = false;

    this.priorityName = "";
    this.priorityOrder = 0;
    this.locationName = "";
    this.industryName = "";
    this.leaderFirstName = "";
    this.leaderLastName = "";
  }

  realize(partialModel: Customer): Customer {
    const newModel = new Customer();
    newModel.customerId = partialModel.customerId;
    newModel.name = partialModel.name;
    newModel.leaderId = partialModel.leaderId;
    newModel.priorityId = partialModel.priorityId;
    newModel.locationId = partialModel.locationId;
    newModel.industryId = partialModel.industryId;
    newModel.potential = partialModel.potential;
    newModel.frameworkAgreement = partialModel.frameworkAgreement;

    newModel.priorityName = partialModel.priorityName;
    newModel.priorityOrder = partialModel.priorityOrder;
    newModel.locationName = partialModel.locationName;
    newModel.industryName = partialModel.industryName;
    newModel.leaderFirstName = partialModel.leaderFirstName;
    newModel.leaderLastName = partialModel.leaderLastName;
    return newModel;
  }

  getId(): number {
    return this.customerId;
  }

  getName(): string {
    return this.name;
  }

  getLeaderName(): string {
    return this.leaderFirstName + " " + this.leaderLastName;
  }

  getDisplayable(): CustomerDisplayable {
    return new CustomerDisplayable(this);
  }
}
