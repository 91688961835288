import { Corporation } from "@/models/Corporation";
import { NisConsultantGroup } from "@/models/NisConsultantGroup";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { NisConsultantGroupDisplayable } from "@/models/displayable/NisConsultantGroupDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class NisConsultantGroupOptions extends TableDataOptions<
  NisConsultantGroup,
  NisConsultantGroupDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  override filterRows(rows: NisConsultantGroup[]): NisConsultantGroup[] {
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const filters = new FilterBuilder<NisConsultantGroup>();

    if (corporationFilter != -1) {
      const corporationIds = store.state.corporationData.findIds(
        "corporationId",
        corporationFilter
      );
      filters.add((nisConsultantGroup) =>
        corporationIds.has(nisConsultantGroup.corporationId)
      );
    }

    return filters.filter(rows);
  }

  public constructor() {
    const { corporationData } = store.state;

    super({
      filterBoxKeys: ["name", "corporation", "leader"],
      sorting: {
        Name: "name",
        Company: "corporationName",
      },
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);

    this.dropdownFilters = [this.corporationDropdown];
  }
}
