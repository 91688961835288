import { Corporation } from "@/models/Corporation";
import { Leader } from "@/models/Leader";
import { Location } from "@/models/Location";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { LeaderDisplayable } from "@/models/displayable/LeaderDisplayable";
import { LocationDisplayable } from "@/models/displayable/LocationDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class LeaderOptions extends TableDataOptions<Leader, LeaderDisplayable> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;
  private locationDropdown: TableDataDropdown<Location, LocationDisplayable>;

  override filterRows(rows: Leader[]): Leader[] {
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const locationFilter: number = this.locationDropdown.selectedOption;
    const filters = new FilterBuilder<Leader>();

    if (locationFilter != -1) {
      filters.add((leader) => leader.locationId == locationFilter);
    } else if (corporationFilter != -1) {
      const { locationData } = store.state;
      const locationIds = locationData.findIds(
        "corporationId",
        corporationFilter
      );
      filters.add((leader) => locationIds.has(leader.locationId));
    }

    return filters.filter(rows);
  }

  public constructor() {
    const { corporationData, locationData } = store.state;

    super({
      filterBoxKeys: ["name", "location", "employmentStart"],
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);

    this.locationDropdown = new TableDataDropdown(
      locationData,
      undefined,
      this.getDropdownFilterFromRelatedDropdown(
        locationData,
        this.corporationDropdown,
        "corporationId"
      )
    );
    this.dropdownFilters = [this.corporationDropdown, this.locationDropdown];
  }
}
