import { Absence } from "@/models/Absence";
import { Assignment } from "@/models/Assignment";
import { Consultant } from "@/models/Consultant";
import { ContractPhase } from "@/models/enum/ContractPhase";
import store from "@/store";
import {
  DateKey,
  MonthKey,
  dateKey,
  dateKeyFromMonthAndDay,
  dayFromKey,
  monthFromKey,
  monthKeyFromDate,
  yearFromKey,
} from "@/types/DateKey";
import {
  DateSpan,
  countDaysInSpan,
  countWorkDaysInMonth,
  dateSpan,
  isInSpan,
  monthAsDateSpan,
  overlappingDays,
  overlaps,
  periodSpan,
} from "@/types/DateSpan";
import { DayOfWeek } from "@/types/DayOfWeek";
import { sum, sumByFunc } from "@/types/SumFields";
import { VeaDates } from "@/util/VeaDates";
import { calculateHourlyRate } from "./calculateHourlyRate";
import { getFirstDaysAfterInternship } from "./getFirstDaysAfterInternship";

type ConsultantPeriodsOfMonth = {
  span: DateSpan;
  consultant: Consultant;
  assignments: Assignment[];
  absences: Absence[];
};

export type InvoiceForecastResult = InvoiceForecastHourlyResult & {
  nis: number;
};

type InvoiceForecastHourlyResult = {
  potential: number;
  probable: number;
  signed: number;
};

type Overlap = {
  periods: Period[];
  span: DateSpan;
};

type PeriodFuncOptions = {
  averageHoursToInvoicePerDayInMonth: number;
  averageHourlyRate: number;
};

type Period = Assignment | Absence;

export function getInvoiceForecast(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number
): InvoiceForecastResult {
  const {
    corporationData,
    consultantData,
    consultantGroupData,
    nisAssignmentData,
    nisConsultantData,
    nisConsultantGroupData,
    invoiceData,
    monthlyReportData,
  } = store.state;

  const monthSpan = monthAsDateSpan(month);

  const firstDayAfterInternshipByConsultantId = getFirstDaysAfterInternship(
    monthSpan.start
  );
  let consultants: Consultant[];
  if (consultantGroupId && consultantGroupId > 0) {
    consultants = consultantData.findMany(
      "consultantGroupId",
      consultantGroupId
    );
  } else {
    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      corporationId
    );
    consultants = consultantData.findWithValuesInSet(
      "consultantGroupId",
      consultantGroupIds
    );
  }

  consultants = consultants
    .filter((c) =>
      c.endDate == null
        ? dateKey(c.startDate) <= monthSpan.end
        : overlaps(monthSpan, dateSpan(c.startDate, c.endDate))
    )
    .filter((c) => {
      const firstDay = firstDayAfterInternshipByConsultantId.get(
        c.consultantId
      );
      return firstDay == undefined || firstDay <= monthSpan.end;
    });

  const monthlyReportsForMonth = monthlyReportData.data.filter(
    (r) =>
      r.corporationId == corporationId &&
      r.year == yearFromKey(month) &&
      r.month == monthFromKey(month) + 1
  );
  const monthlyReportForMonth =
    monthlyReportsForMonth.length == 1 ? monthlyReportsForMonth[0] : null;
  const hours =
    (monthlyReportForMonth?.availableWorkingDays ?? 20) *
    (monthlyReportForMonth?.availableDailyHours ?? 8);

  const periods = consultants.map((c) =>
    mapPeriodsToConsultant(
      c,
      monthSpan,
      firstDayAfterInternshipByConsultantId.get(c.consultantId) ?? null
    )
  );
  const averageHourlyRate = calculateHourlyRate(month, corporationId);
  const averageHoursToInvoicePerDayInMonth =
    hours / countWorkDaysInMonth(month);
  const options: PeriodFuncOptions = {
    averageHourlyRate,
    averageHoursToInvoicePerDayInMonth,
  };
  const dataPoints = periods.flatMap((p) =>
    mapPeriodToDataPoints(p, p.span, options)
  );

  const nisConsultantGroups = nisConsultantGroupData.findIds(
    "corporationId",
    corporationId
  );
  const nisConsultants = nisConsultantData.findWithValuesInSetIds(
    "nisConsultantGroupId",
    nisConsultantGroups
  );
  const nisAssignment = nisAssignmentData.findWithValuesInSetIds(
    "nisConsultantId",
    nisConsultants
  );
  const nisInvoices = invoiceData.findWithValuesInSet(
    "nisAssignmentId",
    nisAssignment
  );

  const corpCurrencyId = corporationData.findById(corporationId).currencyId;
  const nisInvoicesInMonth = nisInvoices.filter(
    (i) => i.currencyId == corpCurrencyId && isInSpan(i.date, monthSpan)
  );
  // multiple currency support is planned as of VEANEW-1770

  const result: InvoiceForecastResult = {
    signed: 0,
    probable: 0,
    potential: 0,
    nis: sum(nisInvoicesInMonth, "amount"),
  };

  for (const dataPoint of dataPoints) {
    result.signed += dataPoint.signed;
    result.probable += dataPoint.probable;
    result.potential += dataPoint.potential;
  }

  return result;
}

/**
 * @returns An object containing all assignments and absences of a consultant during the specified month.
 */
function mapPeriodsToConsultant(
  consultant: Consultant,
  monthSpan: Readonly<DateSpan>,
  firstDayAfterInternship: DateKey | null
): ConsultantPeriodsOfMonth {
  const { absenceData, assignmentData } = store.state;
  const { consultantId } = consultant;

  // create new span to ensure the shared monthSpan isn't edited
  const { start, end } = monthSpan;
  const span = { start, end };

  const startDate = dateKey(consultant.startDate);
  const endDate = consultant.endDate ? dateKey(consultant.endDate) : null;

  // check for consultant end/start date, and end of internship
  // to see if only a part of the month should be checked for
  if (isInSpan(startDate, span)) {
    span.start = dateKey(consultant.startDate);
  }

  if (firstDayAfterInternship && isInSpan(firstDayAfterInternship, span)) {
    span.start = dateKey(firstDayAfterInternship);
  }

  if (endDate && isInSpan(endDate, span)) {
    span.end = endDate;
  }

  const absences = absenceData
    .findMany("consultantId", consultantId)
    .filter((a) => overlaps(span, periodSpan(a)));

  // assignments with phase set to NON_BILLABLE or INTERNSHIP
  // will be ignored and instead calculated as potential
  const assignments = assignmentData
    .findMany("consultantId", consultantId)
    .filter(
      (a) =>
        (a.phase == ContractPhase.ASSIGNMENT ||
          a.phase == ContractPhase.PROBABLE_ASSIGNMENT) &&
        overlaps(span, dateSpan(a.startDate, a.endDate))
    )
    .filter((a) => overlaps(span, dateSpan(a.startDate, a.endDate)));

  return {
    span,
    consultant,
    absences,
    assignments,
  };
}

function mapPeriodToDataPoints(
  period: ConsultantPeriodsOfMonth,
  monthSpan: DateSpan,
  options: PeriodFuncOptions
): InvoiceForecastHourlyResult[] {
  let periods = breakDownPeriods(
    [...period.assignments, ...period.absences],
    monthSpan
  );

  // if there are no periods for a consultant during a month,
  // add an empty period spanning the entire month
  // to ensure potential will still be calculated
  if (periods.length == 0) {
    periods.push({ periods: [], span: monthSpan });
  }

  periods = periods.flatMap(splitByWeekends);

  return periods.map((p) =>
    calculateResultsForPeriod(p, period.consultant, options)
  );
}

function breakDownPeriods(periods: Period[], constraint: DateSpan): Overlap[] {
  if (periods.length == 0) {
    return [];
  }

  function constrainTo(period: Period) {
    const span = overlappingDays(periodSpan(period), constraint) as DateSpan;
    return { period, span };
  }

  // TODO - ensure month & year are the same
  const constrainedPeriods = periods.map(constrainTo);
  const firstActiveDayInMonth = dayFromKey(constraint.start);
  const lastActiveDayInMonth = dayFromKey(constraint.end);
  const month = monthKeyFromDate(constraint.start);
  const set = new Set<Period>();
  const spans = new Array<Overlap>();
  const toDelete = new Set<Period>();
  const toAdd = new Set<Period>();

  let spanStart = constraint.start;
  let lastDate = constraint.start;
  let startNewSpan = false;

  for (let i = firstActiveDayInMonth; i <= lastActiveDayInMonth; i++) {
    const date = dateKeyFromMonthAndDay(month, i);
    if (startNewSpan) {
      startNewSpan = false;
      spanStart = date;
    }

    for (const { span, period } of constrainedPeriods) {
      if (span.start == date) {
        toAdd.add(period);
      }
      if (span.end == date) {
        toDelete.add(period);
      }
    }

    // if no periods were added or removed, continue
    if (toAdd.size == 0 && toDelete.size == 0) {
      lastDate = date;
      continue;
    }

    // if periods were added, create a span from previous values, then add new periods
    if (toAdd.size > 0) {
      if (i != firstActiveDayInMonth) {
        const periods = Array.from(set);
        const span = { start: spanStart, end: lastDate };
        spans.push({ periods, span });
      }

      for (const period of toAdd) {
        set.add(period);
      }
      spanStart = date;
    }

    // if periods were removed, create a span, then remove periods
    if (toDelete.size > 0) {
      const periods = Array.from(set);
      const span = { start: spanStart, end: date };
      spans.push({ periods, span });

      for (const period of toDelete) {
        set.delete(period);
      }
      startNewSpan = true;
    }

    toDelete.clear();
    toAdd.clear();

    lastDate = date;
  }

  if (!startNewSpan) {
    const periods = Array.from(set);
    const span = { start: spanStart, end: lastDate };
    spans.push({ periods, span });
  }

  return spans;
}

function splitByWeekends(overlap: Overlap): Overlap[] {
  const { start, end } = overlap.span;
  const { periods } = overlap;
  const daysInSpan = countDaysInSpan(overlap.span);
  const firstDayInSpan = dayFromKey(start);
  const lastDayInSpan = dayFromKey(end);
  const day = VeaDates.getDayOfWeek(start);
  const month = monthKeyFromDate(start);

  let daysToFirstWorkDay = 0;
  let daysToFirstWeekend = 0;

  if (day == DayOfWeek.Sunday) {
    daysToFirstWorkDay = 1;
    daysToFirstWeekend = -1;
  } else if (day == DayOfWeek.Saturday) {
    daysToFirstWorkDay = 2;
  } else {
    daysToFirstWeekend = DayOfWeek.Saturday - day;
  }

  if (daysInSpan <= daysToFirstWorkDay) {
    return [];
  }

  if (daysInSpan <= daysToFirstWeekend) {
    return [overlap];
  }

  if (daysToFirstWeekend <= 0) {
    daysToFirstWeekend += 7;
  }

  const firstWorkDay = firstDayInSpan + daysToFirstWorkDay;
  const firstWeekend = firstDayInSpan + daysToFirstWeekend;
  const overlaps = new Array<Overlap>();

  let spanStart = dateKeyFromMonthAndDay(month, firstWorkDay);

  for (let i = firstWeekend; i <= lastDayInSpan; i += 6) {
    const lastFriday = dateKeyFromMonthAndDay(month, i - 1);
    i++;

    const overlap = { periods, span: { start: spanStart, end: lastFriday } };
    overlaps.push(overlap);

    if (i >= lastDayInSpan) {
      return overlaps;
    }

    // next monday
    spanStart = dateKeyFromMonthAndDay(month, i + 1);
  }

  overlaps.push({ periods, span: { start: spanStart, end } });
  return overlaps;
}

function calculateResultsForPeriod(
  overlap: Overlap,
  consultant: Consultant,
  options: PeriodFuncOptions
): InvoiceForecastHourlyResult {
  const absences: Absence[] = overlap.periods.filter(
    (t) => t instanceof Absence
  ) as Absence[];

  const assignments: Assignment[] = overlap.periods.filter(
    (t) => t instanceof Assignment
  ) as Assignment[];

  const signed = assignments.filter((a) => a.phase == ContractPhase.ASSIGNMENT);
  const probable = assignments.filter(
    (a) => a.phase == ContractPhase.PROBABLE_ASSIGNMENT
  );

  const absenceCoverage = sum(absences, "coverage");
  const totalAssignmentCoverage = sum([...signed, ...probable], "coverage");
  const potentialCoverage = Math.max(
    0,
    consultant.coverage - totalAssignmentCoverage - absenceCoverage
  );

  const coverageBySigned = new Map<Assignment, number>();
  const coverageByProbable = new Map<Assignment, number>();

  for (const s of signed) {
    coverageBySigned.set(s, s.coverage);
  }

  for (const p of probable) {
    coverageByProbable.set(p, p.coverage);
  }

  const toRemoveFromUnassigned = consultant.coverage - totalAssignmentCoverage;
  let remainder = Math.max(0, absenceCoverage - toRemoveFromUnassigned);

  if (probable.length > 0) {
    const toRemovePerProbable = remainder / probable.length;
    remainder = reduceCoverage(coverageByProbable, toRemovePerProbable);
  }

  if (signed.length > 0) {
    const toRemovePerSigned = remainder / signed.length;
    remainder = reduceCoverage(coverageBySigned, toRemovePerSigned);
  }

  const hourlyRate = sumByFunc(
    coverageBySigned,
    ([a, coverage]) => (a.hourlyRate * coverage) / 100
  );

  const probableHourlyRate = sumByFunc(
    coverageByProbable,
    ([p, coverage]) => (p.hourlyRate * coverage) / 100
  );

  const { averageHourlyRate, averageHoursToInvoicePerDayInMonth } = options;
  const hoursToInvoice =
    averageHoursToInvoicePerDayInMonth * countDaysInSpan(overlap.span);
  const bill = hourlyRate * hoursToInvoice;
  const probableBill = probableHourlyRate * hoursToInvoice;
  const potentialBill =
    averageHourlyRate * hoursToInvoice * (potentialCoverage / 100);

  return {
    signed: bill,
    probable: probableBill,
    potential: potentialBill,
  };
}

/**
 *
 * Takes a map of coverage by Assignment, and reduces the coverage of each Assignment by a given amount.
 *
 * Assignments with no remaining coverage will be removed from the map.
 *
 * If an Assignment was out of coverage and removed, the remaining coverage that couldn't be subtracted
 * will instead be subtracted from remaining Assignments divided equally.
 *
 * If all Assignments are out of coverage and removed, all remaining coverage that couldn't be subtracted will be summed up and returned.
 *
 * @param coverageByAssignment a map of coverage by Assignment.
 * @param toRemovePerAssignment the amount of coverage that ought to be removed from each Assignment due to absence.
 * @returns the remainder of coverage that couldn't be subtracted from the given assignments.
 */
function reduceCoverage(
  coverageByAssignment: Map<Assignment, number>,
  toRemovePerAssignment: number
): number {
  let remainder = 0;

  do {
    for (const [assignment, coverage] of coverageByAssignment) {
      // if coverage is less than or equal to what should be subtracted from absences,
      // the coverage will be removed from the map and effectively from the span altogether
      if (coverage <= toRemovePerAssignment) {
        remainder += toRemovePerAssignment - coverage;
        coverageByAssignment.delete(assignment);
      } else {
        coverageByAssignment.set(assignment, coverage - toRemovePerAssignment);
      }
    }

    if (coverageByAssignment.size > 0) {
      toRemovePerAssignment = remainder / coverageByAssignment.size;
      remainder = 0;
    }
  } while (toRemovePerAssignment > 0 && coverageByAssignment.size > 0);

  return remainder;
}
