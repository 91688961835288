import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { KeyTo } from "@/types/KeyTo";
import { TableData } from "./TableData";

type DependentEntry = {
  model: Model<unknown>;
  name: string;
};

export enum DeletePolicy {
  NoWarning,
  WithWarning,
  NeverDelete,
}

/**
 * Defines a one-to-many relation from one table to a related table;
 */
export interface ITableDataRelation {
  isDependency(id: number): boolean;
  getDependentNames(id: number): string;
  getDependentModels(id: number): Model<unknown>[];
  getDependentEntries(id: number): DependentEntry[];
  deletePolicy: DeletePolicy;
  get title(): string;
}

export class TablePointer<M extends Model<D>, D extends Displayable<M>>
  implements ITableDataRelation
{
  constructor(
    public data: TableData<M, D>,
    public idKey: KeyTo<M, number> | KeyTo<M, number | null>,
    public deletePolicy: DeletePolicy,
    public getName: (model: M) => string = (model: M) => model.getName()
  ) {}

  public isDependency(id: number) {
    return this.data.some(this.idKey as KeyTo<M, number>, id);
  }

  /**
   * @param id
   * @returns the name of all related entries in a comma-separated list
   */
  public getDependentNames(id: number) {
    const set = new Set<string>();

    for (const row of this.data.findMany(this.idKey as KeyTo<M, number>, id)) {
      set.add(this.getName(row));
    }

    return Array.from(set).join(", ");
  }

  public getDependentModels(id: number) {
    return this.data.findMany(this.idKey as KeyTo<M, number>, id);
  }

  public getDependentEntries(id: number): DependentEntry[] {
    return this.data
      .findMany(this.idKey as KeyTo<M, number>, id)
      .map(this.makeEntry);
  }

  private makeEntry(model: M) {
    return {
      model,
      name: this.getName(model),
    };
  }

  public get title(): string {
    return this.data.title;
  }
}
