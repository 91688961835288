import { CertificateData } from "@/store/data/CertificateData";
import { ConsultantCertificateData } from "@/store/data/ConsultantCertificateData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Certificate } from "../Certificate";
import { Consultant } from "../Consultant";
import { ConsultantCertificate } from "../ConsultantCertificate";
import { CertificateDisplayable } from "./CertificateDisplayable";
import { ConsultantDisplayable } from "./ConsultantDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DateField } from "./fields/DateField";
import { DropdownField } from "./fields/DropdownField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldDateNotAfterToday } from "./fields/validators/FieldDateNotAfterToday";
import { FieldDateNotBefore } from "./fields/validators/FieldDateNotBefore";
import { FieldUniqueConsultantCertificate } from "./fields/validators/FieldUniqueConsultantCertificate";

export class ConsultantCertificateDisplayable extends DisplayableBase<ConsultantCertificate> {
  @ValidateNested()
  consultant: DropdownField<Consultant, ConsultantDisplayable>;

  @ValidateNested()
  @FieldUniqueConsultantCertificate()
  certificate: DropdownField<Certificate, CertificateDisplayable>;

  @ValidateNested()
  @FieldDateNotAfterToday()
  @FieldDateNotBefore("2000-01-01")
  date: DateField;

  constructor(consultantCertificate: ConsultantCertificate) {
    super();
    const consultantCertificateData = container.resolve(
      ConsultantCertificateData
    );
    const { headers } = consultantCertificateData;
    const consultantData = container.resolve(ConsultantData);
    const certificateData = container.resolve(CertificateData);

    this.consultant = new DropdownField(
      headers.consultant,
      consultantCertificate.getName(),
      consultantData,
      new DropdownOption(
        consultantCertificate.consultantId,
        consultantCertificate.consultantName
      ),
      {
        enabledWhen: () => false,
      }
    );

    this.certificate = new DropdownField(
      headers.certificate,
      consultantCertificate.certificateName,
      certificateData,
      new DropdownOption(
        consultantCertificate.certificateId,
        consultantCertificate.certificateName
      ),
      {
        enabledWhen: () => false,
      }
    );

    this.date = new DateField(headers.date, consultantCertificate.date);
  }

  toModel(consultantCertificate: ConsultantCertificate): void {
    consultantCertificate.consultantId = this.consultant.selectedOption.id;
    consultantCertificate.certificateId = this.certificate.selectedOption.id;
    consultantCertificate.date = this.date.modelValue();
  }
}
