import { AssignmentFilterType } from "@/coveragePeriodReport/enum/AssignmentFilterType";
import { SortingMode } from "@/coveragePeriodReport/enum/SortingMode";
import { DateUtils } from "@/util/DateUtils";
import { container } from "tsyringe";

const ID_NONE = -1;
const DefaultUserSetting = {
  userId: ID_NONE,
  corporationId: ID_NONE,
  consultantGroupId: ID_NONE,
  priorityId: ID_NONE,
  corporationSetting: {
    corporationId: ID_NONE,
  },
};

export const DefaultReportSetting = {
  options: {
    mainFilter: AssignmentFilterType.SHOW_ALL,
    customerFilter: AssignmentFilterType.SHOW_ALL,
    taskmasterFilter: AssignmentFilterType.SHOW_ALL,
    consultantGroupFilter: AssignmentFilterType.SHOW_ALL,
    phaseFilter: AssignmentFilterType.SHOW_ALL,
    serviceFilter: AssignmentFilterType.SHOW_ALL,
    locationFilter: AssignmentFilterType.SHOW_ALL,
    corporationFilter: AssignmentFilterType.SHOW_ALL,
    enableSortingGroups: false,
    canExcludeTotalAbsence: true,
    canExcludeInternship: false,
    canExcludeNonBillable: false,
    hideEntriesWithFiles: false,
  },

  //misc
  primarySort: undefined,
  secondarySort: SortingMode.CONSULTANT,
  monthsAhead: 3,
  _startDate: container.resolve(DateUtils).getStartDate(),
  _endDate: container
    .resolve(DateUtils)
    .getEndDate(container.resolve(DateUtils).getStartDate(), 3),
};

export default DefaultUserSetting;
