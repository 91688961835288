import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { Recruitment } from "@/models/Recruitment";

export class YearFilterExtension<T extends Model<D>, D extends Displayable<T>> {
  private _getYears: (rows: T[]) => number[];

  public get getYears(): (rows: T[]) => number[] {
    return this._getYears;
  }

  constructor(getYears: (rows: T[]) => number[]) {
    this._getYears = getYears;
  }

  public static getYearsFromRecruitments(rows: Recruitment[]): number[] {
    const years = new Set<number>();
    for (const row of rows) {
      years.add(new Date(row.getDisplayable().period.value).getFullYear());
    }
    return Array.from(years).sort();
  }
}
