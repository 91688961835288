<template>
  <div class="container">
    <BaseTable
      :data="state.userData"
      :canActivate="true"
      :options="options"
      :user="true"
      :grouped="true"
    />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import { VeaState } from "@/store";
import { UserOptions } from "@/store/data/tableDataOptions/UserOptions";
import { useStore } from "vuex";

defineOptions({
  name: "UsersTable",
});
const state = useStore<VeaState>().state;
const options = new UserOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}
</style>
