<template>
  <div class="outer-container" @click="clearSelector()">
    <div class="container">
      <div class="title-section">
        <h1 cy-data="assignment-overview">Assignment Overview</h1>
      </div>
      <div class="options-container">
        <div class="options">
          <div class="filter">
            <div class="select-container">
              <!-- Binds to a data value in the data driver that determines ConsultantGroup filtering rules. -->
              <div class="filter-box">
                <label for="corporations"><strong>Company</strong></label>
                <select
                  id="corporations"
                  v-model.number="reportData.options.corporationFilter"
                  @change="reportData.refilterByCorporation()"
                >
                  <option :value="AssignmentFilterType.SHOW_ALL">
                    &lt;All&gt;
                  </option>
                  <option
                    v-for="c in reportData.corporations"
                    :key="c.corporationId"
                    :value="c.corporationId"
                  >
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <!-- Binds to a data value in the data driver that determines Company filtering rules. -->
              <!-- Binds to a data value in the data driver that determines Location filtering rules. -->
              <div class="filter-box">
                <label for="locations"><strong>Location</strong></label>
                <select
                  id="locations"
                  v-model.number="reportData.options.locationFilter"
                  @change="reportData.refilterByLocation()"
                >
                  <option :value="SHOW_ALL_LOCATIONS">&lt;All&gt;</option>
                  <option
                    v-for="c in reportData.locations"
                    :key="c.locationId"
                    :value="c.locationId"
                  >
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="filter-box">
                <label for="consultant-groups"
                  ><strong>Consultant group</strong></label
                >
                <select
                  id="consultant-groups"
                  v-model.number="reportData.options.consultantGroupFilter"
                  @change="reportData.refilterByConsultantGroup()"
                >
                  <option :value="SHOW_ALL_CONSULTANT_GROUPS">
                    &lt;All&gt;
                  </option>
                  <option
                    v-for="c in reportData.consultantGroups"
                    :key="c.consultantGroupId"
                    :value="c.consultantGroupId"
                  >
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <template v-if="reportData.shouldShowTaskmasters()">
                <div class="filter-box">
                  <label for="taskmasters"><strong>Contact</strong></label>
                  <select
                    id="taskmasters"
                    v-model.number="reportData.options.taskmasterFilter"
                    @change="reportData.refilterByTaskmaster()"
                  >
                    <option :value="AssignmentFilterType.SHOW_ALL">
                      &lt;All&gt;
                    </option>
                    <option
                      v-for="t in reportData.taskmasters"
                      :key="t.taskmasterId"
                      :value="t.taskmasterId"
                    >
                      {{ t.firstname + " " + t.lastname }}
                    </option>
                  </select>
                </div>
              </template>
            </div>
            <div class="select-container">
              <div class="filter-box">
                <label for="group_by"><strong>Group by</strong></label>
                <select
                  id="group_by"
                  v-model.number="reportData.primarySort"
                  @change="reportData.refilter()"
                >
                  <option :value="undefined">&lt;None&gt;</option>
                  <option :value="SortingMode.CONSULTANT">Name</option>
                  <option :value="SortingMode.COMPANY">Company</option>
                  <option :value="SortingMode.EMPLOYMENT_DATE">
                    Employment date
                  </option>
                  <option :value="SortingMode.CUSTOMER">Customer</option>
                  <option :value="SortingMode.LARGEST_CUSTOMER">
                    Largest customer
                  </option>
                  <option :value="SortingMode.LOCATION">Location</option>
                  <option :value="SortingMode.CONSULTANT_GROUP">
                    Consultant group
                  </option>
                  <option :value="SortingMode.SERVICE">Service</option>
                </select>
              </div>
              <div class="filter-box">
                <label for="sort_by"><strong>Sort by</strong></label>
                <select
                  class="report-select"
                  id="sort_by"
                  v-model.number="reportData.secondarySort"
                  @change="reportData.refilter()"
                >
                  <option :value="SortingMode.CONSULTANT">Name</option>
                  <option :value="SortingMode.COMPANY">Company</option>
                  <option :value="SortingMode.AVAILABLE">Availability</option>
                  <option :value="SortingMode.EMPLOYMENT_DATE">
                    Employment date
                  </option>
                  <option :value="SortingMode.CUSTOMER">Customer</option>
                  <option :value="SortingMode.LARGEST_CUSTOMER">
                    Largest customer
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter">
            <div class="report-widget">
              <div class="report-box buttons">
                <button
                  class="report-button"
                  :class="{
                    active: state.btnActive == AssignmentFilterType.SHOW_ALL,
                  }"
                  @click="
                    reportData.coverageMode(),
                      (state.btnActive = AssignmentFilterType.SHOW_ALL)
                  "
                >
                  All
                </button>
                <button
                  class="report-button"
                  :class="{
                    active:
                      state.btnActive == AssignmentFilterType.SHOW_AVAILABLE,
                  }"
                  @click="
                    reportData.availableMode(),
                      (state.btnActive = AssignmentFilterType.SHOW_AVAILABLE)
                  "
                >
                  Available
                </button>
                <button
                  class="report-button"
                  :class="{
                    active:
                      state.btnActive == AssignmentFilterType.SHOW_ASSIGNED,
                  }"
                  @click="
                    reportData.assignmentMode(),
                      (state.btnActive = AssignmentFilterType.SHOW_ASSIGNED)
                  "
                >
                  Assigned
                </button>
              </div>
            </div>
            <div class="radio-container">
              <div class="filter-container">
                <span class="timespan">Timespan</span>
                <!-- Binds to a data value in the data driver that determines time scaling. -->
                <div class="radio-box">
                  <label for="one">
                    <input
                      type="radio"
                      id="one"
                      :value="1"
                      v-model="reportData.monthsAhead"
                      @change="reportData.refilter(), updateLastMonthsAhead()"
                    />
                    <span class="timespanLabel">1</span>
                  </label>
                  <label for="two">
                    <input
                      type="radio"
                      id="two"
                      :value="2"
                      v-model="reportData.monthsAhead"
                      @change="reportData.refilter(), updateLastMonthsAhead()"
                    />
                    <span class="timespanLabel">2</span>
                  </label>
                  <label for="three">
                    <input
                      type="radio"
                      id="three"
                      :value="3"
                      v-model="reportData.monthsAhead"
                      @change="reportData.refilter(), updateLastMonthsAhead()"
                    />
                    <span class="timespanLabel">3</span>
                  </label>
                  <label for="six">
                    <input
                      type="radio"
                      id="six"
                      :value="6"
                      v-model="reportData.monthsAhead"
                      @change="reportData.refilter(), updateLastMonthsAhead()"
                    />
                    <span class="timespanLabel">6</span>
                  </label>
                  <label for="twelve">
                    <input
                      type="radio"
                      id="twelve"
                      :value="12"
                      v-model="reportData.monthsAhead"
                      @change="reportData.refilter(), updateLastMonthsAhead()"
                    />
                    <span class="timespanLabel">12</span>
                  </label>
                  <label for="choose">
                    <input
                      type="radio"
                      id="choose"
                      :value="0"
                      v-model="reportData.monthsAhead"
                      @change="reportData.refilter(), updateDateDefault()"
                    />
                    <span class="timespanLabel">Custom</span>
                  </label>
                </div>
                <div class="custom-box" v-show="reportData.monthsAhead == 0">
                  <input
                    id="startDate"
                    type="date"
                    min="2000-01-01"
                    max="9999-01-01"
                    v-model="state.startDate"
                  />
                  <input
                    id="endDate"
                    type="date"
                    min="2000-01-02"
                    max="9999-01-01"
                    v-model="state.endDate"
                  />
                  <button
                    type="button"
                    class="btn btn-success btn-apply"
                    @click="updateStartEndDates(reportData)"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="report-box buttons">
            <InfoText />
          </div>
          <div class="extra-options" v-if="state.expandFilter">
            <div class="select-container">
              <!-- Only display the Taskmaster dropdown filter if a specific Customer is selected,
            and that Customer has more than one Taskmaster to choose from. -->
              <!-- Binds to a data value in the data driver that determines Taskmaster filtering rules. -->
              <div class="filter-box">
                <!--{{ contractPhaseAndNames() }}-->
                <label for="assignment"><strong>Phase</strong></label>
                <select
                  id="assignment"
                  v-model="reportData.options.phaseFilter"
                  @change="reportData.refilterByPhase()"
                >
                  <option :value="AssignmentFilterType.SHOW_ALL">
                    &lt;All&gt;
                  </option>
                  <option
                    v-for="(v, k) in contractPhaseAndNames()"
                    :key="k"
                    :value="k"
                  >
                    {{ v[0] }}
                  </option>
                </select>
              </div>
              <div class="filter-box">
                <label for="services"><strong>Service</strong></label>
                <select
                  id="services"
                  v-model="reportData.options.serviceFilter"
                  @change="reportData.refilterByService()"
                >
                  <option :value="AssignmentFilterType.SHOW_ALL">
                    &lt;All&gt;
                  </option>
                  <template v-for="s in reportData.services" :key="s.serviceId">
                    <option :value="s.serviceId" v-if="s.name !== 'Internship'">
                      {{ s.name }}
                    </option>
                  </template>
                </select>
              </div>
              <!-- Binds to a data value in the data driver that determines Customer filtering rules. -->
              <div class="filter-box">
                <label for="customers"><strong>Customer</strong></label>
                <select
                  id="customers"
                  v-model="reportData.options.customerFilter"
                  @change="reportData.refilterByCustomer()"
                >
                  <option :value="AssignmentFilterType.SHOW_ALL">
                    &lt;All&gt;
                  </option>
                  <option
                    v-for="c in reportData.customers"
                    :key="c.customerId"
                    :value="c.customerId"
                  >
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="report-form-span">
              <div class="report-form">
                <input
                  id="exclude-absent"
                  type="checkbox"
                  class="report-check"
                  v-model="reportData.options.canExcludeTotalAbsence"
                  @change.prevent="reportData.refilter()"
                />
                <label for="exclude-absent">Exclude total absences </label>
              </div>
              <div class="report-form">
                <input
                  id="exclude-internship"
                  type="checkbox"
                  class="report-check"
                  v-model="reportData.options.canExcludeInternship"
                  @change.prevent="reportData.refilter()"
                />
                <label for="exclude-internship">Exclude internships</label>
              </div>

              <div class="report-form">
                <input
                  id="exclude-nonbillable"
                  type="checkbox"
                  class="report-check"
                  v-model="reportData.options.canExcludeNonBillable"
                  @change.prevent="reportData.refilter()"
                />
                <label for="exclude-nonbillable"
                  >Exclude non billable/internal work</label
                >
              </div>

              <div class="report-form">
                <input
                  id="exclude-files"
                  type="checkbox"
                  class="report-check"
                  v-model="reportData.options.hideEntriesWithFiles"
                  @change.prevent="reportData.refilter()"
                />
                <label for="exclude-files">Hide assignments with files</label>
              </div>
            </div>
          </div>
          <div class="arrow-div" @click="toggleExpandFilter()">
            <div class="arrow-button"></div>
          </div>
        </div>

        <div class="button-container">
          <TableButtonAdd
            v-if="isAuthorizedToEdit"
            @click="action(assignmentData)"
          >
            Add Assignment
          </TableButtonAdd>
          <TableButtonAdd
            v-if="isAuthorizedToEdit"
            @click="action(absenceData)"
          >
            Add Absence
          </TableButtonAdd>
        </div>
      </div>
    </div>

    <BaseModalForm
      :data="assignmentData"
      :action="Action.Add"
      :submitEvent="handleSubmit"
      :field-provider="container.resolve(AssignmentFieldProvider)"
      v-if="assignmentData.isModifying() && state.isLocalFormOrigin"
    />

    <BaseModalForm
      :data="absenceData"
      :action="Action.Add"
      :submitEvent="handleSubmit"
      v-if="absenceData.isModifying() && state.isLocalFormOrigin"
    />

    <BaseModalForm
      :data="consultantData"
      :action="Action.Add"
      :submitEvent="handleSubmit"
      v-if="
        consultantData.isModifying() &&
        !absenceData.isModifying() &&
        !assignmentData.isModifying() &&
        state.isLocalFormOrigin
      "
    />

    <BaseModalDelete
      :data="assignmentData"
      v-if="
        assignmentData.isDeleting() || !assignmentData.hasFinishedDeleting()
      "
      v-on:data-delete="reportData.refreshConsultantReportEntries()"
    />

    <BaseModalDelete
      :data="absenceData"
      v-if="absenceData.isDeleting() || !absenceData.hasFinishedDeleting()"
      v-on:data-delete="reportData.refreshConsultantReportEntries()"
    />

    <TheColorMap :content="colors"></TheColorMap>

    <!-- This is where GanttGenerator inserts the coverage period report -->
    <!-- chart -->
    <div id="chart" :hidden="data.isEmpty()">
      <!-- Row counter -->
      <div id="counter"></div>
      <!-- tooltip on hover consultant entry-->
      <div id="tag"></div>
      <!-- tooltip on hover name -->
      <div id="nameTag"></div>
    </div>
    <!-- selector on click overlap in chart-->
    <div id="selector"></div>

    <GenericProfile
      v-if="assignmentId"
      :id="assignmentId"
      v-on:data-update="reportData.refreshConsultantReportEntries()"
      v-on:files-update="reportData.refreshFileCount(abortController.signal)"
      :data="assignmentData"
      :id-key="'assignmentId'"
      modal-icon="briefcase"
      title="Assignment details"
      :authorization-entry="authorizationManager.editAssignmentDetails"
      :excluded-fields="['name', 'currencyCode', 'filesField']"
      :file-folder="FileFolder.Assignment"
      :url-type="UrlType.Assignment"
      :field-provider="container.resolve(AssignmentFieldProvider)"
      :override-authorization="isOwnAssignment"
    />

    <GenericProfile
      v-if="absenceId"
      :id="absenceId"
      v-on:data-update="reportData.refreshConsultantReportEntries()"
      v-on:files-update="reportData.refreshFileCount(abortController.signal)"
      :data="absenceData"
      :id-key="'absenceId'"
      modal-icon="briefcase-medical"
      title="Absence details"
      :authorization-entry="authorizationManager.editAbsenceDetails"
      :excluded-fields="[]"
    />

    <!-- consultant click popup -->
    <GenericProfile
      v-if="consultantId"
      :id="consultantId"
      v-on:data-update="reportData.refreshConsultantReportEntries()"
      v-on:files-update="reportData.refreshFileCount(abortController.signal)"
      :data="consultantData"
      :id-key="'consultantId'"
      modal-icon="address-card"
      title="Consultant details"
      :authorization-entry="authorizationManager.editConsultantDetails"
      :excluded-fields="['name', 'urlName', 'files']"
      :file-folder="FileFolder.Profile"
      :file-key-field="'userId'"
      :user-id-key-field="'userId'"
      :secondary-data="userData"
      :secondary-data-id-field="'userId'"
      :url-type="UrlType.Consultant"
      :field-provider="container.resolve(ConsultantFieldProvider)"
      :secondary-data-field-provider="container.resolve(UserFieldProvider)"
    />
  </div>

  <div id="spinner">
    <img width="300" alt="Loading spinner" src="/unicus-dandelion.svg" />
  </div>
</template>

<script setup lang="ts">
import user from "@/auth/user";
import { ReportData } from "@/coveragePeriodReport/ReportData";
import ReportFilterOptions from "@/coveragePeriodReport/ReportFilterOptions";
import { AssignmentFilterType } from "@/coveragePeriodReport/enum/AssignmentFilterType";
import { ConsultantGroupFilterType } from "@/coveragePeriodReport/enum/ConsultantGroupFilterType";
import { DiagramColors } from "@/coveragePeriodReport/enum/DiagramColors";
import { LocationFilterType } from "@/coveragePeriodReport/enum/LocationFilterType";
import { SortingMode } from "@/coveragePeriodReport/enum/SortingMode";
import { UrlType } from "@/models/displayable/fields/enum/UrlType";
import { contractPhaseAndNames } from "@/models/enum/ContractPhase";
import { AssignmentFieldProvider } from "@/models/fieldProvider/AssignmentFieldProvider";
import { ConsultantFieldProvider } from "@/models/fieldProvider/ConsultantFieldProvider";
import { UserFieldProvider } from "@/models/fieldProvider/UserFieldProvider";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { SettingsManager } from "@/store/SettingsManager";
import { AbsenceData } from "@/store/data/AbsenceData";
import { AssignmentData } from "@/store/data/AssignmentData";
import BrowserData from "@/store/data/BrowserData";
import { Action } from "@/store/data/enum/Action";
import { MappedColor } from "@/types/MappedColor";
import { DateUtils } from "@/util/DateUtils";
import { EventType } from "@/util/EventEmitter";
import { container } from "tsyringe";
import { computed, onMounted, onUnmounted, onUpdated, reactive } from "vue";
import BaseModalDelete from "./BaseModalDelete.vue";
import BaseModalForm from "./BaseModalForm.vue";
import GenericProfile from "./GenericProfile.vue";
import InfoText from "./InfoText.vue";
import TableButtonAdd from "./TableButtonAdd.vue";
import TheColorMap from "./TheColorMap.vue";
import FileFolder from "./enum/FileFolder";

defineOptions({
  name: "TheAssignmentOverview",
});
const dateUtils = container.resolve(DateUtils);
const isAuthorizedToEdit = container
  .resolve(AuthorizationManager)
  .editCoveragePeriodReport.isAuthorized();
function updateStartEndDates(reportData: ReportData) {
  const endDate = dateUtils
    .addOneDayToDate(dateUtils.stringToDate(state.endDate))
    .toLocaleDateString("sv");
  reportData.startDate = state.startDate;
  reportData.endDate = endDate;
  reportData.refilter();
}
const props = defineProps<{ data: ReportData }>();
const settings = container.resolve(SettingsManager);
const abortController = new AbortController();

onUnmounted(() => abortController?.abort());

onMounted(() => {
  store.state.events.subscribeOrRunIfDispatched(
    EventType.FinishedLoadingTableData,
    onFinishedLoading
  );
});

async function onFinishedLoading() {
  const browserData = new BrowserData();
  await props.data.load(abortController.signal);
  if (abortController.signal.aborted) {
    return;
  }
  if (store.state.browserData.shouldFetchFilters) {
    browserData.shouldChangeFetch = false;
    localStorage.setItem(
      "qHn_-8Df;;y2L7#!p9",
      JSON.stringify(browserData.shouldChangeFetch)
    );
  }
  applyPresetFilters(props.data.options);
  props.data.refilter();
  state.btnActive = props.data.options.mainFilter;
}

function applyPresetFilters(options: ReportFilterOptions) {
  options.corporationFilter = settings.corporationId;
  options.consultantGroupFilter = settings.consultantGroupId;
}

window.onresize = () => props.data.drawGantt();

onUpdated(() => {
  window.onresize = () => props.data.drawGantt();
});

const state = reactive({
  actionableData: undefined as undefined | AssignmentData | AbsenceData,
  btnActive: AssignmentFilterType.SHOW_ALL,
  isLocalFormOrigin: false,
  endDate: new Date(
    dateUtils.getEndDate(props.data._endDate, props.data.monthsAhead)
  ).toLocaleDateString("sv"),
  startDate: new Date(props.data.getStartDate()).toLocaleDateString("sv"),
  monthsAhead: props.data.monthsAhead,
  expandFilter: false,
});

function updateLastMonthsAhead() {
  state.monthsAhead = props.data.monthsAhead;
}

function updateDateDefault() {
  state.startDate = new Date(props.data.getStartDate()).toLocaleDateString(
    "sv"
  );
  state.endDate = new Date(
    dateUtils.getEndDate(
      dateUtils.stringToDate(state.startDate),
      state.monthsAhead
    )
  ).toLocaleDateString("sv");
}

const { assignmentData, absenceData, consultantData, userData } = store.state;

function action(data: AssignmentData | AbsenceData) {
  state.isLocalFormOrigin = true;
  state.actionableData = data;
  state.actionableData.add();
}

function clearSelector() {
  const selector = document.getElementById("selector");
  if (selector && selector.innerHTML) {
    selector.innerHTML = "";
    selector.style.display = "none";
  }
}

function handleSubmit() {
  if (state.actionableData?.hasValidationErrors()) return;
  state.isLocalFormOrigin = false;
  reportData.value.refreshConsultantReportEntries();
}

function toggleExpandFilter() {
  const arrow = document.querySelector(".arrow-button");
  state.expandFilter = !state.expandFilter;
  if (state.expandFilter) {
    arrow?.classList.add("clicked");
  } else {
    arrow?.classList.remove("clicked");
  }
}

function isOwnAssignment(): boolean {
  if (assignmentId.value === null) {
    return false;
  }
  const assignment = assignmentData.findById(assignmentId.value);
  if (!assignment) {
    return false;
  }
  const consultant = consultantData.findById(assignment.consultantId);
  return consultant && consultant.userId == user.state.profile.userId;
}

const authorizationManager = container.resolve(AuthorizationManager);

const colors: MappedColor[] = [
  {
    color: DiagramColors.UNOCCUPIED,
    description: "UNOCCUPIED",
    textBright: false,
  },
  {
    color: DiagramColors.SIGNED_ASSIGNMENT,
    description: "FULLY ASSIGNED",
    textBright: true,
  },
  {
    color: DiagramColors.PROBABLE_ASSIGNMENT,
    description: "PROBABLE ASSIGNMENT",
    textBright: true,
  },
  {
    color: DiagramColors.PARTTIME_ASSIGNMENT,
    description: "PART-TIME ASSIGNMENT",
    textBright: false,
  },
  {
    color: DiagramColors.PROBABLE_PARTTIME_ASSIGNMENT,
    description: "PROBABLE PART-TIME ASSIGNMENT",
    textBright: false,
  },
  {
    color: DiagramColors.ABSENCE,
    description: "ABSENCE",
    textBright: true,
  },
  {
    color: DiagramColors.INTERNSHIP,
    description: "INTERNSHIP",
    textBright: false,
  },
  {
    color: DiagramColors.NON_BILLABLE,
    description: "NON BILLABLE/INTERNAL WORK",
    textBright: true,
  },
  {
    color: DiagramColors.WARNING,
    description: "WARNING",
    textBright: false,
  },
];

const reportData = computed(() => props.data);
const assignmentId = computed(() => store.state.assignmentId);
const absenceId = computed(() => store.state.absenceId);
const consultantId = computed(() => store.state.consultantId);
const SHOW_ALL_CONSULTANT_GROUPS = ConsultantGroupFilterType.SHOW_ALL;
const SHOW_ALL_LOCATIONS = LocationFilterType.SHOW_ALL;
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.btn-apply {
  margin-left: 5px;
}

.tooltip {
  .field {
    text-align: left;
  }
}

.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  @include containertheme($color: $container-grid-color);
  background: none;
  margin-top: 1rem;

  overflow: visible;

  @media (max-width: 1199px) {
    width: 100%;
  }

  .title-section {
    @include title-section;
  }

  .options-container {
    @include baseTableOptionsContainer();
    overflow: visible;
    text-align: center;

    .options {
      @include options($border: 1px, $border-radius: 20px);
      position: relative;
      flex-wrap: wrap;
      overflow: visible;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 800px;

      .filter {
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .arrow-div {
        position: relative;
        margin-top: 5px;
        width: 40px;
        height: 40px;

        .arrow-button {
          $arrow-color: $color-black;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 15px;
          height: 15px;
          border-bottom: 2px solid $arrow-color;
          border-left: 2px solid $arrow-color;
          border-right: 2px solid transparent;
          border-top: 2px solid transparent;
          transform: translate(-50%, -50%) rotate(315deg);
          transition: transform 0.2s;

          &.clicked {
            transform: translate(-50%, 0) rotate(135deg);
          }
        }
      }
    }

    .options {
      margin: 10px;
    }

    .button-container {
      padding-left: 2em;
      display: flex;
      flex-direction: column;
    }

    .report-widget {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      row-gap: 5px;
    }

    .report-button {
      margin-right: 5px;
      border-radius: 5px;
      box-sizing: border-box;
      border: 1px solid;
      background-color: $container-bgc;
      color: $color-black;

      &:not(.active) {
        border-color: $border-color;
      }

      &.active {
        background-color: $vea-primary-color;
        color: $color-white;
      }
    }

    .report-form-span {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .report-form {
      display: flex;
      align-items: center;
      margin-top: 3px;
      margin-left: 15px;

      label {
        margin: 5px;
        font-size: small;
      }
    }

    .report-box:not(.radio):not(.buttons) {
      @include report-box;
    }

    .filter-container {
      .timespan {
        font-weight: bold;
      }

      .radio-box {
        label {
          margin-left: 2px;
          margin-right: 5px;
        }

        .custom-box {
          width: unset;
          padding-right: 10px;
        }
      }
    }

    .report-select {
      width: 150px;
    }

    .buttons {
      margin-right: 30px;
    }

    .filter-box {
      @include sort-box();
      @include form-field;
      width: unset;
      padding-right: 10px;

      strong {
        width: 100%;
        text-align: center;
      }
    }

    .select-container {
      @include select-radio-containers();
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }
  }
}

.timespanLabel {
  margin: 2px;
}

#chart {
  width: fit-content;
  display: inline-block;
  position: relative;
  overflow: visible;

  #counter {
    width: fit-content;
    position: absolute;
    margin-bottom: -20px;
    margin-left: 20px;

    @media (min-width: 1199px) {
      transform: translateX(-65px);
    }

    @media (max-width: 1199px) {
      margin-left: 10px;
    }

    .svg {
      text-align: center;
      overflow: visible;
      max-width: 94vw;
    }
  }
}

#spinner {
  padding-top: 5%;
}

img {
  filter: invert(100%);
  animation-name: rotate;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#tag,
#nameTag {
  pointer-events: none;
}

.grid {
  pointer-events: none;

  .tick {
    stroke: lightgray;
    opacity: 1;
    shape-rendering: crispEdges;

    line {
      opacity: 0.2;
      transform: translateY(-20px);
    }

    text {
      stroke: none;
    }
  }

  path {
    stroke-width: 0;
  }
}

#tag,
#nameTag,
#selector {
  color: $color-black;
  background: $color-white;
  min-width: 150px;
  max-width: 400px;
  position: absolute;
  display: none;
  padding: 3px 6px;
  font-size: 12px;
  transform: translateY(24px);
  border: 1px solid $color-black;
  border-radius: 5px;
}

#tag,
#selector {
  transform: translateY(24px) translateX(-50%);
}

.show-info {
  left: 50%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    transform-origin: 43% 50%;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: 43% 50%;
  }
}

.extra-options {
  align-items: center;
}

input[type="date"] {
  // Actually uses values from form-field mixin but couldn't apply to css.
  border: 1px solid $border-color;
  border-radius: 10px;
  padding-left: 5px;
  margin: 5px;
}
</style>
