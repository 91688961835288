import { Corporation } from "@/models/Corporation";
import { CorporationCustomer } from "@/models/CorporationCustomer";
import { Customer } from "@/models/Customer";
import { CorporationCustomerData } from "@/store/data/CorporationCustomerData";
import { CorporationCustomerDisplayable } from "../../CorporationCustomerDisplayable";
import { CorporationDisplayable } from "../../CorporationDisplayable";
import { CustomerDisplayable } from "../../CustomerDisplayable";
import { FieldUniqueManyToManyConnection } from "./FieldUniqueManyToManyConnection";

export function FieldUniqueCorporationCustomer() {
  return FieldUniqueManyToManyConnection<
    Customer,
    CorporationCustomer,
    Corporation,
    CustomerDisplayable,
    CorporationCustomerDisplayable,
    CorporationDisplayable
  >(
    "customerName",
    "customerId",
    "corporationId",
    () => CorporationCustomerData
  );
}
