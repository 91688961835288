<template>
  <BaseModal
    :title="`Competencies, services and certificates of:  \n${user.value.getName()}`"
    modal-icon="tasks"
    :abort="unsetId"
    :accept="unsetId"
    :aborttitle="'Close'"
    :danger="false"
    class="competencies-modal"
    :class="{ 'fade-out': canFadeOut.value }"
    :disableButton="true"
  >
    <div id="container" v-if="consultant">
      <!-- The html for the coloured bubbles displaying competencies and services -->
      <div
        id="consultant-competencies-and-services"
        v-if="
          consultantKeyCompetencies.length !== 0 ||
          consultantCompetencies.length !== 0 ||
          consultantServices.length !== 0 ||
          consultantCertificates.length !== 0
        "
      >
        <button
          class="consultant-keycompetency"
          v-for="competency in consultantKeyCompetencies"
          :key="competency.consultantCompetencyId"
          @click="
            deleteConsultantCompetencyRow(competency.consultantCompetencyId)
          "
        >
          <div>
            {{ competency.competencyName }}
            <fa-icon icon="times" class="del" />
          </div>
        </button>
        <button
          class="consultant-competency"
          v-for="competency in consultantCompetencies"
          :key="competency.consultantCompetencyId"
          @click="
            deleteConsultantCompetencyRow(competency.consultantCompetencyId)
          "
        >
          <div>
            {{ competency.competencyName }}
            <fa-icon icon="times" class="del" />
          </div>
        </button>
        <button
          class="consultant-service"
          v-for="service in consultantServices"
          :key="service.consultantServiceId"
          @click="deleteConsultantService(service.consultantServiceId)"
        >
          <div>
            {{ service.serviceName }}
            <fa-icon icon="times" class="del" />
          </div>
        </button>

        <div
          class="certificate-container"
          v-for="certificate in consultantCertificates"
          :key="certificate.consultantCertificateId"
        >
          <button
            class="consultant-certificate btn-left"
            @click="editCertificateDate(certificate.consultantCertificateId)"
          >
            <div>
              {{ certificate.date }}
            </div>
          </button>
          <button
            class="consultant-certificate btn-right"
            @click="
              deleteConsultantCertificate(certificate.consultantCertificateId)
            "
          >
            <div>
              {{ certificate.certificateName }}
              <fa-icon icon="times" class="del" />
            </div>
          </button>
        </div>
        <div
          class="background-container btn-group"
          v-for="background in backgrounds"
          :key="background.backgroundId"
        >
          <button
            class="consultant-background btn-left btn"
            @click="editBackground(background.backgroundId)"
          >
            <div>{{ background.startDate }} – {{ background.endDate }}</div>
            <div>
              {{ background.where }}
            </div>
          </button>
          <button
            class="consultant-background btn-right btn"
            @click="deleteBackground(background.backgroundId)"
          >
            <fa-icon icon="times" class="del" />
          </button>
        </div>
      </div>
      <div v-else>This consultant has no competencies assigned.</div>
      <table id="comp-aligner">
        <tbody>
          Competencies
          <tr>
            <td>
              <DisplayableDropdown
                :label="'New Competency'"
                :field="competencyDropdown"
                :key="state.competencyDropdownUpdateKey.check()"
                @input-updated="
                  refreshCompetencyButtonState(),
                    state.competencyDropdownUpdateKey.refresh()
                "
                @button-clicked="addCompetency()"
                @add-filter-button-clicked="addCompetencyCategory()"
              />
              <div>
                <input
                  class="checkbox"
                  type="checkbox"
                  id="compenency-checkbox"
                  v-model="state.isKey"
                />
                &nbsp;<small>Assign as Key Competency</small>
              </div>
              <button
                id="add-button"
                :disabled="state.disableCompetencyBtn"
                @click="
                  addConsultantCompetency().then(refreshCompetencyButtonState)
                "
              >
                Assign Competency
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <table id="service-aligner">
        <tbody>
          Services
          <tr>
            <td>
              <DisplayableDropdown
                :label="'New Service'"
                :field="serviceDropdown"
                :key="state.serviceDropdownUpdateKey.check()"
                @input-updated="refreshServiceButtonState()"
                @button-clicked="addService()"
              />
            </td>
            <td>
              <button
                id="add-button"
                :disabled="state.disableServiceBtn"
                @click="addConsultantService().then(refreshServiceButtonState)"
              >
                Assign Service
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <table id="cert-aligner">
        <tbody>
          Certificates
          <tr>
            <td>
              <DisplayableDropdown
                :label="'New Certificate'"
                :field="certificateDropdown"
                :key="state.certificateDropdownUpdateKey.check()"
                @input-updated="refreshCertificateButtonState()"
                @button-clicked="addCertificate()"
              />
            </td>
            <td>
              <div>
                <input
                  id="certificate-date"
                  type="date"
                  :min="minDate"
                  :max="maxDate"
                  v-model="state.certificateDate"
                  :class="{ invalid: isInvalidCertificateDate() }"
                />
                <!-- &nbsp;<small>Certificate date</small> -->
              </div>
              <button
                id="add-button"
                :disabled="state.disableCertificateBtn"
                @click="
                  addConsultantCertificate().then(refreshCertificateButtonState)
                "
              >
                Assign Certificate
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <table id="background-aligner">
        <tbody>
          Backgrounds
          <tr>
            <td>
              <label for="background-category-select">Category </label>
            </td>
            <td>
              <select
                v-model="state.backgroundCategory"
                id="background-category-select"
              >
                <option
                  v-for="background in Object.values(BackgroundCategory)"
                  :value="background"
                  :key="background"
                >
                  {{ background }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label for="background-text-input-where">Where </label>
            </td>
            <td>
              <input
                v-model="state.backgroundWhere"
                type="text"
                id="background-text-input-where"
                maxlength="50"
                :placeholder="backgroundWherePlaceholderText"
                :class="{ invalid: isInvalidBackgroundWhere() }"
              />
              <br />
              <label
                id="background-where-length-indicator"
                class="max-length-label label"
              >
                {{ state.backgroundWhere.length }} / 50
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <label for="background-text-input-what"> What</label>
            </td>
            <td>
              <textarea
                id="background-text-input-what"
                v-model="state.backgroundWhat"
                maxlength="200"
                :placeholder="backgroundWhatPlaceholderText"
              >
              </textarea>
              <br />
              <label
                id="background-what-length-indicator"
                class="max-length-label label"
              >
                {{ state.backgroundWhat.length }} / 200
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <label for="background-start-date"> Start date </label>
            </td>
            <td>
              <input
                id="background-start-date"
                type="date"
                :min="minDate"
                :max="maxDate"
                v-model="state.backgroundStartDate"
                :class="{ invalid: isInvalidBackgroundStartDate() }"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label for="background-end-date"> End date </label>
            </td>
            <td>
              <input
                id="background-end-date"
                type="date"
                :min="minDate"
                :max="maxDate"
                v-model="state.backgroundEndDate"
                :class="{ invalid: isInvalidBackgroundEndDate() }"
              />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <button
                id="add-background-button"
                :disabled="disableBackgroundBtn"
                @click="addBackground()"
              >
                Assign Background
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </BaseModal>
  <BaseModalForm
    v-if="competencyData.isModifying()"
    :data="competencyData"
    :action="Action.Add"
    :submitEvent="onFormSubmitCompetencies"
    :updateDropdownEvent="
      (d) => updateDropdown(d, state.competencyDropdownUpdateKey)
    "
  />
  <BaseModalForm
    v-if="competencyCategoryData.isModifying()"
    :data="competencyCategoryData"
    :action="Action.Add"
    :submitEvent="onFormSubmitCompetencyCategories"
  />
  <BaseModalForm
    v-if="serviceData.isModifying()"
    :data="serviceData"
    :action="Action.Add"
    :submitEvent="onFormSubmitServices"
    :updateDropdownEvent="
      (d) => updateDropdown(d, state.serviceDropdownUpdateKey)
    "
  />
  <BaseModalForm
    v-if="certificateData.isModifying()"
    :data="certificateData"
    :action="Action.Add"
    :submitEvent="onFormSubmitCertificates"
    :updateDropdownEvent="
      (d) => updateDropdown(d, state.certificateDropdownUpdateKey)
    "
  />
  <BaseModalForm
    v-if="consultantCertificateData.isModifying()"
    :data="consultantCertificateData"
    :action="Action.Edit"
    :submitEvent="() => {}"
    :updateDropdownEvent="
      () =>
        updateDropdown(certificateDropdown, state.certificateDropdownUpdateKey)
    "
  />
  <BaseModalForm
    v-if="backgroundData.isModifying()"
    :data="backgroundData"
    :action="Action.Edit"
    :submitEvent="() => {}"
  />
  <BaseModalDelete
    :data="consultantCompetencyData"
    v-if="consultantCompetencyData.isDeleting()"
  />
  <BaseModalDelete
    :data="consultantServiceData"
    v-if="consultantServiceData.isDeleting()"
  />
  <BaseModalDelete
    :data="consultantCertificateData"
    v-if="consultantCertificateData.isDeleting()"
  />
  <BaseModalDelete :data="backgroundData" v-if="backgroundData.isDeleting()" />
</template>

<script setup lang="ts">
import { Displayable } from "@/interfaces/Displayable";
import { Dropdown } from "@/interfaces/Dropdown";
import { Model } from "@/interfaces/Model";
import { Background, BackgroundCategory } from "@/models/Background";
import { Certificate } from "@/models/Certificate";
import { Competency } from "@/models/Competency";
import { ConsultantCertificate } from "@/models/ConsultantCertificate";
import { ConsultantCompetency } from "@/models/ConsultantCompetency";
import { ConsultantService } from "@/models/ConsultantService";
import Service from "@/models/Service";
import { CertificateDisplayable } from "@/models/displayable/CertificateDisplayable";
import { CompetencyDisplayable } from "@/models/displayable/CompetencyDisplayable";
import { ServiceDisplayable } from "@/models/displayable/ServiceDisplayable";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "@/models/displayable/fields/DropdownField";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { TableData } from "@/store/data/TableData";
import { Action } from "@/store/data/enum/Action";
import UpdateKey, { IUpdateKey } from "@/store/data/types/UpdateKey";
import { DateKey, today } from "@/types/DateKey";
import { Either } from "@/types/Either";
import { KeyTo } from "@/types/KeyTo";
import { setProp } from "@/types/getProp";
import { container } from "tsyringe";
import { computed, reactive } from "vue";
import BaseModal from "./BaseModal.vue";
import BaseModalDelete from "./BaseModalDelete.vue";
import BaseModalForm from "./BaseModalForm.vue";
import DisplayableDropdown from "./displayable/DisplayableDropdown.vue";

const props = defineProps<{
  userId: number;
}>();

const state = reactive({
  isKey: false,
  disableCertificateBtn: true,
  disableCompetencyBtn: true,
  disableServiceBtn: true,
  disableBackgroundBtn: true,
  certificateDropdownUpdateKey: new UpdateKey(),
  competencyDropdownUpdateKey: new UpdateKey(),
  competencyCategoryDropdownUpdateKey: new UpdateKey(),
  serviceDropdownUpdateKey: new UpdateKey(),
  backgroundWhere: "",
  backgroundWhat: "",
  backgroundCategory: BackgroundCategory.Eductation,
  backgroundStartDate: "2010-01-01" as DateKey,
  backgroundEndDate: "2020-01-01" as DateKey,
  certificateDate: today(),
});

function deleteConsultantCompetencyRow(rowId: number) {
  consultantCompetencyData.delete(rowId);
  updateDropdown(competencyDropdown.value, state.competencyDropdownUpdateKey);
}

function deleteConsultantService(rowId: number) {
  consultantServiceData.delete(rowId);
  updateDropdown(serviceDropdown.value, state.serviceDropdownUpdateKey);
}

function deleteConsultantCertificate(rowId: number) {
  consultantCertificateData.delete(rowId);
  updateDropdown(certificateDropdown.value, state.certificateDropdownUpdateKey);
}

function deleteBackground(rowId: number) {
  backgroundData.delete(rowId);
}

function editCertificateDate(rowId: number) {
  consultantCertificateData.edit(rowId);
}

function editBackground(rowId: number) {
  backgroundData.edit(rowId);
}

async function updateDropdown<M extends Model<D>, D extends Displayable<M>>(
  dropdown: DropdownField<M, D>,
  updateKey: IUpdateKey
) {
  await dropdown.updateData();
  dropdown.selectedOption = new DropdownOption(0, "");
  updateKey.refresh();
}

async function onFormSubmit<M extends Model<D>, D extends Displayable<M>>(
  data: TableData<M, D>,
  dropdown: DropdownField<M, D>,
  updateKey: IUpdateKey,
  refreshButtonState: () => Promise<void>
) {
  if (data.hasValidationErrors()) {
    return;
  }

  await data.getRequest();
  let modelId = 0;
  for (const model of data.rows) {
    if (model.getId() > modelId) {
      modelId = model.getId();
    }
  }
  await dropdown.updateData();
  dropdown.generateOptions();
  updateKey.refresh();
  const model = data.findById(modelId);
  dropdown.selectedOption = new DropdownOption<string>(
    model.getId(),
    model.getName()
  );
  refreshButtonState();
}

async function onFormSubmitFilter<M extends Model<D>, D extends Displayable<M>>(
  data: TableData<M, D>,
  dropdown: Dropdown,
  updateKey: IUpdateKey,
  refreshButtonState: () => Promise<void>
) {
  if (data.hasValidationErrors()) {
    return;
  }

  await data.getRequest();
  let modelId = 0;
  for (const model of data.rows) {
    if (model.getId() > modelId) {
      modelId = model.getId();
    }
  }
  await dropdown.updateFilterData();
  dropdown.generateOptions();
  updateKey.refresh();
  const model = data.findById(modelId);
  dropdown.selectedFilter = new DropdownOption<string>(
    model.getId(),
    model.getName()
  );
  refreshButtonState();
}

async function onFormSubmitCompetencies() {
  await onFormSubmit(
    competencyData,
    competencyDropdown.value,
    state.competencyDropdownUpdateKey,
    refreshCompetencyButtonState
  );
}

async function onFormSubmitCompetencyCategories() {
  await onFormSubmitFilter(
    competencyCategoryData,
    competencyDropdown.value,
    state.competencyDropdownUpdateKey,
    refreshCompetencyButtonState
  );
}

async function onFormSubmitServices() {
  await onFormSubmit(
    serviceData,
    serviceDropdown.value,
    state.serviceDropdownUpdateKey,
    refreshServiceButtonState
  );
}

async function onFormSubmitCertificates() {
  await onFormSubmit(
    certificateData,
    certificateDropdown.value,
    state.certificateDropdownUpdateKey,
    refreshCertificateButtonState
  );
}

async function refreshCompetencyButtonState() {
  state.disableCompetencyBtn = competencyDropdown.value.selectedOption.id === 0;
}

async function refreshServiceButtonState() {
  state.disableServiceBtn = serviceDropdown.value.selectedOption.id === 0;
}

async function refreshCertificateButtonState() {
  state.disableCertificateBtn =
    certificateDropdown.value.selectedOption.id === 0;
}

const disableBackgroundBtn = computed(
  () =>
    state.backgroundWhere == "" ||
    isInvalidBackgroundWhere() ||
    isInvalidBackgroundStartDate() ||
    isInvalidBackgroundEndDate()
);

const backgroundWherePlaceholderText = computed(() => {
  switch (state.backgroundCategory) {
    case BackgroundCategory.Eductation:
      return "School, University, etc.";
    case BackgroundCategory.Work:
      return "Employer";
    case BackgroundCategory.Other:
      return "Organization";
    default:
      return "";
  }
});

const backgroundWhatPlaceholderText = computed(() => {
  switch (state.backgroundCategory) {
    case BackgroundCategory.Eductation:
      return "Programs, Courses";
    case BackgroundCategory.Work:
      return "Position, Responsibilities";
    case BackgroundCategory.Other:
      return "";
    default:
      return "";
  }
});

async function addConsultantCompetency(competency?: Competency) {
  if (competencyDropdown.value.selectedOption.id === 0) {
    return;
  }
  await addData(
    consultantCompetencyData,
    {
      isKey: state.isKey,
      consultantId: consultant.value?.consultantId,
      firstName: user.value.firstname,
      lastName: user.value.lastname,
    },
    {
      dropdownOrItem: competency
        ? { item: competency }
        : { dropdown: competencyDropdown.value },
      idKey: "competencyId",
      nameKey: "competencyName",
    }
  );
  await updateDropdown(
    competencyDropdown.value,
    state.competencyDropdownUpdateKey
  );
}

function addCompetency() {
  competencyData.add();
}

function addCompetencyCategory() {
  competencyCategoryData.add();
}

//Adds a service to the consultant, called after the user presses the "Assign Service button"
async function addConsultantService(service?: Service) {
  if (serviceDropdown.value.selectedOption.id === 0) {
    return;
  }
  await addData(
    consultantServiceData,
    {
      consultantName: user.value.getName(),
      consultantId: consultant.value?.consultantId,
    },
    {
      dropdownOrItem: service
        ? { item: service }
        : { dropdown: serviceDropdown.value },
      idKey: "serviceId",
      nameKey: "serviceName",
    }
  );
  await updateDropdown(serviceDropdown.value, state.serviceDropdownUpdateKey);
}

function addService() {
  serviceData.add();
}

async function addConsultantCertificate(certificate?: Certificate) {
  if (certificateDropdown.value.selectedOption.id === 0) {
    return;
  }

  if (state.certificateDate > maxDate || state.certificateDate < minDate) {
    return;
  }

  await addData(
    consultantCertificateData,
    {
      consultantId: consultant.value?.consultantId,
      consultantName: consultant.value?.getName(),
      date: state.certificateDate,
    },
    {
      dropdownOrItem: certificate
        ? { item: certificate }
        : { dropdown: certificateDropdown.value },
      idKey: "certificateId",
      nameKey: "certificateName",
    }
  );
  await updateDropdown(
    certificateDropdown.value,
    state.certificateDropdownUpdateKey
  );
}

async function addBackground() {
  if (isInvalidBackgroundWhere() || state.backgroundWhere == "") {
    return;
  }

  if (isInvalidBackgroundStartDate() || isInvalidBackgroundEndDate()) {
    return;
  }

  if (!consultant.value) {
    return;
  }

  await addData(backgroundData, {
    where: state.backgroundWhere,
    what: state.backgroundWhat,
    consultantId: consultant.value.consultantId,
    consultantName: consultant.value.getName(),
    startDate: state.backgroundStartDate,
    endDate: state.backgroundEndDate,
  });

  state.backgroundWhere = "";
  state.backgroundWhat = "";
}

function isInvalidCertificateDate() {
  const isValid =
    state.certificateDate <= maxDate && state.certificateDate >= minDate;

  if (isValid) {
    refreshCertificateButtonState();
  } else {
    state.disableCertificateBtn = !isValid;
  }

  return !isValid;
}

function isInvalidDate(date: DateKey): boolean {
  const isValid = date <= maxDate && date >= minDate;
  return !isValid;
}

function isInvalidBackgroundEndDate(): boolean {
  return (
    isInvalidDate(state.backgroundEndDate) ||
    state.backgroundEndDate <= state.backgroundStartDate
  );
}

function isInvalidBackgroundStartDate(): boolean {
  return isInvalidDate(state.backgroundStartDate);
}

function isInvalidBackgroundWhere(): boolean {
  return (
    consultant.value === undefined ||
    store.state.backgroundData.some<string | number>(
      ["consultantId", consultant.value.consultantId],
      ["where", state.backgroundWhere]
    )
  );
}

function addCertificate() {
  certificateData.add();
}

async function addData<
  M extends Model<D>,
  D extends Displayable<M>,
  M1 extends Model<D1>,
  D1 extends Displayable<M1>
>(
  data: TableData<M, D>,
  misc: Partial<M>,
  nameAndIdOptions?: {
    idKey: KeyTo<M, number>;
    nameKey: KeyTo<M, string>;
    dropdownOrItem: Either<{ item: M1 }, { dropdown: DropdownField<M1, D1> }>;
  }
) {
  if (adding) return;
  adding = true;
  data.add();

  const active = data.activeRow;
  if (!active) {
    return;
  }

  if (nameAndIdOptions) {
    const { dropdownOrItem, idKey, nameKey } = nameAndIdOptions;
    if (dropdownOrItem.dropdown !== undefined) {
      setProp(active, idKey, dropdownOrItem.dropdown.selectedOption.id);
      setProp(active, nameKey, dropdownOrItem.dropdown.selectedOption.label);
    } else {
      setProp(active, idKey, dropdownOrItem.item.getId());
      setProp(active, nameKey, dropdownOrItem.item.getName());
    }
  }

  for (const key in misc) {
    const val = misc[key];
    if (val) {
      active[key] = val;
    }
  }

  await data.saveChanges(active.getDisplayable());
  await data.finished;
  adding = false;
}

let adding = false;
const canFadeOut = reactive({ value: false });

const emits = defineEmits(["closed"]);

function unsetId(delay = 300) {
  canFadeOut.value = true;
  setTimeout(() => (store.state.competenciesViewUserId = null), delay);
  emits("closed");
}

const minDate: DateKey = "2000-01-01";
const maxDate = today();

const {
  consultantCompetencyData,
  competencyData,
  userData,
  consultantData,
  serviceData,
  consultantServiceData,
  certificateData,
  consultantCertificateData,
  competencyCategoryData,
  backgroundData,
} = store.state;

const user = reactive({
  value: userData.findById(props.userId),
});

const consultant = computed(() => consultantData.find("userId", props.userId));

function consultantCompetencyFilter(currentOption: DropdownOption<string>) {
  return !consultantCompetencyData.some(
    ["competencyId", currentOption.id],
    ["consultantId", consultant.value?.consultantId]
  );
}

function getConsultantCompetencies(k: boolean): ConsultantCompetency[] {
  return consultantCompetencyData.rows
    .filter(
      (consultantCompetency) =>
        consultantCompetency.consultantId == consultant.value?.consultantId &&
        consultantCompetency.isKey == k
    )
    .sort((a, b) => a.competencyName.localeCompare(b.competencyName));
}

function getConsultantServices(): ConsultantService[] {
  return consultantServiceData
    .findMany("consultantId", consultant.value?.consultantId)
    .sort((a, b) => a.serviceName.localeCompare(b.serviceName));
}

function getConsultantCertificates(): ConsultantCertificate[] {
  return consultantCertificateData
    .findMany("consultantId", consultant.value?.consultantId)
    .sort((a, b) => a.certificateName.localeCompare(b.certificateName));
}

function getBackgrounds(): Background[] {
  return backgroundData
    .findMany("consultantId", consultant.value?.consultantId)
    .sort((a, b) => a.where.localeCompare(b.where));
}

function getCompetencyDropdown(): DropdownField<
  Competency,
  CompetencyDisplayable
> {
  return new DropdownField(
    "Competency",
    "Bla",
    competencyData,
    new DropdownOption(0, ""),
    {
      optionsFilter: consultantCompetencyFilter,
      selectionFilter: new DropdownFieldSelectionFilter(
        competencyCategoryData,
        "competencyCategoryId",
        "categoryName"
      ),
      isCompoundType: container
        .resolve(AuthorizationManager)
        .editBaseTable.isAuthorized(),
    }
  );
}

function consultantServiceFilter(currentOption: DropdownOption<string>) {
  return !consultantServiceData.some(
    ["serviceId", currentOption.id],
    ["consultantId", consultant.value?.consultantId]
  );
}

function getServiceDropdown(): DropdownField<Service, ServiceDisplayable> {
  return new DropdownField(
    "Service",
    "bla",
    serviceData,
    new DropdownOption(0, ""),
    {
      optionsFilter: consultantServiceFilter,
      isCompoundType: container
        .resolve(AuthorizationManager)
        .editBaseTable.isAuthorized(),
    }
  );
}

function consultantCertificateFilter(currentOption: DropdownOption<string>) {
  return !consultantCertificateData.some(
    ["certificateId", currentOption.id],
    ["consultantId", consultant.value?.consultantId]
  );
}

function getCertificateDropdown(): DropdownField<
  Certificate,
  CertificateDisplayable
> {
  return new DropdownField(
    "Certificate",
    "Bla",
    certificateData,
    new DropdownOption(0, ""),
    {
      optionsFilter: consultantCertificateFilter,
      isCompoundType: container
        .resolve(AuthorizationManager)
        .editBaseTable.isAuthorized(),
    }
  );
}

const consultantKeyCompetencies = computed(() =>
  getConsultantCompetencies(true)
);
const consultantCompetencies = computed(() => getConsultantCompetencies(false));
const competencyDropdown = computed(() => getCompetencyDropdown());
const serviceDropdown = computed(() => getServiceDropdown());
const consultantServices = computed(() => getConsultantServices());
const certificateDropdown = computed(() => getCertificateDropdown());
const consultantCertificates = computed(() => getConsultantCertificates());
const backgrounds = computed(() => getBackgrounds());
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

#container {
  @include containertheme(
    $theme-bg: unset,
    $display: inline-block,
    $width: 100%,
    $overflow: auto,
    $margin: unset,
    $text-align: center,
    $color: unset
  );
}

.max-length-label {
  color: $color-darkgrey;
}

#consultant-competencies-and-services {
  display: inline-block;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.consultant-keycompetency {
  margin: 1.5px;
  border: 1px solid $border-color;
  border-radius: 15px;
  background: rgb(87, 197, 142);
}

.consultant-competency,
.consultant-service {
  margin: 1.5px;
  border: 1px solid $border-color;
  border-radius: 15px;
}

.consultant-background,
.consultant-certificate {
  border: none;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
}

.btn-left {
  margin-left: 1.5px;
  border-radius: 15px 0px 0px 15px;
}

.btn-right {
  margin-right: 1.5px;
  border-radius: 0px 15px 15px 0px;
}

.consultant-competency {
  background: rgb(150, 150, 255);
}

.consultant-service {
  background: rgb(223, 226, 31);
}

.consultant-certificate {
  background: rgb(74, 121, 42);
}

.consultant-background {
  background: rgb(51, 135, 150);
}

.certificate-container {
  display: inline-block;
}

.consultant-keycompetency:hover,
.consultant-competency:hover,
.consultant-service:hover,
.consultant-certificate:hover,
.consultant-background:hover {
  cursor: pointer;
  filter: brightness(150%);
}

.invalid {
  outline: 2px solid $color-red !important;
  border-color: rgba(255, 0, 0, 0.25) !important;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.25) !important;
    transition: none;
  }
}

#add-button {
  margin: 5px;
  font-size: smaller;
}

#new-button {
  margin: 5px;
}

#comp-aligner,
#service-aligner,
#background-aligner,
#cert-aligner {
  margin: 5% auto 0;
  width: 99%;

  td {
    padding: 0 5px;
    text-align: left;
  }
}
</style>
