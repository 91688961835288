import { createDataList } from "@/coveragePeriodReport/util/createConnectionEntries";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { TableData } from "@/store/data/TableData";
import { KeyTo } from "@/types/KeyTo";
import { DisplayableField } from "./DisplayableField";
import { DropdownFieldSelectionFilter } from "./DropdownField";
import { FieldType } from "./enum/FieldType";
import { VeaError } from "./error/VeaError";

export class ManyToManyField<
  M1 extends Model<D1>,
  M2 extends Model<D2>,
  M3 extends Model<D3>,
  D1 extends Displayable<M1>,
  D2 extends Displayable<M2>,
  D3 extends Displayable<M3>
> extends DisplayableField<string, string> {
  protected _value: string;
  get value(): string {
    return this._value;
  }
  set value(input: string) {
    this._value = input;
  }
  modelValue(): string {
    throw new Error("Method not implemented.");
  }

  constructor(
    header: string,
    parentId: number,
    public connectionData: TableData<M2, D2>,
    public childData: TableData<M3, D3>,
    public parentIdField: KeyTo<M1, number>,
    public connectionIdField: KeyTo<M2, number>,
    public connectionParentIdField: KeyTo<M2, number>,
    public connectionParentNameField: KeyTo<M2, string>,
    public connectionChildIdField: KeyTo<M2, number>,
    public connectionChildNameField: KeyTo<M2, string>,
    public canCreateChildren?: boolean,
    // eslint-disable-next-line
    public childSelectionFilter?: DropdownFieldSelectionFilter<M3, D3, any, any>
  ) {
    super(header, new HiddenError(), FieldType.MANY_TO_MANY);
    this._value = createDataList(
      connectionData,
      parentId,
      connectionParentIdField,
      connectionChildNameField
    );
  }
}

export class HiddenError<T> extends VeaError<T> {
  constructor() {
    super("");
  }
}
