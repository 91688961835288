export enum SortingMode {
  CONSULTANT = 1,
  COMPANY = 2,
  EMPLOYMENT_DATE = 3,
  AVAILABLE = 4,
  LARGEST_CUSTOMER = 5,
  LOCATION = 6,
  CONSULTANT_GROUP = 7,
  CUSTOMER = 8,
  SERVICE = 9,
}
