import { CorporationCustomer } from "@/models/CorporationCustomer";
import { CorporationCustomerDisplayable } from "@/models/displayable/CorporationCustomerDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { CorporationCustomerHeaders } from "./headers/CorporationCustomerHeaders";

@singleton()
export class CorporationCustomerData extends TableData<
  CorporationCustomer,
  CorporationCustomerDisplayable
> {
  constructor() {
    super(
      "Corporation Customers",
      new CorporationCustomerHeaders(),
      "CorporationCustomer",
      new CorporationCustomer(),
      "Corporation-Customer connection"
    );
  }
}
