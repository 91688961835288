import { LocationDisplayable } from "./displayable/LocationDisplayable";
import { ModelBase } from "./ModelBase";

export class Location extends ModelBase<LocationDisplayable> {
  locationId: number;
  name: string;
  corporationId: number;
  corporationName: string;

  constructor() {
    super();

    this.locationId = 0;
    this.name = "";
    this.corporationId = 0;
    this.corporationName = "";
  }

  realize(partialModel: Location): Location {
    const newModel = new Location();

    newModel.locationId = partialModel.locationId;
    newModel.name = partialModel.name;
    newModel.corporationId = partialModel.corporationId;
    newModel.corporationName = partialModel.corporationName;
    return newModel;
  }

  getId(): number {
    return this.locationId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): LocationDisplayable {
    return new LocationDisplayable(this);
  }
}
