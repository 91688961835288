import { IOpenDated } from "@/interfaces/Dated";
import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { ConsultantDisplayable } from "./displayable/ConsultantDisplayable";

export class Consultant
  extends ModelBase<ConsultantDisplayable>
  implements IOpenDated
{
  consultantId: number;
  startDate: DateKey;
  endDate: DateKey | null;
  consultantGroupId: number;
  userId: number;
  coverage: number;
  aboutMe: string | null;
  ambition: string | null;

  firstname: string;
  lastname: string;
  consultantGroupName: string;
  corporationName: string;
  consultantGroupLocationName: string;

  constructor() {
    super();

    this.consultantId = 0;
    this.startDate = "" as DateKey;
    this.endDate = null;
    this.consultantGroupId = 0;
    this.userId = 0;
    this.coverage = 100;
    this.aboutMe = null;
    this.ambition = null;

    this.firstname = "";
    this.lastname = "";
    this.consultantGroupName = "";
    this.corporationName = "";
    this.consultantGroupLocationName = "";
  }

  realize(partialModel: Consultant): Consultant {
    const newModel = new Consultant();

    newModel.consultantId = partialModel.consultantId;
    newModel.startDate = partialModel.startDate;
    newModel.endDate = partialModel.endDate;
    newModel.consultantGroupId = partialModel.consultantGroupId;
    newModel.userId = partialModel.userId;
    newModel.coverage = partialModel.coverage;
    newModel.aboutMe = partialModel.aboutMe;
    newModel.ambition = partialModel.ambition;

    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;
    newModel.consultantGroupName = partialModel.consultantGroupName;
    newModel.corporationName = partialModel.corporationName;
    newModel.consultantGroupLocationName =
      partialModel.consultantGroupLocationName;

    return newModel;
  }

  getId(): number {
    return this.consultantId;
  }

  getName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  getDisplayable(): ConsultantDisplayable {
    return new ConsultantDisplayable(this);
  }

  getStartDate(): Date {
    return new Date(this.startDate);
  }

  getEndDate(): Date | null {
    return this.endDate ? new Date(this.endDate) : null;
  }

  getGroupName(): string {
    return this.consultantGroupName;
  }
}
