import { AssignmentCompetencyData } from "@/store/data/AssignmentCompetencyData";
import { AssignmentData } from "@/store/data/AssignmentData";
import { CompetencyData } from "@/store/data/CompetencyData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Assignment } from "../Assignment";
import { AssignmentCompetency } from "../AssignmentCompetency";
import { Competency } from "../Competency";
import { AssignmentDisplayable } from "./AssignmentDisplayable";
import { CompetencyDisplayable } from "./CompetencyDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueAssignmentCompetency } from "./fields/validators/FieldUniqueAssignmentCompetency";

export class AssignmentCompetencyDisplayable extends DisplayableBase<AssignmentCompetency> {
  @ValidateNested()
  assignmentName: DropdownField<Assignment, AssignmentDisplayable>;
  @ValidateNested()
  @FieldUniqueAssignmentCompetency()
  competencyName: DropdownField<Competency, CompetencyDisplayable>;

  constructor(assignmentCompetency: AssignmentCompetency) {
    super();
    const assignmentCompetencyData = container.resolve(
      AssignmentCompetencyData
    );
    const { headers } = assignmentCompetencyData;
    const assignmentData = container.resolve(AssignmentData);
    const competencyData = container.resolve(CompetencyData);

    this.assignmentName = new DropdownField(
      headers.assignmentName,
      assignmentCompetency.assignmentName,
      assignmentData,
      new DropdownOption(
        assignmentCompetency.assignmentId,
        assignmentCompetency.assignmentName
      )
    );

    this.competencyName = new DropdownField(
      headers.competencyName,
      assignmentCompetency.competencyName,
      competencyData,
      new DropdownOption(
        assignmentCompetency.competencyId,
        assignmentCompetency.competencyName
      )
    );
  }

  toModel(assignmentCompetency: AssignmentCompetency): void {
    assignmentCompetency.assignmentId = this.assignmentName.selectedOption.id;
    assignmentCompetency.competencyId = this.competencyName.selectedOption.id;
  }
}
