import { Industry } from "@/models/Industry";
import { IndustryDisplayable } from "@/models/displayable/IndustryDisplayable";
import { container, singleton } from "tsyringe";
import { CustomerData } from "./CustomerData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { IndustryHeaders } from "./headers/IndustryHeaders";

@singleton()
export class IndustryData extends TableData<Industry, IndustryDisplayable> {
  constructor() {
    super(
      "Industries",
      new IndustryHeaders(),
      "Industry",
      new Industry(),
      undefined,
      [
        new TablePointer(
          container.resolve(CustomerData),
          "industryId",
          DeletePolicy.WithWarning
        ),
      ]
    );
    this._deleteWarning =
      "Warning! This industry still has customer(s) using it!" +
      "Are you sure you want to delete this industry? " +
      "This will set the industry of every customer using it to null";
  }
}
