import { Certificate } from "@/models/Certificate";
import { Consultant } from "@/models/Consultant";
import { ConsultantCertificate } from "@/models/ConsultantCertificate";
import { ConsultantCertificateData } from "@/store/data/ConsultantCertificateData";
import { CertificateDisplayable } from "../../CertificateDisplayable";
import { ConsultantCertificateDisplayable } from "../../ConsultantCertificateDisplayable";
import { ConsultantDisplayable } from "../../ConsultantDisplayable";
import { FieldUniqueManyToManyConnection } from "./FieldUniqueManyToManyConnection";

export function FieldUniqueConsultantCertificate() {
  return FieldUniqueManyToManyConnection<
    Consultant,
    ConsultantCertificate,
    Certificate,
    ConsultantDisplayable,
    ConsultantCertificateDisplayable,
    CertificateDisplayable
  >(
    "consultant",
    "consultantId",
    "certificateId",
    () => ConsultantCertificateData
  );
}
