<template>
  <div class="wrapper">
    <Transition name="btn-transition">
      <button
        class="sctButton btn btn-sm btn-primary"
        @click="ScrollToTop()"
        v-if="visible.value"
      >
        Scroll to Top
      </button>
    </Transition>
    <Transition name="arrow-transition">
      <fa-icon class="arrow-upper" icon="fa-angle-up" v-if="visible.value" />
    </Transition>
    <Transition name="arrow-transition">
      <fa-icon class="arrow-lower" icon="fa-angle-up" v-if="visible.value" />
    </Transition>
  </div>
</template>
<script setup lang="ts">
import { reactive } from "vue";

function ScrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

const visible = reactive({
  value: false,
});

window.onscroll = function () {
  const vh = window.innerHeight;
  if (window.scrollY > vh * 2) {
    visible.value = true;
  } else {
    visible.value = false;
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sctButton {
  position: fixed;
  top: 90vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.wrapper:hover > .arrow-upper {
  top: 83.5vh;
}

.wrapper:hover > .arrow-lower {
  top: 84.5vh;
}

.arrow-upper,
.arrow-lower {
  position: fixed;
  top: 85.5vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
}

.btn-transition-leave-active,
.btn-transition-enter-active {
  transition: all 0.2s ease;
}

.btn-transition-enter-from,
.btn-transition-leave-to {
  opacity: 0;
  top: 95vh;
}

.arrow-transition-leave-active,
.arrow-transition-enter-active {
  transition: all 0.4s ease;
}

.arrow-transition-enter-from,
.arrow-transition-leave-to {
  opacity: 0;
  top: 95vh;
}
</style>
