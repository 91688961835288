import { Consultant } from "@/models/Consultant";
import { Leader } from "@/models/Leader";
import store from "@/store";
import { MonthKey, dateKey } from "@/types/DateKey";
import { dateSpan, isInSpan, monthAsDateSpan } from "@/types/DateSpan";
import { getFirstDaysAfterInternship } from "./getFirstDaysAfterInternship";

export type MonthlyEmploymentCounts = {
  month: Date;
  consultants: number; // includes those with non billable (formerly in-house) assignments
  interns: number;
  internsNames: string[];
  leaders: number;
};

export function calculateNumberOfEmployees(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number,
  options?: { includeInternships: boolean; includeLeaders: boolean }
): MonthlyEmploymentCounts {
  const { consultantData, consultantGroupData, leaderData, locationData } =
    store.state;

  const lastDayInMonth = monthAsDateSpan(month).end;

  function filterEmployee(employee: Leader | Consultant) {
    if (employee.endDate == null) {
      return dateKey(employee.startDate) <= lastDayInMonth;
    }
    const leaderSpan = dateSpan(employee.startDate, employee.endDate);
    return isInSpan(lastDayInMonth, leaderSpan);
  }

  let leaders = options?.includeLeaders
    ? leaderData.rows.filter(filterEmployee)
    : [];
  let consultants = consultantData.rows.filter(filterEmployee);

  if (consultantGroupId && consultantGroupId > 0) {
    consultants = consultants.filter(
      (consultant) => consultant.consultantGroupId == consultantGroupId
    );
    if (options?.includeLeaders) {
      const { firstLeaderId, secondLeaderId } =
        consultantGroupData.findById(consultantGroupId);
      leaders = leaders.filter(
        (l) => l.leaderId === firstLeaderId || l.leaderId === secondLeaderId
      );
    }
  } else if (corporationId > 0) {
    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      corporationId
    );
    consultants = consultants.filter((consultant) =>
      consultantGroupIds.has(consultant.consultantGroupId)
    );
    if (options?.includeLeaders) {
      const locationIds = locationData.findIds("corporationId", corporationId);
      leaders = leaders.filter((l) => locationIds.has(l.locationId));
    }
  }

  const firstDayAfterInternshipByConsultantId = getFirstDaysAfterInternship();

  let employments = 0;
  let interns = 0;
  const internsNames = new Array<string>();

  for (const c of consultants) {
    const startDate = firstDayAfterInternshipByConsultantId.get(c.consultantId);
    if (startDate === undefined || startDate <= lastDayInMonth) {
      employments++;
    } else if (options?.includeInternships) {
      interns++;
      internsNames.push(c.getName());
    }
  }

  return {
    consultants: employments,
    interns,
    internsNames,
    leaders: leaders.length,
    month: new Date(month),
  };
}
