import user, { RoleName, RoleNames } from "@/auth/user";
import { singleton } from "tsyringe";

@singleton()
export class AuthorizationManager {
  private _baseDetailsExtraPermissions = new AuthorizationEntry([
    RoleNames.admin,
  ]);
  get baseDetailsExtraPermissions(): AuthorizationEntry {
    return this._baseDetailsExtraPermissions;
  }

  private _baseToast = new AuthorizationEntry([RoleNames.admin]);
  get baseToast(): AuthorizationEntry {
    return this._baseToast;
  }

  private _editFiles = new AuthorizationEntry([RoleNames.admin]);
  get editFiles(): AuthorizationEntry {
    return this._editFiles;
  }

  private _editBaseTable = new AuthorizationEntry([RoleNames.admin]);
  get editBaseTable(): AuthorizationEntry {
    return this._editBaseTable;
  }

  private _editCoveragePeriodReport = new AuthorizationEntry([RoleNames.admin]);
  get editCoveragePeriodReport(): AuthorizationEntry {
    return this._editCoveragePeriodReport;
  }

  private _editCustomerOverview = new AuthorizationEntry([RoleNames.admin]);
  public get editCustomerOverview(): AuthorizationEntry {
    return this._editCustomerOverview;
  }

  private _editRecruitmentOverview = new AuthorizationEntry([RoleNames.admin]);
  public get editRecruitmentOverview(): AuthorizationEntry {
    return this._editRecruitmentOverview;
  }

  private _editProfile = new AuthorizationEntry([RoleNames.admin]);
  public get editProfile(): AuthorizationEntry {
    return this._editProfile;
  }

  private _editAbsenceDetails = new AuthorizationEntry([RoleNames.admin]);
  public get editAbsenceDetails(): AuthorizationEntry {
    return this._editAbsenceDetails;
  }

  private _editAssignmentDetails = new AuthorizationEntry([RoleNames.admin]);
  public get editAssignmentDetails(): AuthorizationEntry {
    return this._editAssignmentDetails;
  }

  private _editConsultantDetails = new AuthorizationEntry([RoleNames.admin]);
  public get editConsultantDetails(): AuthorizationEntry {
    return this._editConsultantDetails;
  }

  private _settings = new AuthorizationEntry([
    RoleNames.admin,
    RoleNames.user,
    RoleNames.adminReadOnly,
  ]);
  get settings(): AuthorizationEntry {
    return this._settings;
  }

  private _viewSetup = new AuthorizationEntry([
    RoleNames.admin,
    RoleNames.adminReadOnly,
  ]);
  get viewSetup(): AuthorizationEntry {
    return this._viewSetup;
  }
}

export class AuthorizationEntry {
  private _roles: Set<RoleName>;

  constructor(roles?: RoleName[]) {
    this._roles = new Set<RoleName>(roles);
  }

  isAuthorized(): boolean {
    return this._roles.has(user.getters.role);
  }
}
