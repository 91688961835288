<template>
  <div class="container">
    <BaseTable
      :data="state.certificateData"
      :options="options"
      :canEdit="true"
    />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import { VeaState } from "@/store";
import { CertificateOptions } from "@/store/data/tableDataOptions/CertificateOptions";
import { useStore } from "vuex";

defineOptions({
  name: "CertificatesTable",
});
const state = useStore<VeaState>().state;
const options = new CertificateOptions();
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}
</style>
