import userStore from "@/auth/user";
import { AxiosError } from "axios";
import { singleton } from "tsyringe";

@singleton()
export class BackendErrorSet {
  private getErrors: AxiosError[] = [];
  private postErrors: AxiosError[] = [];
  private putErrors: AxiosError[] = [];
  private deleteErrors: AxiosError[] = [];
  private listeners: (() => void)[] = [];

  register(listener: () => void): void {
    this.listeners.push(listener);
  }

  addGetError(error: AxiosError): void {
    if (error.message == "Request failed with status code 401") {
      userStore.logout();
      return;
    }
    this.getErrors.push(error);
    this.notify();
  }

  addPostError(error: AxiosError): void {
    this.postErrors.push(error);
    this.notify();
  }

  addPutError(error: AxiosError): void {
    this.putErrors.push(error);
    this.notify();
  }

  addDeleteError(error: AxiosError): void {
    this.deleteErrors.push(error);
    this.notify();
  }

  private notify(): void {
    this.listeners.map((l) => l());
  }

  get errors(): AxiosError[][] {
    return [this.getErrors, this.postErrors, this.putErrors, this.deleteErrors];
  }

  get hasErrors(): boolean {
    return (
      this.getErrors.length != 0 ||
      this.postErrors.length != 0 ||
      this.putErrors.length != 0 ||
      this.deleteErrors.length != 0
    );
  }

  clearErrors(): void {
    this.getErrors = [];
    this.postErrors = [];
    this.putErrors = [];
    this.deleteErrors = [];
  }
}
