import { Displayable } from "@/interfaces/Displayable";
import { Dropdown } from "@/interfaces/Dropdown";
import { ITableData } from "@/interfaces/ITableData";
import { Model } from "@/interfaces/Model";
import { SettingsManager } from "@/store/SettingsManager";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { CorporationData } from "@/store/data/CorporationData";
import { TableData } from "@/store/data/TableData";
import { Action } from "@/store/data/enum/Action";
import { BackendErrorSet } from "@/store/data/error/BackendErrorSet";
import {
  FormEditingContext,
  IFormEditingContext,
} from "@/types/FormEditingContext";
import {
  DropdownSource,
  IFormEditingContextParentKey,
} from "@/types/FormEditingContextParentKey";
import { KeyTo } from "@/types/KeyTo";
import { getProp, setProp } from "@/types/getProp";
import {
  IsNotEmpty,
  IsNotIn,
  IsString,
  ValidationArguments,
} from "class-validator";
import { container } from "tsyringe";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { TextError } from "./error/TextError";
import { DropdownOption } from "./util/DropdownOption";

export class DropdownField<M extends Model<D>, D extends Displayable<M>>
  extends DisplayableField<string, string>
  implements Dropdown
{
  @IsString({
    message: (args: ValidationArguments) =>
      (args.object as DropdownField<M, D>)._error.valueError(args.value),
  })
  @IsNotEmpty({
    message: (args: ValidationArguments) =>
      (args.object as DropdownField<M, D>)._error.emptyError(),
  })
  @IsNotIn([" "], {
    message: (args: ValidationArguments) =>
      (args.object as DropdownField<M, D>)._error.emptyError(),
  })
  protected _value: string;
  protected _mandatory;
  public editImpossible: boolean;
  protected _selectedOption: DropdownOption<string>;
  protected _data: TableData<M, D>;
  protected _dropdownOptions: DropdownOption<string>[];
  protected _isCompoundType: boolean;
  protected _optionsFilter: (
    value: DropdownOption<string>,
    index: number,
    array: DropdownOption<string>[]
  ) => boolean;

  protected _selectedFilter?: DropdownOption<string>;
  protected _secondarySelectedFilter?: DropdownOption<string>;
  protected _filterOptions?: DropdownOption<string>[];
  protected _filteredOptions: DropdownOption<string>[];
  public readonly nullable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _selectionFilter?: DropdownFieldSelectionFilter<M, D, any, any>;
  protected _secondaryFilterOptions?: DropdownOption<string>[];
  protected _hasGeneratedValues = false;
  public readonly hideOptionWhen?: (option: DropdownOption<string>) => boolean;

  get filterOptions(): DropdownOption<string>[] | undefined {
    return this._filterOptions;
  }

  get secondaryFilterOptions(): DropdownOption<string>[] | undefined {
    return this._secondaryFilterOptions;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get selectionFilterData(): TableData<any, any> | undefined {
    return this._selectionFilter?.filterData;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get secondarySelectionFilterData(): TableData<any, any> | undefined {
    return this._selectionFilter?.secondFilter?.filterData;
  }

  get filterIdKey(): KeyTo<M, number> | undefined {
    return this._selectionFilter?.filterIdKey;
  }

  get filterNameKey(): KeyTo<M, string> | undefined {
    return this._selectionFilter?.filterNameKey;
  }

  get selectedFilter(): DropdownOption<string> | undefined {
    return this._selectedFilter;
  }

  set selectedFilter(option: DropdownOption<string> | undefined) {
    this._selectedFilter = option;
  }

  get secondarySelectedFilter(): DropdownOption<string> | undefined {
    return this._secondarySelectedFilter;
  }

  set secondarySelectedFilter(option: DropdownOption<string> | undefined) {
    this._secondarySelectedFilter = option;
  }

  get value(): string {
    return this._value;
  }

  set value(input: string) {
    this._value = input;
  }

  get data(): TableData<M, D> {
    return this._data;
  }

  get options(): DropdownOption<string>[] {
    return this._filteredOptions;
  }

  get mandatory(): boolean {
    return this._mandatory;
  }

  get consultantGroupData(): ConsultantGroupData {
    return container.resolve(ConsultantGroupData);
  }

  public refilter(): void {
    this._dropdownOptions = this._data
      .rowsToDropdownOptions()
      .filter(this._optionsFilter);

    if (!this._filterOptions || !this._dropdownOptions) {
      this._filteredOptions = this._dropdownOptions;
      return;
    }

    this._filterOptions =
      this._selectionFilter?.filterData.rowsToDropdownOptions();
    const anyOption = new DropdownOption(0, "<Any>");

    const secondaryFilterId = this._secondarySelectedFilter?.id;
    if (
      this._selectionFilter &&
      this._selectionFilter.secondFilter &&
      secondaryFilterId &&
      secondaryFilterId != 0
    ) {
      const { secondFilter, filterData } = this._selectionFilter;
      this._filterOptions = this._filterOptions?.filter((option) =>
        secondFilter.matches(option.id, secondaryFilterId, filterData)
      );
      if (
        this.selectedFilter &&
        !this._filterOptions?.some((o) => o.id == this.selectedFilter?.id)
      ) {
        this.selectedFilter = anyOption;
      }
    }
    this._filterOptions?.unshift(anyOption);

    const filterId = this._selectedFilter?.id;
    if (filterId && filterId !== 0) {
      this._filteredOptions = this._dropdownOptions.filter((option) =>
        this._selectionFilter?.matches(option.id, filterId, this._data)
      );
    } else if (secondaryFilterId && secondaryFilterId !== 0) {
      this._filteredOptions = this._dropdownOptions.filter((option) =>
        this._selectionFilter?.secondFilterMatches(
          option.id,
          secondaryFilterId,
          this._data
        )
      );
    } else {
      this._filteredOptions = this._dropdownOptions.slice();
    }
    if (this.nullable) {
      this._filteredOptions.unshift(new DropdownOption(0, "<None>"));
    }

    if (!this._filteredOptions.find((s) => s.id == this._selectedOption.id)) {
      this._selectedOption = this.nullable
        ? new DropdownOption(0, "<None>")
        : new DropdownOption(0, "");
      this._value = "";
      if (this.nullable) {
        this._filteredOptions.unshift(new DropdownOption(0, "<None>"));
      }
    }
  }

  /**
   * Waits for new data to be available, or for validation errors to be present.
   */
  async updateData(): Promise<void> {
    while (
      this.dropdownsAreEqual(
        this._dropdownOptions,
        this._data.rowsToDropdownOptions().filter(this._optionsFilter)
      ) &&
      !this._data.hasValidationErrors() &&
      !container.resolve(BackendErrorSet).hasErrors
    ) {
      await new Promise((r) => setTimeout(r, 1000));
    }
    const sentInstance = this._data.fetchedInstance;
    this._dropdownOptions = this._data
      .rowsToDropdownOptions()
      .filter(this._optionsFilter);
    if (
      this._filterOptions &&
      (this._selectedFilter?.id !== 0 ||
        this._secondarySelectedFilter?.id !== 0)
    ) {
      this.refilter();
    } else {
      this._filteredOptions = this._dropdownOptions;
    }
    if (this.nullable) {
      this._filteredOptions.unshift(new DropdownOption(0, "<None>"));
    }
    this.selectedOption = new DropdownOption<string>(
      sentInstance.getId(),
      sentInstance.getName()
    );
  }

  async updateFilterData(): Promise<void> {
    const data = this.selectionFilterData as ITableData | undefined;
    if (!data) {
      return;
    }
    const { fetchedInstance } = data;
    this._selectedFilter = new DropdownOption<string>(
      fetchedInstance.getId(),
      fetchedInstance.getName()
    );
    this._filterOptions = data.rowsToDropdownOptions();
    this.refilter();
  }

  async updateSecondaryFilterData(): Promise<void> {
    const data = this.secondarySelectionFilterData as ITableData | undefined;
    if (!data) {
      return;
    }
    const { fetchedInstance } = data;
    this._secondarySelectedFilter = new DropdownOption<string>(
      fetchedInstance.getId(),
      fetchedInstance.getName()
    );
    this._secondaryFilterOptions = data.rowsToDropdownOptions();
    this._secondaryFilterOptions.unshift(new DropdownOption(0, "<Any>"));
    this.refilter();
  }

  get selectedOption(): DropdownOption<string> {
    return this._selectedOption;
  }

  set selectedOption(option: DropdownOption<string>) {
    this._selectedOption = option;
    this.value = option.label;
  }

  get isCompoundType(): boolean {
    return this._isCompoundType;
  }

  modelValue(): string {
    return this._value;
  }

  protected dropdownsAreEqual(
    dropdown1: DropdownOption<string>[],
    dropdown2: DropdownOption<string>[]
  ): boolean {
    return (
      dropdown1.length === dropdown2.length &&
      dropdown1.every((element, index) => element.id === dropdown2[index].id)
    );
  }

  constructor(
    header: string,
    data: string,
    dropdownData: TableData<M, D>,
    selectedOption?: DropdownOption<string>,
    options?: DropdownFieldOptions<M, D>
  ) {
    super(header, new TextError(header), FieldType.DROP_DOWN, options);
    this._data = dropdownData;
    this.editImpossible = false;
    this.nullable = options?.nullable ?? false;
    this._mandatory = !this.nullable;
    this._value = data;
    this._isCompoundType = options?.isCompoundType ?? true;
    this._optionsFilter = options?.optionsFilter ?? (() => true);
    this.hideOptionWhen = options?.hideOptionWhen;
    this._selectionFilter = options?.selectionFilter;
    this._selectedOption =
      selectedOption ??
      (this.nullable
        ? new DropdownOption(0, "<None>")
        : new DropdownOption(0, ""));
    this._dropdownOptions = [];
    this._filteredOptions = this._dropdownOptions;
    if (this._selectionFilter) {
      const instance = this._data.findById(this._selectedOption.id);
      const id = getProp(instance, this._selectionFilter.filterIdKey);
      const relatedInstance = this._selectionFilter.filterData.findById(id);
      this._selectedFilter =
        relatedInstance.getId() != 0
          ? new DropdownOption(
              relatedInstance.getId(),
              relatedInstance.getName()
            )
          : new DropdownOption(0, "<Any>");
    }
  }

  generateOptions(): void {
    if (this._hasGeneratedValues) {
      return;
    }
    this._hasGeneratedValues = true;
    this._value = this._selectedOption.label;
    this._dropdownOptions = this._data
      .rowsToDropdownOptions()
      .filter(this._optionsFilter);
    if (this.nullable) {
      this._dropdownOptions.unshift(new DropdownOption(0, "<None>"));
    }
    this._filteredOptions = this._dropdownOptions;
    this.presetFilterOption();
    const presetOption = this.presetOption();
    if (presetOption) {
      this._selectedOption = presetOption;
      this._value = presetOption.label;
    }
    this.refilter();
  }

  // Returns the dropdown option that matches the preset if it's been set in Settings
  private presetOption(): DropdownOption<string> | undefined {
    const { corporationId, consultantGroupId } =
      container.resolve(SettingsManager);
    if (this.selectedOption.id <= 0) {
      if (this._data instanceof CorporationData && corporationId > 0) {
        return this._dropdownOptions.find((x) => x.id === corporationId);
      }
      if (this._data instanceof ConsultantGroupData && consultantGroupId > 0) {
        return this._dropdownOptions.find((x) => x.id === consultantGroupId);
      }
    }
  }

  private presetFilterOption(): void {
    // If Corporation is selected in Settings, then set selectedFilter to that when modal is opened
    const settings = container.resolve(SettingsManager);
    if (!this.selectedFilter || this.selectedFilter.id <= 0) {
      if (
        this.selectionFilterData instanceof CorporationData &&
        settings.corporationId > 0
      ) {
        const { corporationId, name } = this.selectionFilterData.findById(
          settings.corporationId
        );
        this.selectedFilter = new DropdownOption(corporationId, name);
      }

      if (
        this.secondarySelectionFilterData instanceof CorporationData &&
        settings.corporationId > 0
      ) {
        const { corporationId, name } =
          this.secondarySelectionFilterData.findById(settings.corporationId);
        this.secondarySelectedFilter = new DropdownOption(corporationId, name);
      }
      // If ConsultantGroup is selected in Settings, then set selectedOption to that when modal is opened
      if (
        this.selectionFilterData instanceof ConsultantGroupData &&
        settings.consultantGroupId > 0
      ) {
        const { consultantGroupId, name } = this.selectionFilterData.findById(
          settings.consultantGroupId
        );
        this.selectedFilter = new DropdownOption(consultantGroupId, name);
      }
    }

    if (
      this.secondarySelectionFilterData instanceof ConsultantGroupData &&
      settings.consultantGroupId > 0
    ) {
      const { consultantGroupId, name } =
        this.secondarySelectionFilterData.findById(settings.consultantGroupId);
      this.secondarySelectedFilter = new DropdownOption(
        consultantGroupId,
        name
      );
    }
    // If no corporation is preset, then display "<Any>" as default option
    const anyOption = new DropdownOption(0, "<Any>");
    this._selectedFilter ??= anyOption;
    this._secondarySelectedFilter ??= anyOption;

    if (this._selectionFilter) {
      this._filterOptions =
        this._selectionFilter?.filterData.rowsToDropdownOptions();
      this._filterOptions.unshift(anyOption);
    }

    if (this._selectionFilter?.secondFilter) {
      this._secondaryFilterOptions =
        this._selectionFilter?.secondFilter.filterData.rowsToDropdownOptions();
      this._secondaryFilterOptions.unshift(anyOption);
    }
  }

  createFormEditingContext(
    parent: IFormEditingContextParentKey
  ): IFormEditingContext {
    const { dropdownSource } = parent;
    if (
      dropdownSource == DropdownSource.FirstFilter &&
      this.selectionFilterData
    ) {
      return new FormEditingContext(
        this.selectionFilterData,
        Action.Add,
        undefined,
        parent
      );
    }
    if (
      dropdownSource == DropdownSource.SecondFilter &&
      this.secondarySelectionFilterData
    ) {
      return new FormEditingContext(
        this.secondarySelectionFilterData,
        Action.Add,
        undefined,
        parent
      );
    }
    const extraValues: Partial<M> = {};
    if (this.filterIdKey && this.filterNameKey && this.selectedFilter?.id) {
      setProp(extraValues, this.filterIdKey, this.selectedFilter.id);
      setProp(extraValues, this.filterNameKey, this.selectedFilter.label);
    }
    return new FormEditingContext(this.data, Action.Add, extraValues, parent);
  }
}

export class DropdownFieldOptions<
  M extends Model<D>,
  D extends Displayable<M>
> extends DisplayableFieldOptions {
  isCompoundType?: boolean;
  optionsFilter?: (
    value: DropdownOption<string>,
    index: number,
    array: DropdownOption<string>[]
  ) => boolean;
  nullable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectionFilter?: DropdownFieldSelectionFilter<M, D, any, any>;
  hideOptionWhen?: (option: DropdownOption<string>) => boolean;
}

export class DropdownFieldSelectionFilter<
  M extends Model<D>,
  D extends Displayable<M>,
  FM extends Model<FD>,
  FD extends Displayable<FM>
> {
  constructor(
    public filterData: TableData<FM, FD>,
    public filterIdKey: KeyTo<M, number>,
    public filterNameKey: KeyTo<M, string>,
    public secondFilter?: Omit<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      DropdownFieldSelectionFilter<FM, FD, any, any>,
      "secondFilter"
    >
  ) {}

  matches(
    modelId: number,
    filterId: number,
    dropdownData: TableData<M, D>
  ): boolean {
    return (
      filterId == 0 ||
      filterId == getProp(dropdownData.findById(modelId), this.filterIdKey)
    );
  }

  secondFilterMatches(
    modelId: number,
    filterId: number,
    dropdownData: TableData<M, D>
  ): boolean {
    if (!this.secondFilter) {
      return true;
    }

    const filterIds = this.filterData.findIds(
      this.secondFilter.filterIdKey,
      filterId
    );
    return dropdownData
      .findWithValuesInSet(this.filterIdKey, filterIds)
      .some((m) => m.getId() == modelId);
  }
}
