import { Corporation } from "@/models/Corporation";
import { NisConsultant } from "@/models/NisConsultant";
import { NisConsultantGroup } from "@/models/NisConsultantGroup";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { NisConsultantDisplayable } from "@/models/displayable/NisConsultantDisplayable";
import { NisConsultantGroupDisplayable } from "@/models/displayable/NisConsultantGroupDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { NisConsultantFilter } from "../tableDataOptionsFilters/NisConsultantFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class NisConsultantOptions extends TableDataOptions<
  NisConsultant,
  NisConsultantDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;
  private nisConsultantGroupDropdown: TableDataDropdown<
    NisConsultantGroup,
    NisConsultantGroupDisplayable
  >;

  override filterRows(
    rows: NisConsultant[],
    radioFilters: BaseTableFilterMode[]
  ): NisConsultant[] {
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const nisConsultantGroupFilter: number =
      this.nisConsultantGroupDropdown.selectedOption;
    const now = Date.now();
    const filters = new FilterBuilder<NisConsultant>();

    const [nisConsultantFilter] = radioFilters;

    if (nisConsultantFilter == BaseTableFilterMode.ACTIVE) {
      filters.add(TableDataOptions.isEmployeeCurrent(now));
    } else if (nisConsultantFilter == BaseTableFilterMode.INACTIVE) {
      filters.add(TableDataOptions.isEmployeeNotCurrent(now));
    }

    if (nisConsultantGroupFilter != -1) {
      filters.add(
        (nisConsultant) =>
          nisConsultant.nisConsultantGroupId == nisConsultantGroupFilter
      );
    } else if (corporationFilter != -1) {
      const nisConsultantGroupIds = store.state.nisConsultantGroupData.findIds(
        "corporationId",
        corporationFilter
      );
      filters.add((nisConsultant) =>
        nisConsultantGroupIds.has(nisConsultant.nisConsultantGroupId)
      );
    }

    return filters.filter(rows);
  }

  public constructor() {
    const { corporationData, nisConsultantGroupData } = store.state;

    super({
      radioFilters: [new NisConsultantFilter()],
      sorting: {
        "First name": {
          func: TableDataOptions.compareFirstNames,
          column: "name",
        },
        "Last name": TableDataOptions.compareLastNames,
        "Employment start": {
          func: TableDataOptions.compareStart,
          column: "employmentStart",
        },
        "Employment end": {
          func: TableDataOptions.compareNullableEnd,
          column: "employmentEnd",
        },
        "Consultant group": { key: "nisConsultantGroupName", column: "group" },
        Company: { key: "corporationName", column: "corporation" },
      },
      filterBoxKeys: ["corporation", "group", "employmentStart"],
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);
    this.nisConsultantGroupDropdown = new TableDataDropdown(
      nisConsultantGroupData,
      undefined,
      this.getDropdownFilterFromRelatedDropdown(
        nisConsultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );

    this.dropdownFilters = [
      this.corporationDropdown,
      this.nisConsultantGroupDropdown,
    ];
  }
}
