import { IDisplayable } from "@/interfaces/Displayable";
import { TextAreaField } from "../TextAreaField";
import { VeaValidate } from "./VeaValidator";

export function FieldTextAreaMaxLength<D extends IDisplayable>() {
  return VeaValidate<D, TextAreaField>({
    message(field) {
      if (!field.maxLength) {
        return "";
      }
      return field.error.maxLengthError(field.maxLength.toString());
    },
    validate(field) {
      return !field.maxLength || field.value.length <= field.maxLength;
    },
  });
}
