import { singleton } from "tsyringe";
import { MonthlyReport } from "./types/MonthlyReport";

@singleton()
export class MonthlyReportData {
  data: MonthlyReport[];

  constructor() {
    this.data = [];
  }
}
