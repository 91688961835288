import { CurrencyDisplayable } from "./displayable/CurrencyDisplayable";
import { ModelBase } from "./ModelBase";

export class Currency extends ModelBase<CurrencyDisplayable> {
  currencyId: number;
  currencyCode: string;
  currencySymbol: string;
  isPrefix: boolean;

  constructor() {
    super();
    this.currencyId = 0;
    this.currencyCode = "";
    this.currencySymbol = "";
    this.isPrefix = false;
  }

  realize(partialModel: Currency): Currency {
    const newModel = new Currency();

    newModel.currencyId = partialModel.currencyId;
    newModel.currencyCode = partialModel.currencyCode;
    newModel.currencySymbol = partialModel.currencySymbol;
    newModel.isPrefix = partialModel.isPrefix;

    return newModel;
  }

  getId(): number {
    return this.currencyId;
  }

  getName(): string {
    return this.currencyCode;
  }

  getDisplayable(): CurrencyDisplayable {
    return new CurrencyDisplayable(this);
  }

  symbolize(value: unknown): string {
    return this.isPrefix
      ? `${this.currencySymbol}${value}`
      : `${value} ${this.currencySymbol}`;
  }
}
