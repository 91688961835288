import { TableHeaders } from "@/interfaces/TableHeaders";
import { NisAssignmentDisplayable } from "@/models/displayable/NisAssignmentDisplayable";

export class NisAssignmentHeaders
  implements TableHeaders<NisAssignmentDisplayable>
{
  public readonly name = "Name";
  public readonly customer = "Customer";
  public readonly startDate = "Start";
  public readonly endDate = "End";
  public readonly taskmaster = "Contact";
  public readonly nisConsultant = "Assignee";
  public readonly nisService = "Service";
  public readonly totalInvoiced = "Total Invoiced";
  public readonly description = "Description";
  public readonly invoices = "Invoices";
}
