<template>
  <td class="table-hover">
    <ul>
      <li v-for="(envelope, i) of filterCustomers()" :key="i">
        <span
          :class="[
            'customer-name',
            {
              'color-frameworkAgreement': envelope.customer.frameworkAgreement,
            },
          ]"
          @click="
            isAuthorizedToEdit
              ? editCustomer(envelope.customer.customerId)
              : () => {}
          "
        >
          {{ envelope.customer.getName() }}
        </span>
        {{ " " }}
        <span class="budget-numbers">
          <span
            :class="getBudgetCssClass(envelope)"
            @click="
              isAuthorizedToEdit
                ? envelope.assignmentBudget
                  ? editAssignmentBudget(
                      envelope.assignmentBudget.assignmentBudgetId
                    )
                  : editAssignmentBudget(undefined, envelope.customer)
                : () => {}
            "
          >
            {{
              `(${envelope.assignmentBudgetOutcome}/${
                envelope.assignmentBudget?.budget ?? 0
              })`
            }}
          </span>
        </span>
      </li>
    </ul>
  </td>
</template>

<script setup lang="ts">
import { AssignmentBudget } from "@/models/AssignmentBudget";
import { Customer } from "@/models/Customer";
import { Leader } from "@/models/Leader";
import { Priority } from "@/models/Priority";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { DateKey } from "@/types/DateKey";
import { container } from "tsyringe";
import { toRefs } from "vue";

type CustomerEnvelope = {
  customer: Customer;
  assignmentBudget: AssignmentBudget | null;
  assignmentBudgetOutcome: number;
};

const props = defineProps<{
  priority?: Priority;
  leader?: Leader;
  corporationId?: number;
  filteredCustomers: () => Customer[];
  editCustomer: (rowIfEditing?: number) => void;
  editAssignmentBudget: (
    rowIfEditing?: number,
    customerPresetIfNew?: Customer
  ) => void;
  year: number;
  outcomeDate: DateKey;
}>();

const { assignmentBudgetData, locationData } = store.state;
const { outcomeDate, year } = toRefs(props);

function getBudgetCssClass(envelope: CustomerEnvelope) {
  if (!envelope.assignmentBudget) {
    return "color-neutral";
  }
  return envelope.assignmentBudgetOutcome >= envelope.assignmentBudget.budget
    ? "color-success"
    : "color-failure";
}

function filterCustomers() {
  return props
    .filteredCustomers()
    .filter(
      (c) =>
        c.priorityId == (props.priority?.priorityId ?? null) &&
        c.leaderId == (props.leader?.leaderId ?? null)
    )
    .map(createEnvelope);
}

function createEnvelope(customer: Customer): CustomerEnvelope {
  const corporationId =
    props.corporationId && props.corporationId > -1
      ? props.corporationId
      : locationData.find("locationId", props.leader?.locationId)
          ?.corporationId;
  const assignmentBudget =
    assignmentBudgetData.find(
      ["customerId", customer.customerId],
      ["corporationId", corporationId],
      ["year", year.value]
    ) ?? null;
  return {
    customer,
    assignmentBudget,
    assignmentBudgetOutcome: AssignmentBudget.calcOutcome(
      customer.customerId,
      corporationId,
      outcomeDate.value
    ),
  };
}

const isAuthorizedToEdit = container
  .resolve(AuthorizationManager)
  .editCustomerOverview.isAuthorized();
</script>

<style scoped lang="scss">
@import "@/styles/global.scss";

ul {
  margin: 0;
  list-style-type: none;
  padding-left: 0;

  .customer-name {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .customer-name.color-frameworkAgreement {
    color: $color-blue;
  }
}

.budget-numbers {
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  .color-success {
    color: $color-green;
  }

  .color-failure {
    color: $color-red;
  }

  .color-neutral {
    color: inherit;
  }
}
</style>
