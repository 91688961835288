import { TableHeaders } from "@/interfaces/TableHeaders";
import { InvoiceDisplayable } from "@/models/displayable/InvoiceDisplayable";

export class InvoiceHeaders implements TableHeaders<InvoiceDisplayable> {
  public readonly nisAssignment = "Assignment";
  public readonly amount = "Amount";
  public readonly currency = "Currency";
  public readonly date = "Date";
  public readonly description = "Notes";
}
