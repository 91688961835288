import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Corporation } from "@/models/Corporation";
import { Recruitment } from "@/models/Recruitment";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { RecruitmentDisplayable } from "@/models/displayable/RecruitmentDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { SummaryRowExtension } from "../tableDataOptionsExtensions/SummaryRowExtension";
import { YearFilterExtension } from "../tableDataOptionsExtensions/YearFilterExtension";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class RecruitmentOptions extends TableDataOptions<
  Recruitment,
  RecruitmentDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  private consultantGroupDropdown: TableDataDropdown<
    ConsultantGroup,
    ConsultantGroupDisplayable
  >;

  override filterRows(
    rows: Recruitment[],
    _radioFilters: BaseTableFilterMode[],
    yearFilter: number
  ): Recruitment[] {
    const filters = new FilterBuilder<Recruitment>();

    const corporationFilter = this.corporationDropdown.selectedOption;
    const consultantGroupFilter = this.consultantGroupDropdown.selectedOption;

    if (consultantGroupFilter != -1) {
      filters.add(
        (recruitment) => recruitment.consultantGroupId == consultantGroupFilter
      );
    } else if (corporationFilter != -1) {
      const consultantGroupIds = store.state.consultantGroupData.findIds(
        "corporationId",
        corporationFilter
      );
      filters.add((recruitment) =>
        consultantGroupIds.has(recruitment.consultantGroupId)
      );
    }

    if (yearFilter > -1) {
      filters.add(
        (recruitment) =>
          new Date(recruitment.period).getFullYear() == yearFilter
      );
    }

    return filters.filter(rows);
  }

  public constructor() {
    const { corporationData, consultantGroupData } = store.state;

    super({
      hiddenFields: ["comment"],
      yearFilterExtension: new YearFilterExtension(
        YearFilterExtension.getYearsFromRecruitments
      ),
      summaryRowExtension: SummaryRowExtension.recruitmentsExtension(),
      groupedByKey: "consultantGroup",
      sorting: {
        Months: TableDataOptions.compareMonths,
      },
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);
    this.consultantGroupDropdown = new TableDataDropdown(consultantGroupData);

    this.dropdownFilters = [
      this.corporationDropdown,
      this.consultantGroupDropdown,
    ];
  }
}
