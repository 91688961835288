import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { CorporationData } from "@/store/data/CorporationData";
import { LeaderData } from "@/store/data/LeaderData";
import { LocationData } from "@/store/data/LocationData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { ConsultantGroup } from "../ConsultantGroup";
import { Corporation } from "../Corporation";
import { Leader } from "../Leader";
import { Location } from "../Location";
import { CorporationDisplayable } from "./CorporationDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { LeaderDisplayable } from "./LeaderDisplayable";
import { LocationDisplayable } from "./LocationDisplayable";
import { DropdownField } from "./fields/DropdownField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldDifferentLeaders } from "./fields/validators/FieldDifferentLeaders";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class ConsultantGroupDisplayable extends DisplayableBase<ConsultantGroup> {
  @ValidateNested()
  @FieldUniqueText<ConsultantGroup, ConsultantGroupDisplayable>()
  name: UniqueTextField<ConsultantGroup, ConsultantGroupDisplayable>;
  @ValidateNested()
  corporation: DropdownField<Corporation, CorporationDisplayable>;
  @ValidateNested()
  location: DropdownField<Location, LocationDisplayable>;
  @ValidateNested()
  firstLeader: DropdownField<Leader, LeaderDisplayable>;
  @ValidateNested()
  @FieldDifferentLeaders("firstLeader")
  secondLeader: DropdownField<Leader, LeaderDisplayable>;

  constructor(group: ConsultantGroup) {
    super();
    const consultantGroupData = container.resolve(ConsultantGroupData);
    const corporationData = container.resolve(CorporationData);
    const locationData = container.resolve(LocationData);
    const leaderData = container.resolve(LeaderData);
    const { headers } = consultantGroupData;

    this.name = new UniqueTextField<
      ConsultantGroup,
      ConsultantGroupDisplayable
    >(headers.name, group.name, consultantGroupData);
    this.corporation = new DropdownField(
      headers.corporation,
      group.corporationName,
      corporationData,
      new DropdownOption(group.corporationId, group.corporationName)
    );
    const locationFilter = (option: DropdownOption<string>) => {
      return (
        this.corporation.selectedOption.id <= 0 ||
        locationData.rows.some(
          (row) =>
            row.locationId === option.id &&
            row.corporationId === this.corporation.selectedOption.id
        )
      );
    };
    this.location = new DropdownField(
      headers.location,
      group.locationName,
      locationData,
      new DropdownOption(group.locationId, group.locationName),
      {
        optionsFilter: locationFilter,
      }
    );
    const leaderFilter = (option: DropdownOption<string>) => {
      const corporationId = this.corporation.selectedOption.id;
      const locationId = this.location.selectedOption.id;
      if (corporationId <= 0 && locationId <= 0) {
        return true;
      }
      if (this.location.selectedOption.id > 0) {
        return leaderData.some(
          ["leaderId", option.id],
          ["locationId", locationId]
        );
      }
      if (corporationId > 0) {
        const locationIds = locationData.findIds(
          "corporationId",
          corporationId
        );
        return leaderData
          .findWithValuesInSet("locationId", locationIds)
          .some((l) => l.leaderId == option.id);
      }
      return true;
    };
    this.firstLeader = new DropdownField(
      headers.firstLeader,
      group.firstLeaderName,
      leaderData,
      new DropdownOption(group.firstLeaderId, group.firstLeaderName),
      {
        optionsFilter: leaderFilter,
      }
    );

    this.secondLeader = new DropdownField(
      headers.secondLeader,
      group.secondLeaderName,
      leaderData,
      group.secondLeaderId
        ? new DropdownOption(group.secondLeaderId, group.secondLeaderName)
        : undefined,

      {
        optionsFilter: leaderFilter,
        nullable: true,
      }
    );
  }

  toModel(consultantGroup: ConsultantGroup): void {
    consultantGroup.name = this.name.modelValue();
    consultantGroup.corporationId = this.corporation.selectedOption.id;
    consultantGroup.locationId = this.location.selectedOption.id;
    consultantGroup.firstLeaderId = this.firstLeader.selectedOption.id;
    consultantGroup.secondLeaderId =
      this.secondLeader?.selectedOption.id == undefined ||
      this.secondLeader.selectedOption.id == 0
        ? null
        : this.secondLeader.selectedOption.id;
  }
}
