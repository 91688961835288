/**
 * Constants corresponding to password strength levels.
 */
enum StrengthLevel {
  None,
  Weak,
  Moderate,
  Medium,
  High,
}

export default StrengthLevel;
