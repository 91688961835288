import { Timespan } from "@/coveragePeriodReport/types/Timespan";
import { PeriodDaysPerMonth } from "@/types/PeriodDaysPerMonth";
import { singleton } from "tsyringe";

@singleton()
export class DateUtils {
  getStartDate(): Date {
    const nowExact = new Date(Date.now());
    return new Date(
      nowExact.getFullYear(),
      nowExact.getMonth(),
      nowExact.getDate()
    );
  }

  getEndDate(startDate: Date, monthsAhead: number): Date {
    if (!startDate) {
      console.error("startDate undefined");
    }
    return new Date(
      startDate.getFullYear(),
      startDate.getMonth() + monthsAhead,
      startDate.getDate()
    );
  }

  stringToDate(s: string): Date {
    const [year, month, day] = s.split("-").map((x) => Number.parseInt(x, 10));
    return new Date(year, month - 1, day);
  }

  dateToString(d: Date): string {
    const year = d.getFullYear().toString();
    let month = (d.getMonth() + 1).toString();
    month = month.length == 1 ? "0" + month : month;
    let day = d.getDate().toString();
    day = day.length == 1 ? "0" + day : day;
    return year + "-" + month + "-" + day;
  }

  //Gives the first day of the month of the given date.
  //If you don't provide a month it gives the first day of the current month
  getFirstDayOfMonth = (date?: Date): Date => {
    if (!date) date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  //Gives the last day of the month of the given date.
  //If you don't provide a month it gives the last day of the current month
  getLastDayOfMonth(date?: Date): Date {
    if (!date) date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  splitByMonthAndCountDays = (start: Date, end: Date): PeriodDaysPerMonth[] => {
    const output: PeriodDaysPerMonth[] = [];
    if (start >= end) return output;

    // If the assignment starts and ends on the same month, take the end date into the calculation
    if (start.getMonth() === end.getMonth()) {
      output.push({
        month: start,
        daysInPeriod:
          this.nDaysInMonth(start) -
          start.getDate() +
          1 -
          (this.nDaysInMonth(start) - end.getDate()),
      });
    } else {
      output.push({
        month: start,
        daysInPeriod: this.nDaysInMonth(start) - start.getDate() + 1,
      });
    }

    for (let i = 1; i <= this.fullMonthsBetween(start, end); i++) {
      const curMonth = new Date(start.getFullYear(), start.getMonth() + i);

      output.push({
        month: curMonth,
        daysInPeriod: this.nDaysInMonth(curMonth),
      });
    }

    if (start.getMonth() === end.getMonth()) return output;
    output.push({ month: end, daysInPeriod: end.getDate() });

    return output;
  };

  nDaysInMonth = (month: Date): number => {
    const monthAfter = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    return monthAfter.getDate();
  };

  calculateDaysBetweenDates = (period: Timespan): number => {
    // Convert both dates to UTC to ensure consistency across time zones
    const utcStartDate = Date.UTC(
      period.startDate.getFullYear(),
      period.startDate.getMonth(),
      period.startDate.getDate()
    );
    const utcEndDate = Date.UTC(
      period.endDate.getFullYear(),
      period.endDate.getMonth(),
      period.endDate.getDate()
    );

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInMilliseconds = Math.abs(utcEndDate - utcStartDate);

    const differenceInDays =
      Math.floor(differenceInMilliseconds / millisecondsPerDay) + 1;

    return differenceInDays;
  };

  getDateBoundaries(
    startDate: Date,
    endDate: Date
  ): { dateBefore: Date; dateAfter: Date } {
    const dateBefore = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() - 1
    );
    const dateAfter = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() + 1
    );

    return { dateBefore, dateAfter };
  }

  //  Always ignores start and end month, even if they are "full"
  fullMonthsBetween(startDate: Date, endDate: Date): number {
    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth() + 1;
    months += endDate.getMonth();
    return months <= 0 ? 0 : months;
  }

  //Counts the number of month ends between dates
  countEndOfMonthsBetween(startDate: Date, endDate: Date): number {
    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    if (endDate.getDay() == this.getLastDayOfMonth(endDate).getDay()) months++;
    return months <= 0 ? 0 : months;
  }

  generateDatesFromDateToAndDateFrom = (
    dateFrom: Date,
    dateTo: Date
  ): Date[] => {
    const dates: Date[] = [];

    const dateFromMonth = dateFrom.getMonth();
    const dateToMonth = dateTo.getMonth();
    const dateFromYear = dateFrom.getFullYear();
    const dateToYear = dateTo.getFullYear();
    let k = dateFromMonth;
    let l = dateToMonth;

    for (let i = dateFromYear; i <= dateToYear; i++) {
      if (i >= dateFromYear + 1) {
        k = 0;
      }
      if (i == dateToYear) {
        l = dateToMonth;
      } else if (dateFromYear != dateToYear) {
        l = 11;
      }
      for (let j = k; j <= l; j++) {
        dates.push(new Date(i, j));
      }
    }

    return dates;
  };

  public static getMonthIndexFromString = (month: string): number => {
    switch (month) {
      case "January":
        return 1;
      case "February":
        return 2;
      case "March":
        return 3;
      case "April":
        return 4;
      case "May":
        return 5;
      case "June":
        return 6;
      case "July":
        return 7;
      case "August":
        return 8;
      case "September":
        return 9;
      case "October":
        return 10;
      case "November":
        return 11;
      case "December":
        return 12;
      default:
        return 0;
    }
  };

  removeTimezoneOffset(date: Date): Date {
    const timezoneOffsetMinutes = date.getTimezoneOffset();
    const offsetMilliseconds = timezoneOffsetMinutes * 60 * 1000;
    const adjustedDate = new Date(date.getTime() - offsetMilliseconds);
    return adjustedDate;
  }
  addOneDayToDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  }
  removeOneDayToDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
  }
}
