<template>
  <input
    :id="
      thisField.header.toLocaleLowerCase().replace(/[^a-z0-9]/g, '-') + '-field'
    "
    type="text"
    class="form-control edit-input"
    :class="hasError && 'error'"
    v-model="thisField.value"
    :placeholder="thisField.placeholder"
    v-on:input="signalInputUpdate()"
    :maxlength="thisField.maxLength"
  />
  <label
    :id="
      thisField.header.toLocaleLowerCase().replace(/[^a-z0-9]/g, '-') +
      '-field-max-length-label'
    "
    class="max-length-label"
    v-if="thisField.maxLength"
  >
    Max length: {{ thisField.maxLength }}
  </label>
</template>

<script
  setup
  lang="ts"
  generic="M extends Model<D>, D extends Displayable<M> & IterableFields<D, IDisplayableField>"
>
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { IDisplayableField } from "@/models/displayable/fields/DisplayableField";
import { IterableFields } from "@/types/IterableFields";
import { computed } from "vue";
const props = defineProps<{
  field: IDisplayableField | D[keyof D];
  hasError?: boolean;
}>();
const emit = defineEmits(["input-updated"]);
const signalInputUpdate = () => emit("input-updated");

const thisField = computed(() => props.field);
</script>
<style scoped lang="scss">
@import "../../styles/global.scss";
.error {
  @include error;
}
.max-length-label {
  text-align: right;
  display: block;
  color: $color-darkgrey;
}
</style>
