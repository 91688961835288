import { TaskmasterDisplayable } from "./displayable/TaskmasterDisplayable";
import { ModelBase } from "./ModelBase";

export class Taskmaster extends ModelBase<TaskmasterDisplayable> {
  taskmasterId: number;
  firstname: string;
  lastname: string;
  title: string | null;
  customerId: number;

  customerName: string;

  constructor() {
    super();

    this.taskmasterId = 0;
    this.customerId = 0;
    this.firstname = "";
    this.lastname = "";
    this.title = "";

    this.customerName = "";
  }

  realize(partialModel: Taskmaster): Taskmaster {
    const newModel = new Taskmaster();

    newModel.taskmasterId = partialModel.taskmasterId;
    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;
    newModel.title = partialModel.title;
    newModel.customerId = partialModel.customerId;

    newModel.customerName = partialModel.customerName;

    return newModel;
  }

  getId(): number {
    return this.taskmasterId;
  }

  getName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  getDisplayable(): TaskmasterDisplayable {
    return new TaskmasterDisplayable(this);
  }
}
