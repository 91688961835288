import { NisServiceDisplayable } from "./displayable/NisServiceDisplayable";
import { ModelBase } from "./ModelBase";

export default class NisService extends ModelBase<NisServiceDisplayable> {
  nisServiceId: number;
  name: string;

  constructor() {
    super();
    this.nisServiceId = 0;
    this.name = "";
  }

  realize(partialModel: NisService): NisService {
    const newModel = new NisService();

    newModel.nisServiceId = partialModel.nisServiceId;
    newModel.name = partialModel.name;

    return newModel;
  }

  getId(): number {
    return this.nisServiceId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): NisServiceDisplayable {
    return new NisServiceDisplayable(this);
  }
}
