<template>
  <div class="control-panel" style="display: flex; align-items: start">
    <form @submit.prevent>
      <UnselectableLabel>
        Company
        <select id="company-selector" v-model="corporationIdModel">
          <option v-if="hasNone" name="Company" :value="-1">
            &lt;None&gt;
          </option>
          <option v-if="hasAll" name="Company" :value="-1">&lt;All&gt;</option>
          <option
            v-for="company in corporations"
            :key="company.corporationId"
            :value="company.corporationId"
          >
            {{ company.name }}
          </option>
        </select>
      </UnselectableLabel>
      <UnselectableLabel v-show="showLabel" v-if="!hideConsultantGroupFilter">
        Consultant Group
        <select id="consultant-group-selector" v-model="consultantGroupIdModel">
          <option name="ConsultantGroup" :value="-1">&lt;All&gt;</option>
          <option
            v-for="consultantGroup in consultantGroups"
            :key="consultantGroup.consultantGroupId"
            :value="consultantGroup.consultantGroupId"
          >
            {{ consultantGroup.name }}
          </option>
        </select>
      </UnselectableLabel>
      <slot></slot>
    </form>
  </div>
</template>

<script setup lang="ts">
import store from "@/store";
import { computed } from "vue";
import UnselectableLabel from "../UnselectableLabel.vue";

export interface Props {
  showLabel?: boolean;
  hasAll?: boolean;
  hasNone?: boolean;
  hideConsultantGroupFilter?: boolean;
}

defineProps<Props>();

const consultantGroupIdModel = defineModel<number>("consultantGroupId", {
  default: -1,
});
const corporationIdModel = defineModel<number>("corporationId", {
  default: -1,
  set: (id) => {
    const consultantGroupId = consultantGroupIdModel.value;
    if (id <= 0 || consultantGroupId <= 0) {
      return id;
    }
    const hasMatch = consultantGroups.value.some(
      (cg) =>
        cg.consultantGroupId == consultantGroupId && cg.corporationId == id
    );
    if (!hasMatch) {
      consultantGroupIdModel.value = -1;
    }
    return id;
  },
});

const corporations = computed(() =>
  store.state.corporationData.rows.sort((a, b) =>
    a.getName().localeCompare(b.getName())
  )
);
const consultantGroups = computed(() => {
  const { consultantGroupData } = store.state;
  const consultantGroups =
    corporationIdModel.value <= 0
      ? consultantGroupData.rows
      : consultantGroupData.findMany("corporationId", corporationIdModel.value);
  return consultantGroups.sort((a, b) =>
    a.getName().localeCompare(b.getName())
  );
});

//Exposing the values to the template so that they can be used.
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
form {
  @include form;
  @include form-field;
  input,
  select {
    border-radius: 4px;
    border-color: $border-color;
    height: 1.8rem;

    &:invalid {
      outline: 2px solid $color-red;
    }
  }

  label {
    @include label;
  }
}
</style>
