import { ContractPhase } from "@/models/enum/ContractPhase";
import store from "@/store";
import { DateKey, dateKey } from "@/types/DateKey";

export function getFirstDaysAfterInternship(
  lastDayToCheck: DateKey | null = null
) {
  const { assignmentData } = store.state;
  let internships = assignmentData.findMany("phase", ContractPhase.INTERNSHIP);
  if (lastDayToCheck != null) {
    internships = internships.filter(
      (i) => dateKey(i.endDate) >= lastDayToCheck
    );
  }

  const firstDayAfterInternshipByConsultantId = new Map<number, DateKey>();

  for (const internship of internships) {
    const { consultantId } = internship;
    const date = new Date(internship.endDate);
    date.setDate(date.getDate() + 1);
    const startDate = dateKey(date);
    const found = firstDayAfterInternshipByConsultantId.get(consultantId);
    if (!found || found < startDate) {
      firstDayAfterInternshipByConsultantId.set(consultantId, startDate);
    }
  }

  return firstDayAfterInternshipByConsultantId;
}
