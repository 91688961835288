<template>
  <div class="col-auto form-check form-check-inline">
    <label class="form-check-label" for="option_positive">{{ YES }}</label>
    <input
      type="radio"
      id="option_positive"
      class="radio-button form-check-input"
      name="conditional"
      :value="YES"
      v-model="thisField.value"
      :cy-data="YES"
      :class="yesError && 'error'"
      v-on:change="signalInputUpdate()"
    />
  </div>
  <div class="col-auto form-check form-check-inline">
    <label class="form-check-label" for="option_negative">{{ NO }}</label>
    <input
      type="radio"
      id="option_negative"
      class="radio-button form-check-input"
      name="conditional"
      :value="NO"
      v-model="thisField.value"
      :cy-data="NO"
      :class="noError && 'error'"
      v-on:blur="signalInputUpdate()"
    />
  </div>
</template>

<script setup lang="ts">
import { ConditionalField } from "@/models/displayable/fields/ConditionalField";
import { ConditionalOption } from "@/models/displayable/fields/enum/ConditionalOption";
import { computed } from "vue";
const props = defineProps<{
  field: ConditionalField;
  hasError?: boolean;
}>();
const emit = defineEmits(["input-updated"]);
const signalInputUpdate = () => emit("input-updated");
const thisField = computed(() => props.field);
const YES = computed(() =>
  props.field.hasDefaults ? ConditionalOption.YES : ConditionalOption.ASSIGNMENT
);
const NO = computed(() =>
  props.field.hasDefaults
    ? ConditionalOption.NO
    : ConditionalOption.PROBABLE_ASSIGNMENT
);
const yesError = computed(
  () =>
    (props.hasError && props.field.value === ConditionalOption.YES) ||
    props.field.value === ConditionalOption.ASSIGNMENT
);
const noError = computed(
  () =>
    (props.hasError && props.field.value === ConditionalOption.NO) ||
    props.field.value === ConditionalOption.PROBABLE_ASSIGNMENT
);
</script>

<style scoped lang="scss">
@import "../../styles/global.scss";
.error {
  @include error;
}
</style>
