import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { TableDataOptionsFilter } from "./TableDataOptionsFilter";

export class PeriodFilter extends TableDataOptionsFilter {
  public filterSettings: BaseTableFilterMode[] = [
    BaseTableFilterMode.CURRENT,
    BaseTableFilterMode.FINISHED,
    BaseTableFilterMode.ALL,
  ];

  public filterLabel = "Period";
}
