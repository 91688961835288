import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { ConsultantCertificateDisplayable } from "./displayable/ConsultantCertificateDisplayable";

export class ConsultantCertificate extends ModelBase<ConsultantCertificateDisplayable> {
  consultantCertificateId: number;
  certificateId: number;
  consultantId: number;
  certificateName: string;
  consultantName: string;
  date: DateKey;

  corporationName: string;

  constructor() {
    super();
    this.consultantCertificateId = 0;
    this.consultantId = 0;
    this.certificateId = 0;
    this.consultantName = "";
    this.certificateName = "";
    this.date = "" as DateKey;

    this.corporationName = "";
  }

  realize(partialModel: ConsultantCertificate): ConsultantCertificate {
    const newModel = new ConsultantCertificate();
    newModel.consultantCertificateId = partialModel.consultantCertificateId;
    newModel.certificateId = partialModel.certificateId;
    newModel.consultantId = partialModel.consultantId;
    newModel.certificateName = partialModel.certificateName;
    newModel.consultantName = partialModel.consultantName;
    newModel.date = partialModel.date;

    newModel.corporationName = partialModel.corporationName;
    return newModel;
  }

  getId(): number {
    return this.consultantCertificateId;
  }

  getName(): string {
    return this.certificateName;
  }

  getDate(): Date {
    return new Date(this.date);
  }

  getDisplayable(): ConsultantCertificateDisplayable {
    return new ConsultantCertificateDisplayable(this);
  }
}
