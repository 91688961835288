import ReportFilterOptions from "@/coveragePeriodReport/ReportFilterOptions";
import { DateUtils } from "@/util/DateUtils";
import { container, singleton } from "tsyringe";
import { DefaultReportSetting } from "./data/constant/Setting";

@singleton()
export class ReportSetting {
  options: ReportFilterOptions;

  primarySort: number | undefined;
  secondarySort: number;
  monthsAhead: number;
  startDate: string;
  endDate: string;

  private dateUtils: DateUtils;

  constructor() {
    this.dateUtils = container.resolve(DateUtils);
    this.options = DefaultReportSetting.options;

    this.primarySort = DefaultReportSetting.primarySort;
    this.secondarySort = DefaultReportSetting.secondarySort;
    this.monthsAhead = DefaultReportSetting.monthsAhead;
    this.startDate = this.dateUtils.dateToString(
      DefaultReportSetting._startDate
    );
    this.endDate = this.dateUtils.dateToString(DefaultReportSetting._endDate);
  }
}
