import { Role } from "@/models/Role";
import { RoleDisplayable } from "@/models/displayable/RoleDisplayable";
import { container, singleton } from "tsyringe";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { UserData } from "./UserData";
import { RoleHeaders } from "./headers/RoleHeaders";

@singleton()
export class RoleData extends TableData<Role, RoleDisplayable> {
  constructor() {
    super("Roles", new RoleHeaders(), "Role", new Role(), undefined, [
      new TablePointer(
        container.resolve(UserData),
        "roleId",
        DeletePolicy.NeverDelete
      ),
    ]);
    this._deleteError =
      "This role may not be deleted! " +
      "It still has user(s) under it! " +
      "Reassign or remove each of its remaining users";
  }
}
