import { singleton } from "tsyringe";
import { FormBaseData } from "./FormBaseData";

@singleton()
export class PasswordFormData extends FormBaseData {
  constructor() {
    super();
    this.setStateFields({
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    });

    this.addPropertyToState({ showCriteria: false });
  }
}
