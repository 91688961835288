import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Corporation } from "@/models/Corporation";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class ConsultantGroupOptions extends TableDataOptions<
  ConsultantGroup,
  ConsultantGroupDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  override filterRows(rows: ConsultantGroup[]): ConsultantGroup[] {
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const filters = new FilterBuilder<ConsultantGroup>();

    if (corporationFilter != -1) {
      const corporationIds = store.state.corporationData.findIds(
        "corporationId",
        corporationFilter
      );
      filters.add((consultGroup) =>
        corporationIds.has(consultGroup.corporationId)
      );
    }

    return filters.filter(rows);
  }

  public constructor() {
    const { corporationData } = store.state;

    super({
      filterBoxKeys: [
        "name",
        "corporation",
        "location",
        "firstLeader",
        "secondLeader",
      ],
      sorting: {
        Name: "name",
      },
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);

    this.dropdownFilters = [this.corporationDropdown];
  }
}
