<template>
  <base-modal
    :title="'Delete ' + titleSingular"
    modalIcon="trash"
    :aborttitle="'Cancel'"
    :abort="abort"
    :danger="true"
    :accepttitle="'Confirm'"
    :disableButton="canDelete == CanDelete.No"
    :accept="doDelete"
  >
    <ul class="list-group" ref="body">
      <p class="strong" v-if="canDelete == CanDelete.No">
        {{ deleteError }}
      </p>
      <p class="strong" v-else-if="canDelete == CanDelete.WithWarning">
        {{ deleteWarning }}
      </p>
      <p class="strong" v-else>
        Are you sure you want to delete the following {{ titleSingular }}:
        <br />
        <b> {{ name }} </b>?
      </p>
      <br />
      <ul v-if="canDelete == CanDelete.No">
        <template
          v-for="relatedData in dataToDelete"
          :key="relatedData.dataType"
        >
          <template v-if="relatedData.toDelete != ''">
            These
            {{ relatedData.title.toLowerCase() }}
            are bound to this
            {{ titleSingular.toLowerCase() }}:
            <li>{{ relatedData.toDelete }}</li>
          </template>
        </template>
      </ul>
    </ul>
  </base-modal>
</template>

<script setup lang="ts" generic="M extends Model<D>, D extends Displayable<M>">
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { CanDelete, TableData } from "@/store/data/TableData";
import { computed, onMounted, ref } from "vue";
import BaseModal from "./BaseModal.vue";

const props = defineProps<{
  data: TableData<M, D>;
}>();
function abort() {
  props.data.abort();
  props.data.finishDeletion();
}
async function doDelete() {
  await props.data.doDelete().then(() => {
    emit("data-delete");
    props.data.finishDeletion();
  });
}
const emit = defineEmits(["data-delete"]);
const body = ref<HTMLDivElement | null>(null);
const dataToDelete = computed(() => {
  const id = props.data.activeRow?.getId();
  if (!id) {
    return [];
  }

  return props.data.dependencyOf.map((dep) => {
    return {
      title: dep.title,
      toDelete: dep.getDependentNames(id),
    };
  });
});

onMounted(() => {
  body.value?.focus();
});

const canDelete = computed(
  () =>
    props.data.activeRow && props.data.canDelete(props.data.activeRow.getId())
);
const deleteWarning = computed(() => props.data.deleteWarning);
const deleteError = computed(() => props.data.deleteError);
const titleSingular = computed(() => props.data.titleSingular);
const name = computed(() => props.data.activeRow?.getName());
</script>

<style lang="scss">
p {
  margin-bottom: 0.5rem;
}
</style>
