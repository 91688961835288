<template>
  <section class="accordion profile" :id="accordionId">
    <div class="accordion-item">
      <h2 class="accordion-header" :id="headingId">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#${collapseId}`"
          aria-expanded="true"
          :aria-controls="collapseId"
        >
          URLs ({{ urls.length }})
        </button>
      </h2>
      <div
        :id="collapseId"
        class="accordion-collapse collapse"
        aria-labelledby="heading"
        :data-bs-parent="`#${accordionId}`"
      >
        <div class="accordion-body">
          <div v-if="hasUrlsAvailable()" class="conditional-wrapper">
            <ul class="file-list">
              <li class="file-item" v-for="url in urls" :key="url.urlId">
                <button
                  class="btn btn-sm btn-delete"
                  @click.prevent="handleDelete(url.urlId), $emit('edit')"
                  v-if="isAuthorized"
                >
                  <fa-icon icon="minus-circle" class="del" />
                </button>
                <div>
                  <button
                    class="btn btn-light btn-file"
                    @click="handleRedirect(url.url)"
                    :title="url.name"
                    :class="{ invalid: isDuplicate(url.urlId) }"
                  >
                    <fa-icon icon="link"></fa-icon>
                    {{ url.name }}
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>No URLs here...</p>
          </div>

          <form class="upload-form" v-if="isAuthorized">
            <div class="input-group form-url">
              <button
                class="url-btn"
                :id="uploadBtnId"
                type="button"
                @click="uploadUrl()"
              >
                Upload URL
              </button>
              <input
                type="text"
                class="form-control url-input"
                :id="inputUrlId"
                placeholder="Paste Url Here"
                v-model="state.urlLink"
                :class="{ invalid: invalidUrl }"
              />
              <input
                type="text"
                class="form-control name-input"
                :id="inputNameId"
                placeholder="Enter Name Here"
                v-model="state.urlText"
                :class="{ invalid: invalidText }"
              />
            </div>
            <div class="form-text text-center">
              Accepted Url Type:<br />{{ urlPrefix }}
            </div>
          </form>
        </div>
        <div v-if="state.error" class="err-msg">
          {{ state.error }}
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { AssignmentUrl } from "@/models/AssignmentUrl";
import { ConsultantUrl } from "@/models/ConsultantUrl";
import { AssignmentUrlDisplayable } from "@/models/displayable/AssignmentUrlDisplayable";
import { ConsultantUrlDisplayable } from "@/models/displayable/ConsultantUrlDisplayable";
import { UrlType } from "@/models/displayable/fields/enum/UrlType";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { AssignmentData } from "@/store/data/AssignmentData";
import { AssignmentUrlData } from "@/store/data/AssignmentUrlData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantUrlData } from "@/store/data/ConsultantUrlData";
import { container } from "tsyringe";
import { computed, reactive } from "vue";

const props = defineProps<{
  id: number;
  canEdit?: boolean;
  dataType: UrlType;
}>();

const emit = defineEmits(["edit", "url-upload", "url-delete"]);

const { consultantUrlData, assignmentUrlData, assignmentData, consultantData } =
  store.state;

const state = reactive({
  error: "",
  urlText: "",
  urlLink: "",
});

const urls = computed(() => {
  switch (props.dataType) {
    case UrlType.Assignment:
      return assignmentUrlData.findMany("assignmentId", props.id);
    case UrlType.Consultant:
      return consultantUrlData.findMany("consultantId", props.id);
    default:
      throw new Error("Invalid Url Type");
  }
});

function handleRedirect(url: string) {
  window.open(url, "_blank");
}

function hasUrlsAvailable() {
  return urls.value.length > 0;
}

async function handleDelete(urlId: number) {
  let data: ConsultantUrlData | AssignmentUrlData;
  if (props.dataType === UrlType.Consultant) {
    data = consultantUrlData;
  } else if (props.dataType === UrlType.Assignment) {
    data = assignmentUrlData;
  } else {
    return;
  }

  if (data.findById(urlId)) {
    data.delete(urlId);
    await data.doDelete().then(() => emit("url-delete"));
  }
}

function isDuplicate(id: number) {
  if (!urlExists && !nameExists) {
    return false;
  }

  for (const entry of urls.value) {
    if (
      entry.name.toLowerCase() !== state.urlText.toLowerCase() &&
      entry.url.toLowerCase() !== state.urlLink.toLowerCase()
    ) {
      continue;
    }
    if (entry.urlId === id) {
      urlExists = false;
      nameExists = false;
      return true;
    }
  }
}

function handleError({ message }: { message: string }) {
  switch (message) {
    case nameError.message:
      invalidText = true;
      break;
    case urlError.message:
      invalidUrl = true;
      break;
    case urlNameError.message:
      invalidText = true;
      invalidUrl = true;
      break;
    case nameExistsError.message:
      nameExists = true;
      break;
    case urlExistsError.message:
      urlExists = true;
      break;
    case urlFormatError.message:
      invalidUrl = true;
      break;
  }
  state.error = message;
  setTimeout(() => {
    invalidText = false;
    invalidUrl = false;
    state.error = "";
  }, 2000);
}

const urlPrefix = "https://unicuscom.sharepoint.com";
const type = UrlType[props.dataType].toLowerCase();

// Error Messages
const nameError = { message: "Your Url needs a name" };
const urlError = { message: "You need to Paste in an Url" };
const urlNameError = { message: "No inputs detected" };
const nameExistsError = {
  message: `This ${type} already has a url with that name`,
};
const urlExistsError = { message: `This ${type} already has that url` };
const urlFormatError = { message: `The url has to start with ${urlPrefix}` };

// Error booleans
let invalidText = false;
let invalidUrl = false;
let urlExists = false;
let nameExists = false;

function isValid(url: ConsultantUrl | AssignmentUrl): boolean {
  if (url.name === "" && url.url === "") {
    handleError(urlNameError);
    return false;
  } else if (url.name === "") {
    handleError(nameError);
    return false;
  } else if (url.url === "") {
    handleError(urlError);
    return false;
  } else if (!url.url.startsWith(urlPrefix)) {
    handleError(urlFormatError);
    return false;
  }
  for (const key of urls.value) {
    // The comparators for the strings are put to lowerCase so the comparison isn't case sensitive
    if (key.name.toLowerCase() === url.name.toLowerCase()) {
      handleError(nameExistsError);
      return false;
    } else if (key.url.toLowerCase() === url.url.toLowerCase()) {
      handleError(urlExistsError);
      return false;
    }
  }
  return true;
}

async function uploadUrl() {
  let data: AssignmentData | ConsultantData;
  let urlData: AssignmentUrlData | ConsultantUrlData;

  if (props.dataType === UrlType.Consultant) {
    [data, urlData] = [consultantData, consultantUrlData];
  } else if (props.dataType === UrlType.Assignment) {
    [data, urlData] = [assignmentData, assignmentUrlData];
  } else {
    throw new Error("Invalid Url Type");
  }

  urlData.add();
  const instance = data.findById(props.id);

  if (!instance) {
    return;
  }

  const url = urlData.activeRow;

  if (!url) {
    return;
  }

  if (url instanceof ConsultantUrl) {
    url.consultantId = props.id;
    url.consultantName = instance.getName();
  } else {
    url.assignmentId = props.id;
    url.assignmentName = instance.getName();
  }

  url.name = state.urlText;
  url.url = state.urlLink;

  if (isValid(url)) {
    await urlData.saveChanges(
      url.getDisplayable() as ConsultantUrlDisplayable &
        AssignmentUrlDisplayable
    );
    await urlData.finished?.then(() => emit("url-upload"));
    state.urlLink = "";
    state.urlText = "";
  }
}

const accordionId = `accordion${props.id}`;
const collapseId = `collapse${props.id}`;
const headingId = `heading${props.id}`;
const inputUrlId = `inputUrl${props.id}`;
const inputNameId = `inputName${props.id}`;
const uploadBtnId = `uploadBtn${props.id}`;
const isAuthorized = computed(
  () =>
    props.canEdit ||
    container.resolve(AuthorizationManager).editFiles.isAuthorized()
);
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";
.accordion {
  padding: 3px;

  .accordion-button {
    padding: 0.3rem;
  }

  .accordion-item:focus-within {
    outline: 3px solid $vea-primary-color;
  }

  .accordion-body {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
  }

  .accordion-collapse {
    position: relative;
    background-color: $container-bgc;
  }
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0rem 0 0rem 0;

  .file-item {
    display: grid;
    gap: 10px;
    margin-bottom: 10px;
    grid-template-columns: 1fr minmax(0, 8fr);
    text-align: left;

    .invalid {
      outline: 2px solid $color-red;
    }

    .btn-delete:hover {
      color: $color-darkred;
    }

    .btn-file {
      margin: 2px;
      border: 1px solid $color-lightgrey;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-file:hover {
      border-width: 2px;
      margin: 1px;
    }
  }

  .upload-form {
    margin-top: 40px;
  }
}

.err-msg {
  padding-bottom: 10px;
  margin-top: -10px;
  font-weight: bold;
  color: #a22b2b;
}

.form-url {
  margin-top: 10px;
}

.url-btn {
  width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  background-color: $color-grey;
  color: $color-white;
  border: 1px solid $color-vea-whitesmoke;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out;
}

.url-btn:hover {
  background-color: $color-lightgrey;
}
</style>
