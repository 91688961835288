import { NisAssignment } from "@/models/NisAssignment";
import { NisAssignmentDisplayable } from "@/models/displayable/NisAssignmentDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { NisAssignmentHeaders } from "./headers/NisAssignmentHeaders";

@singleton()
export class NisAssignmentData extends TableData<
  NisAssignment,
  NisAssignmentDisplayable
> {
  constructor() {
    super(
      "NIS Assignments",
      new NisAssignmentHeaders(),
      "NisAssignment",
      new NisAssignment(),
      "NIS Assignment"
    );
  }
}
