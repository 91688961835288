import {
  IsInt,
  IsOptional,
  Max,
  Min,
  ValidationArguments,
} from "class-validator";
import { DisplayableFieldOptions } from "./DisplayableField";
import { NullableField } from "./NullableField";
import { NumberField } from "./NumberField";
import { FieldType } from "./enum/FieldType";
import { NumberError } from "./error/NumberError";

export class NullableNumberField extends NullableField<number> {
  @IsOptional()
  @IsInt({
    message: (args: ValidationArguments) =>
      (args.object as NullableNumberField)._error.valueError(args.value),
  })
  @Min(NumberField.MIN, {
    message: (args: ValidationArguments) =>
      (args.object as NullableNumberField)._error.minValueError(
        args.constraints[0],
        args.value
      ),
  })
  @Max(NumberField.MAX, {
    message: (args: ValidationArguments) =>
      (args.object as NullableNumberField)._error.maxValueError(
        args.constraints[0],
        args.value
      ),
  })
  protected _value: number | null;

  modelValue(): number | null {
    return this._value;
  }

  constructor(
    header: string,
    data: number | null,
    options?: DisplayableFieldOptions
  ) {
    super(header, new NumberError(header), FieldType.NUMERIC, options);
    this._value = data;
  }
}
