import { Currency } from "@/models/Currency";
import { CurrencyDisplayable } from "@/models/displayable/CurrencyDisplayable";
import { container, singleton } from "tsyringe";
import { SettingsManager } from "../SettingsManager";
import { CorporationData } from "./CorporationData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { CurrencyHeaders } from "./headers/CurrencyHeaders";

@singleton()
export class CurrencyData extends TableData<Currency, CurrencyDisplayable> {
  constructor() {
    super(
      "Currencies",
      new CurrencyHeaders(),
      "Currency",
      new Currency(),
      undefined,
      [
        new TablePointer(
          container.resolve(CorporationData),
          "currencyId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This currency may not be deleted! " +
      "It still has corporation(s) using it! " +
      "Reassign or remove each of its remaining corporations";
  }

  public getCompanyCurrencyOrDefault(corporationId?: number) {
    const id =
      corporationId ?? container.resolve(SettingsManager).corporationId;
    const corporationData = container.resolve(CorporationData);
    const corporation = corporationData.findById(id === -1 ? 1 : id);
    const currency = this.findById(corporation.currencyId);
    return currency;
  }
}
