import { AssignmentBudget } from "@/models/AssignmentBudget";
import { Corporation } from "@/models/Corporation";
import { AssignmentBudgetDisplayable } from "@/models/displayable/AssignmentBudgetDisplayable";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class AssignmentBudgetOptions extends TableDataOptions<
  AssignmentBudget,
  AssignmentBudgetDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  override filterRows(rows: AssignmentBudget[]): AssignmentBudget[] {
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const filters = new FilterBuilder<AssignmentBudget>();

    if (corporationFilter != -1) {
      const corporationIds = store.state.corporationData.findIds(
        "corporationId",
        corporationFilter
      );
      filters.add((assignmentBudget) =>
        corporationIds.has(assignmentBudget.corporationId)
      );
    }

    return filters.filter(rows);
  }

  public constructor() {
    const { corporationData } = store.state;

    super({
      filterBoxKeys: ["corporation"],
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);

    this.dropdownFilters = [this.corporationDropdown];
  }
}
