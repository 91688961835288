import { Month } from "@/models/enum/Months";

/**
 * yyyy-mm representation of a month.
 */
export type MonthKey = `${YearNumbers}-${MonthsNumber}`;

/**
 * yyyy-mm-dd representation of a date.
 */
export type DateKey = `${YearNumbers}-${MonthsNumber}-${DaysNumber}`;

/**
 * A 4-digit representation of a year.
 * Constrained to 2000-2049 to keep `DateKey` from getting too complex for typescript to represent.
 */
export type YearNumbers = `20${0 | 1 | 2 | 3 | 4}${Digit}`;

/**
 * Covers all months in a two-digit format (01 - 12).
 */
export type MonthsNumber = Exclude<
  `${0 | 1}${Digit}`,
  `1${Exclude<Digit, 0 | 1 | 2>}` | "00"
>;

/**
 * Covers all days in a two-digit format (01 - 31).
 */
export type DaysNumber = Exclude<
  `${0 | 1 | 2 | 3}${Digit}`,
  `3${Exclude<Digit, 0 | 1>}` | "00"
>;

export type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

/**
 * Constructs a {@link DateKey} from a given {@link Date} or string.
 */
export function dateKey(date: Date | string): DateKey {
  if (typeof date === "string") {
    if (isDateKeyString(date)) {
      return date;
    }
    date = new Date(date);
  }

  const year = fmtYear(date.getFullYear());
  const month = fmtMonth(date.getMonth());
  const day = fmtDay(date.getDate());

  return `${year}-${month}-${day}` as DateKey;
}

/**
 * Constructs a {@link MonthKey} from a given {@link Date} or string.
 */
export function monthKey(date: Date | string): MonthKey {
  if (typeof date === "string") {
    if (isMonthKeyString(date)) {
      return date;
    }
    if (isDateKeyString(date)) {
      return monthKeyFromDate(date);
    }
    date = new Date(date);
  }

  const year = fmtYear(date.getFullYear());
  const month = fmtMonth(date.getMonth());

  return `${year}-${month}` as MonthKey;
}

/**
 * Extracts the year and month from a {@link DateKey} as a {@link MonthKey}.
 */
export function monthKeyFromDate(date: DateKey): MonthKey {
  return date.substring(0, 7) as MonthKey;
}

/**
 * Constructs a {@link MonthKey} from a specific year and month.
 */
export function monthKeyFromYearAndMonth(year: number, month: Month): MonthKey {
  return `${fmtYear(year)}-${fmtMonth(month)}` as MonthKey;
}

export function dateKeyFromMonthAndDay(month: MonthKey, day: number): DateKey {
  return dateKey(`${month}-${fmtDay(day)}`);
}

/**
 * Returns a given year formated as a 4-digit string (or number, if already 4-digit).
 */
export function fmtYear(year: number): number | string {
  if (year > 9999) {
    return 9999;
  }

  if (year >= 1000) {
    return year;
  }

  const yearStr = `000${year}`;
  return yearStr.substring(yearStr.length - 4);
}

/**
 * Returns a given month formated as a 2-digit string (or number, if already 2-digit).
 * @param month
 * @returns
 */
export function fmtMonth(month: Month): number | string {
  month++;
  return month >= 10 ? month : `0${month}`;
}

/**
 * Returns a given day formated as a 2-digit string (or number, if already 2-digit).
 * @param day
 * @returns
 */
export function fmtDay(day: number): number | string {
  return day >= 10 ? day : `0${day}`;
}

/**
 *
 * @returns the year of a {@link DateKey} or {@link MonthKey}.
 */
export function yearFromKey(key: DateKey | MonthKey): number {
  return Number.parseInt(key.substring(0, 4));
}

/**
 * @returns the {@link Month} of a {@link DateKey} or {@link MonthKey}.
 */
export function monthFromKey(key: DateKey | MonthKey): Month {
  return (Number.parseInt(key.substring(5, 7)) - 1) as Month;
}

/**
 * @returns the day of month of a {@link DateKey}
 */
export function dayFromKey(key: DateKey): number {
  return Number.parseInt(key.substring(8));
}

export function isDateKeyString(value: string): value is DateKey {
  if (value.length != 10) {
    return false;
  }

  for (let i = 0; i < 10; i++) {
    const char = value[i];

    if (i == 4 || i == 7) {
      if (char != "-") {
        return false;
      }
      continue;
    }

    if (char < "0" || char > "9") {
      return false;
    }
  }

  return true;
}

export function isMonthKeyString(value: string): value is MonthKey {
  if (value.length != 7) {
    return false;
  }

  for (let i = 0; i < 7; i++) {
    const char = value[i];

    if (i == 4) {
      if (char != "-") {
        return false;
      }
      continue;
    }

    if (char < "0" || char > "9") {
      return false;
    }
  }

  return true;
}

export function today(): DateKey {
  return dateKey(new Date());
}
