import { ContractPhase } from "@/models/enum/ContractPhase";
import { singleton } from "tsyringe";
import store from ".";
import { StatData } from "./data/types/StatData";
import { StatPicks } from "./data/types/StatPicks";

@singleton()
export class Statistics {
  public generateTidbits(): string[] {
    const state = this.filterActiveByCorporation(store.state);
    return this.tidbits(state);
  }

  private filterActiveByCorporation(state: StatPicks): StatData {
    const { corporationId } = store.state.settings;
    const corporation = state.corporationData.find(
      "corporationId",
      corporationId
    );

    // Consultants: Currently employed and working for selected corporation
    let currentConsultants = state.consultantData.getActive();
    currentConsultants = corporation
      ? currentConsultants.filter((c) => c.corporationName === corporation.name)
      : currentConsultants;
    // Assignments: Currently ongoing and assigned to one of the above consultants
    let currentAssignments = state.assignmentData.getActive();
    currentAssignments = currentAssignments.filter((a) =>
      currentConsultants.some((c) => c.consultantId === a.consultantId)
    );
    // Absences: Currently ongoing and assigned to one of the above consultants
    let currentAbsences = state.absenceData.getActive();
    currentAbsences = currentAbsences.filter((a) =>
      currentConsultants.some((c) => c.consultantId === a.consultantId)
    );

    let employedConsultant = currentConsultants;
    // Filter away interns
    employedConsultant = employedConsultant.filter(
      (c) =>
        !currentAssignments
          .filter((a) => c.consultantId === a.consultantId)
          .some((a) => a.phase == ContractPhase.INTERNSHIP)
    );

    //Currently unused filtering of conultant. Keeping functionality incase of later use.
    // keep in mind in-house has been renamed to non-billable
    // let strictFilteredConsultants = relevantConsultants;
    // // Filter away in-house
    // strictFilteredConsultants = strictFilteredConsultants.filter(
    //   (c) =>
    //     !currentAssignments
    //       .filter((a) => c.consultantId === a.consultantId)
    //       .some((a) => a.phase == ContractPhase.IN_HOUSE)
    // );
    // // Filter away unassigned with known end date
    // strictFilteredConsultants = strictFilteredConsultants.filter(
    //   (c) =>
    //     !(
    //       c.getEndDate() &&
    //       !currentAssignments.some((a) => c.consultantId === a.consultantId)
    //     )
    // );

    const assignedConsultants = currentConsultants.filter((c) =>
      currentAssignments
        .filter((a) => c.consultantId === a.consultantId)
        .some(
          (a) =>
            a.phase == ContractPhase.ASSIGNMENT ||
            a.phase == ContractPhase.PROBABLE_ASSIGNMENT
        )
    );

    let assignmentFulltimes = 0.0;
    currentAssignments.forEach((a) => {
      if (
        a.phase == ContractPhase.ASSIGNMENT ||
        a.phase == ContractPhase.PROBABLE_ASSIGNMENT
      ) {
        assignmentFulltimes += a.coverage / 100;
      }
    });

    let availableConsultants = currentConsultants;
    availableConsultants = availableConsultants.filter(
      (c) =>
        !currentAbsences.some((a) => c.consultantId === a.consultantId) &&
        !currentAssignments.some((a) => c.consultantId == a.consultantId)
    );

    return {
      nTotalConsultants: currentConsultants.length,
      nEmployedConsultants: employedConsultant.length,
      nAvailableConsultants: availableConsultants.length,
      nAssignedConsultants: assignedConsultants.length,
      assignmentFulltimes: Math.round(assignmentFulltimes * 10) / 10, // Round to 1 decimal point (inaccurate, but close enough)
      corpName: corporation ? corporation.name : "Unicus",
    };
  }

  private tidbits(state: StatData): string[] {
    return [
      `At ${state.corpName}, ${state.nAssignedConsultants} out of ${state.nEmployedConsultants} consultants have ongoing assignments (equivalent to ${state.assignmentFulltimes} full-time assignments).`,
      `There are ${state.nAvailableConsultants} consultants available for assignments at ${state.corpName}.`,
      `${state.corpName} employs a total of ${state.nEmployedConsultants} consultants.`,
    ];
  }
}
