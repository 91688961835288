import { AssignmentCompetencyDisplayable } from "./displayable/AssignmentCompetencyDisplayable";
import { ModelBase } from "./ModelBase";

export class AssignmentCompetency extends ModelBase<AssignmentCompetencyDisplayable> {
  assignmentCompetencyId: number;
  competencyId: number;
  assignmentId: number;
  competencyName: string;
  assignmentName: string;

  constructor() {
    super();
    this.assignmentCompetencyId = 0;
    this.competencyId = 0;
    this.assignmentId = 0;
    this.competencyName = "";
    this.assignmentName = "";
  }

  realize(partialModel: AssignmentCompetency): AssignmentCompetency {
    const newModel = new AssignmentCompetency();
    newModel.assignmentCompetencyId = partialModel.assignmentCompetencyId;
    newModel.competencyId = partialModel.competencyId;
    newModel.assignmentId = partialModel.assignmentId;
    newModel.competencyName = partialModel.competencyName;
    newModel.assignmentName = partialModel.assignmentName;
    return newModel;
  }

  getName(): string {
    return this.competencyName;
  }

  getId(): number {
    return this.assignmentCompetencyId;
  }

  getDisplayable(): AssignmentCompetencyDisplayable {
    return new AssignmentCompetencyDisplayable(this);
  }
}
