import { Displayable, IDisplayable } from "@/interfaces/Displayable";
import { Dropdown } from "@/interfaces/Dropdown";
import { Model } from "@/interfaces/Model";
import { IOneToManyField } from "@/models/displayable/fields/OneToManyField";
import { TableData } from "@/store/data/TableData";
import { KeyTo } from "./KeyTo";
import { getProp } from "./getProp";

export enum DropdownSource {
  Main,
  FirstFilter,
  SecondFilter,
}

export interface IFormEditingContextParentKey {
  readonly displayable: IDisplayable;
  readonly dropdownSource: DropdownSource;
  readonly fieldKey: string | number | symbol;
  update(): Promise<void>;
}

export class FormEditingContextParentKeyOneToMany<
  M extends Model<D>,
  D extends Displayable<M>
> implements IFormEditingContextParentKey
{
  readonly dropdownSource = DropdownSource.Main;

  constructor(
    readonly data: TableData<M, D>,
    readonly displayable: D,
    readonly fieldKey: KeyTo<D, IOneToManyField>
  ) {}

  async update() {
    if (this.data.hasFieldValidationErrors(this.fieldKey)) {
      await this.data.validateForm(this.displayable);
    }
  }
}

export class FormEditingContextParentKey<
  M extends Model<D>,
  D extends Displayable<M>
> implements IFormEditingContextParentKey
{
  constructor(
    readonly data: TableData<M, D>,
    readonly displayable: D,
    readonly fieldKey: KeyTo<D, Dropdown>,
    readonly dropdownSource: DropdownSource
  ) {}

  get dropdown() {
    return getProp(this.displayable, this.fieldKey);
  }

  async update() {
    let updateFunc: Promise<void>;

    if (this.dropdownSource == DropdownSource.Main) {
      updateFunc = this.dropdown.updateData();
    } else if (this.dropdownSource == DropdownSource.FirstFilter) {
      updateFunc = this.dropdown.updateFilterData();
    } else {
      updateFunc = this.dropdown.updateSecondaryFilterData();
    }

    await updateFunc;

    if (this.data.hasFieldValidationErrors(this.fieldKey)) {
      await this.data.validateForm(this.displayable);
    }
  }
}
