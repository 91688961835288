import { Consultant } from "@/models/Consultant";
import { ConsultantService } from "@/models/ConsultantService";
import Service from "@/models/Service";
import { ConsultantServiceData } from "@/store/data/ConsultantServiceData";
import { ConsultantDisplayable } from "../../ConsultantDisplayable";
import { ConsultantServiceDisplayable } from "../../ConsultantServiceDisplayable";
import { ServiceDisplayable } from "../../ServiceDisplayable";
import { FieldUniqueManyToManyConnection } from "./FieldUniqueManyToManyConnection";

export function FieldUniqueConsultantService() {
  return FieldUniqueManyToManyConnection<
    Consultant,
    ConsultantService,
    Service,
    ConsultantDisplayable,
    ConsultantServiceDisplayable,
    ServiceDisplayable
  >("consultantName", "consultantId", "serviceId", () => ConsultantServiceData);
}
