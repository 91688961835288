import { onBeforeUnmount, onMounted, Ref } from "vue";

// function for click outside of element to close it (like dropdown)
export function useClickOutside(
  el_target_ref: Ref<EventTarget | null>,
  callback_fn: () => void
): { listener: (event: Event) => void } {
  const listener = (event: Event) => {
    if (
      el_target_ref.value &&
      event.target != el_target_ref.value &&
      !event.composedPath().includes(el_target_ref.value)
    ) {
      callback_fn();
    }
  };

  onMounted(() => {
    document.addEventListener("click", listener);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("click", listener);
  });
  return {
    listener,
  };
}
