import CryptoJS from "crypto-js";

const secretKey = process.env.VUE_APP_SECRET_KEY;

export function encrypt(text: string): string {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
}

export function decrypt(encryptedText: string): string {
  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey).toString(
      CryptoJS.enc.Utf8
    );
    return decrypted;
  } catch (error) {
    console.error("Decryption error:", error);
    throw new Error("Decryption error");
  }
}
