import { IsIn, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { ConditionalOption } from "./enum/ConditionalOption";
import { FieldType } from "./enum/FieldType";
import { ConditionalError } from "./error/ConditionalError";

export class ConditionalField extends DisplayableField<
  ConditionalOption,
  boolean
> {
  @IsIn(
    [
      ConditionalOption.YES,
      ConditionalOption.NO,
      ConditionalOption.DONT_CARE,
      ConditionalOption.ASSIGNMENT,
      ConditionalOption.PROBABLE_ASSIGNMENT,
    ],
    {
      message: (args: ValidationArguments) =>
        (args.object as ConditionalField)._error.valueError(args.value),
    }
  )
  protected _value = ConditionalOption.NO;

  private _hasDefaults: boolean;

  get hasDefaults(): boolean {
    return this._hasDefaults;
  }

  get value(): ConditionalOption {
    return this._value;
  }

  set value(input: ConditionalOption) {
    this._value = input;
  }

  modelValue(): boolean {
    return this.parseCondition(this._value);
  }

  constructor(
    header: string,
    data: boolean,
    options?: ConditionalFieldOptions
  ) {
    super(header, new ConditionalError(header), FieldType.RADIO, options);
    this._hasDefaults = header !== "Phase";

    if (options?.required === false) {
      this._value = ConditionalOption.DONT_CARE;
    } else if (data) {
      this._value = this._hasDefaults
        ? ConditionalOption.YES
        : ConditionalOption.ASSIGNMENT;
    } else {
      this._value = this._hasDefaults
        ? ConditionalOption.NO
        : ConditionalOption.PROBABLE_ASSIGNMENT;
    }
  }

  private parseCondition(condition: ConditionalOption): boolean {
    switch (condition) {
      case ConditionalOption.YES:
      case ConditionalOption.ASSIGNMENT:
        return true;
      default:
        return false;
    }
  }
}

export class ConditionalFieldOptions extends DisplayableFieldOptions {
  required?: boolean;
}
