import { RoleDisplayable } from "./displayable/RoleDisplayable";
import { ModelBase } from "./ModelBase";

export class Role extends ModelBase<RoleDisplayable> {
  roleId: number;
  name: string;

  constructor() {
    super();

    this.roleId = 0;
    this.name = "";
  }

  realize(partialModel: Role): Role {
    const newModel = new Role();

    newModel.roleId = partialModel.roleId;
    newModel.name = partialModel.name;

    return newModel;
  }

  getId(): number {
    return this.roleId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): RoleDisplayable {
    return new RoleDisplayable(this);
  }
}
