<template>
  <div class="container">
    <div class="title-section">
      <h1>Recruitment Overview</h1>
    </div>
    <div class="options-container">
      <div class="options">
        <div class="select-container">
          <div class="filter-box">
            <label><strong>Company</strong></label>
            <select id="corpSelector" v-model="state.corporationId">
              <option :value="-1">&lt;All&gt;</option>
              <option
                v-for="corporation of companies"
                :key="corporation.corporationId"
                :value="corporation.corporationId"
              >
                {{ corporation.name }}
              </option>
            </select>
          </div>
          <div class="filter-box">
            <label><strong>Year</strong></label>
            <input
              id="year-selector"
              type="number"
              min="2020"
              max="2099"
              v-model="state.fieldYear"
            />
          </div>
        </div>
      </div>
      <div class="button-container">
        <TableButtonAdd v-if="isAuthorizedToEdit" @click="addRecruitment()">
          Add Recruitment
        </TableButtonAdd>
      </div>
    </div>
    <div class="tablewrapper">
      <table
        v-if="filteredConsultantGroups().length !== 0"
        class="recruitment-table"
      >
        <tbody>
          <tr class="table-border">
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <template
              v-for="consultantGroup in filteredConsultantGroups()"
              :key="consultantGroup.getId()"
            >
              <th colspan="3" :title="getGroupName(consultantGroup)">
                {{ getNames(consultantGroup) }}
              </th>
              <th class="empty-cell">
                <div class="top-line"></div>
              </th>
            </template>
            <th colspan="4" class="total">Total</th>
          </tr>
          <tr class="table-border">
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <template
              v-for="consultantGroup in filteredConsultantGroups()"
              :key="consultantGroup.getId()"
            >
              <th><abbr title="Budget">B</abbr></th>
              <th><abbr title="Prediction">P</abbr></th>
              <th><abbr title="Outcome">O</abbr></th>
              <th class="empty-cell">
                <div class="bottom-line"></div>
              </th>
            </template>
            <th><abbr title="Budget">B</abbr></th>
            <th><abbr title="Prediction">P</abbr></th>
            <th><abbr title="Outcome">O</abbr></th>
            <th>&nbsp;</th>
          </tr>
          <tr class="table-border table-spacer">
            &nbsp;
          </tr>
          <template v-for="months in monthsAndNames" :key="months.month">
            <tr>
              <th class="table-border">
                {{ months.name.slice(0, 3) }}
              </th>
              <template
                v-for="consultantGroup in filteredConsultantGroups()"
                :key="consultantGroup.getId()"
              >
                <RecruitmentsRow
                  :recruitments="
                    filteredRecruitments(consultantGroup.consultantGroupId)
                  "
                  :month="months.month"
                  :year="state.fieldYear"
                  :editRecruitment="
                    isAuthorizedToEdit ? editRecruitment : () => {}
                  "
                />
              </template>
              <RecruitmentsRow
                :recruitments="filteredTotalRecruitments(state.corporationId)"
                :month="months.month"
                :year="state.fieldYear"
                :edit-recruitment="
                  isAuthorizedToEdit ? editRecruitment : () => {}
                "
                :is-total-col="true"
              />
            </tr>
          </template>
          <tr>
            <th class="table-border month-spacer">&nbsp;</th>
            <template
              v-for="consultantGroup in filteredConsultantGroups()"
              :key="consultantGroup.getId()"
            >
              <th class="month-spacer">&nbsp;</th>
              <th class="month-spacer line-cell">
                <div class="left-line"></div>
              </th>
              <th class="month-spacer line-cell">
                <div class="middle-line"></div>
              </th>
              <th class="month-spacer line-cell">
                <div class="right-line"></div>
              </th>
            </template>
            <th class="month-spacer">&nbsp;</th>
            <th class="month-spacer line-cell">
              <div class="left-line"></div>
            </th>
            <th class="month-spacer line-cell">
              <div class="middle-line"></div>
            </th>
            <th class="month-spacer line-cell">
              <div class="right-line"></div>
            </th>
          </tr>
          <tr>
            <th class="table-border">Total</th>
            <template
              v-for="consultantGroup in filteredConsultantGroups()"
              :key="consultantGroup.getId()"
            >
              <RecruitmentsRow
                :recruitments="
                  filteredRecruitments(consultantGroup.consultantGroupId)
                "
                :month="'total'"
                :year="state.fieldYear"
                :edit-recruitment="
                  isAuthorizedToEdit ? editRecruitment : () => {}
                "
              />
            </template>
            <RecruitmentsRow
              :recruitments="filteredTotalRecruitments(state.corporationId)"
              :month="'total'"
              :year="state.fieldYear"
              :edit-recruitment="
                isAuthorizedToEdit ? editRecruitment : () => {}
              "
              :is-total-col="true"
            />
          </tr>
          <tr>
            <th class="table-border remaining">
              <abbr
                title="Subtotal of the amount of consultant to be recruited to meet budget."
                >Remaining</abbr
              >
            </th>
            <template
              v-for="consultantGroup in filteredConsultantGroups()"
              :key="consultantGroup.getId()"
            >
              <RecruitmentsRow
                :recruitments="
                  filteredRecruitments(consultantGroup.consultantGroupId)
                "
                :month="'remaining'"
                :year="state.fieldYear"
                :edit-recruitment="
                  isAuthorizedToEdit ? editRecruitment : () => {}
                "
              />
            </template>
            <RecruitmentsRow
              :recruitments="filteredTotalRecruitments(state.corporationId)"
              :month="'remaining'"
              :year="state.fieldYear"
              :edit-recruitment="
                isAuthorizedToEdit ? editRecruitment : () => {}
              "
              :is-total-col="true"
            />
          </tr>
        </tbody>
      </table>
      <h4 v-if="filteredConsultantGroups().length == 0">No data to display</h4>
    </div>
  </div>

  <BaseModalForm
    :data="recruitmentData"
    :action="state.action"
    :submitEvent="() => $emit('edit')"
    v-if="recruitmentData.isModifying()"
  />
</template>

<script setup lang="ts">
import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Recruitment } from "@/models/Recruitment";
import { MonthsAndNames } from "@/models/enum/Months";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { Action } from "@/store/data/enum/Action";
import { container } from "tsyringe";
import { computed, reactive } from "vue";
import BaseModalForm from "../components/BaseModalForm.vue";
import TableButtonAdd from "../components/TableButtonAdd.vue";
import RecruitmentsRow from "./RecruitmentsRow.vue";

defineOptions({
  name: "RecruitmentsGrid",
});

const { recruitmentData, corporationData, consultantGroupData, settings } =
  store.state;

const state = reactive({
  fieldYear: new Date().getFullYear(),
  action: Action.None,
  corporationId: settings.corporationId,
});

function addRecruitment() {
  state.action = Action.Add;
  recruitmentData.add();
}

function editRecruitment(row?: number) {
  if (!row) {
    return;
  }
  state.action = Action.Edit;
  recruitmentData.edit(row);
}

/** Every consultant group that has one or more recruitment posts */
function filteredConsultantGroups() {
  const anyCorp = state.corporationId == -1;
  return consultantGroupData.rows.filter(
    (consultantGroup) =>
      (anyCorp || state.corporationId === consultantGroup.corporationId) &&
      recruitmentData.some(
        "consultantGroupId",
        consultantGroup.consultantGroupId
      )
  );
}

function filteredRecruitments(consultantGroupId: number): Recruitment[] {
  const output = recruitmentData.rows.filter(
    (recruitment) =>
      recruitment.consultantGroupId === consultantGroupId &&
      recruitment.period.startsWith(state.fieldYear.toString())
  );
  return output;
}

function filteredTotalRecruitments(corporationId: number): Recruitment[] {
  if (corporationId === -1) {
    return recruitmentData.rows;
  }

  const consultantGroupIds = consultantGroupData.findIds(
    "corporationId",
    corporationId
  );

  const recruitments = recruitmentData.findWithValuesInSet(
    "consultantGroupId",
    consultantGroupIds
  );

  return recruitments;
}

function getGroupName(consultantGroup: ConsultantGroup) {
  return consultantGroup.getName();
}

function getNames(consultantGroup: ConsultantGroup) {
  const firstLeaderFullName = consultantGroup.firstLeaderName.split(" ");
  const secondLeaderFullName = consultantGroup.secondLeaderName.split(" ");
  const firstLeaderName =
    firstLeaderFullName[0] + " " + firstLeaderFullName[1].substring(0, 1);
  let secondLeaderName = null;
  if (secondLeaderFullName.length >= 2) {
    secondLeaderName =
      secondLeaderFullName[0] + " " + secondLeaderFullName[1].substring(0, 1);
  }
  if (secondLeaderName !== null) {
    return firstLeaderName + "/" + secondLeaderName;
  } else {
    return firstLeaderName;
  }
}

const companies = computed(() => corporationData.rows);
const monthsAndNames = MonthsAndNames();
const isAuthorizedToEdit = container
  .resolve(AuthorizationManager)
  .editRecruitmentOverview.isAuthorized();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.container {
  @include containertheme($color: $color-black);
  background: none;

  .title-section {
    @include title-section;
  }

  .options-container {
    @include baseTableOptionsContainer();
    overflow: visible;

    .options {
      @include options($border: 1px, $border-radius: 20px);
      position: relative;
      flex-wrap: wrap;
      overflow: visible;
    }

    .options {
      left: 100;
    }

    .options > div {
      margin: 10px;

      .options {
        margin: 10px;
      }
    }

    .button-container {
      padding-left: 2em;
      white-space: nowrap;
    }
  }
  .table-border {
    background-color: $big-table-border-color;
    color: $big-table-text-color;
    height: 40px;
    cursor: default;

    .total {
      border-radius: 0 25px 0 0;
    }

    .empty-cell {
      position: relative;

      .top-line {
        position: absolute;
        top: 5px;
        bottom: 0;
        left: 50%;
        border-left: 1px solid $big-table-text-color;
      }

      .bottom-line {
        position: absolute;
        top: 0;
        bottom: 5px;
        left: 50%;
        border-left: 1px solid $big-table-text-color;
      }
    }
  }

  .table-spacer {
    height: 8px;
    line-height: 8px;
    font-size: 1px;
  }

  .month-spacer {
    position: relative;
    height: 1px;
    line-height: 1px;
    font-size: 1px;

    .left-line {
      position: absolute;
      left: 5px;
      right: 0;
      top: 50%;
      border-bottom: 1px solid $color-lightgrey;
    }

    .middle-line {
      position: absolute;
      left: -5px;
      right: -5px;
      top: 50%;
      border-bottom: 1px solid $color-lightgrey;
    }

    .right-line {
      position: absolute;
      left: 0;
      right: 5px;
      top: 50%;
      border-bottom: 1px solid $color-lightgrey;
    }
  }

  .line-cell {
    background-color: $container-bgc;
    border: 5px solid $container-bgc;
  }

  .remaining {
    font-size: 80%;
  }

  .select-container {
    @include select-radio-containers();
  }

  .filter-box {
    @include sort-box();
    @include form-field;
    width: unset;
    padding-right: 10px;

    strong {
      width: 100%;
      text-align: center;
    }
  }

  h4 {
    margin-top: 50%;
    font-style: italic;
    color: #2c3e50;
  }

  .tablewrapper {
    @include tablewrapper();
    text-align: center;
  }
}
</style>
