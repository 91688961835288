import {
  createCompetencyList,
  createServiceList,
} from "@/coveragePeriodReport/util/createConnectionEntries";
import { ConsultantData } from "@/store/data/ConsultantData";
import { LeaderData } from "@/store/data/LeaderData";
import { NisConsultantData } from "@/store/data/NisConsultantData";
import { RoleData } from "@/store/data/RoleData";
import { UserData } from "@/store/data/UserData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Role } from "../Role";
import { User } from "../User";
import { DisplayableBase } from "./DisplayableBase";
import { RoleDisplayable } from "./RoleDisplayable";
import { ConditionalField } from "./fields/ConditionalField";
import { DropdownField } from "./fields/DropdownField";
import { EmailField } from "./fields/EmailField";
import { NullablePhoneField } from "./fields/NullablePhoneField";
import { NullableTextField } from "./fields/NullableTextField";
import { TableField } from "./fields/TableField";
import { TextField } from "./fields/TextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldName } from "./fields/validators/FieldName";
import { FieldUniqueEmail } from "./fields/validators/FieldUniqueEmail";

export class UserDisplayable extends DisplayableBase<User> {
  @ValidateNested()
  @FieldName()
  firstName: TextField;
  @ValidateNested()
  @FieldName()
  lastName: TextField;
  fullName: TableField;

  position: TableField;
  @ValidateNested()
  role: DropdownField<Role, RoleDisplayable>;
  @ValidateNested()
  coverage: TableField;
  @ValidateNested()
  address: NullableTextField;
  @ValidateNested()
  phoneNr: NullablePhoneField;
  @ValidateNested()
  @FieldUniqueEmail<User, UserDisplayable>()
  email: EmailField<User, UserDisplayable>;
  @ValidateNested()
  active: ConditionalField;
  @ValidateNested()
  keyCompetencies: TableField;
  @ValidateNested()
  competencies: TableField;
  @ValidateNested()
  services: TableField;

  constructor(user: User) {
    super();
    const userData = container.resolve(UserData);
    const consultantData = container.resolve(ConsultantData);
    const leaderData = container.resolve(LeaderData);
    const nisConsultantData = container.resolve(NisConsultantData);
    const roleData = container.resolve(RoleData);
    const { headers } = userData;

    this.firstName = new TextField(headers.firstName, user.firstname, {
      hideInTable: true,
    });
    this.lastName = new TextField(headers.lastName, user.lastname, {
      hideInTable: true,
    });
    this.fullName = new TableField(
      headers.fullName,
      `${user.firstname} ${user.lastname}`
    );
    let position = "Unassigned user";
    const consultant = consultantData.find("userId", user.userId);
    if (consultant) {
      position = "Consultant";
    } else if (leaderData.some("userId", user.userId)) {
      position = "Leader";
    } else if (nisConsultantData.some("userId", user.userId)) {
      position = "NIS Consultant";
    }
    this.position = new TableField(headers.position, position);
    this.role = new DropdownField(
      headers.role,
      user.roleName,
      roleData,
      new DropdownOption(user.roleId, user.roleName),
      {
        isCompoundType: false,
      }
    );
    this.address = new NullableTextField(headers.address, user.address);
    this.phoneNr = new NullablePhoneField(headers.phoneNr, user.phoneNr);
    this.email = new EmailField(headers.email, user.email, userData);
    this.active = new ConditionalField(headers.active, user.active);

    let consultantCoverage = "-";
    let keyCompsList = "-";
    let serviceList = "-";
    let compsList = "-";

    if (consultant) {
      consultantCoverage = consultant.coverage.toString();
      keyCompsList = createCompetencyList(consultant.consultantId, true);
      compsList = createCompetencyList(consultant.consultantId, false);
      serviceList = createServiceList(consultant.consultantId);
    }

    this.coverage = new TableField(headers.coverage, consultantCoverage);
    this.keyCompetencies = new TableField(
      headers.keyCompetencies,
      keyCompsList
    );
    this.competencies = new TableField(headers.competencies, compsList);
    this.services = new TableField(headers.services, serviceList);
  }

  toModel(user: User): void {
    user.firstname = this.firstName.modelValue();
    user.lastname = this.lastName.modelValue();
    user.address = this.address.modelValue();
    user.phoneNr = this.phoneNr.modelValue();
    user.email = this.email.modelValue();
    user.active = this.active.modelValue();
    user.roleId = this.role.selectedOption.id;
  }
}
