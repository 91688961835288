import { IModelBase } from "@/interfaces/IModelBase";
import { Displayable } from "../interfaces/Displayable";
import { IModel, Model } from "../interfaces/Model";

export abstract class ModelBase<T extends Displayable<Model<T>>>
  implements Model<T>, IModel, IModelBase
{
  abstract realize(partialModel: Model<T>): Model<T>;
  abstract getId(): number;
  abstract getName(): string;
  abstract getDisplayable(): T;
}
