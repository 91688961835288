<template>
  <p v-if="state.isLoading" class="text-loading">Calculating...</p>
  <StatisticsDiagram
    v-else
    :can-show-all-corporations="false"
    :options="{ absencePercentage: settings.absencePercentage }"
    :yearly-view="YearlyViewBehavior.AccumulateMonths"
    :table-settings="{
      accumulatedTotal: true,
      showMonthlyTotal: true,
      appendUnit: false,
      fileName: 'Invoice_Forecast',
      columns,
      csvInfoRows: {
        absencePercentage: { text: 'Expected (short term) Absence', unit: '%' },
      },
    }"
    :value-fields="['potential', 'probable', 'signed', 'nis']"
    :colors="{
      potential: {
        color: DiagramColors.POTENTIAL_ASSIGNMENTS,
        description: 'POTENTIAL ASSIGNMENTS',
        textBright: true,
      },
      probable: {
        color: DiagramColors.PROBABLE_ASSIGNMENT,
        description: 'PROBABLE ASSIGNMENTS',
        textBright: false,
      },
      signed: {
        color: DiagramColors.SIGNED_ASSIGNMENT,
        description: 'SIGNED ASSIGNMENTS',
        textBright: true,
      },
      nis: {
        color: DiagramColors.NIS_ASSIGNMENT,
        description: 'NIS ASSIGNMENTS',
        textBright: false,
      },
    }"
    v-model:canUpdate="state.canUpdate"
    :calculate-month="calculateResults"
    :suffix-from-options="getSuffix"
    :prefix-from-options="getPrefix"
    :show-unit-in-bar-tooltip="true"
    title="Invoice Forecast"
    clarification="Each bar represents the total amount invoiced during that period."
  >
    <div class="newRow">
      <UnselectableLabel for="absence-percentage"
        >Expected absences (%)
        <input
          type="number"
          max="100"
          min="0"
          step="0.1"
          id="absence-percentage"
          v-model="settings.absencePercentage"
          @change="state.canUpdate = true"
        />
      </UnselectableLabel>
    </div>
    Assignments displayed:
    <UnselectableLabel for="include-potential" class="checkbox-label">
      <input
        type="checkbox"
        id="include-potential"
        v-model="state.displayPotential"
        @change="state.canUpdate = true"
      />
      Potential
    </UnselectableLabel>
    <UnselectableLabel for="include-probable" class="checkbox-label">
      <input
        type="checkbox"
        id="include-probable"
        v-model="state.displayProbable"
        @change="state.canUpdate = true"
      />
      Probable
    </UnselectableLabel>
    <UnselectableLabel for="include-signed" class="checkbox-label">
      <input
        type="checkbox"
        id="include-signed"
        v-model="state.displaySigned"
        @change="state.canUpdate = true"
      />
      Signed
    </UnselectableLabel>
    <UnselectableLabel for="include-nis" class="checkbox-label">
      <input
        type="checkbox"
        id="include-nis"
        v-model="state.displayNis"
        @change="state.canUpdate = true"
      />
      NIS
    </UnselectableLabel>
  </StatisticsDiagram>
</template>

<script setup lang="ts" generic="T extends { month: Date }, O">
import UnselectableLabel from "@/components/UnselectableLabel.vue";
import { DiagramColors } from "@/coveragePeriodReport/enum/DiagramColors";
import StatisticsDiagram from "@/diagram/StatisticsDiagram.vue";
import {
  InvoiceForecastResult,
  getInvoiceForecast,
} from "@/diagram/functions/calculateInvoiceForecast";
import { YearlyViewBehavior } from "@/diagram/types/YearlyViewBehavior";
import store from "@/store";
import { MonthlyReportManager } from "@/store/MonthlyReportManager";
import { ColumnDef } from "@/types/ColumnDef";
import { MonthKey } from "@/types/DateKey";
import { EventType } from "@/util/EventEmitter";
import { container } from "tsyringe";
import { computed, onBeforeMount, reactive } from "vue";

const state = reactive({
  isLoading: true,
  canUpdate: false,
  displaySigned: true,
  displayProbable: true,
  displayPotential: true,
  displayNis: true,
});
const { currencyData, settings } = store.state;
const monthlyReportManager = container.resolve(MonthlyReportManager);
onBeforeMount(
  async () =>
    await monthlyReportManager.fetchMonthlyReport().then(() => {
      store.state.events.subscribeOrRunIfDispatched(
        EventType.FinishedLoadingTableData,
        () => (state.isLoading = false)
      );
    })
);

type Result = InvoiceForecastResult & {
  month: Date;
};

type Options = {
  absencePercentage: number;
};

const columns = computed(() => {
  const columns = {} as { [key: string]: ColumnDef<Result> };
  if (state.displayPotential) {
    columns["Potential"] = "potential";
  }
  if (state.displayProbable) {
    columns["Probable"] = "probable";
  }
  if (state.displaySigned) {
    columns["Signed"] = "signed";
  }
  if (state.displayNis) {
    columns["NIS"] = "nis";
  }
  if (state.displayProbable && state.displaySigned && state.displayPotential) {
    columns["Signed + Probable"] = (d) => d.signed + d.probable;
  }

  return columns;
});

function calculateResults(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number,
  options?: Options
): Result {
  const result = getInvoiceForecast(month, corporationId, consultantGroupId);

  if (!state.displayPotential) {
    result.potential = 0;
  }
  if (!state.displaySigned) {
    result.signed = 0;
  }
  if (!state.displayProbable) {
    result.probable = 0;
  }
  if (!state.displayNis) {
    result.nis = 0;
  }

  if (options && options.absencePercentage > 0) {
    const modifier = 1 - options.absencePercentage / 100;
    result.signed *= modifier;
    result.potential *= modifier;
    result.probable *= modifier;
    // NIS is not modified by absence
  }

  result.signed = Math.round(result.signed);
  result.probable = Math.round(result.probable);
  result.potential = Math.round(result.potential);
  result.nis = Math.round(result.nis);

  return { ...result, month: new Date(month) };
}

function getPrefix(corporationId: number): string {
  const currency = currencyData.getCompanyCurrencyOrDefault(corporationId);
  return currency.isPrefix ? currency.currencySymbol : "";
}

function getSuffix(corporationId: number): string {
  const currency = currencyData.getCompanyCurrencyOrDefault(corporationId);
  return currency.isPrefix ? "" : ` ${currency.currencySymbol}`;
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.newRow {
  width: 100%;
}

#absence-percentage {
  margin-right: 5px;
  margin-left: 15px;
}

.checkbox-label {
  margin-right: 15px;
}
</style>
