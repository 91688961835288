import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { TableDataOptionsFilter } from "./TableDataOptionsFilter";

export class FilesFilter extends TableDataOptionsFilter {
  public filterSettings: BaseTableFilterMode[] = [
    BaseTableFilterMode.ALL,
    BaseTableFilterMode.HAS_FILES,
    BaseTableFilterMode.NO_FILES,
  ];

  public filterLabel = "Files";
}
