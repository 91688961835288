import { Background } from "@/models/Background";
import { BackgroundDisplayable } from "@/models/displayable/BackgroundDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { BackgroundHeaders } from "./headers/BackgroundHeaders";

@singleton()
export class BackgroundData extends TableData<
  Background,
  BackgroundDisplayable
> {
  constructor() {
    super(
      "Backgrounds",
      new BackgroundHeaders(),
      "Background",
      new Background()
    );
  }
}
