import axios, { AxiosError } from "axios";
import { singleton } from "tsyringe";
import { useStore } from "vuex";
import { MonthlyReportData } from "./data/MonthlyReportData";
import { MonthlyReport } from "./data/types/MonthlyReport";

@singleton()
export class MonthlyReportManager {
  private lastGet: number;
  public responseData: MonthlyReport[];
  private monthlyReport: MonthlyReportData = useStore().state.monthlyReportData;

  constructor() {
    this.lastGet = 0;
    this.responseData = [];
  }
  private get locked(): boolean {
    return Date.now() - this.lastGet < 1000 * 60 * 5;
  }

  private lockTable() {
    this.lastGet = Date.now();
  }

  private unlockTable() {
    this.lastGet = 0;
  }

  async fetchMonthlyReport(): Promise<void> {
    if (!this.locked) {
      this.lockTable();
      await axios
        .get<MonthlyReport[]>("/monthlyReport")
        .then((resp) => {
          this.monthlyReport.data = resp.data;
          this.responseData = resp.data;
        })
        .catch((error: AxiosError) => {
          this.unlockTable();
          this.handleError(error, "get all", "monthly reports");
        });
    }
  }

  async updateMonthlyReport(data: MonthlyReport): Promise<void> {
    return await axios
      .put("/monthlyReport", data)
      .then(() => {
        this.fetchMonthlyReport();
      })
      .catch((err: AxiosError) => {
        this.handleError(err, "update");
        throw err;
      });
  }

  async addMonthlyReport(data: MonthlyReport): Promise<void> {
    return await axios
      .post("/monthlyReport", data)
      .then(() => {
        this.fetchMonthlyReport();
      })
      .catch((err: AxiosError) => {
        this.handleError(err, "add");
        throw err;
      });
  }

  private handleError = (
    err: AxiosError,
    action: string,
    context = "Monthly Reports"
  ) => {
    if (err.response) {
      console.error(`Failed to ${action} ${context}`, err.message);
    } else {
      console.error("Server did not respond");
    }
  };
}
