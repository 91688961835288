import { PriorityDisplayable } from "./displayable/PriorityDisplayable";
import { ModelBase } from "./ModelBase";

export class Priority extends ModelBase<PriorityDisplayable> {
  priorityId: number;
  name: string;
  order: number;

  constructor() {
    super();

    this.priorityId = 0;
    this.name = "";
    this.order = 0;
  }

  realize(partialModel: Priority): Priority {
    const newModel = new Priority();
    newModel.priorityId = partialModel.priorityId;
    newModel.name = partialModel.name;
    newModel.order = partialModel.order;
    return newModel;
  }

  getId(): number {
    return this.priorityId;
  }

  getName(): string {
    return this.name;
  }

  getOrderNumber(): number {
    return this.order;
  }

  getDisplayable(): PriorityDisplayable {
    return new PriorityDisplayable(this);
  }
}
