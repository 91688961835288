<template>
  <div class="container" v-if="parent">
    <!-- The html for the coloured bubbles displaying connections -->
    <div class="connections-container" v-if="connections.length !== 0">
      <button
        class="connection"
        v-for="connection in connections"
        :key="getProp(connection, props.connectionIdField)"
        @click="
          deleteConnectionRow(getProp(connection, props.connectionIdField))
        "
      >
        <div>
          {{ getProp(connection, props.connectionChildNameField) }}
          <fa-icon icon="times" class="del" />
        </div>
      </button>
    </div>
    <div v-else>
      This {{ props.parentData.titleSingular.toLowerCase() }} has no associated
      {{ childData.title.toLowerCase() }}.
    </div>
    <table class="connection-aligner">
      <tbody>
        <tr>
          <td>
            <DisplayableDropdown
              :label="`New ${childData.titleSingular}`"
              :field="childDropdown"
              :key="state.childDropdownUpdateKey.check()"
              :can-add="props.canCreateChildren"
              @input-updated="
                refreshChildButtonState(),
                  state.childDropdownUpdateKey.refresh()
              "
              @button-clicked="addChild()"
            />
          </td>
          <td>
            <button
              id="add-button"
              :disabled="state.disableChildBtn"
              @click="addConnection().then(refreshChildButtonState)"
            >
              Add {{ childData.titleSingular }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <BaseModalForm
    v-if="childDataReactive.isModifying()"
    :data="childDataReactive"
    :action="Action.Add"
    :submitEvent="onFormSubmitChildren"
    :updateDropdownEvent="
      (d) => updateDropdown(d, state.childDropdownUpdateKey)
    "
  />
  <BaseModalDelete
    :data="connectionDataReactive"
    v-if="connectionDataReactive.deleting"
  />
</template>

<script
  setup
  lang="ts"
  generic="M1 extends Model<D1>, M2 extends Model<D2>, M3 extends Model<D3>, D1 extends Displayable<M1>, D2 extends Displayable<M2>, D3 extends Displayable<M3>  & IterableFields<D3, IDisplayableField> & PropsOf<D3, DropdownFieldAny>"
>
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { IDisplayableField } from "@/models/displayable/fields/DisplayableField";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "@/models/displayable/fields/DropdownField";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { TableData } from "@/store/data/TableData";
import { Action } from "@/store/data/enum/Action";
import UpdateKey, { IUpdateKey } from "@/store/data/types/UpdateKey";
import { DropdownFieldAny } from "@/types/DropdownFieldAny";
import { Either } from "@/types/Either";
import { IterableFields } from "@/types/IterableFields";
import { KeyTo } from "@/types/KeyTo";
import { PropsOf } from "@/types/PropsOf";
import { getProp, setProp } from "@/types/getProp";
import { container } from "tsyringe";
import { computed, onMounted, reactive } from "vue";
import BaseModalDelete from "../BaseModalDelete.vue";
import BaseModalForm from "../BaseModalForm.vue";
import DisplayableDropdown from "./DisplayableDropdown.vue";

const props = defineProps<{
  canCreateChildren?: boolean;
  parentId: number;
  parentData: TableData<M1, D1>;
  connectionData: TableData<M2, D2>;
  childData: TableData<M3, D3>;
  parentIdField: KeyTo<M1, number>;
  connectionIdField: KeyTo<M2, number>;
  connectionParentIdField: KeyTo<M2, number>;
  connectionChildIdField: KeyTo<M2, number>;
  connectionChildNameField: KeyTo<M2, string>;
  connectionParentNameField: KeyTo<M2, string>;
  // eslint-disable-next-line
  childSelectionFilter?: DropdownFieldSelectionFilter<M3, D3, any, any>;
}>();

const state = reactive({
  disableChildBtn: true,
  childDropdownUpdateKey: new UpdateKey(),
});

onMounted(refreshChildButtonState);

const childDataReactive = reactive<TableData<M3, D3>>(
  props.childData
) as unknown as TableData<M3, D3>;

const connectionDataReactive = reactive<TableData<M2, D2>>(
  props.connectionData
) as unknown as TableData<M2, D2>;

function deleteConnectionRow(rowId: number) {
  connectionDataReactive.delete(rowId);
  updateDropdown(childDropdown.value, state.childDropdownUpdateKey);
}

async function updateDropdown(
  dropdown: DropdownField<M3, D3>,
  updateKey: IUpdateKey
) {
  await dropdown.updateData();
  dropdown.selectedOption = new DropdownOption(0, "");
  updateKey.refresh();
}

async function onFormSubmit<M extends Model<D>, D extends Displayable<M>>(
  data: TableData<M, D>,
  dropdown: DropdownField<M, D>,
  updateKey: IUpdateKey,
  refreshButtonState: () => Promise<void>
) {
  if (data.hasValidationErrors()) {
    return;
  }

  await data.getRequest();
  let modelId = 0;
  for (const model of data.rows) {
    if (model.getId() > modelId) {
      modelId = model.getId();
    }
  }
  await dropdown.updateData();
  dropdown.generateOptions();
  updateKey.refresh();
  const model = data.findById(modelId);
  dropdown.selectedOption = new DropdownOption<string>(
    model.getId(),
    model.getName()
  );
  refreshButtonState();
}

async function onFormSubmitChildren() {
  await onFormSubmit(
    childDataReactive,
    childDropdown.value,
    state.childDropdownUpdateKey,
    refreshChildButtonState
  );
}

async function refreshChildButtonState() {
  state.disableChildBtn = childDropdown.value.selectedOption.id === 0;
}

async function addConnection(child?: M3) {
  if (childDropdown.value.selectedOption.id === 0) {
    return;
  }
  const partial: Partial<M2> = {};
  setProp(partial, props.connectionParentIdField, props.parentId);
  setProp(partial, props.connectionParentNameField, data.value.getName());
  await addData(connectionDataReactive, partial, {
    dropdownOrItem: child ? { item: child } : { dropdown: childDropdown.value },
    idKey: props.connectionChildIdField,
    nameKey: props.connectionChildNameField,
  });
  await updateDropdown(childDropdown.value, state.childDropdownUpdateKey);
}

function addChild() {
  childDataReactive.add();
}

async function addData(
  data: TableData<M2, D2>,
  misc: Partial<M2>,
  nameAndIdOptions?: {
    idKey: KeyTo<M2, number>;
    nameKey: KeyTo<M2, string>;
    dropdownOrItem: Either<{ item: M3 }, { dropdown: DropdownField<M3, D3> }>;
  }
) {
  if (adding) return;
  adding = true;
  data.add();

  const active = data.activeRow;
  if (!active) {
    return;
  }

  if (nameAndIdOptions) {
    const { dropdownOrItem, idKey, nameKey } = nameAndIdOptions;
    if (dropdownOrItem.dropdown !== undefined) {
      setProp(active, idKey, dropdownOrItem.dropdown.selectedOption.id);
      setProp(active, nameKey, dropdownOrItem.dropdown.selectedOption.label);
    } else {
      setProp(active, idKey, dropdownOrItem.item.getId());
      setProp(active, nameKey, dropdownOrItem.item.getName());
    }
  }

  for (const key in misc) {
    const val = misc[key];
    if (val) {
      active[key] = val;
    }
  }

  await data.saveChanges(active.getDisplayable());
  await data.finished;
  adding = false;
}

let adding = false;

props.parentData.getRequest();

const data = reactive({
  value: props.parentData.findById(props.parentId),
});

const parent = computed(() =>
  props.parentData.find(props.parentIdField, props.parentId)
);

function connectionFilter(currentOption: DropdownOption<string>) {
  return !props.connectionData.some(
    [props.connectionChildIdField, currentOption.id],
    [props.connectionParentIdField, props.parentId]
  );
}

function getConnections(): M2[] {
  state.childDropdownUpdateKey.check();
  return props.connectionData
    .findMany(props.connectionParentIdField, props.parentId)
    .sort((a, b) =>
      getProp(a, props.connectionChildNameField).localeCompare(
        getProp(b, props.connectionChildNameField)
      )
    );
}

function getChildDropdown(): DropdownField<M3, D3> {
  return new DropdownField(
    childDataReactive.titleSingular,
    "Bla",
    childDataReactive,
    new DropdownOption(0, ""),
    {
      optionsFilter: connectionFilter,
      selectionFilter: props.childSelectionFilter,
      isCompoundType: container
        .resolve(AuthorizationManager)
        .editBaseTable.isAuthorized(),
    }
  );
}

const connections = computed(() => getConnections());
const childDropdown = computed(() => getChildDropdown());
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  border: 1px solid $border-color;
  border-radius: 15px;
  @include containertheme(
    $theme-bg: unset,
    $display: inline-block,
    $width: 100%,
    $overflow: auto,
    $margin: unset,
    $text-align: center,
    $color: unset
  );
}

.connections-container {
  display: inline-block;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.connection {
  margin: 1.5px;
  border: 1px solid $border-color;
  border-radius: 15px;
  background: rgb(150, 150, 255);
}

.connection:hover {
  cursor: pointer;
  filter: brightness(150%);
}

#add-button {
  margin: 30% 5px 5px;
  font-size: smaller;
}

.connection-aligner {
  margin: 0 auto;
  width: 99%;

  td {
    padding: 0 5px;
    text-align: left;
  }
}
</style>
