enum MarkDownMark {
  Bold = "**",
  Italic = "*",
  Bullet = "--",
  Heading = "##",
  Link = "link",
  None = "",
}

export default MarkDownMark;
