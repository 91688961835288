import { IOpenDated } from "@/interfaces/Dated";
import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { NisConsultantDisplayable } from "./displayable/NisConsultantDisplayable";

export class NisConsultant
  extends ModelBase<NisConsultantDisplayable>
  implements IOpenDated
{
  nisConsultantId: number;
  startDate: DateKey;
  endDate: DateKey | null;
  nisConsultantGroupId: number;
  userId: number;

  firstname: string;
  lastname: string;
  nisConsultantGroupName: string;
  corporationName: string;
  locationName: string;

  constructor() {
    super();

    this.nisConsultantId = 0;
    this.startDate = "" as DateKey;
    this.endDate = null;
    this.nisConsultantGroupId = 0;
    this.userId = 0;

    this.firstname = "";
    this.lastname = "";
    this.nisConsultantGroupName = "";
    this.corporationName = "";
    this.locationName = "";
  }

  realize(partialModel: NisConsultant): NisConsultant {
    const newModel = new NisConsultant();

    newModel.nisConsultantId = partialModel.nisConsultantId;
    newModel.startDate = partialModel.startDate;
    newModel.endDate = partialModel.endDate;
    newModel.nisConsultantGroupId = partialModel.nisConsultantGroupId;
    newModel.userId = partialModel.userId;

    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;
    newModel.nisConsultantGroupName = partialModel.nisConsultantGroupName;
    newModel.corporationName = partialModel.corporationName;
    newModel.locationName = partialModel.locationName;

    return newModel;
  }

  getId(): number {
    return this.nisConsultantId;
  }

  getName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  getDisplayable(): NisConsultantDisplayable {
    return new NisConsultantDisplayable(this);
  }

  getGroupName(): string {
    return this.nisConsultantGroupName;
  }
}
