import { Priority } from "@/models/Priority";
import { PriorityDisplayable } from "@/models/displayable/PriorityDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class PriorityOptions extends TableDataOptions<
  Priority,
  PriorityDisplayable
> {
  public constructor() {
    super({
      sorting: {
        Name: { key: "name", column: "name" },
        Order: { key: "order", column: "order" },
      },
    });
  }
}
