import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { TableDataOptionsFilter } from "./TableDataOptionsFilter";

export class ConsultantFilter extends TableDataOptionsFilter {
  public filterSettings: BaseTableFilterMode[] = [
    BaseTableFilterMode.ACTIVE,
    BaseTableFilterMode.INACTIVE,
    BaseTableFilterMode.ALL,
  ];

  public filterLabel = "Status";
}
