import { Model } from "@/interfaces/Model";
import { TableDataValidationError } from "@/types/TableDataValidationError";
import { ValidationError, validate } from "class-validator";
import { Displayable, IDisplayable } from "../../interfaces/Displayable";

export abstract class DisplayableBase<T extends Model<Displayable<T>>>
  implements Displayable<T>, IDisplayable
{
  abstract toModel(modelObject: T): void;

  async validateFields(): Promise<TableDataValidationError[]> {
    return await validate(this).then((errors) => {
      return Array.from(errors, (e) => this.accumulateErrors(e));
    });
  }

  private accumulateErrors(error: ValidationError): TableDataValidationError {
    const accumulator: TableDataValidationError = {
      fieldKey: "",
      description: "",
    };
    accumulator.description = this.accumulateConstraints(error);
    error.children?.forEach((c) => {
      accumulator.description = this.accumulateConstraints(c);
    });

    accumulator.fieldKey = error.property;

    return accumulator;
  }

  private accumulateConstraints(e: ValidationError): string {
    let acc = "";
    for (const key in e.constraints) {
      acc = `${e.constraints[key].toString()}\n`;
    }
    return acc;
  }
}
