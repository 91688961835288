import { CorporationDisplayable } from "./displayable/CorporationDisplayable";
import { ModelBase } from "./ModelBase";

export class Corporation extends ModelBase<CorporationDisplayable> {
  corporationId: number;
  name: string;
  currencyId: number;
  currencyCode: string;

  constructor() {
    super();

    this.corporationId = 0;
    this.name = "";
    this.currencyId = 0;
    this.currencyCode = "";
  }

  realize(partialModel: Corporation): Corporation {
    const newModel = new Corporation();

    newModel.corporationId = partialModel.corporationId;
    newModel.name = partialModel.name;
    newModel.currencyId = partialModel.currencyId;
    newModel.currencyCode = partialModel.currencyCode;

    return newModel;
  }

  getId(): number {
    return this.corporationId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): CorporationDisplayable {
    return new CorporationDisplayable(this);
  }
}
