import { TableHeaders } from "@/interfaces/TableHeaders";
import { BackgroundDisplayable } from "@/models/displayable/BackgroundDisplayable";

export class BackgroundHeaders implements TableHeaders<BackgroundDisplayable> {
  public readonly category = "Category";
  public readonly where = "Where";
  public readonly what = "What";
  public readonly consultant = "Consultant";
  public readonly startDate = "Start date";
  public readonly endDate = "End date";
}
