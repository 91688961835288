<!-- Summary: this is the info text displayed when you click on "Show information" on Assignmnet Overview -->
<template>
  <button
    class="info-toggler"
    title="About filters and ordering"
    @click="toggleInfoText()"
  >
    <div class="toggler-state-text">
      {{ hasInfoText.open ? "Hide information" : "Show information" }}
    </div>
  </button>
  <div class="info-box" v-if="hasInfoText.open">
    <strong>Filters</strong><br />
    <p class="text-border">All</p>
    shows all employed consultants. <br />
    <p class="text-border">Available</p>
    shows all consultants with:
    <ol style="margin-bottom: 0">
      <li>no current or future assignments/absences</li>
      <li>
        ongoing or future assignments/absences where the last assignment ends
        before the chosen time period
      </li>
      <li>
        ongoing or future probable assignment with end date after the chosen
        time period
      </li>
    </ol>
    <p class="text-border">On assignment</p>
    shows all consultants with currently ongoing assignments. <br />
    <br />

    <strong>Exclude total absences</strong>: Excludes consultats who are absent
    over the course of all of the selected time-period. <br />
    <strong>Exclude internship</strong>: Excludes consultants who are interns
    and have no future assignmnets. <br />
    <strong>Hide assignments with files</strong>: Hides assignments with files
    assigned to it. <br />
    <br />

    <strong>Timespan</strong><br />
    <strong>1, 2, 3, 6, 12</strong>: How many months that are shown. <br />
    <strong>Custom</strong>: You get to select a custom start and end date.
    <br />
    <br />

    <strong>Group by</strong><br />
    Group by <strong>name</strong>: The consultants are grouped by the first
    letter of their first name. <br />
    Group by <strong>company</strong>: The consultants are grouped by their
    company. <br />
    Group by <strong>employment date</strong>: The consultants are grouped by
    which month they got employed. <br />
    Group by <strong>customer</strong>: The consultants are grouped by which
    customer they currently work for. <br />
    Group by <strong>largest customer</strong>: The consultants are grouped by
    how many consultants work for a customer. <br />
    Group by <strong>location</strong>: The consultants are grouped by which
    city they operate from. <br />
    Group by <strong>consultant group</strong>: The consultants are grouped by
    their constultant group. <br /><br />

    <strong>Sort by</strong><br />
    Sort by <strong>name</strong>: The consultants will be ordered by their
    first name. <br />
    Sort by <strong>company</strong>: The consultants will be ordered by the
    name of their company and secondarily by their first name <br />
    Sort by <strong>availability</strong>: The consultants will be ordered by
    the total number of available days they have before, after, or between
    assignments within the specified time span. <br />
    Sort by <strong>employment date</strong>: The consultants will be ordered by
    the date when their employment started/starts. <br />
    Sort by <strong>customer</strong>: The consultants will be ordered by
    customer in alphabetical order. <br />
    Sort by <strong>largest customer</strong>: The consultants will be ordered
    descendingly by the size of the customer where they currently have an
    assignment. <br />
    <br />

    <strong>Customer</strong>: Filters the consultants that are assigned to the
    selected customers. <br />
    <strong>Service</strong>: Filters the consultants that provide the selected
    service. <br />
    <strong>Company</strong>: Filters the consultants that are employed by the
    selected company. <br />
    <strong>Location</strong>: Filters the consultants that operate from the
    selected location. <br />
    <strong>Consultant group</strong>: Filters the consultats that belong to the
    selected group. <br />
    <br />

    <strong>On customers with contacts, a dropdown will be shown</strong>
    <br />
    <br />
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
const toggleInfoText = () => {
  hasInfoText.open = !hasInfoText.open;
};
const hasInfoText = reactive({ open: false });
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.info-box {
  padding: 2px;
  text-align: left;
  display: left;
  font-size: 12px;
  padding-bottom: 2px;
}

.text-border {
  border-color: $color-black;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 2px;
  display: inline;
}
.info-toggler {
  margin-left: 30px;
  border: 1px solid $border-color;
  border-radius: 3px;
  &:hover {
    background: $color-lightgrey;
  }
  .toggler-state-text {
    font-weight: bold;
    font-size: 11px;
  }
}
</style>
