import { Leader } from "@/models/Leader";
import { LeaderDisplayable } from "@/models/displayable/LeaderDisplayable";
import { container, singleton } from "tsyringe";
import { ConsultantGroupData } from "./ConsultantGroupData";
import { CustomerData } from "./CustomerData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { LeaderHeaders } from "./headers/LeaderHeaders";

@singleton()
export class LeaderData extends TableData<Leader, LeaderDisplayable> {
  constructor() {
    super("Leaders", new LeaderHeaders(), "Leader", new Leader(), undefined, [
      new TablePointer(
        container.resolve(CustomerData),
        "leaderId",
        DeletePolicy.WithWarning
      ),
      new TablePointer(
        container.resolve(ConsultantGroupData),
        "firstLeaderId",
        DeletePolicy.NeverDelete
      ),
      new TablePointer(
        container.resolve(ConsultantGroupData),
        "secondLeaderId",
        DeletePolicy.NeverDelete
      ),
    ]);
    this._deleteError =
      "This leader may not be deleted! " +
      "It still has consultant group(s) under it! " +
      "Reassign or remove each of its remaining consultant groups";
    this._deleteWarning =
      "Warning! This leader still has customer(s) using it!" +
      "Are you sure you want to delete this leader? " +
      "This will set the responsible of every customer using it to null";
  }
}
