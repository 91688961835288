import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { RecruitmentDisplayable } from "@/models/displayable/RecruitmentDisplayable";
import { Recruitment } from "@/models/Recruitment";
import { isNumberString } from "class-validator";

export class SummaryRowExtension<T extends Model<D>, D extends Displayable<T>> {
  private _headers: string[];
  private _getSums: (rows: T[]) => number[];
  private _disableRow?: (row: T) => boolean;

  public get getSums(): (rows: T[]) => number[] {
    return this._getSums;
  }

  public get disableRow(): (row: T) => boolean {
    return this._disableRow ?? (() => false);
  }

  public get headers(): string[] {
    return this._headers;
  }

  constructor(
    headers: string[],
    getValues: (((row: T) => number) | ((row: T) => string))[],
    disableRow?: (row: T) => boolean
  ) {
    this._headers = headers;
    this._disableRow = disableRow;
    this._getSums = (rows) => {
      const sums = [];
      for (const getValue of getValues) {
        let i = 0;
        for (const row of rows) {
          if (this.disableRow(row)) {
            continue;
          }
          const val = getValue(row);
          if (typeof val == "number") {
            i += val;
          } else if (isNumberString(val)) {
            i += Number(val);
          }
        }
        sums.push(i);
      }
      return sums;
    };
  }

  static recruitmentsExtension(): SummaryRowExtension<
    Recruitment,
    RecruitmentDisplayable
  > {
    return new SummaryRowExtension(
      ["Budget", "Prediction", "Outcome"],
      [
        (row) => row.budget,
        (row) => row.prediction,
        (row) => row.getDisplayable().outcome.value,
      ],
      (row) => {
        const date = new Date(row.period);
        const now = new Date(Date.now());
        return (
          date.getFullYear() < now.getFullYear() ||
          (date.getFullYear() == now.getFullYear() &&
            date.getMonth() < now.getMonth())
        );
      }
    );
  }
}
