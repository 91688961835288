import { AssignmentUrl } from "@/models/AssignmentUrl";
import { AssignmentUrlDisplayable } from "@/models/displayable/AssignmentUrlDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { AssignmentUrlHeaders } from "./headers/AssignmentUrlHeaders";

@singleton()
export class AssignmentUrlData extends TableData<
  AssignmentUrl,
  AssignmentUrlDisplayable
> {
  constructor() {
    super(
      "Assignment Urls",
      new AssignmentUrlHeaders(),
      "AssignmentUrl",
      new AssignmentUrl(),
      "Assignment Url"
    );
    this._deleteError = "This Url may not be deleted!";
  }

  public getUrls(assignmentId: number): AssignmentUrl[] {
    return this.findMany("assignmentId", assignmentId);
  }
}
