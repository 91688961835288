import { CompetencyCategory } from "@/models/CompetencyCategory";
import { CompetencyCategoryDisplayable } from "@/models/displayable/CompetencyCategoryDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class CompetencyCategoryOptions extends TableDataOptions<
  CompetencyCategory,
  CompetencyCategoryDisplayable
> {
  public constructor() {
    super({
      sorting: { Name: "name" },
      filterBoxKeys: ["nameField"],
    });
  }
}
