import { singleton } from "tsyringe";

@singleton()
export default class BrowserData {
  shouldFetchFilters: boolean;
  shouldChangeFetch: boolean;

  constructor() {
    // Retrieves the stored value from the browsers localstorage if the retrieved item is undefined then false is the default value.
    const storedValue = localStorage.getItem("qHn_-8Df;;y2L7#!p9");
    this.shouldChangeFetch = storedValue ? JSON.parse(storedValue) : false;

    // If no value was previously stored, the current value of "shouldChangeFetch" is stored in localStorage
    if (!storedValue) {
      localStorage.setItem(
        "qHn_-8Df;;y2L7#!p9",
        JSON.stringify(this.shouldChangeFetch)
      );
    }
    this.shouldFetchFilters = this.shouldChangeFetch;
  }
}
