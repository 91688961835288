import user, { RoleName } from "@/auth/user";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { KeyTo } from "@/types/KeyTo";
import { IDisplayableField } from "../displayable/fields/DisplayableField";

export abstract class FieldProvider<
  M extends Model<D>,
  D extends Displayable<M>
> implements IFieldProvider
{
  abstract get fieldKeysByRole(): Record<
    RoleName,
    KeyTo<D, IDisplayableField>[] | null
  >;

  isFieldAllowed(key: KeyTo<D, IDisplayableField>): boolean {
    const role = user.state.profile.roleName as RoleName;
    return this.fieldKeysByRole[role]?.includes(key) ?? true;
  }
}

export interface IFieldProvider {
  isFieldAllowed(key: string | number | symbol): boolean;
}
