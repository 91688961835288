import { singleton } from "tsyringe";

@singleton()
export class DecimalUtils {
  /**
   * Returns a number representing a number in fixed-point notation.
   * @param value The value to limit. No effect on strings or integers.
   * @param decimalCount Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
   */
  limitDecimalCount<T extends number | string = number>(
    value: T,
    decimalCount: number
  ): T {
    if (typeof value == "string") {
      return value;
    }
    return Number.isInteger(value)
      ? value
      : (Number(value.toFixed(decimalCount)) as T);
  }

  /**
   * Returns a string replacing decimal separators (,.) with the provided separator.
   * @param value The value to be converted.
   * @param separator String to replace the decimal separator with.
   */
  replaceDecimalSeparator(value: number | string, separator: string) {
    return typeof value == "string"
      ? value.replace(".", separator).replace(",", separator)
      : value.toString().replace(".", separator).replace(",", separator);
  }

  /**
   * Returns a number or string representing a number in fixed-point notation. Also replaces the decimal separator.
   * @param value The value to be converted.
   * @param decimalCount Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
   * @param separator String to replace the decimal separator with.
   */
  limitDecimalCountAndReplaceSeparator(
    value: number | string,
    decimalCount: number,
    separator: string
  ) {
    const limitedValue = this.limitDecimalCount(value, decimalCount);
    return this.replaceDecimalSeparator(limitedValue, separator);
  }
}
