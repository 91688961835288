import { User } from "@/models/User";
import router from "@/router";
import store from "@/store";
import { SettingsManager } from "@/store/SettingsManager";
import axios from "axios";
import { container } from "tsyringe";
import { computed, reactive } from "vue";
import { decrypt } from "./cryptographer";

export type Token = {
  Value: string | null;
};

export type RoleName = "User" | "Admin" | "Admin Read-Only";

export class RoleNames {
  static get user(): RoleName {
    return "User";
  }

  static get admin(): RoleName {
    return "Admin";
  }

  static get adminReadOnly(): RoleName {
    return "Admin Read-Only";
  }

  static get allRoles(): RoleName[] {
    return [this.user, this.admin, this.adminReadOnly];
  }
}

//The state holds the username & profile of the user.
export const state = reactive({
  username: "",
  profile: new User(),
});

//Checks if the user is logged in or not.
export const getters = reactive({
  isLoggedIn: computed(() => state.username !== ""),
  role: computed(() => state.profile.roleName as RoleName),
});

export const actions = {
  /**
   * Persists the login on page refresh/app reload
   */
  async persistLogin(): Promise<void> {
    state.username = localStorage.getItem("user") ?? "";
    if (getters.isLoggedIn) {
      const profileJson = localStorage.getItem("profile");
      if (!profileJson) {
        // should never happen, but nullcheck is forced by eslint
        return actions.logout();
      }
      try {
        const localProfile = JSON.parse(decrypt(profileJson));
        state.profile = new User().realize(localProfile);
        axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
          "jwt"
        )}`;
      } catch (error) {
        console.error(error);
        return actions.logout();
      }
    } else return actions.logout();
  },
  async persistSettings() {
    state.username = localStorage.getItem("user") ?? "";
    const settingsManager = container.resolve(SettingsManager);
    if (getters.isLoggedIn) {
      settingsManager.loadFromLocalStorage();
    }
  },
  async logout() {
    //Reset store state, local storage & Authorization header
    state.username = "";
    state.profile = new User();
    const token: Token = {
      Value: localStorage.getItem("refreshToken"),
    };
    if (token.Value) {
      axios.post("Token/LogoutSingleToken", token);
    }
    localStorage.clear();
    delete axios.defaults.headers.Authorization;
    store.state.reportData.defaultOptions();
    router.push("/"); // to login page
  },
  async logoutAllSessions() {
    await axios.post("Token/RevokeAllUserTokens");
    actions.logout();
  },
};

export default { state, getters, ...actions };
