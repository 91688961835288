<template>
  <div id="power-container">
    <div id="pw-power">
      <span
        :class="{
          grey: score == 0,
          red: score == 1,
          orange: score == 2,
          yellow: score == 3,
          green: score == 4,
        }"
      ></span>
      <span
        :class="{
          grey: score <= 1,
          orange: score == 2,
          yellow: score == 3,
          green: score == 4,
        }"
      ></span>
      <span
        :class="{
          grey: score <= 2,
          yellow: score == 3,
          green: score == 4,
        }"
      ></span>
      <span
        :class="{
          grey: score <= 3,
          green: score == 4,
        }"
      ></span>
    </div>
    <div>
      <span id="power-text">Strength: {{ strengthLevel(score) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import zxcvbn, { ZXCVBNScore } from "zxcvbn";
import StrengthLevel from "./enum/StrengthLevel";

defineOptions({
  name: "TheStrengthMeter",
});
const props = defineProps<{ password: string }>();
/**
 * Returns a password strength estimation object
 * based on a given password.
 *
 * @returns The result of the password strength estimator
 */
const passwordStrength = () => {
  const output = zxcvbn(props.password);
  return output;
};

/**
 * Takes in a password strength score and
 * returns the corresponding strength level.
 *
 * @param score The password strength score
 * @returns The password strength level.
 */
const strengthLevel = (score: ZXCVBNScore) => {
  return StrengthLevel[score];
};

const score = computed(() => passwordStrength().score);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
#power-container {
  margin-bottom: 10px;
}

#pw-power {
  display: flex;
  justify-content: center;
  height: 10px;

  .grey,
  .red,
  .orange,
  .yellow,
  .green {
    height: 3px;
    width: 30px;
    margin-right: 5px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 1px $border-color;
  }
  .grey {
    background: $color-darkgrey;
  }
  .red {
    background: $color-red;
  }
  .orange {
    background: $color-orange;
  }
  .yellow {
    background: $color-yellow;
  }
  .green {
    background: $color-lightgreen;
  }
}

#power-text {
  font-size: 0.9em;
}
</style>
