import { AssignmentBudget } from "@/models/AssignmentBudget";
import { AssignmentBudgetDisplayable } from "@/models/displayable/AssignmentBudgetDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { AssignmentBudgetHeaders } from "./headers/AssignmentBudgetHeaders";

@singleton()
export class AssignmentBudgetData extends TableData<
  AssignmentBudget,
  AssignmentBudgetDisplayable
> {
  constructor() {
    super(
      "Assignment Budget",
      new AssignmentBudgetHeaders(),
      "AssignmentBudget",
      new AssignmentBudget(),
      "Assignment Budget"
    );
  }
}
