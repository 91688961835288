import { CompetencyCategoryData } from "@/store/data/CompetencyCategoryData";
import { CompetencyData } from "@/store/data/CompetencyData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Competency } from "../Competency";
import { CompetencyCategory } from "../CompetencyCategory";
import { CompetencyCategoryDisplayable } from "./CompetencyCategoryDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class CompetencyDisplayable extends DisplayableBase<Competency> {
  @ValidateNested()
  @FieldUniqueText<Competency, CompetencyDisplayable>()
  nameField: UniqueTextField<Competency, CompetencyDisplayable>;

  @ValidateNested()
  categoryField: DropdownField<
    CompetencyCategory,
    CompetencyCategoryDisplayable
  >;

  constructor(competency: Competency) {
    super();
    const competencyData = container.resolve(CompetencyData);
    const { headers } = competencyData;
    this.nameField = new UniqueTextField(
      headers.nameField,
      competency.name,
      competencyData,
      {
        placeholder: "Enter a competency name",
      }
    );
    this.categoryField = new DropdownField<
      CompetencyCategory,
      CompetencyCategoryDisplayable
    >(
      "Category",
      competency.categoryName,
      container.resolve(CompetencyCategoryData),
      new DropdownOption(
        competency.competencyCategoryId,
        competency.categoryName
      )
    );
  }

  toModel(competency: Competency): void {
    competency.name = this.nameField.modelValue();
    competency.categoryName = this.categoryField.selectedOption.label;
    competency.competencyCategoryId = this.categoryField.selectedOption.id;
  }
}
