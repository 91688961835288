import {
  IsOptional,
  IsPhoneNumber,
  ValidationArguments,
} from "class-validator";
import parsePhoneNumber from "libphonenumber-js";
import { DisplayableFieldOptions } from "./DisplayableField";
import { NullableStringField } from "./NullableStringField";
import { FieldType } from "./enum/FieldType";
import { PhoneError } from "./error/PhoneError";

const LOCALE = undefined;

export class NullablePhoneField extends NullableStringField {
  @IsOptional()
  @IsPhoneNumber(LOCALE, {
    message: (args: ValidationArguments) =>
      (args.object as NullablePhoneField)._error.valueError(args.value),
  })
  protected _value: string | null;

  modelValue(): string | null {
    if (!this._value) {
      return this._value;
    }
    const phoneNumber = parsePhoneNumber(this._value)?.number.toString();
    if (!phoneNumber) {
      return null;
    }
    return phoneNumber;
  }

  constructor(
    header: string,
    data: string | null,
    options?: DisplayableFieldOptions
  ) {
    super(header, new PhoneError(header), FieldType.INPUT, options);
    this._value = data;
  }
}
