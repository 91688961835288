import { IClosedDated } from "@/interfaces/Dated";
import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { NisAssignmentDisplayable } from "./displayable/NisAssignmentDisplayable";

export class NisAssignment
  extends ModelBase<NisAssignmentDisplayable>
  implements IClosedDated
{
  nisAssignmentId: number;
  name: string;
  startDate: DateKey;
  endDate: DateKey;
  nisConsultantId: number;

  taskmasterName: string;
  taskmasterId: number;

  firstname: string;
  lastname: string;

  nisServiceName: string;
  nisServiceId: number;

  customerName: string;

  corporationName: string;

  description: string;

  constructor() {
    super();

    this.nisAssignmentId = 0;
    this.name = "";
    this.startDate = "" as DateKey;
    this.endDate = "" as DateKey;
    this.taskmasterId = 0;
    this.nisConsultantId = 0;

    this.taskmasterName = "";
    this.firstname = "";
    this.lastname = "";

    this.nisServiceId = 0;
    this.nisServiceName = "";

    this.customerName = "";
    this.corporationName = "";

    this.description = "";
  }

  realize(partialModel: NisAssignment): NisAssignment {
    const newModel = new NisAssignment();

    newModel.nisAssignmentId = partialModel.nisAssignmentId;
    newModel.name = partialModel.name;
    newModel.startDate = partialModel.startDate;
    newModel.endDate = partialModel.endDate;
    newModel.taskmasterId = partialModel.taskmasterId;
    newModel.nisConsultantId = partialModel.nisConsultantId;

    newModel.taskmasterName = partialModel.taskmasterName;
    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;

    newModel.nisServiceId = partialModel.nisServiceId;
    newModel.nisServiceName = partialModel.nisServiceName;

    newModel.customerName = partialModel.customerName;
    newModel.corporationName = partialModel.corporationName;

    newModel.description = partialModel.description;

    return newModel;
  }

  getId(): number {
    return this.nisAssignmentId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): NisAssignmentDisplayable {
    return new NisAssignmentDisplayable(this);
  }
}
