<template>
  <BaseModal
    title="Auto-Generate Monthly Report Values"
    :danger="false"
    accepttitle="Apply"
    aborttitle="Cancel"
    :accept
    :abort
    modalIcon="calendar"
    size="modal-xl"
    :disableButton="!isAnyCellToggled"
  >
    <p class="clarification">
      Press any Table Cell to select/deselect it.<br />
      Press a Month (e.g. "Feb") to select all cells in that column.<br />
      Press a Name (e.g. "Employed Consultants") to select all cells in that
      row.
    </p>
    <input
      id="autofill-overwrite-checkbox"
      type="checkbox"
      v-model="overwrite"
    />
    <UnselectableLabel for="autofill-overwrite-checkbox"
      >Overwrite existing values</UnselectableLabel
    >
    <br />

    <button
      id="toggle-last-month-button"
      class="toggle-many-button"
      v-if="isLastMonthInView"
      @click="toggleMonth(lastMonth + 1)"
      :disabled="disableLastMonth"
      @mouseover="hoverCurrentMonth(lastMonth + 1, true)"
      @mouseleave="hoverCurrentMonth(lastMonth + 1, false)"
    >
      Toggle Last Month
    </button>
    <button
      id="toggle-current-month-button"
      class="toggle-many-button"
      v-if="isCurrentMonthInView"
      @click="toggleMonth(currentMonth + 1)"
      :disabled="disableCurrentMonth"
      @mouseover="hoverCurrentMonth(currentMonth + 1, true)"
      @mouseleave="hoverCurrentMonth(currentMonth + 1, false)"
    >
      Toggle Current Month
    </button>
    <button
      id="toggle-all-cells-button"
      class="toggle-many-button"
      @click="toggleAll"
    >
      Toggle All Cells
    </button>

    <table class="auto-fill-table">
      <tr>
        <th></th>
        <th
          v-for="{ month } of reports"
          :id="`month-header-${BackendMonth[month]}`"
          :key="month"
          :class="{ togglable: canColumnBeToggled(month) }"
          @click="toggleMonth(month)"
        >
          <UnselectableLabel>{{
            BackendMonth[month].slice(0, 3)
          }}</UnselectableLabel>
        </th>
      </tr>
      <template v-for="(_, key) in defaultFuncs" :key="key">
        <tr class="category" v-if="categoryHeaders.has(key)">
          <td style="text-align: left">
            {{ categoryHeaders.get(key) }}
          </td>
        </tr>
        <tr
          v-if="!isKeyOf(extraReportFuncs, key) && isKeyOf(defaultFuncs, key)"
        >
          <td
            :id="`row-name-${key}`"
            class="row-name"
            :class="{ togglable: canRowBeToggled(key) }"
            @click="toggleRow(key)"
          >
            <UnselectableLabel>
              {{
                ReportDescriptors[key].header +
                (ReportDescriptors[key].isPercent ? " (%)" : "")
              }}
            </UnselectableLabel>
          </td>
          <template v-for="report in props.reports" :key="report.month">
            <td
              @click="toggleCell(cellKey(key, report.month))"
              :id="`value-cell-${key}-${BackendMonth[report.month]}`"
              class="value-cell"
              :class="getCellState(report, key)"
            >
              <UnselectableLabel>
                <template v-if="showOldValueComparison(report, key)">
                  {{ formattedCellValue(report, key) }}<br />↓<br />{{
                    formattedAutoCellValue(report, key)
                  }}
                </template>
                <template v-else>
                  {{ formattedAutoCellValue(report, key) }}
                </template>
              </UnselectableLabel>
            </td>
          </template>
        </tr>
      </template>
    </table>
    <TheColorMap
      v-if="showColorMap"
      bright-text-color="darkgray"
      font-size="16px"
      :start-open="true"
      :content="[
        {
          color: 'white',
          description: 'Same as Old Value',
          textBright: true,
        },
        {
          color: 'white',
          description: 'Not Selected',
        },
        {
          color: 'rgb(196, 255, 232)',
          description: 'Selected',
        },
        {
          color: 'rgb(247, 224, 174)',
          description: 'Selected (will overwrite)',
        },
        {
          color: 'rgb(247, 174, 174)',
          description: 'Selected (will NOT overwrite)',
        },
      ]"
    >
    </TheColorMap>
  </BaseModal>
</template>

<script setup lang="ts">
import { Month } from "@/models/enum/Months";
import {
  BackendMonth,
  CellKey,
  EditableCellKey,
  ExtraReportFields,
  ExtraReportFunc,
  MonthlyReport,
  ReportDescriptors,
  cellKey,
} from "@/store/data/types/MonthlyReport";
import {
  monthFromKey,
  monthKey,
  monthKeyFromYearAndMonth,
  yearFromKey,
} from "@/types/DateKey";
import { PropsAs, PropsOf } from "@/types/PropsOf";
import {
  getAllEntries,
  getAllKeys,
  getProp,
  isKeyOf,
  setProp,
} from "@/types/getProp";
import { DecimalUtils } from "@/util/DecimalUtils";
import { container } from "tsyringe";
import { computed, onMounted, ref, toRef } from "vue";
import BaseModal from "./BaseModal.vue";
import TheColorMap from "./TheColorMap.vue";
import UnselectableLabel from "./UnselectableLabel.vue";

const props = defineProps<{
  accept: () => void;
  abort: () => void;
  extraReportFuncs: Record<ExtraReportFields, ExtraReportFunc>;
  defaultFuncs: PropsAs<
    MonthlyReport,
    number | null,
    (report: MonthlyReport) => number | null
  >;
  reports: MonthlyReport[];
  corporationId: number;
  year: number;
  activeCells: Set<CellKey>;
}>();

const showColorMap = ref(false);

onMounted(() =>
  new Promise((r) => setTimeout(r, 200)).then(() => (showColorMap.value = true))
);

const decimalUtils = container.resolve(DecimalUtils);
const valueMap = new Map<BackendMonth, PropsOf<MonthlyReport, number | null>>();
const overwrite = defineModel<boolean>("overwrite");
const cells = toRef(props.activeCells);
const noDiffCells = new Set<CellKey>();

const isAnyCellToggled = computed(() => {
  return cells.value.size > 0;
});

for (const report of props.reports) {
  const values = {} as PropsOf<MonthlyReport, number | null>;
  for (const { key, value } of getAllEntries(props.defaultFuncs)) {
    let newVal = value(report);
    newVal = newVal != null ? decimalUtils.limitDecimalCount(newVal, 2) : null;
    const oldVal = report[key];
    setProp(values, key, value(report));
    if (oldVal === newVal) {
      noDiffCells.add(cellKey(key, report.month));
    }
  }
  valueMap.set(report.month, values);
}

const categoryHeaders = new Map<EditableCellKey, string>();

let categoryHeader: string | null = null;
for (const { key, value } of getAllEntries(ReportDescriptors)) {
  if (value.categoryHeader) {
    categoryHeader = value.categoryHeader;
  }
  if (categoryHeader && isKeyOf(props.defaultFuncs, key)) {
    categoryHeaders.set(key, categoryHeader);
    categoryHeader = null;
  }
}

if (props.activeCells.size == 0) {
  toggleAll();
}

const currentMonthKey = monthKey(new Date());
const currentMonth = monthFromKey(currentMonthKey);
const currentYear = yearFromKey(currentMonthKey);
const lastMonthKey =
  currentMonth == Month.Jan
    ? monthKeyFromYearAndMonth(currentYear - 1, Month.Dec)
    : monthKeyFromYearAndMonth(currentYear, currentMonth - 1);
const lastMonth = monthFromKey(lastMonthKey);

const isLastMonthInView = yearFromKey(lastMonthKey) == props.year;
const isCurrentMonthInView = yearFromKey(currentMonthKey) == props.year;
const disableLastMonth =
  isLastMonthInView && !canColumnBeToggled(lastMonth + 1);
const disableCurrentMonth =
  isCurrentMonthInView && !canColumnBeToggled(currentMonth + 1);

function toggleCell(key: CellKey) {
  if (noDiffCells.has(key)) {
    return;
  }
  if (!cells.value.delete(key)) {
    cells.value.add(key);
  }
}

function deSelect(key: CellKey) {
  cells.value.delete(key);
}

function select(key: CellKey) {
  cells.value.add(key);
}

function toggleMonth(month: BackendMonth) {
  const func = isColumnSelected(month) ? deSelect : select;
  for (const key of getAllKeys(props.defaultFuncs)) {
    const cell = cellKey(key, month);
    if (!noDiffCells.has(cell)) {
      func(cell);
    }
  }
}

function hoverCurrentMonth(month: BackendMonth, isHovering: boolean) {
  const monthHeader = `month-header-${BackendMonth[month]}`;
  const ids = [
    monthHeader,
    ...getAllKeys(props.defaultFuncs).map(
      (key) => `value-cell-${key}-${BackendMonth[month]}`
    ),
  ];
  for (const id of ids) {
    const element = document.getElementById(id);
    if (isHovering) {
      element?.classList?.add("hovered");
    } else {
      element?.classList.remove("hovered");
    }
  }
}

function toggleRow(key: EditableCellKey) {
  const func = isRowSelected(key) ? deSelect : select;
  for (const { month } of props.reports) {
    const cell = cellKey(key, month);
    if (!noDiffCells.has(cell)) {
      func(cell);
    }
  }
}

function toggleAll() {
  const func = areAllCellsSelected() ? deSelect : select;
  for (const { month } of props.reports) {
    for (const key of getAllKeys(props.defaultFuncs)) {
      const cell = cellKey(key, month);
      if (!noDiffCells.has(cell)) {
        func(cell);
      }
    }
  }
}

function canColumnBeToggled(month: BackendMonth) {
  return !getAllKeys(props.defaultFuncs)
    .map((key) => cellKey(key, month))
    .every((cell) => noDiffCells.has(cell));
}

function canRowBeToggled(key: EditableCellKey) {
  return !props.reports
    .map(({ month }) => cellKey(key, month))
    .every((cell) => noDiffCells.has(cell));
}

function isSelectedOrNoDiff(cell: CellKey) {
  return cells.value.has(cell) || noDiffCells.has(cell);
}

function isRowSelected(key: EditableCellKey): boolean {
  return props.reports
    .map((report) => cellKey(key, report.month))
    .every(isSelectedOrNoDiff);
}

function isColumnSelected(month: BackendMonth): boolean {
  return getAllKeys(props.defaultFuncs)
    .map((key) => cellKey(key, month))
    .every(isSelectedOrNoDiff);
}

function areAllCellsSelected() {
  return getAllKeys(props.defaultFuncs).every(isRowSelected);
}

function getAutoCellValue(report: MonthlyReport, key: EditableCellKey) {
  const found = valueMap.get(report.month);
  if (!found) {
    return null;
  }
  return found ? getProp(found, key) : null;
}

function formattedAutoCellValue(report: MonthlyReport, key: EditableCellKey) {
  const val = getAutoCellValue(report, key);
  if (val == null) {
    return null;
  }
  return decimalUtils.limitDecimalCountAndReplaceSeparator(val, 2, ",");
}

function formattedCellValue(report: MonthlyReport, key: EditableCellKey) {
  let val = report[key] || "";
  val = decimalUtils.limitDecimalCountAndReplaceSeparator(val, 2, ",");
  return ReportDescriptors[key].isPercent ? `${val}%` : val;
}

enum CellState {
  None = "cell-auto-not-selected",
  Selected = "cell-auto-fill",
  SelectedOverwrite = "cell-auto-fill-overwrite",
  SelectedOverwriteDisabled = "cell-auto-fill-overwrite-disabled",
  SameValue = "cell-auto-fill-same",
}

function showOldValueComparison(
  report: MonthlyReport,
  key: EditableCellKey
): boolean {
  return (
    report[key] != null && getCellState(report, key) != CellState.SameValue
  );
}

function getCellState(report: MonthlyReport, key: EditableCellKey): CellState {
  if (noDiffCells.has(cellKey(key, report.month))) {
    return CellState.SameValue;
  }
  if (!cells.value.has(cellKey(key, report.month))) {
    return CellState.None;
  }
  const old = report[key];
  if (old == null) {
    return CellState.Selected;
  }
  if (overwrite.value) {
    return CellState.SelectedOverwrite;
  }
  return CellState.SelectedOverwriteDisabled;
}
</script>

<style scoped lang="scss">
@import "@/styles/global.scss";

table.auto-fill-table {
  @include monthlyReportTable;

  th {
    &:not(.togglable) {
      color: darkgrey;
    }

    border-top: solid $border-color 1px;
    &:first-child {
      border-left: solid $border-color 1px;
    }
    &:nth-child(13) {
      border-right: solid $border-color 1px;
    }
  }

  tr {
    td {
      &:first-child {
        &.row-name {
          text-align: left;
        }
      }

      &.row-name:not(.togglable) {
        color: darkgray;
      }

      &.cell-auto-fill {
        background-color: rgb(196, 255, 232);
      }

      &.cell-auto-fill-same {
        color: darkgray;
      }

      &.cell-auto-fill-overwrite {
        background-color: rgb(247, 224, 174);
      }

      &.cell-auto-fill-overwrite-disabled {
        background-color: rgb(247, 174, 174);
      }
    }

    &.category {
      td {
        user-select: none;
      }
    }
  }

  tr:has(td.togglable:first-child:hover) > td,
  tr:first-child > .month-column:hover,
  &:has(tr:first-child > .togglable:nth-child(2):hover) > tr > :nth-child(2),
  &:has(tr:first-child > .togglable:nth-child(3):hover) > tr > :nth-child(3),
  &:has(tr:first-child > .togglable:nth-child(4):hover) > tr > :nth-child(4),
  &:has(tr:first-child > .togglable:nth-child(5):hover) > tr > :nth-child(5),
  &:has(tr:first-child > .togglable:nth-child(6):hover) > tr > :nth-child(6),
  &:has(tr:first-child > .togglable:nth-child(7):hover) > tr > :nth-child(7),
  &:has(tr:first-child > .togglable:nth-child(8):hover) > tr > :nth-child(8),
  &:has(tr:first-child > .togglable:nth-child(9):hover) > tr > :nth-child(9),
  &:has(tr:first-child > .togglable:nth-child(10):hover) > tr > :nth-child(10),
  &:has(tr:first-child > .togglable:nth-child(11):hover) > tr > :nth-child(11),
  &:has(tr:first-child > .togglable:nth-child(12):hover) > tr > :nth-child(12),
  &:has(tr:first-child > .togglable:nth-child(13):hover) > tr > :nth-child(13) {
    &:not(
        .cell-auto-fill,
        .cell-auto-fill-same,
        .cell-auto-fill-overwrite,
        .cell-auto-fill-overwrite-disabled
      ) {
      background-color: $row-hover-bgc;
    }

    &.cell-auto-fill {
      background-color: rgb(163, 211, 192) !important;
    }

    &.cell-auto-fill-overwrite {
      background-color: rgb(206, 187, 145) !important;
    }

    &.cell-auto-fill-overwrite-disabled {
      background-color: rgb(209, 147, 147) !important;
    }
  }
}

.togglable,
.cell-auto-fill,
.cell-auto-not-selected,
.cell-auto-fill-overwrite,
.cell-auto-fill-overwrite-disabled {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}

.hovered {
  &:not(
      .cell-auto-fill,
      .cell-auto-fill-same,
      .cell-auto-fill-overwrite,
      .cell-auto-fill-overwrite-disabled
    ) {
    background-color: $row-hover-bgc !important;
  }

  &.cell-auto-fill {
    background-color: rgb(163, 211, 192) !important;
  }

  &.cell-auto-fill-overwrite {
    background-color: rgb(206, 187, 145) !important;
  }

  &.cell-auto-fill-overwrite-disabled {
    background-color: rgb(209, 147, 147) !important;
  }
}

#autofill-overwrite-checkbox {
  margin-right: 5px;
}

.toggle-many-button {
  min-width: 200px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.clarification {
  @include clarification;
  text-align: left;
  color: rgb(94, 116, 138);
}
</style>
