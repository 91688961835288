import FileFolder from "@/components/enum/FileFolder";
import FileData from "@/store/data/FileData";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { TextError } from "./error/TextError";

export class FilesField extends DisplayableField<FileData, string> {
  protected _folder: FileFolder;
  protected _id: number;
  protected _value: FileData;

  public get folder(): FileFolder {
    return this._folder;
  }
  protected set folder(value: FileFolder) {
    this._folder = value;
  }

  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  get value(): FileData {
    return this._value;
  }
  set value(input: FileData) {
    this._value = input;
  }

  modelValue(): string {
    return "";
  }

  constructor(
    header: string,
    folder: FileFolder,
    id: number,
    data: FileData,
    options?: DisplayableFieldOptions
  ) {
    super(header, new TextError(header), FieldType.FILES, options);
    this._folder = folder;
    this._id = id;
    this._value = data;
  }
}
