import { Displayable, IDisplayable } from "@/interfaces/Displayable";
import { Dropdown } from "@/interfaces/Dropdown";
import { ITableData } from "@/interfaces/ITableData";
import { Model } from "@/interfaces/Model";
import { IOneToManyField } from "@/models/displayable/fields/OneToManyField";
import { TableData } from "@/store/data/TableData";
import { Action } from "@/store/data/enum/Action";
import {
  DropdownSource,
  FormEditingContextParentKey,
  FormEditingContextParentKeyOneToMany,
  IFormEditingContextParentKey,
} from "./FormEditingContextParentKey";
import { KeyTo } from "./KeyTo";
import { getAllKeys, getProp } from "./getProp";

export interface IFormEditingContext {
  readonly action: Action;
  readonly data: ITableData;
  readonly parent?: IFormEditingContextParentKey;
  readonly displayable: IDisplayable;
  cancel(): void;
  accept(): Promise<boolean>;
  createDisplayable(): IDisplayable;
  addDropdownData(
    key: string | number | symbol,
    dropdownSource: DropdownSource
  ): IFormEditingContext;
  addOrEditOneToMany(
    key: string | number | symbol,
    childId?: number
  ): IFormEditingContext;
}

export class FormEditingContext<M extends Model<D>, D extends Displayable<M>>
  implements IFormEditingContext
{
  readonly displayable;
  constructor(
    public readonly data: TableData<M, D>,
    public readonly action: Action,
    public readonly extraValues?: Partial<M>,
    public readonly parent?: IFormEditingContextParentKey,
    lockedFieldKey?: KeyTo<D, Dropdown>
  ) {
    this.displayable = this.createDisplayable();
    if (lockedFieldKey) {
      getProp(this.displayable, lockedFieldKey).hideInForm = true;
    }
  }

  cancel() {
    this.data.abort();
  }

  async accept(): Promise<boolean> {
    await this.data.saveChanges(this.displayable);

    await this.data.finished;

    if (this.data.hasValidationErrors()) {
      return false;
    }

    if (this.parent) {
      await this.parent.update();
    }

    return true;
  }

  createDisplayable(): D {
    const displayable = this.data.getFailedModifyingData();
    if (displayable) {
      return displayable;
    }
    const model = this.data.activeRow as M;
    if (this.extraValues) {
      for (const key of getAllKeys(this.extraValues)) {
        const val = this.extraValues[key];
        if (val !== undefined) {
          model[key] = val as M[keyof M];
        }
      }
    }
    return model.getDisplayable();
  }

  addDropdownData(
    key: KeyTo<D, Dropdown>,
    dropdownSource: DropdownSource
  ): IFormEditingContext {
    const dropdown = getProp(this.displayable, key);
    let data: ITableData | undefined;
    if (dropdownSource == DropdownSource.Main) {
      data = dropdown.data;
    } else if (dropdownSource == DropdownSource.FirstFilter) {
      data = dropdown.selectionFilterData;
    } else {
      data = dropdown.secondarySelectionFilterData;
    }
    data?.add();
    const parentKey = new FormEditingContextParentKey(
      this.data,
      this.displayable,
      key,
      dropdownSource
    );
    return dropdown.createFormEditingContext(parentKey);
  }

  addOrEditOneToMany<M2 extends Model<D2>, D2 extends Displayable<M2>>(
    key: KeyTo<D, IOneToManyField>,
    childId?: number
  ): IFormEditingContext {
    const oneToMany = getProp(this.displayable, key);
    const { childData } = oneToMany;
    if (childId) {
      childData.edit(childId);
    } else {
      childData.add();
    }

    const parentKey = new FormEditingContextParentKeyOneToMany(
      this.data,
      this.displayable,
      key
    );

    const action = childId ? Action.Edit : Action.Add;
    return oneToMany.createFormEditingContext(parentKey, action);
  }
}
