import { TaskmasterDisplayable } from "../../TaskmasterDisplayable";
import { NullableTextField } from "../NullableTextField";
import { TextField } from "../TextField";
import { VeaValidate } from "./VeaValidator";

export function FieldDifferentThanCustomer() {
  return VeaValidate({
    message(field: TextField | NullableTextField) {
      return field.error.notDifferentNameError(field.value as string);
    },
    validate(field, object: TaskmasterDisplayable) {
      const customerName = object.customer.selectedOption.label;
      return field.value
        ? field.value.toLowerCase() !== customerName.toLowerCase()
        : true;
    },
  });
}
