import Service from "@/models/Service";
import { ServiceDisplayable } from "@/models/displayable/ServiceDisplayable";
import { container, singleton } from "tsyringe";
import { AssignmentData } from "./AssignmentData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { ServiceHeaders } from "./headers/ServiceHeaders";

@singleton()
export class ServiceData extends TableData<Service, ServiceDisplayable> {
  constructor() {
    super(
      "Services",
      new ServiceHeaders(),
      "Service",
      new Service(),
      undefined,
      [
        new TablePointer(
          container.resolve(AssignmentData),
          "serviceId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This service may not be deleted! " +
      "It still has assignment(s) under it! " +
      "Reassign or remove each of its remaining assignments";
  }
}
