import { NonEmptyArray } from "@/util/NonEmptyArray";

export enum Month {
  Jan = 0,
  Feb = 1,
  Mar = 2,
  Apr = 3,
  May = 4,
  Jun = 5,
  Jul = 6,
  Aug = 7,
  Sep = 8,
  Oct = 9,
  Nov = 10,
  Dec = 11,
}

export type MonthName =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December";

// Returns an array of the contract phase and their corresponding names
export function MonthsAndNames(): NonEmptyArray<MonthAndName> {
  return [
    { name: "January", month: Month.Jan },
    { name: "February", month: Month.Feb },
    { name: "March", month: Month.Mar },
    { name: "April", month: Month.Apr },
    { name: "May", month: Month.May },
    { name: "June", month: Month.Jun },
    { name: "July", month: Month.Jul },
    { name: "August", month: Month.Aug },
    { name: "September", month: Month.Sep },
    { name: "October", month: Month.Oct },
    { name: "November", month: Month.Nov },
    { name: "December", month: Month.Dec },
  ];
}

export function MonthNames(): NonEmptyArray<MonthName> {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
}

export function Months(): NonEmptyArray<Month> {
  return [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sep,
    Month.Oct,
    Month.Nov,
    Month.Dec,
  ];
}

export type MonthAndName = {
  name: MonthName;
  month: Month;
};
