<template>
  <input
    :id="thisField.header.toLocaleLowerCase().split(' ').join('') + '-field'"
    type="number"
    class="edit-input"
    v-model="thisField.value"
    :class="hasError && 'error'"
    :onInput="handleInput"
    :size="state.size"
    :min="MIN"
    :max="MAX"
    v-on:input="signalInputUpdate()"
  />
</template>

<script setup lang="ts">
import { NullableNumberField } from "@/models/displayable/fields/NullableNumberField";
import { NumberField } from "@/models/displayable/fields/NumberField";
import { computed, reactive } from "vue";
const props = defineProps<{
  field: NumberField | NullableNumberField;
  hasError?: boolean;
}>();

const emit = defineEmits(["input-updated"]);
const state = reactive({
  size: props.field.value?.toString().length ?? 0,
});
const maxSize = 4;
const changeSize = () => {
  const isFieldEmpty = Number.isNaN(props.field.value);
  if (isFieldEmpty) {
    state.size = 1;
  } else {
    const valueSize = props.field.value?.toString().length ?? 0;
    state.size = valueSize > maxSize ? maxSize : valueSize;
  }
};

const handleInput = () => {
  changeSize();
};

const signalInputUpdate = () => emit("input-updated");

const thisField = computed(() => props.field);
const MIN = Object.getPrototypeOf(props.field).constructor.MIN;
const MAX = Object.getPrototypeOf(props.field).constructor.MAX;
</script>
<style scoped lang="scss">
@import "../../styles/global.scss";
input {
  @include input;
}

.error {
  @include error;
}

//Firefox specific bodge in /assignments
@supports (-moz-appearance: none) {
  input {
    width: 100%;
  }
}
</style>
