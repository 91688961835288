import { ConsultantGroup } from "@/models/ConsultantGroup";
import { MonthsAndNames } from "@/models/enum/Months";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { container } from "tsyringe";
import { ConsultantReportEntry } from "./ConsultantReportEntry";
import ReportFilterOptions from "./ReportFilterOptions";
import { Locales } from "./constant/Locales";
import { AssignmentFilterType } from "./enum/AssignmentFilterType";
import { ConsultantGroupFilterType } from "./enum/ConsultantGroupFilterType";
import { LocationFilterType } from "./enum/LocationFilterType";
import { SortingMode } from "./enum/SortingMode";

/**
 * An instance of filtered data from the main report driver.
 */
export class ReportFilter {
  static filter(
    consultantReportEntries: ConsultantReportEntry[],
    options: ReportFilterOptions,
    startDate: Date,
    endDate: Date
  ): ConsultantReportEntry[] {
    let consultants = consultantReportEntries;

    if (options.corporationFilter != ConsultantGroupFilterType.SHOW_ALL) {
      consultants = this.filterCorporation(
        consultants,
        options.corporationFilter
      );
    }

    consultants = this.filterQuit(consultants, startDate);

    switch (options.mainFilter) {
      default:
      case AssignmentFilterType.SHOW_ALL:
        break;
      case AssignmentFilterType.SHOW_ASSIGNED:
        consultants = this.filterAssignments(consultants, startDate, endDate);
        break;
      case AssignmentFilterType.SHOW_AVAILABLE:
        consultants = this.filterAvailable(consultants, startDate, endDate);
        consultants = consultants.filter(
          (entry) => !entry.isTotallyAbsent(startDate, endDate)
        );
        break;
    }

    if (options.customerFilter !== AssignmentFilterType.SHOW_ALL) {
      consultants = this.filterByCustomer(
        consultants,
        options.customerFilter,
        options.taskmasterFilter,
        startDate,
        endDate
      );
    }

    if (options.consultantGroupFilter !== ConsultantGroupFilterType.SHOW_ALL) {
      consultants = this.filterByConsultantGroup(
        consultants,
        options.consultantGroupFilter
      );
    }

    if (options.locationFilter !== LocationFilterType.SHOW_ALL) {
      consultants = this.filterByLocation(consultants, options.locationFilter);
    }

    if (options.serviceFilter !== AssignmentFilterType.SHOW_ALL) {
      consultants = this.filterByService(
        consultants,
        options.serviceFilter,
        startDate,
        endDate
      );
    }

    if (options.phaseFilter !== AssignmentFilterType.SHOW_ALL) {
      consultants = this.filterByPhase(
        consultants,
        options.phaseFilter,
        startDate,
        endDate
      );
    }

    if (options.canExcludeTotalAbsence) {
      consultants = consultants.filter(
        (entry) => !entry.isTotallyAbsent(startDate, endDate)
      );
    }

    if (options.canExcludeInternship) {
      consultants = consultants.filter(
        (entry) => !entry.hasInternship(startDate, endDate)
      );
    }

    if (options.canExcludeNonBillable) {
      consultants = consultants.filter(
        (entry) => !entry.hasNonBillable(startDate, endDate)
      );
    }

    return consultants;
  }

  static sort(
    array: ConsultantReportEntry[],
    primarySort: SortingMode | undefined,
    secondarySort: SortingMode
  ): ConsultantReportEntry[] {
    this.clearSortingGroups(array);
    // Tertiary sort (always alphabetical)
    this.singleSort(array, SortingMode.CONSULTANT, false);
    // Secondary sort
    this.singleSort(array, secondarySort, false);
    // Primary sort
    if (primarySort) {
      this.singleSort(array, primarySort, true);
    }
    return array;
  }

  static singleSort(
    array: ConsultantReportEntry[],
    sort: SortingMode,
    doGroup: boolean
  ): ConsultantReportEntry[] {
    switch (sort) {
      case SortingMode.CONSULTANT:
        if (doGroup) {
          array.sort((a, b) =>
            a
              .getName()
              .substring(0, 1)
              .localeCompare(b.getName().substring(0, 1), Locales)
          );
          this.createSortingGroups(array, (a) => a.getName().substring(0, 1));
        } else {
          array.sort((a, b) => a.getName().localeCompare(b.getName(), Locales));
        }
        break;
      case SortingMode.COMPANY:
        array.sort((a, b) =>
          a.corporation.localeCompare(b.corporation, Locales)
        );
        if (doGroup) {
          this.createSortingGroups(array, (a) => a.corporation);
        }
        break;
      case SortingMode.EMPLOYMENT_DATE:
        array.sort((a, b) =>
          this.compareDates(a.getStartDate(), b.getStartDate())
        );
        if (doGroup) {
          this.createSortingGroups(
            array,
            (a) =>
              `${MonthsAndNames()[a.getStartDate().getMonth()].name} ${a
                .getStartDate()
                .getFullYear()}`
          );
        }
        break;
      case SortingMode.AVAILABLE:
        array.sort((a, b) => ConsultantReportEntry.compareAvailability(a, b));
        break;
      case SortingMode.LARGEST_CUSTOMER:
        array = this.setNCoworkers(array);
        array.sort((a, b) => this.sortByLargestCustomerDesc(a, b));
        if (doGroup) {
          this.createSortingGroups(array, (a) => {
            if (a.currentCoworkers != null) {
              return `Customer(s) with ${a.currentCoworkers + 1} consultant${
                a.currentCoworkers !== 0 ? "s" : ""
              }`;
            } else {
              return `Not applicable`;
            }
          });
        }
        break;
      case SortingMode.LOCATION:
        array.sort((a, b) =>
          a.consultantGroupLocationName.localeCompare(
            b.consultantGroupLocationName,
            Locales
          )
        );
        if (doGroup) {
          this.createSortingGroups(array, (a) => a.consultantGroupLocationName);
        }
        break;
      case SortingMode.SERVICE:
        array.sort((a, b) => {
          if (a.currentServiceName && b.currentServiceName) {
            return a.currentServiceName.localeCompare(
              b.currentServiceName,
              Locales
            );
          } else if (a.currentServiceName && !b.currentServiceName) {
            return -1;
          } else if (!a.currentServiceName && b.currentServiceName) {
            return 1;
          } else {
            return 0;
          }
        });
        if (doGroup) {
          this.createSortingGroups(
            array,
            (a) => a.currentServiceName ?? "(none)"
          );
        }
        break;
      case SortingMode.CONSULTANT_GROUP:
        array.sort((a, b) =>
          a.consultantGroupName.localeCompare(b.consultantGroupName, Locales)
        );
        if (doGroup) {
          this.createSortingGroups(array, (a) => a.consultantGroupName);
        }
        break;
      case SortingMode.CUSTOMER:
        array.sort((a, b) => {
          if (a.currentCustomerName && b.currentCustomerName) {
            return a.currentCustomerName.localeCompare(
              b.currentCustomerName,
              Locales
            );
          } else if (a.currentCustomerName && !b.currentCustomerName) {
            return -1;
          } else if (!a.currentCustomerName && b.currentCustomerName) {
            return 1;
          } else {
            return 0;
          }
        });
        if (doGroup) {
          this.createSortingGroups(
            array,
            (a) => a.currentCustomerName ?? "(none)"
          );
        }
        break;
    }
    return array;
  }

  private static createSortingGroups(
    array: ConsultantReportEntry[],
    sortingGroup: (cre: ConsultantReportEntry) => string
  ) {
    this.clearSortingGroups(array);
    let sortingGroupN = 0;
    let sortingGroupIndex = 0;
    for (let i = 0; i < array.length; i++) {
      array[i].sortingGroup = sortingGroup(array[i]);
      if (array[i].sortingGroup != array[i - 1]?.sortingGroup) {
        sortingGroupN++;
        sortingGroupIndex = 0;
      } else {
        sortingGroupIndex++;
      }
      array[i].sortingGroupN = sortingGroupN;
      array[i].sortingGroupIndex = sortingGroupIndex;
    }
  }

  private static clearSortingGroups(array: ConsultantReportEntry[]) {
    array.forEach((a) => {
      a.sortingGroup = null;
      a.sortingGroupN = null;
      a.sortingGroupIndex = null;
    });
  }

  private static filterByService(
    consultantReportEntries: ConsultantReportEntry[],
    serviceFilter: number,
    startDate: Date,
    endDate: Date
  ) {
    return consultantReportEntries.filter((entry) =>
      entry.isServiceProviderInPeriod(serviceFilter, startDate, endDate)
    );
  }

  private static filterByPhase(
    consultantReportEntries: ConsultantReportEntry[],
    phaseFilter: number,
    startDate: Date,
    endDate: Date
  ) {
    return consultantReportEntries.filter((entry) =>
      entry.isPhaseInPeriod(phaseFilter, startDate, endDate)
    );
  }

  private static filterByCustomer(
    consultantReportEntries: ConsultantReportEntry[],
    customerFilter: number,
    taskmasterFilter: number,
    startDate: Date,
    endDate: Date
  ): ConsultantReportEntry[] {
    let consultants = consultantReportEntries;
    if (taskmasterFilter == AssignmentFilterType.SHOW_ALL) {
      consultants = consultantReportEntries.filter((entry) =>
        entry.hasCustomerInPeriod(customerFilter, startDate, endDate)
      );
      return consultants;
    }
    consultants = consultantReportEntries.filter((entry) =>
      entry.hasCustomerAndTaskmasterInPeriod(
        customerFilter,
        taskmasterFilter,
        startDate,
        endDate
      )
    );
    return consultants;
  }

  static filterCorporation(
    consultantReportEntries: ConsultantReportEntry[],
    corporationFilter: number
  ): ConsultantReportEntry[] {
    const consultantGroups: ConsultantGroup[] =
      container.resolve(ConsultantGroupData).rows;
    return consultantReportEntries.filter(
      (consultant) =>
        consultantGroups.find(
          (consultantGroup) =>
            consultantGroup.getId() == consultant.consultantGroupId
        )?.corporationId == corporationFilter
    );
  }

  private static filterQuit(
    consultantReportEntries: ConsultantReportEntry[],
    startDate: Date
  ): ConsultantReportEntry[] {
    return consultantReportEntries.filter(
      (entry) => entry.endDate == null || entry.endDate >= startDate
    );
  }

  private static filterAssignments(
    consultantReportEntries: ConsultantReportEntry[],
    startDate: Date,
    endDate: Date
  ): ConsultantReportEntry[] {
    return consultantReportEntries.filter((entry) =>
      entry.hasAssignment(startDate, endDate)
    );
  }

  private static filterAvailable(
    consultantReportEntries: ConsultantReportEntry[],
    startDate: Date,
    endDate: Date
  ): ConsultantReportEntry[] {
    return consultantReportEntries.filter((entry) =>
      entry.isAvailable(startDate, endDate)
    );
  }

  private static filterByConsultantGroup(
    consultantReportEntries: ConsultantReportEntry[],
    consultantGroupFilter: number
  ): ConsultantReportEntry[] {
    return consultantReportEntries.filter(
      (entry) => entry.consultantGroupId == consultantGroupFilter
    );
  }

  private static filterByLocation(
    consultantReportEntries: ConsultantReportEntry[],
    locationFilter: number
  ): ConsultantReportEntry[] {
    return consultantReportEntries.filter(
      (entry) => entry.consultantGroupLocationId == locationFilter
    );
  }

  public static setIndices(
    consultantReportEntries: ConsultantReportEntry[]
  ): ConsultantReportEntry[] {
    for (let i = 0; i < consultantReportEntries.length; i++) {
      consultantReportEntries[i].reportIndex =
        i + (consultantReportEntries[i].sortingGroupN ?? 0);
    }
    return consultantReportEntries;
  }

  private static compareDates(d1: Date, d2: Date) {
    if (d1 < d2) {
      return -1;
    } else if (d1 > d2) {
      return 1;
    } else {
      return 0;
    }
  }

  private static setNCoworkers(
    entries: ConsultantReportEntry[]
  ): ConsultantReportEntry[] {
    for (const entry of entries) {
      if (entry.currentCustomerId != null) {
        entry.currentCoworkers =
          entries.filter((x) => x.currentCustomerId === entry.currentCustomerId)
            .length - 1;
      }
    }
    return entries;
  }

  private static sortByLargestCustomerDesc(
    a: ConsultantReportEntry,
    b: ConsultantReportEntry
  ): number {
    if (a.currentCoworkers === null && b.currentCoworkers === null) return 0;

    if (a.currentCoworkers === null) return 1;

    if (b.currentCoworkers === null) return -1;

    if (a.currentCoworkers < b.currentCoworkers) return 1;

    if (a.currentCoworkers !== b.currentCoworkers) return -1;

    if (a.currentCustomerId === null) return 1;

    if (b.currentCustomerId === null) return -1;

    if (a.currentCustomerId > b.currentCustomerId) return -1;

    if (a.currentCustomerId < b.currentCustomerId) return 1;

    return 0;
  }
}
