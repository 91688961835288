<template>
  <div class="container">
    <div class="profile-container">
      <div class="details-container">
        <BaseDetails
          :title="profileUser.getName()"
          :tableData="userData"
          :secondaryData="consultantData"
          :id="profileInfo.profileId"
          :secondaryId="consultantId"
          :excludedFields="['fullName']"
          :included-secondary-fields="['certificates', 'aboutMe', 'ambition']"
          :user-id="profileInfo.profileId"
          :detailIcon="hideGenericIcon ? undefined : 'user'"
          :can-edit="isAuthorizedToEdit || profileInfo.isMyOwn"
          :field-provider="fieldProvider"
          :secondary-data-field-provider="secondaryFieldProvider"
        >
          <UserPhoto
            :user-id="profileInfo.profileId"
            v-model="state.profilePhotoDataUrl"
            ref="userPhoto"
          />
        </BaseDetails>

        <button
          class="btn btn-light btn-file"
          v-if="consultantId && (profileInfo.isMyOwn || isAuthorizedToEdit)"
          id="downloadConsultantDocBtn"
          type="button"
          @click="downloadConsultantProfileDoc()"
        >
          <fa-icon icon="download"></fa-icon>
          Generate Consultant Profile Document (BETA)
        </button>

        <FileContainer
          :key="profileInfo.profileId"
          :id="profileInfo.profileId"
          :data="fileData"
          :folder="FileFolder.Profile"
          :canEdit="profileInfo.isMyOwn || isAuthorizedToEdit"
        />
        <UrlContainer
          v-if="consultantId !== undefined"
          :id="consultantId"
          :canEdit="profileInfo.isMyOwn || isAuthorizedToEdit"
          :dataType="UrlType.Consultant"
        />
        <button
          v-if="profileInfo.isMyOwn"
          id="resetPassword"
          class="btn btn-secondary"
          @click="resetPassword()"
        >
          Reset Password
        </button>
        <button
          v-if="profileInfo.isMyOwn"
          id="changePassword"
          class="btn btn-secondary"
          @click="changePassword()"
        >
          Change Password
        </button>
        <!-- This function is currently removed because it doesn't work and is to be replaced with a different function later-->
        <!--<BaseFormPasswordChange
      v-if="profileInfo.isMyOwn"
      :user="profile.value"
      :data="formData"
    />-->
      </div>
      <div class="stats-container" v-if="consultantId != undefined">
        <div class="profile-stats">
          <ul class="tabs">
            <li v-for="tab in tabs" :key="tab.id">
              <a
                @click="changeTab(tab)"
                :id="tab.id.toString()"
                class="tab"
                :class="{ active: activeTab.id == tab.id }"
                >{{ tab.text }}</a
              >
            </li>
          </ul>
          <ProfileTable
            class="profile-table"
            :active-tab="activeTab.id"
            :consultant-id="consultantId"
            :is-my-own="profileInfo.isMyOwn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import userStore from "@/auth/user";
import BaseDetails from "@/components/BaseDetails.vue";
import FileContainer from "@/components/FileContainer.vue";
import UrlContainer from "@/components/UrlContainer.vue";
import FileFolder from "@/components/enum/FileFolder";
import { UrlType } from "@/models/displayable/fields/enum/UrlType";
import { ConsultantFieldProvider } from "@/models/fieldProvider/ConsultantFieldProvider";
import { UserFieldProvider } from "@/models/fieldProvider/UserFieldProvider";
import router from "@/router";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import FileData from "@/store/data/FileData";
import FileDetails from "@/store/data/types/FileDetails";
import { Tab } from "@/types/Tab";
import { container } from "tsyringe";
import { computed, reactive, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import UserPhoto from "../components/UserPhoto.vue";
import ProfileTable from "./ProfileTable.vue";

defineOptions({
  name: "ProfilePage",
  // beforeRouteEnter is not available in <script setup> so we define it here instead.
  beforeRouteEnter: async (to, _from, next) => {
    const { userData, profileInfo } = store.state;
    if (to.params.id) {
      profileInfo.profileId = parseInt(to.params.id as string);
      profileInfo.isMyOwn =
        profileInfo.profileId == userStore.state.profile.userId;
    } else {
      profileInfo.profileId = userStore.state.profile.userId;
      profileInfo.isMyOwn = true;
    }
    let retries: number = 3;
    const retry = async function () {
      await userData
        .getSingleRequest(profileInfo.profileId)
        .then(function (result) {
          if (result) {
            return;
          }
          if (retries == 0) {
            router.push("/error/notFound");
            return;
          }
          retries--;
          setTimeout(() => {
            retry();
          }, 1000);
        });
    };

    await retry();

    next();
  },
});

// we utilize a singleton for keeping track of isMyOwn and profileId since beforeRouteEnter can't access properties defined here
const { userData, consultantData, profileInfo, fileData } = store.state;
const userPhoto = ref<InstanceType<typeof UserPhoto> | null>(null);
const state = reactive({
  profilePhotoDataUrl: "",
});

onBeforeRouteUpdate(async (to, from, next) => {
  if (to.fullPath == from.fullPath) {
    next(false);
    return;
  }

  if (to.params.id) {
    profileInfo.profileId = parseInt(to.params.id as string);
    profileInfo.isMyOwn =
      profileInfo.profileId == userStore.state.profile.userId;
  } else {
    profileInfo.profileId = userStore.state.profile.userId;
    profileInfo.isMyOwn = true;
  }

  const user = await userData.getSingleRequest(profileInfo.profileId);
  if (!user) {
    next("/error/notFound");
    return;
  }

  state.profilePhotoDataUrl = "";
  userPhoto.value?.getUserPhoto();
  next();
});

const tabs = [
  { id: 1, text: "Your Assignments" },
  { id: 2, text: "Shared Customers" },
  { id: 3, text: "Shared Competencies" },
];

const activeTab = ref(tabs[0]);

function changeTab(tab: Tab) {
  activeTab.value = tab;
}

async function downloadConsultantProfileDoc() {
  if (consultantId.value == undefined) {
    return;
  }
  const controllerName = "ConsultantProfileDocument";
  const fileData = new FileData(controllerName);
  const fileDetails: FileDetails = {
    fileName: `consultant_profile_${profileUser.value.getName()}.docx`,
    contentType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    createdOn: "",
    lastModified: "",
    localPath: consultantId.value.toString(),
    size: "",
  };
  await fileData.downloadFile(fileDetails);
}

function resetPassword() {
  router.push("/requestReset");
}

function changePassword() {
  router.push("/changePassword");
}

const hideGenericIcon = computed(
  () =>
    state.profilePhotoDataUrl != "" ||
    userPhoto.value?.state.isLoadingProfilePhoto
);
const profileUser = computed(() => userData.findById(profileInfo.profileId));
const consultantId = computed(
  () => consultantData.find("userId", profileInfo.profileId)?.consultantId
);
const isAuthorizedToEdit = container
  .resolve(AuthorizationManager)
  .editProfile.isAuthorized();
const fieldProvider = container.resolve(UserFieldProvider);
const secondaryFieldProvider = container.resolve(ConsultantFieldProvider);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.profile-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;

  .details-container {
    align-self: flex-start;
    margin: 100px auto;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }

  .stats-container {
    align-self: baseline;
    margin-left: 100px;
    margin-top: 80px;
    top: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    height: max-content;
    overflow: visible;
    padding-bottom: 200px;
  }
}

ul.tabs {
  width: max-content;
  max-width: 100%;
  height: 80px;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

ul.tabs li {
  float: left;
  width: max-content;
  display: flex;
}

ul.tabs li a {
  position: relative;
  height: 50px;
  margin-top: 40px;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: $color-black;
  background: $table-row-alternate-color;
  border: 0px solid $color-black;
  transition: padding 0.2s ease, margin 0.2s ease;
  cursor: pointer;
}

.tabs li:first-child a {
  z-index: 3;
  border-top-left-radius: 8px;
}

.tabs li:nth-child(2) a {
  z-index: 2;
}

.tabs li:last-child a {
  z-index: 1;
  border-top-right-radius: 8px;
}

ul.tabs li a:hover {
  margin: 35px 0 0 0;
  padding-bottom: 15px;
  background-color: $color-nav-text;
}

ul.tabs li a.active {
  margin: 30px 0 0 0;
  padding-bottom: 20px;
  background-color: $vea-primary-color;
  color: $color-white;
  z-index: 4;
  outline: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

@media screen and (max-width: 600px) {
  .profile-container {
    .stats-container {
      margin: 0;
      margin-left: 15px;

      .profile-stats {
        max-width: 100vw;
        margin-top: 0;
      }
    }
  }

  .profile-container {
    .details-container {
      margin-right: 0;
    }
  }

  .profile-table {
    box-sizing: content-box;
  }

  ul.tabs {
    width: max-content;
    height: auto;
    bottom: 0;

    li {
      flex-wrap: wrap;

      a {
        width: 30vw;
        height: max-content;
        font-size: 4vw;
        flex-wrap: wrap;
      }
    }
  }
}

.btn-file {
  @include btn-file();
  margin-bottom: 20px;
}

.btn-secondary {
  margin-top: 10px;
  background-color: $primary-ui-color;
}
</style>
