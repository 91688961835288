import ReportFilterOptions from "@/coveragePeriodReport/ReportFilterOptions";
import { OpenStorageUtils } from "@/util/OpenStorageUtils";
import { container, singleton } from "tsyringe";
import { ReportSetting } from "../ReportSetting";

@singleton()
export class ReportSettingsData {
  private _reportSetting: ReportSetting;

  private _openStorageUtils: OpenStorageUtils;

  constructor() {
    this._reportSetting = container.resolve(ReportSetting);

    this._openStorageUtils = container.resolve(OpenStorageUtils);
  }

  loadReportSettings(): ReportSetting {
    let reportSetting: ReportSetting = container.resolve(ReportSetting);
    reportSetting = this._openStorageUtils.getStorage(1);

    if (reportSetting) {
      const listOfDefaultKeys = [];
      for (const defaultKey in this._reportSetting.options) {
        listOfDefaultKeys.push(defaultKey);
      }
      for (const key in reportSetting.options) {
        if (!listOfDefaultKeys.includes(key)) {
          delete reportSetting.options[key];
        }
      }
      for (const defaultKey of listOfDefaultKeys) {
        if (!(defaultKey in reportSetting.options)) {
          const value = (this._reportSetting.options as ReportFilterOptions)[
            defaultKey
          ];
          reportSetting.options[defaultKey] = value;
        }
      }
      this._reportSetting.options = reportSetting.options;
      this._reportSetting.monthsAhead = reportSetting.monthsAhead;
      this._reportSetting.primarySort = reportSetting.primarySort;
      this._reportSetting.secondarySort = reportSetting.secondarySort;
      this._reportSetting.startDate = reportSetting.startDate;
      this._reportSetting.endDate = reportSetting.endDate;
    }

    return this._reportSetting;
  }

  saveSettings(reportSetting: ReportSetting): void {
    this._openStorageUtils.saveToStorage(reportSetting, 1);
  }
}
