<template>
  <div
    class="filter-container"
    v-if="field.secondarySelectionFilterData && hasFilter"
  >
    Filter by {{ field?.secondarySelectionFilterData?.titleSingular }}
    <div class="filter-select">
      <select
        class="form-select dropdown-secondary-filter"
        v-model="thisField.secondarySelectedFilter"
        :id="label.toLowerCase().replace(' ', '') + '-secondary-filter'"
        v-on:change="thisField.refilter(), emitInputUpdate()"
      >
        <option
          v-for="filter in thisField.secondaryFilterOptions"
          :key="filter.id"
          :value="filter"
        >
          {{ filter.label }}
        </option>
      </select>
      <div class="col-auto">
        <table-button-add
          v-if="field.isCompoundType && canAdd"
          @click="emit('add-secondary-filter-button')"
        >
          Add {{ field?.secondarySelectionFilterData?.titleSingular }}
        </table-button-add>
      </div>
    </div>
  </div>
  <div class="filter-container" v-if="field.filterOptions && hasFilter">
    <small>Filter by {{ field?.selectionFilterData?.titleSingular }}</small>
    <div class="filter-select">
      <select
        class="form-select"
        v-model="thisField.selectedFilter"
        :id="label.toLowerCase().replace(' ', '') + '-filter'"
        v-on:change="thisField.refilter(), emitInputUpdate()"
      >
        <option
          v-for="filter in thisField.filterOptions"
          :key="filter.id"
          :value="filter"
        >
          {{ filter.label }}
        </option>
      </select>
      <div class="col-auto">
        <table-button-add
          v-if="field.isCompoundType && canAdd"
          @click="emit('add-filter-button-clicked')"
        >
          Add {{ field?.selectionFilterData?.titleSingular }}
        </table-button-add>
      </div>
    </div>
  </div>

  <div class="col-auto">
    <select
      class="form-select"
      :class="hasError && 'error'"
      :id="label.toLowerCase().replace(' ', '') + '-select'"
      v-model="thisField.selectedOption"
      v-on:change="emitInputUpdate()"
    >
      <template v-for="option in thisField.options" :key="option.id">
        <option
          :value="option"
          v-if="!field.hideOptionWhen || !field.hideOptionWhen(option)"
        >
          {{ option.label }}
        </option>
      </template>
    </select>
  </div>
  <div class="col-auto">
    <table-button-add
      v-if="field.isCompoundType && canAdd"
      @click="emitButtonClicked()"
    >
      Add {{ field.data.titleSingular }}
    </table-button-add>
  </div>
</template>

<script setup lang="ts" generic="M extends Model<D>, D extends Displayable<M>">
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { DropdownField } from "@/models/displayable/fields/DropdownField";
import { computed, onBeforeMount } from "vue";
import TableButtonAdd from "../TableButtonAdd.vue";

export interface Props<M extends Model<D>, D extends Displayable<M>> {
  field: DropdownField<M, D>;
  label?: string;
  hasError?: boolean;
  canAdd?: boolean;
  hasFilter?: boolean;
}

const props = withDefaults(defineProps<Props<M, D>>(), {
  label: "",
  hasError: false,
  canAdd: true,
  hasFilter: true,
});

const emit = defineEmits([
  "input-updated",
  "button-clicked",
  "add-filter-button-clicked",
  "add-secondary-filter-button",
]);
onBeforeMount(() => props.field.generateOptions());
function emitInputUpdate() {
  emit("input-updated");
}
function emitButtonClicked() {
  emit("button-clicked");
}

const thisField = computed(() => props.field);
emitInputUpdate;
emitButtonClicked;
</script>
<style scoped lang="scss">
@import "../../styles/global.scss";
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.filter-select,
col-auto {
  width: fit-content;
  min-width: 30%;
  margin-bottom: 5px;
}
.filter-select {
  display: flex;
  flex-direction: column;
}
.error {
  @include error;
}
</style>
