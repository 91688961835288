import Invoice from "@/models/Invoice";
import { InvoiceDisplayable } from "@/models/displayable/InvoiceDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { InvoiceHeaders } from "./headers/InvoiceHeaders";

@singleton()
export class InvoiceData extends TableData<Invoice, InvoiceDisplayable> {
  constructor() {
    super("Invoices", new InvoiceHeaders(), "Invoice", new Invoice());
  }
}
