/**
 * Helper function for ensuring that all values of an object are a specific type.
 *
 * @example
 * ```ts
 * const someColors = valuesAre<CssColor>()){
 *   background: "black" // OK
 *   foreground: "12345" // ERROR - "12345" is not assignable to type 'CssColor'
 * });
 * ```
 */
export function valuesAre<P>() {
  return f as <T extends Record<keyof T, P>>(t: T) => Readonly<T>;
}

// eslint-disable-next-line
function f(p: any) {
  return p;
}
