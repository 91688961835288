<template>
  <div class="toast-container">
    <div
      :id="toastId"
      class="toast"
      :class="[{ show: canShow() }]"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <span class="icon-wrap">
          <slot name="icon"> ℹ️ </slot>
        </span>
        <form @submit.prevent class="me-auto">
          <input
            :id="toastId + '-title'"
            aria-label="Message title"
            class="input-title"
            :class="[{ interactive: state.isAuthorized }]"
            :disabled="!state.isAuthorized"
            v-model="state.message.title"
            @focusout="handleFocusOut()"
            type="text"
          />
        </form>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click.prevent="onDismiss()"
        ></button>
      </div>
      <div class="toast-body">
        <form>
          <textarea
            v-if="state.isEditingBody"
            ref="bodyref"
            class="input-content"
            @focusout="handleFocusOut()"
            v-model="state.message.content"
            name=""
            :id="toastId + '-content'"
            cols="37"
            rows="9"
            placeholder="Text-format instructions:
## Heading
**bold content**
*italicized content*
-- bullet point
Link [title](https://www.example.com)"
          ></textarea>
          <div
            class="content-body"
            tabindex="0"
            role="button"
            v-else
            :class="{ interactive: state.isAuthorized }"
            @click="handleBody()"
            @keypress.enter.space="handleBody()"
          >
            <p v-if="!state.message.content">No message...</p>
            <span v-else>New release {{ state.releaseDate }}: </span>

            <template v-for="(portion, i) of messageContent" :key="i">
              <template v-for="(piece, j) of portion" :key="j">
                <h3 v-if="markdown.isHeading(piece.mark)" class="heading">
                  {{ piece.content }}
                </h3>
                <strong v-else-if="markdown.isBold(piece.mark)" class="strong">
                  {{ piece.content }}
                </strong>
                <em
                  v-else-if="markdown.isEmphasis(piece.mark)"
                  class="emphasis"
                >
                  {{ piece.content }}
                </em>
                <span v-else-if="markdown.isBullet(piece.mark)"
                  >&bull; {{ piece.content }}</span
                >
                <a
                  v-else-if="
                    markdown.isLink(piece.mark) && !isString(piece.content)
                  "
                  @click.stop
                  :href="piece.content.url"
                  target="_blank"
                  >{{ piece.content.title }}</a
                >
                <span v-else :class="{ break: !piece.content }">{{
                  piece.content
                }}</span>
              </template>
              <br />
            </template>
          </div>
        </form>
        <div class="mt-2 pt-2 border-top">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            @click="onDismiss()"
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AuthorizationManager } from "@/store/AuthorizationManager";
import MessageService from "@/store/MessageService";
import InfoMessageDefault from "@/store/data/constant/InfoMessageDefault";
import VersionInfo from "@/util/VersionInfo";
import MarkDownParser from "@/util/markdown/MarkDownParser";
import { isString } from "class-validator";
import { container } from "tsyringe";
import { computed, nextTick, onBeforeMount, reactive, ref } from "vue";

const props = defineProps<{ id: string }>();
const previousReleaseShown = `${props.id}-previousReleaseShown`;
const messenger = container.resolve(MessageService);
const versionInfo = container.resolve(VersionInfo);
const state = reactive({
  message: InfoMessageDefault,
  releaseDate: "",
  currentRelease: "",
  isEditingTitle: false,
  isEditingBody: false,
  isAuthorized: false,
  isHidden: false,
});

onBeforeMount(async () => {
  state.message = await messenger.retrieveMessage(1);
  state.currentRelease = versionInfo.releaseNumber();
  state.isAuthorized = checkIsAuthorized();
  state.isHidden =
    localStorage.getItem(previousReleaseShown) === state.currentRelease;
  state.releaseDate = getLatestBuild();
});

const checkIsAuthorized = () => {
  return container.resolve(AuthorizationManager).baseToast.isAuthorized();
};

const canShow = () => {
  return !state.isHidden && state.message.infoMessageId !== 0;
};

const handleBody = () => {
  if (state.isAuthorized) {
    state.isEditingBody = true;
    focusBody();
  }
};

const focusBody = () => {
  nextTick(() => {
    if (bodyref.value) bodyref.value.focus();
  });
};

const handleFocusOut = async () => {
  state.isEditingTitle = false;
  state.isEditingBody = false;
  const response = await messenger.submitEdit(state.message);
  if (response) {
    state.message = response.data;
  }
};

const onDismiss = () => {
  state.isHidden = true;
  localStorage.setItem(previousReleaseShown, state.currentRelease);
};

const getLatestBuild = () => {
  const backendBuildDate = versionInfo.buildBackendDate();
  const frontendBuildDate = versionInfo.buildFrontendDate();
  if (backendBuildDate && frontendBuildDate) {
    return backendBuildDate > frontendBuildDate
      ? backendBuildDate.toLocaleDateString()
      : frontendBuildDate.toLocaleDateString();
  }
  if (backendBuildDate && !frontendBuildDate) {
    return backendBuildDate.toLocaleDateString();
  }
  if (frontendBuildDate && !backendBuildDate) {
    return frontendBuildDate.toLocaleDateString();
  }
  return "unknown date";
};

const bodyref = ref<null | HTMLTextAreaElement>(null);

const markdown = container.resolve(MarkDownParser);

const messageContent = computed(() => markdown.parse(state.message.content));
const toastId = computed(() => props.id);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.toast-container {
  z-index: 1324;

  .toast {
    font-size: 1rem;
    background-color: $transparent-menu-color;

    &:not(.show) {
      opacity: 0;
      transition: all 300ms;
      transform: translateY(-20%);
      pointer-events: none;
    }

    &.show {
      opacity: 1;
      transition: all 300ms;
      transform: translateY(0);
    }

    .icon-wrap {
      margin-right: 0.3rem;
      color: $base-text-color;
    }
  }

  .toast-header {
    background-color: $base-color;
  }

  .strong-title,
  .toast-header > form {
    flex-grow: 1;
    text-align: left;
  }

  .input-title {
    width: 100%;
    padding: 0 2px;
    border: none;
    font-size: 1rem;
    color: $base-text-color;
    font-weight: bold;
    border-radius: 3px;
    background-color: $base-color;

    &.interactive:hover {
      background-color: $transparent-menu-color;
      cursor: pointer;
    }
  }

  textarea {
    background-color: $color-grey;
    border-radius: 3px;
    text-align: center;
  }

  .content-body {
    padding: 2px;
    margin-left: 1px;
    border-radius: 3px;

    .msg-heading {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    h3 {
      font-size: 1.4rem;
      margin-bottom: 0.1rem;
    }

    p {
      margin: 0;
    }

    br {
      display: block;
      margin-bottom: 0rem;
      content: " ";
    }

    .break {
      display: block;
      height: 1rem;
    }

    &:not(.interactive) {
      cursor: unset;
    }

    &.interactive:hover,
    &.interactive:focus {
      background-color: $color-white-transparent;
      outline: 1px solid $border-color;
      cursor: pointer;
    }
  }
}
</style>
