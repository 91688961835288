<template>
  <div class="container">
    <BaseTable
      :data="state.customerData"
      :options="customerOptions"
      :grouped="true"
    />
    <BaseTable :data="state.taskmasterData" :options="taskmasterOptions" />
    <BaseTable :data="state.industryData" :options="industryOptions" />
    <BaseTable :data="state.priorityData" :options="priorityOptions" />
  </div>
  <div class="sidebar">
    <TableOfContents />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import TableOfContents from "@/components/TableOfContents.vue";
import { VeaState } from "@/store";
import { CustomerOptions } from "@/store/data/tableDataOptions/CustomerOptions";
import { IndustryOptions } from "@/store/data/tableDataOptions/IndustryOptions";
import { PriorityOptions } from "@/store/data/tableDataOptions/PriorityOptions";
import { TaskmasterOptions } from "@/store/data/tableDataOptions/TaskmasterOptions";
import { useStore } from "vuex";

defineOptions({
  name: "CustomersTable",
});
const state = useStore<VeaState>().state;
const customerOptions = new CustomerOptions();
const taskmasterOptions = new TaskmasterOptions();
const priorityOptions = new PriorityOptions();
const industryOptions = new IndustryOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}
.sidebar {
  @include sidebar;
}
</style>
