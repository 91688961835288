import { ModelBase } from "./ModelBase";
import { CertificateDisplayable } from "./displayable/CertificateDisplayable";

export class Certificate extends ModelBase<CertificateDisplayable> {
  certificateId: number;
  name: string;

  constructor() {
    super();
    this.certificateId = 0;
    this.name = "";
  }

  realize(partialModel: Certificate): Certificate {
    const newModel = new Certificate();
    newModel.certificateId = partialModel.certificateId;
    newModel.name = partialModel.name;
    return newModel;
  }
  getId(): number {
    return this.certificateId;
  }
  getName(): string {
    return this.name;
  }
  getDisplayable(): CertificateDisplayable {
    return new CertificateDisplayable(this);
  }
}
