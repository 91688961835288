import { CurrencyData } from "@/store/data/CurrencyData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Currency } from "../Currency";
import { DisplayableBase } from "./DisplayableBase";
import { SelectionField } from "./fields/SelectionField";
import { TextField } from "./fields/TextField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class CurrencyDisplayable extends DisplayableBase<Currency> {
  @ValidateNested()
  @FieldUniqueText<Currency, CurrencyDisplayable>()
  code: UniqueTextField<Currency, CurrencyDisplayable>;
  @ValidateNested()
  symbol: TextField;
  @ValidateNested()
  isPrefix: SelectionField<boolean>;

  constructor(currency: Currency) {
    super();
    const currencyData = container.resolve(CurrencyData);
    const { headers } = currencyData;
    this.code = new UniqueTextField(
      headers.code,
      currency.currencyCode,
      currencyData,
      {
        maxLength: 3,
        placeholder: "EUR",
      }
    );
    this.symbol = new TextField(headers.symbol, currency.currencySymbol, {
      maxLength: 3,
      placeholder: "€",
    });
    this.isPrefix = new SelectionField(
      headers.isPrefix,
      [
        ["Prefix", true],
        ["Suffix", false],
      ],
      currency.isPrefix
    );
  }

  toModel(currency: Currency): void {
    currency.currencyCode = this.code.modelValue();
    currency.currencySymbol = this.symbol.modelValue();
    currency.isPrefix = this.isPrefix.modelValue();
  }
}
