import { DateKey } from "@/types/DateKey";
import { Consultant } from "./Consultant";
import { RecruitmentDisplayable } from "./displayable/RecruitmentDisplayable";
import { ModelBase } from "./ModelBase";

export class Recruitment extends ModelBase<RecruitmentDisplayable> {
  recruitmentId: number;
  consultantGroupId: number;
  consultantGroupName: string;
  period: DateKey;
  budget: number;
  prediction: number;
  comment: string | null;

  constructor() {
    super();
    this.recruitmentId = 0;
    this.consultantGroupId = 0;
    this.consultantGroupName = "";
    this.period = "" as DateKey;
    this.budget = 0;
    this.prediction = 0;
    this.comment = "";
  }

  realize(partialModel: Recruitment): Recruitment {
    const newModel = new Recruitment();
    newModel.recruitmentId = partialModel.recruitmentId;
    newModel.consultantGroupId = partialModel.consultantGroupId;
    newModel.consultantGroupName = partialModel.consultantGroupName;
    newModel.period = partialModel.period;
    newModel.budget = partialModel.budget;
    newModel.prediction = partialModel.prediction;
    newModel.comment = partialModel.comment;
    return newModel;
  }

  getId(): number {
    return this.recruitmentId;
  }

  getName(): string {
    return this.consultantGroupName + " " + this.period;
  }

  calcOutcome(amongConsultants: Consultant[]): number {
    const recruitmentDate = new Date(this.period);
    let newConsultants = 0;
    amongConsultants.forEach((consultant) => {
      const startDate = new Date(consultant.startDate);
      if (
        startDate.getMonth() == recruitmentDate.getMonth() &&
        startDate.getFullYear() == recruitmentDate.getFullYear() &&
        this.consultantGroupId == consultant.consultantGroupId
      ) {
        newConsultants++;
      }
    });
    return newConsultants;
  }

  calcDeficit(amongConsultants: Consultant[]): number {
    return this.calcOutcome(amongConsultants) - this.budget;
  }

  getDisplayable(): RecruitmentDisplayable {
    return new RecruitmentDisplayable(this);
  }
}
