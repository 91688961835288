import { Customer } from "@/models/Customer";
import { CustomerDisplayable } from "@/models/displayable/CustomerDisplayable";
import { container, singleton } from "tsyringe";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { TaskmasterData } from "./TaskmasterData";
import { CustomerHeaders } from "./headers/CustomerHeaders";

@singleton()
export class CustomerData extends TableData<Customer, CustomerDisplayable> {
  constructor() {
    super(
      "Customers",
      new CustomerHeaders(),
      "Customer",
      new Customer(),
      undefined,
      [
        new TablePointer(
          container.resolve(TaskmasterData),
          "customerId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This customer may not be deleted! " +
      "It still has taskmaster(s) with assignments under them! " +
      "Reassign or remove each of its remaining taskmasters";
  }
}
