import { IDisplayable } from "@/interfaces/Displayable";
import { DropdownFieldAny } from "@/types/DropdownFieldAny";
import { VeaValidate } from "./VeaValidator";

/**
 * Valid if a Dropdown is disabled or doesn't have a hidden option selected.
 */
export function FieldDisabledOrNotHidden<D extends IDisplayable>() {
  return VeaValidate<D, DropdownFieldAny>({
    message(field) {
      return field.error.emptyError();
    },
    validate(field) {
      return (
        !field.enabled ||
        !field.hideOptionWhen ||
        !field.hideOptionWhen(field.selectedOption)
      );
    },
  });
}
