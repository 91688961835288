import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";

export class EditCompetenciesExtension<
  T extends Model<D>,
  D extends Displayable<T>
> {
  public keyCompetenciesOnly = false;

  constructor(
    public readonly getUserId: (value: T) => number,
    public readonly canHaveCompetencies: (value: T) => boolean = () => true
  ) {}
}
