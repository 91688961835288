import { Consultant } from "@/models/Consultant";
import { ConsultantDisplayable } from "@/models/displayable/ConsultantDisplayable";
import { ConsultantFieldProvider } from "@/models/fieldProvider/ConsultantFieldProvider";
import { container, singleton } from "tsyringe";
import { AbsenceData } from "./AbsenceData";
import { AssignmentData } from "./AssignmentData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { ConsultantHeaders } from "./headers/ConsultantHeaders";

@singleton()
export class ConsultantData extends TableData<
  Consultant,
  ConsultantDisplayable
> {
  constructor() {
    super(
      "Consultants",
      new ConsultantHeaders(),
      "Consultant",
      new Consultant(),
      undefined,
      [
        new TablePointer(
          container.resolve(AbsenceData),
          "consultantId",
          DeletePolicy.WithWarning
        ),
        new TablePointer(
          container.resolve(AssignmentData),
          "consultantId",
          DeletePolicy.WithWarning
        ),
      ],
      {
        fieldProvider: new ConsultantFieldProvider(),
        hasFiles: true,
      }
    );
    this._deleteWarning =
      "Warning! This consultant still has data connected to it!" +
      "Are you sure you want to delete this consultant? " +
      "This will remove every assignment and absence posted under it.";
  }

  public getActive(rows = this.rows): Consultant[] {
    return rows.filter((row) => {
      return (
        new Date(row.startDate).getTime() < Date.now() &&
        (row.endDate ? Date.now() < new Date(row.endDate).getTime() : true)
      );
    });
  }

  public getFuture(rows = this.rows): Consultant[] {
    return rows.filter((row) => {
      return row.endDate ? Date.now() < new Date(row.endDate).getTime() : true;
    });
  }
}
