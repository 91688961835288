import { RoleName } from "@/auth/user";
import { KeyTo } from "@/types/KeyTo";
import { Consultant } from "../Consultant";
import { ConsultantDisplayable } from "../displayable/ConsultantDisplayable";
import { IDisplayableField } from "../displayable/fields/DisplayableField";
import { FieldProvider } from "./FieldProvider";

export class ConsultantFieldProvider extends FieldProvider<
  Consultant,
  ConsultantDisplayable
> {
  fieldKeysByRole: Record<
    RoleName,
    KeyTo<ConsultantDisplayable, IDisplayableField>[] | null
  > = {
    Admin: null,
    User: ["aboutMe", "ambition"],
    "Admin Read-Only": ["aboutMe", "ambition"],
  };
}
