import { IClosedDated } from "@/interfaces/Dated";
import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { BackgroundDisplayable } from "./displayable/BackgroundDisplayable";

export enum BackgroundCategory {
  Eductation = "Education",
  Work = "Work",
  Other = "Other",
}

export class Background
  extends ModelBase<BackgroundDisplayable>
  implements IClosedDated
{
  backgroundId: number;
  category: BackgroundCategory;
  where: string;
  what: string;
  consultantId: number;
  consultantName: string;
  startDate: DateKey;
  endDate: DateKey;

  constructor() {
    super();

    this.backgroundId = 0;
    this.category = BackgroundCategory.Other;
    this.where = "";
    this.what = "";
    this.consultantId = 0;
    this.consultantName = "";
    this.startDate = "" as DateKey;
    this.endDate = "" as DateKey;
  }

  realize(partialModel: Background): Background {
    const newModel = new Background();

    newModel.backgroundId = partialModel.backgroundId;
    newModel.category = partialModel.category;
    newModel.where = partialModel.where;
    newModel.what = partialModel.what;
    newModel.consultantId = partialModel.consultantId;
    newModel.consultantName = partialModel.consultantName;
    newModel.startDate = partialModel.startDate;
    newModel.endDate = partialModel.endDate;

    return newModel;
  }

  getId(): number {
    return this.backgroundId;
  }

  getName(): string {
    return this.where;
  }

  getStartDate(): Date {
    return new Date(this.startDate);
  }

  getEndDate(): Date | null {
    return new Date(this.endDate);
  }

  getDisplayable(): BackgroundDisplayable {
    return new BackgroundDisplayable(this);
  }
}
