import { ContainsEmail } from "@/interfaces/ContainsEmail";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { EmailField } from "../EmailField";
import { VeaValidate } from "./VeaValidator";

export function FieldUniqueEmail<
  M extends Model<D> & ContainsEmail,
  D extends Displayable<M>
>() {
  return VeaValidate<D, EmailField<M, D>>({
    message(field) {
      return field.error.notUniqueError(field.value.toLocaleLowerCase());
    },
    validate(field) {
      const email = field.value.toLocaleLowerCase();
      const { activeRow } = field.tableData;
      if (activeRow && email == activeRow.email.toLocaleLowerCase()) {
        return true;
      }
      return !field.tableData.rows.some(
        (x) => x.email.toLocaleLowerCase() === email
      );
    },
  });
}
