import { BackgroundData } from "@/store/data/BackgroundData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Background, BackgroundCategory } from "../Background";
import { Consultant } from "../Consultant";
import { ConsultantDisplayable } from "./ConsultantDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DateField } from "./fields/DateField";
import { DropdownField } from "./fields/DropdownField";
import { EnumDropdownField } from "./fields/EnumDropdownField";
import { TextAreaField } from "./fields/TextAreaField";
import { TextField } from "./fields/TextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldDateGreaterThan } from "./fields/validators/FieldDateGreaterThan";
import { FieldDateNotAfterToday } from "./fields/validators/FieldDateNotAfterToday";
import { FieldDateNotBefore } from "./fields/validators/FieldDateNotBefore";
import { FieldTextAreaMaxLength } from "./fields/validators/FieldTextAreaMaxLength";

export class BackgroundDisplayable extends DisplayableBase<Background> {
  @ValidateNested()
  category: EnumDropdownField<BackgroundCategory>;

  @ValidateNested()
  where: TextField;

  @ValidateNested()
  @FieldTextAreaMaxLength()
  what: TextAreaField;

  @ValidateNested()
  consultant: DropdownField<Consultant, ConsultantDisplayable>;

  @ValidateNested()
  @FieldDateNotAfterToday()
  @FieldDateNotBefore("2000-01-01")
  startDate: DateField;

  @ValidateNested()
  @FieldDateNotAfterToday()
  @FieldDateGreaterThan("startDate")
  endDate: DateField;

  constructor(background: Background) {
    super();
    const consultantData = container.resolve(ConsultantData);
    const backgroundData = container.resolve(BackgroundData);
    const { headers } = backgroundData;

    this.category = new EnumDropdownField(
      BackgroundCategory,
      headers.category,
      background.category
    );
    this.where = new TextField(headers.where, background.where);
    this.what = new TextAreaField(headers.what, background.what, {
      maxLength: 200,
    });
    this.consultant = new DropdownField(
      headers.consultant,
      background.consultantName,
      consultantData,
      new DropdownOption(background.consultantId, background.consultantName),
      { enabledWhen: () => false }
    );
    this.startDate = new DateField(headers.startDate, background.startDate);
    this.endDate = new DateField(headers.endDate, background.endDate);
  }

  toModel(background: Background): void {
    background.category = this.category.modelValue();
    background.where = this.where.modelValue();
    background.what = this.what.modelValue();
    background.consultantId = this.consultant.selectedOption.id;
    background.startDate = this.startDate.modelValue();
    background.endDate = this.endDate.modelValue();
  }
}
