<template>
  <!-- took template from bootstrap 5 website at https://getbootstrap.com/docs/5.0/components/navbar/ -->
  <nav cy-data="navbar" class="navbar navbar-expand-xl navbar-dark">
    <div class="container-fluid">
      <router-link aria-current="page" to="/home">
        <img width="40" alt="Unicus Logo" src="/unicus-dandelion.svg" />
      </router-link>
      <router-link class="navbar-brand" aria-current="page" to="/home">
        VEA
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        id="nav-toggle"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-0">
          <template v-if="!authorizationManager.viewSetup.isAuthorized()">
            <li class="nav-item" v-if="canRouteTo('/consultants')">
              <router-link class="nav-link listener" to="/consultants">
                Consultants
              </router-link>
            </li>
          </template>
          <template v-else>
            <li
              class="nav-item dropdown"
              @mouseenter="
                shouldHover() ? expandDropdown(NavDropdown.Tables) : null
              "
              @mouseleave="
                shouldHover()
                  ? collapseDropdown(NavDropdown.Tables, 1000)
                  : null
              "
            >
              <a
                cy-data="nav-dropdown"
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarScrollingDropdown"
                role="button"
                aria-expanded="false"
                @click="
                  isButtonDisplayed()
                    ? toggleDropdown(NavDropdown.Tables)
                    : expandDropdown(NavDropdown.Tables)
                "
                @keydown.enter.prevent="toggleDropdown(NavDropdown.Tables)"
              >
                Tables & Search
              </a>
              <ul
                class="dropdown-menu dropdown-tables"
                aria-labelledby="navbarScrollingDropdown"
                :style="tabelsDropdownAnim"
              >
                <li class="nav-item" v-if="canRouteTo('/absences')">
                  <router-link class="nav-link listener" to="/absences">
                    Absences
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/assignments')">
                  <router-link
                    class="nav-link listener"
                    to="/assignments"
                    cy-data="link-assignments"
                  >
                    Assignments
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/budgets')">
                  <router-link class="nav-link listener" to="/budgets">
                    Budgets
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/certificates')">
                  <router-link class="nav-link listener" to="/certificates">
                    Certificates
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/companies')">
                  <router-link class="nav-link listener" to="/companies">
                    Companies
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/competencies')">
                  <router-link class="nav-link listener" to="/competencies">
                    Competencies
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/consultants')">
                  <router-link class="nav-link listener" to="/consultants">
                    Consultants
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/customers')">
                  <router-link class="nav-link listener" to="/customers">
                    Customers
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/monthlyHours')">
                  <router-link class="nav-link listener" to="/monthlyHours">
                    Monthly Hours
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/nis')">
                  <router-link class="nav-link listener" to="/nis">
                    Neuroinclusion Services
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/leaders')">
                  <router-link class="nav-link listener" to="/leaders">
                    Leaders
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/services')">
                  <router-link class="nav-link listener" to="/services">
                    Services
                  </router-link>
                </li>
                <li class="nav-item" v-if="canRouteTo('/users')">
                  <router-link class="nav-link listener" to="/users">
                    Users
                  </router-link>
                </li>
              </ul>
            </li>
          </template>
          <li class="nav-item" v-if="canRouteTo('/assignmentOverview')">
            <router-link class="nav-link listener" to="/assignmentOverview">
              Assignment Overview
            </router-link>
          </li>
          <li class="nav-item" v-if="canRouteTo('/customerOverview')">
            <router-link class="nav-link listener" to="/customerOverview">
              Customer Overview
            </router-link>
          </li>
          <li class="nav-item" v-if="canRouteTo('/recruitments')">
            <router-link class="nav-link listener" to="/recruitments">
              Recruitments
            </router-link>
          </li>
          <li class="nav-item" v-if="canRouteTo('/monthlyReports')">
            <router-link class="nav-link listener" to="/monthlyReports">
              Monthly Reports
            </router-link>
          </li>
          <li
            class="nav-item dropdown"
            @mouseenter="
              shouldHover() ? expandDropdown(NavDropdown.Stats) : null
            "
            @mouseleave="
              shouldHover() ? collapseDropdown(NavDropdown.Stats, 1000) : null
            "
          >
            <a
              cy-data="nav-dropdown"
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarScrollingDropdown"
              role="button"
              aria-expanded="false"
              @click="
                isButtonDisplayed()
                  ? toggleDropdown(NavDropdown.Stats)
                  : expandDropdown(NavDropdown.Stats)
              "
              @keydown.enter.prevent="toggleDropdown(NavDropdown.Stats)"
            >
              Statistics
            </a>
            <ul class="dropdown-menu dropdown-stats" :style="statsDropdownAnim">
              <li class="nav-item" v-if="canRouteTo('/hourlyRateReport')">
                <router-link class="nav-link listener" to="/hourlyRateReport">
                  Hourly Rate Report
                </router-link>
              </li>
              <li class="nav-item" v-if="canRouteTo('/invoiceForecast')">
                <router-link class="nav-link listener" to="/invoiceForecast">
                  Invoice Forecast
                </router-link>
              </li>
              <li class="nav-item" v-if="canRouteTo('/numberOfAssignments')">
                <router-link
                  class="nav-link listener"
                  to="/numberOfAssignments"
                >
                  Number of Assignments
                </router-link>
              </li>
              <li class="nav-item" v-if="canRouteTo('/numberOfCertificates')">
                <router-link
                  class="nav-link listener"
                  to="/numberOfCertificates"
                >
                  Number of Certificates
                </router-link>
              </li>
              <li class="nav-item" v-if="canRouteTo('/numberOfEmployees')">
                <router-link class="nav-link listener" to="/numberOfEmployees">
                  Number of Employees
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link listener" :to="userProfile">
              <fa-icon icon="user-circle" /> My Profile
            </router-link>
          </li>
        </ul>

        <div id="info">{{ versionText }}</div>
        <ul class="navbar-nav mb-0">
          <li class="nav-item">
            <router-link class="nav-link listener" to="/about">
              <fa-icon icon="info-circle" /> About
            </router-link>
          </li>
        </ul>
        <button
          v-if="authorizationManager.settings.isAuthorized()"
          class="btn btn-settings listener"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#settingsOffcanvas"
          aria-controls="settingsOffcanvas"
          title="Settings"
        >
          <fa-icon icon="cog"></fa-icon>
        </button>
        <div v-else id="settings-placeholder"></div>
        <router-link class="btn btn-success btn-sm" to="/logout">
          Logout
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import userStore from "@/auth/user";
import NavDropdown from "@/components/enum/NavDropdown";
import { canRouteTo } from "@/router";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { SettingsManager } from "@/store/SettingsManager";
import { container } from "tsyringe";
import { computed, onMounted, reactive } from "vue";
import VersionInfo from "../util/VersionInfo";

defineOptions({ name: "NavBar" });

const state = reactive({
  isTablesDropdownExpanded: false,
  isStatsDropdownExpanded: false,
  collapseTimer: 0,
  displayTimer: 0,
  isPotraitMode: false,
});

// When component has mounted
// Listener that closes all menus when a menu item is clicked
onMounted(() => {
  const elements = document.querySelectorAll(".listener");
  elements.forEach(function (element) {
    element.addEventListener("click", function () {
      collapseDropdown(NavDropdown.All, 0);
      document
        .getElementById("navbarSupportedContent")
        ?.classList.remove("show");
    });
  });
});

// Handles the mouseenter and leave events
function expandDropdown(dropdown: string) {
  clearTimeout(state.collapseTimer);
  clearTimeout(state.displayTimer);
  const dropdownMenu = document.querySelector(".dropdown-" + dropdown);
  dropdownMenu?.classList.add("expanded");
  switch (dropdown) {
    case NavDropdown.Tables:
      state.isTablesDropdownExpanded = true;
      collapseDropdown(NavDropdown.Stats, 0);
      return;
    case NavDropdown.Stats:
      state.isStatsDropdownExpanded = true;
      collapseDropdown(NavDropdown.Tables, 0);
      return;
  }
}
function collapseDropdown(dropdown: string, time: number) {
  clearTimeout(state.collapseTimer);
  let dropdownMenu: NodeListOf<Element>;
  state.collapseTimer = setTimeout(() => {
    switch (dropdown) {
      case NavDropdown.Tables:
        state.isTablesDropdownExpanded = false;
        dropdownMenu = document.querySelectorAll(".dropdown-" + dropdown);
        break;
      case NavDropdown.Stats:
        state.isStatsDropdownExpanded = false;
        dropdownMenu = document.querySelectorAll(".dropdown-" + dropdown);
        break;
      case NavDropdown.All:
        state.isStatsDropdownExpanded = false;
        state.isTablesDropdownExpanded = false;
        dropdownMenu = document.querySelectorAll(
          `.dropdown-${NavDropdown.Stats}, .dropdown-${NavDropdown.Tables}`
        );
        break;
    }
    state.displayTimer = setTimeout(
      () => {
        dropdownMenu.forEach((element) => {
          element.classList.remove("expanded");
        });
      },
      settings.navHover ? 1000 : 1
    );
  }, time);
}

// Toggle function for when clicking on the dropdown
function toggleDropdown(dropdown: string) {
  switch (dropdown) {
    case NavDropdown.Tables:
      collapseDropdown(NavDropdown.Stats, 0);
      if (state.isTablesDropdownExpanded) {
        collapseDropdown(dropdown, 0);
      } else if (!state.isTablesDropdownExpanded) {
        expandDropdown(dropdown);
      }
      return;
    case NavDropdown.Stats:
      collapseDropdown(NavDropdown.Tables, 0);
      if (state.isStatsDropdownExpanded) {
        collapseDropdown(dropdown, 0);
      } else if (!state.isStatsDropdownExpanded) {
        expandDropdown(dropdown);
      }
      return;
  }
}

// Specific styles for dropdowns that animates the expansion and collapse of the dropdown
const tabelsDropdownAnim = computed(() => ({
  maxHeight: state.isTablesDropdownExpanded ? maxHeight(NavDropdown.Tables) : 0,
  opacity: state.isTablesDropdownExpanded ? 1 : 0,
  transition: shouldHover() ? "max-height 1s ease, opacity 1s ease" : "none",
  overflow: "hidden",
}));
const statsDropdownAnim = computed(() => ({
  maxHeight: state.isStatsDropdownExpanded ? maxHeight(NavDropdown.Stats) : 0,
  opacity: state.isStatsDropdownExpanded ? 1 : 0,
  transition: shouldHover() ? "max-height 1s ease, opacity 1s ease" : "none",
  overflow: "hidden",
}));

const settings = container.resolve(SettingsManager);

// Boolean that returns true if the browser is in potrait mode
// Used to disable mouseenter and leave events when in potrait mode
const isButtonDisplayed = () => {
  const navbarTogglerButton = document.querySelector(".navbar-toggler");
  const buttonDisplayed = navbarTogglerButton
    ? window
        .getComputedStyle(navbarTogglerButton)
        .getPropertyValue("display") !== "none"
    : false;

  if (!settings.navHover) {
    return true;
  } else {
    return buttonDisplayed;
  }
};

const shouldHover = () => {
  const navbarTogglerButton = document.querySelector(".navbar-toggler");
  const buttonDisplayed = navbarTogglerButton
    ? window
        .getComputedStyle(navbarTogglerButton)
        .getPropertyValue("display") !== "none"
    : false;

  if (!buttonDisplayed) {
    return settings.navHover;
  } else {
    return false;
  }
};

// Finds the maxHeight for the specified dropdown, use specific dropdown names in class
const maxHeight = (dropdown: string) => {
  const dropdownMenu = document.querySelector(
    ".dropdown-" + dropdown
  ) as HTMLElement;
  return dropdownMenu.scrollHeight + "px";
};
const authorizationManager = container.resolve(AuthorizationManager);
const userProfile = "/profile/" + userStore.state.profile.userId;
const versionText = computed(() => container.resolve(VersionInfo).fullText());
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.navbar-brand {
  text-indent: 10px;
}

.navbar {
  z-index: 1234;
}

.fa-user-circle {
  width: 1.5rem;
  height: 1.5rem;
  margin: -5px 0 -4px;
}

.navbar {
  background-color: $primary-ui-color;

  .btn-settings {
    color: $color-nav-text;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }

    & > svg {
      pointer-events: none;
    }
  }

  .dropdown-menu {
    position: absolute;
    display: none;
    background-color: $primary-ui-color;
    box-shadow: none;
    text-align: center;
    max-width: 0px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
  }

  .dropdown-stats.expanded {
    display: block;
  }

  .dropdown-tables.expanded {
    display: block;
  }

  #settings-placeholder {
    width: 1rem;
  }
}

.btn-settings {
  &:hover {
    color: $color-grey;
  }

  & > svg {
    pointer-events: none;
  }
}

#info {
  @include info();
  font-size: xx-small;
  color: rgba(255, 255, 255, 0.6);
}
</style>
