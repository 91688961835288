import { LOCALE } from "@/Constant";
import { Absence } from "@/models/Absence";
import { AbsenceData } from "@/store/data/AbsenceData";
import { container } from "tsyringe";
import { ReportEntry } from "./ReportEntry";
import { DiagramColors } from "./enum/DiagramColors";
import { HtmlSummaryBuilder } from "./util/HtmlSummaryBuilder";

export class AbsenceReportEntry extends ReportEntry {
  consultant: string;
  category: string;
  approved: boolean;
  coverage: number;

  hasOverlapThatExceedsMaxCoverage: boolean;

  //overlap in the same timespan as this entry
  hasOverlap: boolean;

  absenceData: AbsenceData;

  constructor(absence: Absence, reportIndex: number) {
    super(
      absence.getId(),
      absence.consultantId,
      reportIndex,
      new Date(absence.startDate),
      new Date(absence.endDate)
    );
    this.category = absence.absenceCategoryName;
    this.consultant = `${absence.firstname} ${absence.lastname}`;
    this.approved = absence.approved;
    this.coverage = absence.coverage;
    this.hasOverlapThatExceedsMaxCoverage = false;
    this.absenceData = container.resolve(AbsenceData);
    this.hasOverlap = false;
  }

  // implement switch on category
  getColor(): string {
    return this.hasOverlapThatExceedsMaxCoverage
      ? DiagramColors.WARNING
      : DiagramColors.ABSENCE;
  }

  getTextColor(): string {
    return DiagramColors.WHITE;
  }

  getRowText(): string {
    return this.category;
  }

  getSummarySize(): number {
    if (this.endDate != null) return 4;
    else return 3;
  }

  getSummaryHtml(): string {
    const builder = new HtmlSummaryBuilder()
      .addField("Consultant", this.consultant)
      .addField("Category", this.category)
      .addField("Approved", this.approved ? "Yes" : "No")
      .addField("Extent", this.coverage.toString() + "%")
      .addField("From", this.startDate.toLocaleDateString(LOCALE));

    if (this.endDate != null) {
      builder.addField("Through", this.endDate.toLocaleDateString(LOCALE));
    }

    return builder.toString();
  }

  deleteEntry(): void {
    this.absenceData.delete(this.internalId);
  }
}
