import { IsString, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { TextError } from "./error/TextError";

export class TextAreaField extends DisplayableField<string, string> {
  get value(): string {
    return this._value;
  }
  set value(input: string) {
    this._value = input;
  }
  modelValue(): string {
    return this._value.trim();
  }

  @IsString({
    message: (args: ValidationArguments) =>
      (args.object as TextAreaField)._error.valueError(args.value),
  })
  protected _value: string;
  public readonly rows: number | undefined;
  public readonly col: number | undefined;
  public readonly wrap: "soft" | "hard" | undefined;

  constructor(
    header: string,
    data: string | null,
    options?: TextAreaFieldOptions
  ) {
    super(header, new TextError(header), FieldType.TEXT_AREA, options);
    this._value = data ?? "";
    this.rows = options?.rows;
    this.col = options?.cols;
    this.wrap = options?.wrap;
  }
}

export class TextAreaFieldOptions extends DisplayableFieldOptions {
  /**
   * Specifies the visible number of lines in a text area
   */
  rows?: number;
  /**
   * Specifies the visible width of a text area
   */
  cols?: number;

  /**
   * Specifies how the text in a text area is to be wrapped
   */
  wrap?: "soft" | "hard";
}
