import { IndustryDisplayable } from "./displayable/IndustryDisplayable";
import { ModelBase } from "./ModelBase";

export class Industry extends ModelBase<IndustryDisplayable> {
  industryId: number;
  name: string;

  constructor() {
    super();

    this.industryId = 0;
    this.name = "";
  }

  realize(partialModel: Industry): Industry {
    const newModel = new Industry();

    newModel.industryId = partialModel.industryId;
    newModel.name = partialModel.name;

    return newModel;
  }

  getId(): number {
    return this.industryId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): IndustryDisplayable {
    return new IndustryDisplayable(this);
  }
}
