<template>
  <div class="container">
    <BaseTable
      :data="state.competencyData"
      :options="options"
      :canEdit="true"
    />
    <BaseTable
      :data="state.competencyCategoryData"
      :options="categoryOptions"
      :canEdit="true"
    />
  </div>
  <div class="sidebar">
    <TableOfContents />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import TableOfContents from "@/components/TableOfContents.vue";
import { VeaState } from "@/store";
import { CompetencyCategoryOptions } from "@/store/data/tableDataOptions/CompetencyCategoryOptions";
import { CompetencyOptions } from "@/store/data/tableDataOptions/CompetencyOptions";
import { useStore } from "vuex";

defineOptions({
  name: "CompetenciesTable",
});
const state = useStore<VeaState>().state;
const options = new CompetencyOptions();
const categoryOptions = new CompetencyCategoryOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}

.sidebar {
  @include sidebar;
}
</style>
