import store from "@/store";
import { FilterBuilder } from "@/store/data/types/FilterBuilder";
import { DateKey, today, yearFromKey } from "@/types/DateKey";
import { Assignment } from "./Assignment";
import { ModelBase } from "./ModelBase";
import { AssignmentBudgetDisplayable } from "./displayable/AssignmentBudgetDisplayable";

export class AssignmentBudget extends ModelBase<AssignmentBudgetDisplayable> {
  assignmentBudgetId: number;
  customerId: number;
  customerName: string;
  corporationId: number;
  corporationName: string;
  year: number;
  budget: number;
  outcome: number | undefined;

  constructor() {
    super();
    this.assignmentBudgetId = 0;
    this.customerId = 0;
    this.customerName = "";
    this.corporationId = 0;
    this.corporationName = "";
    this.year = yearFromKey(today());
    this.budget = 0;
    this.outcome = undefined;
  }

  realize(partialModel: AssignmentBudget): AssignmentBudget {
    const newModel = new AssignmentBudget();
    newModel.assignmentBudgetId = partialModel.assignmentBudgetId;
    newModel.customerId = partialModel.customerId;
    newModel.customerName = partialModel.customerName;
    newModel.corporationId = partialModel.corporationId;
    newModel.corporationName = partialModel.corporationName;
    newModel.year = partialModel.year;
    newModel.budget = partialModel.budget;
    return newModel;
  }

  getId(): number {
    return this.assignmentBudgetId;
  }

  getName(): string {
    return this.customerName + " " + this.year;
  }

  getDisplayable(): AssignmentBudgetDisplayable {
    return new AssignmentBudgetDisplayable(this);
  }

  calcOutcome(referenceDate?: DateKey, forceRefresh = false): number {
    if (this.outcome && !forceRefresh) return this.outcome;
    const outcome = AssignmentBudget.calcOutcome(
      this.customerId,
      this.corporationId,
      referenceDate
    );
    this.outcome = outcome; // caches the number for later use in the same session
    return outcome;
  }

  static calcOutcome(
    customerId: number,
    corporationId?: number,
    referenceDate = today()
  ): number {
    const {
      assignmentData,
      consultantGroupData,
      consultantData,
      taskmasterData,
    } = store.state;

    const taskmasterIds = taskmasterData.findIds("customerId", customerId);
    let consultantIds: Set<number> | undefined = undefined;
    if (corporationId) {
      const consultantGroupIds = consultantGroupData.findIds(
        "corporationId",
        corporationId
      );
      consultantIds = consultantData.findWithValuesInSetIds(
        "consultantGroupId",
        consultantGroupIds
      );
    }

    const filters = new FilterBuilder<Assignment>([
      (a) => taskmasterIds.has(a.taskmasterId),
      (a) => !consultantIds || consultantIds.has(a.consultantId),
      (a) => referenceDate >= a.startDate,
      (a) => referenceDate <= a.endDate,
    ]);

    return filters.filter(assignmentData.rows).length;
  }
}
