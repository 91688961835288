<template>
  <div style="margin: 10% auto">
    <h1>Not authorized</h1>
    <div>
      You don't have permission to view that page.<br />
      <router-link to="/home"
        >Click here to return to the home page</router-link
      >
    </div>
  </div>
</template>
