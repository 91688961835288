import user from "@/auth/user";
import { KeyTo } from "@/types/KeyTo";
import { getAllKeys, setProp } from "@/types/getProp";
import { container, singleton } from "tsyringe";
import { ConsultantData } from "./data/ConsultantData";
import { ConsultantGroupData } from "./data/ConsultantGroupData";
import { LeaderData } from "./data/LeaderData";
import { LocationData } from "./data/LocationData";

export type LocalSettings = {
  navHover: boolean;
  corporationId: number;
  consultantGroupId: number;
  absencePercentage: number;
};

type NullableLocalSettings = {
  [key in keyof LocalSettings]: LocalSettings[key] | null;
};

const defaultLocalSettings: Readonly<LocalSettings> = {
  navHover: false,
  corporationId: -1,
  consultantGroupId: -1,
  absencePercentage: 5,
};

@singleton()
export class SettingsManager implements LocalSettings {
  private localValues: NullableLocalSettings | null = null;
  private values: LocalSettings = {
    navHover: false,
    corporationId: 0,
    consultantGroupId: 0,
    absencePercentage: 0,
  };

  navHover = false;
  corporationId = -1;
  consultantGroupId = -1;
  absencePercentage = 5;

  constructor() {
    for (const key of getAllKeys(this.values)) {
      Object.defineProperty(this, key, {
        get: () => {
          return this.values[key];
        },
        set: (value: LocalSettings[KeyTo<LocalSettings, unknown>]) => {
          setProp(this.values, key, value);
          this.storeValue(key, value);
        },
      });
    }
  }

  loadFromLocalStorage() {
    this.localValues = {
      navHover: this.getStoredBoolean("navHover"),
      corporationId: this.getStoredNumber("corporationId"),
      consultantGroupId: this.getStoredNumber("consultantGroupId"),
      absencePercentage: this.getStoredNumber("absencePercentage"),
    };

    for (const key of getAllKeys(defaultLocalSettings)) {
      setProp(this.values, key, defaultLocalSettings[key]);
    }

    for (const key of getAllKeys(this.localValues)) {
      const val = this.localValues[key] ?? defaultLocalSettings[key];
      setProp(this.values, key, val);
      this.storeValue(key, val);
    }
  }

  private storeValue<T>(key: KeyTo<LocalSettings, T>, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private getStoredNumber(key: KeyTo<LocalSettings, number>) {
    const local = localStorage.getItem(key);
    if (local == null) {
      return null;
    }
    const n = Number(local);
    if (isNaN(n)) {
      localStorage.removeItem(key);
      return null;
    }
    return n;
  }

  private getStoredBoolean(key: KeyTo<LocalSettings, boolean>): boolean | null {
    switch (localStorage.getItem(key)) {
      case null:
        return null;
      case "true":
        return true;
      case "false":
        return false;
      default:
        localStorage.removeItem(key);
        return null;
    }
  }

  async setDefaultsFromUserConnections() {
    const hasCorporationId = this.localValues?.corporationId != null;
    const hasConsultantGroupId = this.localValues?.consultantGroupId != null;

    if (hasCorporationId && hasConsultantGroupId) {
      return;
    }

    const userId = user.state.profile.getId();
    const consultantData = container.resolve(ConsultantData);
    const consultantGroupData = container.resolve(ConsultantGroupData);
    const leaderData = container.resolve(LeaderData);
    const locationData = container.resolve(LocationData);

    const consultant = consultantData.find("userId", userId);
    if (consultant) {
      const { consultantGroupId } = consultant;
      if (!hasConsultantGroupId) {
        this.consultantGroupId = consultantGroupId;
      }
      if (hasCorporationId) {
        return;
      }
      const group = consultantGroupData.find(
        "consultantGroupId",
        consultantGroupId
      );
      if (group) {
        this.corporationId = group.corporationId;
      }
      return;
    }

    const leader = leaderData.find("userId", userId);
    if (leader) {
      const { locationId } = leader;
      const location = locationData.find("locationId", locationId);
      if (!location) {
        return;
      }
      if (!hasCorporationId) {
        this.corporationId = location.corporationId;
      }
      if (hasConsultantGroupId) {
        return;
      }
      const group =
        consultantGroupData.find("firstLeaderId", leader.leaderId) ??
        consultantGroupData.find("secondLeaderId", leader.leaderId || null);
      if (group) {
        this.consultantGroupId = group.consultantGroupId;
      }
    }
  }
}
