export interface IUpdateKey {
  refresh(): void;
  check(): number;
}

/**
 * Utilized to force `computed` objects to recompute.
 */
export default class UpdateKey implements IUpdateKey {
  private _key = -1;

  /**
   * Called to recompute a `computed` object that is calling `check()`.
   */
  public refresh() {
    this._key *= -1;
  }

  /**
   * Called within a `computed` block ensure it recomputes whenever `refresh()` has been called.
   */
  public check() {
    return this._key;
  }
}
