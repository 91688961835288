import { NisConsultant } from "@/models/NisConsultant";
import { NisConsultantDisplayable } from "@/models/displayable/NisConsultantDisplayable";
import { container, singleton } from "tsyringe";
import { NisAssignmentData } from "./NisAssignmentData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { NisConsultantHeaders } from "./headers/NisConsultantHeaders";

@singleton()
export class NisConsultantData extends TableData<
  NisConsultant,
  NisConsultantDisplayable
> {
  constructor() {
    super(
      "NIS Consultants",
      new NisConsultantHeaders(),
      "NisConsultant",
      new NisConsultant(),
      "NIS Consultant",
      [
        new TablePointer(
          container.resolve(NisAssignmentData),
          "nisConsultantId",
          DeletePolicy.WithWarning
        ),
      ]
    );
    this._deleteWarning =
      "Warning! This NIS Consultant still has data connected to it!" +
      "Are you sure you want to delete this NIS Consultant? " +
      "This will remove every NIS Assignment posted under it.";
  }
}
