import {
  IsNotEmpty,
  IsString,
  MaxLength,
  ValidationArguments,
} from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { TextError } from "./error/TextError";

export class TextField extends DisplayableField<string, string> {
  @IsNotEmpty({
    message: (args: ValidationArguments) =>
      (args.object as TextField)._error.emptyError(),
  })
  @IsString({
    message: (args: ValidationArguments) =>
      (args.object as TextField)._error.valueError(args.value),
  })
  /*
    This is a hard coded upper limit. An optional limit is inherited from DisplayableField
    if a limit lower than this is desired.
   */
  @MaxLength(50, {
    message: (args: ValidationArguments) =>
      (args.object as TextField)._error.maxLengthError(args.constraints[0]),
  })
  protected _value: string;
  protected _mandatory = true;

  get value(): string {
    return this._value;
  }

  set value(input: string) {
    this._value = input;
  }

  modelValue(): string {
    return this._value.trim();
  }

  get mandatory(): boolean {
    return this._mandatory;
  }

  constructor(header: string, data: string, options?: DisplayableFieldOptions) {
    super(header, new TextError(header), FieldType.INPUT, options);
    this._value = data;
  }
}
