import { ValidateNested } from "class-validator";
import { Consultant } from "../Consultant";
import { ConsultantUrl } from "../ConsultantUrl";
import { ConsultantDisplayable } from "./ConsultantDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";

import store from "@/store";
import { TextField } from "./fields/TextField";
import { DropdownOption } from "./fields/util/DropdownOption";

export class ConsultantUrlDisplayable extends DisplayableBase<ConsultantUrl> {
  @ValidateNested()
  consultant: DropdownField<Consultant, ConsultantDisplayable>;

  name: TextField;

  url: TextField;

  toModel(consultantUrl: ConsultantUrl): void {
    consultantUrl.consultantId = this.consultant.selectedOption.id;
    consultantUrl.consultantName = this.consultant.selectedOption.label;
    consultantUrl.name = this.name.value;
    consultantUrl.url = this.url.value;
  }

  constructor(model: ConsultantUrl) {
    const { consultantData, consultantUrlData } = store.state;
    const { headers } = consultantUrlData;
    super();
    this.consultant = new DropdownField(
      headers.consultant,
      model.consultantName,
      consultantData,
      new DropdownOption(model.consultantId, model.consultantName)
    );
    this.url = new TextField(headers.url, model.url);
    this.name = new TextField(headers.name, model.name);
  }
}
