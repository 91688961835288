import FileFolder from "@/components/enum/FileFolder";
import { AssignmentCompetencyData } from "@/store/data/AssignmentCompetencyData";
import { AssignmentData } from "@/store/data/AssignmentData";
import { CompetencyCategoryData } from "@/store/data/CompetencyCategoryData";
import { CompetencyData } from "@/store/data/CompetencyData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { CorporationData } from "@/store/data/CorporationData";
import { CurrencyData } from "@/store/data/CurrencyData";
import { CustomerData } from "@/store/data/CustomerData";
import FileData from "@/store/data/FileData";
import { ServiceData } from "@/store/data/ServiceData";
import { TaskmasterData } from "@/store/data/TaskmasterData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Assignment } from "../Assignment";
import { AssignmentCompetency } from "../AssignmentCompetency";
import { Competency } from "../Competency";
import { Consultant } from "../Consultant";
import Service from "../Service";
import { Taskmaster } from "../Taskmaster";
import { ContractPhase, contractPhaseAndNames } from "../enum/ContractPhase";
import { AssignmentCompetencyDisplayable } from "./AssignmentCompetencyDisplayable";
import { CompetencyDisplayable } from "./CompetencyDisplayable";
import { ConsultantDisplayable } from "./ConsultantDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { ServiceDisplayable } from "./ServiceDisplayable";
import { TaskmasterDisplayable } from "./TaskmasterDisplayable";
import { DateField } from "./fields/DateField";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "./fields/DropdownField";
import { FilesField } from "./fields/FilesField";
import { FilesUrlField } from "./fields/FilesUrlField";
import { ManyToManyField } from "./fields/ManyToManyField";
import { NullableNumberField } from "./fields/NullableNumberField";
import { NumberField } from "./fields/NumberField";
import { PercentageField } from "./fields/PercentageField";
import { SelectionField } from "./fields/SelectionField";
import { TableField } from "./fields/TableField";
import { TextAreaField } from "./fields/TextAreaField";
import { TextField } from "./fields/TextField";
import { UrlType } from "./fields/enum/UrlType";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldDateGreaterThan } from "./fields/validators/FieldDateGreaterThan";
import { FieldDisabledOrNotHidden } from "./fields/validators/FieldDisabledOrNotHidden";
import { FieldConsultantEmployed } from "./fields/validators/FieldEmployeeEmployed";

export class AssignmentDisplayable extends DisplayableBase<Assignment> {
  @ValidateNested()
  name: TextField;
  @ValidateNested()
  phase: SelectionField<ContractPhase>;
  @ValidateNested()
  coverage: PercentageField;
  @ValidateNested()
  consultant: DropdownField<Consultant, ConsultantDisplayable>;
  @ValidateNested()
  @FieldConsultantEmployed("consultant")
  startDate: DateField;
  @ValidateNested()
  @FieldDateGreaterThan("startDate")
  @FieldConsultantEmployed("consultant")
  endDate: DateField;
  @ValidateNested()
  taskmaster: DropdownField<Taskmaster, TaskmasterDisplayable>;
  @ValidateNested()
  extension: NumberField;
  @ValidateNested()
  @FieldDisabledOrNotHidden()
  service: DropdownField<Service, ServiceDisplayable>;
  @ValidateNested()
  competencies: ManyToManyField<
    Assignment,
    AssignmentCompetency,
    Competency,
    AssignmentDisplayable,
    AssignmentCompetencyDisplayable,
    CompetencyDisplayable
  >;
  @ValidateNested()
  hourlyRate: NullableNumberField;
  customer: TableField;
  currencyCode: TableField;
  filesField: FilesField;
  urlField: FilesUrlField;
  description: TextAreaField;
  workTasks: TextAreaField;

  constructor(assignment: Assignment) {
    super();
    const assignmentData = container.resolve(AssignmentData);
    const assignmentCompetencyData = container.resolve(
      AssignmentCompetencyData
    );
    const currencyData = container.resolve(CurrencyData);
    const competencyData = container.resolve(CompetencyData);
    const taskmasterData = container.resolve(TaskmasterData);
    const customerData = container.resolve(CustomerData);
    const consultantData = container.resolve(ConsultantData);
    const consultantGroupData = container.resolve(ConsultantGroupData);
    const corporationData = container.resolve(CorporationData);
    const serviceData = container.resolve(ServiceData);
    const fileData = container.resolve(FileData);
    const competencyCategoryData = container.resolve(CompetencyCategoryData);
    const consultantName = `${assignment.firstname} ${assignment.lastname}`;
    const { headers } = assignmentData;

    this.name = new TextField(headers.name, assignment.name);
    this.customer = new TableField(headers.customer, assignment.customerName);
    this.phase = new SelectionField(
      headers.phase,
      contractPhaseAndNames(),
      assignment.phase
    );
    this.coverage = new PercentageField(headers.coverage, assignment.coverage);
    const consultant = consultantData.findById(assignment.consultantId);
    const consultantGroup = consultantGroupData.findById(
      consultant.consultantGroupId
    );
    const currency = currencyData.getCompanyCurrencyOrDefault(
      consultantGroup.corporationId
    );
    const isInternal =
      assignment.phase == ContractPhase.INTERNSHIP ||
      assignment.phase == ContractPhase.NON_BILLABLE;
    const { currencySymbol, isPrefix } = currency;
    this.hourlyRate = new NullableNumberField(
      headers.hourlyRate,
      isInternal ? null : assignment.hourlyRate, //still shows it when creating new since default phase is probableassignment
      {
        placeholder: currency.currencyCode,
        enabledWhen: () => !this.isInternal(),
        prefix: isPrefix && !isInternal ? `${currencySymbol}` : undefined,
        suffix: !isPrefix && !isInternal ? ` ${currencySymbol}` : undefined,
      }
    );
    this.description = new TextAreaField(
      headers.description,
      assignment.description,
      {
        maxLength: 700,
        rows: 2,
        placeholder: "What does the team do? Why is our consultant needed?",
      }
    );
    this.workTasks = new TextAreaField(
      headers.workTasks,
      assignment.workTasks,
      {
        maxLength: 500,
        rows: 2,
        placeholder: "What does our consultant do? Concrete example: Solution",
      }
    );
    this.currencyCode = new TableField(
      headers.currencyCode,
      currency.currencyCode
    );
    this.startDate = new DateField(headers.startDate, assignment.startDate);
    this.endDate = new DateField(headers.endDate, assignment.endDate);
    this.taskmaster = new DropdownField(
      headers.taskmaster,
      assignment.taskmasterName,
      taskmasterData,
      new DropdownOption(assignment.taskmasterId, assignment.taskmasterName),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          customerData,
          "customerId",
          "customerName"
        ),
      }
    );

    this.consultant = new DropdownField(
      headers.consultant,
      consultantName,
      consultantData,
      new DropdownOption(assignment.consultantId, consultantName),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          consultantGroupData,
          "consultantGroupId",
          "consultantGroupName",
          new DropdownFieldSelectionFilter(
            corporationData,
            "corporationId",
            "corporationName"
          )
        ),
      }
    );

    let internshipServiceExists = false;
    if (assignment.serviceId <= 0) {
      const internship = serviceData.find("name", "Internship");
      if (internship) {
        internshipServiceExists = true;
        assignment.serviceId = internship.getId();
        assignment.serviceName = internship.getName();
      }
    }
    this.service = new DropdownField(
      headers.service,
      assignment.serviceName,
      serviceData,
      new DropdownOption(assignment.serviceId, assignment.serviceName),
      {
        enabledWhen: () =>
          this.phase.modelValue() != ContractPhase.INTERNSHIP ||
          (!internshipServiceExists && assignment.serviceName != "Internship"),
        hideOptionWhen: (option: DropdownOption<string>) =>
          this.phase.modelValue() != ContractPhase.INTERNSHIP &&
          option.label == "Internship",
      }
    );

    this.competencies = new ManyToManyField(
      headers.competencies,
      assignment.assignmentId,
      assignmentCompetencyData,
      competencyData,
      "assignmentId",
      "assignmentCompetencyId",
      "assignmentId",
      "assignmentName",
      "competencyId",
      "competencyName",
      true,
      new DropdownFieldSelectionFilter(
        competencyCategoryData,
        "competencyCategoryId",
        "categoryName"
      )
    );
    this.extension = new NumberField(headers.extension, assignment.extension, {
      enabledWhen: () => !this.isInternal(),
    });

    this.filesField = new FilesField(
      headers.filesField,
      FileFolder.Assignment,
      assignment.assignmentId,
      fileData
    );
    this.urlField = new FilesUrlField(
      headers.urlField,
      assignment.assignmentId,
      UrlType.Assignment
    );
  }

  isInternal(): boolean {
    const phase = this.phase.modelValue();
    return (
      phase == ContractPhase.INTERNSHIP || phase == ContractPhase.NON_BILLABLE
    );
  }

  toModel(assignment: Assignment): void {
    assignment.name = this.name.modelValue();
    assignment.phase = this.phase.modelValue();
    assignment.coverage = this.coverage.modelValue();
    assignment.startDate = this.startDate.modelValue();
    assignment.endDate = this.endDate.modelValue();
    assignment.taskmasterId = this.taskmaster.selectedOption.id;
    assignment.consultantId = this.consultant.selectedOption.id;
    assignment.serviceId = this.service.selectedOption.id;
    assignment.extension = this.isInternal() ? 0 : this.extension.modelValue();
    assignment.hourlyRate = this.hourlyRate.modelValue() ?? 0;
    assignment.description = this.description.modelValue() ?? "";
    assignment.workTasks = this.workTasks.modelValue() ?? "";

    if (this.phase.modelValue() == ContractPhase.INTERNSHIP) {
      const internship = container
        .resolve(ServiceData)
        .find("name", "Internship");

      if (internship) {
        assignment.serviceId = internship.getId();
      }
    }
  }
}
