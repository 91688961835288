import { CorporationCustomerData } from "@/store/data/CorporationCustomerData";
import { CorporationData } from "@/store/data/CorporationData";
import { CustomerData } from "@/store/data/CustomerData";
import { container } from "tsyringe";
import { Corporation } from "../Corporation";
import { CorporationCustomer } from "../CorporationCustomer";
import { Customer } from "../Customer";
import { CorporationDisplayable } from "./CorporationDisplayable";
import { CustomerDisplayable } from "./CustomerDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueCorporationCustomer } from "./fields/validators/FieldUniqueCorporationCustomer";

export class CorporationCustomerDisplayable extends DisplayableBase<CorporationCustomer> {
  @FieldUniqueCorporationCustomer()
  corporationName: DropdownField<Corporation, CorporationDisplayable>;
  customerName: DropdownField<Customer, CustomerDisplayable>;

  constructor(corporationCustomer: CorporationCustomer) {
    super();
    const corporationCustoemrData = container.resolve(CorporationCustomerData);
    const { headers } = corporationCustoemrData;
    const corporationData = container.resolve(CorporationData);
    const customerData = container.resolve(CustomerData);

    this.corporationName = new DropdownField(
      headers.corporationName,
      corporationCustomer.corporationName,
      corporationData,
      new DropdownOption(
        corporationCustomer.corporationId,
        corporationCustomer.corporationName
      )
    );

    this.customerName = new DropdownField(
      headers.customerName,
      corporationCustomer.customerName,
      customerData,
      new DropdownOption(
        corporationCustomer.customerId,
        corporationCustomer.customerName
      )
    );
  }

  toModel(corporationCustomer: CorporationCustomer): void {
    corporationCustomer.corporationId = this.corporationName.selectedOption.id;
    corporationCustomer.customerId = this.customerName.selectedOption.id;
  }
}
