import { CustomerData } from "@/store/data/CustomerData";
import { TaskmasterData } from "@/store/data/TaskmasterData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Customer } from "../Customer";
import { Taskmaster } from "../Taskmaster";
import { CustomerDisplayable } from "./CustomerDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { NullableTextField } from "./fields/NullableTextField";
import { TableField } from "./fields/TableField";
import { TextField } from "./fields/TextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldDifferentThanCustomer } from "./fields/validators/FieldDifferentThanCustomer";
import { FieldName } from "./fields/validators/FieldName";

export class TaskmasterDisplayable extends DisplayableBase<Taskmaster> {
  @ValidateNested()
  customer: DropdownField<Customer, CustomerDisplayable>;
  @ValidateNested()
  @FieldName()
  @FieldDifferentThanCustomer()
  firstname: TextField;
  @ValidateNested()
  @FieldName()
  @FieldDifferentThanCustomer()
  lastname: TextField;
  fullName: TableField;
  @ValidateNested()
  @FieldDifferentThanCustomer()
  title: NullableTextField;

  constructor(taskmaster: Taskmaster) {
    super();
    const taskmasterData = container.resolve(TaskmasterData);
    const customerData = container.resolve(CustomerData);
    const { headers } = taskmasterData;
    this.customer = new DropdownField(
      headers.customer,
      taskmaster.customerName,
      customerData,
      new DropdownOption(taskmaster.customerId, taskmaster.customerName)
    );
    this.firstname = new TextField(headers.firstname, taskmaster.firstname, {
      hideInTable: true,
    });
    this.lastname = new TextField(headers.lastname, taskmaster.lastname, {
      hideInTable: true,
    });
    this.fullName = new TableField(
      headers.fullName,
      `${taskmaster.firstname} ${taskmaster.lastname}`
    );
    this.title = new NullableTextField(headers.title, taskmaster.title, {
      placeholder: '"manager", "project lead", etc...',
    });
  }

  toModel(taskmaster: Taskmaster): void {
    taskmaster.firstname = this.firstname.modelValue();
    taskmaster.lastname = this.lastname.modelValue();
    taskmaster.title = this.title.modelValue();
    taskmaster.customerId = this.customer.selectedOption.id;
  }
}
