import { Certificate } from "@/models/Certificate";
import { CertificateDisplayable } from "@/models/displayable/CertificateDisplayable";
import { container, singleton } from "tsyringe";
import { ConsultantCertificateData } from "./ConsultantCertificateData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { CertificateHeaders } from "./headers/CertificateHeaders";

@singleton()
export class CertificateData extends TableData<
  Certificate,
  CertificateDisplayable
> {
  constructor() {
    super(
      "Certificates",
      new CertificateHeaders(),
      "Certificate",
      new Certificate(),
      undefined,
      [
        new TablePointer(
          container.resolve(ConsultantCertificateData),
          "certificateId",
          DeletePolicy.WithWarning
        ),
      ]
    );
    this._deleteWarning =
      "Warning! This certificate still has data connected to it! " +
      "Are you sure you want to delete this certificate? " +
      "This will remov it from every user that has it.";
  }
}
