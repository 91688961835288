<template>
  <p v-if="state.isLoading" class="text-loading">Calculating...</p>
  <StatisticsDiagram
    v-else
    :can-show-all-corporations="true"
    :options="{
      includeInternships: state.includeInternships,
      includeLeaders: state.includeLeaders,
    }"
    :yearly-view="includeInternships.LastMonth"
    :table-settings="{
      accumulatedTotal: false,
      showMonthlyTotal: true,
      appendUnit: false,
      fileName: 'Number_of_Employees',
      columns: {
        Leaders: 'leaders',
        Interns: 'interns',
        Consultants: 'consultants',
      },
    }"
    :value-fields="['leaders', 'interns', 'consultants']"
    :tooltipNameListFields="{ internsNames: 'Interns' }"
    :colors="{
      leaders: {
        color: DiagramColors.LEADERS,
        description: 'LEADERS',
        textBright: false,
      },
      interns: {
        color: DiagramColors.INTERNS,
        description: 'INTERNS',
        textBright: false,
      },
      consultants: {
        color: DiagramColors.CONSULTANTS,
        description: 'CONSULTANTS',
        textBright: true,
      },
    }"
    v-model:canUpdate="state.canUpdate"
    title="Number of Employees"
    clarification="Each bar represent the number of employees on the last day of that period."
    :calculate-month="calculateNumberOfEmployees"
    suffix=" employees"
  >
    <UnselectableLabel for="include-interns">
      <input
        type="checkbox"
        class="checkbox"
        id="include-interns"
        v-model="state.includeInternships"
        @click="state.canUpdate = true"
      />
      Include Internships
    </UnselectableLabel>

    <UnselectableLabel for="include-leaders">
      <input
        type="checkbox"
        class="checkbox"
        id="include-leaders"
        v-model="state.includeLeaders"
        @click="state.canUpdate = true"
      />
      Include Leaders
    </UnselectableLabel>
  </StatisticsDiagram>
</template>

<script setup lang="ts">
import UnselectableLabel from "@/components/UnselectableLabel.vue";
import { DiagramColors } from "@/coveragePeriodReport/enum/DiagramColors";
import StatisticsDiagram from "@/diagram/StatisticsDiagram.vue";
import { calculateNumberOfEmployees } from "@/diagram/functions/calculateNumberOfEmployees";
import { YearlyViewBehavior as includeInternships } from "@/diagram/types/YearlyViewBehavior";
import store from "@/store";
import { EventType } from "@/util/EventEmitter";
import { reactive } from "vue";

const state = reactive({
  isLoading: true,
  canUpdate: false,
  includeLeaders: true,
  includeInternships: true,
  data: [],
});

store.state.events.subscribeOrRunIfDispatched(
  EventType.FinishedLoadingTableData,
  () => (state.isLoading = false)
);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
</style>
