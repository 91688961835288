<template>
  <div class="container">
    <BaseTable
      :data="state.nisAssignmentData"
      :options="nisAssignmentOptions"
    />
    <BaseTable
      :data="state.nisConsultantData"
      :options="nisConsultantOptions"
    />
    <BaseTable
      :data="state.nisConsultantGroupData"
      :options="nisConsultantGroupOptions"
    />
    <BaseTable :data="state.nisServiceData" :options="nisServiceOptions" />
  </div>
  <div class="sidebar">
    <TableOfContents />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import TableOfContents from "@/components/TableOfContents.vue";
import { VeaState } from "@/store";
import { NisAssignmentOptions } from "@/store/data/tableDataOptions/NisAssignmentOptions";
import { NisConsultantGroupOptions } from "@/store/data/tableDataOptions/NisConsultantGroupOptions";
import { NisConsultantOptions } from "@/store/data/tableDataOptions/NisConsultantOptions";
import { NisServiceOptions } from "@/store/data/tableDataOptions/NisServiceOptions";
import { useStore } from "vuex";

defineOptions({
  name: "NisTable",
});
const state = useStore<VeaState>().state;
const nisAssignmentOptions = new NisAssignmentOptions();
const nisConsultantOptions = new NisConsultantOptions();
const nisConsultantGroupOptions = new NisConsultantGroupOptions();
const nisServiceOptions = new NisServiceOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.container {
  @include tableContainer;
}
.sidebar {
  @include sidebar;
}
</style>
