import { NonEmptyArray } from "@/util/NonEmptyArray";
import { ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { SelectionError } from "./error/SelectionError";
import { HasSelection, InSelection } from "./validators/InSelection";

export class SelectionField<V>
  extends DisplayableField<string, V>
  implements HasSelection<string>
{
  @InSelection({
    message: (args: ValidationArguments) =>
      (args.object as SelectionField<V>)._error.valueError(args.value),
  })
  protected _value: string;
  private _optionToValue: Map<string, V>;
  private _selectionOptions: NonEmptyArray<string>;

  get value(): string {
    return this._value;
  }

  set value(input: string) {
    this._value = input;
  }

  modelValue(): V {
    return this._optionToValue.get(this._value) as V;
  }

  selectionOptions(): NonEmptyArray<string> {
    return this._selectionOptions;
  }

  constructor(
    header: string,
    options: NonEmptyArray<[string, V]>,
    selected: V,
    fieldOptions?: DisplayableFieldOptions
  ) {
    super(
      header,
      new SelectionError(
        header,
        options.map((x) => x[0]) as NonEmptyArray<string>
      ),
      FieldType.SELECTION,
      fieldOptions
    );
    this._optionToValue = new Map(options);
    this._selectionOptions = options.map((x) => x[0]) as NonEmptyArray<string>;
    this._value = options.find((x) => x[1] === selected)?.[0] as string;
  }
}
