<template>
  <div class="container">
    <BaseTable :data="state.corporationData" :options="corporationOptions" />
    <BaseTable
      :data="state.consultantGroupData"
      :options="consultantGroupOptions"
    />
    <BaseTable :data="state.locationData" :options="locationOptions" />
    <BaseTable :data="state.currencyData" :options="currencyOptions" />
  </div>
  <div class="sidebar">
    <TableOfContents />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import TableOfContents from "@/components/TableOfContents.vue";
import { VeaState } from "@/store";
import { ConsultantGroupOptions } from "@/store/data/tableDataOptions/ConsultantGroupOptions";
import { CorporationOptions } from "@/store/data/tableDataOptions/CorporationOptions";
import { CurrencyOptions } from "@/store/data/tableDataOptions/CurrencyOptions";
import { LocationOptions } from "@/store/data/tableDataOptions/LocationOptions";
import { useStore } from "vuex";

defineOptions({
  name: "CorporationsTable",
});
const state = useStore<VeaState>().state;
const corporationOptions = new CorporationOptions();
const consultantGroupOptions = new ConsultantGroupOptions();
const locationOptions = new LocationOptions();
const currencyOptions = new CurrencyOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}

.sidebar {
  @include sidebar;
}
</style>
