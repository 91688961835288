import { IsInt, Max, Min, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { NumberError } from "./error/NumberError";

export class NumberField extends DisplayableField<number, number> {
  static MIN = 0;
  static MAX = 1000000;

  @IsInt({
    message: (args: ValidationArguments) =>
      (args.object as NumberField)._error.valueError(args.value),
  })
  @Min(NumberField.MIN, {
    message: (args: ValidationArguments) =>
      (args.object as NumberField)._error.minValueError(
        args.constraints[0],
        args.value
      ),
  })
  @Max(NumberField.MAX, {
    message: (args: ValidationArguments) =>
      (args.object as NumberField)._error.maxValueError(
        args.constraints[0],
        args.value
      ),
  })
  protected _value: number;
  protected _mandatory = true;

  get value(): number {
    return this._value;
  }

  set value(input: number) {
    this._value = input;
  }

  modelValue(): number {
    return this._value;
  }

  get mandatory(): boolean {
    return this._mandatory;
  }

  setMandatory(isMandatory: boolean): void {
    this._mandatory = isMandatory;
  }

  constructor(header: string, data: number, options?: DisplayableFieldOptions) {
    super(header, new NumberError(header), FieldType.NUMERIC, options);
    this._value = data;
  }
}
