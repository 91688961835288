import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { TableDataOptionsFilter } from "./TableDataOptionsFilter";

export class AssignmentPhaseFilter extends TableDataOptionsFilter {
  public filterSettings: BaseTableFilterMode[] = [
    BaseTableFilterMode.ALL,
    BaseTableFilterMode.ASSIGNMENT,
    BaseTableFilterMode.PROBABLE_ASSIGNMENT,
    BaseTableFilterMode.INTERNSHIP,
    BaseTableFilterMode.NON_BILLABLE,
  ];

  public filterLabel = "Phase";
}
