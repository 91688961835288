import userStore from "@/auth/user";
import { singleton } from "tsyringe";

@singleton()
export class InactivityChecker {
  private timer: number;

  constructor() {
    this.timer = setTimeout(userStore.logout, 10 * 60 * 60 * 1000); // 10 hours
    document.onmousemove = this.resetTimer;
    document.onkeydown = this.resetTimer;
    document.onclick = this.resetTimer;
    document.onmousedown = this.resetTimer;
  }

  private resetTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(userStore.logout, 10 * 60 * 60 * 1000); // 10 hours
  }
}
