<template>
  <form @submit.prevent="submit()" ref="formRef">
    <div
      id="pw-error"
      class="pw-error text-danger form-error"
      :class="{ appear: error.show }"
    >
      <div
        v-for="(text, index) in error.text"
        :key="index"
        class="error-message"
      >
        {{ text }}
      </div>
    </div>
    <div
      v-if="success.show"
      id="pw-success"
      class="text-success pw-success pw-message"
    >
      {{ success.text }}
    </div>

    <h3 v-if="title" cy-data="form-title" class="form-title">
      {{ title }}
    </h3>

    <slot> </slot>

    <div class="buttons form-group my-3">
      <slot name="otherButtons"> </slot>
      <button type="submit" :class="btnClass" :disabled="hasButtonDisabled()">
        <div class="overlay-submit">
          <div
            v-if="submitActive"
            class="spinner-border spinner-border-sm"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span :class="{ invisible: submitActive }">
            <slot name="saveText"> Submit </slot>
          </span>
        </div>
      </button>
    </div>

    <slot name="belowSubmit"> </slot>
  </form>
</template>

<script setup lang="ts">
import { FormBaseData } from "@/store/data/forms/FormBaseData";
import { computed, ref } from "vue";

const props = defineProps<{
  data: FormBaseData;
  mainButtonClass: string | undefined;
  formTitle?: string;
  noDisabledSubmit?: boolean;
  submitActive?: boolean;
}>();

const emit = defineEmits(["submitForm"]);
function submit() {
  emit("submitForm");
}
const formRef = ref(null);

function hasButtonDisabled(): boolean {
  return (hasEmptyField() && !props.noDisabledSubmit) || props.submitActive;
}

function hasEmptyField(): boolean {
  return Object.values(props.data.formState.fields).some((f) => f.trim() == "");
}

const error = computed(() => props.data.formState.error);
const success = computed(() => props.data.formState.success);
const title = computed(() => props.formTitle);
const btnClass = computed(
  () => `btn ${props.mainButtonClass ?? "btn-primary"}`
);
</script>

<style lang="scss" scoped>
.form-title {
  display: block;
}
.pw-error {
  overflow: hidden;
  transition: all 0.8s;
  .error-message {
    transition: inherit;
    transform-origin: top;
  }
}
.pw-error:not(.appear) {
  height: 0;
  filter: opacity(0);
  .error-message {
    transform: scaleY(0);
  }
}
.appear {
  height: 64px;
  filter: opacity(1);
  .error-message {
    transform: scaleY(1);
  }
}
.overlay-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .spinner-border-sm {
    position: absolute;
  }

  .invisible {
    color: transparent;
  }
}
</style>
