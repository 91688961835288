const MarkDownPatterns = {
  heading: /^##(.+)$/,
  bullet: /^--(.+)$/,
  emphasis: /\*([^*]+)\*/,
  bold: /\*\*([^*]+)\*\*/,
  link: /\[(.+)\]\((.+)\)/,
  other: /[\w|\s|.,;:'!?@&%¤#"]+/,
  inBrackets: /(?<=\[).+(?=\])/,
  inParens: /(?<=\().+(?=\))/,
};

export default MarkDownPatterns;
