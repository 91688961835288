import { Month } from "@/models/enum/Months";
import { monthFromKey, monthKeyFromYearAndMonth, yearFromKey } from "./DateKey";
import { DateSpan, MonthSpan } from "./DateSpan";

export enum Quarter {
  Q1 = 1,
  Q2 = 2,
  Q3 = 3,
  Q4 = 4,
}

export function quarterFromMonth(month: Month): Quarter {
  switch (month) {
    case Month.Jan:
    case Month.Feb:
    case Month.Mar:
      return Quarter.Q1;
    case Month.Apr:
    case Month.May:
    case Month.Jun:
      return Quarter.Q2;
    case Month.Jul:
    case Month.Aug:
    case Month.Sep:
      return Quarter.Q3;
    case Month.Oct:
    case Month.Nov:
    case Month.Dec:
      return Quarter.Q4;
  }
}

export function quarterAsMonthSpan(year: number, quarter: Quarter): MonthSpan {
  switch (quarter) {
    case Quarter.Q1:
      return {
        start: monthKeyFromYearAndMonth(year, Month.Jan),
        end: monthKeyFromYearAndMonth(year, Month.Mar),
      };
    case Quarter.Q2:
      return {
        start: monthKeyFromYearAndMonth(year, Month.Apr),
        end: monthKeyFromYearAndMonth(year, Month.Jun),
      };
    case Quarter.Q3:
      return {
        start: monthKeyFromYearAndMonth(year, Month.Jul),
        end: monthKeyFromYearAndMonth(year, Month.Sep),
      };
    case Quarter.Q4:
      return {
        start: monthKeyFromYearAndMonth(year, Month.Oct),
        end: monthKeyFromYearAndMonth(year, Month.Dec),
      };
  }
}

export function quartersInSpan(span: MonthSpan | DateSpan): MonthSpan[] {
  const year1 = yearFromKey(span.start);
  const year2 = yearFromKey(span.end);
  const month1 = monthFromKey(span.start);
  const month2 = monthFromKey(span.end);
  const quarters = new Array<MonthSpan>();
  for (let y = year1; y <= year2; y++) {
    for (let q = Quarter.Q1; q <= Quarter.Q4; q++) {
      const span = quarterAsMonthSpan(y, q);
      if (y == year1 && month1 > monthFromKey(span.end)) {
        continue;
      }
      if (y == year2 && month2 < monthFromKey(span.start)) {
        break;
      }
      quarters.push(span);
    }
  }
  return quarters;
}

export function yearsInSpan(span: MonthSpan): MonthSpan[] {
  const year1 = yearFromKey(span.start);
  const year2 = yearFromKey(span.end);
  const years = [];
  for (let y = year1; y <= year2; y++) {
    years.push({
      start: monthKeyFromYearAndMonth(y, Month.Jan),
      end: monthKeyFromYearAndMonth(y, Month.Dec),
    });
  }
  return years;
}
