import { IClosedDated, IOpenDated } from "@/interfaces/Dated";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { Absence } from "@/models/Absence";
import { Assignment } from "@/models/Assignment";
import { Competency } from "@/models/Competency";
import { NisAssignment } from "@/models/NisAssignment";
import { Recruitment } from "@/models/Recruitment";
import {
  DisplayableField,
  IDisplayableField,
} from "@/models/displayable/fields/DisplayableField";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import store from "@/store";
import { IterableFields } from "@/types/IterableFields";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import { TableData } from "./TableData";
import { BaseTableFilterMode } from "./enum/BaseTableFilterMode";
import {
  KeyToComparable,
  TableDataOptionsParams,
  TableDataSortOptions,
} from "./tableDataOptions/TableDataOptionsParams";
import { EditCompetenciesExtension } from "./tableDataOptionsExtensions/EditCompetenciesExtension";
import { FilesExtension } from "./tableDataOptionsExtensions/FilesExtension";
import { RequiredCompetenciesExtension } from "./tableDataOptionsExtensions/RequiredCompetenciesExtension";
import { SummaryRowExtension } from "./tableDataOptionsExtensions/SummaryRowExtension";
import { YearFilterExtension } from "./tableDataOptionsExtensions/YearFilterExtension";
import {
  ITableDataDropdown,
  TableDataDropdown,
} from "./tableDataOptionsFilters/TableDataDropdown";
import { TableDataOptionsFilter } from "./tableDataOptionsFilters/TableDataOptionsFilter";
import { Filter } from "./types/FilterBuilder";

export abstract class TableDataOptions<
  M extends Model<D>,
  D extends Displayable<M> & IterableFields<D, IDisplayableField>
> {
  public get competencies(): Competency[] {
    return this._competencies;
  }

  public dropdownFilters?: ITableDataDropdown[];
  public radioFilters: TableDataOptionsFilter[];
  public sorting?: TableDataSortOptions<M, D>;

  public yearFilterExtension?: YearFilterExtension<M, D>;
  public summaryRowExtension?: SummaryRowExtension<M, D>;
  public editCompetenciesExtension?: EditCompetenciesExtension<M, D>;
  public requiredCompetenciesExtension?: RequiredCompetenciesExtension<M, D>;
  public filesExtension?: FilesExtension<M, D>;
  public groupedByKey?: KeyTo<D, DisplayableField<string, unknown>>;
  public hiddenFields: KeyTo<D, IDisplayableField>[];
  public filterBoxKeys?: KeyTo<D, DisplayableField<string, unknown>>[];

  // eslint-disable-next-line
  public canRowBeModified(_row: M): boolean {
    return true;
  }

  private _competencies: Competency[] = [];
  private readonly _competenciesByOwnerId: Map<number, Set<number>> = new Map<
    number,
    Set<number>
  >();

  private readonly _keyCompetenciesByOwnerId: Map<number, Set<number>> =
    new Map<number, Set<number>>();

  protected constructor(params: TableDataOptionsParams<M, D>) {
    this.dropdownFilters = params.dropdownFilters ?? [];
    this.radioFilters = params.radioFilters ?? [];
    this.sorting = params.sorting;
    this.yearFilterExtension = params.yearFilterExtension;
    this.summaryRowExtension = params.summaryRowExtension;
    this.editCompetenciesExtension = params.editCompetenciesExtension;
    this.requiredCompetenciesExtension = params.requiredCompetenciesExtension;
    this.filesExtension = params.filesExtension;
    this.groupedByKey = params.groupedByKey;
    this.hiddenFields = params.hiddenFields ?? [];
    this.filterBoxKeys = params.filterBoxKeys;
  }

  /**
   *
   * @param rows
   * @param radioFilters
   * @param yearFilter
   * @param selectedCompetencies
   * @returns a copy of `rows`, unless overriden
   */
  protected filterRows(
    rows: M[],
    // eslint-disable-next-line
    radioFilters: BaseTableFilterMode[],
    // eslint-disable-next-line
    yearFilter: number,
    // eslint-disable-next-line
    selectedCompetencies?: Competency[]
  ) {
    return rows.slice(0);
  }

  public filterAndSortRows(
    rows: M[],
    radioFilters: BaseTableFilterMode[],
    yearFilter: number,
    sortKey: string | number,
    selectedCompetencies?: Competency[]
  ) {
    rows = this.filterRows(
      rows,
      radioFilters,
      yearFilter,
      selectedCompetencies
    );
    return this.sort(rows, sortKey);
  }

  protected static getPeriodFilters<T extends Absence | Assignment>(
    filter: BaseTableFilterMode,
    corporationFilter: number,
    consultantFilter: number,
    consultantGroupFilter: number
  ): Filter<T>[] {
    const now = Date.now();
    const filters: Filter<T>[] = [];

    switch (filter) {
      case BaseTableFilterMode.CURRENT:
        filters.push(TableDataOptions.isPeriodCurrent(now));
        break;
      case BaseTableFilterMode.FINISHED:
        filters.push(TableDataOptions.isPeriodNotCurrent(now));
        break;
    }

    const { consultantGroupData, consultantData } = store.state;

    if (consultantFilter != -1) {
      filters.push((row) => row.consultantId == consultantFilter);
      return filters;
    }

    if (consultantGroupFilter != -1) {
      const consultantIds = consultantData.findIds(
        "consultantGroupId",
        consultantGroupFilter
      );
      filters.push((row) => consultantIds.has(row.consultantId));
      return filters;
    }

    if (corporationFilter != -1) {
      const consultantGroupIds = consultantGroupData.findIds(
        "corporationId",
        corporationFilter
      );
      const consultantIds = consultantData.findWithValuesInSetIds(
        "consultantGroupId",
        consultantGroupIds
      );
      filters.push((o) => consultantIds.has(o.consultantId));
    }

    return filters;
  }

  protected static getNisPeriodFilters(
    filter: BaseTableFilterMode,
    corporationFilter: number,
    nisConsultantFilter: number,
    nisConsultantGroupFilter: number
  ): Filter<NisAssignment>[] {
    const now = Date.now();
    const filters: Filter<NisAssignment>[] = [];

    switch (filter) {
      case BaseTableFilterMode.CURRENT:
        filters.push(TableDataOptions.isPeriodCurrent(now));
        break;
      case BaseTableFilterMode.FINISHED:
        filters.push(TableDataOptions.isPeriodNotCurrent(now));
        break;
    }

    const { nisConsultantGroupData, nisConsultantData } = store.state;

    if (nisConsultantFilter != -1) {
      filters.push((row) => row.nisConsultantId == nisConsultantFilter);
      return filters;
    }

    if (nisConsultantGroupFilter != -1) {
      const nisConsultantIds = nisConsultantData.findIds(
        "nisConsultantGroupId",
        nisConsultantGroupFilter
      );
      filters.push((row) => nisConsultantIds.has(row.nisConsultantId));
      return filters;
    }

    if (corporationFilter != -1) {
      const nisConsultantGroupIds = nisConsultantGroupData.findIds(
        "corporationId",
        corporationFilter
      );
      const nisConsultantIds = nisConsultantData.findWithValuesInSetIds(
        "nisConsultantGroupId",
        nisConsultantGroupIds
      );
      filters.push((o) => nisConsultantIds.has(o.nisConsultantId));
    }

    return filters;
  }

  private sort(rows: M[], key: keyof TableDataSortOptions<M, D>) {
    if (!this.sorting) {
      return rows;
    }

    const option = this.sorting[key];

    if (!option) {
      return rows;
    }

    if (typeof option === "string") {
      // option is a property key, used to compare values
      return this.sortByComparable(rows, option);
    }

    if (typeof option === "function") {
      // option is a sort function
      return rows.sort(option);
    }

    if (option.func) {
      // option has a sort function defined,
      // which takes precedence regardless if the
      // sort key is also defined
      return rows.sort(option.func);
    }

    // if no sort function is defined,
    // the sort key *will* be defined.
    return this.sortByComparable(rows, option.key);
  }

  private sortByComparable(rows: M[], key: KeyToComparable<M>): M[] {
    return rows.sort((r1, r2) => {
      const v1 = r1[key];
      const v2 = r2[key];

      if (v1 === v2) {
        return 0;
      }

      if (v1 === null || v1 === undefined) {
        return -1;
      }

      if (v2 === null || v2 === undefined) {
        return 1;
      }

      if (typeof v1 == "string" && typeof v2 == "string") {
        return v1.localeCompare(v2);
      }

      return v1 > v2 ? 1 : -1;
    });
  }

  public getSortingOptionsCount(): number {
    return this.sorting ? Object.keys(this.sorting).length : 0;
  }

  public getDefaultSoringOption(): string | number {
    if (!this.sorting) {
      return 0;
    }
    return Object.keys(this.sorting)[0] ?? 0;
  }

  public static isPeriodNotCurrent<T extends IClosedDated>(
    now: number
  ): Filter<T> {
    return (row: T) => !TableDataOptions.isPeriodCurrent(now)(row);
  }

  public static isPeriodCurrent<T extends IClosedDated>(
    now: number
  ): Filter<T> {
    return (row: T) => Date.parse(row.endDate) >= now;
  }

  protected static isEmployeeNotCurrent<T extends IOpenDated>(
    now: number
  ): Filter<T> {
    return (row: T) => !TableDataOptions.isEmployeeCurrent(now)(row);
  }

  protected static isEmployeeCurrent<T extends IOpenDated>(
    now: number
  ): Filter<T> {
    return (row: T) => row.endDate == null || Date.parse(row.endDate) >= now;
  }

  public static compareStart(
    row1: Pick<IClosedDated, "startDate">,
    row2: Pick<IClosedDated, "startDate">
  ): number {
    const start1 = row1.startDate;
    const start2 = row2.startDate;
    return start1.localeCompare(start2);
  }

  protected static compareEnd(row1: IClosedDated, row2: IClosedDated): number {
    const end1 = row1.endDate;
    const end2 = row2.endDate;
    return end1.localeCompare(end2);
  }

  protected static compareNullableEnd(
    row1: IOpenDated,
    row2: IOpenDated
  ): number {
    const end1 = row1.endDate;
    const end2 = row2.endDate;
    if (end1 == end2) {
      return 0;
    } else if (end1 == null) {
      return -1;
    } else if (end2 == null) {
      return 1;
    }
    return end1.localeCompare(end2);
  }

  protected static compareFirstNames<
    T extends { firstname: string; lastname: string }
  >(row1: T, row2: T): -1 | 0 | 1 {
    const n1 = row1.firstname + row1.lastname;
    const n2 = row2.firstname + row2.lastname;

    return TableDataOptions.compareStrings(n1, n2);
  }

  protected static compareLastNames<
    T extends { firstname: string; lastname: string }
  >(row1: T, row2: T): -1 | 0 | 1 {
    const n1 = row1.lastname + row1.firstname;
    const n2 = row2.lastname + row2.firstname;

    return TableDataOptions.compareStrings(n1, n2);
  }

  protected static compareMonths(
    row1: Recruitment,
    row2: Recruitment
  ): -1 | 0 | 1 {
    const display1 = row1.getDisplayable();
    const display2 = row2.getDisplayable();
    const date1 = new Date(display1.period.value);
    const date2 = new Date(display2.period.value);
    return date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
  }

  protected static compareComissionNames(c1: Assignment, c2: Assignment) {
    return TableDataOptions.compareStrings(c1.name, c2.name);
  }

  protected static compareServices(c1: Assignment, c2: Assignment) {
    return TableDataOptions.compareStrings(c1.serviceName, c2.serviceName);
  }

  protected static compareNumbers(n1: number, n2: number): -1 | 0 | 1 {
    return n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
  }

  public static compareStrings(s1: string, s2: string): -1 | 0 | 1 {
    s1 = s1.toUpperCase();
    s2 = s2.toUpperCase();
    return s1 < s2 ? -1 : s1 > s2 ? 1 : 0;
  }

  protected static compareCustomers(row1: Assignment, row2: Assignment) {
    const customer1 = TableDataOptions.findCustomerName(row1).toLowerCase();
    const customer2 = TableDataOptions.findCustomerName(row2).toLowerCase();
    return customer1 < customer2 ? -1 : customer1 > customer2 ? 1 : 0;
  }

  protected static findCustomerName(com: Assignment): string {
    const tm = store.state.taskmasterData.find(
      "taskmasterId",
      com.taskmasterId
    );
    return tm?.customerName ?? "Unavailable";
  }

  public async setCompetencies(): Promise<void> {
    const {
      competencyData,
      consultantCompetencyData,
      assignmentCompetencyData,
    } = store.state;
    this._competencies = competencyData.rows;
    this._competenciesByOwnerId.clear();

    const assignmentData = store.state.assignmentData;
    const consultantData = store.state.consultantData;

    const userIdByConsultantId = new Map<number, number>(
      consultantData.rows.map((r) => [r.consultantId, r.userId])
    );
    const ownerIdByAssignmentId = new Map<number, number>(
      assignmentData.rows.map((r) => [r.assignmentId, r.assignmentId])
    );

    // Set assignment competencies/competencies
    if (this.requiredCompetenciesExtension) {
      for (const assignmentCompetency of assignmentCompetencyData.rows) {
        const ownerId = ownerIdByAssignmentId.get(
          assignmentCompetency.assignmentId
        );

        if (!ownerId) {
          continue;
        }

        let set = this._competenciesByOwnerId.get(ownerId);

        if (set === undefined) {
          set = new Set<number>();
          this._competenciesByOwnerId.set(ownerId, set);
        }

        set.add(assignmentCompetency.competencyId);

        set = this._keyCompetenciesByOwnerId.get(ownerId);

        if (set === undefined) {
          set = new Set<number>();
          this._keyCompetenciesByOwnerId.set(ownerId, set);
        }

        set.add(assignmentCompetency.competencyId);
      }
    }

    // Set consultant competencies
    if (this.editCompetenciesExtension) {
      for (const consultantCompetency of consultantCompetencyData.rows) {
        const userId = userIdByConsultantId.get(
          consultantCompetency.consultantId
        );

        if (!userId) {
          continue;
        }

        let set = this._competenciesByOwnerId.get(userId);

        if (set === undefined) {
          set = new Set<number>();
          this._competenciesByOwnerId.set(userId, set);
        }

        set.add(consultantCompetency.competencyId);

        if (!consultantCompetency.isKey) {
          continue;
        }

        set = this._keyCompetenciesByOwnerId.get(userId);

        if (set === undefined) {
          set = new Set<number>();
          this._keyCompetenciesByOwnerId.set(userId, set);
        }

        set.add(consultantCompetency.competencyId);
      }
    }
  }

  protected filterSelectedCompetencies<M>(
    rows: M[],
    selectedCompetencies: Competency[],
    getOwnerId: (row: M) => number
  ): M[] {
    const keysOnly =
      this.editCompetenciesExtension?.keyCompetenciesOnly ?? false;
    return rows.filter((row) => {
      const competencies = keysOnly
        ? this._keyCompetenciesByOwnerId.get(getOwnerId(row))
        : this._competenciesByOwnerId.get(getOwnerId(row));

      if (competencies === undefined) {
        return false;
      }

      return selectedCompetencies.every((competency) =>
        competencies.has(competency.competencyId)
      );
    });
  }

  protected getSelectedCompetenciesFilter(
    selectedCompetencies: Competency[]
  ): Filter<M> {
    if (
      !this.editCompetenciesExtension &&
      !this.requiredCompetenciesExtension
    ) {
      return () => true;
    }

    let id: (value: M) => number;
    let keysOnly: boolean;
    if (this.requiredCompetenciesExtension) {
      id = this.requiredCompetenciesExtension.getOwnerId;
    }
    if (this.editCompetenciesExtension) {
      keysOnly = this.editCompetenciesExtension.keyCompetenciesOnly;
      id = this.editCompetenciesExtension.getUserId;
    }

    return (row: M) => {
      const competencies = keysOnly
        ? this._keyCompetenciesByOwnerId.get(id(row))
        : this._competenciesByOwnerId.get(id(row));

      if (competencies === undefined) {
        return false;
      }

      return selectedCompetencies.every((competency) =>
        competencies.has(competency.competencyId)
      );
    };
  }

  /**
   * Generates a filter for a Dropdown,
   * based on the selected value of another related Dropdown.
   * @param data
   * @param dropdown the related dropdown
   * @param idKey the property key of the `Model` that points to an id relating to an of the second dropdown
   * @returns
   */
  getDropdownFilterFromRelatedDropdown<
    M extends Model<D>,
    D extends Displayable<M>
  >(
    data: TableData<M, D>,
    dropdown: ITableDataDropdown,
    idKey: KeyTo<M, number>
  ): (option: DropdownOption<string>) => boolean {
    return (option) => {
      const filter = dropdown.selectedOption;

      if (filter == -1) {
        return true;
      }

      const id = getProp(data.findById(option.id), idKey);
      return id == filter;
    };
  }

  /**
   *
   * @param data1
   * @param dropdown1
   * @param idKey1
   * @param data2
   * @param dropdown2
   * @param idKey2
   * @returns
   */
  getDropdownFilterFromRelatedDropdowns<
    M1 extends Model<D1>,
    D1 extends Displayable<M1>,
    M2 extends Model<D2>,
    D2 extends Displayable<M2>
  >(
    firstData: TableData<M1, D1>,
    secondDropdown: TableDataDropdown<M2, D2>,
    firstModelIdKeyToSecondData: KeyTo<M1, number>,
    secondData: TableData<M2, D2>,
    thirdDropdown: ITableDataDropdown,
    secondModelIdKeyToThirdData: KeyTo<M2, number>
  ): (option: DropdownOption<string>) => boolean {
    return (option) => {
      const filter1 = secondDropdown.selectedOption;
      if (filter1 > 0) {
        return (
          filter1 ==
          getProp(firstData.findById(option.id), firstModelIdKeyToSecondData)
        );
      }

      const filter2 = thirdDropdown.selectedOption;
      if (filter2 > 0) {
        const idsData2 = secondData.findIds(
          secondModelIdKeyToThirdData,
          filter2
        );
        const set = firstData.findWithValuesInSetIds(
          firstModelIdKeyToSecondData,
          idsData2
        );
        return set.has(option.id);
      }
      return true;
    };
  }
}
