import { Corporation } from "@/models/Corporation";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class CorporationOptions extends TableDataOptions<
  Corporation,
  CorporationDisplayable
> {
  public constructor() {
    super({
      filterBoxKeys: ["name"],
      sorting: {
        Name: { column: "name", key: "name" },
        Currency: { column: "currencyCode", key: "currencyCode" },
      },
    });
  }
}
