import { singleton } from "tsyringe";

export enum EventType {
  LoggedIn,
  FinishedLoadingTableData,
}

/**
 * event emitter to subscribe, dispatch, and unsubscribe to events
 */
@singleton()
export class EventEmitter {
  // event object to store events
  private readonly _events: Map<EventType, Array<() => void>>;

  // list of events that got dispatched once
  private readonly _dispatchedEvents: Set<EventType>;

  constructor() {
    this._events = new Map<EventType, (() => void)[]>();
    this._dispatchedEvents = new Set([]);
  }

  // this returns if an event was attempted to be dispatched at any point
  public wasDispatched(eventType: EventType): boolean {
    return this._dispatchedEvents.has(eventType);
  }

  // this will dispatch the event and call the callback for every event
  public dispatch(eventType: EventType): void {
    this._dispatchedEvents.add(eventType);
    if (!this._events.has(eventType)) {
      return;
    }
    this._events.get(eventType)?.forEach((callback: () => void) => callback());
  }

  // this will subscribe the event with a callback
  public subscribe(eventType: EventType, callback: () => void): void {
    if (!this._events.has(eventType)) {
      this._events.set(eventType, []);
    }
    this._events.get(eventType)?.push(callback);
  }

  // this will unsubscribe the event to avoid unnecessary event calls
  public unsubscribe(eventType: EventType): void {
    if (!this._events.has(eventType)) {
      return;
    }
    this._events.delete(eventType);
  }

  // this will run the callback if the event was already dispatched somewhere, else it will subscribe the event with a callback
  public subscribeOrRunIfDispatched(
    eventType: EventType,
    callback: () => void
  ) {
    if (this.wasDispatched(eventType)) {
      callback();
    } else {
      this.subscribe(eventType, callback);
    }
  }
}
