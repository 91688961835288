import { TableHeaders } from "@/interfaces/TableHeaders";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";

export class ConsultantGroupHeaders
  implements TableHeaders<ConsultantGroupDisplayable>
{
  public readonly name = "Name";
  public readonly corporation = "Company";
  public readonly location = "Location";
  public readonly firstLeader = "Leader";
  public readonly secondLeader = "Extra Leader";
}
