import { TableHeaders } from "@/interfaces/TableHeaders";
import { NisConsultantDisplayable } from "@/models/displayable/NisConsultantDisplayable";

export class NisConsultantHeaders
  implements TableHeaders<NisConsultantDisplayable>
{
  public readonly name = "Name";
  public readonly group = "Group";
  public readonly corporation = "Company";
  public readonly employmentStart = "Employment start";
  public readonly employmentEnd = "Employment end";
}
