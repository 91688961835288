import { IsNotEmpty, IsString } from "class-validator";
import { DisplayableField } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { EnumError } from "./error/EnumError";
import { EnumDropdownOption } from "./util/EnumDropdownOption";

export interface IEnumDropdownField {
  getOptions(): EnumDropdownOption<string>[];
}

type EType<E> = { [s: string]: E } | ArrayLike<E>;

export class EnumDropdownField<E extends string>
  extends DisplayableField<E, E>
  implements IEnumDropdownField
{
  private _selectedOption: EnumDropdownOption<E>;
  private readonly _enum: EType<E>;
  @IsNotEmpty()
  @IsString()
  protected _value: E;
  get value(): E {
    return this._value;
  }
  set value(input: E) {
    this._value = input;
  }
  modelValue(): E {
    return this._value;
  }

  getOptions(): EnumDropdownOption<E>[] {
    return Object.values(this._enum).map((val) => new EnumDropdownOption(val));
  }

  /**
   *
   * @param type Type symbol of {@link E}, necessary to pass here in order to iterate all enum values.
   * @param header
   * @param value
   */
  public constructor(type: EType<E>, header: string, value: E) {
    super(header, new EnumError(header), FieldType.ENUM_DROP_DOWN);
    this._value = value;
    this._enum = type;
    this._selectedOption = new EnumDropdownOption(value);
  }

  get selectedOption(): EnumDropdownOption<E> {
    return this._selectedOption;
  }

  set selectedOption(option: EnumDropdownOption<E>) {
    this._selectedOption = option;
    this._value = option.label;
  }
}
