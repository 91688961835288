import { Competency } from "@/models/Competency";
import { Consultant } from "@/models/Consultant";
import { ConsultantCompetency } from "@/models/ConsultantCompetency";
import { ConsultantCompetencyData } from "@/store/data/ConsultantCompetencyData";
import { CompetencyDisplayable } from "../../CompetencyDisplayable";
import { ConsultantCompetencyDisplayable } from "../../ConsultantCompetencyDisplayable";
import { ConsultantDisplayable } from "../../ConsultantDisplayable";
import { FieldUniqueManyToManyConnection } from "./FieldUniqueManyToManyConnection";

export function FieldUniqueConsultantCompetency() {
  return FieldUniqueManyToManyConnection<
    Consultant,
    ConsultantCompetency,
    Competency,
    ConsultantDisplayable,
    ConsultantCompetencyDisplayable,
    CompetencyDisplayable
  >(
    "consultantName",
    "consultantId",
    "competencyId",
    () => ConsultantCompetencyData
  );
}
