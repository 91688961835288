import { decrypt, encrypt } from "@/auth/cryptographer";
import storageIds from "@/store/data/constant/StorageIds";
import { injectable } from "tsyringe";
import actions from "../auth/user";

@injectable()
export class OpenStorageUtils {
  getStorage(storageId: number) {
    const storageKey = storageIds.find((x) => x.id == storageId);
    if (!storageKey) {
      return null;
    }

    const settings = localStorage.getItem(storageKey.key);
    if (!settings) {
      return null;
    }

    try {
      return JSON.parse(decrypt(settings));
    } catch (error) {
      actions.logout();
      return null;
    }
  }

  saveToStorage<T>(objectToStore: T, storageId: number) {
    const storageKey = storageIds.find((x) => x.id == storageId);
    const encryptedText = encrypt(JSON.stringify(objectToStore));
    if (storageKey) {
      localStorage.setItem(storageKey.key, encryptedText);
    }
  }
}
