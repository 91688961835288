import { IUrlModel } from "./ConsultantUrl";
import { ModelBase } from "./ModelBase";
import { AssignmentUrlDisplayable } from "./displayable/AssignmentUrlDisplayable";

export class AssignmentUrl
  extends ModelBase<AssignmentUrlDisplayable>
  implements IUrlModel
{
  urlId: number;
  name: string;
  url: string;
  assignmentName: string;
  assignmentId: number;

  constructor() {
    super();
    this.urlId = 0;
    this.assignmentId = 0;
    this.assignmentName = "";
    this.name = "";
    this.url = "";
  }

  realize(partialModel: AssignmentUrl): AssignmentUrl {
    const newModel = new AssignmentUrl();
    newModel.urlId = partialModel.urlId;
    newModel.name = partialModel.name;
    newModel.assignmentId = partialModel.assignmentId;
    newModel.assignmentName = partialModel.assignmentName;
    newModel.url = partialModel.url;
    return newModel;
  }

  getId(): number {
    return this.urlId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): AssignmentUrlDisplayable {
    return new AssignmentUrlDisplayable(this);
  }
}
