import FileFolder from "@/components/enum/FileFolder";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { container } from "tsyringe";
import FileData from "../FileData";
import FileDetails from "../types/FileDetails";

export class FilesExtension<T extends Model<D>, D extends Displayable<T>> {
  private _getFileId: (row: T) => number;
  private _folder: FileFolder;
  private _files?: FileDetailsMap;
  private _filesPromise?: Promise<FileDetailsMap>;

  public get filesPromise(): Promise<FileDetailsMap> | undefined {
    return this._filesPromise;
  }

  public get getFileId(): (row: T) => number {
    return this._getFileId;
  }

  public async setFileDetails(): Promise<FileDetailsMap> {
    const fileData = container.resolve(FileData);
    this._filesPromise = fileData
      .getFullListById(this._folder)
      .then((files) => new FileDetailsMap(files || undefined));
    this._files = await this._filesPromise;
    return this._files;
  }

  hasFiles(row: T): boolean {
    return this._files?.hasFiles(this._getFileId(row)) ?? false;
  }

  getFiles(row: T): FileDetails[] {
    const result = this._files?.getFileDetailsOrAddEmpty(this._getFileId(row));
    return result ?? [];
  }

  constructor(getFileId: (row: T) => number, folder: FileFolder) {
    this._getFileId = getFileId;
    this._folder = folder;
  }
}

export class FileDetailsMap {
  private _files: Map<number, FileDetails[]>;

  hasFiles(id: number): boolean {
    const length = this._files.get(id)?.length;
    return length !== undefined && length > 0;
  }

  getFileDetailsOrAddEmpty(id: number): FileDetails[] {
    let files = this._files.get(id);
    if (files === undefined) {
      files = [];
      this._files.set(id, files);
    }
    return files;
  }

  setFileDetails(id: number, files: FileDetails[]): void {
    this._files.set(id, files);
  }

  constructor(files?: Map<number, FileDetails[]>) {
    this._files = files ?? new Map<number, FileDetails[]>();
  }
}
