import { Corporation } from "@/models/Corporation";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { container, singleton } from "tsyringe";
import { ConsultantGroupData } from "./ConsultantGroupData";
import { LocationData } from "./LocationData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { CorporationHeaders } from "./headers/CorporationHeaders";

@singleton()
export class CorporationData extends TableData<
  Corporation,
  CorporationDisplayable
> {
  constructor() {
    super(
      "Companies",
      new CorporationHeaders(),
      "Corporation",
      new Corporation(),
      "Company",
      [
        new TablePointer(
          container.resolve(ConsultantGroupData),
          "corporationId",
          DeletePolicy.NeverDelete
        ),
        new TablePointer(
          container.resolve(LocationData),
          "corporationId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This Company may not be deleted! " +
      "It still has non-empty consultant group(s) or location(s) under it! " +
      "Reassign or remove each of its remaining consultant groups and locations";
  }
}
