import { Either } from "@/types/Either";
import { KeyTo } from "@/types/KeyTo";
import { AllPropsAs } from "@/types/PropsOf";

export type MonthlyReport = {
  monthlyReportId: number;
  corporationId: number;
  year: number;
  month: BackendMonth;
  availableWorkingDays: number | null;
  availableDailyHours: number | null;
  expectedVacationHours: number | null;
  totalVacationHours: number | null;
  shortTermSickLeaveHours: number | null;
  longTermSickLeaveHours: number | null;
  expectedFTEConsultants: number | null;
  fteConsultants: number | null;
  expectedEmployedConsultants: number | null;
  employedConsultants: number | null;
  internsAndNotEmployed: number | null;
  fteNonConsultantPersonell: number | null;
  nonConsultantPersonell: number | null;
  expectedBilledHours: number | null;
  totalBilledHours: number | null;
  ebitda: number | null;
  revenue: number | null;
  revenueNIS: number | null;
  soRDataServices: number | null;
  soRSoftwareDevelopment: number | null;
  soRQATesting: number | null;
  soRCyberSecurityCompliance: number | null;
  soROther: number | null;
  newClients: number | null;
  noOfInvoicedClients: number | null;
  newCertificates: number | null;
  newConsultantApplications: number | null;
  noOfPeopleTrainedInNIS: number | null;
  noOfClientsLivedExperience: number | null;
  noOfClientsImplementingChange: number | null;
  noOfNDCareersProvided: number | null;
};

export enum BackendMonth {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
}

export enum TotalBehavior {
  SumOfMonthlyCalculations,
  CalculationWithAccumulatedDataPoints,
}

// Determines the order of each field in the report
// Only needs to include visible fields
export const ReportDescriptors: MonthlyReportDescriptors = {
  expectedAvailableHours: {
    categoryHeader: "Basic Data",
    header: "Expected Available Hours",
    totalBehavior: TotalBehavior.SumOfMonthlyCalculations,
    tooltip:
      "Calculation of all expected available hours for all Expected FTE Consultants.<br />" +
      "Calculated automatically. Defined as:<br />Available Working Days * Available Hours/Day * Expected FTE Consultants<br />" +
      "Total: Sum of all months.",
  },
  totalAvailableHours: {
    header: "Total Available Hours",
    totalBehavior: TotalBehavior.SumOfMonthlyCalculations,
    tooltip:
      "Calculation of all available hours for all FTE Consultants.<br />" +
      "Calculated automatically. Defined as:<br />Available Working Days * Available Hours/Day * FTE Consultants<br />" +
      "Total: Sum of all months.",
  },
  availableWorkingDays: {
    header: "Available Working Days",
    tooltip:
      "Number of working days per month.<br />" + "Total: Sum of all months.",
    maxValue: 31,
    canHaveDecimals: true,
  },
  availableDailyHours: {
    header: "Available Hours/Day",
    tooltip:
      "Number of working hours/day that can be billed.<br />" +
      "Total: Sum of all months.",
    maxValue: 24,
    canHaveDecimals: true,
  },
  expectedVacationHours: {
    header: "Expected Vacation Hours",
    tooltip:
      "Total number of hours that consultants expect to be on vacation in the given month. Only consultant hours.<br />" +
      "Total: Sum of all months.",
    canHaveDecimals: true,
  },
  totalVacationHours: {
    header: "Total Vacation Hours",
    tooltip:
      "Total number of hours that consultants have been on vacation in the given month. Only consultant hours.<br />" +
      "Total: Sum of all months.",
    canHaveDecimals: true,
  },
  shortTermSickLeaveHours: {
    header: "Short-Term Sick Leave Hours",
    tooltip:
      "< 2 weeks. Sick leave hours for the given month. Calculated on consultants that have been on sick leave for less than 2 weeks.<br />" +
      "Only consultant hours.<br />" +
      "Total: Sum of all months.",
    canHaveDecimals: true,
  },
  longTermSickLeaveHours: {
    header: "Long-Term Sick Leave Hours",
    tooltip:
      "> 2 weeks. Sick leave hours for the given month. Calculated on consultants that have been on sick leave for at least 2 weeks.<br />" +
      "Only consultant hours.<br />" +
      "Total: Sum of all months.",
    canHaveDecimals: true,
  },

  expectedFTEConsultants: {
    categoryHeader: "Workforce Data",
    header: "Expected FTE Consultants",
    fullHeader: "Expected Full-time Equivalent Consultants",
    tooltip:
      "Expected Total number of FTE consultants by average in any given month. Value between 0-1 per employee. <br />Example: 80% employment = 0,8.<br />If that employee was employed in the middle of the month it gives an FTE of 0,4.<br />" +
      "Total: N/A.",
    skipTotal: true,
    canHaveDecimals: true,
  },
  fteConsultants: {
    header: "FTE Consultants",
    fullHeader: "Full-time Equivalent Consultants",
    tooltip:
      "Total number of FTE consultants by average in any given month. Value between 0-1 per employee.<br />Example: 80% employment = 0,8.<br />If that employee was employed in the middle of the month it gives an FTE of 0,4.<br />" +
      "Total: N/A.",
    skipTotal: true,
    canHaveDecimals: true,
  },
  expectedEmployedConsultants: {
    header: "Expected Empl. Consultants",
    tooltip:
      "Expected Headcount - total number of consultants at month end.<br />" +
      "Total: N/A.",
    skipTotal: true,
  },
  employedConsultants: {
    header: "Employed Consultants",
    tooltip:
      "Headcount - total number of consultants at month end.<br />" +
      "Total: N/A.",
    skipTotal: true,
  },
  internsAndNotEmployed: {
    header: "Interns and Not Employed",
    tooltip:
      "Number of people that are not employed but are on internships or other equivalent situations.<br />" +
      "Total: N/A.",
    skipTotal: true,
  },
  fteNonConsultantPersonell: {
    header: "FTE Non-Consulting Personell",
    fullHeader: "Full-time Equivalent Non-Consulting Personell",
    tooltip:
      "Total number of FTE non-consulting personell by average in any given month. Value between 0-1 per employee.<br />Example: 80% employment = 0,8.<br />If that employee was employed in the middle of the month it gives an FTE of 0,4.<br />" +
      "Total: N/A.",
    skipTotal: true,
    canHaveDecimals: true,
  },
  nonConsultantPersonell: {
    header: "Non-Consulting Personell",
    tooltip:
      "Non-Consultant personell defined as:<br />All Employees - Employed Consultants.<br />" +
      "Total: N/A.",
    skipTotal: true,
  },

  expectedBilledHours: {
    categoryHeader: "Financials",
    header: "Expected Billed Hours",
    tooltip:
      "The expected Total number of billed hours (revenue generating hours) in the given month. If the revenue is based on daily or monthly rates, then convert to hours and enter here.<br />Do not include hours spent on internal competence building activities.<br />" +
      "Total: Sum of all months.",
  },
  totalBilledHours: {
    header: "Total Billed Hours",
    tooltip:
      "Total number of billed hours (revenue generating hours) in the given month. If the revenue is based on daily or monthly rates, then convert to hours and enter here.<br />Do not include hours spent on internal competence building activities.<br />" +
      "Total: Sum of all months.",
  },
  ebitda: {
    header: "EBITDA",
    tooltip:
      "Earnings before interest, taxes, depreciation, and amortization.<br />" +
      "Total: Sum of all months.",
    canBeNegative: true,
    skipAllReportTotal: true,
  },
  revenue: {
    header: "Revenue",
    tooltip: "Total revenue.<br />" + "Total: Sum of all months.",
    canBeNegative: true,
    skipAllReportTotal: true,
  },
  revenueNIS: {
    header: "Revenue NIS",
    tooltip:
      "Revenue from NIS. Do not include lived experience.<br />" +
      "Total: Sum of all months.",
    canBeNegative: true,
    skipAllReportTotal: true,
  },
  soRDataServices: {
    header: "SoR: Data Services",
    fullHeader: "Share of Revenue: Data Services",
    tooltip:
      "Percentage share of consulting revenue generated from: Data visualization, data architecture, data engineering, data science, AI modelling...<br />" +
      "Total: N/A.",
    isPercent: true,
    skipTotal: true,
    skipAllReportTotal: true,
    canHaveDecimals: true,
    maxValue: 100,
  },
  soRSoftwareDevelopment: {
    header: "SoR: Software Development",
    fullHeader: "Share of Revenue: Software Development",
    tooltip:
      "Percentage share of consulting revenue generated from: Software development, migration...<br />" +
      "Total: N/A.",
    isPercent: true,
    skipTotal: true,
    skipAllReportTotal: true,
    canHaveDecimals: true,
    maxValue: 100,
  },
  soRQATesting: {
    header: "SoR: QA & Testing",
    fullHeader: "Share of Revenue: Quality Assurance & Testing",
    tooltip:
      "Percentage share of consulting revenue generated from: Function, performance, usability, automation, test design...<br />" +
      "Total: N/A.",
    isPercent: true,
    skipTotal: true,
    skipAllReportTotal: true,
    canHaveDecimals: true,
    maxValue: 100,
  },
  soRCyberSecurityCompliance: {
    header: "SoR: Cyber Sec & Compl.",
    fullHeader: "Share of Revenue: Cyber Security & Compliance",
    tooltip:
      "Percentage share of consulting revenue generated from: Penetration testing, advice, system surveillance...<br />" +
      "Total: N/A.",
    isPercent: true,
    skipTotal: true,
    skipAllReportTotal: true,
    canHaveDecimals: true,
    maxValue: 100,
  },
  soROther: {
    header: "SoR: Other",
    fullHeader: "Share of Revenue: Other",
    tooltip:
      "Percentage share of consulting revenue generated from: Salesforce Professional Services, data annotation, other.<br />" +
      "Total: N/A.",
    isPercent: true,
    skipTotal: true,
    skipAllReportTotal: true,
    canHaveDecimals: true,
    maxValue: 100,
  },
  expectedUtilRate: {
    header: "Expected Utilisation Rate",
    fullHeader: "Expected Utilisation Rate",
    totalBehavior: TotalBehavior.CalculationWithAccumulatedDataPoints,
    tooltip:
      "Calculated automatically. Defined as:<br />Expected Billed Hours / (Expected Available Hours - Expected Vacation Hours)<br />" +
      "Total is calculated from accumulated Expected Billed Hours, Expected Available Hours and Expected Vacation Hours.",
    isPercent: true,
  },
  actualUtilRate: {
    header: "Actual Utilisation Rate",
    fullHeader: "Actual Utilisation Rate",
    totalBehavior: TotalBehavior.CalculationWithAccumulatedDataPoints,
    tooltip:
      "Calculated automatically. Defined as:<br />Total Billed Hours / (Total Available Hours - Total Vacation Hours)<br />" +
      "Total is calculated from accumulated Total Billed Hours, Total Available Hours and Total Vacation Hours.",
    isPercent: true,
  },

  newClients: {
    categoryHeader: "Sales",
    header: "New Clients",
    tooltip:
      "Number of new clients for the given month.<br />" +
      "Total: Sum of all months.",
  },
  noOfInvoicedClients: {
    header: "No. of Invoiced Clients",
    fullHeader: "Number of Invoiced Clients",
    tooltip:
      "Number of invoiced clients for the given month.<br />" +
      "Total: Sum of all months.",
  },

  newCertificates: {
    categoryHeader: "Consultants",
    header: "New Certificates",
    tooltip:
      "Total number of new certifications acquired by consultants during the month.<br />Only include completed and acquired certifications, not those in progress.<br />" +
      "Total: Sum of all months.",
  },
  newConsultantApplications: {
    header: "New Consultant Applications",
    tooltip:
      "Number of applications received for consultant roles from autism-diagnosed individuals.<br />" +
      "Total: Sum of all months.",
  },

  noOfPeopleTrainedInNIS: {
    categoryHeader: "NIS",
    header: "No. of Trained in NIS",
    fullHeader: "Number of People Trained in NIS",
    tooltip:
      "Total number of people that received ND training in the month.<br />Include attendees for NIS training (e-learning or trainer-led) and all awareness sessions (online or in-person).<br />" +
      "Total: Sum of all months.",
  },
  noOfClientsLivedExperience: {
    header: "No. of Clients: Lived Exp",
    fullHeader: "Number of Clients with Lived Experience",
    tooltip:
      "Total number of client companies where our consultants have worked or are working since 1 Jan.<br />Also include any companies where JCaaS is provided.<br />" +
      "Total: N/A.",
    skipTotal: true,
  },
  noOfClientsImplementingChange: {
    header: "No. of Clients: Impl. Change",
    fullHeader: "Number of Clients Implementing Change",
    tooltip:
      "Cumulative total number of client companies that have either:<br />1. Implemented NIMA<br />2. Implemented NIS advisory recommendations<br />3. Signed up to auticon training (e-learning or trainer-led)<br />Any company that has done/doing all 3 or a combination of any of the above to be counted as 1.<br />" +
      "Total: N/A.",
    skipTotal: true,
  },
  noOfNDCareersProvided: {
    header: "No. of ND Careers Provided",
    fullHeader: "Number of ND Careers Provided",
    tooltip:
      "Total number of ND people hired since starting operations in the country. Only include those who stayed with us for min 3 months. Also include those in operational roles.<br />" +
      "Total: N/A.",
    skipTotal: true,
  },
};

export type ExtraReportFields =
  | "expectedAvailableHours"
  | "totalAvailableHours"
  | "expectedUtilRate"
  | "actualUtilRate";

export type ReportDescriptor = {
  header: string;
  fullHeader?: string;
  tooltip: string;
  categoryHeader?: string;
  canBeNegative?: boolean;
  canHaveDecimals?: boolean;
  isPercent?: boolean;
  isCurrency?: boolean;
  skipTotal?: boolean;
  skipAllReportTotal?: boolean;
  maxValue?: number;
};

/**
 * Includes all fields of ReportDescriptor,
 * in addition to defining the behavior of
 * calculating the total column
 * if `skipTotal` is not set to true.
 */
export type ExtraReportDescriptor = ReportDescriptor &
  Either<
    {
      totalBehavior: TotalBehavior;
    },
    { skipTotal: true }
  >;

export type MonthlyReportDescriptors = AllPropsAs<
  MonthlyReport,
  number | null,
  ReportDescriptor
> &
  Readonly<Record<ExtraReportFields, ExtraReportDescriptor>>;

export type ExtraReportFunc = (
  report: MonthlyReport,
  accumulatedDataPoints?: boolean,
  month?: number
) => number;

export type CellKey = `${EditableCellKey}|${BackendMonth}`;
export type EditableCellKey = KeyTo<MonthlyReport, number | null>;

export function cellKey(key: EditableCellKey, month: BackendMonth): CellKey {
  return `${key}|${month}`;
}

const headerIdRegExp = /[^\w]/g;
export function getMonthlyReportHeaderId(header: string) {
  return header.replaceAll(headerIdRegExp, "");
}
