import { AssignmentCompetency } from "@/models/AssignmentCompetency";
import { AssignmentCompetencyDisplayable } from "@/models/displayable/AssignmentCompetencyDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { AssignmentCompetencyHeaders } from "./headers/AssignmentCompetencyHeaders";

@singleton()
export class AssignmentCompetencyData extends TableData<
  AssignmentCompetency,
  AssignmentCompetencyDisplayable
> {
  constructor() {
    super(
      "Assignment Competencies",
      new AssignmentCompetencyHeaders(),
      "AssignmentCompetency",
      new AssignmentCompetency(),
      "Assignment Competency"
    );
  }
}
