import { Location } from "@/models/Location";
import { LocationDisplayable } from "@/models/displayable/LocationDisplayable";
import { container, singleton } from "tsyringe";
import { ConsultantGroupData } from "./ConsultantGroupData";
import { LeaderData } from "./LeaderData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { LocationHeaders } from "./headers/LocationHeaders";

@singleton()
export class LocationData extends TableData<Location, LocationDisplayable> {
  constructor() {
    super(
      "Locations",
      new LocationHeaders(),
      "Location",
      new Location(),
      undefined,
      [
        new TablePointer(
          container.resolve(ConsultantGroupData),
          "locationId",
          DeletePolicy.NeverDelete
        ),
        new TablePointer(
          container.resolve(LeaderData),
          "locationId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This location may not be deleted! " +
      "It still has non-empty consultant group(s) or leader(s) under it! " +
      "Reassign or remove each of its remaining consultant groups and leaders";
  }
}
