import { AssignmentBudgetData } from "@/store/data/AssignmentBudgetData";
import { CorporationData } from "@/store/data/CorporationData";
import { CustomerData } from "@/store/data/CustomerData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { AssignmentBudget } from "../AssignmentBudget";
import { Corporation } from "../Corporation";
import { Customer } from "../Customer";
import { CorporationDisplayable } from "./CorporationDisplayable";
import { CustomerDisplayable } from "./CustomerDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { NumberField } from "./fields/NumberField";
import { TableField } from "./fields/TableField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueAssignmentBudget } from "./fields/validators/FieldUniqueAssignmentBudget";

export class AssignmentBudgetDisplayable extends DisplayableBase<AssignmentBudget> {
  assignmentBudgetId = -1; // Needed for uniqueness validation
  @ValidateNested()
  @FieldUniqueAssignmentBudget()
  customer: DropdownField<Customer, CustomerDisplayable>;
  @ValidateNested()
  @FieldUniqueAssignmentBudget()
  corporation: DropdownField<Corporation, CorporationDisplayable>;
  @ValidateNested()
  @FieldUniqueAssignmentBudget()
  year: NumberField;
  @ValidateNested()
  budget: NumberField;
  outcome: TableField;

  constructor(assignmentBudget: AssignmentBudget) {
    super();
    const assignmentBudgetData = container.resolve(AssignmentBudgetData);
    const { headers } = assignmentBudgetData;
    const customerData = container.resolve(CustomerData);
    const corporationData = container.resolve(CorporationData);

    this.assignmentBudgetId = assignmentBudget.assignmentBudgetId;
    this.customer = new DropdownField(
      headers.customer,
      assignmentBudget.customerName,
      customerData,
      new DropdownOption(
        assignmentBudget.customerId,
        assignmentBudget.customerName
      )
    );
    this.corporation = new DropdownField(
      headers.corporation,
      assignmentBudget.corporationName,
      corporationData,
      new DropdownOption(
        assignmentBudget.corporationId,
        assignmentBudget.corporationName
      )
    );
    this.year = new NumberField(headers.year, assignmentBudget.year);
    this.budget = new NumberField(headers.budget, assignmentBudget.budget);

    const outcome = assignmentBudget.calcOutcome();
    this.outcome = new TableField(headers.outcome, outcome.toString());
  }

  toModel(assignmentBudget: AssignmentBudget): void {
    assignmentBudget.customerId = this.customer.selectedOption.id;
    assignmentBudget.corporationId = this.corporation.selectedOption.id;
    assignmentBudget.year = this.year.modelValue();
    assignmentBudget.budget = this.budget.modelValue();
  }
}
