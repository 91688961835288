import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { ConsultantCertificate } from "@/models/ConsultantCertificate";
import { AbsenceData } from "@/store/data/AbsenceData";
import { AssignmentData } from "@/store/data/AssignmentData";
import { ConsultantCertificateData } from "@/store/data/ConsultantCertificateData";
import { ConsultantCompetencyData } from "@/store/data/ConsultantCompetencyData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantServiceData } from "@/store/data/ConsultantServiceData";
import { TableData } from "@/store/data/TableData";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import { container } from "tsyringe";
import { ConsultantReportEntry } from "../ConsultantReportEntry";

export function createConsultantReportEntries(): ConsultantReportEntry[] {
  const consultants = container.resolve(ConsultantData).rows;
  const coveragePeriods = container.resolve(AssignmentData).rows;
  const absences = container.resolve(AbsenceData).rows;
  const entries = consultants.map(
    (c) =>
      new ConsultantReportEntry(
        c,
        createCompetencyList(c.consultantId, true),
        createCompetencyList(c.consultantId, false),
        createCertificateList(c.consultantId),
        createServiceList(c.consultantId),
        coveragePeriods,
        absences
      )
  );

  return entries;
}

export function createCompetencyList(
  consultantId: number,
  key?: boolean
): string {
  return createDataList(
    container.resolve(ConsultantCompetencyData),
    consultantId,
    "consultantId",
    "competencyName",
    key ? (c) => c.isKey : undefined
  );
}

export function createServiceList(consultantId: number): string {
  return createDataList(
    container.resolve(ConsultantServiceData),
    consultantId,
    "consultantId",
    "serviceName",
    undefined
  );
}

export function createCertificateList(consultantId: number): string {
  return createDataList(
    container.resolve(ConsultantCertificateData),
    consultantId,
    "consultantId",
    "certificateName",
    undefined
  );
}

export function createCertificateListWithDates(consultantId: number): string {
  return createDataList(
    container.resolve(ConsultantCertificateData),
    consultantId,
    "consultantId",
    (m: ConsultantCertificate) =>
      m.certificateName.concat(" - ", m.date.substring(0, 4)),
    undefined
  );
}

export function createDataList<M extends Model<D>, D extends Displayable<M>>(
  data: TableData<M, D>,
  id: number,
  idKey: KeyTo<M, number>,
  nameKeyOrFunc: KeyTo<M, string> | ((row: M) => string),
  extraConditional: (m: M) => boolean = () => true
): string {
  const rows = data.rows.filter(
    (m) => getProp(m, idKey) == id && extraConditional(m)
  );

  const names = rows
    .map((r) =>
      typeof nameKeyOrFunc == "function"
        ? nameKeyOrFunc(r)
        : getProp(r, nameKeyOrFunc)
    )
    .sort((a, b) => a.localeCompare(b));
  let output = "";
  let length = 0;
  const compSeparator = " · ";
  const maxRowLength = 35; // results in nice column widths

  for (const name of names) {
    if (length > 0 && length + name.length >= maxRowLength) {
      output += "<br>";
      length = 0;
    }
    output += name + compSeparator;
    length += name.length + compSeparator.length;
  }
  if (output.length <= 0) output = "-";
  else output = output.slice(0, -compSeparator.length);
  return output;
}
