import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import { TableData } from "../TableData";

export interface ITableDataDropdown {
  label: string;
  optionsFilter: (
    value: DropdownOption<string>,
    index: number,
    array: DropdownOption<string>[]
  ) => boolean;
  selectedOption: number;
  refilter: () => void;
  get options(): DropdownOption<string>[];
  // eslint-disable-next-line
  get data(): TableData<any, any>;
}

export class TableDataDropdown<M extends Model<D>, D extends Displayable<M>>
  implements ITableDataDropdown
{
  public label: string;
  public selectedOption: number;
  private _options: DropdownOption<string>[];

  public refilter(): void {
    this._options = [this.baseOption].concat(
      this.data.rowsToDropdownOptions().filter(this.optionsFilter)
    );
    if (!this._options.find((option) => option.id == this.selectedOption))
      this.selectedOption = this.baseOption.id;
  }

  get options(): DropdownOption<string>[] {
    return this._options;
  }

  constructor(
    readonly data: TableData<M, D>,
    private baseOption = new DropdownOption(-1, "<All>"),
    public optionsFilter: (
      value: DropdownOption<string>,
      index: number,
      array: DropdownOption<string>[]
    ) => boolean = () => true
  ) {
    this.label = data.titleSingular;
    this.selectedOption = baseOption.id;
    this._options = [baseOption].concat(
      data.rowsToDropdownOptions().filter(optionsFilter)
    );
  }
}
