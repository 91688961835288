import { ConsultantGroup } from "@/models/ConsultantGroup";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";
import { container, singleton } from "tsyringe";
import { ConsultantData } from "./ConsultantData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { ConsultantGroupHeaders } from "./headers/ConsultantGroupHeaders";

@singleton()
export class ConsultantGroupData extends TableData<
  ConsultantGroup,
  ConsultantGroupDisplayable
> {
  constructor() {
    super(
      "Consultant Groups",
      new ConsultantGroupHeaders(),
      "ConsultantGroup",
      new ConsultantGroup(),
      "Consultant Group",
      [
        new TablePointer(
          container.resolve(ConsultantData),
          "consultantGroupId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This consultant group may not be deleted! " +
      "It still has consultant(s) under it! " +
      "Reassign or remove each of its remaining consultants";
  }
}
