import { IndustryData } from "@/store/data/IndustryData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Industry } from "../Industry";
import { DisplayableBase } from "./DisplayableBase";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class IndustryDisplayable extends DisplayableBase<Industry> {
  @ValidateNested()
  @FieldUniqueText<Industry, IndustryDisplayable>()
  name: UniqueTextField<Industry, IndustryDisplayable>;

  constructor(industry: Industry) {
    super();
    const industryData = container.resolve(IndustryData);
    const { headers } = industryData;
    this.name = new UniqueTextField(headers.name, industry.name, industryData);
  }

  toModel(industry: Industry): void {
    industry.name = this.name.modelValue();
  }
}
