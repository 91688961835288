<template>
  <input
    :id="
      thisField.header.toLocaleLowerCase().replace(/[^a-z0-9]/g, '') + '-field'
    "
    type="number"
    class="edit-input"
    :class="hasError && 'error'"
    v-model="thisField.value"
    :size="3"
    :min="MIN"
    :max="MAX"
    v-on:input="signalInputUpdate()"
  />
</template>

<script setup lang="ts">
import {
  PercentageField,
  PercentageField_MIN10,
} from "@/models/displayable/fields/PercentageField";
import { FieldType } from "@/models/displayable/fields/enum/FieldType";
import { computed } from "vue";
const props = defineProps<{
  field: PercentageField | PercentageField_MIN10;
  hasError?: boolean;
}>();
const emit = defineEmits(["input-updated"]);
const signalInputUpdate = () => emit("input-updated");
const thisField = computed(() => props.field);
const MIN = computed(() =>
  props.field.type == FieldType.PERCENTAGE
    ? PercentageField.MIN
    : PercentageField_MIN10.MIN
);
const MAX = computed(() =>
  props.field.type == FieldType.PERCENTAGE
    ? PercentageField.MAX
    : PercentageField_MIN10.MAX
);
</script>
<style scoped lang="scss">
@import "../../styles/global.scss";

input {
  @include input;
}

//Firefox specific bodge in /assignments
@supports (-moz-appearance: none) {
  input {
    width: 70%;
  }
}

.error {
  @include error;
}
</style>
