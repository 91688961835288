import { TableHeaders } from "@/interfaces/TableHeaders";
import { TaskmasterDisplayable } from "@/models/displayable/TaskmasterDisplayable";

export class TaskmasterHeaders implements TableHeaders<TaskmasterDisplayable> {
  public readonly customer = "Customer";
  public readonly firstname = "First name";
  public readonly lastname = "Last name";
  public readonly fullName = "Full name";
  public readonly title = "Title"; //maybe change to "position", some translation of "befattning"
}
