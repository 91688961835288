<template>
  <div class="container">
    <div class="card">
      <div class="cardBody">
        <label class="form-label">
          Email-address:
          <input
            v-model="state.form.email"
            type="email"
            class="form-control"
            id="emailInput"
            placeholder="name@unicus/auticon.com"
            @input="state.buttonDisabled = false"
          />
        </label>
        <label class="form-label">
          Old password:
          <input
            v-model="state.form.oldPassword"
            type="password"
            class="form-control"
            id="oldPasswordInput"
            placeholder="Current password"
            @input="state.buttonDisabled = false"
          />
        </label>
        <label class="form-label">
          New password:
          <input
            v-model="state.form.newPassword"
            type="password"
            class="form-control"
            id="newPasswordInput"
            placeholder="5 - 20 characters"
            @input="state.buttonDisabled = false"
          />
        </label>
        <ThePasswordStrengthMeter :password="state.form.newPassword" />
        <label class="form-label">
          Confirm new password:
          <input
            v-model="state.form.confirmNewPassword"
            type="password"
            class="form-control"
            id="confirmPasswordInput"
            @input="state.buttonDisabled = false"
          />
        </label>
      </div>
      <button
        type="submit"
        id="submit-btn"
        class="btn btn-primary"
        @click.prevent="submit()"
        :disabled="state.buttonDisabled"
      >
        Submit
      </button>
      <div v-show="state.matchingPasswordsError" class="alert alert-danger">
        'Confirm password' and 'New passwords' fields are different
      </div>
      <div v-show="state.newPasswordSizeError" class="alert alert-danger">
        Your new password needs to be at least
        {{ state.passwordMinLength }} characters and at most
        {{ state.passwordMaxLength }} characters
      </div>
      <div v-show="state.showSuccess" class="alert alert-success" role="alert">
        Your password has successfully been changed!
      </div>
      <div
        v-show="state.showGenericError"
        class="alert alert-danger"
        role="alert"
      >
        Something went wrong, please try again later.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { actions } from "@/auth/user";
import ThePasswordStrengthMeter from "@/components/ThePasswordStrengthMeter.vue";
import { setProp } from "@/types/getProp";
import { KeyTo } from "@/types/KeyTo";
import axios from "axios";
import { reactive } from "vue";

const state = reactive({
  form: {
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
  matchingPasswordsError: false,
  newPasswordSizeError: false,
  showSuccess: false,
  showGenericError: false,
  buttonDisabled: false,
  passwordMinLength: 5,
  passwordMaxLength: 20,
});

function reset() {
  state.showSuccess = false;
  state.showGenericError = false;
  state.matchingPasswordsError = false;
  state.newPasswordSizeError = false;
  state.form.email = "";
  state.form.oldPassword = "";
  state.form.newPassword = "";
  state.form.confirmNewPassword = "";
  state.buttonDisabled = false;
}

function handleInvalidPassword(fault: KeyTo<typeof state, boolean>) {
  setProp(state, fault, true);
  state.buttonDisabled = true;
  setTimeout(() => setProp(state, fault, false), 5000);
}

function submit() {
  if (state.form.newPassword != state.form.confirmNewPassword) {
    handleInvalidPassword("matchingPasswordsError");
    return;
  }

  const { length } = state.form.newPassword;
  if (length < state.passwordMinLength || length > state.passwordMaxLength) {
    handleInvalidPassword("newPasswordSizeError");
    return;
  }

  axios
    .post("Password/ChangePassword", {
      email: state.form.email,
      oldPassword: state.form.oldPassword,
      newPassword: state.form.newPassword,
    })
    .then(() => {
      state.showSuccess = true;
      setTimeout(actions.logoutAllSessions, 5000);
    })
    .catch((error) => {
      console.error(error);
      state.showGenericError = true;
    })
    .finally(() => {
      state.buttonDisabled = true;
      setTimeout(() => {
        reset();
      }, 5000);
    });
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.card {
  width: 19rem;
  margin-top: 1rem;
  padding: 10px;
}
.alert {
  margin-top: 1rem;
  margin-bottom: 0;
}
</style>
