import { LOCALE_SV_SE } from "@/Constant";
import { KeyTo } from "@/types/KeyTo";
import { PropsAs, PropsOf } from "@/types/PropsOf";
import * as d3 from "d3";

function generateStackedBarChart<
  T extends PropsOf<T, number> & { month: Date }
>(
  data: T[],
  keys: (string & KeyTo<T, number>)[],
  colorMap: PropsAs<T, number, string>,
  getBarLabel: (date: Date) => string,
  getDisplayInfoTagInnerHtml: (data: T) => string,
  prefix = "",
  suffix = "",
  snapshotData: T[] = []
): void {
  const stackedData = d3.stack<T>().keys(keys).order(d3.stackOrderReverse)([
    ...data,
    ...snapshotData,
  ]);

  const chartWidth = window.innerWidth * 0.5;
  const chartHeight = window.innerHeight * 0.85;

  function barLabel(d: T) {
    return getBarLabel(d.month);
  }

  const months = data.map(barLabel);

  const xScale = d3
    .scaleBand()
    .domain(months)
    .range([0, chartWidth])
    .padding(0.1);

  const maxEmployees = Math.max(
    ...data.map((x) => {
      let i = 0;
      for (const key of keys) {
        i += x[key];
      }
      return i;
    }),
    ...snapshotData.map((x) => {
      let i = 0;
      for (const key of keys) {
        i += x[key];
      }
      return i;
    })
  );

  const yScale = d3
    .scaleLinear()
    .domain([0, maxEmployees * 1.3])
    .range([chartHeight * 0.75, 0]);

  let yTickSteps = 1;
  while (yTickSteps < maxEmployees / 100) {
    yTickSteps *= 10;
  }

  for (let i = yTickSteps; yTickSteps < maxEmployees / 10; yTickSteps += i);

  if (yTickSteps < 10 && yTickSteps != 1) {
    yTickSteps = yTickSteps < 5 ? 5 : 10;
  }

  const yAxisDesign = d3
    .axisLeft(yScale)
    .ticks(5)
    .tickValues(d3.range(yTickSteps, maxEmployees * 1.3, yTickSteps))
    .tickSizeInner(3)
    .tickSizeOuter(0)
    .tickFormat((d) => `${prefix}${d.toLocaleString(LOCALE_SV_SE)}${suffix}`);

  const getColor = (phase: string) => {
    return colorMap[phase as KeyTo<T, number>] ?? "white";
  };

  const isAhead = (monthDate: Date) => {
    const dateEndOfLastMonth = new Date();
    dateEndOfLastMonth.setDate(0);
    return monthDate > dateEndOfLastMonth;
  };

  const chart = d3.select("#chart");
  chart.selectAll("svg").remove();

  const svg = chart
    .append("svg")
    .attr("overflow", "visible")
    .attr("width", chartWidth)
    .attr("height", chartHeight * 0.86);

  const bars = svg
    .selectAll(".serie")
    .data(stackedData)
    .enter()
    .append("g")
    .attr("class", "serie")
    .attr("fill", (d) => getColor(d.key))
    .attr("stroke", "black")
    .attr("data-phase", (d) => d.key)
    .selectAll("rect");

  const getOffsetX = (d: T) => {
    const isSecondary = snapshotData.length && snapshotData.includes(d);
    return isSecondary ? xScale.bandwidth() * 0.67 : 0;
  };

  const getWidthAdjustment = (d: T) => {
    return snapshotData.length ? (snapshotData.includes(d) ? 0.33 : 0.66) : 1;
  };

  bars
    .data((d) => d)
    .enter()
    .append("rect")
    .on("mouseover", triggerInfoDisplay)
    .on("mousemove", moveTag)
    .on("mouseout", hideInfo)
    .attr("opacity", (d) => (isAhead(d.data.month) ? 0.7 : 1))
    .attr("i", (_d, i) => i)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .attr("x", (d) => xScale(barLabel(d.data))! + getOffsetX(d.data))
    .attr("y", chartHeight * 0.75)
    .attr("height", 0)
    .attr("width", (d) => xScale.bandwidth() * getWidthAdjustment(d.data))
    .transition()
    .duration((_d, i) => i * 40)
    .delay((_d, i) => i * 10)
    .attr("y", (d) => yScale(d[1]))
    .attr("height", (d) => yScale(d[0]) - yScale(d[1]));

  const yAxis = svg.append("g").attr("class", "axis axis--y").call(yAxisDesign);
  yAxis
    .selectAll("text")
    .style("font-size", "14px")
    .attr("transform", "translate(-5,0)");

  const xAxisDesign = d3.axisBottom(xScale).tickSize(0).tickSizeOuter(0);
  const xAxis = svg
    .append("g")
    .attr("class", "axis axis--x")
    .attr("transform", `translate(0, ${chartHeight * 0.75})`)
    .call(xAxisDesign);

  const barCount = data.length;
  if (barCount > 10) {
    xAxis
      .selectAll("text")
      //set opacity for every other x-axis label if barCount > 20
      .attr("opacity", barCount > 20 ? (_d, i) => (i % 2 === 1 ? 1 : 0) : 1)
      .style("font-size", "13px")
      .style("text-anchor", "end")
      .attr("transform-origin", "end")
      .attr("transform", "translate(-2, 3) rotate(-45)");
  } else {
    xAxis.selectAll("text").style("font-size", "14px").attr("y", "10");
  }

  function triggerInfoDisplay(
    this: SVGRectElement,
    e: MouseEvent,
    { data }: { data: T }
  ) {
    const series = this.parentElement;
    if (series) {
      const phase = series.getAttribute("data-phase") ?? "grey";
      this.style.fill = getColor(phase);
    }
    const x = e.pageX;
    const y = e.pageY;
    displayInfoTag(x, y, data);
  }

  const tag = document.getElementById("tag");
  const displayInfoTag = (x: number, y: number, d: T) => {
    const infoTag = tag;
    if (!infoTag) return;
    infoTag.style.top = `${y}px`;
    infoTag.style.left = `${x}px`;
    infoTag.innerHTML = `${getDisplayInfoTagInnerHtml(d)}`;

    infoTag.style.opacity = "1";
  };

  function moveTag(this: SVGRectElement, e: MouseEvent) {
    const infoTag = tag;
    if (!infoTag) return;
    infoTag.style.top = `${e.pageY - 20}px`;
    infoTag.style.left = `${e.pageX + 20}px`;
  }

  function hideInfo(this: SVGRectElement) {
    const series = this.parentElement;
    if (series) {
      const phase = series.getAttribute("data-phase") ?? "grey";
      this.style.fill = getColor(phase);
    }
    const infoTag = tag;
    if (infoTag) infoTag.style.opacity = "0";
  }
}

export default generateStackedBarChart;
