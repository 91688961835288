import { TableHeaders } from "@/interfaces/TableHeaders";
import { AssignmentUrlDisplayable } from "@/models/displayable/AssignmentUrlDisplayable";

export class AssignmentUrlHeaders
  implements TableHeaders<AssignmentUrlDisplayable>
{
  public readonly assignment = "Assignment";
  public readonly name = "Title";
  public readonly url = "Url";
}
