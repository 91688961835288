import { IClosedDated, IOpenDated } from "@/interfaces/Dated";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { Consultant } from "@/models/Consultant";
import { NisConsultant } from "@/models/NisConsultant";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import { ConsultantDisplayable } from "../../ConsultantDisplayable";
import { NisConsultantDisplayable } from "../../NisConsultantDisplayable";
import { DateField } from "../DateField";
import { DropdownField } from "../DropdownField";
import { IEmployeeDisplayable } from "./FieldValidEmployeeEndDate";
import { VeaValidate } from "./VeaValidator";

export function FieldConsultantEmployed<
  M extends Model<D> & IClosedDated,
  D extends Displayable<M>
>(
  dropdownConsultantKey: KeyTo<
    D,
    DropdownField<Consultant, ConsultantDisplayable>
  >
) {
  return FieldEmployeeEmployed<M, D, Consultant, ConsultantDisplayable>(
    dropdownConsultantKey
  );
}

export function FieldNisConsultantEmployed<
  M extends Model<D> & IClosedDated,
  D extends Displayable<M>
>(
  dropdownConsultantKey: KeyTo<
    D,
    DropdownField<NisConsultant, NisConsultantDisplayable>
  >
) {
  return FieldEmployeeEmployed<M, D, NisConsultant, NisConsultantDisplayable>(
    dropdownConsultantKey
  );
}

export function FieldEmployeeEmployed<
  M extends Model<D> & IClosedDated,
  D extends Displayable<M>,
  EM extends Model<ED> & IOpenDated,
  ED extends Displayable<EM> & IEmployeeDisplayable
>(consultantKey: KeyTo<D, DropdownField<EM, ED>>) {
  return VeaValidate({
    message(field: DateField, object: D) {
      return field.error.consultantNotEmployedError(
        field.value,
        getProp(object, consultantKey).value
      );
    },
    validate(field, object) {
      const consultantField = getProp(object, consultantKey);
      const consultant = consultantField.data.findById(
        consultantField.selectedOption.id
      );
      if (!consultant || !consultant.getId()) return true;
      const { startDate, endDate } = consultant;
      return field.value >= startDate && (!endDate || field.value <= endDate);
    },
  });
}
