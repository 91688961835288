import { RoleData } from "@/store/data/RoleData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Role } from "../Role";
import { DisplayableBase } from "./DisplayableBase";
import { TextField } from "./fields/TextField";

export class RoleDisplayable extends DisplayableBase<Role> {
  @ValidateNested()
  name: TextField;

  constructor(role: Role) {
    super();
    const roleData = container.resolve(RoleData);
    const { headers } = roleData;
    this.name = new TextField(headers.name, role.name);
  }

  toModel(role: Role): void {
    role.name = this.name.modelValue();
  }
}
