<template>
  <div class="period">
    <UnselectableLabel>
      from
      <input
        id="date-from"
        type="month"
        :min="minDate"
        :max="maxDate"
        :pattern="MONTH_DATE_FORMAT"
        v-model="start"
      />
    </UnselectableLabel>
    <UnselectableLabel>
      until
      <input
        id="date-to"
        type="month"
        :min="minDate"
        :max="maxDate"
        :pattern="MONTH_DATE_FORMAT"
        v-model="end"
      />
    </UnselectableLabel>
    <p class="msg-invalid" :class="{ visible: !isValidPeriod }">
      Invalid Period
    </p>
  </div>
</template>

<script setup lang="ts">
import { MONTH_DATE_FORMAT } from "@/diagram/constant/MonthDateFormat";
import { MonthKey, dateKey } from "@/types/DateKey";
import { computed } from "vue";
import UnselectableLabel from "../UnselectableLabel.vue";
const minDate = "2009-01";
const currentDate = new Date();
const maxDate = dateKey(
  new Date(currentDate.getFullYear() + 1, currentDate.getMonth() + 12)
);
const start = defineModel<MonthKey>("start");
const end = defineModel<MonthKey>("end");
const isValidPeriod = computed(
  () =>
    start.value != undefined &&
    end.value != undefined &&
    start.value < end.value
);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.period {
  @include period;
  @include form-field;

  label {
    @include label;
  }

  .msg-invalid {
    @include msg-invalid;

    &.visible {
      opacity: 1;
      transition: opacity 200ms;
    }
  }
}

input {
  border-radius: 4px;
  border-color: $color-lightgrey;
  height: 1.8rem;

  &:invalid {
    outline: 2px solid $color-red;
  }
}
</style>
