import { CorporationCustomerDisplayable } from "./displayable/CorporationCustomerDisplayable";
import { ModelBase } from "./ModelBase";

export class CorporationCustomer extends ModelBase<CorporationCustomerDisplayable> {
  corporationCustomerId: number;
  corporationName: string;
  customerName: string;
  corporationId: number;
  customerId: number;

  constructor() {
    super();
    this.corporationCustomerId = 0;
    this.corporationName = "";
    this.customerName = "";
    this.corporationId = 0;
    this.customerId = 0;
  }

  realize(partialModel: CorporationCustomer): CorporationCustomer {
    const newModel = new CorporationCustomer();
    newModel.corporationCustomerId = partialModel.corporationCustomerId;
    newModel.corporationName = partialModel.corporationName;
    newModel.corporationId = partialModel.corporationId;
    newModel.customerName = partialModel.customerName;
    newModel.customerId = partialModel.customerId;

    return newModel;
  }
  getId(): number {
    return this.corporationCustomerId;
  }
  getName(): string {
    return `${this.corporationName} → ${this.customerName}`;
  }
  getDisplayable(): CorporationCustomerDisplayable {
    return new CorporationCustomerDisplayable(this);
  }
}
