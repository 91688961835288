import { Absence } from "@/models/Absence";
import { Assignment } from "@/models/Assignment";
import { Month } from "@/models/enum/Months";
import { VeaDates } from "@/util/VeaDates";
import {
  DateKey,
  MonthKey,
  dateKey,
  dayFromKey,
  monthFromKey,
  monthKey,
  monthKeyFromDate,
  monthKeyFromYearAndMonth,
  yearFromKey,
} from "./DateKey";

export type DateSpan = {
  start: DateKey;
  end: DateKey;
};

export type MonthSpan = {
  start: MonthKey;
  end: MonthKey;
};

type MonthOrDateSpan<T extends MonthKey | DateKey> = {
  start: T;
  end: T;
};

/**
 * @returns whether two spans overlap.
 */
export function overlaps<T extends MonthSpan | DateSpan>(a: T, b: T): boolean {
  return a.end >= b.start && a.start <= b.end;
}

/**
 * @returns a {@link DateSpan} representing the overlapping days of two given {@link DateSpan}s.
 */
export function overlappingDays(a: DateSpan, b: DateSpan): DateSpan | null {
  if (a.start > b.end || a.end < b.start) {
    return null;
  }
  const start = a.start > b.start ? a.start : b.start;
  const end = a.end < b.end ? a.end : b.end;
  return { start, end };
}

/**
 * @param point a specific date or month
 * @param span a period constrained by two dates or months
 * @returns whether the span contains the specified date/month.
 */
export function isInSpan<T extends MonthKey | DateKey>(
  point: T,
  span: MonthOrDateSpan<T>
): boolean {
  return point >= span.start && point <= span.end;
}

/**
 * Constructs a {@link MonthSpan}.
 */
export function monthSpan(start: string | Date, end: string | Date): MonthSpan {
  return {
    start: monthKey(start),
    end: monthKey(end),
  };
}

/**
 * constructs a {@link DateSpan}.
 */
export function dateSpan(start: string | Date, end: string | Date): DateSpan {
  return {
    start: dateKey(start),
    end: dateKey(end),
  };
}

/**
 * @returns the first date of the given {@link MonthKey} as a {@link DateKey}.
 */
export function firstDayOfMonth(month: MonthKey): DateKey {
  return `${month}-01`;
}

/**
 *
 * @returns the first date of the given {@link MonthKey} as a {@link Date}.
 */
export function firstDateOfMonth(month: MonthKey): Date {
  return new Date(yearFromKey(month), monthFromKey(month), 1);
}

/**
 *
 * @returns the given {@link MonthKey} as a {@link DateSpan},
 * starting from the first day of the month and ending on the last day of the month.
 */
export function monthAsDateSpan(month: MonthKey): DateSpan {
  const date = firstDateOfMonth(month);
  const start = dateKey(date);

  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  const end = dateKey(date);

  return { start, end };
}

/**
 * @returns the number of days in a specific month.
 */
export function countDaysInMonth(month: MonthKey): number {
  const date = new Date(yearFromKey(month), monthFromKey(month), 1);
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  return date.getDate();
}

/**
 *
 * @returns the number of work days (Mon-Fri) in a specific month.
 */
export function countWorkDaysInMonth(month: MonthKey): number {
  const days = countDaysInMonth(month);
  const firstDay = firstDayOfMonth(month);
  const remainder = days % 7;
  let weekends = ((days - remainder) / 7) * 2;
  if (remainder > 0) {
    const day = VeaDates.getDayOfWeek(firstDay) || 7;
    weekends +=
      day >= 6
        ? Math.min(8 - day, remainder)
        : Math.max(0, Math.min(2, remainder + day - 6));
  }
  return days - weekends;
}

/**
 *
 * @returns the number of days in a {@link DateSpan}.
 */
export function countDaysInSpan(span: DateSpan): number {
  // If the span starts and ends in the same year and month,
  // we can skip all Date calculations.
  if (monthKeyFromDate(span.start) == monthKeyFromDate(span.end)) {
    return 1 + dayFromKey(span.end) - dayFromKey(span.start);
  }

  const differenceInMilliseconds = Math.abs(
    utcFromKey(span.end) - utcFromKey(span.start)
  );

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / millisecondsPerDay
  );

  return differenceInDays + 1;
}

/**
 * @returns the number of months in a {@link MonthSpan}.
 */
export function monthsInSpan(span: MonthSpan): MonthKey[] {
  if (span.start == span.end) {
    return [span.start];
  }

  const months: MonthKey[] = [];
  let key = span.start;
  let year = yearFromKey(span.start);
  let month = monthFromKey(span.start);
  do {
    months.push(key);
    if (monthFromKey(key) == Month.Dec) {
      month = Month.Jan;
      year++;
    } else {
      month++;
    }
    key = monthKeyFromYearAndMonth(year, month);
  } while (key <= span.end);

  return months;
}

export function utcFromKey(date: DateKey): number {
  return Date.UTC(yearFromKey(date), monthFromKey(date), dayFromKey(date));
}

export function periodSpan(period: Assignment | Absence): DateSpan {
  return dateSpan(period.startDate, period.endDate);
}
