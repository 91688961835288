import { NisServiceDisplayable } from "@/models/displayable/NisServiceDisplayable";
import NisService from "@/models/NisService";
import { TableDataOptions } from "../TableDataOptions";

export class NisServiceOptions extends TableDataOptions<
  NisService,
  NisServiceDisplayable
> {
  public constructor() {
    super({
      filterBoxKeys: ["name"],
    });
  }
}
