import { ReportData } from "@/coveragePeriodReport/ReportData";
import { EventEmitter } from "@/util/EventEmitter";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { createStore } from "vuex";
import { SettingsManager } from "./SettingsManager";
import { Statistics } from "./Statistics";
import { AbsenceCategoryData } from "./data/AbsenceCategoryData";
import { AbsenceData } from "./data/AbsenceData";
import { AssignmentBudgetData } from "./data/AssignmentBudgetData";
import { AssignmentCompetencyData } from "./data/AssignmentCompetencyData";
import { AssignmentData } from "./data/AssignmentData";
import { AssignmentUrlData } from "./data/AssignmentUrlData";
import { BackgroundData } from "./data/BackgroundData";
import BrowserData from "./data/BrowserData";
import { CertificateData } from "./data/CertificateData";
import { CompetencyCategoryData } from "./data/CompetencyCategoryData";
import { CompetencyData } from "./data/CompetencyData";
import { ConsultantCertificateData } from "./data/ConsultantCertificateData";
import { ConsultantCompetencyData } from "./data/ConsultantCompetencyData";
import { ConsultantData } from "./data/ConsultantData";
import { ConsultantGroupData } from "./data/ConsultantGroupData";
import { ConsultantServiceData } from "./data/ConsultantServiceData";
import { ConsultantUrlData } from "./data/ConsultantUrlData";
import { CorporationCustomerData } from "./data/CorporationCustomerData";
import { CorporationData } from "./data/CorporationData";
import { CurrencyData } from "./data/CurrencyData";
import { CustomerData } from "./data/CustomerData";
import FileData from "./data/FileData";
import { IndustryData } from "./data/IndustryData";
import { InvoiceData } from "./data/InvoiceData";
import { LeaderData } from "./data/LeaderData";
import { LocationData } from "./data/LocationData";
import { MonthlyReportData } from "./data/MonthlyReportData";
import { NisAssignmentData } from "./data/NisAssignmentData";
import { NisConsultantData } from "./data/NisConsultantData";
import { NisConsultantGroupData } from "./data/NisConsultantGroupData";
import { NisServiceData } from "./data/NisServiceData";
import { PriorityData } from "./data/PriorityData";
import { RecruitmentData } from "./data/RecruitmentData";
import { ReportSettingsData } from "./data/ReportSettingsData";
import { RoleData } from "./data/RoleData";
import { ServiceData } from "./data/ServiceData";
import { TableData } from "./data/TableData";
import { TaskmasterData } from "./data/TaskmasterData";
import { UserData } from "./data/UserData";
import { BackendErrorSet } from "./data/error/BackendErrorSet";
import { LoginFormData } from "./data/forms/LoginFormData";
import { PasswordFormData } from "./data/forms/PasswordFormData";
import { ProfileInfo } from "./data/types/ProfileInfo";

/**
 * The stored data values that HTML-templates use.
 */
export class VeaState {
  absenceData: AbsenceData = container.resolve(AbsenceData);
  absenceCategoryData: AbsenceCategoryData =
    container.resolve(AbsenceCategoryData);
  assignmentData: AssignmentData = container.resolve(AssignmentData);
  assignmentBudgetData: AssignmentBudgetData =
    container.resolve(AssignmentBudgetData);
  assignmentCompetencyData: AssignmentCompetencyData = container.resolve(
    AssignmentCompetencyData
  );
  backgroundData: BackgroundData = container.resolve(BackgroundData);
  consultantData: ConsultantData = container.resolve(ConsultantData);
  consultantGroupData: ConsultantGroupData =
    container.resolve(ConsultantGroupData);
  consultantServiceData: ConsultantServiceData = container.resolve(
    ConsultantServiceData
  );
  corporationData: CorporationData = container.resolve(CorporationData);
  corporationCustomerData: CorporationCustomerData = container.resolve(
    CorporationCustomerData
  );
  currencyData: CurrencyData = container.resolve(CurrencyData);
  customerData: CustomerData = container.resolve(CustomerData);
  locationData: LocationData = container.resolve(LocationData);
  priorityData: PriorityData = container.resolve(PriorityData);
  reportData: ReportData = container.resolve(ReportData);
  recruitmentData: RecruitmentData = container.resolve(RecruitmentData);
  roleData: RoleData = container.resolve(RoleData);
  serviceData: ServiceData = container.resolve(ServiceData);
  taskmasterData: TaskmasterData = container.resolve(TaskmasterData);
  userData: UserData = container.resolve(UserData);
  competencyData: CompetencyData = container.resolve(CompetencyData);
  competencyCategoryData: CompetencyCategoryData = container.resolve(
    CompetencyCategoryData
  );
  consultantCompetencyData: ConsultantCompetencyData = container.resolve(
    ConsultantCompetencyData
  );
  certificateData: CertificateData = container.resolve(CertificateData);
  consultantCertificateData: ConsultantCertificateData = container.resolve(
    ConsultantCertificateData
  );
  consultantUrlData: ConsultantUrlData = container.resolve(ConsultantUrlData);
  assignmentUrlData: AssignmentUrlData = container.resolve(AssignmentUrlData);
  leaderData: LeaderData = container.resolve(LeaderData);
  industryData: IndustryData = container.resolve(IndustryData);
  settings: SettingsManager = container.resolve(SettingsManager);
  fileData: FileData = container.resolve(FileData);
  statistics: Statistics = container.resolve(Statistics);
  profileInfo: ProfileInfo = container.resolve(ProfileInfo);

  nisAssignmentData: NisAssignmentData = container.resolve(NisAssignmentData);
  nisConsultantData: NisConsultantData = container.resolve(NisConsultantData);
  nisConsultantGroupData: NisConsultantGroupData = container.resolve(
    NisConsultantGroupData
  );
  nisServiceData: NisServiceData = container.resolve(NisServiceData);
  invoiceData: InvoiceData = container.resolve(InvoiceData);

  //Forms
  loginFormData: LoginFormData = container.resolve(LoginFormData);
  passwordFormData: PasswordFormData = container.resolve(PasswordFormData);

  assignmentId: number | null = null;

  absenceId: number | null = null;

  consultantId: number | null = null;

  userId: number | null = null;

  competenciesViewUserId: number | null = null;
  connectionRowId: number | null = null;

  //Diagrams
  monthlyReportData: MonthlyReportData = container.resolve(MonthlyReportData);

  //Filters and sorting fetched from local storage
  reportSettingsData: ReportSettingsData =
    container.resolve(ReportSettingsData);

  browserData: BrowserData = container.resolve(BrowserData);
  events: EventEmitter = container.resolve(EventEmitter);

  // failsafe so we dont accidentally run it twice
  private sentAllGetRequests: boolean = false;
  async sendAllGetRequests(): Promise<void | void[]> {
    if (this.sentAllGetRequests) {
      return;
    }
    this.sentAllGetRequests = true;

    const requests = new Array<Promise<void>>();
    for (const data of Object.values(this)) {
      if (data instanceof TableData) {
        requests.push(data.getRequest());
      }
    }
    return await Promise.all(requests).catch((error: AxiosError) => {
      container.resolve(BackendErrorSet).addGetError(error);
      console.warn(error);
    });
  }
}

export default createStore({
  state: new VeaState(),
});
