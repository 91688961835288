<template>
  <div class="container">
    <div class="title-section">
      <h1>Customer Overview</h1>
    </div>
    <div class="options-container">
      <div class="options">
        <div class="select-container">
          <div class="filter-box">
            <label><strong>Company</strong></label>
            <select id="corpSelector" v-model="state.corporationId">
              <option :value="-1">&lt;All&gt;</option>
              <option
                v-for="corporation of corporations"
                :key="corporation.corporationId"
                :value="corporation.corporationId"
              >
                {{ corporation.name }}
              </option>
            </select>
          </div>
          <div class="filter-box">
            <label><strong>Budget year</strong></label>
            <input
              id="budgetYear"
              type="number"
              min="2020"
              max="2099"
              v-model="state.year"
            />
          </div>
          <div class="filter-box">
            <label><strong>Date for budget outcome</strong></label>
            <input
              id="budget-outcome-date"
              type="date"
              v-model="state.outcomeDate"
            />
          </div>
        </div>
        <div class="radio-container">
          <div class="filter-container">
            <div class="radio-box sort-box">
              <strong>Also display</strong>
              <label for="emptyLeaders">
                <input
                  id="emptyLeaders"
                  type="checkbox"
                  v-model="state.showEmptyLeaders"
                />
                <span>Leaders without customers</span>
              </label>
              <label for="leaderlessCustomers">
                <input
                  id="leaderlessCustomers"
                  type="checkbox"
                  v-model="state.showLeaderlessCustomers"
                />
                <span>Unassigned customers</span>
              </label>
              <label for="priolessCustomers">
                <input
                  id="priolessCustomers"
                  type="checkbox"
                  v-model="state.showPriolessCustomers"
                />
                <span>Unprioritized customers</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <TableButtonAdd
          v-if="isAuthorizedToEdit"
          @click="actionAssignmentBudget()"
        >
          Add Assignment Budget
        </TableButtonAdd>
      </div>
    </div>
    <div class="tablewrapper">
      <table class="table">
        <tbody
          v-if="
            getDisplayedLeaders().length == 0 && filteredCustomers().length == 0
          "
        >
          <th scope="col">No data to display</th>
        </tbody>
        <tbody v-else>
          <tr class="table-border">
            <!-- <th scope="col">&nbsp;</th> -->
            <th scope="col">Total {{ getTotalsString() }}</th>
            <th
              v-for="leader of getDisplayedLeaders()"
              :key="leader.getId()"
              scope="col"
            >
              {{
                leader.getName() +
                " " +
                getTotalsForLeaderString(leader.leaderId)
              }}
            </th>
            <th scope="col" v-if="state.showLeaderlessCustomers">
              <i>(no leader assigned)</i>
              <br />
              {{ getTotalsForLeaderString(null) }}
            </th>
          </tr>
          <tr
            v-for="priority of sortedPrios()"
            :key="priority.getOrderNumber()"
          >
            <th scope="row" class="table-border">
              {{ priority.getName() }} <br />
              {{ getTotalsForPriorityString(priority.priorityId) }}
            </th>
            <CustomerOverviewCell
              v-for="leader of getDisplayedLeaders()"
              :key="leader.getId()"
              :filtered-customers="filteredCustomers"
              :edit-customer="actionCustomer"
              :edit-assignment-budget="actionAssignmentBudget"
              :priority="priority"
              :leader="leader"
              :year="state.year"
              :corporationId="state.corporationId"
              :outcome-date="state.outcomeDate"
            />
            <CustomerOverviewCell
              v-if="state.showLeaderlessCustomers"
              class="null-col"
              :filtered-customers="filteredCustomers"
              :edit-customer="actionCustomer"
              :edit-assignment-budget="actionAssignmentBudget"
              :priority="priority"
              :year="state.year"
              :corporationId="state.corporationId"
              :outcome-date="state.outcomeDate"
            />
          </tr>
          <tr v-if="state.showPriolessCustomers">
            <th scope="row">
              <i>(no priority<br />assigned)</i>
              <br />
              {{ getTotalsForPriorityString(null) }}
            </th>
            <CustomerOverviewCell
              v-for="leader of getDisplayedLeaders()"
              :key="leader.getId()"
              class="null-row"
              :filtered-customers="filteredCustomers"
              :edit-customer="actionCustomer"
              :edit-assignment-budget="actionAssignmentBudget"
              :leader="leader"
              :year="state.year"
              :corporationId="state.corporationId"
              :outcome-date="state.outcomeDate"
            />
            <CustomerOverviewCell
              v-if="state.showLeaderlessCustomers"
              class="null-row null-col"
              :filtered-customers="filteredCustomers"
              :edit-customer="actionCustomer"
              :edit-assignment-budget="actionAssignmentBudget"
              :year="state.year"
              :corporationId="state.corporationId"
              :outcome-date="state.outcomeDate"
            />
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <BaseModalForm
    :data="(state.actionableData as AssignmentBudgetData)"
    :action="state.action"
    :extraValues="state.actionPreset"
    :submitEvent="() => $emit('edit')"
    v-if="state.actionableData && state.actionableData.isModifying()"
  />
</template>

<script setup lang="ts">
import { AssignmentBudget } from "@/models/AssignmentBudget";
import { Customer } from "@/models/Customer";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { AssignmentBudgetData } from "@/store/data/AssignmentBudgetData";
import { CustomerData } from "@/store/data/CustomerData";
import { Action } from "@/store/data/enum/Action";
import { today, yearFromKey } from "@/types/DateKey";
import { container } from "tsyringe";
import { computed, reactive } from "vue";
import BaseModalForm from "../components/BaseModalForm.vue";
import TableButtonAdd from "../components/TableButtonAdd.vue";
import CustomerOverviewCell from "./CustomerOverviewCell.vue";

const {
  customerData,
  priorityData,
  locationData,
  leaderData,
  assignmentBudgetData,
  corporationCustomerData,
  settings,
} = store.state;

const state = reactive({
  showEmptyLeaders: false,
  showLeaderlessCustomers: false,
  showPriolessCustomers: false,
  corporationId: settings.corporationId,
  year: yearFromKey(today()),
  outcomeDate: today(),
  action: Action.None,
  actionableData: undefined as CustomerData | AssignmentBudgetData | undefined,
  actionPreset: undefined as Partial<AssignmentBudget> | undefined,
});

function actionCustomer(rowIfEditing?: number) {
  state.actionableData = customerData;
  state.actionPreset = undefined;
  if (rowIfEditing) {
    store.state.connectionRowId = rowIfEditing;
    state.action = Action.Edit;
    state.actionableData.edit(rowIfEditing);
  } else {
    state.action = Action.Add;
    state.actionableData.add();
  }
}

function actionAssignmentBudget(
  rowIfEditing?: number,
  customerPresetIfNew?: Customer
) {
  state.actionableData = assignmentBudgetData;
  state.actionPreset = customerPresetIfNew
    ? {
        customerId: customerPresetIfNew.customerId,
        customerName: customerPresetIfNew.name,
        year: state.year,
        corporationId: state.corporationId,
        corporationName: store.state.corporationData.find(
          "corporationId",
          state.corporationId
        )?.name,
      }
    : undefined;
  if (rowIfEditing) {
    state.action = Action.Edit;
    state.actionableData.edit(rowIfEditing);
  } else {
    state.action = Action.Add;
    state.actionableData.add();
  }
}

function getDisplayedLeaders() {
  if (state.showEmptyLeaders) {
    return filteredLeaders();
  } else {
    const leaderIdsWithCustomers = filteredCustomers().map((c) => c.leaderId);
    return filteredLeaders().filter((l) =>
      leaderIdsWithCustomers.includes(l.leaderId)
    );
  }
}

// Vue somehow doesn't sort it on its own, so this is needed
function sortedPrios() {
  return priorityData.rows.sort(
    (a, b) => a.getOrderNumber() - b.getOrderNumber()
  );
}

function getTotalsForLeaderString(leaderId: number | null) {
  const corporationId =
    leaderId && state.corporationId < 0
      ? locationData.findById(leaderData.findById(leaderId).locationId)
          .corporationId
      : undefined;
  const totals = getTotalsFor((c) => c.leaderId == leaderId, corporationId);
  return `(${totals.totalOutcome}/${totals.totalBudget})`;
}

function getTotalsForPriorityString(priorityId: number | null) {
  const totals = getTotalsFor((c) => c.priorityId == priorityId);
  return `(${totals.totalOutcome}/${totals.totalBudget})`;
}

function getTotalsString() {
  const totals = getTotalsFor(() => true);
  return `(${totals.totalOutcome}/${totals.totalBudget})`;
}

function getTotalsFor(
  customerFilter: (c: Customer) => boolean,
  corpId = state.corporationId
): {
  totalOutcome: number;
  totalBudget: number;
} {
  const corporationId = corpId > 0 ? corpId : undefined;
  const customers = filteredCustomers().filter(customerFilter);
  const customerIds = new Set(customers.map((c) => c.customerId));

  const filteredAssignmentBudgets = assignmentBudgetData
    .findWithValuesInSet("customerId", customerIds)
    .filter((a) => a.year == state.year);

  let totalOutcome = 0;
  let totalBudget = 0;
  totalBudget = filteredAssignmentBudgets.reduce(
    (sum, ab) => sum + ab.budget,
    0
  );
  for (const c of customers) {
    totalOutcome += AssignmentBudget.calcOutcome(
      c.customerId,
      corporationId,
      state.outcomeDate
    );
  }

  return { totalOutcome, totalBudget };
}

const corporations = computed(() => store.state.corporationData.rows);

const filteredLeaders = () => {
  if (state.corporationId <= 0) return leaderData.rows;

  const filteredLocations = locationData.findMany(
    "corporationId",
    state.corporationId
  );
  const filteredLeaders = leaderData.findWithValuesInSet(
    "locationId",
    new Set(filteredLocations.map((l) => l.locationId))
  );
  return filteredLeaders;
};

const filteredCustomers = () => {
  let output;
  if (state.corporationId <= 0) {
    output = customerData.rows;
  } else {
    const filteredLeadersIds = filteredLeaders().map((l) => l.leaderId);
    const customersByLeaders = customerData.rows.filter(
      (c) => c.leaderId != null && filteredLeadersIds.includes(c.leaderId)
    );
    const filteredCorpConnections = corporationCustomerData.findMany(
      "corporationId",
      state.corporationId
    );
    const customersByCorpConnection = customerData.findWithValuesInSet(
      "customerId",
      new Set(filteredCorpConnections.map((cc) => cc.customerId))
    );

    output = [
      ...new Set([...customersByLeaders, ...customersByCorpConnection]),
    ];
  }
  if (!state.showLeaderlessCustomers)
    output = output.filter((c) => c.leaderId != null);
  if (!state.showPriolessCustomers)
    output = output.filter((c) => c.priorityId != null);
  return output;
};

const isAuthorizedToEdit = container
  .resolve(AuthorizationManager)
  .editCustomerOverview.isAuthorized();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.container {
  @include containertheme($color: $color-black);
  background: none;

  .title-section {
    @include title-section;

    * {
      margin: 3px;
    }
  }

  .options-container {
    @include baseTableOptionsContainer();
    overflow: auto;

    .options {
      @include options($border: 1px, $border-radius: 20px);
      position: relative;
      flex-wrap: wrap;
      overflow: visible;

      td {
        padding: 1em;

        input,
        select {
          font-size: larger;
        }

        input {
          margin: 0.5em;
        }
      }

      .select-container {
        @include select-radio-containers();
      }

      .radio-container {
        @include select-radio-containers();
      }

      .filter-container {
        border-radius: 4px;
        display: flex;
      }

      .filter-box,
      .sort-box {
        @include form-field;
        @include sort-box();
        width: unset;
        padding-right: 10px;

        strong {
          width: 100%;
          text-align: center;
        }
      }
    }

    .options {
      left: 100;
    }

    .options > div {
      margin: 10px;

      .options {
        margin: 10px;
      }
    }

    .button-container {
      padding-left: 2em;
      display: flex;
      flex-direction: column;
    }
  }

  .radio-box {
    margin: 5px;

    input {
      margin-right: 5px;
      padding-right: 5px;
    }
  }

  .tablewrapper {
    @include tablewrapper;

    .table-border {
      background-color: $big-table-border-color;
      color: $base-color;
      min-width: 100px;
      cursor: default;
    }
  }

  table {
    @include table;

    thead {
      @include thead;

      td:first-child {
        width: 0;
      }
    }

    .table-hover:hover {
      background-color: $row-hover-bgc;
    }

    tr {
      @include tr;
      vertical-align: left;

      &:nth-child(even) > td {
        background-color: $table-row-alternate-color;
      }

      &:focus,
      &:focus-visible {
        outline: 2px solid $vea-primary-color;
      }

      td {
        @include td;

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }

    .center {
      @include center;
    }
  }
}
</style>
