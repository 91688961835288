/**
 * Constants corresponding to file folders.
 */
enum FileFolder {
  Assignment = "assignment",
  Profile = "profile",
  ConsultantPhotos = "consultant_photo",
}

export default FileFolder;
