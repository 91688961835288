import { DisplayableField } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { VeaError } from "./error/VeaError";

export class TableField extends DisplayableField<string, string> {
  protected _value: string;

  get value(): string {
    return this._value;
  }
  set value(input: string) {
    this._value = input;
  }

  modelValue(): string {
    throw new Error("Method not implemented.");
  }

  constructor(header: string, data: string) {
    super(header, new HiddenError(), FieldType.NONE, { hideInForm: true });
    this._value = data;
  }
}

export class LabelField extends DisplayableField<string, string> {
  protected _value: string;

  get value(): string {
    return this._value;
  }

  modelValue(): string {
    throw new Error("Method not implemented.");
  }

  refresh() {
    this._value = this.getValue();
  }

  constructor(header: string, private readonly getValue: () => string) {
    super(header, new HiddenError(), FieldType.NONE);
    this._value = getValue();
  }
}

export class WrappedTextTableField extends DisplayableField<string, string> {
  protected _value: string;

  get value(): string {
    const maxRowLength = 40; // results in nice column widths
    if (this._value.length <= 0) {
      return "-";
    } else if (this._value.length <= maxRowLength) {
      return this._value;
    }

    const separator = " · ";
    const wordArray = this._value.split(separator);
    const linebreak = "<br>";

    let wrappedString = "";
    let length = 0;

    for (const name of wordArray) {
      if (length > 0 && length + name.length >= maxRowLength) {
        wrappedString += linebreak;
        length = 0;
      }
      wrappedString += name + separator;
      length += name.length + separator.length;
    }
    // if (wrappedString.length <= 0) wrappedString = "-";
    wrappedString = wrappedString.slice(0, -separator.length);
    return wrappedString;
  }

  set value(input: string) {
    this._value = input;
  }

  modelValue(): string {
    throw new Error("Method not implemented.");
  }

  constructor(header: string, data: string) {
    super(header, new HiddenError(), FieldType.NONE);
    this._value = data;
  }
}

export class TableNumericField extends DisplayableField<number, number> {
  protected _value: number;

  get value(): number {
    return this._value;
  }

  set value(input: number) {
    this._value = input;
  }

  modelValue(): number {
    throw new Error("Method not implemented.");
  }

  constructor(header: string, data: number) {
    super(header, new HiddenError(), FieldType.NONE);
    this._value = data;
  }
}

export class HiddenError<T> extends VeaError<T> {
  constructor() {
    super("");
  }
}
