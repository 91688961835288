import {
  VersionInfoMessage,
  VersionInfoMessageDefault,
} from "@/util/VersionInfo";
import axios, { AxiosError, AxiosResponse } from "axios";
import { container, singleton } from "tsyringe";
import InfoMessageDefault from "./data/constant/InfoMessageDefault";
import { BackendErrorSet } from "./data/error/BackendErrorSet";
import InfoMessage from "./data/types/InfoMessage";

@singleton()
export default class MessageService {
  async retrieveMessage(id: number): Promise<InfoMessage> {
    const message = await axios
      .get<InfoMessage>(`InfoMessage/${id}`)
      .then((resp) => resp.data)
      .catch((error: AxiosError) => {
        container.resolve(BackendErrorSet).addGetError(error);
        return InfoMessageDefault;
      });

    return message;
  }

  async submitEdit(message: InfoMessage): Promise<AxiosResponse<InfoMessage>> {
    const response = await axios
      .put<InfoMessage>(`InfoMessage`, message)
      .catch((error: AxiosError) => {
        container.resolve(BackendErrorSet).addPutError(error);
      });

    return response as AxiosResponse<InfoMessage>;
  }

  async retrieveVersionInfo(): Promise<VersionInfoMessage> {
    const message = await axios
      .get<VersionInfoMessage>(`VersionInfo/1`)
      .then((resp) => resp.data)
      .catch((error: AxiosError) => {
        container.resolve(BackendErrorSet).addGetError(error);
        return VersionInfoMessageDefault;
      });

    return message;
  }
}
