/*
Constants for different navbar dropdowns.
*/
enum NavDropdwon {
  Tables = "tables",
  Stats = "stats",
  All = "all",
}

export default NavDropdwon;
