import { RecruitmentData } from "@/store/data/RecruitmentData";
import { monthKeyFromDate } from "@/types/DateKey";
import { container } from "tsyringe";
import { RecruitmentDisplayable } from "../../RecruitmentDisplayable";
import { DateField } from "../DateField";
import { VeaValidate } from "./VeaValidator";

export function FieldUniqueRecruitment() {
  return VeaValidate({
    message(field: DateField) {
      return field.error.notUniqueRecruitmentError();
    },
    validate(field, object: RecruitmentDisplayable) {
      const recruitmentData = container.resolve(RecruitmentData);
      const current = recruitmentData.activeRow;
      if (current == null) {
        return false;
      }
      const selectedMonth = monthKeyFromDate(field.value);
      const consultantGroupId = object.consultantGroup.selectedOption.id;
      if (consultantGroupId <= 0) {
        return true;
      }
      for (const recruitment of recruitmentData.rows) {
        if (recruitment.recruitmentId == current.recruitmentId) {
          continue;
        }
        if (recruitment.consultantGroupId != consultantGroupId) {
          continue;
        }
        if (selectedMonth == monthKeyFromDate(recruitment.period)) {
          return false;
        }
      }
      return true;
    },
  });
}
