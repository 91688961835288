import MessageService from "@/store/MessageService";
import { container, singleton } from "tsyringe";
import { reactive } from "vue";

export type VersionInfoMessage = {
  buildBackend: string | null;
};
export const VersionInfoMessageDefault: VersionInfoMessage = {
  buildBackend: "[error]",
};

@singleton()
export default class VersionInfo {
  private readonly _appName = `Unicus VEA`;
  private readonly _buildFrontend: string | null =
    process.env.VUE_APP_BUILDNUMBER ?? null;
  private readonly _releaseNumber: string | null =
    process.env.VUE_APP_RELEASENUMBER ?? null;
  private readonly _copyrightNoticeFirstYear = 2021;
  private _fetchedInfo: VersionInfoMessage = reactive({
    buildBackend: "...",
  });

  constructor() {
    this.load();
  }

  private async load(): Promise<void> {
    const message = await container
      .resolve(MessageService)
      .retrieveVersionInfo();
    this._fetchedInfo.buildBackend = message.buildBackend;
  }
  public releaseNumber(): string {
    return this._releaseNumber || "[error]";
  }

  public appName(): string {
    return this._appName;
  }
  public appNameAndRelease(): string {
    return `${this.appName()} version ${this.releaseNumber()}`;
  }

  public buildFrontend(): string | null {
    return this._buildFrontend;
  }
  public buildBackend(): string | null {
    return this._fetchedInfo.buildBackend;
  }
  public buildsText(): string {
    const buildFrontend = this.buildFrontend();
    const buildBackend = this.buildBackend();
    if (!buildFrontend && !buildBackend) {
      return "Build numbers unknown";
    }
    if (buildFrontend && !buildBackend) {
      return `Build ${buildFrontend}`;
    }
    return `Build: ${buildFrontend} / ${buildBackend}`;
  }

  private buildToDate(buildNo: string | null): Date | null {
    if (buildNo) {
      const year = buildNo.slice(0, 4);
      const month = buildNo.slice(4, 6);
      const day = buildNo.slice(6, 8);
      return new Date(`${year}-${month}-${day}`);
    } else {
      return null;
    }
  }
  public buildFrontendDate(): Date | null {
    return this.buildToDate(this.buildFrontend());
  }
  public buildBackendDate(): Date | null {
    return this.buildToDate(this.buildBackend());
  }

  /** The year in which Unicus launched the VEA project,
   * and thus the first authorship date of copyrighted code and content.
   * (Hardcoded) */
  public copyrightNoticeFirstYear(): number {
    return this._copyrightNoticeFirstYear;
  }
  /** The most recent year during which builds were made,
   * and thus the last authorship date of copyrighted code and content. */
  public copyrightNoticeLastYear(): number | null {
    let lastYearWithBuilds: number | null = null;
    const frontendYear = this.buildFrontendDate()?.getFullYear();
    const backendYear = this.buildBackendDate()?.getFullYear();
    if (frontendYear && backendYear)
      lastYearWithBuilds =
        frontendYear > backendYear ? frontendYear : backendYear;
    else if (frontendYear) lastYearWithBuilds = frontendYear;
    else if (backendYear) lastYearWithBuilds = backendYear;
    return lastYearWithBuilds;
  }
  public copyrightNotice(): string {
    return `Unicus Sverige AB © ${this.copyrightNoticeFirstYear()}–${
      this.copyrightNoticeLastYear() ?? " "
    }. All rights reserved.`;
  }

  public fullText(separator = "\n"): string {
    return (
      `${this.appNameAndRelease()}${separator}` +
      `${this.buildsText()}${separator}` +
      `${this.copyrightNotice()}`
    );
  }
}
