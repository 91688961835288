import { DisplayableField } from "./DisplayableField";

export abstract class NullableField<T> extends DisplayableField<
  T | null,
  T | null
> {
  get value(): T | null {
    return this._value;
  }

  set value(input: T | null) {
    this._value = input;
  }
}
