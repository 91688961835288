import { Currency } from "@/models/Currency";
import { CurrencyDisplayable } from "@/models/displayable/CurrencyDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class CurrencyOptions extends TableDataOptions<
  Currency,
  CurrencyDisplayable
> {
  public constructor() {
    super({
      filterBoxKeys: ["code", "symbol"],
    });
  }
}
