<template>
  <section id="profile" class="profile">
    <div>
      <fa-icon v-if="detailIcon" id="icon-basedetails" :icon="detailIcon" />
      <slot></slot>
    </div>
    <h3 v-if="title != undefined">{{ title }}</h3>
    <section class="details">
      <li
        class="list-group item"
        v-for="(field, key) in displayable"
        :key="key"
      >
        <div
          class="detail-container"
          v-if="field.header !== '' && !excludedFields?.includes(key as KeyTo<D, IDisplayableField>)"
        >
          <span class="detail-header">
            <strong>{{ field.header }}: </strong>
            <!-- If header and value is to long(50 chars) start a new row after header to avoid very short rows-->
            <br
              v-if="
                typeof field.value === 'string' &&
                field.header.length + field.value.length > 50
              "
            />
          </span>
          <span v-if="field.prefix">
            {{ field.prefix }}
          </span>
          <span class="detail-content" v-html="field.value"></span>
          <span v-if="field.suffix">
            {{ field.suffix }}
          </span>
        </div>
      </li>
      <li
        class="list-group item"
        v-for="(field, key) in secondaryDisplayable"
        :key="key"
      >
        <div
          class="detail-container"
          v-if="includedSecondaryFields?.includes(key as KeyTo<D2, IDisplayableField>)"
        >
          <span class="detail-header">
            <strong>{{ field.header }}: </strong>
            <br
              v-if="
                typeof field.value === 'string' &&
                field.header.length + field.value.length > 50
              "
            />
          </span>

          <span class="detail-content" v-html="field.value"></span>
        </div>
      </li>
      <div class="buttons">
        <button
          v-if="canEdit"
          @click="handleEdit()"
          class="btn btn-sm btn-success"
        >
          {{ Action.Edit + " " + tableData.titleSingular }}
        </button>
        <button
          v-if="canEditSecondaryData"
          @click="handleEditSecondary()"
          class="btn btn-sm btn-success"
        >
          {{ Action.Edit + " " + secondaryData?.titleSingular }}
        </button>
        <button
          v-if="extendable && hasExtraPermissions"
          @click="handleExtend()"
          class="btn btn-sm btn-success"
        >
          {{ Action.Extend + " " + tableData.titleSingular }}
        </button>
        <button
          v-if="
            userId &&
            consultant &&
            (hasExtraPermissions || isModifyingOwnProfile)
          "
          @click="store.state.competenciesViewUserId = userId ?? null"
          class="btn btn-sm btn-success"
        >
          {{ Action.Edit + " Competencies" }}
        </button>
        <button
          v-if="
            hasExtraPermissions &&
            (tableData instanceof AbsenceData ||
              tableData instanceof AssignmentData)
          "
          @click="accept ? accept() : () => {}"
          :cy-data="`btn-submit-${title}`"
          class="btn btn-sm btn-danger"
        >
          {{ Action.Delete + " " + tableData.titleSingular }}
        </button>
      </div>
    </section>
  </section>

  <BaseModalForm
    :data="tableData"
    :action="action.label"
    :submitEvent="() => emit(action.event)"
    v-if="tableData.isModifying()"
  />
  <BaseModalForm
    :data="secondaryData"
    :action="action.label"
    :submitEvent="() => emit(action.event)"
    v-if="secondaryData && secondaryData.isModifying()"
  />
  <BaseModalCompetencies
    v-if="store.state.competenciesViewUserId"
    :user-id="store.state.competenciesViewUserId"
    @closed="() => emit('edit')"
  />
</template>

<script
  setup
  lang="ts"
  generic="M extends Model<D>, D extends Displayable<M> & IterableFields<D, IDisplayableField>, M2 extends Model<D2>, D2 extends Displayable<M2> & IterableFields<D2, IDisplayableField>"
>
import user from "@/auth/user";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { IDisplayableField } from "@/models/displayable/fields/DisplayableField";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { AbsenceData } from "@/store/data/AbsenceData";
import { AssignmentData } from "@/store/data/AssignmentData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { TableData } from "@/store/data/TableData";
import { UserData } from "@/store/data/UserData";
import { Action } from "@/store/data/enum/Action";
import { IterableFields } from "@/types/IterableFields";
import { KeyTo } from "@/types/KeyTo";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { container } from "tsyringe";
import { computed, reactive } from "vue";
import BaseModalCompetencies from "./BaseModalCompetencies.vue";
import BaseModalForm from "./BaseModalForm.vue";

const props = defineProps<{
  title?: string;
  tableData: TableData<M, D>;
  secondaryData?: TableData<M2, D2>;
  id: number;
  secondaryId?: number;
  excludedFields?: KeyTo<D, IDisplayableField>[];
  includedSecondaryFields?: KeyTo<D2, IDisplayableField>[];
  extendable?: boolean;
  userId?: number;
  detailIcon?: IconName;
  canEdit?: boolean;
  accept?: () => void;
}>();

const emit = defineEmits([
  "delete",
  "edit",
  "edit-secondary",
  "extend",
  "update",
]);

const action: {
  label: Action.Add | Action.Edit | Action.Extend;
  event: "delete" | "edit" | "edit-secondary" | "extend" | "update";
} = reactive({ label: Action.Edit, event: "update" });

const consultant = computed(() =>
  store.state.consultantData.find("userId", props.userId)
);

const isModifyingOwnProfile = computed(
  () =>
    (props.tableData instanceof UserData ||
      props.tableData instanceof ConsultantData) &&
    user.state.profile.userId == props.userId
);

const displayable = computed(() =>
  props.tableData.findById(props.id).getDisplayable()
);

const secondaryDisplayable = computed(() => {
  if (!props.secondaryId || !props.secondaryData) {
    return undefined;
  }
  return props.secondaryData.findById(props.secondaryId)?.getDisplayable();
});

const hasExtraPermissions = computed(() =>
  container
    .resolve(AuthorizationManager)
    .baseDetailsExtraPermissions.isAuthorized()
);

const canEditSecondaryData = computed(
  () =>
    props.canEdit &&
    (hasExtraPermissions.value || isModifyingOwnProfile.value) &&
    props.secondaryData &&
    props.secondaryId
);

function handleEdit() {
  action.label = Action.Edit;
  action.event = "edit";
  store.state.connectionRowId = props.id;
  props.tableData.edit(props.id);
}

function handleEditSecondary() {
  if (props.secondaryData && props.secondaryId) {
    action.label = Action.Edit;
    action.event = "edit-secondary";
    props.secondaryData.edit(props.secondaryId);
  }
}

function handleExtend() {
  if (props.tableData instanceof AssignmentData) {
    action.label = Action.Extend;
    action.event = "extend";
    props.tableData.extend(props.id);
  }
}
</script>

<style lang="scss">
@import "../styles/global.scss";

.profile {
  @include profile($width: 30%, $min-width: 360px, $margin-bottom: 40px);
  max-width: 30vw;
  border: 1px solid #80808033;
  border-radius: 5px;
  background: $container-bgc; // ursprungligen grå komponent som renderas i olika vyer (Profile, Assignment Overview)
  padding: 0 10px;
}

#icon-basedetails {
  width: 5rem;
  height: 5rem;
  color: $color-white;
  padding: 10px;
  background-color: $color-darkgrey;
  border-radius: 50%;
  border: 1px solid $color-darkgrey;
  margin-top: -40px;
}

section.details {
  margin: auto;
  margin-bottom: 30px;
  background: $color-white; // $color-white-transparent;
  padding: 15px;
  // border: 1px solid $color-lightgrey;
  border-radius: 3px;

  .detail-container {
    margin-bottom: 8px;
    text-align: left;
    overflow-wrap: break-word;
  }

  .buttons {
    @include button(
      $display: flex,
      $justify-content: center,
      $background: unset
    );

    .btn {
      flex-grow: 1;
    }
  }
}

.container > .container {
  margin-top: 0;
}

.profile-upload {
  margin-bottom: 30px;

  label {
    @include label($margin-left: initial);
  }

  button {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
  }
}

#changePass {
  background-color: $primary-ui-color;
}
</style>
