import { Absence } from "@/models/Absence";
import { AbsenceCategory } from "@/models/AbsenceCategory";
import { Consultant } from "@/models/Consultant";
import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Corporation } from "@/models/Corporation";
import { AbsenceCategoryDisplayable } from "@/models/displayable/AbsenceCategoryDisplayable";
import { AbsenceDisplayable } from "@/models/displayable/AbsenceDisplayable";
import { ConsultantDisplayable } from "@/models/displayable/ConsultantDisplayable";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { PeriodFilter } from "../tableDataOptionsFilters/PeriodFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class AbsenceOptions extends TableDataOptions<
  Absence,
  AbsenceDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;
  private consultantDropdown: TableDataDropdown<
    Consultant,
    ConsultantDisplayable
  >;
  private consultantGroupDropdown: TableDataDropdown<
    ConsultantGroup,
    ConsultantGroupDisplayable
  >;
  private absenceCategoryDropdown: TableDataDropdown<
    AbsenceCategory,
    AbsenceCategoryDisplayable
  >;

  override filterRows(
    rows: Absence[],
    radioFilters: BaseTableFilterMode[]
  ): Absence[] {
    const corporationFilter = this.corporationDropdown.selectedOption;
    const consultantFilter = this.consultantDropdown.selectedOption;
    const consultantGroupFilter = this.consultantGroupDropdown.selectedOption;
    const absenceCategoryFilter = this.absenceCategoryDropdown.selectedOption;
    const [periodFilter] = radioFilters;

    const absenceFilters = TableDataOptions.getPeriodFilters(
      periodFilter,
      corporationFilter,
      consultantFilter,
      consultantGroupFilter
    );
    const filters = new FilterBuilder<Absence>(absenceFilters);

    if (absenceCategoryFilter != -1) {
      filters.add((a) => a.absenceCategoryId == absenceCategoryFilter);
    }

    return filters.filter(rows);
  }

  public constructor() {
    super({
      radioFilters: [new PeriodFilter()],
      sorting: {
        Consultant: {
          func: TableDataOptions.compareFirstNames,
          column: "consultant",
        },
        Category: {
          key: "absenceCategoryName",
          column: "absenceCategory",
        },
        Start: {
          func: TableDataOptions.compareStart,
          column: "startDate",
        },
        End: {
          func: TableDataOptions.compareEnd,
          column: "endDate",
        },
      },
      filterBoxKeys: ["consultant", "absenceCategory", "startDate", "endDate"],
    });

    const {
      corporationData,
      consultantData,
      consultantGroupData,
      absenceCategoryData,
    } = store.state;

    this.corporationDropdown = new TableDataDropdown(corporationData);

    this.consultantGroupDropdown = new TableDataDropdown(
      consultantGroupData,
      undefined,
      this.getDropdownFilterFromRelatedDropdown(
        consultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );
    this.consultantDropdown = new TableDataDropdown(
      consultantData,
      undefined,
      this.getDropdownFilterFromRelatedDropdowns(
        consultantData,
        this.consultantGroupDropdown,
        "consultantGroupId",
        consultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );

    this.absenceCategoryDropdown = new TableDataDropdown(absenceCategoryData);

    this.dropdownFilters = [
      this.corporationDropdown,
      this.consultantGroupDropdown,
      this.consultantDropdown,
      this.absenceCategoryDropdown,
    ];
  }
}
