import { Locales } from "@/coveragePeriodReport/constant/Locales";
import { Displayable } from "@/interfaces/Displayable";
import { ITableData } from "@/interfaces/ITableData";
import { Model } from "@/interfaces/Model";
import { TableHeaders } from "@/interfaces/TableHeaders";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import { FieldProvider } from "@/models/fieldProvider/FieldProvider";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import axios, { AxiosError } from "axios";
import { container } from "tsyringe";
import { TableDataParams } from "./TableDataParams";
import { DeletePolicy, ITableDataRelation } from "./TableDataRelation";
import { BackendErrorSet } from "./error/BackendErrorSet";

type ModifyAction<T> = (body: T) => Promise<void>;

export enum CanDelete {
  No,
  WithWarning,
  WithoutWarning,
}

/**
 * Represents a generic table data object.
 * Each derived class defines its own table title, headers, controller string and row data.
 */
export abstract class TableData<M extends Model<D>, D extends Displayable<M>>
  implements ITableData
{
  private tableTitle: string;
  private controllerName: string;
  private tableTitleSingular: string;
  private _deleting: boolean;
  private _hasFinishedDeleting: boolean;
  private _validationErrorsByKey: Map<keyof D, string>;
  private _fetchedInstance: M;
  private lastGet: number;
  private _modifyingFailed: boolean;
  private _failedModifyingData: D | null;
  private _getPromise: Promise<void> | null = null;
  protected tableRows: M[];
  protected _modifying: boolean;
  protected _activeRow: M | null;
  protected action: ModifyAction<M> | null;
  protected sourceInstance: M;
  protected _deleteWarning: string;
  protected _deleteError: string;

  finished?: Promise<true>;

  /**
   * @param tableTitle The title of the table in the view.
   * @param headers The column header names for the table.
   * @param controllerName The name of the API-controller with which data is exchanged.
   */
  constructor(
    tableTitle: string,
    public readonly headers: TableHeaders<D>,
    controllerName: string,
    emptyInstance: M,
    tableTitleSingular?: string,
    readonly dependencyOf: ITableDataRelation[] = [],
    options?: TableDataParams<M, D>
  ) {
    this.tableRows = [];
    this.tableTitle = tableTitle;
    this.headers = headers;
    this.controllerName = controllerName;
    this.tableTitleSingular = tableTitleSingular ?? controllerName;
    this._modifying = false;
    this._deleting = false;
    this._hasFinishedDeleting = true;
    this._activeRow = null;
    this._validationErrorsByKey = new Map<keyof D, string>();
    this.action = null;
    this.sourceInstance = emptyInstance;
    this._fetchedInstance = emptyInstance;
    this.lastGet = 0;
    this._modifyingFailed = false;
    this._failedModifyingData = null;
    this._deleteWarning = "";
    this._deleteError = "";
    this.fieldProvider = options?.fieldProvider;
    this.hasFiles = options?.hasFiles;
  }

  readonly fieldProvider?: FieldProvider<M, D>;
  readonly hasFiles?: boolean;

  get title(): string {
    return this.tableTitle;
  }

  get titleSingular(): string {
    return this.tableTitleSingular;
  }

  get rows(): M[] {
    return this.tableRows;
  }

  get hasData(): boolean {
    return this.tableRows.length > 0;
  }

  get activeRow(): M | null {
    return this._activeRow;
  }

  get fetchedInstance(): M {
    return this._fetchedInstance;
  }

  get deleting(): boolean {
    return this._deleting;
  }

  get modifying(): boolean {
    return this._modifying;
  }

  get deleteWarning(): string {
    return this._deleteWarning;
  }

  get deleteError(): string {
    return this._deleteError;
  }

  private get locked(): boolean {
    return Date.now() - this.lastGet < 1000 * 60 * 5;
  }

  public deletionWarningMessage(): string {
    return `This ${this.titleSingular} may not be deleted! `;
  }

  /**
   * Guardrail for determining whether the key-value pairs are defined as a tuple array.
   */
  private isArrayOfTuples<K, V>(
    arg: [KeyTo<M, K>, V] | [KeyTo<M, K>, V][]
  ): arg is [KeyTo<M, K>, V][] {
    if (arg.length == 0) {
      return true;
    }
    return Array.isArray(arg[0]);
  }

  /**
   * Ensures that the tuple/tuple array is returned as a tuple array.
   */
  private toValueTupleArray<K>(
    arg: [KeyTo<M, K>, K] | [KeyTo<M, K>, K][]
  ): [KeyTo<M, K>, K][] {
    return this.isArrayOfTuples(arg) ? arg : [arg];
  }

  /**
   * @param arg one or several key-value pairs
   * @returns a filter for rows based on the key-value pairs provided
   */
  private getFilter<K>(
    arg: [KeyTo<M, K>, K] | [KeyTo<M, K>, K][]
  ): (row: M) => boolean {
    const keyValuePairs = this.toValueTupleArray(arg);
    return (row: M) =>
      keyValuePairs.every((pair) => getProp(row, pair[0]) == pair[1]);
  }

  /**
   *
   * @param keyValuePairs one or several key-value pairs pointing to properties of the Model class
   * @returns true if any row matches the key-value pair(s) provided
   */
  some<K>(...keyValuePairs: [KeyTo<M, K>, K] | [KeyTo<M, K>, K][]): boolean {
    return this.rows.some(this.getFilter(keyValuePairs));
  }

  /**
   *
   * @param keyValuePairs one or several key-value pairs pointing to properties of the Model class
   * @returns the first row matches the key-value pairs
   *
   * @example
   * ```ts
   * const data = container.resolve(UserData);
   * let erik: User;
   *
   * erik = data.find("firstname", "Erik");
   * erik = data.find(["firstname", "Erik"], ["lastname", "Sandh"])
   *
   * erik = data.rows.find(user => user.firstname == "Erik");
   * erik = data.rows.find(user => user.firstname == "Erik" && user.lastname = "Sandh");
   * ```
   */
  find<K>(
    ...keyValuePairs: [KeyTo<M, K>, K] | [KeyTo<M, K>, K][]
  ): M | undefined {
    return this.rows.find(this.getFilter(keyValuePairs));
  }

  /**
   *
   * @param keyValuePairs one or several key-value pairs pointing to properties of the Model class
   * @returns all rows matching the key-value pair(s) provided
   */
  findMany<K>(...keyValuePairs: [KeyTo<M, K>, K] | [KeyTo<M, K>, K][]): M[] {
    return this.rows.filter(this.getFilter(keyValuePairs));
  }

  /**
   *
   * @param keyValuePairs one or several key-value pairs pointing to properties of the Model class
   * @returns the id of the first row matching the key-value pair(s) provided
   */
  findIds<K>(
    ...keyValuePairs: [KeyTo<M, K>, K] | [KeyTo<M, K>, K][]
  ): Set<number> {
    const ids: number[] = this.rows
      .filter(this.getFilter(keyValuePairs))
      .map((r) => r.getId());
    return new Set(ids);
  }

  /**
   *
   * @param keyValuePairs one or several key-set pairs pointing to properties of the Model class
   * @returns the first row with the all of the specified properties matching any of the corresponding values provided
   */
  findWithValuesInSet<K>(
    ...keyValuePairs: [KeyTo<M, K>, Set<K>] | [KeyTo<M, K>, Set<K>][]
  ): M[] {
    if (!this.isArrayOfTuples(keyValuePairs)) {
      const [key, values] = keyValuePairs;
      return this.rows.filter((r) => values.has(getProp(r, key)));
    }
    return this.rows.filter((r) =>
      keyValuePairs.every(([key, values]) => values.has(getProp(r, key)))
    );
  }

  /**
   *
   * @param keyValuePairs one or several key-set pairs pointing to properties of the Model class
   * @returns the id of the first row with all of the specified property matching any of the corresponding values provided
   */
  findWithValuesInSetIds<K>(
    ...keyValuePairs: [KeyTo<M, K>, Set<K>] | [KeyTo<M, K>, Set<K>][]
  ): Set<number> {
    return new Set(
      this.findWithValuesInSet(...keyValuePairs).map((t) => t.getId())
    );
  }

  /**
   *
   * @param keyValuePairs one or several key-set pairs pointing to properties of the Model class
   * @returns the first row with the all of the specified properties matching any of the corresponding values provided
   */
  someWithValuesInSet<K>(
    ...keyValuePairs: [KeyTo<M, K>, Set<K>] | [KeyTo<M, K>, Set<K>][]
  ): boolean {
    if (!this.isArrayOfTuples(keyValuePairs)) {
      const [key, values] = keyValuePairs;
      return this.rows.some((r) => values.has(getProp(r, key)));
    }
    return this.rows.some((r) =>
      keyValuePairs.every(([key, values]) => values.has(getProp(r, key)))
    );
  }

  rowsToDropdownOptions(): DropdownOption<string>[] {
    return this.tableRows
      .map((row) => new DropdownOption(row.getId(), row.getName()))
      .sort((a, b) => a.label.localeCompare(b.label, Locales));
  }

  hasValidationErrors(): boolean {
    return this._validationErrorsByKey.size > 0;
  }

  hasFieldValidationErrors(fieldKey: keyof D): boolean {
    return this._validationErrorsByKey.has(fieldKey);
  }

  getFieldValidationErrors(fieldKey: keyof D): string | undefined {
    return this._validationErrorsByKey.get(fieldKey);
  }

  setTableRows(entries: M[]) {
    this.tableRows = this.realizeEntries(entries) as M[];
  }

  isModifying(): boolean {
    return this.modifying;
  }

  hasModifyingFailed(): boolean {
    return this._modifyingFailed;
  }

  getFailedModifyingData(): D | null {
    return this._failedModifyingData;
  }

  edit(clickedRowId: number): void {
    this._modifying = true;
    this._activeRow =
      this.tableRows.find((row) => row.getId() == clickedRowId) ?? null;
    this.action = this.putRequest;
  }

  add(): void {
    this._modifying = true;
    this._activeRow = this.sourceInstance.realize(this.sourceInstance) as M;
    this.action = this.postRequest;
  }

  async saveChanges(displayable: D): Promise<void> {
    const isValid = await this.validateForm(displayable);

    this.finished = new Promise((resolve, reject) => {
      const rowModel = this.activeRow;

      if (rowModel != null && this.action != null && isValid) {
        displayable.toModel(rowModel);
        this.action(rowModel)
          .then(() => resolve(true))
          .catch((err) => reject(err.message));

        this.reset();
      } else {
        this._modifyingFailed = true;

        this._failedModifyingData = displayable;

        resolve(true);
      }
    });
  }

  async validateForm(displayable: D): Promise<boolean> {
    const errors = await displayable.validateFields();

    this._validationErrorsByKey.clear();
    errors.forEach((e) =>
      this._validationErrorsByKey.set(e.fieldKey as keyof D, e.description)
    );

    return errors.length === 0;
  }

  isDeleting(): boolean {
    return this.deleting;
  }

  hasFinishedDeleting(): boolean {
    return this._hasFinishedDeleting;
  }

  delete(clickedRowId: number): void {
    this._deleting = true;
    this._hasFinishedDeleting = false;
    this._activeRow =
      this.tableRows.find((row) => row.getId() == clickedRowId) ?? null;
  }

  async doDelete(): Promise<void> {
    const rowModel = this.activeRow;
    if (rowModel != null) {
      await this.deleteRequest(rowModel.getId()).then(() => this.reset());
    }
  }

  finishDeletion() {
    this._hasFinishedDeleting = true;
  }

  abort(): void {
    this.reset();
  }

  private reset(): void {
    this._activeRow = null;
    this._modifying = false;
    this._modifyingFailed = false;
    this._failedModifyingData = null;
    this._deleting = false;
    this._validationErrorsByKey.clear();
    this.action = null;
  }

  private realizeEntries(entries: M[]): Model<D>[] {
    return Array.from(entries, (entry) => this.sourceInstance.realize(entry));
  }

  private lockTable() {
    this.lastGet = Date.now();
  }

  private unlockTable() {
    this.lastGet = 0;
  }

  /**TableDatas can extend this to change the behavior when deleting.
  Returns 1, 0 or -1 for if a row can be deleted without warning, with warning or not at all.*/
  public canDelete(id: number): CanDelete {
    let result = CanDelete.WithoutWarning;
    for (const tablePointer of this.dependencyOf) {
      if (!tablePointer.isDependency(id)) {
        continue;
      }
      if (tablePointer.deletePolicy == DeletePolicy.NeverDelete) {
        return CanDelete.No;
      } else if (tablePointer.deletePolicy == DeletePolicy.WithWarning) {
        result = CanDelete.WithWarning;
      }
    }
    return result;
  }

  public findById(id: number): M {
    return (
      this.rows.find((instance) => instance.getId() === id) ||
      this.sourceInstance
    );
  }

  public async getRequest(): Promise<void> {
    if (this.locked || this.controllerName === "") {
      if (this._getPromise) {
        await this._getPromise;
        this._getPromise = null;
      }
      return;
    }

    this.lockTable();
    this._getPromise = axios
      .get<M[]>(this.controllerName)
      .then((response) => {
        this.setTableRows(response.data);
      })
      .catch((error: AxiosError) => {
        this.unlockTable();
        console.warn(this.controllerName);
        console.warn(this);
        container.resolve(BackendErrorSet).addGetError(error);
        console.warn(error);
      });
    return await this._getPromise.then(() => {
      this._getPromise = null;
    });
  }

  public async getSingleRequest(id: number): Promise<M | null> {
    return await axios
      .get<M>(`${this.controllerName}/${id}`)
      .then((response) => {
        const realInstance = this.sourceInstance.realize(response.data) as M;
        this._fetchedInstance = realInstance;
        return realInstance;
      })
      .catch((error: AxiosError) => {
        this.unlockTable();
        container.resolve(BackendErrorSet).addGetError(error);
        return null;
      });
  }

  public async putRequest(body: Model<D>): Promise<void> {
    if (this._getPromise) {
      await this._getPromise;
      this._getPromise = null;
    }

    this._getPromise = axios
      .put<M>(this.controllerName, body)
      .then(() => this.unlockTable())
      .then(() => this.getRequest())
      .catch((error: AxiosError) => {
        this.unlockTable();
        container.resolve(BackendErrorSet).addPutError(error);
      });
    await this._getPromise;
    this._getPromise = null;
  }

  public async postRequest(body: Model<D>): Promise<void> {
    await axios
      .post<M>(this.controllerName, body)
      .then(
        (response) =>
          (this._fetchedInstance = this.sourceInstance.realize(
            response.data
          ) as M)
      )
      .then(() => this.unlockTable())
      .then(() => this.tableRows.push(this._fetchedInstance))
      .then(() => this.getRequest())
      .catch((error: AxiosError) => {
        this.unlockTable();
        container.resolve(BackendErrorSet).addPostError(error);
      });
  }

  public async deleteRequest(id: number): Promise<void> {
    await axios
      .delete(this.controllerName + "/" + id)
      .then(() => this.unlockTable())
      .then(() => this.getRequest())
      .catch((error: AxiosError) => {
        this.unlockTable();
        container.resolve(BackendErrorSet).addDeleteError(error);
      });
  }
}
