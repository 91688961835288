import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { TableData } from "@/store/data/TableData";
import { IsEmail, IsNotEmpty, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { EmailError } from "./error/EmailError";

export class EmailField<
  M extends Model<D>,
  D extends Displayable<M>
> extends DisplayableField<string, string> {
  @IsNotEmpty({
    message: (args: ValidationArguments) =>
      (args.object as EmailField<M, D>)._error.emptyError(),
  })
  @IsEmail(
    {},
    {
      message: (args: ValidationArguments) =>
        (args.object as EmailField<M, D>)._error.valueError(args.value),
    }
  )
  protected _value: string;
  protected _tableData: TableData<M, D>;
  protected _mandatory = true;

  get value(): string {
    return this._value;
  }

  set value(input: string) {
    this._value = input;
  }

  get tableData(): TableData<M, D> {
    return this._tableData;
  }

  modelValue(): string {
    return this._value;
  }

  get mandatory(): boolean {
    return this._mandatory;
  }

  constructor(
    header: string,
    data: string,
    tableData: TableData<M, D>,
    options?: DisplayableFieldOptions
  ) {
    super(header, new EmailError(header), FieldType.INPUT, options);
    this._tableData = tableData;
    this._value = data;
  }
}
