import { ConsultantCompetencyDisplayable } from "./displayable/ConsultantCompetencyDisplayable";
import { ModelBase } from "./ModelBase";

export class ConsultantCompetency extends ModelBase<ConsultantCompetencyDisplayable> {
  consultantCompetencyId: number;
  isKey: boolean;
  competencyId: number;
  consultantId: number;
  competencyName: string;
  firstName: string;
  lastName: string;

  constructor() {
    super();
    this.consultantCompetencyId = 0;
    this.isKey = false;
    this.competencyId = 0;
    this.consultantId = 0;
    this.competencyName = "";
    this.firstName = "";
    this.lastName = "";
  }

  realize(partialModel: ConsultantCompetency): ConsultantCompetency {
    const newModel = new ConsultantCompetency();
    newModel.consultantCompetencyId = partialModel.consultantCompetencyId;
    newModel.isKey = partialModel.isKey;
    newModel.competencyId = partialModel.competencyId;
    newModel.consultantId = partialModel.consultantId;
    newModel.competencyName = partialModel.competencyName;
    newModel.firstName = partialModel.firstName;
    newModel.lastName = partialModel.lastName;
    return newModel;
  }

  getName(): string {
    return this.competencyName;
  }

  getId(): number {
    return this.consultantCompetencyId;
  }

  getConsultantName(): string {
    return this.firstName + " " + this.lastName;
  }

  getDisplayable(): ConsultantCompetencyDisplayable {
    return new ConsultantCompetencyDisplayable(this);
  }
}
