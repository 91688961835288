import { Competency } from "@/models/Competency";
import { CompetencyDisplayable } from "@/models/displayable/CompetencyDisplayable";
import { container, singleton } from "tsyringe";
import { ConsultantCompetencyData } from "./ConsultantCompetencyData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { CompetencyHeaders } from "./headers/CompetencyHeaders";

@singleton()
export class CompetencyData extends TableData<
  Competency,
  CompetencyDisplayable
> {
  constructor() {
    super(
      "Competencies",
      new CompetencyHeaders(),
      "Competency",
      new Competency(),
      undefined,
      [
        new TablePointer(
          container.resolve(ConsultantCompetencyData),
          "competencyId",
          DeletePolicy.WithWarning
        ),
      ]
    );
    this._deleteWarning =
      "Warning! This competency still has data connected to it!" +
      "Are you sure you want to delete this competency? " +
      "This will remove it from every consultant that has it.";
  }
}
