import { IsOptional, IsString, ValidationArguments } from "class-validator";
import { DisplayableFieldOptions } from "./DisplayableField";
import { NullableStringField } from "./NullableStringField";
import { FieldType } from "./enum/FieldType";
import { TextError } from "./error/TextError";

export class NullableTextField extends NullableStringField {
  @IsOptional()
  @IsString({
    message: (args: ValidationArguments) =>
      (args.object as NullableTextField)._error.valueError(args.value),
  })
  protected _value: string | null;

  constructor(
    header: string,
    data: string | null,
    options?: DisplayableFieldOptions
  ) {
    super(header, new TextError(header), FieldType.INPUT, options);
    this._value = data;
  }
}
