<template>
  <form class="upload-form">
    <div class="input-group">
      <input
        type="file"
        ref="fileInput"
        :accept="acceptedFileTypes.join()"
        @input="onInput"
        class="form-control"
        :id="inputId"
        :aria-describedby="uploadBtnId"
        aria-label="Input"
        :multiple
      />
    </div>
    <div class="form-text text-center">
      Accepted File Types:
      {{
        acceptedFileTypes.map((s) => s.substring(1).toUpperCase()).join(", ")
      }}
    </div>
    <div class="form-text text-center">
      Max File Size: {{ maxFileSizeMb }}MB
    </div>
  </form>
</template>

<script setup lang="ts">
import { FileExtension } from "@/types/FileExtension";
import { ref } from "vue";

const props = defineProps<{
  inputId: string;
  uploadBtnId?: string;
  maxFileSizeMb: number;
  acceptedFileTypes: FileExtension[];
  multiple?: boolean;
  handleUpload: (file: FileList) => Promise<void>;
}>();

const fileInput = ref<HTMLInputElement | null>(null);

async function onInput() {
  if (!fileInput.value) {
    return;
  }
  if (fileInput.value.files) {
    await props.handleUpload(fileInput.value.files);
  }
  fileInput.value.value = "";
}
</script>
