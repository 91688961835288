import NisService from "@/models/NisService";
import { NisServiceDisplayable } from "@/models/displayable/NisServiceDisplayable";
import { container, singleton } from "tsyringe";
import { NisAssignmentData } from "./NisAssignmentData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { NisServiceHeaders } from "./headers/NisServiceHeaders";

@singleton()
export class NisServiceData extends TableData<
  NisService,
  NisServiceDisplayable
> {
  constructor() {
    super(
      "NIS Services",
      new NisServiceHeaders(),
      "NisService",
      new NisService(),
      "NIS Service",
      [
        new TablePointer(
          container.resolve(NisAssignmentData),
          "nisServiceId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This NIS Service may not be deleted! " +
      "It still has assignment(s) under it! " +
      "Reassign or remove each of its remaining assignments";
  }
}
