import { TableHeaders } from "@/interfaces/TableHeaders";
import { AssignmentBudgetDisplayable } from "@/models/displayable/AssignmentBudgetDisplayable";

export class AssignmentBudgetHeaders
  implements TableHeaders<AssignmentBudgetDisplayable>
{
  public readonly customer = "Customer";
  public readonly corporation = "Company";
  public readonly year = "Year";
  public readonly budget = "Budget";
  public readonly outcome = "Outcome";
}
