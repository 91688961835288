import FileFolder from "@/components/enum/FileFolder";
import { downloadBlob } from "@/util/DownloadHelper";
import axios, { AxiosError, HttpStatusCode } from "axios";
import { injectable } from "tsyringe";
import MAX_VALID_FILE_SIZE from "./constant/ValidFileSize";
import VALID_FILE_TYPES from "./constant/ValidFileTypes";
import handleAxiosError from "./error/handleAxiosError";
import FileDetails from "./types/FileDetails";

type FileDataOptions = {
  validFileTypes?: string[];
  maxFileSize?: number;
  prependFolderNameToSubFolders?: boolean;
};

@injectable()
export default class FileData {
  static filesToUpload?: FileList;

  private readonly validFileTypes: Set<string>;
  private readonly maxFileSize: number;
  private readonly prependFolderNameToSubFolders?: boolean;

  constructor(private controllerName = "File", options?: FileDataOptions) {
    this.validFileTypes = new Set(options?.validFileTypes ?? VALID_FILE_TYPES);
    this.maxFileSize = options?.maxFileSize ?? MAX_VALID_FILE_SIZE;
    this.prependFolderNameToSubFolders =
      options?.prependFolderNameToSubFolders ?? true;
  }

  /**
   * Clears files available for upload.
   */
  static clear(): void {
    FileData.filesToUpload = undefined;
  }

  /**
   * Posts files that are available for upload.
   * @param folder Name of the main folder.
   * @param id ID of the sub-folder.
   */
  public async postFiles(folder: string, id: number): Promise<void> {
    if (FileData.filesToUpload) {
      const files = Array.from(FileData.filesToUpload);

      await Promise.all(
        files.map((file) => {
          this.uploadFile(file, folder, id);
        })
      ).finally(() => {
        FileData.clear();
      });
    }
  }

  /**
   * Fetches a list of file details for an entity.
   * @param folder Main folder to fetch from.
   * @param id ID of the associated entity.
   * @returns The list of file details.
   */
  public async getList(
    folder: string,
    id: number
  ): Promise<void | FileDetails[]> {
    return axios
      .get<FileDetails[]>(
        `${this.controllerName}/${this.localPath(folder, id)}`
      )
      .then((resp) => {
        return Array.from(resp.data);
      })
      .catch((error: AxiosError) => handleAxiosError(error));
  }

  /**
   * Fetches a list of file details.
   * @param folder Folder to fetch from.
   * @returns The list of file details.
   */
  public async getFullList(folder: string): Promise<void | FileDetails[]> {
    return axios
      .get<FileDetails[]>(`${this.controllerName}/${folder}`)
      .then((resp) => Array.from(resp.data))
      .catch((error: AxiosError) => handleAxiosError(error));
  }

  public async getFullListById(
    folder: string
  ): Promise<void | Map<number, FileDetails[]>> {
    const files = (await this.getFullList(folder)) || [];
    const map = new Map<number, FileDetails[]>();

    if (files.length == 0) {
      return map;
    }

    const folderPrefix = this.getFolderPrefix(folder);

    for (const file of files) {
      const id = FileData.getIdFromLocalPath(file.localPath, folderPrefix);
      let groupedFiles = map.get(id);
      if (groupedFiles === undefined) {
        groupedFiles = [];
        map.set(id, groupedFiles);
      }
      groupedFiles.push(file);
    }

    return map;
  }

  /**
   * Fetches the number of files in a specific folder,
   * grouped by the "id" as determined by their subfolder:
   * `{folder}/{folder}-{id}/`
   * @param folder Folder to fetch from.
   * @returns A Map where the key is the id of a subfolder
   * and the value is the number of files it contains.
   */
  public async getFullFileCount(
    folder: FileFolder
  ): Promise<undefined | Map<number, number>> {
    const response = (await this.getFullList(folder)) || [];

    if (response.length == 0) {
      return undefined;
    }

    const folderPrefix = this.getFolderPrefix(folder);
    const fileCounts = new Map<number, number>();

    for (const file of response) {
      const id = FileData.getIdFromLocalPath(file.localPath, folderPrefix);

      if (Number.isNaN(id)) {
        continue;
      }

      const count = fileCounts.get(id);

      if (count === undefined) {
        fileCounts.set(id, 1);
      } else {
        fileCounts.set(id, count + 1);
      }
    }

    return fileCounts;
  }

  private static getIdFromLocalPath(
    localPath: string,
    folderPrefix: string
  ): number {
    if (!localPath.startsWith(folderPrefix)) {
      return Number.NaN;
    }

    return Number(
      localPath.substring(
        folderPrefix.length,
        localPath.indexOf("/", folderPrefix.length)
      )
    );
  }

  /**
   * Gets the number of files available for an entity.
   * @param folder Main folder to fetch from.
   * @param id ID of the associated entity.
   * @returns The number of files available.
   */
  async getFileCount(folder: string, id: number): Promise<number> {
    const list = await this.getList(folder, id);
    return list ? list.length : 0;
  }

  /**
   * Fetches a specific blob-file and allows the user to download it directly.
   * @param fileDetails Details associated with the file.
   */
  public async downloadFile(fileDetails: FileDetails): Promise<void> {
    await axios
      .get(`${this.controllerName}/${fileDetails.localPath}`, {
        responseType: "blob",
      })
      .then((resp) => {
        const blob = new Blob([resp.data], { type: fileDetails.contentType });
        downloadBlob(blob, fileDetails.fileName);
      })
      .catch((error: AxiosError) => handleAxiosError(error));
  }

  public async getFile(
    fileName: string,
    folder: string,
    id: number,
    contentType: string
  ): Promise<File | null> {
    let file: File | null = null;
    const localPath = this.localPath(folder, id);
    await axios
      .get(`${this.controllerName}/${localPath}/${fileName}`, {
        responseType: "blob",
      })
      .then((resp) => {
        if (resp.status == HttpStatusCode.Ok) {
          file = new File([resp.data], fileName, {
            type: contentType,
          });
        }
      })
      .catch((error: AxiosError) => handleAxiosError(error));
    return file;
  }

  /*   *
   * Uploads a file for a specific consultant.
   * @param file File to upload.
   * @param id Consultant ID.
   */
  public async uploadFile(
    file: File,
    folder: string,
    id: number
  ): Promise<void> {
    if (!this.validFileTypes.has(file.type)) {
      throw new Error(`Invalid File-Type`);
    } else if (file.size > this.maxFileSize) {
      throw new Error(`Invalid File-Size`);
    }

    const formData = new FormData();
    formData.append("bytes", file);

    await axios
      .post(`${this.controllerName}/${this.localPath(folder, id)}/`, formData, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .catch((error: AxiosError) => {
        handleAxiosError(error);
        throw error;
      });
  }

  /**
   * Deletes a file for a specific consultant.
   * @param fileName Name of the file.
   * @param id Consultant ID.
   */
  async deleteFile(
    fileName: string,
    folder: string,
    id: number
  ): Promise<void> {
    await axios
      .delete(
        `${this.controllerName}/${this.localPath(folder, id)}/${fileName}`
      )
      .then(() => {
        console.log("Deleted file: " + fileName);
      })
      .catch((error: AxiosError) => handleAxiosError(error));
  }

  async deleteSubFolder(folder: string, id: number): Promise<void> {
    await axios
      .delete(`${this.controllerName}/${this.localPath(folder, id)}`)
      .catch((error: AxiosError) => handleAxiosError(error));
  }

  private localPath = (folder: string, id: number | string) => {
    return this.prependFolderNameToSubFolders
      ? `${folder}/${folder}-${id}`
      : `${folder}/${id}`;
  };

  private getFolderPrefix(folder: string) {
    return this.prependFolderNameToSubFolders
      ? `${folder}/${folder}-`
      : `${folder}/`;
  }
}
