import { UnwrapRef } from "vue";
import { KeyTo } from "./KeyTo";
import { AllPropsAs, PropsAs, PropsOf } from "./PropsOf";

/**
 * @returns the property value of a object based on a key, properly infered as the type the key points to
 * @param obj an object of type T, containing one or more properties of type P
 * @param key a key pointing to a property of type P in an object of type T
 */
export function getProp<T extends PropsOf<T, P>, P>(
  obj: T | PropsOf<T, P> | UnwrapRef<T>,
  key: KeyTo<T, P> | UnwrapRef<KeyTo<T, P>> | (KeyTo<T, P> & string)
): P {
  return (obj as T)[key as KeyTo<T, P>] as P;
}

export function castKey<T, P1, P2>(key: KeyTo<T, P1>) {
  return key as unknown as KeyTo<T, P2>;
}

export function keysEqual<T, P1, P2 extends P1>(
  key1: KeyTo<T, P1>,
  key2?: KeyTo<T, P2>
): boolean {
  return key2 !== undefined && key1 == (key2 as unknown as KeyTo<T, P1>);
}

/**
 * @param obj an object of type T, containing one or more properties of type P
 * @param key a key pointing to a property of type P in an object of type T
 * @param val the value to set the property to
 */
export function setProp<T extends PropsOf<T, P>, P>(
  obj: T,
  key: KeyTo<T, P>,
  val: P
) {
  obj[key] = val as T[KeyTo<T, P>];
}

export function getPropAs<T extends PropsOf<T, P>, P, N>(
  obj: PropsAs<T, P, N> | AllPropsAs<T, P, N>,
  key: KeyTo<T, P>
): N | undefined {
  return obj[key];
}

export function getPropAsAll<T extends PropsOf<T, P>, P, N>(
  obj: AllPropsAs<T, P, N>,
  key: KeyTo<T, P>
): N {
  return obj[key];
}

export function getEntries<T extends PropsOf<T, P>, P>(
  obj: PropsOf<T, P>
): KeyValuePair<T, P>[] {
  return Object.entries(obj).map((arr) => {
    return { key: arr[0], value: arr[1] };
  }) as KeyValuePair<T, P>[];
}

export function getEntriesAs<T extends PropsOf<T, P>, P, N>(
  obj: AllPropsAs<T, P, N> | PropsAs<T, P, N>
): KeyValuePairAs<T, P, N>[] {
  return Object.entries(obj).map((arr) => {
    return { key: arr[0], value: arr[1] };
  }) as KeyValuePairAs<T, P, N>[];
}

export function getAllEntries<T extends object>(obj: T) {
  return Object.entries(obj)
    .map((arr) => {
      return {
        key: arr[0] as keyof T,
        value: arr[1] as Exclude<T[keyof T], undefined>,
      };
    })
    .filter((kv) => kv.value != undefined);
}

export function getAllKeys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

export function getAllProps<T extends PropsOf<T, P>, P>(
  obj: T | Partial<T> | PropsAs<T, unknown, P> | { [key: string]: P }
): P[] {
  const result = new Array<P>();
  for (const key of getAllKeys(obj)) {
    const val = obj[key];
    if (val != undefined) {
      result.push(val);
    }
  }
  return result;
}

export function getKeys<T extends PropsOf<T, P>, P>(obj: T): KeyTo<T, P>[] {
  return Object.keys(obj) as KeyTo<T, P>[];
}

export function getKeysAs<T extends PropsOf<T, P>, P, N>(
  obj: AllPropsAs<T, P, N>
): KeyTo<T, P>[] {
  return Object.keys(obj) as KeyTo<T, P>[];
}

export function getValues<T extends PropsOf<T, P>, P>(obj: PropsOf<T, P>): P[] {
  return Object.values(obj);
}

export function getValuesAs<T extends PropsOf<T, P>, P, N>(
  obj: AllPropsAs<T, P, N>
): N[] {
  return Object.values(obj);
}

export function isKeyOf<T extends object>(
  obj: T,
  key: string | number | symbol
): key is keyof T {
  return key in obj;
}

export type KeyValuePair<T, P> = { key: KeyTo<T, P>; value: P };
export type KeyValuePairAs<T, P, N> = { key: KeyTo<T, P>; value: N };
