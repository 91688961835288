/**
 * Defines how yearly bars will be calculated in Statistics Reports.
 */
export enum YearlyViewBehavior {
  /** The Statistics Report will not display a yearly view. */
  None,
  /** Takes the values from the last month of the year. */
  LastMonth,
  /** Sum the values of all months of the year. */
  AccumulateMonths,
}
