import { IDisplayable } from "@/interfaces/Displayable";
import { Leader } from "@/models/Leader";
import { KeyTo } from "@/types/KeyTo";
import { getProp } from "@/types/getProp";
import { LeaderDisplayable } from "../../LeaderDisplayable";
import { DropdownField } from "../DropdownField";
import { VeaValidate } from "./VeaValidator";

type LeaderDropdown = DropdownField<Leader, LeaderDisplayable>;

export function FieldDifferentLeaders<D extends IDisplayable>(
  relatedFieldKey: KeyTo<D, LeaderDropdown>
) {
  return VeaValidate({
    validate(field: LeaderDropdown, object: D) {
      const secondLeader = getProp(object, relatedFieldKey).selectedOption.id;
      const firstLeader = field.selectedOption.id;
      return firstLeader == 0 || firstLeader != secondLeader;
    },
    message() {
      return "Extra leader cannot be the same as leader";
    },
  });
}
