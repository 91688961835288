export enum BaseTableFilterMode {
  ALL = "All",
  CURRENT = "Current & New", // Also includes upcoming
  FINISHED = "Finished",
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  HAS_FILES = "With Files",
  NO_FILES = "Without Files",
  ASSIGNMENT = "Assignment",
  PROBABLE_ASSIGNMENT = "Probable Assignment",
  INTERNSHIP = "Internship",
  ARCHIVED = "Archived",
  CURRENT_ASSIGNMENTS = "With current assignments",
  PREVIOUS_ASSIGNMENTS = "With previous assignments",
  NON_BILLABLE = "Non billable/Internal work",
}
