import { AssignmentBudgetData } from "@/store/data/AssignmentBudgetData";
import { DropdownFieldAny } from "@/types/DropdownFieldAny";
import { container } from "tsyringe";
import { AssignmentBudgetDisplayable } from "../../AssignmentBudgetDisplayable";
import { NumberField } from "../NumberField";
import { VeaValidate } from "./VeaValidator";

export function FieldUniqueAssignmentBudget() {
  return VeaValidate({
    message(field: NumberField | DropdownFieldAny) {
      return field.error.notUniqueAssignmentBudgetError();
    },
    validate(_field, object: AssignmentBudgetDisplayable) {
      for (const budget of container.resolve(AssignmentBudgetData).rows) {
        if (budget.assignmentBudgetId === object.assignmentBudgetId) {
          continue;
        }
        if (budget.customerId != object.customer.selectedOption.id) {
          continue;
        }
        if (budget.corporationId != object.corporation.selectedOption.id) {
          continue;
        }
        if (budget.year == object.year.value) {
          return false;
        }
      }
      return true;
    },
  });
}
