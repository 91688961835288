<template>
  <div>
    <div v-if="!user.isLoggedIn" class="d-grid gap-2 col-6 mx-auto"></div>

    <div v-else class="text-center position-relative">
      <TheNavbar />
      <TheSettings v-if="authorizationManager.settings.isAuthorized()" />
    </div>

    <router-view />

    <BaseModalErrorBackend
      :errorSet="backendErrors"
      :onClick="closeModal"
      :onMount="registerCheck"
      v-show="hasErrors"
    />
  </div>
  <ScrollToTopButton />
</template>

<script setup lang="ts">
import { InactivityChecker } from "@/auth/InactivityChecker";
import userStore from "@/auth/user";
import BaseModalErrorBackend from "@/components/BaseModalErrorBackend.vue";
import ScrollToTopButton from "@/components/ScrollToTopButton.vue";
import TheNavbar from "@/components/TheNavbar.vue";
import TheSettings from "@/components/TheSettings.vue";
import axios, { AxiosError } from "axios";
import { container } from "tsyringe";
import { computed, onBeforeMount } from "vue";
import store from "./store";
import { AuthorizationManager } from "./store/AuthorizationManager";
import { BackendErrorSet } from "./store/data/error/BackendErrorSet";
import { EventType } from "./util/EventEmitter";

let hasErrors = false;
function registerCheck() {
  backendErrors.value.register(checkErrors);
}
function checkErrors() {
  hasErrors = backendErrors.value.hasErrors;
}
function closeModal() {
  backendErrors.value.clearErrors();
  hasErrors = backendErrors.value.hasErrors;
}
const backendErrorSet = container.resolve(BackendErrorSet);

export type RefreshTokenDetails = {
  refreshToken: string;
  oldToken: string;
};

let refreshPromise: Promise<string | null> | null = null;
const resetPromise = () => (refreshPromise = null);

async function getNewToken(
  details: RefreshTokenDetails
): Promise<string | null> {
  const response = await axios.post("Token/Refresh", details);
  return response?.status == 200 ? response.data : null;
}

axios.interceptors.response.use(undefined, async (error) => {
  const config = error.config;
  if (!error.response.status) {
    return AxiosError;
  }
  if (error.response.status !== 401 || config.retry) {
    return;
  }
  config._retry = true;
  const jwtToken = localStorage.getItem("jwt");
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken || !jwtToken) {
    userStore.logout();
    return;
  }
  const details: RefreshTokenDetails = {
    refreshToken: refreshToken,
    oldToken: jwtToken,
  };
  if (!refreshPromise) {
    refreshPromise = getNewToken(details).finally(resetPromise);
  }
  const newToken = await refreshPromise;
  if (!newToken) {
    userStore.logout();
    return;
  }
  config.headers.Authorization = `Bearer ${newToken}`;
  axios.defaults.headers.Authorization = `Bearer ${newToken}`;
  localStorage.setItem("jwt", newToken);
  return axios(config);
});

onBeforeMount(async () => {
  store.state.events.subscribe(EventType.LoggedIn, async () => {
    await store.state.sendAllGetRequests();
    store.state.events.dispatch(EventType.FinishedLoadingTableData);
    store.state.events.unsubscribe(EventType.FinishedLoadingTableData);
  });

  await userStore.persistLogin().then(async () => {
    await userStore.persistSettings();
    if (user.value.isLoggedIn) {
      store.state.events.dispatch(EventType.LoggedIn);
    }
  });
});

container.resolve(InactivityChecker); // to run in the background

const user = computed(() => userStore.getters);
const backendErrors = computed(() => backendErrorSet);
const authorizationManager = container.resolve(AuthorizationManager);
</script>

<style lang="scss">
@import "@/styles/global.scss";

¨ :root {
  --vea-blue: #54a0ff;
  --vea-orange: #fba626;
  --vea-white: whitesmoke;
  // variables such as these can be used to implement the entire color scheme
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // --focus-blue: rgba(106, 106, 252, 0.5);
  // --active-blue: rgb(11, 11, 241);
  // --danger-red: #a22b2b;
}

body {
  /* when offcanvas opens, bootstrap adds
   * padding to the right on small screens
   * for some reason */
  color: $info-text-color; // $base-text-color;
  background-color: $base-color;

  @media (max-width: 400px) {
    padding-right: 0px !important;
  }
}

.container {
  max-width: Min(95vw, 100%);
}

.form-errors {
  margin: 3px;
  padding-bottom: 15px;
}

.error-message {
  @include error-message;
}

.text-loading {
  margin-top: 10vh;
  font-weight: bold;
  animation: pulse 400ms ease 0ms 10 alternate;
}

@keyframes pulse {
  to {
    transform: scale(1.1);
  }
}
</style>
