import { ConsultantData } from "@/store/data/ConsultantData";
import { CorporationData } from "@/store/data/CorporationData";
import { LeaderData } from "@/store/data/LeaderData";
import { LocationData } from "@/store/data/LocationData";
import { UserData } from "@/store/data/UserData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Leader } from "../Leader";
import { Location } from "../Location";
import { User } from "../User";
import { DisplayableBase } from "./DisplayableBase";
import { LocationDisplayable } from "./LocationDisplayable";
import { UserDisplayable } from "./UserDisplayable";
import { DateField } from "./fields/DateField";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "./fields/DropdownField";
import { NullableDateField } from "./fields/NullableDateField";
import { TableField } from "./fields/TableField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldMinDateOrNull } from "./fields/validators/FieldMinDateOrNull";

export class LeaderDisplayable extends DisplayableBase<Leader> {
  @ValidateNested()
  name: DropdownField<User, UserDisplayable>;
  @ValidateNested()
  location: DropdownField<Location, LocationDisplayable>;
  corporation: TableField;
  @ValidateNested()
  employmentStart: DateField;
  @ValidateNested()
  @FieldMinDateOrNull("employmentStart")
  employmentEnd: NullableDateField;

  constructor(leader: Leader) {
    super();
    const leaderData = container.resolve(LeaderData);
    const userData = container.resolve(UserData);
    const locationData = container.resolve(LocationData);
    const consultantData = container.resolve(ConsultantData);
    const corporationData = container.resolve(CorporationData);
    const { headers } = leaderData;
    const name = `${leader.firstname} ${leader.lastname}`;

    // Used to filter out users that already has a leader, unless we're editing a leader, in which case the corresponding user is shown.
    // Also filters out consultants.
    const userFilter = (currentOption: DropdownOption<string>) => {
      return (
        !consultantData.some("userId", currentOption.id) &&
        !leaderData.rows.some(
          (currentLeader) =>
            currentLeader.userId === currentOption.id &&
            currentLeader.userId !== leader.userId
        )
      );
    };

    this.name = new DropdownField(
      "User",
      name,
      userData,
      new DropdownOption(leader.userId, name),
      {
        optionsFilter: userFilter,
      }
    );
    this.corporation = new TableField(
      headers.corporation,
      leader.corporationName
    );
    this.location = new DropdownField(
      headers.location,
      leader.locationName,
      locationData,
      new DropdownOption(leader.locationId, leader.locationName),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          corporationData,
          "corporationId",
          "corporationName"
        ),
      }
    );
    this.employmentStart = new DateField(
      headers.employmentStart,
      leader.startDate
    );
    this.employmentEnd = new NullableDateField(
      headers.employmentEnd,
      leader.endDate
    );
  }

  toModel(leader: Leader): void {
    leader.userId = this.name.selectedOption.id;
    leader.locationId = this.location.selectedOption.id;
    leader.startDate = this.employmentStart.modelValue();
    leader.endDate = this.employmentEnd.modelValue();
  }
}
