import { CorporationCustomerData } from "@/store/data/CorporationCustomerData";
import { CorporationData } from "@/store/data/CorporationData";
import { CustomerData } from "@/store/data/CustomerData";
import { IndustryData } from "@/store/data/IndustryData";
import { LeaderData } from "@/store/data/LeaderData";
import { LocationData } from "@/store/data/LocationData";
import { PriorityData } from "@/store/data/PriorityData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Corporation } from "../Corporation";
import { CorporationCustomer } from "../CorporationCustomer";
import { Customer } from "../Customer";
import { Industry } from "../Industry";
import { Leader } from "../Leader";
import { Location } from "../Location";
import { Priority } from "../Priority";
import { CorporationCustomerDisplayable } from "./CorporationCustomerDisplayable";
import { CorporationDisplayable } from "./CorporationDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { IndustryDisplayable } from "./IndustryDisplayable";
import { LeaderDisplayable } from "./LeaderDisplayable";
import { LocationDisplayable } from "./LocationDisplayable";
import { PriorityDisplayable } from "./PriorityDisplayable";
import { ConditionalField } from "./fields/ConditionalField";
import { DropdownField } from "./fields/DropdownField";
import { ManyToManyField } from "./fields/ManyToManyField";
import { NullableTextField } from "./fields/NullableTextField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class CustomerDisplayable extends DisplayableBase<Customer> {
  @ValidateNested()
  @FieldUniqueText<Customer, CustomerDisplayable>()
  name: UniqueTextField<Customer, CustomerDisplayable>;
  @ValidateNested()
  leaderName: DropdownField<Leader, LeaderDisplayable>;
  @ValidateNested()
  priorityName: DropdownField<Priority, PriorityDisplayable>;
  @ValidateNested()
  locationName: DropdownField<Location, LocationDisplayable>;
  @ValidateNested()
  industryName: DropdownField<Industry, IndustryDisplayable>;
  @ValidateNested()
  corporationCustomerName: ManyToManyField<
    Customer,
    CorporationCustomer,
    Corporation,
    CustomerDisplayable,
    CorporationCustomerDisplayable,
    CorporationDisplayable
  >;
  potential: NullableTextField;
  @ValidateNested()
  frameworkAgreement: ConditionalField;

  constructor(customer: Customer) {
    super();
    const customerData = container.resolve(CustomerData);
    const leaderData = container.resolve(LeaderData);
    const priorityData = container.resolve(PriorityData);
    const locationData = container.resolve(LocationData);
    const industryData = container.resolve(IndustryData);
    const corporationCustomerData = container.resolve(CorporationCustomerData);
    const corporationData = container.resolve(CorporationData);
    const { headers } = customerData;

    this.name = new UniqueTextField<Customer, CustomerDisplayable>(
      headers.name,
      customer.name,
      customerData
    );

    this.leaderName = new DropdownField<Leader, LeaderDisplayable>(
      headers.leaderName,
      customer.getLeaderName(),
      leaderData,
      customer.leaderId
        ? new DropdownOption(customer.leaderId, customer.getLeaderName())
        : undefined,
      {
        nullable: true,
      }
    );

    this.priorityName = new DropdownField<Priority, PriorityDisplayable>(
      headers.priorityName,
      customer.priorityName,
      priorityData,
      customer.priorityId
        ? new DropdownOption(customer.priorityId, customer.priorityName)
        : undefined,
      {
        nullable: true,
      }
    );

    this.locationName = new DropdownField<Location, LocationDisplayable>(
      headers.locationName,
      customer.locationName,
      locationData,
      new DropdownOption(customer.locationId, customer.locationName)
    );

    this.industryName = new DropdownField<Industry, IndustryDisplayable>(
      headers.industryName,
      customer.industryName,
      industryData,
      new DropdownOption(customer.industryId, customer.industryName)
    );

    this.corporationCustomerName = new ManyToManyField(
      headers.corporationCustomerName,
      customer.customerId,
      corporationCustomerData,
      corporationData,
      "customerId",
      "corporationCustomerId",
      "customerId",
      "customerName",
      "corporationId",
      "corporationName",
      false
    );

    this.potential = new NullableTextField(
      headers.potential,
      customer.potential
    );

    this.frameworkAgreement = new ConditionalField(
      headers.frameworkAgreement,
      customer.frameworkAgreement
    );
  }

  toModel(customer: Customer): void {
    customer.name = this.name.modelValue();
    customer.leaderId =
      this.leaderName?.selectedOption.id == undefined ||
      this.leaderName.selectedOption.id == 0
        ? null
        : this.leaderName.selectedOption.id;
    customer.priorityId =
      this.priorityName?.selectedOption.id == undefined ||
      this.priorityName.selectedOption.id == 0
        ? null
        : this.priorityName.selectedOption.id;
    customer.locationId = this.locationName.selectedOption.id;
    customer.industryId = this.industryName.selectedOption.id;
    customer.potential = this.potential.modelValue();
    customer.frameworkAgreement = this.frameworkAgreement.modelValue();
  }
}
