import { NisConsultantGroupDisplayable } from "./displayable/NisConsultantGroupDisplayable";
import { ModelBase } from "./ModelBase";

export class NisConsultantGroup extends ModelBase<NisConsultantGroupDisplayable> {
  nisConsultantGroupId: number;
  name: string;
  corporationId: number;
  leaderId: number;

  corporationName: string;
  leaderName: string;
  locationName: string;

  constructor() {
    super();

    this.nisConsultantGroupId = 0;
    this.name = "";
    this.corporationId = 0;
    this.leaderId = 0;

    this.corporationName = "";
    this.leaderName = "";
    this.locationName = "";
  }

  realize(partialModel: NisConsultantGroup): NisConsultantGroup {
    const newModel = new NisConsultantGroup();

    newModel.nisConsultantGroupId = partialModel.nisConsultantGroupId;
    newModel.name = partialModel.name;
    newModel.corporationId = partialModel.corporationId;
    newModel.leaderId = partialModel.leaderId;

    newModel.corporationName = partialModel.corporationName;
    newModel.leaderName = partialModel.leaderName;
    newModel.locationName = partialModel.locationName;

    return newModel;
  }

  getId(): number {
    return this.nisConsultantGroupId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): NisConsultantGroupDisplayable {
    return new NisConsultantGroupDisplayable(this);
  }
}
