import { NullableField } from "./NullableField";

export abstract class NullableStringField extends NullableField<string> {
  modelValue(): string | null {
    if (!this._value) return null;
    const value = this._value.trim();
    if (value == "") return null;
    else return value;
  }

  get value(): string | null {
    return this._value;
  }

  set value(input: string | null) {
    if (input === "") this._value = null;
    else this._value = input;
  }
}
