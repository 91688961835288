import { Assignment } from "@/models/Assignment";
import { ContractPhase } from "@/models/enum/ContractPhase";
import { CssColor } from "@/types/CssColor";
import { CoveragePeriodReportEntry } from "./CoveragePeriodReportEntry";
import { DiagramColors } from "./enum/DiagramColors";

export class OverlapPeriodReportEntry extends CoveragePeriodReportEntry {
  private _members: CoveragePeriodReportEntry[];

  constructor(
    coveragePeriod: Assignment,
    reportIndex: number,
    members: CoveragePeriodReportEntry[]
  ) {
    super(coveragePeriod, reportIndex);
    this._members = members;
    this.internalId = Number(`${members.map((m) => m.internalId).join("00")}`);
  }

  public get members(): CoveragePeriodReportEntry[] {
    return this._members;
  }

  private hasProbableAssignment(): boolean {
    return this._members.some(
      (member) => member.phase === ContractPhase.PROBABLE_ASSIGNMENT
    );
  }

  getColor(): CssColor {
    if (this.coverage > 100) {
      return DiagramColors.WARNING;
    }
    if (this.phase === ContractPhase.INTERNSHIP) {
      return DiagramColors.INTERNSHIP;
    }
    if (this.phase === ContractPhase.NON_BILLABLE) {
      return DiagramColors.NON_BILLABLE;
    }
    const probable = this.hasProbableAssignment();
    const parttime = this.coverage < 100;
    if (parttime) {
      if (probable) {
        return DiagramColors.PROBABLE_PARTTIME_ASSIGNMENT;
      } else {
        return DiagramColors.PARTTIME_ASSIGNMENT;
      }
    } else if (probable) {
      return DiagramColors.PROBABLE_ASSIGNMENT;
    } else {
      return DiagramColors.SIGNED_ASSIGNMENT;
    }
  }
}
