<template>
  <p v-if="state.isLoading" class="text-loading">Calculating...</p>
  <StatisticsDiagram
    v-else
    :can-show-all-corporations="false"
    :options="state.options"
    :yearly-view="YearlyViewBehavior.None"
    :table-settings="{
      accumulatedTotal: false,
      showMonthlyTotal: false,
      appendUnit: false,
      fileName: 'Hourly_Rates',
      columns: {
        'Hourly Rates': 'rate',
      },
    }"
    :value-fields="['rate']"
    :colors="{
      rate: {
        color: DiagramColors.SIGNED_ASSIGNMENT,
        description: 'HOURLY RATES',
        textBright: true,
      },
    }"
    v-model:can-update="state.canUpdate"
    :calculate-month="calculate"
    :suffix-from-options="getSuffix"
    :prefix-from-options="getPrefix"
    :show-unit-in-bar-tooltip="true"
    :hide-consultant-group-filter="true"
    title="Hourly Rate Report"
    clarification="Each bar represents the average hourly rate of assignments that are active during that period."
  >
    <UnselectableLabel for="include-probable">
      <input
        type="checkbox"
        id="include-probable"
        v-model="state.options.includeProbableAssignments"
        @change="state.canUpdate = true"
      />
      Including Probable Assignments
    </UnselectableLabel>
  </StatisticsDiagram>
</template>

<script setup lang="ts">
import UnselectableLabel from "@/components/UnselectableLabel.vue";
import { DiagramColors } from "@/coveragePeriodReport/enum/DiagramColors";
import StatisticsDiagram from "@/diagram/StatisticsDiagram.vue";
import { calculateHourlyRate } from "@/diagram/functions/calculateHourlyRate";
import { YearlyViewBehavior } from "@/diagram/types/YearlyViewBehavior";
import store from "@/store";
import { MonthKey } from "@/types/DateKey";
import { EventType } from "@/util/EventEmitter";
import { reactive } from "vue";

type Result = {
  month: Date;
  rate: number;
  corporationId: number;
};

const state = reactive({
  isLoading: true,
  canUpdate: false,
  options: {
    includeProbableAssignments: false,
  },
});

const { currencyData, events } = store.state;

events.subscribeOrRunIfDispatched(
  EventType.FinishedLoadingTableData,
  () => (state.isLoading = false)
);

function getPrefix(corporationId: number): string {
  const currency = currencyData.getCompanyCurrencyOrDefault(corporationId);
  return currency.isPrefix ? currency.currencySymbol : "";
}

function getSuffix(corporationId: number): string {
  const currency = currencyData.getCompanyCurrencyOrDefault(corporationId);
  return currency.isPrefix ? "" : ` ${currency.currencySymbol}`;
}

function calculate(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number,
  options?: { includeProbableAssignments: boolean }
): Result {
  const rate =
    corporationId > 0
      ? calculateHourlyRate(
          month,
          corporationId,
          consultantGroupId,
          options,
          true
        )
      : 0;
  return {
    month: new Date(month),
    corporationId,
    rate,
  };
}
</script>
