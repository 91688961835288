import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { TextError } from "./error/TextError";

export class UrlField extends DisplayableField<string, string> {
  protected _value: string;
  protected _url: string;

  get value(): string {
    return this._value;
  }

  set value(input: string) {
    this._value = input;
  }

  get url(): string {
    return this._url;
  }

  modelValue(): string {
    return this._value;
  }

  constructor(
    header: string,
    data: string,
    url: string,
    options?: DisplayableFieldOptions
  ) {
    super(header, new TextError(header), FieldType.URL, options);
    this._value = data;
    this._url = url;
  }
}
