import { IDisplayable } from "@/interfaces/Displayable";
import { today } from "@/types/DateKey";
import { DateField } from "../DateField";
import { VeaValidate } from "./VeaValidator";

export function FieldDateNotAfterToday<D extends IDisplayable>() {
  return VeaValidate<D, DateField>({
    message(field) {
      return field.error.afterTodayError();
    },
    validate(field) {
      return field.value <= today();
    },
  });
}
