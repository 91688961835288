<template>
  <div class="flex-container-column">
    <div class="container">
      <div class="title-section">
        <h1>Monthly Reports</h1>
        <p>
          Company reports for each month<sup>
            <a href="#clarification">[?]</a>
          </sup>
        </p>
      </div>
      <div class="options-container">
        <div class="options">
          <div class="select-container">
            <div class="filter-box">
              <label for="corpSelector">
                <strong>Company</strong>
              </label>
              <select
                id="corpSelector"
                v-model="state.corporationId"
                @change="fetchTableData"
                :disabled="hasTableChanged()"
              >
                <option :key="-1" :value="-1">&lt;All&gt;</option>
                <option
                  v-for="c of corporationData.rows"
                  :key="c.corporationId"
                  :value="c.corporationId"
                >
                  {{ c.name }}
                </option>
              </select>
            </div>
            <div class="filter-box">
              <label for="year-selector"><strong>Year</strong></label>
              <input
                id="year-selector"
                type="number"
                min="2020"
                max="2099"
                v-model="state.fieldYear"
                @change="fetchTableData"
                :disabled="hasTableChanged()"
              />
            </div>
            <div class="filter-box">
              <label for="show-total"><strong>Show Totals</strong></label>
              <input
                id="show-total"
                type="checkbox"
                v-model="state.showTotal"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDataEmpty()" class="tablewrapper">
        <div class="submit-container">
          <button
            v-if="canEdit"
            class="btn-primary submit-btn"
            @click.prevent="submitForm()"
            :disabled="
              submitStatus.isPending ||
              !hasTableChanged() ||
              hasInvalidTableData()
            "
          >
            {{ submitStatus.isPending ? "Saving..." : "Save" }}
          </button>
          <template v-if="canEdit">
            <button
              id="auto-fill-view-button"
              class="btn-primary submit-btn"
              @click="state.autoFillView = true"
            >
              Auto-Fill...
            </button>
          </template>
          <p v-if="showAllCompanyReport()">
            Below is a total of all company reports for the selected year.<br />
            If you wish to edit a report, please select your company first.
          </p>
          <strong
            class="submit-msg"
            :class="{
              unsaved: submitStatus.msg == Message.Unsaved,
              success: submitStatus.isSuccess,
            }"
          >
            {{ submitStatus.msg }}
          </strong>
        </div>
        <form>
          <!-- <div class="scrollable"> -->
          <table>
            <thead>
              <tr>
                <th>Statistic</th>
                <th
                  v-for="month in monthsAndNames"
                  :key="month.month"
                  class="month-header"
                >
                  {{ month.name.slice(0, 3) }}
                </th>
                <th v-if="state.showTotal">Total</th>
              </tr>
            </thead>

            <TransitionGroup name="fadeInOut">
              <template
                v-for="({ header, categoryHeader }, key) in reportDescriptors"
                :key="key"
              >
                <template
                  v-if="
                    state.corporationId != -1 ||
                    !reportDescriptors[key].skipAllReportTotal
                  "
                >
                  <tr class="category" v-if="categoryHeader">
                    <td style="text-align: left">{{ categoryHeader }}</td>
                  </tr>
                  <tr class="input-container">
                    <td
                      style="text-align: left"
                      v-on:mouseenter="showDescription(key)"
                      v-on:mouseleave="hideDescription()"
                      :id="getMonthlyReportHeaderId(header)"
                    >
                      {{
                        header +
                        (reportDescriptors[key].isPercent ? " (%)" : "")
                      }}
                    </td>
                    <template
                      v-for="(report, index) in state.reports"
                      :key="`${report}-report`"
                    >
                      <template v-if="isKeyOf(report, key)">
                        <td
                          :class="{
                            invalid: hasInvalidFieldData(
                              `${key}|${report.month}`
                            ),
                            fading: state.anim,
                            'readonly-field': !canEdit,
                          }"
                        >
                          <p v-if="!canEdit">
                            {{ formattedCellValue(report, key) }}
                          </p>
                          <input
                            v-if="canEdit"
                            v-model="report[key]"
                            class="no-input-arrows"
                            :class="{
                              hasFieldChanged: hasFieldChanged(
                                `${key}|${report.month}`
                              ),
                            }"
                            type="number"
                            lang="sv"
                            :step="0.01"
                            :title="errorTooltip(`${key}|${report.month}`)"
                            :id="getInputId(key, report.month)"
                            @focusout="
                              fieldEdited(`${key}|${report.month}`, report)
                            "
                            @keydown="
                              (event) =>
                                handleCellInput(event, key, report.month)
                            "
                          />
                        </td>
                      </template>
                      <template v-if="isKeyOf(extraReportFuncs, key)">
                        <td class="readonly-field">
                          <p v-if="!showAllCompanyReport()">
                            {{ formattedCellValue(report, key) }}
                          </p>
                          <p v-else>
                            {{ formattedCellValueTotal(key, index + 1) }}
                          </p>
                        </td>
                      </template>
                    </template>
                    <td class="total-cell" v-if="state.showTotal">
                      {{ formattedCellValueTotal(key) }}
                    </td>
                  </tr>
                </template>
              </template>
            </TransitionGroup>
          </table>
          <!-- </div> -->
        </form>
      </div>
      <div class="no-corp-selected">
        <p v-if="showAllCompanyReport() && isDataEmpty()">
          No data has been reported for the selected year.
        </p>
        <div
          v-if="
            !showAllCompanyReport() && isDataEmpty() && !state.isReloadingData
          "
          class="submit-container"
        >
          <p>This company has no report for the selected year.</p>
          <button
            v-if="canEdit"
            class="btn-primary submit-btn add-btn"
            @click.prevent="addReports()"
          >
            {{ submitStatus.isPending ? "Adding..." : "Add Report" }}
          </button>
          <p class="submit-msg" :class="{ success: submitStatus.isSuccess }">
            {{ submitStatus.msg }}
          </p>
        </div>
      </div>
      <strong class="save-reminder">
        {{ hasTableChanged() ? Message.Unsaved : "" }}
      </strong>
    </div>
    <div class="clarification">
      <p id="clarification">
        <sup>[?]</sup>
        <br />
        On this page you can fill out the statistics for your company for each
        month of the selected year.<br />
        If the company does not have a report for the selected year you can
        click on "Add Report" to create one.<br />
        Select "All" companies to see an aggregated total of all reported
        statistics for the selected year.<br />
        <br />
        Some fields are calculated automatically. See more information about
        each row by hovering over the row name.<br />
      </p>
    </div>
    <form v-if="!isDataEmpty()" @submit.prevent>
      <div class="btn-group">
        <button
          type="button"
          id="exportTableAsCsv"
          :disabled="submitStatus.invalidFields.size > 0"
          @click="handleCsvExport()"
        >
          <fa-icon icon="share-square"></fa-icon>
          Export table as csv file
        </button>
      </div>
    </form>
  </div>
  <MonthlyReportAutoFillDialog
    v-if="state.autoFillView"
    :accept="acceptAutoFill"
    :abort="abortAutoFill"
    :default-funcs="defaultFuncs"
    :extra-report-funcs="extraReportFuncs"
    :year="state.fieldYear"
    :corporationId="state.corporationId"
    :reports="state.reports"
    :active-cells="autoFillSelectedCells"
    v-model:overwrite="state.autoFillOverride"
  ></MonthlyReportAutoFillDialog>
  <!-- Tooltip will be inserted here by showDescription() when hovering over a row header -->
  <div id="tooltip"></div>
</template>

<script setup lang="ts">
import user from "@/auth/user";
import MonthlyReportAutoFillDialog from "@/components/MonthlyReportAutoFillDialog.vue";
import { Month, MonthsAndNames } from "@/models/enum/Months";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { MonthlyReportManager } from "@/store/MonthlyReportManager";
import { MonthlyReportDefaultCalculations } from "@/store/data/functions/MonthlyReportDefaultCalculations";
import {
  BackendMonth,
  CellKey,
  EditableCellKey,
  ExtraReportFields,
  ExtraReportFunc,
  MonthlyReport,
  MonthlyReportDescriptors,
  ReportDescriptors,
  TotalBehavior,
  getMonthlyReportHeaderId,
} from "@/store/data/types/MonthlyReport";
import { PropsAs } from "@/types/PropsOf";
import { getAllKeys, isKeyOf } from "@/types/getProp";
import { DecimalUtils } from "@/util/DecimalUtils";
import { downloadBlob } from "@/util/DownloadHelper";
import { EventType } from "@/util/EventEmitter";
import { container } from "tsyringe";
import { computed, onBeforeMount, onMounted, onUnmounted, reactive } from "vue";
import { onBeforeRouteLeave } from "vue-router";

/*
 * fetchedReports - reports with unedited values, used to compare edits to original values
 * reports - reports with the latest values, used for displaying, validating and saving new values
 */
const { corporationData, settings } = store.state;

const state = reactive({
  anim: false,
  showTotal: true,
  corporationId: settings.corporationId,
  fieldYear: new Date().getFullYear(),
  fetchedReports: [] as MonthlyReport[],
  reports: [] as MonthlyReport[],
  isReloadingData: false,
  autoFillView: false,
  autoFillOverride: true,
});

const autoFillSelectedCells = new Set<CellKey>();
const Authorization = container.resolve(AuthorizationManager);
const decimalUtils = container.resolve(DecimalUtils);
const managed = container.resolve(MonthlyReportManager);
const monthsAndNames = MonthsAndNames();
const corpIdLocalStorageKey = "6kPo2";
const yearLocalStorageKey = "a3sK4";
const reportDescriptors = ReportDescriptors;

async function reloadTableData() {
  state.isReloadingData = true;
  try {
    await managed.fetchMonthlyReport().then(() => {
      store.state.events.subscribeOrRunIfDispatched(
        EventType.FinishedLoadingTableData,
        onDataReceived
      );
    });
  } catch (error) {
    // rudimentary exception handling
    console.error(error);
    alert("Failed to load table data.");
  } finally {
    state.isReloadingData = false;
  }
}

function onDataReceived() {
  // storedId is only not null when a new database entry has been created.
  const storedId = localStorage.getItem(corpIdLocalStorageKey);
  const storedYear = localStorage.getItem(yearLocalStorageKey);
  if (storedId != null) {
    state.corporationId = parseInt(storedId);
    localStorage.removeItem(corpIdLocalStorageKey);
  } else {
    state.corporationId = settings.corporationId;
  }
  if (storedYear != null) {
    state.fieldYear = Number(storedYear);
    localStorage.removeItem(yearLocalStorageKey);
  }
  fetchTableData();
}

const canEdit = computed(
  () =>
    Authorization.editFiles.isAuthorized() &&
    store.state.leaderData
      .findWithValuesInSet(
        "locationId",
        store.state.locationData.findIds("corporationId", state.corporationId)
      )
      .some((l) => l.userId == user.state.profile.userId)
);

async function fetchTableData() {
  if (!isDataEmpty()) {
    animate();
  }

  if (showAllCompanyReport()) {
    await sumUpAllCompanyReports();
    return;
  }
  state.reports.length = 0;

  for (const obj of managed.responseData) {
    if (
      obj.corporationId === state.corporationId &&
      obj.year === state.fieldYear
    ) {
      const clone = { ...obj };
      state.reports[obj.month - 1] = obj;
      state.fetchedReports[obj.month - 1] = clone;
    }
  }
}

async function sumUpAllCompanyReports() {
  state.reports.length = 0;

  if (managed.responseData.length === 0) return;

  for (let month = 1; month <= 12; month++) {
    const totalReport: MonthlyReport = {} as MonthlyReport;
    for (const report of managed.responseData) {
      if (report.year === state.fieldYear && report.month === month) {
        for (const key of getAllKeys(reportDescriptors)) {
          if (!isKeyOf(report, key)) {
            continue;
          }
          totalReport[key] = (totalReport[key] ?? 0) + (report[key] ?? 0);
        }
      }
      state.reports[month - 1] = totalReport;
    }
  }

  if (Object.keys(state.reports[0]).length === 0) {
    state.reports.length = 0;
  }
}

onBeforeMount(async () => {
  reloadTableData().then(() => {
    const i = location.href.lastIndexOf("#");
    if (i < 0 || i >= location.href.length - 1) {
      return;
    }
    const id = location.href.substring(i + 1);
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  });
});

onMounted(() => {
  window.addEventListener("mousemove", handleMouse);
});

onUnmounted(() => {
  // Reset the form to lose the unsaved changes when navigating
  if (hasTableChanged()) {
    for (let i = 0; i < state.fetchedReports.length; ++i) {
      for (let j = 0; j < managed.responseData.length; ++j) {
        if (
          managed.responseData[j].year == state.fetchedReports[i].year &&
          managed.responseData[j].month == state.fetchedReports[i].month &&
          managed.responseData[j].corporationId ==
            state.fetchedReports[i].corporationId
        ) {
          managed.responseData[j] = state.fetchedReports[i];
          break;
        }
      }
    }
  }
  // Clear event listeners so they don't trigger on other pages
  window.removeEventListener("beforeunload", beforeUnloadHandler);
  window.removeEventListener("mousemove", handleMouse);
});

function beforeUnloadHandler(event: Event) {
  // Displays a warning when trying to reload the page with unsaved changes
  event.preventDefault();
}

onBeforeRouteLeave((to, from, next) => {
  if (hasTableChanged() && to.fullPath != "/") {
    // Displays a warning when trying to navigate away from the page
    if (!window.confirm(Message.SaveWarning)) {
      return;
    }
  }
  next();
});

function isDataEmpty(): boolean {
  return state.reports.length === 0;
}

function showAllCompanyReport(): boolean {
  return state.corporationId === -1;
}

const extraReportFuncs: Record<ExtraReportFields, ExtraReportFunc> = {
  expectedAvailableHours: calculateExpectedAvailableHours,
  totalAvailableHours: calculateTotalAvailableHours,
  actualUtilRate: calculateActualUtilizationRates,
  expectedUtilRate: calculateExpectedUtilizationRates,
};

function getInputId(key: EditableCellKey, month: BackendMonth) {
  return `${key}-${month}-input`;
}

function getCellInputElement(key: EditableCellKey, month: BackendMonth) {
  return document.getElementById(getInputId(key, month));
}

function handleCellInput(
  payload: KeyboardEvent,
  key: EditableCellKey,
  month: BackendMonth
) {
  const { code } = payload;

  if (code == "ArrowUp") {
    payload.preventDefault();
    navigateCellUp(key, month);
    return;
  }

  if (code == "ArrowDown") {
    payload.preventDefault();
    navigateCellDown(key, month);
    return;
  }

  if (code == "ArrowLeft") {
    if (month != BackendMonth.Jan) {
      getCellInputElement(key, month - 1)?.focus();
    }
    return;
  }

  if (code == "ArrowRight") {
    if (month != BackendMonth.Dec) {
      getCellInputElement(key, month + 1)?.focus();
    }
    return;
  }

  if (code == "Enter") {
    if (payload.shiftKey) navigateCellUp(key, month);
    else navigateCellDown(key, month);
  }
}

function navigateCellUp(key: EditableCellKey, month: BackendMonth) {
  const report = state.reports[month - 1];

  let lastKey: EditableCellKey | undefined;
  for (const reportKey of getAllKeys(reportDescriptors)) {
    if (!isKeyOf(report, reportKey)) {
      continue;
    }

    if (reportKey != key) {
      lastKey = reportKey;
      continue;
    }

    if (lastKey == undefined) {
      return;
    }

    getCellInputElement(lastKey, month)?.focus();
    return;
  }
}

function navigateCellDown(key: EditableCellKey, month: BackendMonth) {
  const report = state.reports[month - 1];

  let lastKey: EditableCellKey | undefined;
  for (const reportKey of getAllKeys(reportDescriptors)) {
    if (!isKeyOf(report, reportKey)) {
      continue;
    }

    if (lastKey != key) {
      lastKey = reportKey;
      continue;
    }

    getCellInputElement(reportKey, month)?.focus();
    return;
  }
}

function abortAutoFill() {
  state.autoFillView = false;
  autoFillSelectedCells.clear();
}

function acceptAutoFill() {
  state.autoFillView = false;
  for (const cell of autoFillSelectedCells) {
    const key = getKey(cell);
    const month = getMonth(cell);
    const report = state.reports[month - 1];
    if (!report) {
      continue;
    }

    const value = report[key];
    const func = defaultFuncs[key];
    if (!func || (!state.autoFillOverride && value !== null)) {
      continue;
    }

    let newValue = func(report);
    if (newValue == null) {
      continue;
    }

    newValue = decimalUtils.limitDecimalCount(newValue, 2);
    if (newValue == value) {
      continue;
    }

    report[key] = newValue;
    fieldEdited(cell, report);
  }
  autoFillSelectedCells.clear();
}

const defaultFuncs: PropsAs<
  MonthlyReport,
  number | null,
  (report: MonthlyReport) => number | null
> = {
  fteConsultants: MonthlyReportDefaultCalculations.fteConsultants,
  employedConsultants: MonthlyReportDefaultCalculations.employedConsultants,
  internsAndNotEmployed: MonthlyReportDefaultCalculations.internsAndNotEmployed,
  // revenueNIS: MonthlyReportDefaultCalculations.revenueNIS,
  availableDailyHours: MonthlyReportDefaultCalculations.availableDailyHours,
  newCertificates: MonthlyReportDefaultCalculations.newCertificates,
};

enum Message {
  None = "",
  Unsaved = "You have unsaved changes.",
  SaveWarning = "Are you sure you want to leave without saving?",
  Invalid = "Invalid data.",
  Unauthorized = "Not Authorized to edit.",
  Added = "Report added.",
  Updated = "Report updated.",
  FailedToAdd = "Failed to add report.",
  FailedToUpdate = "Failed to update report.",
}

const msgTime = 3000;
function setMessageWithTimeout(msg: Message) {
  submitStatus.msg = msg;
  setTimeout(() => {
    submitStatus.msg = hasTableChanged() ? Message.Unsaved : Message.None;
  }, msgTime);
}

const submitStatus = reactive({
  msg: Message.None,
  isPending: false,
  isSuccess: true,
  invalidFields: new Set<CellKey>(),
  changedFields: new Set<CellKey>(),
});

const CellKeySeparator = "|";

function getKey(key: CellKey) {
  return key.split(CellKeySeparator)[0] as EditableCellKey;
}

function getMonth(key: CellKey): BackendMonth {
  return Number.parseInt(key.split(CellKeySeparator)[1]);
}

function hasTableChanged() {
  return submitStatus.changedFields.size > 0;
}

function hasFieldChanged(key: CellKey) {
  return submitStatus.changedFields.has(key);
}

function hasInvalidTableData() {
  return submitStatus.invalidFields.size > 0;
}

function hasInvalidFieldData(key: CellKey) {
  return submitStatus.invalidFields.has(key);
}

async function animate() {
  state.anim = true;
  await new Promise((resolve) => setTimeout(resolve, 500));
  state.anim = false;
}

/**
 * @param key Set key containing the key to the cell and the month
 * @param report Contains all fields and values of a given month
 * @focusout Called with focusout because non-numeric inputs won't trigger onchange
 */
function fieldEdited(key: CellKey, report: MonthlyReport) {
  const fieldKey = getKey(key);
  const month: Month = getMonth(key) - 1;
  const wasChangedBefore = submitStatus.changedFields.has(key);
  const field = getCellInputElement(fieldKey, report.month) as HTMLInputElement;

  // If the field is cleared, it will be an empty string instead of null, convert it
  if (report[fieldKey]?.toString().length === 0) {
    report[fieldKey] = null;
  }
  const isUnchanged = state.fetchedReports[month][fieldKey] == report[fieldKey];

  if (!wasChangedBefore) {
    // Firefox support - consider the field changed if it contains bad input, even if it's "empty"
    if (!isUnchanged || field.validity.badInput) {
      submitStatus.changedFields.add(key);
      submitStatus.msg = Message.Unsaved;
      window.addEventListener("beforeunload", beforeUnloadHandler);
    }
  }

  // If the value is reverted to its original value, clear it from changedFields
  if (wasChangedBefore && isUnchanged) {
    // Firefox support - don't clear changed status if field contains non-numeric value
    if (!field.validity.badInput) submitStatus.changedFields.delete(key);
  }

  validateInput(key, report);

  // If there are no changed fields anymore, clear the unsaved message
  if (!hasTableChanged()) {
    submitStatus.msg = Message.None;
  }
}

// Runs whenever an input is changed and loses focus
function validateInput(key: CellKey, report: MonthlyReport) {
  const fieldKey = getKey(key);
  const field = getCellInputElement(fieldKey, report.month) as HTMLInputElement;
  const wasInvalidBefore = submitStatus.invalidFields.has(key);

  /* Firefox support - invalidate non-numeric inputs */
  if (field.validity.badInput) {
    if (!wasInvalidBefore) submitStatus.invalidFields.add(key);
    return;
  }

  const value = report[fieldKey];

  // Null values (empty fields) are valid
  if (value === null) {
    if (wasInvalidBefore) submitStatus.invalidFields.delete(key);
    return;
  }

  // Define unacceptable values here
  const isNAN = typeof value != "number";
  const canBeNegative = reportDescriptors[fieldKey].canBeNegative;
  const isInvalidNegative = !canBeNegative && value < 0;
  const canHaveDecimals = reportDescriptors[fieldKey].canHaveDecimals;
  const isInvalidDecimal = !canHaveDecimals && !Number.isInteger(value);
  const maxValue = reportDescriptors[fieldKey].maxValue;
  const valueTooHigh = maxValue && value > maxValue;
  const isInvalid =
    isNAN || isInvalidNegative || isInvalidDecimal || valueTooHigh;

  // Add the field to invalid values if it isn't already added
  if (isInvalid && !wasInvalidBefore) {
    submitStatus.invalidFields.add(key);
    return;
  }

  // Clear the field from invalid values
  if (!isInvalid && wasInvalidBefore) {
    submitStatus.invalidFields.delete(key);
  }
}

// Validates all cells upon saving
function validateAll(report: MonthlyReport) {
  let hasInvalidFields = false;
  for (const key of getAllKeys(reportDescriptors)) {
    if (!isKeyOf(report, key)) {
      continue;
    }
    validateInput(`${key}|${report.month}`, report);
    hasInvalidFields = submitStatus.invalidFields.has(`${key}|${report.month}`);
  }
  return !hasInvalidFields;
}

async function submitForm() {
  if (!canEdit.value) {
    setMessageWithTimeout(Message.Unauthorized);
    return;
  }
  /* The submit button should be disabled when fields are invalid
   * This is in case the user re-enables the button with devtools*/
  if (hasInvalidTableData()) {
    setMessageWithTimeout(Message.Invalid);
    return;
  }

  /* We run validation again on each cell in each report
   *  to make sure validation is not circumvented.
   *  To test this, input an invalid value, then cause a hot-reload and try to submit. */
  for (const report of state.reports) {
    validateAll(report);
  }
  if (hasInvalidTableData()) {
    submitStatus.isSuccess = false;
    setMessageWithTimeout(Message.Invalid);
    return;
  }

  await request(
    state.reports,
    (r) => managed.updateMonthlyReport(r),
    Message.Updated,
    Message.FailedToUpdate
  );

  /* Once each month has finished submitting, if there are no invalid fields:
   *  Clear cells from the "unsaved changes" flag
   *  Clear the event listener for the "unsaved changes" popup warning*/
  if (!hasInvalidTableData()) {
    submitStatus.changedFields.clear();
    window.removeEventListener("beforeunload", beforeUnloadHandler);
    // Update "original" reports with saved data
    const clone = JSON.parse(JSON.stringify(state.reports));
    state.fetchedReports = clone;
    managed.fetchMonthlyReport();
  }
}

async function addReports() {
  if (!canEdit.value) {
    setMessageWithTimeout(Message.Unauthorized);
    return;
  }

  if (!isDataEmpty()) {
    return;
  }

  const reports = new Array<MonthlyReport>();

  for (let i = 1; i <= 12; i++) {
    const data = {} as MonthlyReport;
    data.corporationId = state.corporationId;
    data.year = state.fieldYear;
    data.month = i;
    reports.push(data);
  }

  await request(
    reports,
    (r) => managed.addMonthlyReport(r),
    Message.Added,
    Message.FailedToAdd
  );

  // Refreshes the page so it can load in the new date
  localStorage.setItem(corpIdLocalStorageKey, state.corporationId.toString());
  localStorage.setItem(yearLocalStorageKey, state.fieldYear.toString());
  location.reload();
}

async function request(
  reports: MonthlyReport[],
  func: (report: MonthlyReport) => Promise<void>,
  success: Message,
  fail: Message
) {
  const promises = new Array<Promise<void>>();

  for (const report of reports) {
    const promise = func(report)
      .then(() => {
        submitStatus.isSuccess = true;
        setMessageWithTimeout(success);
      })
      .catch(() => {
        submitStatus.isSuccess = false;
        setMessageWithTimeout(fail);
      })
      .finally(() => {
        submitStatus.isPending = false;
      });

    promises.push(promise);
  }

  await Promise.all(promises);
}

function errorTooltip(key: CellKey) {
  // Returns no tooltip unless the value is invalid
  if (!hasInvalidFieldData(key)) return undefined;

  let errorMessage = "Expects a ";
  const fieldKey = getKey(key);

  const { canBeNegative, canHaveDecimals, maxValue } =
    reportDescriptors[fieldKey];
  errorMessage += canHaveDecimals ? "number" : "whole number";
  if (maxValue) {
    errorMessage += (canBeNegative ? " up to " : " between 0 and ") + maxValue;
  } else {
    errorMessage += canBeNegative ? "" : " greater than 0";
  }
  errorMessage += ".";

  return errorMessage;
}

function showDescription(key: keyof MonthlyReportDescriptors) {
  const tooltip = document.getElementById("tooltip");
  if (tooltip == null) {
    return;
  }

  const descriptor = reportDescriptors[key];
  let header = descriptor.fullHeader
    ? descriptor.fullHeader
    : descriptor.header;
  header = header + (descriptor.isPercent ? " (%)" : "");
  tooltip.innerHTML = `<strong>${header}:</strong><br />${descriptor.tooltip}`;
  tooltip.style.display = "inline-block";
}

function handleMouse(event: MouseEvent) {
  const tooltip = document.getElementById("tooltip");
  if (tooltip == null) {
    return;
  }
  tooltip.style.top = `${event.pageY}px`;
  tooltip.style.left = `${event.pageX}px`;
}

function hideDescription() {
  const tooltip = document.getElementById("tooltip");
  if (tooltip != null) {
    tooltip.style.display = "none";
  }
}

function calculateTotalAvailableHours(report: MonthlyReport) {
  return calculateHours(
    report.availableWorkingDays,
    report.availableDailyHours,
    report.fteConsultants
  );
}

function calculateExpectedAvailableHours(report: MonthlyReport) {
  return calculateHours(
    report.availableWorkingDays,
    report.availableDailyHours,
    report.expectedFTEConsultants
  );
}

function calculateHours(
  workDays: number | null,
  workHours: number | null,
  fte: number | null
) {
  // If any value needed for the calculation is null, return 0
  if (workDays == null || workHours == null || fte == null) {
    return 0;
  }

  return workDays * workHours * fte;
}

function calculateExpectedUtilizationRates(
  report: MonthlyReport,
  accumulatedDataPoints?: boolean,
  month?: number
) {
  return calculateUtilisationRates(
    report.expectedBilledHours,
    accumulatedDataPoints
      ? totalAsSumOfMonthlyCalculations("expectedAvailableHours", month)
      : calculateExpectedAvailableHours(report),
    report.expectedVacationHours
  );
}

function calculateActualUtilizationRates(
  report: MonthlyReport,
  accumulatedDataPoints?: boolean,
  month?: number
) {
  return calculateUtilisationRates(
    report.totalBilledHours,
    accumulatedDataPoints
      ? totalAsSumOfMonthlyCalculations("totalAvailableHours", month)
      : calculateTotalAvailableHours(report),
    report.totalVacationHours
  );
}

function calculateUtilisationRates(
  billedHours: number | null,
  availableHours: number | null,
  vacationHours: number | null
) {
  if (availableHours == null || billedHours == null) {
    return 0;
  }

  vacationHours ??= 0;

  if (availableHours - vacationHours <= 0) {
    return 100;
  }

  const calc = (billedHours / (availableHours - vacationHours)) * 100;
  // Limit to 0-100%
  if (calc >= 100) return 100;
  if (calc <= 0) return 0;
  if (!calc) return 0;

  return calc;
}

function getCellValue(
  report: MonthlyReport,
  key: keyof MonthlyReportDescriptors
) {
  return isKeyOf(report, key)
    ? report[key] ?? ""
    : extraReportFuncs[key](report);
}

function formattedCellValue(
  report: MonthlyReport,
  key: keyof MonthlyReportDescriptors
) {
  const val = decimalUtils.limitDecimalCountAndReplaceSeparator(
    getCellValue(report, key),
    2,
    ","
  );
  return val != "" && reportDescriptors[key].isPercent ? `${val}%` : val;
}

function formattedCellValueTotal(
  key: keyof MonthlyReportDescriptors,
  month?: number
) {
  return decimalUtils.limitDecimalCountAndReplaceSeparator(
    getCellValueTotal(key, month),
    2,
    ","
  );
}

function getCellValueTotal(
  key: keyof MonthlyReportDescriptors,
  month?: number
): number | string {
  if (reportDescriptors[key].skipTotal) {
    return "N/A";
  }

  if (!isKeyOf(extraReportFuncs, key)) {
    // if the key is pointing to a plain number field in MonthlyReport
    // we can simply return the sum/average of that field across all reports
    const sum = state.reports.reduce((s, r) => s + (r[key] || 0), 0);
    return reportDescriptors[key].isPercent
      ? `${decimalUtils.limitDecimalCountAndReplaceSeparator(
          sum / 12,
          2,
          ","
        )}%`
      : sum;
  }

  // if we are looking at a calculated field not defined in MonthlyReport,
  // how the total will be calculated is determined by `totalBehavior`.
  switch (reportDescriptors[key].totalBehavior) {
    case TotalBehavior.SumOfMonthlyCalculations:
      return totalAsSumOfMonthlyCalculations(key, month);
    case TotalBehavior.CalculationWithAccumulatedDataPoints:
      return totalAsCalculationWithAccumulatedDataPoints(key, month);
  }

  return "N/A";
}

/**
 * Calculating the values for each month, then returning the sum.
 */
function totalAsSumOfMonthlyCalculations(
  key: ExtraReportFields,
  month?: number
) {
  const func = extraReportFuncs[key];
  // Return the sum of the year for the selected company
  if (!showAllCompanyReport()) {
    return state.reports.reduce((s, r) => s + (func(r) as number), 0);
  }

  // Return the sum of all company reports, for the month or the year
  let filteredReports = [] as MonthlyReport[];
  month
    ? (filteredReports = managed.responseData.filter(
        (r) => r.year === state.fieldYear && r.month === month
      ))
    : (filteredReports = managed.responseData.filter(
        (r) => r.year === state.fieldYear
      ));
  return filteredReports.reduce((s, r) => s + (func(r) as number), 0);
}

/**
 * Sum the values of each field of every report as a MonthlyReport proxy,
 * then pass the proxy to the calculation function.
 */
function totalAsCalculationWithAccumulatedDataPoints(
  key: ExtraReportFields,
  month?: number
) {
  const sums = {} as MonthlyReport & Record<ExtraReportFields, number>;
  let filteredReports = [] as MonthlyReport[];
  if (showAllCompanyReport()) {
    month
      ? (filteredReports = managed.responseData.filter(
          (r) => r.year === state.fieldYear && r.month === month
        ))
      : (filteredReports = managed.responseData.filter(
          (r) => r.year === state.fieldYear
        ));
  } else {
    filteredReports = state.reports;
  }

  for (const report of filteredReports) {
    for (const key of getAllKeys(reportDescriptors)) {
      if (!isKeyOf(report, key)) {
        continue;
      }
      const val = report[key];
      const sum = sums[key];
      if (sum == undefined || sum == null) {
        sums[key] = val ?? 0;
      } else if (val) {
        sums[key] = sum + val;
      }
    }
  }

  const result = extraReportFuncs[key](sums, true, month);
  return reportDescriptors[key].isPercent
    ? `${decimalUtils.limitDecimalCountAndReplaceSeparator(result, 2, ",")}%`
    : result;
}

function handleCsvExport() {
  // Appending \t forces Excel to consider all cells as "general", preventing accidental conversion into date format
  const separator = "\t;";
  const csvTable = new Array<string>();
  // Top row
  csvTable.push("Statistic" + separator);
  const months = monthsAndNames.map((m) => m.name).join(separator);
  csvTable.push(`${months}${separator}Total\n`);

  for (const key of getAllKeys(reportDescriptors)) {
    if (showAllCompanyReport() && reportDescriptors[key].skipAllReportTotal)
      continue;
    const headerToUse = reportDescriptors[key].fullHeader
      ? reportDescriptors[key].fullHeader
      : reportDescriptors[key].header;
    const row = state.reports
      .map((report, index) =>
        showAllCompanyReport() && !isKeyOf(report, key)
          ? formattedCellValueTotal(key, index + 1)
          : formattedCellValue(report, key)
      )
      .join(separator);
    const total = formattedCellValueTotal(key);
    csvTable.push(`${headerToUse}${separator}${row}${separator}${total}\t\n`);
  }

  // Download csv file
  const csvBlob = new Blob(csvTable, { type: "text/csv" });
  const corpName =
    corporationData.find("corporationId", state.corporationId)?.getName() ??
    "Corporation_Name";
  const formattedCorpName = corpName.replace(/\s+/g, "_");
  const csvFileName = showAllCompanyReport()
    ? `Annual_Report-${state.fieldYear}.csv`
    : `Annual_Report-${formattedCorpName}-${state.fieldYear}.csv`;
  downloadBlob(csvBlob, csvFileName);
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

$verticallyAlignTooltip: translateY(-26px);
$horizontallyAlignTooltip: translateX(10px);

#tooltip {
  color: $color-black;
  background: $color-white;
  text-align: left;
  min-width: 150px;
  max-width: 400px;
  min-height: 25px;
  height: fit-content;
  position: absolute;
  display: none;
  padding: 3px 6px;
  font-size: 12px;
  transform: $verticallyAlignTooltip $horizontallyAlignTooltip;
  border: 1px solid $color-black;
  border-radius: 5px;
  pointer-events: none;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#exportTableAsCsv {
  margin-bottom: 3vh;
}

.clarification {
  @include clarification($font-size: 0.9em);
  display: inline-flex;
  position: relative;
  text-align: left;
  margin: 20px 20px 20px 20px;
}

.container {
  @include baseTableOptionsContainer;
  width: max-content;
  display: inline-flex;
  margin: 10px 20px;
  position: relative;
  text-align: left;
  padding: 24px 16px;
  flex-direction: column;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .title-section {
    @include title-section;

    * {
      margin: 3px;
    }
  }

  .options-container {
    @include baseTableOptionsContainer();
    overflow: visible;

    .options {
      @include options($border: 1px, $border-radius: 20px);
      position: relative;
      flex-wrap: wrap;
      overflow: visible;
    }

    .options {
      left: 100;
    }

    .options > div {
      margin: 10px;

      .options {
        margin: 10px;
      }
    }
  }

  .select-container {
    @include select-radio-containers();
  }

  .filter-box {
    @include sort-box();
    @include form-field;
    width: unset;
    padding-right: 10px;
    margin: 0 auto;

    strong {
      width: 100%;
      text-align: center;
      user-select: none;
    }
  }

  .tablewrapper {
    @include tablewrapper;
  }

  table {
    @include monthlyReportTable;

    th {
      position: sticky;
      top: 0;
    }

    tr {
      @include tr;
      vertical-align: left;

      &:hover > td,
      &:focus-visible > td {
        background-color: $row-hover-bgc;
      }
    }
  }

  .input-container {
    overflow: hidden;
    border: 1px solid $border-color;
    border-radius: 5px;
    width: 100px;

    input {
      @include input($width: 100%);
      border: none;
      text-align: right;
      border-width: 0 1px;
      padding: 1px 3px;

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px $vea-primary-color;
      }

      &.no-input-arrows {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type="number"] {
          appearance: none;
          -moz-appearance: textfield;
        }
      }
    }
  }

  .invalid {
    outline: 2px solid $color-red;
  }

  .hasFieldChanged {
    font-weight: bold;
  }

  .submit-container {
    @include submit-container;

    .submit-btn {
      @include submit-btn;

      &:disabled {
        background-color: $color-grey-transparent;
      }

      margin-right: 5px;
      margin-left: 5px;

      &#auto-fill-button {
        margin-right: 0;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      &#set-auto-fill-month-btn {
        margin-left: 0;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        background: #54a0ff;
        color: white;

        &:hover {
          background-color: #91bdfe;
        }

        padding: 3.5px;
        padding-left: 10px;
        line-height: 24px !important;
      }
    }

    #auto-fill-override-container {
      background: #54a0ff;
      color: white;
      display: inline;
      border-radius: 5px;
      border: none;
      padding: 3.5px;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid dimgray;
      border-right: 1px solid dimgray;

      input {
        margin: 5px;
      }

      &:hover {
        background-color: #91bdfe;
      }

      border-radius: 0px;
    }

    .submit-msg {
      display: block;
      height: 1em;
      margin-bottom: 1em;
      margin-top: 0.5rem;
    }

    .submit-msg.success {
      color: $color-green;
    }

    .submit-msg:not(.success) {
      color: $color-red;
    }

    .submit-msg.unsaved {
      color: black;
    }

    .add-btn {
      margin-top: 30px;
      background-color: $color-green;
    }
  }

  .no-corp-selected {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1rem;
    font-style: italic;
  }

  .fadeInOut-enter-active,
  .fadeInOut-leave-active {
    transition: all 0.2s ease-in-out;
  }

  .fadeInOut-enter-from,
  .fadeInOut-leave-to {
    opacity: 0;
  }

  .fading {
    animation: fading 0.5s ease-in-out;
  }

  @keyframes fading {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.scrollable {
  height: 50vh;
  overflow-y: auto;
}
</style>
