import { Consultant } from "@/models/Consultant";
import { ContractPhase } from "@/models/enum/ContractPhase";
import store from "@/store";
import { MonthlyReportManager } from "@/store/MonthlyReportManager";
import {
  DateKey,
  dateKey,
  dayFromKey,
  monthFromKey,
  monthKeyFromDate,
  monthKeyFromYearAndMonth,
  yearFromKey,
} from "@/types/DateKey";
import {
  countDaysInMonth,
  countDaysInSpan,
  dateSpan,
  monthAsDateSpan,
  overlaps,
} from "@/types/DateSpan";
import { sum } from "@/types/SumFields";
import { container } from "tsyringe";
import { BackendMonth, MonthlyReport } from "../types/MonthlyReport";

export class MonthlyReportDefaultCalculations {
  static fteConsultants(report: MonthlyReport): number | null {
    const month = monthKeyFromYearAndMonth(report.year, report.month - 1);
    const monthSpan = monthAsDateSpan(month);
    const consultants =
      MonthlyReportDefaultCalculations.getConsultantsForReport(report);
    const internshipEnds =
      MonthlyReportDefaultCalculations.getInternshipsEnds();

    let result = 0;
    for (const consultant of consultants) {
      let startDate = dateKey(consultant.startDate);
      const endDate = dateKey(consultant.endDate ?? monthSpan.end);
      const internshipEnd = internshipEnds.get(consultant.consultantId);
      if (internshipEnd !== undefined) {
        if (monthSpan.end <= internshipEnd) {
          continue;
        }
        startDate = MonthlyReportDefaultCalculations.nextDay(internshipEnd);
      }
      const start = startDate > monthSpan.start ? startDate : monthSpan.start;
      const end = endDate < monthSpan.end ? endDate : monthSpan.end;
      const daysInSpan = countDaysInSpan({ start, end });
      const daysInMonth = countDaysInMonth(month);

      result += (daysInSpan / daysInMonth) * (consultant.coverage / 100);
    }
    return result;
  }

  private static nextDay(date: DateKey): DateKey {
    const year = yearFromKey(date);
    const month = monthFromKey(date);
    const day = dayFromKey(date);

    const result = dateKey(new Date(year, month, day + 1));
    return result;
  }

  private static getConsultantsForReport(report: MonthlyReport): Consultant[] {
    const { corporationId } = report;
    const { consultantData, consultantGroupData } = store.state;
    const monthSpan = monthAsDateSpan(
      monthKeyFromYearAndMonth(report.year, report.month - 1)
    );

    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      corporationId
    );

    const consultants = consultantData.findWithValuesInSet(
      "consultantGroupId",
      consultantGroupIds
    );

    return consultants.filter((consultant) => {
      if (!consultant.endDate) {
        return consultant.startDate <= monthSpan.end;
      }
      const employmentSpan = dateSpan(consultant.startDate, consultant.endDate);
      return overlaps(employmentSpan, monthSpan);
    });
  }

  private static getInternshipsEnds(): Map<number, DateKey> {
    const { assignmentData } = store.state;
    const internships = assignmentData.findMany(
      "phase",
      ContractPhase.INTERNSHIP
    );
    const internshipEndByConsultantId = new Map<number, DateKey>();

    for (const internship of internships) {
      const { consultantId } = internship;
      const oldDate = internshipEndByConsultantId.get(consultantId);
      const newDate = dateKey(internship.endDate);
      if (oldDate == undefined || oldDate < newDate) {
        internshipEndByConsultantId.set(consultantId, newDate);
      }
    }

    return internshipEndByConsultantId;
  }

  static employedConsultants(report: MonthlyReport): number | null {
    const consultants =
      MonthlyReportDefaultCalculations.getConsultantsForReport(report);

    const internshipEndByConsultantId =
      MonthlyReportDefaultCalculations.getInternshipsEnds();

    const { end } = monthAsDateSpan(
      monthKeyFromYearAndMonth(report.year, report.month - 1)
    );
    return consultants.filter(({ consultantId, endDate }) => {
      const internshipEnd = internshipEndByConsultantId.get(consultantId);
      return (
        (internshipEnd === undefined || internshipEnd < end) &&
        (endDate === null || dateKey(endDate) >= end)
      );
    }).length;
  }

  static internsAndNotEmployed(report: MonthlyReport): number | null {
    const consultants =
      MonthlyReportDefaultCalculations.getConsultantsForReport(report);

    const internshipEndByConsultantId =
      MonthlyReportDefaultCalculations.getInternshipsEnds();

    const { end } = monthAsDateSpan(
      monthKeyFromYearAndMonth(report.year, report.month - 1)
    );
    return consultants.filter(({ consultantId }) => {
      const internshipEnd = internshipEndByConsultantId.get(consultantId);
      return internshipEnd !== undefined && internshipEnd >= end;
    }).length;
  }

  static revenueNIS(report: MonthlyReport): number | null {
    const {
      corporationData,
      nisConsultantGroupData,
      nisConsultantData,
      nisAssignmentData,
      invoiceData,
    } = store.state;

    const corporation = corporationData.findById(report.corporationId);

    const nisConsultantGroupIds = nisConsultantGroupData.findIds(
      "corporationId",
      corporation.corporationId
    );
    const nisConsultantIds = nisConsultantData.findWithValuesInSetIds(
      "nisConsultantGroupId",
      nisConsultantGroupIds
    );
    const nisAssignmentIds = nisAssignmentData.findWithValuesInSetIds(
      "nisConsultantId",
      nisConsultantIds
    );
    const nisInvoices = invoiceData
      .findWithValuesInSet("nisAssignmentId", nisAssignmentIds)
      .filter(
        (i) =>
          i.currencyId == corporation.currencyId &&
          yearFromKey(i.date) == report.year &&
          monthFromKey(i.date) + 1 == report.month
      );

    return sum(nisInvoices, "amount");
  }

  static availableDailyHours(report: MonthlyReport): number | null {
    if (report.availableDailyHours) {
      return report.availableDailyHours;
    }

    const managed = container.resolve(MonthlyReportManager);
    const reportStartOfYear = managed.responseData.find(
      (r) =>
        report.corporationId == r.corporationId &&
        report.year == r.year &&
        r.month == BackendMonth.Jan
    );
    if (reportStartOfYear?.availableDailyHours) {
      return reportStartOfYear.availableDailyHours;
    }

    const reportSameMonthLastYear = managed.responseData.find(
      (r) =>
        report.corporationId == r.corporationId &&
        report.year - 1 == r.year &&
        report.month == r.month
    );
    if (reportSameMonthLastYear?.availableDailyHours) {
      return reportSameMonthLastYear.availableDailyHours;
    }

    return 8;
  }

  static newCertificates(report: MonthlyReport): number | null {
    const { consultantCertificateData, consultantData, consultantGroupData } =
      store.state;
    const reportMonth = monthKeyFromYearAndMonth(report.year, report.month - 1);

    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      report.corporationId
    );
    const consultantIds = consultantData.findWithValuesInSetIds(
      "consultantGroupId",
      consultantGroupIds
    );
    const consultantCertificateIds =
      consultantCertificateData.findWithValuesInSet(
        "consultantId",
        consultantIds
      );
    return consultantCertificateIds.filter(
      (cc) => monthKeyFromDate(cc.date) == reportMonth
    ).length;
  }
}
