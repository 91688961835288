import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantServiceData } from "@/store/data/ConsultantServiceData";
import { ServiceData } from "@/store/data/ServiceData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Consultant } from "../Consultant";
import { ConsultantService } from "../ConsultantService";
import Service from "../Service";
import { ConsultantDisplayable } from "./ConsultantDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { ServiceDisplayable } from "./ServiceDisplayable";
import { DropdownField } from "./fields/DropdownField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueConsultantService } from "./fields/validators/FieldUniqueConsultantService";

export class ConsultantServiceDisplayable extends DisplayableBase<ConsultantService> {
  @ValidateNested()
  consultantName: DropdownField<Consultant, ConsultantDisplayable>;

  @ValidateNested()
  @FieldUniqueConsultantService()
  serviceName: DropdownField<Service, ServiceDisplayable>;

  constructor(consultantService: ConsultantService) {
    super();
    const consultantServiceData = container.resolve(ConsultantServiceData);
    const serviceData = container.resolve(ServiceData);
    const { headers } = consultantServiceData;
    const consultantData = container.resolve(ConsultantData);

    this.consultantName = new DropdownField(
      headers.consultantName,
      consultantService.getName(),
      consultantData,
      new DropdownOption(
        consultantService.consultantId,
        consultantService.consultantName
      )
    );

    this.serviceName = new DropdownField(
      headers.serviceName,
      consultantService.serviceName,
      serviceData,
      new DropdownOption(
        consultantService.serviceId,
        consultantService.serviceName
      )
    );
  }

  toModel(consultantService: ConsultantService): void {
    consultantService.consultantId = this.consultantName.selectedOption.id;
  }
}
