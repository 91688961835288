<template>
  <div style="margin: 10% auto">
    <h1>Not found</h1>
    <div>
      That page does not exist.<br />
      <router-link to="/home"
        >Click here to return to the home page</router-link
      >
    </div>
  </div>
</template>
