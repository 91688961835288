<template>
  <div
    class="multi-select"
    tabindex="0"
    @keydown.enter.prevent="toggleDropdown()"
    @keydown.esc="hideDropdown()"
    ref="dropdown_ref"
    @click="toggleDropdown()"
  >
    <div class="selector" id="dropdown-selector">
      {{ selected }}
      <fa-icon icon="chevron-down" class="icon" />
    </div>
    <table class="dropdown" id="dropdown-table" v-if="hasDropdown.open">
      <tr
        class="dropdown-option"
        v-for="({ option, index }, key) in sortedOptions"
        :key="key"
        @click="eventToggle($event, index)"
        @keydown.enter.prevent="eventToggle($event, index)"
        @keydown.tab="key === options.length - 1 && hideDropdown()"
        @keydown.shift.tab.exact="key === 0 && hideDropdown()"
      >
        <td>
          <input
            :name="getName(option)"
            class="checkbox"
            type="checkbox"
            v-model="selection[index]"
            v-bind:id="index.toString()"
          />
        </td>
        <td>&nbsp;{{ getName(option) }}</td>
      </tr>
    </table>
  </div>
</template>

<script setup lang="ts" generic="T extends string | Named">
import { Named } from "@/interfaces/Named";
import { useClickOutside } from "@/util/UseClickOutside";
import { computed, reactive, Ref, ref } from "vue";

const props = defineProps<{
  options: T[];
  selectedOptions: boolean[];
  getName: (option: T) => string;
  sorted?: boolean;
}>();

const emit = defineEmits(["toggle:selectedOption"]);

function eventToggle(event: KeyboardEvent | MouseEvent, key: number) {
  emit("toggle:selectedOption", key);
  event.stopPropagation();
}

const sortedOptions = computed(() => {
  if (!props.sorted) {
    return props.options.map((option, index) => {
      return { option, index };
    });
  }
  return props.options
    .map((option, index) => {
      return { option, index };
    })
    .sort((a, b) =>
      props.getName(a.option).localeCompare(props.getName(b.option))
    );
});

const selected = computed(() => {
  const nOptions = props.selectedOptions.filter((o) => o).length;
  if (nOptions == props.selectedOptions.length) return "<All>";
  if (nOptions == 0) return "<None>";
  let returnString = "";
  const maxLength = 15;
  for (let i = 0; i < props.selectedOptions.length; i++) {
    if (props.selectedOptions[i]) {
      returnString += props.getName(props.options[i]);
      if (returnString.length >= maxLength) {
        returnString = returnString.substring(0, maxLength);
        returnString += `... (${nOptions})`;
        break;
      } else returnString += "; ";
    }
  }
  if (returnString.charAt(returnString.length - 2) == ";")
    return returnString.substring(0, returnString.length - 2);
  else return returnString;
});
function hideDropdown() {
  hasDropdown.open = false;
}
function toggleDropdown() {
  hasDropdown.open = !hasDropdown.open;
}

const hasDropdown = reactive({ open: false });

const dropdown_ref: Ref<EventTarget | null> = ref(null);
useClickOutside(dropdown_ref, () => {
  hasDropdown.open = false;
});

const selection = computed(() => props.selectedOptions);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.multi-select {
  text-align: left;
  width: 200px;
}

.selector {
  border: 1px solid $border-color;
  box-shadow: 0px 2.5px 4px 0px $border-color;
  //max-width: 150px;
  overflow: visible;
  border-radius: 5% / 20%;
  padding-left: 5px;
  background-color: rgba(230, 230, 230);
  position: relative;
}

.selector:hover {
  cursor: default;
  filter: brightness(90%);
}

.dropdown {
  background-color: $container-bgc;
  border: 1px solid;
  border-radius: 3%;
  position: absolute;
  z-index: 10;
  max-height: 240px;
  min-width: max-content;
  padding-left: 5px;
  padding-right: 20px;
  overflow-y: auto;
  display: inline-block;
}

.dropdown-option {
  position: relative;

  td {
    user-select: none;
  }
}

.checkbox {
  pointer-events: none;
}

.icon {
  position: absolute;
  right: 0;
  top: 20%;
  padding-right: 5px;
}
</style>
