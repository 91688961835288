import { ModelBase } from "./ModelBase";
import { ConsultantUrlDisplayable } from "./displayable/ConsultantUrlDisplayable";

export interface IUrlModel {
  url: string;
}
export class ConsultantUrl
  extends ModelBase<ConsultantUrlDisplayable>
  implements IUrlModel
{
  urlId: number;
  name: string;
  url: string;
  consultantName: string;
  consultantId: number;

  constructor() {
    super();
    this.urlId = 0;
    this.consultantId = 0;
    this.consultantName = "";
    this.name = "";
    this.url = "";
  }

  realize(partialModel: ConsultantUrl): ConsultantUrl {
    const newModel = new ConsultantUrl();
    newModel.urlId = partialModel.urlId;
    newModel.name = partialModel.name;
    newModel.consultantId = partialModel.consultantId;
    newModel.consultantName = partialModel.consultantName;
    newModel.url = partialModel.url;
    return newModel;
  }

  getId(): number {
    return this.urlId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): ConsultantUrlDisplayable {
    return new ConsultantUrlDisplayable(this);
  }
}
