import { IDisplayable } from "@/interfaces/Displayable";
import { TextField } from "../TextField";
import { VeaValidate } from "./VeaValidator";

export function FieldName<D extends IDisplayable>() {
  return VeaValidate<D, TextField>({
    validate(field) {
      const forbidden = /[^\p{L}\- ]|(?<!\p{L})[- ]|\P{L}$|^\P{L}/u;
      return field.value.match(forbidden) == null;
    },
    message(field) {
      return field.error.invalidNameError(field.value);
    },
  });
}
