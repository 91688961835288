import { Assignment } from "@/models/Assignment";
import { ContractPhase } from "@/models/enum/ContractPhase";
import { Month } from "@/models/enum/Months";
import store from "@/store";
import {
  MonthKey,
  monthFromKey,
  monthKey,
  monthKeyFromYearAndMonth,
  yearFromKey,
} from "@/types/DateKey";
import { monthAsDateSpan, overlaps, periodSpan } from "@/types/DateSpan";
import { avg } from "@/types/SumFields";

export function calculateHourlyRate(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number,
  options?: {
    includeProbableAssignments?: boolean;
  },
  allowFutureMonths?: boolean
): number {
  const { assignmentData, consultantData, consultantGroupData } = store.state;

  let assignments: Assignment[];
  if (consultantGroupId && consultantGroupId > 0) {
    const consultantIds = consultantData.findIds(
      "consultantGroupId",
      consultantGroupId
    );
    assignments = assignmentData.findWithValuesInSet(
      "consultantId",
      consultantIds
    );
  } else if (corporationId > 0) {
    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      corporationId
    );
    const consultantIds = consultantData.findWithValuesInSetIds(
      "consultantGroupId",
      consultantGroupIds
    );
    assignments = assignmentData.findWithValuesInSet(
      "consultantId",
      consultantIds
    );
  } else {
    assignments = assignmentData.rows;
  }

  if (options?.includeProbableAssignments) {
    assignments = assignments.filter(
      (a) =>
        a.phase == ContractPhase.ASSIGNMENT ||
        a.phase == ContractPhase.PROBABLE_ASSIGNMENT
    );
  } else {
    assignments = assignments.filter(
      (a) => a.phase == ContractPhase.ASSIGNMENT
    );
  }

  /**
   * The month to use for calculating average hourly rate.
   *
   * If the specified month is in the past, that month will be used.
   * If the specified month is in the future, the last finished month will be used.
   */
  let avgRateMonth: MonthKey;
  const currentMonth = monthKey(new Date());
  if (allowFutureMonths || currentMonth > month) {
    avgRateMonth = month;
  } else {
    const year = yearFromKey(currentMonth);
    const month = monthFromKey(currentMonth);

    if (month == Month.Jan) {
      avgRateMonth = monthKeyFromYearAndMonth(year - 1, Month.Dec);
    } else {
      avgRateMonth = monthKeyFromYearAndMonth(year, month - 1);
    }
  }

  const avgRateMonthSpan = monthAsDateSpan(avgRateMonth);
  assignments = assignments.filter((a) =>
    overlaps(periodSpan(a), avgRateMonthSpan)
  );

  return avg(assignments, "hourlyRate");
}
