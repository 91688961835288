<template>
  <div class="container">
    <BaseTable :data="state.serviceData" :options="options" />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import { VeaState } from "@/store";
import { ServiceOptions } from "@/store/data/tableDataOptions/ServiceOptions";
import { useStore } from "vuex";

defineOptions({
  name: "ServicesTable",
});
const state = useStore<VeaState>().state;
const options = new ServiceOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}
</style>
