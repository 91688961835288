import { Industry } from "@/models/Industry";
import { IndustryDisplayable } from "@/models/displayable/IndustryDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class IndustryOptions extends TableDataOptions<
  Industry,
  IndustryDisplayable
> {
  public constructor() {
    super({
      filterBoxKeys: ["name"],
    });
  }
}
