<template>
  <div style="margin: 10% auto">
    <h1>No longer in use</h1>
    <div>
      Monthly hours are now edited from the
      <router-link :to="`/monthlyReports#${id}`">Monthly Reports</router-link>
      page through <strong>Available Working Days</strong> and
      <strong>Available Hours/Day</strong>.<br />The previous Monthly Hours
      table has been deprecated, and this page will be removed in a future
      release.
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ReportDescriptors,
  getMonthlyReportHeaderId,
} from "@/store/data/types/MonthlyReport";

const id = getMonthlyReportHeaderId(
  ReportDescriptors.availableWorkingDays.header
);
</script>
