import { ServiceDisplayable } from "@/models/displayable/ServiceDisplayable";
import Service from "@/models/Service";
import { TableDataOptions } from "../TableDataOptions";

export class ServiceOptions extends TableDataOptions<
  Service,
  ServiceDisplayable
> {
  public constructor() {
    super({
      filterBoxKeys: ["name"],
    });
  }

  public override canRowBeModified(row: Service): boolean {
    return row.name !== "Internship";
  }
}
