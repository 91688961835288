import { CompetencyData } from "@/store/data/CompetencyData";
import { ConsultantCompetencyData } from "@/store/data/ConsultantCompetencyData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Competency } from "../Competency";
import { Consultant } from "../Consultant";
import { ConsultantCompetency } from "../ConsultantCompetency";
import { CompetencyDisplayable } from "./CompetencyDisplayable";
import { ConsultantDisplayable } from "./ConsultantDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueConsultantCompetency } from "./fields/validators/FieldUniqueConsultantCompetency";

export class ConsultantCompetencyDisplayable extends DisplayableBase<ConsultantCompetency> {
  @ValidateNested()
  consultantName: DropdownField<Consultant, ConsultantDisplayable>;
  @ValidateNested()
  @FieldUniqueConsultantCompetency()
  competencyName: DropdownField<Competency, CompetencyDisplayable>;

  constructor(consultantCompetency: ConsultantCompetency) {
    super();
    const consultantCompetencyData = container.resolve(
      ConsultantCompetencyData
    );
    const { headers } = consultantCompetencyData;
    const consultantData = container.resolve(ConsultantData);
    const competencyData = container.resolve(CompetencyData);

    this.consultantName = new DropdownField(
      headers.consultantName,
      consultantCompetency.getConsultantName(),
      consultantData,
      new DropdownOption(
        consultantCompetency.consultantId,
        consultantCompetency.getConsultantName()
      )
    );

    this.competencyName = new DropdownField(
      headers.competencyName,
      consultantCompetency.competencyName,
      competencyData,
      new DropdownOption(
        consultantCompetency.competencyId,
        consultantCompetency.competencyName
      )
    );
  }

  toModel(consultantCompetency: ConsultantCompetency): void {
    consultantCompetency.consultantId = this.consultantName.selectedOption.id;
    consultantCompetency.competencyId = this.competencyName.selectedOption.id;
  }
}
