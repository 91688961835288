<template>
  <div>
    <div class="container">
      <BaseTable
        :data="state.absenceData"
        :options="absenceOptions"
        :grouped="true"
      />
      <BaseTable
        :data="state.absenceCategoryData"
        :options="absenceCategoryOptions"
      />
    </div>
    <div class="sidebar">
      <TableOfContents />
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import TableOfContents from "@/components/TableOfContents.vue";
import { VeaState } from "@/store";
import { AbsenceCategoryOptions } from "@/store/data/tableDataOptions/AbsenceCategoryOptions";
import { AbsenceOptions } from "@/store/data/tableDataOptions/AbsenceOptions";
import { useStore } from "vuex";

defineOptions({ name: "AbsencesTable" });
const state = useStore<VeaState>().state;
const absenceOptions = new AbsenceOptions();
const absenceCategoryOptions = new AbsenceCategoryOptions();
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.container {
  @include tableContainer;
}

.sidebar {
  @include sidebar;
}
</style>
