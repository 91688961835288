import { TableHeaders } from "@/interfaces/TableHeaders";
import { AssignmentDisplayable } from "@/models/displayable/AssignmentDisplayable";

export class AssignmentHeaders implements TableHeaders<AssignmentDisplayable> {
  public readonly name = "Name";
  public readonly customer = "Customer";
  public readonly phase = "Phase";
  public readonly coverage = "Extent";
  public readonly hourlyRate = "Hourly Rate";
  public readonly currencyCode = "Currency";
  public readonly startDate = "Start";
  public readonly endDate = "End";
  public readonly taskmaster = "Contact";
  public readonly consultant = "Assignee";
  public readonly service = "Service";
  public readonly competencies = "Required Competencies";
  public readonly extension = "Extension";
  public readonly filesField = "Files";
  public readonly urlField = "Urls";
  public readonly description = "Description";
  public readonly workTasks = "Work Tasks";
}
