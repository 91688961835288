import { ServiceData } from "@/store/data/ServiceData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import Service from "../Service";
import { DisplayableBase } from "./DisplayableBase";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class ServiceDisplayable extends DisplayableBase<Service> {
  @ValidateNested()
  @FieldUniqueText<Service, ServiceDisplayable>()
  name: UniqueTextField<Service, ServiceDisplayable>;

  constructor(service: Service) {
    super();
    const serviceData = container.resolve(ServiceData);
    const { headers } = serviceData;
    this.name = new UniqueTextField(headers.name, service.name, serviceData);
  }

  toModel(service: Service): void {
    service.name = this.name.modelValue();
  }
}
