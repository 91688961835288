<template>
  <div
    class="container-basetable basetable"
    :id="tableData.title.replaceAll(' ', '_')"
  >
    <div class="title-section">
      <h1 cy-data="table-title">{{ tableData.title }}</h1>
    </div>
    <!-- Radio buttons and selectlists for filtering and sorting rows -->
    <div class="options-container">
      <div class="options" v-if="options">
        <div class="select-container">
          <div
            class="filter-box"
            v-if="options.filterBoxKeys && options.filterBoxKeys.length > 0"
          >
            <label for="filter-box"><strong>Filter</strong></label>
            <input type="search" id="filter-box" v-model="state.filterText" />
          </div>
          <div v-for="(dropdown, index) in state.dropdownFilters" :key="index">
            <div class="filter-box">
              <label
                ><strong>{{ dropdown.label }}</strong></label
              >
              <select
                :id="
                  dropdown.label
                    .toLocaleLowerCase()
                    .replace(/[^a-z0-9]/g, '-') + '-selector'
                "
                v-model="dropdown.selectedOption"
                @change="refilterDropdowns()"
              >
                <option
                  v-for="option in dropdown.options"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="
              options.editCompetenciesExtension != undefined ||
              options.requiredCompetenciesExtension != undefined
            "
            class="filter-box"
          >
            <label for="Competencies"><strong>Competencies</strong></label>
            <MultiSelectDropdown
              :options="state.competencies"
              :selectedOptions="state.selectedCompetencies"
              :get-name="(competency: Competency) => competency.name"
              :sorted="true"
              @toggle:selected-option="toggleSelectedCompetency($event)"
            />
          </div>
          <!-- <div
          v-if="options?.editCompetenciesExtension != undefined"
          class="filter-box"
          >
          <label for="Key Competencies">Key Competencies</label>
          <input
            type="checkbox"
            v-model="options!.editCompetenciesExtension!.keyCompetenciesOnly"
          />
        </div> -->
          <div
            v-if="options?.yearFilterExtension != undefined"
            class="filter-box"
          >
            <label>Year</label>
            <select
              :id="
                'year-select-' +
                tableData.title.toLocaleLowerCase().replace(/[^a-z0-9]/g, '-')
              "
              v-model="state.yearFilter"
            >
              <option name="Year" :key="-1" :value="-1">&lt;All&gt;</option>
              <option
                v-for="year in options.yearFilterExtension.getYears(data.rows)"
                :key="year"
                :value="year"
              >
                {{ year }}
              </option>
            </select>
          </div>
          <div class="filter-box" v-if="getHeaders().length > 1">
            <label for="View"><strong>Show/Hide Columns</strong></label>
            <MultiSelectDropdown
              :options="getHeaders()"
              :selectedOptions="state.visibleColumns"
              :get-name="(header: string) => header"
              @toggle:selected-option="toggleSelectedColumn($event)"
            />
          </div>
        </div>
        <div class="radio-container">
          <div class="filter-container">
            <div
              class="radio-box filter-box"
              v-for="(f, j) in options.radioFilters"
              :key="f.filterLabel"
            >
              <strong>{{ f.filterLabel }}</strong>
              <div v-for="v in f.filterSettings" :key="v">
                <label>
                  <input
                    :id="'radio-' + f.filterLabel.toLocaleLowerCase()"
                    :name="f.filterLabel + '_' + tableData.title"
                    type="radio"
                    :value="v"
                    v-model="state.radioFilters[j]"
                    :checked="v == f.filterSettings[0]"
                  />
                  <span class=""> {{ v }}</span>
                </label>
              </div>
            </div>
          </div>
          <div
            class="radio-box sort-box"
            v-if="options.sorting && options.getSortingOptionsCount() > 1"
          >
            <strong>Sort by</strong>
            <div v-for="(_value, key) in options.sorting" :key="key">
              <label>
                <input
                  :id="`radio-sort-${tableData.title.toLocaleLowerCase()}-${key}`"
                  :name="tableData.title"
                  type="radio"
                  :value="key"
                  v-model="state.sort"
                />
                <span class="">{{ key }}</span>
              </label>
            </div>
          </div>
          <div
            class="radio-box sort-box"
            v-if="options.sorting && options.getSortingOptionsCount() > 1"
          >
            <strong>Sort direction</strong>
            <div>
              <label>
                <input
                  :id="'radio-sort-direction-ascending'"
                  type="radio"
                  :value="true"
                  v-model="state.sortAscending"
                />
                <span class="">Ascending</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  :id="'radio-sort-direction-descending'"
                  type="radio"
                  :value="false"
                  v-model="state.sortAscending"
                />
                <span class="">Descending</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <TableButtonAdd
          cy-data="tbl-btn-add"
          v-if="canEdit && isAuthorizedToEdit"
          :onClick="addRow"
        >
          Add {{ tableData.titleSingular }}
        </TableButtonAdd>
      </div>
      <br />
      <div class="button-container">
        <TableButtonAdd icon="download" @click="saveAsCsv"
          >Download Table</TableButtonAdd
        >
      </div>
    </div>
    <div class="tablewrapper flipped">
      <table
        class="table table-hover"
        :class="{ summaryTable: options.summaryRowExtension != undefined }"
      >
        <thead v-if="options.summaryRowExtension == undefined">
          <tr>
            <td v-if="canEdit && isAuthorizedToEdit" />
            <template
              v-for="({ key, value: header }, j) in getHeaderKeyValuePairs()"
              :key="key"
            >
              <td
                v-if="
                  !keysEqual(key, options.groupedByKey) &&
                  state.visibleColumns[j]
                "
                @click="handleSortColumn(key)"
                :class="{
                  'sortable-header': isColumnSortable(key),
                  'non-filterable-header': isColumnNotFilterable(key),
                }"
                style="text-align: left"
              >
                <b>{{ header }} </b>
                <fa-icon
                  :icon="['fas', 'sort']"
                  class="column-sort-indicator"
                  v-if="isColumnSortable(key) && !isColumnSelectedForSort(key)"
                />
                <fa-icon
                  :icon="['fas', 'sort-down']"
                  class="column-sort-indicator"
                  v-if="isColumnSelectedForSort(key) && !state.sortAscending"
                />
                <fa-icon
                  :icon="['fas', 'sort-up']"
                  class="column-sort-indicator"
                  v-if="isColumnSelectedForSort(key) && state.sortAscending"
                />
              </td>
            </template>
          </tr>
        </thead>
        <tbody v-for="group in groupedRows.keys()" :key="group">
          <tr v-if="options?.groupedByKey">
            <th :colspan="columnCount + 1">{{ group }}</th>
          </tr>
          <tr v-if="options?.summaryRowExtension != undefined">
            <td v-if="canEdit && isAuthorizedToEdit" />
            <template
              v-for="({ key, value: header }, j) in getHeaderKeyValuePairs()"
              :key="key"
            >
              <td
                v-if="
                  !keysEqual(key, options.groupedByKey) &&
                  state.visibleColumns[j]
                "
                :class="{
                  'non-filterable-header': isColumnNotFilterable(key),
                }"
              >
                <b>{{ header }}</b>
              </td>
            </template>
          </tr>
          <tr
            v-for="row in groupedRows.get(group)"
            :class="{
              strikethrough: options?.summaryRowExtension?.disableRow(row),
            }"
            :key="row.getId()"
            :id="`${row.getId()}`"
            tabindex="0"
            @mouseenter="showSummary(row.getId())"
            @mouseleave="hideSummary()"
          >
            <template
              v-if="
                canEdit &&
                isAuthorizedToEdit &&
                !options?.summaryRowExtension?.disableRow(row) &&
                options.canRowBeModified(row)
              "
            >
              <td class="actions">
                <span class="btns">
                  <TableButtonRowAction
                    v-if="canDelete"
                    class="del"
                    title="Delete"
                    cy-data="row-delete"
                    icon="minus-circle"
                    :onClick="deleteRow"
                    :id="row.getId()"
                  />
                  <TableButtonRowAction
                    class="primary"
                    title="Edit"
                    icon="pencil-alt"
                    cy-data="row-edit"
                    :onClick="editRow"
                    :id="row.getId()"
                  />
                  <TableButtonRowAction
                    v-if="canExtend && isIndividuallyExtendable(row)"
                    class="primary"
                    title="Extend"
                    icon="plus"
                    cy-data="row-extend"
                    :onClick="addExtension"
                    :id="row.getId()"
                  />
                  <TableButtonRowAction
                    v-if="canActivate"
                    class="primary"
                    title="Send activation email"
                    icon="envelope-open-text"
                    cy-data="activation-email"
                    :onClick="setToActivate"
                    :id="row.getId()"
                  />
                  <TableButtonRowAction
                    v-if="
                      options?.editCompetenciesExtension &&
                      options.editCompetenciesExtension.canHaveCompetencies(row)
                    "
                    class="primary"
                    title="Edit Competencies"
                    icon="tasks"
                    cy-data="edit-competencies"
                    :onClick="editCompetencies"
                    :id="options.editCompetenciesExtension.getUserId(row)"
                  />
                </span>
              </td>
            </template>
            <td v-else-if="canEdit && isAuthorizedToEdit" />
            <template
              v-for="(field, key, i) in getFields(row.getDisplayable())"
              :key="key"
            >
              <template
                v-if="
                  key != options?.groupedByKey &&
                  field.hideInTable == false &&
                  state.visibleColumns[i]
                "
              >
                <td
                  v-if="
                    field.type !== FieldType.URL &&
                    field.type !== FieldType.FILES &&
                    field.type !== FieldType.URLFILES &&
                    field.type !== FieldType.TEXT_AREA
                  "
                  :class="isNumericField(field.type) ? 'right' : 'left'"
                >
                  <span v-if="field.prefix">{{ field.prefix }}</span>
                  <span v-html="field.value"></span>
                  <span v-if="field.suffix">{{ field.suffix }}</span>
                </td>
                <td
                  class="text-area left"
                  v-else-if="field.type === FieldType.TEXT_AREA"
                >
                  <p
                    v-html="checkTextSizeAndReturn(field.value, row.getId(), i)"
                  ></p>
                </td>
                <td
                  v-else-if="
                    field.type === FieldType.URL && field instanceof UrlField
                  "
                >
                  <router-link :to="field.url">
                    {{ field.value }}
                  </router-link>
                </td>
                <td
                  v-else-if="
                    field.type === FieldType.FILES &&
                    field instanceof FilesField
                  "
                >
                  <FileContainer
                    :folder="field.folder"
                    :id="options?.filesExtension?.getFileId(row) ?? field.id"
                    :key="key"
                    :files-promise="options?.filesExtension?.filesPromise"
                  />
                </td>
                <td
                  v-else-if="
                    field.type === FieldType.URLFILES &&
                    field instanceof FilesUrlField
                  "
                >
                  <UrlContainer
                    :id="field.id"
                    :canEdit="canEdit"
                    :dataType="field.urlType"
                  />
                </td>
              </template>
            </template>
          </tr>
          <tr
            class="table-summary summary-part"
            v-if="options?.summaryRowExtension != undefined"
          >
            <th
              :colspan="
                columnCount -
                options.summaryRowExtension.headers.length -
                (canEdit && isAuthorizedToEdit ? 1 : 0)
              "
            >
              Summary
            </th>
            <template
              v-for="(sum, key) in options.summaryRowExtension.getSums(
                groupedRows.get(group) ?? []
              )"
              :key="key"
            >
              <th>{{ sum }}</th>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <table class="table table-hover summaryTable">
      <tbody
        class="table-summary"
        v-if="
          options?.summaryRowExtension != undefined &&
          state.dropdownFilters[1].selectedOption == -1
        "
      >
        <tr>
          <th :colspan="options.summaryRowExtension.headers.length">
            Full Summary
          </th>
          <template
            v-for="(name, key) in options.summaryRowExtension.headers"
            :key="key"
          >
            <th>{{ name }}</th>
          </template>
        </tr>
        <tr class="final-row">
          <td :colspan="options.summaryRowExtension.headers.length" />
          <template
            v-for="(sum, key) in options.summaryRowExtension.getSums(
              filteredRows
            )"
            :key="key"
          >
            <th>{{ sum }}</th>
          </template>
        </tr>
      </tbody>
    </table>
    <table-button-add
      :onClick="addRow"
      class="row-btn-add"
      v-if="canEdit && isAuthorizedToEdit"
    >
      Add {{ tableData.titleSingular }}
    </table-button-add>
  </div>

  <BaseModalCompetencies
    v-if="store.state.competenciesViewUserId"
    :user-id="store.state.competenciesViewUserId"
  />

  <BaseModalForm
    :data="tableData"
    :action="state.action"
    :submitEvent="onFormSubmit"
    v-if="data.isModifying() && state.toggle.enabled"
    :toggle="state.toggle as ModalToggle"
  />

  <BaseModal
    :accept="
      () => {
        state.showAfterAddMessage = false;
      }
    "
    :abort="
      () => {
        state.showAfterAddMessage = false;
      }
    "
    :danger="false"
    :title="'Added a ' + tableData.titleSingular"
    modalIcon="info-circle"
    accepttitle="Ok"
    aborttitle="Cancel"
    v-if="state.showAfterAddMessage"
  >
    {{ afterAddMessage }}
  </BaseModal>

  <BaseModalDelete :data="tableData" v-if="data.isDeleting()" />

  <BaseModal
    title="Send activation email"
    modalIcon="envelope"
    :accept="
      () => {
        if (data instanceof UserData) {
          data.sendActivationEmail();
        }
      }
    "
    :abort="
      () => {
        if (data instanceof UserData) {
          data.resetToDefault();
        }
      }
    "
    :danger="false"
    accepttitle="Send"
    aborttitle="Cancel"
    v-if="data instanceof UserData && data.isAboutToSendActivationEmail"
  >
    <p>
      Are you sure you want to send an activation email to user
      <strong>{{ data.userToActivate.getName() }}</strong> with email
      <strong>{{ data.userToActivate.email }}</strong
      >?
    </p>
  </BaseModal>
  <div id="tooltip"></div>
</template>

<script
  setup
  lang="ts"
  generic="M extends Model<D>, D extends Displayable<M> & IterableFields<D, IDisplayableField> & PropsOf<D, IDisplayableField>"
>
import TableButtonRowAction from "@/components/TableButtonRowAction.vue";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { Assignment } from "@/models/Assignment";
import { Competency } from "@/models/Competency";
import { Customer } from "@/models/Customer";
import { IDisplayableField } from "@/models/displayable/fields/DisplayableField";
import { FilesField } from "@/models/displayable/fields/FilesField";
import { FilesUrlField } from "@/models/displayable/fields/FilesUrlField";
import { UrlField } from "@/models/displayable/fields/UrlField";
import { FieldType } from "@/models/displayable/fields/enum/FieldType";
import { ContractPhase } from "@/models/enum/ContractPhase";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import { AssignmentData } from "@/store/data/AssignmentData";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { CorporationData } from "@/store/data/CorporationData";
import { TableData } from "@/store/data/TableData";
import { TableDataOptions } from "@/store/data/TableDataOptions";
import { UserData } from "@/store/data/UserData";
import { Action } from "@/store/data/enum/Action";
import { BaseTableFilterMode } from "@/store/data/enum/BaseTableFilterMode";
import { BackendErrorSet } from "@/store/data/error/BackendErrorSet";
import UpdateKey from "@/store/data/types/UpdateKey";
import { IterableFields } from "@/types/IterableFields";
import { KeyTo } from "@/types/KeyTo";
import { ModalToggle } from "@/types/ModalToggle";
import { PropsOf } from "@/types/PropsOf";
import {
  castKey,
  getEntriesAs,
  getKeysAs,
  getProp,
  getValues,
  keysEqual,
} from "@/types/getProp";
import { downloadBlob } from "@/util/DownloadHelper";
import { EventType } from "@/util/EventEmitter";
import { SummaryGenerator } from "@/util/SummaryGenerator";
import { container } from "tsyringe";
import { computed, onBeforeMount, reactive } from "vue";
import BaseModal from "./BaseModal.vue";
import BaseModalCompetencies from "./BaseModalCompetencies.vue";
import BaseModalDelete from "./BaseModalDelete.vue";
import BaseModalForm from "./BaseModalForm.vue";
import FileContainer from "./FileContainer.vue";
import MultiSelectDropdown from "./MultiSelectDropdown.vue";
import TableButtonAdd from "./TableButtonAdd.vue";
import UrlContainer from "./UrlContainer.vue";

export interface Props<
  M extends Model<D>,
  D extends Displayable<M> &
    IterableFields<D, IDisplayableField> &
    PropsOf<D, IDisplayableField>
> {
  data: TableData<M, D>;
  canEdit?: boolean;
  canDelete?: boolean;
  canExtend?: boolean;
  canActivate?: boolean;
  options: TableDataOptions<M, D>;
  grouped?: boolean;
  tooltip?: boolean;
  user?: boolean;
  afterAddMessage?: string;
}

const props = withDefaults(defineProps<Props<M, D>>(), {
  canEdit: true,
  canDelete: true,
  canExtend: false,
  canActivate: false,
  grouped: false,
  tooltip: false,
  user: false,
  afterAddMessage: undefined,
});

props.options?.filesExtension?.setFileDetails();

function editRow(rowId: number) {
  state.action = Action.Edit;
  store.state.connectionRowId = rowId;
  props.data.edit(rowId);
  state.toggle.show();
}

function addRow() {
  state.action = Action.Add;
  props.data.add();
  state.toggle.show();
}

function addExtension(rowId: number) {
  state.action = Action.Extend;
  if (props.data instanceof AssignmentData) {
    props.data.extend(rowId);
    state.toggle.show();
  }
}

function deleteRow(rowId: number) {
  props.data.delete(rowId);
}

function setToActivate(rowId: number) {
  if (props.data instanceof UserData) props.data.setToActivate(rowId);
}

function editCompetencies(userId: number) {
  store.state.competenciesViewUserId = userId;
}

function isNumericField(type: FieldType) {
  return type === FieldType.NUMERIC || type === FieldType.PERCENTAGE;
}

function onFormSubmit() {
  const backend = container.resolve(BackendErrorSet);
  state.showAfterAddMessage =
    !props.data.hasValidationErrors() &&
    !backend.hasErrors &&
    state.action === Action.Add &&
    props.afterAddMessage != undefined;
}

function showSummary(id: number) {
  if (!props.tooltip) return;
  const rect = document.getElementById(id.toString())?.getBoundingClientRect();
  if (!rect) return;
  const element = document.getElementById("tooltip");
  if (!element) return;
  const data = props.data.findById(id);
  if (!(data instanceof Customer)) {
    return;
  }
  const summaryGenerator = new SummaryGenerator();
  element.innerHTML = summaryGenerator.generateCustomerSummary(data);
  const x = rect.left + document.body.scrollLeft + rect.width / 2;
  let y = rect.bottom;
  const summaryRows = (element.innerHTML.match(/<br/g) || []).length + 1;
  //h is the enstimated height of the summary box
  const h = summaryRows * 18 + 8;
  const height = document.documentElement.clientHeight - h;
  //If the summary would fall outside the window, moves it up
  if (y > height) y = height;
  element.style.display = "block";
  element.style.top = y + "px";
  element.style.left = x + "px";
}

function hideSummary() {
  const element = document.getElementById("tooltip");
  if (element != null) {
    element.style.display = "none";
    element.childNodes.forEach((node) => node.remove());
  }
}

function saveAsCsv() {
  const separator = ";";
  const headers = getHeaders()
    .filter((_, i) => state.visibleColumns[i])
    .join(separator);
  const csv: string[] = [`${headers}\n`];
  for (const row of filteredRows.value) {
    const fields = getFields(row.getDisplayable());
    const line = getValues<D, IDisplayableField>(fields)
      .filter((_, i) => state.visibleColumns[i])
      .map(getCsvValue)
      .join(separator);
    csv.push(`${line}\n`);
  }

  // make a string of the csv contents to be able to add the BOM before it in the blob
  let csvString = "";
  for (const row of csv) {
    csvString += row;
  }
  const BOM_UTF8 = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([BOM_UTF8, csvString], { type: "text/csv" });
  downloadBlob(blob, `VEA_${props.data.title}.csv`);
}

function getCsvValue(field: IDisplayableField) {
  if (field.type == FieldType.URLFILES || field.type == FieldType.FILES) {
    return "N/A";
  } else {
    return field.value;
  }
}

function isIndividuallyExtendable(row: M): boolean {
  if (!(row instanceof Assignment)) {
    return false;
  }
  return (
    row.phase != ContractPhase.INTERNSHIP &&
    row.phase != ContractPhase.NON_BILLABLE
  );
}

function refilterDropdowns() {
  state.dropdownFilters?.forEach((filter) => filter.refilter());
  state.dropdownUpdateKey.refresh();
}

function handleSortColumn(fieldKey: KeyTo<D, IDisplayableField>) {
  if (!props.options.sorting) {
    return;
  }
  for (const key in props.options.sorting) {
    const option = props.options.sorting[key];
    if (typeof option !== "object" || option.column != fieldKey) {
      continue;
    }
    if (state.sort == key) {
      state.sortAscending = !state.sortAscending;
    } else {
      state.sort = key;
      state.sortAscending = false;
    }
    return;
  }
}

function isColumnSortable(fieldKey: KeyTo<D, IDisplayableField>): boolean {
  if (!props.options.sorting) {
    return false;
  }
  for (const key in props.options.sorting) {
    const option = props.options.sorting[key];
    if (typeof option === "object" && option.column == fieldKey) {
      return true;
    }
  }
  return false;
}

function isColumnSelectedForSort(key: KeyTo<D, IDisplayableField>): boolean {
  if (!props.options.sorting) {
    return false;
  }
  const option = props.options.sorting[state.sort];
  return typeof option === "object" && option.column == key;
}

const columnCount = computed(
  () =>
    Object.keys(props.data.headers).length - props.options.hiddenFields.length
);

function isColumnNotFilterable(key: KeyTo<D, IDisplayableField>): boolean {
  return (
    state.filterText != "" &&
    !props.options.filterBoxKeys?.includes(castKey(key), 0)
  );
}

const groupedRows = computed(() => {
  const map = new Map<string, M[]>();
  const rows = filteredRows.value;

  if (!props.options?.groupedByKey || rows.length == 0) {
    map.set("", rows);
    return map;
  }
  for (const row of rows) {
    const { value } = getProp(row.getDisplayable(), props.options.groupedByKey);
    let group = map.get(value);
    if (group === undefined) {
      group = [];
      map.set(value, group);
    }
    group.push(row);
  }
  return map;
});

const filteredRows = computed(() => {
  state.dropdownUpdateKey.check();

  if (!props.options) {
    return props.data.rows;
  }

  let r = props.options.filterAndSortRows(
    props.data.rows,
    state.radioFilters,
    state.yearFilter,
    state.sort,
    state.competencies?.filter(
      (_competency, index) => state.selectedCompetencies[index] == true
    )
  );

  const { filterBoxKeys } = props.options;

  if (filterBoxKeys && filterBoxKeys.length > 0 && state.filterText !== "") {
    const filterString = state.filterText.toLocaleLowerCase();

    r = r.filter((r) => {
      const d = r.getDisplayable();
      return filterBoxKeys.some((filter) =>
        getProp(d, filter).value.toLocaleLowerCase().includes(filterString)
      );
    });
  }

  if (state.sortAscending) {
    r.reverse();
  }

  return r;
});

const state = reactive({
  action: Action.None,
  yearFilter: -1,
  customers: new Array<Customer>(),
  radioFilters: props.options?.radioFilters.map((x) => x.filterSettings[0]) || [
    BaseTableFilterMode.ALL,
  ],
  dropdownFilters: props.options?.dropdownFilters ?? [],
  sort: props.options?.getDefaultSoringOption() ?? 0,
  competencies: props.options?.competencies,
  selectedCompetencies: [false],
  visibleColumns: getHeaders().map(() => true),
  showAfterAddMessage: false,
  dropdownUpdateKey: new UpdateKey(),
  filterText: "",
  sortAscending: false,
  toggle: new ModalToggle(),
});

function toggleSelectedCompetency(i: number) {
  state.selectedCompetencies[i] = !state.selectedCompetencies[i];
}

function toggleSelectedColumn(i: number) {
  state.visibleColumns[i] = !state.visibleColumns[i];
}

onBeforeMount(() => {
  store.state.events.subscribeOrRunIfDispatched(
    EventType.FinishedLoadingTableData,
    onFinishedLoading
  );
});

async function onFinishedLoading() {
  state.dropdownFilters.forEach((filter) => filter.refilter());
  const { corporationId, consultantGroupId } = store.state.settings;
  for (const filter of state.dropdownFilters) {
    if (filter.data instanceof CorporationData)
      filter.selectedOption = corporationId;
    if (filter.data instanceof ConsultantGroupData) {
      filter.selectedOption = consultantGroupId;
    }
    filter.refilter();
  }
  await props.options?.setCompetencies();
  state.competencies = props.options?.competencies ?? [];
  state.selectedCompetencies = new Array<boolean>(state.competencies.length);
}

function getFields(displayable: D): D {
  const obj: Partial<D> = {};
  for (const key of getKeysAs(props.data.headers)) {
    if (!isHidden(key)) {
      obj[key] = displayable[key];
    }
  }
  return obj as D;
}

function getHeaders(): string[] {
  return getKeysAs(props.data.headers)
    .filter((k) => !isHidden(k))
    .map((k) => props.data.headers[k]);
}

function getHeaderKeyValuePairs() {
  return getEntriesAs(props.data.headers).filter((kv) => !isHidden(kv.key));
}

function isHidden(key: KeyTo<D, IDisplayableField>) {
  return props.options.hiddenFields.includes(key);
}

const isAuthorizedToEdit = computed(() =>
  container.resolve(AuthorizationManager).editBaseTable.isAuthorized()
);

const tableData = computed(() => props.data);

const sizeCap = 80;
let prevText = "";

//fullText is a string but field.value is not defined as a string. So eslint complains that I use any.
// eslint-disable-next-line
function checkTextSizeAndReturn(fullText: any, rowId: number, fieldId: number) {
  if (typeof fullText != "string") return;
  const field = findElement(rowId, fieldId);
  if (prevText != fullText && field) {
    Array.from(field.children).forEach((element) => {
      if (
        element.classList.contains("expandText") ||
        element.classList.contains("collapseText")
      ) {
        element.remove();
        if (field.firstChild && fullText.length > sizeCap) {
          field.firstChild.textContent = fullText.substring(0, sizeCap);
        }
      }
      field.classList.remove("hasButton");
      prevText = fullText;
    });
  }

  if (fullText.length > sizeCap) {
    if (field) {
      //To prevent a box from having two or more buttons which happens without this check.
      if (!field.classList.contains("hasButton")) {
        createButton(true, fullText, field);
      }
      return fullText.substring(0, sizeCap);
    }
  }
  return fullText;
}

function collapseAndExpandText(
  self: HTMLButtonElement,
  orgString: string,
  fieldElement: Element,
  expand: boolean
) {
  self.remove();
  createButton(!expand, orgString, fieldElement);
  if (fieldElement.firstChild) {
    if (expand) {
      fieldElement.firstChild.textContent = orgString;
    } else {
      fieldElement.firstChild.textContent = orgString.substring(0, sizeCap);
    }
  }
}

function createButton(
  isExpand: boolean,
  orgString: string,
  textSquare: Element
) {
  const newButton = document.createElement("button");

  if (isExpand) {
    newButton.textContent = "...";
    newButton.classList.add("expandText");
    newButton.onclick = function () {
      collapseAndExpandText(newButton, orgString, textSquare, true);
    };
  } else {
    newButton.textContent = "show less";
    newButton.classList.add("collapseText");
    newButton.onclick = function () {
      collapseAndExpandText(newButton, orgString, textSquare, false);
    };
    newButton.style.fontSize = "12px";
  }

  newButton.style.color = "#1c1b1ced";
  newButton.style.height = "0";
  newButton.style.padding = "0";
  newButton.style.border = "0";

  //Attach button to square
  textSquare.appendChild(newButton);
  textSquare.classList.add("hasButton");
}

function findElement(rowId: number, fieldId: number) {
  const row = document.getElementById(rowId.toString());
  if (row) {
    const textSquare = row.children[fieldId + 1];
    return textSquare;
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
$container-bgc: $container-bgc;
$row-br: 5px;
$row-odd: $table-row-primary-color;
$row-even: $table-row-alternate-color;
$rounded-row-left: $row-br 0 0 $row-br;
$rounded-row-right: 0 $row-br $row-br 0;

.container-basetable {
  @include containertheme($overflow: unset);
  padding-top: 10px;
  margin-top: 10px;
  background: none;
  max-width: 95vw;
  align-items: center;
  flex-direction: column;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: $base-text-color;

  .text-area {
    min-width: 16rem;
    max-width: 32rem;
    white-space: normal;
    word-wrap: break-word;
  }

  .title-section {
    @include title-section($text-align: left);
    margin-bottom: 1rem;

    h1 {
      margin-bottom: 0;
      margin-right: 15px;
    }

    button {
      @include button($margin-bottom: 4px, $display: initial);
      align-self: self-end;
    }

    button:hover {
      background-color: $color-darkgreen;
    }
  }
}

.options-container {
  @include baseTableOptionsContainer();
  overflow: visible;
  background-color: $transparent-menu-color;

  .button-container {
    padding-left: 2em;
    white-space: nowrap;
  }
}

.flipped,
.flipped .table {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg); // IE 9
  -webkit-transform: rotateX(180deg); // Safari and Chrome
}

.options {
  @include options($border: 1px, $border-radius: 20px);
  position: relative;
  flex-wrap: wrap;
  overflow: visible;
  left: 100;
}

.options > div {
  margin: 10px;

  .options {
    margin: 10px;
  }
}

.filter-box,
.sort-box {
  @include sort-box();
  @include form-field;
  width: unset;
  padding-right: 10px;

  strong {
    width: 100%;
    text-align: center;
  }
}

.select-container,
.radio-container {
  @include select-radio-containers();
}

.select {
  @include select();
}

.filter-container {
  border-radius: 4px;
  display: flex;
}

.radio-box {
  margin: 5px;

  input[type="radio"] {
    margin-right: 5px;
  }
}

.radio-box > * {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tablewrapper {
  @include tablewrapper($width: max-content);
  overflow: auto;
  max-width: 95vw;
}

.summaryTable {
  display: flex;
  white-space: nowrap;

  tbody {
    padding: 5px;
  }

  .row-btn-add {
    padding: 0.5rem;
    width: 100%;
    text-align: left;
    margin-top: 5px;
  }
}

table {
  @include table();

  thead {
    @include thead();
    border-style: none;

    td:first-child {
      width: 0;
    }

    td {
      background-color: $vea-primary-color;
      color: $base-text-color;
    }

    td:hover {
      color: $color-white;
    }
  }

  .td-center {
    vertical-align: middle;
  }

  tr {
    @include tr();

    &:first-child {
      user-select: none;
    }

    &:nth-child(odd) {
      background-color: $table-row-primary-color;
    }

    &:nth-child(even) > td {
      background-color: $table-row-alternate-color;
      border-right: 1px solid $color-white;
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid $vea-primary-color;
    }

    &.strikethrough {
      color: $color-grey;

      td {
        position: relative;
        border-color: $color-black;
      }

      td:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        border-bottom: 1px dashed $color-grey;
        width: 100%;
      }
    }

    td {
      white-space: nowrap;
      border-width: 0px;
      border-right: 1px solid $color-white;

      &:first-child {
        border-radius: $rounded-row-left;
      }

      &:last-child {
        border-radius: $rounded-row-right;
        border-right: none;
      }

      &.sortable-header {
        cursor: pointer;
      }

      .column-sort-indicator {
        margin-left: 10px;
      }

      &:hover > .column-sort-indicator {
        color: $color-darkgrey;
      }

      &.non-filterable-header {
        filter: brightness(50%);
      }
    }
  }

  .center {
    @include center();
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .btns {
    visibility: hidden;
  }

  tr:hover,
  tr:focus,
  tr:focus-within {
    .btns {
      visibility: visible;
      transform: scaleY(100%);
      height: unset;
      overflow: visible;
      opacity: 1;
    }
  }

  td > .accordion {
    width: max-content;
    max-width: 360px;
    margin-bottom: 0;

    .accordion-item {
      .accordion-collapse {
        background-color: $color-white;
        z-index: 7960;
        position: relative;
        box-sizing: content-box;
      }
    }
  }

  .actions {
    .btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      top: -31%;
      background: $color-lightgrey;
      border: 1px solid $border-color;
      border-radius: 15px;
      z-index: 1000;
      padding: 1px 0;
      height: 0;
      transform: scaleY(15%);
      opacity: 0;
      overflow: hidden;
      transition: opacity 300ms ease, transform 100ms ease;
      margin-top: -100%;
      margin-bottom: -100%;
    }
  }
}

.table-summary {
  text-align: center;

  th:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.summary-part {
  border-color: $color-black;
}

.row-btn-add {
  padding: 0.5rem;
  width: 100%;
  text-align: left;
  background-color: $table-row-alternate-color;
  margin-top: 2px;
  color: $base-text-color;

  svg {
    margin-right: 5px;
  }
}

.btnTable {
  display: flex;
  width: auto;
  white-space: nowrap;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

#tooltip {
  color: $color-black;
  background: $color-grey;
  min-width: 150px;
  position: fixed;
  display: none;
  padding: 3px 6px;
  font-size: 12px;
  transform: translateX(-50%);
  border: 1px solid $color-black;
  border-radius: 5px;
  pointer-events: none;
}
</style>
