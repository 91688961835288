import { BackendCommunicator } from "@/interfaces/BackendCommunicator";
import { BackendErrorReporter } from "@/interfaces/BackendErrorReporter";
import { ConsultantCompetency } from "@/models/ConsultantCompetency";
import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Corporation } from "@/models/Corporation";
import { Customer } from "@/models/Customer";
import { Location } from "@/models/Location";
import { Priority } from "@/models/Priority";
import Service from "@/models/Service";
import { Taskmaster } from "@/models/Taskmaster";
import store from "@/store";
import { ReportSetting } from "@/store/ReportSetting";
import { AbsenceData } from "@/store/data/AbsenceData";
import { AssignmentData } from "@/store/data/AssignmentData";
import { AssignmentUrlData } from "@/store/data/AssignmentUrlData";
import { ConsultantCompetencyData } from "@/store/data/ConsultantCompetencyData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { ConsultantUrlData } from "@/store/data/ConsultantUrlData";
import { CorporationData } from "@/store/data/CorporationData";
import { CustomerData } from "@/store/data/CustomerData";
import { LocationData } from "@/store/data/LocationData";
import { PriorityData } from "@/store/data/PriorityData";
import { ReportSettingsData } from "@/store/data/ReportSettingsData";
import { ServiceData } from "@/store/data/ServiceData";
import { TaskmasterData } from "@/store/data/TaskmasterData";
import { UserData } from "@/store/data/UserData";
import { DateUtils } from "@/util/DateUtils";
import { container, singleton } from "tsyringe";
import { ConsultantReportEntry } from "./ConsultantReportEntry";
import { GanttGenerator } from "./GanttGenerator";
import { GanttOptions } from "./GanttOptions";
import ReportFilterOptions from "./ReportFilterOptions";
import { AssignmentFilterType } from "./enum/AssignmentFilterType";
import { ConsultantGroupFilterType } from "./enum/ConsultantGroupFilterType";
import { LocationFilterType } from "./enum/LocationFilterType";
import { SortingMode } from "./enum/SortingMode";
import { createConsultantReportEntries } from "./util/createConnectionEntries";

/**
 * The driver behind the Gantt-diagram. Contains data and operations for filtering displayed data
 * and redrawing the diagram.
 */
@singleton()
export class ReportData {
  options: ReportFilterOptions;
  primarySort: SortingMode | undefined;
  secondarySort: SortingMode;
  monthsAhead: number;

  _startDate: Date;
  _endDate: Date;

  private _settingsData: ReportSettingsData;
  // ----------------------------------------

  private dateUtils: DateUtils;

  private _corporations: Corporation[];
  private _customers: Customer[];
  private _consultantCompetencies: ConsultantCompetency[];
  private _priorities: Priority[];
  private _taskmasters: Taskmaster[];
  private _consultantGroups: ConsultantGroup[];
  private _locations: Location[];
  private _services: Service[];
  private _consultantReportEntries: ConsultantReportEntry[];
  private _minDate: Date;

  private ganttOptions: GanttOptions;
  private ganttGenerator: GanttGenerator;

  private tableDatas: (BackendErrorReporter | BackendCommunicator)[];

  constructor() {
    this.dateUtils = container.resolve(DateUtils);
    this._settingsData = container.resolve(ReportSettingsData);
    this._minDate = this.dateUtils.stringToDate("2000-00-01");

    const settings = this._settingsData.loadReportSettings();

    this.options = settings.options;
    this.primarySort = settings.primarySort;
    this.secondarySort = settings.secondarySort;
    this.monthsAhead = settings.monthsAhead;

    this._startDate = this.dateUtils.stringToDate(settings.startDate);

    this._endDate = this.dateUtils.addOneDayToDate(
      this.dateUtils.stringToDate(settings.endDate)
    );

    this._corporations = [];
    this._customers = [];
    this._consultantCompetencies = [];
    this._priorities = [];
    this._taskmasters = [];
    this._consultantGroups = [];
    this._locations = [];
    this._services = [];
    this._consultantReportEntries = [];

    this.ganttOptions = new GanttOptions();
    this.ganttGenerator = new GanttGenerator(this.ganttOptions);

    this.dateUtils = container.resolve(DateUtils);

    // This should contain all of the data tables that the report uses
    this.tableDatas = [
      container.resolve(AbsenceData),
      container.resolve(ConsultantData),
      container.resolve(CorporationData),
      container.resolve(TaskmasterData),
      container.resolve(CustomerData),
      container.resolve(PriorityData),
      container.resolve(ConsultantGroupData),
      container.resolve(ServiceData),
      container.resolve(UserData),
      container.resolve(LocationData),
      container.resolve(AssignmentData),
      container.resolve(ConsultantCompetencyData),
      container.resolve(ConsultantUrlData),
      container.resolve(AssignmentUrlData),
    ];
  }

  isEmpty(): boolean {
    return this.ganttGenerator.isRowsEmpty;
  }

  getStartDate(): Date {
    const nowExact = new Date(Date.now());
    return new Date(
      nowExact.getFullYear(),
      nowExact.getMonth(),
      nowExact.getDate()
    );
  }

  getEndDate(): Date {
    if (!this._startDate) {
      console.error("startDate undefined");
    }
    return new Date(
      this._startDate.getFullYear(),
      this._startDate.getMonth() + this.monthsAhead,
      this._startDate.getDate() + 1
    );
  }

  defaultOptions(): void {
    this.options.customerFilter = AssignmentFilterType.SHOW_ALL;
    this.options.taskmasterFilter = AssignmentFilterType.SHOW_ALL;
    this.primarySort = undefined;
    this.secondarySort = SortingMode.CONSULTANT;
    this.monthsAhead = 3;
    this._startDate = this.getStartDate();
    this._endDate = this.getEndDate();
  }

  refilterByCustomer(): void {
    this.options.taskmasterFilter = AssignmentFilterType.SHOW_ALL;
    this.refilter();
  }

  refilterByTaskmaster(): void {
    this.refilter();
  }

  refilterByService(): void {
    this.refilter();
  }

  refilterByCorporation(): void {
    this.options.consultantGroupFilter = ConsultantGroupFilterType.SHOW_ALL;
    this.options.locationFilter = LocationFilterType.SHOW_ALL;
    this.refilter();
  }

  refilterByConsultantGroup(): void {
    this.refilter();
  }

  refilterByLocation(): void {
    this.options.consultantGroupFilter = ConsultantGroupFilterType.SHOW_ALL;
    this.refilter();
  }

  refilterByPhase(): void {
    this.refilter();
  }

  updateStartDate(s: string): void {
    this.startDate = s;
    this.refilter();
  }
  updateEndDate(s: string): void {
    this.endDate = s;
    this.refilter();
  }

  refilter(): void {
    if (this.monthsAhead != 0) {
      this._startDate = this.getStartDate();
      this._endDate = this.getEndDate();
    }

    if (this.hasValidDates(this._startDate, this._endDate)) {
      this.saveSettings();
      this.setCorporations();
      this.setTaskmasters();
      this.setCustomers();
      this.setConsultantCompetencies();
      this.setPriorities();
      this.setConsultantGroups();
      this.setLocations();
      this.setServices();
      this.drawGantt();
    }
  }

  private hasValidDates(start: Date, end: Date) {
    const isValidStart = start instanceof Date && !isNaN(start.valueOf());
    const isValidEnd = end instanceof Date && !isNaN(end.valueOf());
    return isValidStart && isValidEnd && this._minDate <= start && start < end;
  }

  drawGantt(): void {
    this.setWidth();
    this.ganttGenerator.drawGantt(
      this.options,
      this.primarySort,
      this.secondarySort,
      this._startDate,
      this._endDate
    );
  }

  refreshConsultantReportEntries() {
    this.ganttGenerator.refreshConsultantReportEntries();
    this.drawGantt();
  }

  async refreshFileCount(abortSignal?: AbortSignal) {
    await this.ganttGenerator.refreshFileCount(abortSignal);
    this.drawGantt();
  }

  //Currently not used
  //TODO: Instead of finding the latest assignment, either find the "guilty" assignment or restrict it to the leader it is assigned
  checkForExtent() {
    const assignments = store.state.assignmentData.rows;
    assignments.sort((a, b) => (a.assignmentId < b.assignmentId ? -1 : 1));
    const latestAssignment = assignments[assignments.length - 1];
    const consultant = store.state.consultantData.rows.find(
      (consultant) => consultant.consultantId == latestAssignment.consultantId
    );
    if (!consultant) {
      return;
    }
    if (latestAssignment.coverage > consultant.coverage) {
      alert(
        'The assingnment "' +
          latestAssignment.name +
          '" has an extent of ' +
          latestAssignment.coverage +
          '% while the assignee "' +
          consultant.getName() +
          '" only has an employment rate of ' +
          consultant.coverage +
          "%"
      );
    }
  }

  private setWidth(): void {
    this.ganttOptions.width = Math.min(
      0.925 * window.innerWidth,
      this.ganttOptions.maxWidth()
    );
    this.ganttOptions.leftPadding = this.ganttOptions.width * 0.15;
    this.ganttOptions.labelPadding = this.ganttOptions.leftPadding - 10;
  }

  private async initializeGantt(abortSignal?: AbortSignal) {
    if (this.monthsAhead != 0) {
      this._startDate = this.getStartDate();
      this._endDate = this.getEndDate();
    }
    this.setCorporations();
    this.setTaskmasters();
    this.setCustomers();
    this.setConsultantCompetencies();
    this.setPriorities();
    this.setConsultantGroups();
    this.setLocations();
    this.setServices();
    this.setWidth();
    await this.ganttGenerator.makeGantt(
      createConsultantReportEntries(),
      this.options,
      this.primarySort,
      this.secondarySort,
      this._startDate,
      this._endDate,
      abortSignal
    );
  }

  get corporations(): Corporation[] {
    return this._corporations;
  }

  get customers(): Customer[] {
    return this._customers;
  }

  get consultantCompetencies(): ConsultantCompetency[] {
    return this._consultantCompetencies;
  }

  get priorities(): Priority[] {
    return this._priorities;
  }

  get taskmasters(): Taskmaster[] {
    return this._taskmasters;
  }

  get consultantGroups(): ConsultantGroup[] {
    return this._consultantGroups;
  }

  get locations(): Location[] {
    return this._locations;
  }

  get services(): Service[] {
    return this._services;
  }

  get startDate(): string {
    return this.dateUtils.dateToString(this._startDate);
  }

  set startDate(startDate: string) {
    const date = this.dateUtils.stringToDate(startDate);
    this._startDate = date;
  }

  get endDate(): string {
    return this.dateUtils.dateToString(this._endDate);
  }

  set endDate(endDate: string) {
    this._endDate = this.dateUtils.stringToDate(endDate);
  }

  get consultantReportEntries(): ConsultantReportEntry[] {
    return this._consultantReportEntries;
  }

  shouldShowTaskmasters(): boolean {
    return this.options.customerFilter >= 0 && this._taskmasters.length > 1;
  }

  private setCorporations(): void {
    this._corporations = container
      .resolve(CorporationData)
      .rows.sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  private setTaskmasters(): void {
    const id = this.options.customerFilter;
    if (id < 0) {
      this._taskmasters = [];
      return;
    }
    this._taskmasters = container
      .resolve(TaskmasterData)
      .findMany("customerId", id)
      .sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  private setCustomers(): void {
    this._customers = container
      .resolve(CustomerData)
      .rows.sort((a, b) => a.getName().localeCompare(b.getName()));
    if (this.options.corporationFilter == -1) {
      return;
    }
  }

  private setConsultantCompetencies(): void {
    this._consultantCompetencies = container
      .resolve(ConsultantCompetencyData)
      .rows.sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  private setPriorities(): void {
    this._priorities = container
      .resolve(PriorityData)
      .rows.sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  private setServices(): void {
    this._services = container
      .resolve(ServiceData)
      .rows.sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  private setConsultantGroups(): void {
    const corporationId = this.options.corporationFilter;
    const locationId = this.options.locationFilter;
    this._consultantGroups = container
      .resolve(ConsultantGroupData)
      .rows.sort((a, b) => a.getName().localeCompare(b.getName()));
    //Companies can share a location, so we might need to apply both filters.
    if (corporationId > -1)
      this._consultantGroups = this._consultantGroups.filter(
        (t) => t.corporationId == corporationId
      );
    if (locationId > -1)
      this._consultantGroups = this._consultantGroups.filter(
        (t) => t.locationId == locationId
      );
  }

  private setLocations(): void {
    const id = this.options.corporationFilter;
    if (id < 0) {
      this._locations = container.resolve(LocationData).rows;
      return;
    }
    const consultantGroups = container
      .resolve(ConsultantGroupData)
      .findMany("corporationId", id);
    const locationIds = new Set(consultantGroups.map((cg) => cg.locationId));
    this._locations = container
      .resolve(LocationData)
      .findWithValuesInSet("locationId", locationIds);
  }

  async load(abortSignal?: AbortSignal): Promise<void> {
    if (abortSignal?.aborted) {
      return;
    }
    await this.initializeGantt(abortSignal);
  }

  coverageMode(): void {
    this.options.mainFilter = AssignmentFilterType.SHOW_ALL;
    this.refilter();
  }

  availableMode(): void {
    this.options.mainFilter = AssignmentFilterType.SHOW_AVAILABLE;
    this.refilter();
  }

  assignmentMode(): void {
    this.options.mainFilter = AssignmentFilterType.SHOW_ASSIGNED;
    this.refilter();
  }

  saveSettings(): void {
    const newSettings = container.resolve(ReportSetting);
    newSettings.options = this.options;
    newSettings.primarySort = this.primarySort;
    newSettings.secondarySort = this.secondarySort;
    newSettings.monthsAhead = this.monthsAhead;
    newSettings.startDate = this.dateUtils.dateToString(this._startDate);
    newSettings.endDate = this.dateUtils.dateToString(this._endDate);

    this._settingsData.saveSettings(newSettings);
  }
}
