import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { TableDataOptionsFilter } from "./TableDataOptionsFilter";

export class AssignmentFilter extends TableDataOptionsFilter {
  public filterSettings: BaseTableFilterMode[] = [
    BaseTableFilterMode.ALL,
    BaseTableFilterMode.CURRENT_ASSIGNMENTS,
    BaseTableFilterMode.PREVIOUS_ASSIGNMENTS,
  ];

  public filterLabel = "Assignments";
}
