import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { UniqueTextField } from "../UniqueTextField";
import { VeaValidate } from "./VeaValidator";

export function FieldUniqueText<
  M extends Model<D>,
  D extends Displayable<M>
>() {
  return VeaValidate<D, UniqueTextField<M, D>>({
    message(field) {
      return field.error.notUniqueError(field.value);
    },
    validate(field) {
      const previousName = field.tableData.activeRow
        ?.getName()
        .toLocaleLowerCase();
      const name = field.value.toLocaleLowerCase().trim();
      return (
        name === previousName ||
        !field.tableData.rows.some(
          (x) => x.getName().toLocaleLowerCase() === name
        )
      );
    },
  });
}
