<template>
  <colgroup>
    <col class="clickable" />
  </colgroup>
  <td
    @click="editRecruitment(findRecruitment(month)?.recruitmentId)"
    :class="{
      top: isTopRow(month),
      clickable: month != 'remaining' && !isTotalCol,
    }"
    :colspan="month == 'remaining' ? 3 : 1"
  >
    <span>
      {{ calculateBudgetForMonth(month, year) }}
    </span>
  </td>
  <td
    @click="editRecruitment(findRecruitment(month)?.recruitmentId)"
    :class="{
      top: isTopRow(month),
      clickable: !isTotalCol,
      hasComment: findRecruitment(month)?.comment,
    }"
    v-if="month != 'remaining'"
    :title="findRecruitment(month)?.comment ?? 'No comment'"
  >
    <span>
      {{ calculatePredictionForMonth(month, year) }}
    </span>
  </td>
  <td
    @click="editRecruitment(findRecruitment(month)?.recruitmentId)"
    :class="{
      top: isTopRow(month),
      clickable: !isTotalCol,
    }"
    v-if="month != 'remaining'"
  >
    <span
      v-if="
        month == 'total' || // Show outcome in the Total row
        (today.getFullYear() == year && today.getMonth() > month) || // Show outcome if the month is in the past
        today.getFullYear() > year
      "
      :class="getOutcomeClass(calculateDeficitForMonth(month, year) ?? 0)"
    >
      {{ calculateOutcomeForMonth(month, year) }}
    </span>
    <span v-else> &nbsp; </span>
  </td>
</template>

<script setup lang="ts">
import { Recruitment } from "@/models/Recruitment";
import { Month } from "@/models/enum/Months";
import store from "@/store";
import { computed } from "vue";

type RecruitmentRowIndex = Month | "total" | "remaining";
type MonthOrTotal = Month | "total";

type Props = {
  recruitments: Recruitment[];
  month: RecruitmentRowIndex;
  year: number;
  editRecruitment: (row?: number) => void;
  isTotalCol?: boolean;
};
const props = withDefaults(defineProps<Props>(), { isTotalCol: false });
const { consultantData } = store.state;

const today = new Date();

const totals = computed(() => {
  const output = {
    budget: 0,
    prediction: 0,
    outcome: 0,
    deficit: 0,
  };

  // Filter by the selected year
  const recruitments = props.recruitments.filter(
    (recruitment) => new Date(recruitment.period).getFullYear() === props.year
  );
  // Add together the total
  for (const recruitment of recruitments) {
    output.budget += recruitment.budget;
    output.prediction += recruitment.prediction;
    output.outcome += recruitment.calcOutcome(consultantData.rows);
  }
  output.deficit = output.outcome - output.budget;
  return output;
});

function isTopRow(month: RecruitmentRowIndex) {
  return month === Month.Jan;
}

const totalsRemaining = computed(() => {
  return totals.value.budget - totals.value.outcome;
});

function findRecruitment(month: RecruitmentRowIndex) {
  if (props.isTotalCol || month == "remaining" || month == "total") {
    return undefined;
  }
  return props.recruitments.find(
    (recruitment) => new Date(recruitment.period).getMonth() === month
  );
}

function findRecruitments(month: number, year: number) {
  return props.recruitments.filter(
    (recruitment) =>
      new Date(recruitment.period).getMonth() === month &&
      new Date(recruitment.period).getFullYear() === year
  );
}

function calculateRecruitmentValue(
  month: Month,
  year: number,
  func: (recruitment?: Recruitment) => number | undefined
) {
  if (!props.isTotalCol) {
    return func(findRecruitment(month));
  }

  let result = 0;
  for (const recruitment of findRecruitments(month, year)) {
    result += func(recruitment) ?? 0;
  }
  return result;
}

function getBudget(recruitment?: Recruitment) {
  return recruitment?.budget;
}

function getPrediction(recruitment?: Recruitment) {
  return recruitment?.prediction;
}

function getOutcome(recruitment?: Recruitment) {
  return recruitment?.calcOutcome(consultantData.rows);
}

function getDeficit(recruitment?: Recruitment) {
  return recruitment?.calcDeficit(consultantData.rows);
}

function calculateBudgetForMonth(month: RecruitmentRowIndex, year: number) {
  if (month == "total") {
    return totals.value.budget;
  } else if (month == "remaining") {
    return totalsRemaining;
  } else {
    return calculateRecruitmentValue(month, year, getBudget);
  }
}

function calculatePredictionForMonth(month: MonthOrTotal, year: number) {
  if (month == "total") {
    return totals.value.prediction;
  } else {
    return calculateRecruitmentValue(month, year, getPrediction);
  }
}

function calculateOutcomeForMonth(month: MonthOrTotal, year: number) {
  if (month == "total") {
    return totals.value.outcome;
  } else {
    return calculateRecruitmentValue(month, year, getOutcome);
  }
}

function calculateDeficitForMonth(month: MonthOrTotal, year: number) {
  if (month == "total") {
    return totals.value.deficit;
  } else {
    return calculateRecruitmentValue(month, year, getDeficit);
  }
}

function getOutcomeClass(outcome: number) {
  if (outcome < 0) {
    return "deficit";
  } else if (outcome >= 0) {
    return "surplus";
  } else {
    return "";
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
tr {
  border-collapse: separate;
  vertical-align: left;
  text-align: center;

  &:nth-child(even) > td {
    background-color: $table-row-alternate-color;
  }
  &:nth-child(3n + 3):not(:last-child) > td {
    border-bottom: 10px solid $container-bgc;
  }

  td {
    background-color: $table-row-primary-color;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    border: 5px solid $container-bgc;
    min-width: 40px;
    min-height: 40px;

    &.hasComment::after {
      content: "\2605"; // Unicode character for a star icon
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
      color: $color-edit-blue;
    }

    .crossed-out {
      color: $color-grey;
      text-decoration: line-through;
    }

    span.surplus {
      color: $color-lightgreen;
      font-weight: bold;
    }

    span.deficit {
      color: $color-red;
      font-weight: bold;
    }
  }

  td.clickable {
    cursor: pointer;

    &:hover {
      background-color: $row-hover-bgc;
    }
  }

  td.total {
    font-weight: bold;
  }

  td.success-color {
    background-color: $color-lightgreen;
  }

  td.failure-color {
    background-color: $color-red;
  }
}
</style>
