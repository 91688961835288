import { LOCALE, LOCALE_EN_US } from "@/Constant";
import FileFolder from "@/components/enum/FileFolder";
import store from "@/store";
import FileData from "@/store/data/FileData";
import * as d3 from "d3";
import { container } from "tsyringe";
import { AbsenceReportEntry } from "./AbsenceReportEntry";
import { ConsultantReportEntry } from "./ConsultantReportEntry";
import { CoveragePeriodReportEntry } from "./CoveragePeriodReportEntry";
import { GanttOptions } from "./GanttOptions";
import { OverlapPeriodReportEntry } from "./OverlapPeriodReportEntry";
import { ReportEntry } from "./ReportEntry";
import { ReportFilter } from "./ReportFilter";
import ReportFilterOptions from "./ReportFilterOptions";
import { DataType } from "./enum/DataType";
import { DiagramColors } from "./enum/DiagramColors";
import { SortingMode } from "./enum/SortingMode";
import { GanttUtils } from "./util/GanttUtils";
import { HtmlSummaryBuilder } from "./util/HtmlSummaryBuilder";
import { createConsultantReportEntries } from "./util/createConnectionEntries";

/**
 * Draws the Gantt-diagram using D3.
 */
export class GanttGenerator {
  private svg: /* eslint-disable-next-line */
  d3.Selection<SVGSVGElement, unknown, HTMLElement, any> | undefined;
  private _timeScale!: d3.ScaleTime<number, number, never>;
  private _ganttOptions: GanttOptions;
  private ganttUtils: GanttUtils;
  private consultantReportEntries!: ConsultantReportEntry[];
  private filteredConsultants!: ConsultantReportEntry[];
  private startDate!: Date;
  private endDate!: Date;
  private profileCounts: Map<number, number> | undefined;
  private assignmentCounts: Map<number, number> | undefined;

  private _isRowsEmpty: boolean;

  constructor(ganttOptions: GanttOptions) {
    this._ganttOptions = ganttOptions;
    this.ganttUtils = new GanttUtils(this);
    this._isRowsEmpty = false;
  }

  get ganttOptions(): GanttOptions {
    return this._ganttOptions;
  }

  get timeScale(): d3.ScaleTime<number, number, never> {
    return this._timeScale;
  }

  get isRowsEmpty(): boolean {
    return this._isRowsEmpty;
  }

  setTimeScale(): void {
    this._timeScale = d3
      .scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, this._ganttOptions.innerLeft()]);
  }

  scaleStartDate(date: Date): number {
    return date > this.startDate
      ? this._timeScale(date)
      : this._timeScale(this.startDate);
  }

  scaleEndDate(date: Date | null): number {
    if (date == null) {
      return this._timeScale(this.endDate);
    }

    const dateCopy = new Date(date);
    return date < this.endDate
      ? this._timeScale(dateCopy.setDate(date.getDate() + 1))
      : this._timeScale(this.endDate);
  }

  ganttHeight(): number {
    if (this._isRowsEmpty) {
      return 0; // Special case to avoid compiling error. Already handled in
      // TheCoveragePeriodReport.
    }
    // If sorting groups are defined, last row's group index is the same as
    // the total number of groups
    return this._ganttOptions.height(
      this.filteredConsultants.length +
        (this.filteredConsultants.slice(-1)[0].sortingGroupN ?? 0)
    );
  }

  rowY(entry: ReportEntry, rowsOffset = 0): number {
    return this._ganttOptions.rowY(entry.reportIndex + rowsOffset);
  }

  private flattenCoveragePeriods(
    consultantReportEntries: ConsultantReportEntry[],
    options: ReportFilterOptions
  ): CoveragePeriodReportEntry[] {
    return consultantReportEntries.flatMap((c) =>
      c
        .getCurrentCoveragePeriods(this.startDate, this.endDate)
        .filter(
          (entry) => !options.hideEntriesWithFiles || entry.filesAvailable === 0
        )
    );
  }

  private flattenAbsences(
    consultantReportEntries: ConsultantReportEntry[]
  ): AbsenceReportEntry[] {
    return consultantReportEntries.flatMap((c) =>
      c.getCurrentAbsences(this.startDate, this.endDate)
    );
  }

  async makeGantt(
    consultantReportEntries: ConsultantReportEntry[],
    options: ReportFilterOptions,
    primarySort: SortingMode | undefined,
    secondarySort: SortingMode,
    startDate: Date,
    endDate: Date,
    abortSignal?: AbortSignal
  ): Promise<void> {
    //Make sure each consultant report entry has loaded how many files it has available.
    this.consultantReportEntries = consultantReportEntries;
    await this.refreshFileCount(abortSignal);
    this.filteredConsultants = ReportFilter.sort(
      consultantReportEntries,
      primarySort,
      secondarySort
    );
    this.startDate = startDate;
    this.endDate = endDate;

    if (abortSignal?.aborted) {
      return;
    }

    this.makeSvg();
    this.makeGridAndText();
    this.drawGantt(options, primarySort, secondarySort, startDate, endDate);
  }

  async refreshFileCount(abortSignal?: AbortSignal) {
    const fileData = container.resolve(FileData);

    await Promise.all([
      fileData
        .getFullFileCount(FileFolder.Profile)
        .then((map) => (this.profileCounts = map)),
      fileData
        .getFullFileCount(FileFolder.Assignment)
        .then((map) => (this.assignmentCounts = map)),
    ]);

    if (abortSignal?.aborted) {
      return;
    }

    this.setFileCount();
  }

  setFileCount() {
    for (const consultantReportEntry of this.consultantReportEntries) {
      consultantReportEntry.filesAvailable =
        this.profileCounts?.get(consultantReportEntry.userId) ?? 0;
      for (const periodReportEntry of consultantReportEntry.coveragePeriodReportEntries) {
        periodReportEntry.filesAvailable =
          this.assignmentCounts?.get(periodReportEntry.assignmentId) ?? 0;
      }
    }

    const { consultantUrlData, assignmentUrlData } = store.state;

    for (const entry of this.consultantReportEntries) {
      entry.urlsAvailable = consultantUrlData.findMany(
        "consultantId",
        entry.consultantId
      ).length;
      for (const periodReportEntry of entry.coveragePeriodReportEntries) {
        periodReportEntry.urlsAvailable = assignmentUrlData.findMany(
          "assignmentId",
          periodReportEntry.assignmentId
        ).length;
      }
    }
  }

  refreshConsultantReportEntries() {
    this.consultantReportEntries = createConsultantReportEntries();
    this.setFileCount();
  }

  drawGantt(
    options: ReportFilterOptions,
    primarySort: SortingMode | undefined,
    secondarySort: SortingMode,
    startDate: Date,
    endDate: Date
  ): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.filteredConsultants = ReportFilter.filter(
      this.consultantReportEntries,
      options,
      startDate,
      endDate
    );
    this._isRowsEmpty = this.filteredConsultants.length === 0;
    this.filteredConsultants = ReportFilter.sort(
      this.filteredConsultants,
      primarySort,
      secondarySort
    );
    this.filteredConsultants = ReportFilter.setIndices(
      this.filteredConsultants
    );
    this.resizeSvg();
    this.makeCounter(this.filteredConsultants.length);
    this.drawGrid();
    this.drawRowLabels();
    this.drawGroupHeaders();
    this.drawReportEntries(
      this.filteredConsultants,
      DataType.CONSULTANTS,
      false,
      false
    );
    this.drawReportEntries(
      this.flattenCoveragePeriods(this.filteredConsultants, options),
      DataType.COVERAGE_PERIODS,
      true,
      true
    );
    this.drawReportEntries(
      this.flattenAbsences(this.filteredConsultants),
      DataType.ABSENCES,
      true,
      true
    );
    this.raiseOverlaps();
    this.drawBackground();
  }

  private resizeSvg(): void {
    if (this.svg) {
      this.svg
        .attr("height", this.ganttHeight() + this._ganttOptions.bottomPadding)
        .attr("width", this._ganttOptions.width);
    }
  }

  private makeCounter(count: number) {
    const element = document.getElementById("counter");
    if (element != null) {
      element.innerHTML = count + " consultants";
    }
  }

  private makeSvg(): void {
    d3.select("#chart").selectAll("svg").remove();
    d3.select("#spinner").selectAll("img").remove();
    const scrollButton = d3.select("#scroll-button").node();
    if (scrollButton) (scrollButton as Element).removeAttribute("hidden");
    this.svg = d3
      .select("#chart")
      .append("svg")
      .attr("width", this._ganttOptions.width)
      .attr("height", this.ganttHeight() + this._ganttOptions.bottomPadding)
      .attr("class", "svg")
      .attr("overflow", "visible");
    this.svg
      .append("g")
      .attr("id", "background")
      .append("rect")
      .attr("x", this._ganttOptions.leftPadding)
      .attr("y", this.ganttOptions.topPadding)
      .attr(
        "width",
        this._ganttOptions.width - this.ganttOptions.leftPadding - 1
      )
      .attr("height", this.ganttHeight() - this.ganttOptions.topPadding)
      .attr("stroke", DiagramColors.BLACK)
      .attr("fill", DiagramColors.BACKGROUND);
    this.svg.append("g").attr("id", DataType.CONSULTANTS);
    this.svg.append("g").attr("id", DataType.COVERAGE_PERIODS);
    this.svg.append("g").attr("id", DataType.ABSENCES);
  }

  private makeGridAndText(): void {
    if (this.svg == undefined) {
      return;
    }
    this.setTimeScale();
    const topAxis = this.svg
      .append("g")
      .attr("class", "grid top")
      .attr("transform", this.ganttUtils.axisTopTranslation())
      .call(this.ganttUtils.buildXAxisTop());
    const bottomAxis = this.svg
      .append("g")
      .attr("class", "grid bottom")
      .attr("transform", this.ganttUtils.axisBottomTranslation())
      .call(this.ganttUtils.buildXAxisBottom());
    this.ganttUtils.setAxisText(topAxis);
    this.ganttUtils.setAxisText(bottomAxis, this.ganttOptions.axisFontSize);
    this.svg.append("g").attr("id", "labels");
    this.svg.append("g").attr("id", "headers");
  }

  drawBackground(): void {
    if (this.svg == undefined) {
      return;
    }
    const background = this.svg.select("#background").select("rect");
    background.join(
      (enter) => {
        enter
          .transition()
          .duration(this.ganttOptions.midTransitionDuration)
          .attr("x", this._ganttOptions.leftPadding)
          .attr("y", this.ganttOptions.topPadding)
          .attr("height", this.ganttHeight() - this.ganttOptions.topPadding)
          .attr(
            "width",
            this._ganttOptions.width - this._ganttOptions.leftPadding
          );
        return background;
      },
      (update) => {
        update
          .transition()
          .duration(this.ganttOptions.midTransitionDuration)
          .attr("x", this._ganttOptions.leftPadding)
          .attr("y", this.ganttOptions.topPadding)
          .attr("height", this.ganttHeight() - this.ganttOptions.topPadding)
          .attr(
            "width",
            this._ganttOptions.width - this._ganttOptions.leftPadding
          );
        return background;
      }
    );
  }

  drawGrid(): void {
    if (this.svg == undefined) {
      return;
    }
    this.setTimeScale();
    const topAxis = this.svg
      .select<SVGGElement>("g.grid.top")
      .transition()
      .duration(this.ganttOptions.midTransitionDuration)
      .ease(d3.easeSinInOut)
      .attr("transform", this.ganttUtils.axisTopTranslation())
      .call(this.ganttUtils.buildXAxisTop());
    const bottomAxis = this.svg
      .select<SVGGElement>("g.grid.bottom")
      .transition()
      .duration(this.ganttOptions.midTransitionDuration)
      .ease(d3.easeSinInOut)
      .attr("transform", this.ganttUtils.axisBottomTranslation())
      .call(this.ganttUtils.buildXAxisBottom());
    this.ganttUtils.setAxisText(topAxis.selection());
    this.ganttUtils.setAxisText(
      bottomAxis.selection(),
      this.ganttOptions.axisFontSize
    );
  }

  private calculateBarWidth(d: ReportEntry): number {
    const width =
      this.scaleEndDate(d.endDate) - this.scaleStartDate(d.startDate);
    return width > 0 ? width : 0;
  }
  private computeDateLabel(d: ReportEntry): string {
    const barWidth = this.calculateBarWidth(d);
    const writeEndDate = d.endDate
      ? d.endDate.toLocaleDateString(LOCALE_EN_US, {
          month: "short",
          day: "numeric",
        })
      : "";
    const barText = d.getRowText();
    const textWidth = this.ganttUtils.getTextWidth(
      barText,
      "14px Arial"
    ) as number;
    const dateWidth = this.ganttUtils.getTextWidth(
      writeEndDate,
      "14px Arial"
    ) as number;
    if (barWidth < dateWidth + textWidth + 40) {
      return "";
    }
    return writeEndDate;
  }

  private computeNameLabel(name: string): string {
    const windowWidth = window.visualViewport?.width;
    if (!windowWidth) return name;

    let output = name;
    let outputLength = name.length;
    const outputWidth = () =>
      this.ganttUtils.getTextWidth(output, "14px Arial") ?? outputLength * 1.5;
    const maxWidth = this.ganttOptions.labelPadding;

    while (outputWidth() > maxWidth && outputLength >= 0) {
      outputLength--;
      output = name.substring(0, outputLength) + "...";
    }
    return output;
  }

  private computeBarLabel(d: ReportEntry): string {
    const barWidth = this.calculateBarWidth(d);
    let barText = d.getRowText();
    const writeEndDate = d.endDate
      ? d.endDate.toLocaleDateString(LOCALE_EN_US, {
          month: "short",
          day: "numeric",
        })
      : "";
    const absenceEntry = d as AbsenceReportEntry;
    const consultant = this.findConsultantWithId(d.consultantId);
    let overlappedAssignment;
    if (absenceEntry.absenceData) {
      overlappedAssignment = this.findAnOverlappedAssignment(
        consultant,
        absenceEntry
      );
      if (
        this.isInTimespan(
          overlappedAssignment,
          absenceEntry.startDate,
          absenceEntry.endDate
        )
      ) {
        absenceEntry.hasOverlap = true;
      } else {
        absenceEntry.hasOverlap = false;
      }
      if (absenceEntry.hasOverlap)
        barText += ", " + overlappedAssignment.customer;
    }
    const textWidth = this.ganttUtils.getTextWidth(
      barText,
      "14px Arial"
    ) as number;

    const dateWidth = this.ganttUtils.getTextWidth(
      writeEndDate,
      "14px Arial"
    ) as number;
    if (barWidth < textWidth + dateWidth + 40) {
      return "";
    }
    return barText;
  }
  private calculateEndDateWidth(d: ReportEntry): number {
    const date = d.endDate
      ? d.endDate.toLocaleDateString(LOCALE_EN_US, {
          month: "short",
          day: "numeric",
        })
      : "";
    return this.ganttUtils.getTextWidth(
      date,
      `${this.ganttOptions.standardFontSize} Arial`
    ) as number;
  }

  private calculateHeaderX(d: ConsultantReportEntry): number {
    const headerWidth = this.ganttUtils.getTextWidth(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      d.sortingGroup!,
      "18px Avenir, Helvetica, Arial, sans-serif"
    ) as number;
    return headerWidth < this._ganttOptions.labelPadding
      ? this._ganttOptions.labelPadding
      : 0;
  }

  private calcAnchorType(d: ConsultantReportEntry): string {
    const headerWidth = this.ganttUtils.getTextWidth(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      d.sortingGroup!,
      "18px Avenir, Helvetica, Arial, sans-serif"
    ) as number;
    return headerWidth < this._ganttOptions.labelPadding ? "end" : "start";
  }

  private findConsultantWithId(id: number): ConsultantReportEntry {
    let consultatToFind;
    this.consultantReportEntries.forEach((consultEntry) => {
      if (consultEntry.consultantId == id) {
        consultatToFind = consultEntry;
      }
    });
    if (consultatToFind) return consultatToFind;
    else {
      return ConsultantReportEntry.prototype;
    }
  }

  private findAnOverlappedAssignment(
    consultant: ConsultantReportEntry,
    absenceEntry: AbsenceReportEntry
  ): CoveragePeriodReportEntry {
    for (const periodReport of consultant.coveragePeriodReportEntries) {
      if (
        !periodReport.endDate ||
        periodReport.startDate > absenceEntry.startDate ||
        periodReport.endDate >= absenceEntry.startDate
      ) {
        return periodReport;
      }
    }
    return CoveragePeriodReportEntry.prototype;
  }

  private isInTimespan(
    entry: ReportEntry,
    startDate: Date,
    endDate: Date | null
  ) {
    const endsAfterStart = !entry.endDate || startDate <= entry.endDate;
    const startsBeforeEnd = !endDate || entry.startDate < endDate;
    return startsBeforeEnd && endsAfterStart;
  }

  private drawReportEntries(
    data: ReportEntry[],
    dataType: DataType,
    drawText: boolean,
    showSummary: boolean
  ): void {
    if (this.svg == undefined) {
      return;
    }
    const bars = this.svg
      .select(`#${dataType}`)
      .selectAll("g")
      .data(data, (d) => dataType + (d as ReportEntry).internalId);
    bars.join(
      (enter) => {
        const group = enter.append("g");
        group.attr(
          "class",
          (d) => (isOverlap(d) ? "overlap" : "") + " reportEntry"
        );
        const innerRects = group
          .append("rect")
          .attr(
            "x",
            (d) =>
              this.scaleStartDate(d.startDate) + this._ganttOptions.leftPadding
          )
          .attr("y", (d) => this.rowY(d) + this.ganttOptions.reportMargin / 2)
          .attr("rx", 20)
          .attr("ry", 20)
          .attr("width", (d) => this.calculateBarWidth(d))
          .attr(
            "height",
            this._ganttOptions.rowHeight - this.ganttOptions.reportMargin
          )
          // .attr("stroke", Colors.BLACK)
          .attr("fill", (d) => d.getColor());
        if (showSummary) {
          innerRects
            .attr("tabindex", "0")
            .on("mouseover", triggerSummaryRect)
            .on("mouseout", hideCoveragePeriodSummary);
          if (dataType === DataType.COVERAGE_PERIODS) {
            innerRects
              .attr("cursor", "pointer")
              .on("click", handlePeriodClick)
              .on("keydown", handleEnterKeydown);
          }
          if (dataType === DataType.ABSENCES) {
            innerRects
              .attr("cursor", "pointer")
              .on("click", handleAbsenceClick)
              .on("keydown", handleEnterKeydown);
          }
        }

        if (drawText) {
          group
            .append("text")
            .text((d) => this.computeBarLabel(d))
            .attr(
              "x",
              (d) =>
                this._ganttOptions.leftPadding +
                this._ganttOptions.rowTextPadding +
                this.scaleStartDate(d.startDate)
            )
            .attr(
              "y",
              (d) => this.rowY(d) + this._ganttOptions.getTextYOffset()
            )
            .attr("font-size", this._ganttOptions.standardFontSize)
            .attr("text-anchor", "left")
            .attr("text-height", this._ganttOptions.rowHeight)
            .attr("pointer-events", "none")
            .attr("fill", (d) => d.getTextColor());

          group
            .append("text")
            .text((d) => this.computeDateLabel(d))
            .attr(
              "x",
              (d) =>
                this.scaleStartDate(d.startDate) +
                this._ganttOptions.leftPadding +
                this.calculateBarWidth(d) -
                this.calculateEndDateWidth(d) -
                20
            )
            .attr(
              "y",
              (d) => this.rowY(d) + this._ganttOptions.getTextYOffset()
            )
            .attr("font-size", this._ganttOptions.standardFontSize)
            .attr("text-anchor", "start")
            .attr("text-height", this._ganttOptions.rowHeight)
            .attr("pointer-events", "none")
            .attr("fill", (d) => d.getTextColor());
        }
        return bars;
      },
      (update) => {
        update
          .transition()
          .duration(this._ganttOptions.midTransitionDuration)
          .select("rect")
          .attr(
            "x",
            (d) =>
              this.scaleStartDate(d.startDate) + this._ganttOptions.leftPadding
          )
          .attr("y", (d) => this.rowY(d) + this.ganttOptions.reportMargin / 2)
          .attr("width", (d) => this.calculateBarWidth(d))
          .attr("fill", (d) => d.getColor());
        if (drawText) {
          update
            .transition()
            .duration(this._ganttOptions.midTransitionDuration)
            .select<SVGTextElement>("text:not(:last-child)")
            .text((d) => this.computeBarLabel(d))
            .attr(
              "x",
              (d) =>
                this._ganttOptions.leftPadding +
                this._ganttOptions.rowTextPadding +
                this.scaleStartDate(d.startDate)
            )
            .attr(
              "y",
              (d) => this.rowY(d) + this._ganttOptions.getTextYOffset()
            )
            .attr("fill", (d) => d.getTextColor());

          update
            .transition()
            .duration(this._ganttOptions.midTransitionDuration)
            .select<SVGTextElement>("text:last-child")
            .text((d) => this.computeDateLabel(d))
            .attr(
              "x",
              (d) =>
                this.scaleStartDate(d.startDate) +
                this._ganttOptions.leftPadding +
                this.calculateBarWidth(d) -
                this.calculateEndDateWidth(d) -
                20
            )
            .attr(
              "y",
              (d) => this.rowY(d) + this._ganttOptions.getTextYOffset()
            )
            .attr("fill", (d) => d.getTextColor());
        }
        return bars;
      },
      (exit) => {
        exit.remove();
      }
    );

    function triggerSummaryRect(
      this: SVGRectElement,
      e: MouseEvent,
      d: ReportEntry
    ) {
      let x = this.x.animVal.value + this.getBoundingClientRect().width / 2;
      let y = this.y.animVal.value;
      //w is the estimated width of the summary box.
      let w = 105;
      if (isOverlap(d)) {
        const overlapEntry = d as OverlapPeriodReportEntry;
        const members = overlapEntry.members;
        w = 91 * members.length;
        y -= 10;
      }
      const clientX = x + document.body.scrollLeft;
      const width = document.documentElement.clientWidth - w;
      if (clientX < w - 30) x += w - 30 - clientX;
      else if (clientX > width) x += width - clientX;
      const clientY =
        this.getBoundingClientRect().top + document.body.scrollTop;
      //h is the enstimated height of the summary box
      const h = d.getSummarySize() * 18 + 32;
      const height = document.documentElement.clientHeight - h;
      if (clientY > height) y += height - clientY;
      showCoveragePeriodSummary(x, y, d);
    }

    function isOverlap(reportEntry: ReportEntry) {
      return reportEntry instanceof OverlapPeriodReportEntry;
    }

    function handlePeriodClick(
      this: SVGRectElement,
      ev: MouseEvent,
      d: ReportEntry
    ) {
      if (isOverlap(d)) {
        ev.stopPropagation();
        displaySelector(this, ev, d as OverlapPeriodReportEntry);
      } else {
        const id = (d as CoveragePeriodReportEntry).assignmentId;
        store.state.assignmentId = id;
        store.state.connectionRowId = id;
      }
    }

    function handleEnterKeydown(
      this: SVGRectElement,
      ee: KeyboardEvent,
      d: ReportEntry
    ) {
      if (ee.key === "Enter") {
        if (isOverlap(d)) {
          ee.stopPropagation();
        } else if (d instanceof AbsenceReportEntry) {
          const id = (d as AbsenceReportEntry).internalId;
          store.state.absenceId = id;
        } else {
          const id = (d as CoveragePeriodReportEntry).assignmentId;
          store.state.assignmentId = id;
        }
      }
    }

    function handleAbsenceClick(
      this: SVGRectElement,
      ev: MouseEvent,
      d: ReportEntry
    ) {
      if (isOverlap(d)) {
        ev.stopPropagation();
        displaySelector(this, ev, d as OverlapPeriodReportEntry);
      } else {
        const id = (d as AbsenceReportEntry).internalId;
        store.state.absenceId = id;
      }
    }

    function displaySelector(
      _rect: SVGRectElement,
      ev: MouseEvent,
      d: OverlapPeriodReportEntry
    ) {
      const selector = document.getElementById("selector");
      if (!selector) return;

      selector.innerHTML = "";
      selector.style.display = "flex";
      selector.style.top = `${ev.pageY}px`;
      selector.style.left = `${ev.pageX}px`;

      d.members.forEach((m) => {
        const btn = document.createElement("button");
        btn.innerText = m.assignmentName;
        btn.className = "btn btn-sm";
        btn.style.backgroundColor = m.getColor();
        btn.style.color = m.getTextColor();
        btn.style.border = "1px solid grey";
        btn.style.fontSize = "12px";
        btn.style.fontWeight = "bold";
        btn.onclick = () => {
          store.state.assignmentId = m.assignmentId;
          selector.innerHTML = "";
          selector.style.display = "none";
        };
        btn.onmouseenter = () => {
          btn.style.filter = "brightness(0.96)";
        };
        btn.onmouseout = () => {
          btn.style.filter = "unset";
        };
        selector.appendChild(btn);
      });
    }

    function showCoveragePeriodSummary(
      x: number,
      y: number,
      reportEntry: ReportEntry
    ): void {
      const element = document.getElementById("tag");
      if (element == null) {
        return;
      }
      if (isOverlap(reportEntry)) {
        const overlapEntry = reportEntry as OverlapPeriodReportEntry;
        const container = document.createElement("div");
        for (const member of overlapEntry.members) {
          const el = document.createElement("div");
          el.innerHTML = member.getSummaryHtml();

          el.style.border = "1px solid";
          el.style.borderRadius = "3px";
          el.style.padding = "3px 5px";
          el.style.margin = "3px 0px";

          container.appendChild(el);
        }

        container.style.display = "flex";
        //if (window.innerWidth < 500) {
        //  container.style.flexDirection = "column";
        //}
        container.style.columnGap = "6px";

        element.appendChild(container);
      } else {
        element.innerHTML = reportEntry.getSummaryHtml();
      }

      element.style.top = y + "px";
      element.style.left = x + "px";
      element.style.display = "block";
    }

    function hideCoveragePeriodSummary(): void {
      const element = document.getElementById("tag");
      if (element != null) {
        element.style.display = "none";
        const childNodes = element.childNodes;
        childNodes.forEach((node) => element.removeChild(node));
      }
    }
  }

  private textColourBasedOnTheme(): string {
    // this function should be revised/reworked --- also ref Colors and GanttUtils
    switch (document.body.className) {
      case "Dark":
        return "white";
      case "RG":
        return "#1ae9d8";
      default:
        return "black";
    }
  }

  private drawRowLabels(): void {
    if (this.svg == undefined) {
      return;
    }
    const labels = this.svg
      .select("#labels")
      .selectAll("text")
      .data(this.filteredConsultants, (d) => (d as ConsultantReportEntry).name)
      .attr("class", "consultant-label");
    labels.join(
      (enter) => {
        enter
          .append("text")
          .attr("x", this._ganttOptions.labelPadding)
          .attr("y", (d) => this.rowY(d) + this._ganttOptions.getTextYOffset())
          .attr("font-size", this._ganttOptions.standardFontSize)
          //.attr("font-style", "italic")
          .attr("text-decoration", "none")
          .attr("text-anchor", "end")
          .attr("text-height", this._ganttOptions.labelTextHeight)
          .attr("fill", this.textColourBasedOnTheme())
          .append("a")
          .attr("cursor", "pointer")
          .attr("tabindex", "0")
          .on("click", handleConsultantClick)
          .on("keydown", handleConsultantEnter)
          .text((d) => this.computeNameLabel(d.name))
          .on("mouseover", showDescription)
          .on("mouseout", hideDescription);

        return labels;
      },
      (update) => {
        update
          .transition()
          .duration(1)
          .attr("x", this._ganttOptions.labelPadding)
          .attr("y", (d) => this.rowY(d) + this._ganttOptions.getTextYOffset());

        update
          .transition()
          .duration(1)
          .select<SVGTextElement>("a")
          .text((d) => this.computeNameLabel(d.name));

        return labels;
      },
      (exit) => {
        exit.remove();
      }
    );

    function handleConsultantClick(
      this: HTMLAnchorElement,
      ev: MouseEvent,
      d: ReportEntry
    ) {
      const id = (d as ConsultantReportEntry).consultantId;
      store.state.consultantId = id;
    }

    function handleConsultantEnter(
      this: HTMLAnchorElement,
      ev: KeyboardEvent,
      d: ReportEntry
    ) {
      if (ev.key === "Enter") {
        const id = (d as ConsultantReportEntry).consultantId;
        store.state.consultantId = id;
      }
    }

    function showDescription(
      this: HTMLAnchorElement,
      _e: MouseEvent,
      d: ConsultantReportEntry
    ): void {
      const element = document.getElementById("nameTag");
      if (element == null) {
        return;
      }

      const builder = new HtmlSummaryBuilder()
        .addField("Consultant group", d.consultantGroupName)
        .addField("Company", d.corporation)
        .addField("Employment start", d.startDate.toLocaleDateString(LOCALE));

      if (d.endDate != null) {
        builder.addField("End", d.endDate.toLocaleDateString(LOCALE));
      }

      builder.addField(
        "Employment rate",
        d.consultantEmploymentRate.toString() + "%"
      );

      if (d.filesAvailable > 0) {
        builder.addField("Files available", d.filesAvailable.toString());
      }

      if (d.urlsAvailable > 0) {
        builder.addField("URLs available", d.urlsAvailable.toString());
      }

      if (d.keyCompetenciesDescription != "-") {
        builder
          .addHeader("Key Competencies:")
          .addRow(d.keyCompetenciesDescription);
      }

      if (d.competenciesDescription != "-") {
        builder.addHeader("Competencies:").addRow(d.competenciesDescription);
      }

      if (d.certificateDescription != "-") {
        builder.addHeader("Certificates:").addRow(d.certificateDescription);
      }

      if (d.servicesDescription != "-") {
        builder.addHeader("Services:").addRow(d.servicesDescription);
      }

      element.innerHTML = builder.toString();
      element.style.display = "inline-block";
      element.style.position = "absolute";
      element.style.top = this.parentElement?.getAttribute("y") + "px";
    }

    function hideDescription(): void {
      const element = document.getElementById("nameTag");
      if (element != null) {
        element.style.display = "none";
      }
    }
  }

  private drawGroupHeaders(): void {
    if (this.svg == undefined) {
      return;
    }
    const headers = this.svg
      .select("#headers")
      .selectAll("g")
      .data(
        this.filteredConsultants.filter((cre) => cre.sortingGroupIndex === 0)
      );
    headers.join(
      (enter) => {
        const group = enter.append("g");
        group
          .append("rect")
          .attr("x", 0)
          .attr("y", (d) => this.rowY(d, -1) + 1)
          .attr("width", this._ganttOptions.width + 1)
          .attr("height", this._ganttOptions.rowHeight - 2)
          .attr("fill", DiagramColors.WHITE);

        group
          .append("text")
          .attr("x", (d) => this.calculateHeaderX(d))
          .attr(
            "y",
            (d) =>
              this.rowY(d, -1) +
              this._ganttOptions.rowLabelOffsetY +
              this._ganttOptions.getTextYOffset()
          )
          .attr("font-size", this._ganttOptions.headingFontSize)
          .attr("font-weight", "bold")
          .attr("text-anchor", (d) => this.calcAnchorType(d))
          .attr("text-height", this._ganttOptions.labelTextHeight)
          .attr("fill", DiagramColors.BLACK)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .text((d) => d.sortingGroup!);
        return headers;
      },
      (update) => {
        update
          .transition()
          .duration(this.ganttOptions.midTransitionDuration)
          .select("rect")
          .attr("y", (d) => this.rowY(d, -1) + 1)
          .attr("width", this._ganttOptions.width + 1);

        update
          .transition()
          .duration(this.ganttOptions.midTransitionDuration)
          .select("text")
          .attr("x", (d) => this.calculateHeaderX(d))
          .attr(
            "y",
            (d) =>
              this.rowY(d, -1) +
              this._ganttOptions.rowLabelOffsetY +
              this._ganttOptions.getTextYOffset()
          )
          .attr("text-anchor", (d) => this.calcAnchorType(d))
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .text((d) => d.sortingGroup!);
        return headers;
      },
      (exit) => {
        exit.remove();
      }
    );
  }

  private raiseOverlaps(): void {
    const overlaps = this.svg?.selectAll(".overlap");
    if (!overlaps) {
      return;
    }
    overlaps
      .attr("opacity", "0")
      .raise()
      .transition()
      .delay(this._ganttOptions.midTransitionDuration)
      .duration(this._ganttOptions.fastTransitionDuration)
      .attr("opacity", "1");
  }
}
