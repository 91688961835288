import { Competency } from "@/models/Competency";
import { Corporation } from "@/models/Corporation";
import { Customer } from "@/models/Customer";
import { NisAssignment } from "@/models/NisAssignment";
import { NisConsultant } from "@/models/NisConsultant";
import { NisConsultantGroup } from "@/models/NisConsultantGroup";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { CustomerDisplayable } from "@/models/displayable/CustomerDisplayable";
import { NisAssignmentDisplayable } from "@/models/displayable/NisAssignmentDisplayable";
import { NisConsultantDisplayable } from "@/models/displayable/NisConsultantDisplayable";
import { NisConsultantGroupDisplayable } from "@/models/displayable/NisConsultantGroupDisplayable";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import store from "@/store";
import { container } from "tsyringe";
import { TableDataOptions } from "../TableDataOptions";
import { TaskmasterData } from "../TaskmasterData";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { PeriodFilter } from "../tableDataOptionsFilters/PeriodFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class NisAssignmentOptions extends TableDataOptions<
  NisAssignment,
  NisAssignmentDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  private nisConsultantGroupDropdown: TableDataDropdown<
    NisConsultantGroup,
    NisConsultantGroupDisplayable
  >;

  private nisConsultantDropdown: TableDataDropdown<
    NisConsultant,
    NisConsultantDisplayable
  >;

  private customerDropdown: TableDataDropdown<Customer, CustomerDisplayable>;

  customerFilter(option: DropdownOption<string>) {
    const selectedNisConsultantGroup =
      this.nisConsultantGroupDropdown.selectedOption;
    const selectedCorporation = this.corporationDropdown.selectedOption;
    const selectedNisConsultant = this.nisConsultantDropdown.selectedOption;
    const {
      nisAssignmentData,
      consultantGroupData,
      consultantData,
      taskmasterData,
    } = store.state;

    const taskmasterIds = taskmasterData.findIds("customerId", option.id);

    if (selectedNisConsultant != -1) {
      return nisAssignmentData
        .findWithValuesInSet("taskmasterId", taskmasterIds)
        .some((a) => a.nisConsultantId == selectedNisConsultant);
    }

    let nisConsultantIds: Set<number>;

    if (selectedNisConsultantGroup != -1) {
      nisConsultantIds = consultantData.findIds(
        "consultantGroupId",
        selectedNisConsultantGroup
      );
    } else if (selectedCorporation != -1) {
      const consultantGroupIds = consultantGroupData.findIds(
        "corporationId",
        selectedCorporation
      );
      nisConsultantIds = consultantData.findWithValuesInSetIds(
        "consultantGroupId",
        consultantGroupIds
      );
    } else {
      return true;
    }

    return nisAssignmentData.someWithValuesInSet(
      ["taskmasterId", taskmasterIds],
      ["nisConsultantId", nisConsultantIds]
    );
  }

  protected override filterRows(
    rows: NisAssignment[],
    radioFilters: BaseTableFilterMode[],
    _yearFilter: number,
    selectedCompetencies?: Competency[] | undefined
  ): NisAssignment[] {
    const corporationFilter = this.corporationDropdown.selectedOption;
    const nisConsultantFilter = this.nisConsultantDropdown.selectedOption;
    const nisConsultantGroupFilter =
      this.nisConsultantGroupDropdown.selectedOption;
    const customerFilter = this.customerDropdown.selectedOption;
    const [periodFilter] = radioFilters;

    const nisAssignmentFilters = TableDataOptions.getNisPeriodFilters(
      periodFilter,
      corporationFilter,
      nisConsultantFilter,
      nisConsultantGroupFilter
    );

    const filters = new FilterBuilder<NisAssignment>(nisAssignmentFilters);

    if (customerFilter != -1) {
      const taskmasterData = container.resolve(TaskmasterData);
      const taskmastersForCustomerFilter = taskmasterData.findIds(
        "customerId",
        customerFilter
      );
      filters.add((a) => taskmastersForCustomerFilter.has(a.taskmasterId));
    }

    if (selectedCompetencies && selectedCompetencies.length > 0) {
      filters.add(this.getSelectedCompetenciesFilter(selectedCompetencies));
    }

    return filters.filter(rows);
  }

  public constructor() {
    const {
      corporationData,
      nisConsultantData,
      nisConsultantGroupData,
      customerData,
    } = store.state;

    super({
      radioFilters: [new PeriodFilter()],
      sorting: {
        Name: { key: "name", column: "name" },
        Start: { func: TableDataOptions.compareStart, column: "startDate" },
        Assignee: {
          func: TableDataOptions.compareFirstNames,
          column: "nisConsultant",
        },
        Customer: { key: "customerName", column: "customer" },
        Service: { key: "nisServiceName", column: "nisService" },
      },
      filterBoxKeys: [
        "name",
        "customer",
        "startDate",
        "endDate",
        "taskmaster",
        "nisConsultant",
        "nisService",
      ],
      hiddenFields: ["invoices"],
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);
    this.nisConsultantGroupDropdown = new TableDataDropdown(
      nisConsultantGroupData,
      undefined,
      this.getDropdownFilterFromRelatedDropdown(
        nisConsultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );
    this.nisConsultantDropdown = new TableDataDropdown(
      nisConsultantData,
      undefined,
      this.getDropdownFilterFromRelatedDropdowns(
        nisConsultantData,
        this.nisConsultantGroupDropdown,
        "nisConsultantGroupId",
        nisConsultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );

    this.customerDropdown = new TableDataDropdown(
      customerData,
      undefined,
      this.customerFilter.bind(this)
    );

    this.dropdownFilters = [
      this.corporationDropdown,
      this.nisConsultantGroupDropdown,
      this.nisConsultantDropdown,
      this.customerDropdown,
    ];
  }
}
