import { Certificate } from "@/models/Certificate";
import { CertificateDisplayable } from "@/models/displayable/CertificateDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class CertificateOptions extends TableDataOptions<
  Certificate,
  CertificateDisplayable
> {
  public constructor() {
    super({
      sorting: { Name: "name" },
      filterBoxKeys: ["nameField"],
    });
  }
}
