import { Taskmaster } from "@/models/Taskmaster";
import { TaskmasterDisplayable } from "@/models/displayable/TaskmasterDisplayable";
import { container, singleton } from "tsyringe";
import { AssignmentData } from "./AssignmentData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { TaskmasterHeaders } from "./headers/TaskmasterHeaders";

@singleton()
export class TaskmasterData extends TableData<
  Taskmaster,
  TaskmasterDisplayable
> {
  constructor() {
    super(
      "Contacts",
      new TaskmasterHeaders(),
      "Taskmaster",
      new Taskmaster(),
      "Contact",
      [
        new TablePointer(
          container.resolve(AssignmentData),
          "taskmasterId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This taskmaster may not be deleted! " +
      "It still has assignment(s) under it! " +
      "Reassign or remove each of its remaining assignments";
  }
}
