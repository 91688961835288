import { ValidateNested } from "class-validator";
import { Assignment } from "../Assignment";
import { AssignmentUrl } from "../AssignmentUrl";
import { AssignmentDisplayable } from "./AssignmentDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";

import { AssignmentData } from "@/store/data/AssignmentData";
import { container } from "tsyringe";
import { TextField } from "./fields/TextField";
import { DropdownOption } from "./fields/util/DropdownOption";

export class AssignmentUrlDisplayable extends DisplayableBase<AssignmentUrl> {
  @ValidateNested()
  assignment: DropdownField<Assignment, AssignmentDisplayable>;

  name: TextField;

  url: TextField;

  toModel(assignmentUrl: AssignmentUrl): void {
    assignmentUrl.assignmentId = this.assignment.selectedOption.id;
    assignmentUrl.assignmentName = this.assignment.selectedOption.label;
    assignmentUrl.name = this.name.value;
    assignmentUrl.url = this.url.value;
  }

  constructor(model: AssignmentUrl) {
    const assignmentData = container.resolve(AssignmentData);
    super();
    this.assignment = new DropdownField(
      "Assignment",
      model.assignmentName,
      assignmentData,
      new DropdownOption(model.assignmentId, model.assignmentName)
    );
    this.url = new TextField("Url", model.url);
    this.name = new TextField("Name", model.name);
  }
}
