import { IsInt, Max, Min, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { NumberError } from "./error/NumberError";

export class PercentageField extends DisplayableField<string, number> {
  static MIN = 1;
  static MAX = 100;

  @IsInt({
    message: (args: ValidationArguments) =>
      (args.object as PercentageField)._error.valueError(args.value),
  })
  @Min(PercentageField.MIN, {
    message: (args: ValidationArguments) =>
      (args.object as PercentageField)._error.minValueError(
        args.constraints[0],
        args.value
      ),
  })
  @Max(PercentageField.MAX, {
    message: (args: ValidationArguments) =>
      (args.object as PercentageField)._error.maxValueError(
        args.constraints[0],
        args.value
      ),
  })
  protected _value: number;

  get value(): string {
    return this._value.toString();
  }

  set value(input: string) {
    this._value = parseInt(input);
  }

  modelValue(): number {
    return this._value;
  }

  constructor(header: string, data: number, options?: DisplayableFieldOptions) {
    super(header, new NumberError(header), FieldType.PERCENTAGE, options);
    this._value = data;
    this._suffix ??= "%";
  }
}

export class PercentageField_MIN10 extends DisplayableField<string, number> {
  static MIN = 10;
  static MAX = 100;

  @IsInt({
    message: (args: ValidationArguments) =>
      (args.object as PercentageField_MIN10)._error.valueError(args.value),
  })
  @Min(PercentageField_MIN10.MIN, {
    message: (args: ValidationArguments) =>
      (args.object as PercentageField_MIN10)._error.minValueError(
        args.constraints[0],
        args.value
      ),
  })
  @Max(PercentageField_MIN10.MAX, {
    message: (args: ValidationArguments) =>
      (args.object as PercentageField_MIN10)._error.maxValueError(
        args.constraints[0],
        args.value
      ),
  })
  protected _value: number;

  get value(): string {
    return this._value.toString();
  }

  set value(input: string) {
    this._value = parseInt(input);
  }

  modelValue(): number {
    return this._value;
  }

  constructor(header: string, data: number, options?: DisplayableFieldOptions) {
    super(header, new NumberError(header), FieldType.PERCENTAGE_MIN10, options);
    this._value = data;
    this._suffix ??= "%";
  }
}
