import { TableHeaders } from "@/interfaces/TableHeaders";
import { ConsultantCertificateDisplayable } from "@/models/displayable/ConsultantCertificateDisplayable";

export class ConsultantCertificateHeaders
  implements TableHeaders<ConsultantCertificateDisplayable>
{
  public readonly consultant = "Consultant";
  public readonly certificate = "Certificate";
  public readonly date = "Date";
}
