import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";

export class RequiredCompetenciesExtension<
  T extends Model<D>,
  D extends Displayable<T>
> {
  constructor(
    public readonly getOwnerId: (value: T) => number,
    public readonly canHaveCompetencies: (value: T) => boolean = () => true
  ) {}
}
