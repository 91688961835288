<template>
  <p v-if="state.isLoading" class="text-loading">Calculating...</p>
  <StatisticsDiagram
    v-else
    :can-show-all-corporations="true"
    :options="undefined"
    :yearly-view="YearlyViewBehavior.AccumulateMonths"
    :table-settings="{
      accumulatedTotal: true,
      showMonthlyTotal: false,
      fileName: 'Number_of_Certificates',
      appendUnit: false,
      columns: {
        Certificates: 'noOfCertificates',
      },
    }"
    :value-fields="['noOfCertificates']"
    :colors="{
      noOfCertificates: {
        color: DiagramColors.CONSULTANTS,
        description: 'CERTIFICATES',
        textBright: true,
      },
    }"
    :can-update="state.canUpdate"
    :calculate-month="calculateNumberOfCertificates"
    suffix=" certificates"
    names-field="certificateNames"
    :show-unit-in-bar-tooltip="true"
    :start-date-month-offset="-11"
    :end-date-month-offset="0"
    title="Number of Certificates"
    clarification="Each bar represent the number of certificates taken during that period."
    :hideFutureMonthsClarification="true"
  >
  </StatisticsDiagram>
</template>

<script setup lang="ts">
import { DiagramColors } from "@/coveragePeriodReport/enum/DiagramColors";
import StatisticsDiagram from "@/diagram/StatisticsDiagram.vue";
import { calculateNumberOfCertificates } from "@/diagram/functions/calculateNumberOfCertificates";
import { YearlyViewBehavior } from "@/diagram/types/YearlyViewBehavior";
import store from "@/store";
import { EventType } from "@/util/EventEmitter";
import { reactive } from "vue";
const state = reactive({
  isLoading: true,
  data: [],
  canUpdate: false,
});

store.state.events.subscribeOrRunIfDispatched(
  EventType.FinishedLoadingTableData,
  () => (state.isLoading = false)
);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
</style>
