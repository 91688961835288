export class HtmlSummaryBuilder {
  private summaryHtml: string;

  constructor() {
    this.summaryHtml = "";
  }

  addHeader(name: string): HtmlSummaryBuilder {
    this.summaryHtml += `<u><strong>${name}</strong></u>`;

    return this;
  }

  addField(label: string, data: string): HtmlSummaryBuilder {
    this.summaryHtml += `<nobr style="align-self: flex-start"><strong>${label}:</strong> ${data} </nobr>`;

    return this;
  }

  addRow(data: string): HtmlSummaryBuilder {
    this.summaryHtml += `${data}`;

    return this;
  }

  toString(): string {
    return `<div style="display: flex; flex-direction: column">${this.summaryHtml}</div>`;
  }
}
