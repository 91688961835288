import { TableHeaders } from "@/interfaces/TableHeaders";
import { LeaderDisplayable } from "@/models/displayable/LeaderDisplayable";

export class LeaderHeaders implements TableHeaders<LeaderDisplayable> {
  public readonly name = "Name";
  public readonly corporation = "Company";
  public readonly location = "Location";
  public readonly employmentStart = "Employment start";
  public readonly employmentEnd = "Employment end";
}
