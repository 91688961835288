import { ConsultantGroupDisplayable } from "./displayable/ConsultantGroupDisplayable";
import { ModelBase } from "./ModelBase";

export class ConsultantGroup extends ModelBase<ConsultantGroupDisplayable> {
  consultantGroupId: number;
  name: string;
  corporationId: number;
  locationId: number;
  firstLeaderId: number;
  secondLeaderId: number | null;

  corporationName: string;
  locationName: string;
  firstLeaderName: string;
  secondLeaderName: string;

  constructor() {
    super();

    this.consultantGroupId = 0;
    this.name = "";
    this.corporationId = 0;
    this.locationId = 0;
    this.firstLeaderId = 0;
    this.secondLeaderId = 0;

    this.corporationName = "";
    this.locationName = "";
    this.firstLeaderName = "";
    this.secondLeaderName = "";
  }

  realize(partialModel: ConsultantGroup): ConsultantGroup {
    const newModel = new ConsultantGroup();

    newModel.consultantGroupId = partialModel.consultantGroupId;
    newModel.name = partialModel.name;
    newModel.corporationId = partialModel.corporationId;
    newModel.locationId = partialModel.locationId;
    newModel.firstLeaderId = partialModel.firstLeaderId;
    newModel.secondLeaderId = partialModel.secondLeaderId;

    newModel.corporationName = partialModel.corporationName;
    newModel.locationName = partialModel.locationName;
    newModel.firstLeaderName = partialModel.firstLeaderName;
    newModel.secondLeaderName = partialModel.secondLeaderName;

    return newModel;
  }

  getId(): number {
    return this.consultantGroupId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): ConsultantGroupDisplayable {
    return new ConsultantGroupDisplayable(this);
  }
}
