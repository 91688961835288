<template>
  <div
    v-for="opt in field.selectionOptions()"
    :key="opt"
    class="col-auto form-check form-check-inline"
  >
    <label class="form-selection-check-label">
      <input
        type="radio"
        :id="opt.toLocaleLowerCase().replace(/[^a-z0-9]/g, '-')"
        :cy-data="opt"
        class="radio-button form-check-input"
        name="conditional"
        :value="opt"
        v-model="thisField.value"
        :class="hasError && 'error'"
        v-on:change="signalInputUpdate()"
      />
      {{ opt }}
    </label>
  </div>
</template>

<script setup lang="ts" generic="V">
import { SelectionField } from "@/models/displayable/fields/SelectionField";
import { computed } from "vue";
const props = defineProps<{
  field: SelectionField<V>;
  hasError?: boolean;
}>();

const emit = defineEmits(["input-updated"]);
const signalInputUpdate = () => emit("input-updated");
const thisField = computed(() => props.field);
</script>

<style scoped lang="scss">
@import "../../styles/global.scss";
.error {
  @include error;
}
</style>
