import FileFolder from "@/components/enum/FileFolder";
import { Assignment } from "@/models/Assignment";
import { AssignmentDisplayable } from "@/models/displayable/AssignmentDisplayable";
import { ContractPhase } from "@/models/enum/ContractPhase";
import { AssignmentFieldProvider } from "@/models/fieldProvider/AssignmentFieldProvider";
import { dateKey } from "@/types/DateKey";
import { container, singleton } from "tsyringe";
import FileData from "./FileData";
import { TableData } from "./TableData";
import { AssignmentHeaders } from "./headers/AssignmentHeaders";

@singleton()
export class AssignmentData extends TableData<
  Assignment,
  AssignmentDisplayable
> {
  constructor() {
    super(
      "Assignments",
      new AssignmentHeaders(),
      "Assignment",
      new Assignment(),
      undefined,
      undefined,
      {
        fieldProvider: new AssignmentFieldProvider(),
        hasFiles: true,
      }
    );
  }

  extend(clickedRowId: number): void {
    this._modifying = true;
    const instance = this.tableRows.find((row) => row.getId() == clickedRowId);

    //In the long term: Implement central app error handling (try,catch) and throw here?
    if (!instance) {
      this._modifying = false;
      console.log("Could not find clicked row to be extended");
      return;
    }

    const extension = this.createExtension(instance);
    this._activeRow = extension;

    this.action = this.postRequest;
  }

  private createExtension(instance: Assignment): Assignment {
    const extended = this.sourceInstance.realize(instance);
    extended.assignmentId = 0;
    extended.phase = ContractPhase.PROBABLE_ASSIGNMENT;
    extended.extension++;
    this.changeDates(extended);

    return extended;
  }

  private changeDates(instance: Assignment): void {
    const endDate = instance.getEndDate();
    const redate = new Date(endDate);
    redate.setDate(endDate.getDate() + 1);
    instance.startDate = dateKey(redate);
    redate.setMonth(redate.getMonth() + 6);
    redate.setDate(redate.getDate() - 1);
    instance.endDate = dateKey(redate);
  }

  public getActive(rows = this.rows): Assignment[] {
    return rows.filter((row) => {
      return (
        new Date(row.startDate).getTime() < Date.now() &&
        (row.endDate ? Date.now() < new Date(row.endDate).getTime() : true)
      );
    });
  }

  public getFuture(rows = this.rows): Assignment[] {
    return rows.filter((row) => {
      return row.endDate ? Date.now() < new Date(row.endDate).getTime() : true;
    });
  }

  public async postRequest(assignment: Assignment): Promise<void> {
    await super.postRequest(assignment).then(() => {
      const assignmentId = this.fetchedInstance.getId();
      container
        .resolve(FileData)
        .postFiles(FileFolder.Assignment, assignmentId);
    });
  }
}
