import { NisServiceData } from "@/store/data/NisServiceData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import NisService from "../NisService";
import { DisplayableBase } from "./DisplayableBase";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class NisServiceDisplayable extends DisplayableBase<NisService> {
  @ValidateNested()
  @FieldUniqueText<NisService, NisServiceDisplayable>()
  name: UniqueTextField<NisService, NisServiceDisplayable>;

  constructor(nisService: NisService) {
    super();
    const nisServiceData = container.resolve(NisServiceData);
    const { headers } = nisServiceData;
    this.name = new UniqueTextField(
      headers.name,
      nisService.name,
      nisServiceData
    );
  }

  toModel(nisService: NisService): void {
    nisService.name = this.name.modelValue();
  }
}
