import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { UrlType } from "./enum/UrlType";
import { TextError } from "./error/TextError";

export class FilesUrlField extends DisplayableField<null, string> {
  protected _value = null;
  protected _id: number;
  protected _urlType: UrlType;

  get value(): null {
    return null;
  }

  set value(input: null) {
    this._value = input;
  }

  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  get urlType(): UrlType {
    return this._urlType;
  }
  set urlType(value: UrlType) {
    this._urlType = value;
  }

  modelValue(): string {
    return "";
  }

  constructor(
    header: string,
    id: number,
    urlType: UrlType,
    options?: DisplayableFieldOptions
  ) {
    super(header, new TextError(header), FieldType.URLFILES, options);
    this._id = id;
    this._urlType = urlType;
    this._type = FieldType.URLFILES;
  }
}
