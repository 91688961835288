import { TableHeaders } from "@/interfaces/TableHeaders";
import { ConsultantUrlDisplayable } from "@/models/displayable/ConsultantUrlDisplayable";

export class ConsultantUrlHeaders
  implements TableHeaders<ConsultantUrlDisplayable>
{
  public readonly consultant = "Consultant";
  public readonly name = "Title";
  public readonly url = "Url";
}
