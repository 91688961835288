import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { InvoiceDisplayable } from "./displayable/InvoiceDisplayable";

export default class Invoice extends ModelBase<InvoiceDisplayable> {
  invoiceId: number;
  nisAssignmentId: number;
  nisAssignmentName: string;
  customerName: string;
  amount: number;
  currencyId: number;
  currencyCode: string;
  date: DateKey;
  description: string;

  constructor() {
    super();
    this.invoiceId = 0;
    this.nisAssignmentId = 0;
    this.nisAssignmentName = "";
    this.customerName = "";
    this.amount = 0;
    this.currencyId = 0;
    this.currencyCode = "";
    this.date = "" as DateKey;
    this.description = "";
  }

  realize(partialModel: Invoice): Invoice {
    const newModel = new Invoice();
    newModel.invoiceId = partialModel.invoiceId;
    newModel.nisAssignmentId = partialModel.nisAssignmentId;
    newModel.nisAssignmentName = partialModel.nisAssignmentName;
    newModel.customerName = partialModel.customerName;
    newModel.amount = partialModel.amount;
    newModel.currencyId = partialModel.currencyId;
    newModel.currencyCode = partialModel.currencyCode;
    newModel.date = partialModel.date;
    newModel.description = partialModel.description;
    return newModel;
  }

  getId(): number {
    return this.invoiceId;
  }

  getName(): string {
    return `invoice ${this.date} for ${this.nisAssignmentName}`;
  }

  getDisplayable(): InvoiceDisplayable {
    return new InvoiceDisplayable(this);
  }
}
