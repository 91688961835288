<template>
  <base-modal
    :title="title"
    :modalIcon="modalIcon"
    :aborttitle="'OK'"
    :abort="unsetId"
    :accept="deleteEntry"
    :danger="true"
    class="generic-profile-modal"
    :class="{ 'fade-out': canFadeOut.value }"
  >
    <BaseDetails
      :title="profile.value.getName()"
      :tableData="data"
      :id="entryId"
      :accept="deleteEntry"
      :excludedFields="excludedFields"
      :extendable="extendable"
      detailIcon="briefcase"
      v-on:edit="emit('data-update')"
      v-on:edit-secondary="emit('data-update')"
      v-on:extend="emit('data-update'), unsetId"
      :can-edit="isAuthorizedToEdit || isOwnProfile || hasAuthorizationOverride"
      :user-id="getUserId()"
      :secondary-data="secondaryData"
      :secondary-id="getSecondaryId()"
      :included-secondary-fields="includedSecondaryFields"
    />
    <FileContainer
      v-if="fileFolder"
      :id="getFileId()"
      :folder="fileFolder"
      :can-edit="isAuthorizedToEdit || isOwnProfile"
      v-on:file-upload="emit('files-update')"
      v-on:file-delete="emit('files-update')"
    />
    <UrlContainer
      v-if="urlType != undefined"
      :id="props.id"
      :can-edit="isAuthorizedToEdit || isOwnProfile"
      :dataType="urlType"
      v-on:url-upload="emit('files-update')"
      v-on:url-delete="emit('files-update')"
    />
  </base-modal>
</template>

<script
  setup
  lang="ts"
  generic="M extends Model<D>, D extends Displayable<M> & IterableFields<D, IDisplayableField>, M2 extends Model<D2>, D2 extends Displayable<M2> & IterableFields<D2, IDisplayableField>"
>
import user from "@/auth/user";
import BaseDetails from "@/components/BaseDetails.vue";
import FileContainer from "@/components/FileContainer.vue";
import UrlContainer from "@/components/UrlContainer.vue";
import FileFolder from "@/components/enum/FileFolder";
import { Displayable } from "@/interfaces/Displayable";
import { Model } from "@/interfaces/Model";
import { Assignment } from "@/models/Assignment";
import { IDisplayableField } from "@/models/displayable/fields/DisplayableField";
import { UrlType } from "@/models/displayable/fields/enum/UrlType";
import { ContractPhase } from "@/models/enum/ContractPhase";
import store, { VeaState } from "@/store";
import { AuthorizationEntry } from "@/store/AuthorizationManager";
import { AssignmentData } from "@/store/data/AssignmentData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { TableData } from "@/store/data/TableData";
import { IterableFields } from "@/types/IterableFields";
import { KeyTo } from "@/types/KeyTo";
import { getProp, setProp } from "@/types/getProp";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { computed, reactive } from "vue";
import BaseModal from "./BaseModal.vue";

const props = defineProps<{
  id: number;
  data: TableData<M, D>;
  excludedFields: KeyTo<D, IDisplayableField>[];
  authorizationEntry: AuthorizationEntry;
  idKey: KeyTo<VeaState, number | null>;
  modalIcon: IconName;
  title: string;
  fileFolder?: FileFolder;
  fileKeyField?: KeyTo<M, number>;
  userIdKeyField?: KeyTo<M, number>;
  secondaryData?: TableData<M2, D2>;
  secondaryDataIdField?: KeyTo<M, number>;
  includedSecondaryFields?: KeyTo<D2, IDisplayableField>[];
  urlType?: UrlType;
  overrideAuthorization?: (entry: M) => boolean;
}>();
const emit = defineEmits(["data-update", "files-update"]);

const canFadeOut = reactive({ value: false });

function unsetId(delay = 300) {
  canFadeOut.value = true;
  setTimeout(() => {
    store.state.connectionRowId = null;
    setProp(store.state, props.idKey, null);
  }, delay);
}

const profile = reactive({
  value: props.data.findById(props.id),
});

function deleteEntry() {
  props.data.delete(props.id);
  unsetId();
}

const entryId = computed(() => props.id);
const extendable = computed(
  () =>
    props.data instanceof AssignmentData &&
    (profile.value as Assignment).phase != ContractPhase.INTERNSHIP
);
const isAuthorizedToEdit = computed(() =>
  props.authorizationEntry.isAuthorized()
);

const isOwnProfile = computed(
  () =>
    props.data instanceof ConsultantData &&
    user.state.profile.userId == getUserId()
);

const hasAuthorizationOverride = computed(
  () =>
    props.overrideAuthorization !== undefined &&
    props.overrideAuthorization(profile.value as M)
);

function getFileId(): number {
  return props.fileKeyField
    ? getProp(profile.value, props.fileKeyField)
    : entryId.value;
}

function getUserId(): number | undefined {
  return props.userIdKeyField
    ? getProp(profile.value, props.userIdKeyField)
    : undefined;
}

function getSecondaryId(): number | undefined {
  return props.secondaryDataIdField
    ? getProp(profile.value, props.secondaryDataIdField)
    : undefined;
}
</script>

<style lang="scss">
@import "@/styles/global.scss";

.generic-profile-modal {
  .modal-dialog {
    .modal-body {
      padding-top: 50px;

      .profile {
        @include profile();
      }
    }
  }

  .btn-secondary {
    background-color: $primary-ui-color;
  }
}
</style>
