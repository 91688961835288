import { TableHeaders } from "@/interfaces/TableHeaders";
import { AbsenceDisplayable } from "@/models/displayable/AbsenceDisplayable";

export class AbsenceHeaders implements TableHeaders<AbsenceDisplayable> {
  public readonly consultant = "Consultant";
  public readonly absenceCategory = "Category";
  public readonly coverage = "Extent";
  public readonly startDate = "Start";
  public readonly endDate = "End";
  public readonly approved = "Approved";
}
