import { CorporationData } from "@/store/data/CorporationData";
import { LocationData } from "@/store/data/LocationData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Corporation } from "../Corporation";
import { Location } from "../Location";
import { CorporationDisplayable } from "./CorporationDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DropdownField } from "./fields/DropdownField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class LocationDisplayable extends DisplayableBase<Location> {
  @ValidateNested()
  @FieldUniqueText<Location, LocationDisplayable>()
  name: UniqueTextField<Location, LocationDisplayable>;

  @ValidateNested()
  corporationName: DropdownField<Corporation, CorporationDisplayable>;

  constructor(location: Location) {
    super();
    const locationData = container.resolve(LocationData);
    const corporationData = container.resolve(CorporationData);
    const { headers } = locationData;
    this.name = new UniqueTextField(headers.name, location.name, locationData);

    this.corporationName = new DropdownField(
      headers.corporationName,
      location.corporationName,
      corporationData,
      new DropdownOption(location.corporationId, location.corporationName)
    );
  }

  toModel(location: Location): void {
    location.name = this.name.modelValue();
    location.corporationId = this.corporationName.selectedOption.id;
  }
}
