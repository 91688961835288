export abstract class ReportEntry {
  /** The unique model-ID of this entry */
  internalId: number;
  /** The id of the corresponding consultant */
  consultantId: number;
  /** Defines the report entry's placement in the graph */
  startDate: Date;
  /** Defines the report entry's placement in the graph */
  endDate: Date | null;
  /** Defines the start date as shown, used if the report entry has been split up in two */
  summaryStartDate: Date;
  /** Defines the start date as shown in the summary, used if the report entry has been split up in two */
  summaryEndDate: Date | null;
  private _reportIndex: number;

  constructor(
    internalId: number,
    id: number,
    reportIndex: number,
    startDate: Date,
    endDate: Date | null
  ) {
    this.internalId = internalId;
    this.consultantId = id;
    this.startDate = startDate;
    this.endDate = endDate;
    this.summaryStartDate = startDate;
    this.summaryEndDate = endDate;
    this._reportIndex = reportIndex;
  }

  get reportIndex(): number {
    return this._reportIndex;
  }

  set reportIndex(reportIndex: number) {
    this._reportIndex = reportIndex;
  }

  abstract getColor(): string;
  abstract getTextColor(): string;
  abstract getSummaryHtml(): string;
  abstract getRowText(): string;
  abstract getSummarySize(): number;
}
