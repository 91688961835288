import { ModelBase } from "./ModelBase";
import { ConsultantServiceDisplayable } from "./displayable/ConsultantServiceDisplayable";

export class ConsultantService extends ModelBase<ConsultantServiceDisplayable> {
  consultantServiceId: number;
  serviceName: string;
  consultantId: number;
  serviceId: number;
  consultantName: string;

  constructor() {
    super();
    this.consultantServiceId = 0;
    this.serviceName = "";
    this.consultantId = 0;
    this.serviceId = 0;
    this.consultantName = "";
  }

  realize(partialModel: ConsultantService): ConsultantService {
    const newModel = new ConsultantService();
    newModel.consultantServiceId = partialModel.consultantServiceId;
    newModel.serviceName = partialModel.serviceName;
    newModel.serviceId = partialModel.serviceId;
    newModel.consultantId = partialModel.consultantId;
    newModel.consultantName = partialModel.consultantName;
    return newModel;
  }

  getName(): string {
    return this.serviceName;
  }

  getId(): number {
    return this.consultantServiceId;
  }

  getDisplayable(): ConsultantServiceDisplayable {
    return new ConsultantServiceDisplayable(this);
  }
}
