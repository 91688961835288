<template>
  <div class="wide-container" cy-data="assignments-page">
    <BaseTable
      :data="state.assignmentData"
      :options="options"
      :canExtend="true"
      :grouped="true"
    />
  </div>
</template>

<script setup lang="ts">
import BaseTable from "@/components/BaseTable.vue";
import { VeaState } from "@/store";
import { AssignmentOptions } from "@/store/data/tableDataOptions/AssignmentOptions";
import { useStore } from "vuex";

defineOptions({
  name: "ServicesTable",
});
const state = useStore<VeaState>().state;
const options = new AssignmentOptions();
</script>
