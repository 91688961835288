import { IDisplayable } from "@/interfaces/Displayable";
import { DateKey } from "@/types/DateKey";
import { DateField } from "../DateField";
import { VeaValidate } from "./VeaValidator";

export function FieldDateNotBefore<D extends IDisplayable>(date: DateKey) {
  return VeaValidate<D, DateField>({
    message(field) {
      return field.error.beforeDateError(date);
    },
    validate(field) {
      return date <= field.value;
    },
  });
}
