import { Absence } from "@/models/Absence";
import { AbsenceDisplayable } from "@/models/displayable/AbsenceDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { AbsenceHeaders } from "./headers/AbsenceHeaders";

@singleton()
export class AbsenceData extends TableData<Absence, AbsenceDisplayable> {
  constructor() {
    super("Absences", new AbsenceHeaders(), "Absence", new Absence());
  }

  public getActive(rows = this.rows): Absence[] {
    return rows.filter((row) => {
      return (
        new Date(row.startDate).getTime() < Date.now() &&
        (row.endDate ? Date.now() < new Date(row.endDate).getTime() : true)
      );
    });
  }

  public getFuture(rows = this.rows): Absence[] {
    return rows.filter((row) => {
      return row.endDate ? Date.now() < new Date(row.endDate).getTime() : true;
    });
  }
}
