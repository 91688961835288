<template>
  <div class="col-auto">
    <select
      class="form-select"
      :class="hasError && 'error'"
      :id="label.toLowerCase().replace(' ', '') + '-select'"
      v-model="thisField.selectedOption"
      v-on:change="emitInputUpdate()"
    >
      <option
        v-for="option in thisField.getOptions()"
        :key="option.id"
        :value="option"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts" generic="E extends string">
import { EnumDropdownField } from "@/models/displayable/fields/EnumDropdownField";
import { computed } from "vue";

export interface Props<E extends string> {
  field: EnumDropdownField<E>;
  label?: string;
  hasError?: boolean;
}

const props = withDefaults(defineProps<Props<E>>(), {
  label: "",
  hasError: false,
});

const emit = defineEmits(["input-updated", "button-clicked"]);
function emitInputUpdate() {
  emit("input-updated");
}
function emitButtonClicked() {
  emit("button-clicked");
}

const thisField = computed(() => props.field);

emitInputUpdate;
emitButtonClicked;
</script>
<style scoped lang="scss">
@import "../../styles/global.scss";
.assignment-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.filter-select,
col-auto {
  width: fit-content;
  min-width: 30%;
  margin-bottom: 5px;
}
.error {
  @include error;
}
</style>
