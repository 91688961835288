import { Location } from "@/models/Location";
import { LocationDisplayable } from "@/models/displayable/LocationDisplayable";
import { TableDataOptions } from "../TableDataOptions";

export class LocationOptions extends TableDataOptions<
  Location,
  LocationDisplayable
> {
  public constructor() {
    super({
      filterBoxKeys: ["name"],
    });
  }
}
