import { RoleName } from "@/auth/user";
import { KeyTo } from "@/types/KeyTo";
import { User } from "../User";
import { UserDisplayable } from "../displayable/UserDisplayable";
import { IDisplayableField } from "../displayable/fields/DisplayableField";
import { FieldProvider } from "./FieldProvider";

export class UserFieldProvider extends FieldProvider<User, UserDisplayable> {
  fieldKeysByRole: Record<
    RoleName,
    KeyTo<UserDisplayable, IDisplayableField>[] | null
  > = {
    Admin: null,
    User: ["address", "phoneNr"],
    "Admin Read-Only": ["address", "phoneNr"],
  };
}
