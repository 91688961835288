import { TableHeaders } from "@/interfaces/TableHeaders";
import { NisConsultantGroupDisplayable } from "@/models/displayable/NisConsultantGroupDisplayable";

export class NisConsultantGroupHeaders
  implements TableHeaders<NisConsultantGroupDisplayable>
{
  public readonly name = "Name";
  public readonly corporation = "Company";
  public readonly leader = "Leader";
}
