import { ServiceDisplayable } from "./displayable/ServiceDisplayable";
import { ModelBase } from "./ModelBase";

export default class Service extends ModelBase<ServiceDisplayable> {
  serviceId: number;
  name: string;
  user_id: number;

  constructor() {
    super();
    this.serviceId = 0;
    this.name = "";
    this.user_id = 0;
  }

  realize(partialModel: Service): Service {
    const newModel = new Service();

    newModel.serviceId = partialModel.serviceId;
    newModel.name = partialModel.name;
    newModel.user_id = partialModel.user_id;

    return newModel;
  }

  getId(): number {
    return this.serviceId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): ServiceDisplayable {
    return new ServiceDisplayable(this);
  }
}
