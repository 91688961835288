import { NisConsultantGroup } from "@/models/NisConsultantGroup";
import { NisConsultantGroupDisplayable } from "@/models/displayable/NisConsultantGroupDisplayable";
import { container, singleton } from "tsyringe";
import { NisConsultantData } from "./NisConsultantData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { NisConsultantGroupHeaders } from "./headers/NisConsultantGroupHeaders";

@singleton()
export class NisConsultantGroupData extends TableData<
  NisConsultantGroup,
  NisConsultantGroupDisplayable
> {
  constructor() {
    super(
      "NIS Consultant Groups",
      new NisConsultantGroupHeaders(),
      "NisConsultantGroup",
      new NisConsultantGroup(),
      "NIS Consultant Group",
      [
        new TablePointer(
          container.resolve(NisConsultantData),
          "nisConsultantGroupId",
          DeletePolicy.NeverDelete
        ),
      ]
    );
    this._deleteError =
      "This NIS Consultant Group group may not be deleted! " +
      "It still has NIS Consultant(s) under it! " +
      "Reassign or remove each of its remaining NIS Consultants";
  }
}
