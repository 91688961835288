import { AbsenceCategoryDisplayable } from "./displayable/AbsenceCategoryDisplayable";
import { ModelBase } from "./ModelBase";

export class AbsenceCategory extends ModelBase<AbsenceCategoryDisplayable> {
  absenceCategoryId: number;
  name: string;
  requiredRequest: boolean;

  constructor() {
    super();

    this.absenceCategoryId = 0;
    this.name = "";
    this.requiredRequest = false;
  }

  realize(partialModel: AbsenceCategory): AbsenceCategory {
    const newModel = new AbsenceCategory();

    newModel.absenceCategoryId = partialModel.absenceCategoryId;
    newModel.name = partialModel.name;
    newModel.requiredRequest = partialModel.requiredRequest;

    return newModel;
  }

  getId(): number {
    return this.absenceCategoryId;
  }

  getName(): string {
    return this.name;
  }

  getDisplayable(): AbsenceCategoryDisplayable {
    return new AbsenceCategoryDisplayable(this);
  }
}
