import { ConsultantCertificate } from "@/models/ConsultantCertificate";
import { ConsultantCertificateDisplayable } from "@/models/displayable/ConsultantCertificateDisplayable";
import { singleton } from "tsyringe";
import { TableData } from "./TableData";
import { ConsultantCertificateHeaders } from "./headers/ConsultantCertificateHeaders";

@singleton()
export class ConsultantCertificateData extends TableData<
  ConsultantCertificate,
  ConsultantCertificateDisplayable
> {
  constructor() {
    super(
      "Consultant Certificates",
      new ConsultantCertificateHeaders(),
      "ConsultantCertificate",
      new ConsultantCertificate(),
      "Consultant Certificate"
    );
    this._deleteError = "This certificate may not be deleted!";
  }
}
