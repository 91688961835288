<template>
  <div>
    <TheCoveragePeriodReport :data="state.reportData" />
  </div>
</template>

<script setup lang="ts">
import TheCoveragePeriodReport from "@/components/TheCoveragePeriodReport.vue";
import store from "@/store";
import { onBeforeRouteLeave } from "vue-router";

onBeforeRouteLeave(() => {
  store.state.browserData.shouldFetchFilters = true;
});
/* Exposing the application state (and all of its table data objects) to the component. */
const state = store.state;
</script>
