import FileFolder from "@/components/enum/FileFolder";
import { User } from "@/models/User";
import { UserDisplayable } from "@/models/displayable/UserDisplayable";
import { UserFieldProvider } from "@/models/fieldProvider/UserFieldProvider";
import { container, singleton } from "tsyringe";
import { ConsultantData } from "./ConsultantData";
import FileData from "./FileData";
import { LeaderData } from "./LeaderData";
import { TableData } from "./TableData";
import { DeletePolicy, TablePointer } from "./TableDataRelation";
import { UserHeaders } from "./headers/UserHeaders";

@singleton()
export class UserData extends TableData<User, UserDisplayable> {
  private _isAboutToSendActivationEmail: boolean;

  constructor() {
    super(
      "Users",
      new UserHeaders(),
      "User",
      new User(),
      undefined,
      [
        new TablePointer(
          container.resolve(LeaderData),
          "userId",
          DeletePolicy.NeverDelete
        ),
        new TablePointer(
          container.resolve(ConsultantData),
          "userId",
          DeletePolicy.NeverDelete
        ),
      ],
      {
        fieldProvider: new UserFieldProvider(),
      }
    );
    this._deleteError =
      "This user may not be deleted! " +
      "It still has a consultant or leader using it! " +
      "Remove the consultant or leader";
    this._isAboutToSendActivationEmail = false;
  }

  public async postRequest(user: User): Promise<void> {
    super.postRequest(user).then(() => {
      const userId = this.fetchedInstance.getId();

      container.resolve(FileData).postFiles(FileFolder.Profile, userId);
    });
  }

  get isAboutToSendActivationEmail(): boolean {
    return this._isAboutToSendActivationEmail;
  }

  public setToActivate(userId: number): void {
    this._isAboutToSendActivationEmail = true;
    this._activeRow = this.findById(userId);
  }

  public resetToDefault(): void {
    this._isAboutToSendActivationEmail = false;
    this._activeRow = null;
  }

  get userToActivate(): User {
    return this.activeRow ?? new User();
  }

  public async sendActivationEmail(): Promise<void> {
    const user = this.activeRow;
    if (!user) return;
    if (user.active) {
      user.active = false;
      await super.putRequest(user);
    }
    user.active = true;
    await super.putRequest(user);
    this.resetToDefault();
  }
}
