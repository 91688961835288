import { injectable } from "tsyringe";
import MarkDownLink from "./MarkDownLink";
import MarkDownMark from "./MarkDownMark";
import MarkDownPart from "./MarkDownPart";
import MarkDownPatterns from "./MarkDownPatterns";

@injectable()
export default class MarkDownParser {
  constructor(private patterns = MarkDownPatterns) {}

  parse = (message: string) => {
    const splitMessage = message.split("\n");

    const splitMessageWithMatchedPortions = this.matchPortions(splitMessage);

    const parsedMarkDownParts = splitMessageWithMatchedPortions.map(
      this.parseMarkDownParts
    );

    return parsedMarkDownParts;
  };

  private matchPortions = (splitMessage: string[]) => {
    const { ...relevantPatterns } = this.patterns;

    const joinedPatterns = Object.values(relevantPatterns)
      .map((pattern) => pattern.source)
      .join("|");
    const markPatterns = new RegExp(joinedPatterns, "g");
    const output = splitMessage.map(
      (portion) => portion.match(markPatterns) ?? [portion]
    );

    return output;
  };

  private parseMarkDownParts = (portion: string[]): MarkDownPart[] => {
    const { heading, emphasis, bold, link, bullet } = this.patterns;
    const mark = MarkDownMark;

    const output = portion.map<MarkDownPart>((part) => {
      return heading.test(part)
        ? {
            mark: mark.Heading,
            content: part.replace(mark.Heading, "").trim(),
          }
        : bold.test(part)
        ? {
            mark: mark.Bold,
            content: part.replaceAll(mark.Bold, "").trim(),
          }
        : emphasis.test(part)
        ? {
            mark: mark.Italic,
            content: part.replaceAll(mark.Italic, "").trim(),
          }
        : bullet.test(part)
        ? {
            mark: mark.Bullet,
            content: part.replace(mark.Bullet, "").trim(),
          }
        : link.test(part)
        ? {
            mark: mark.Link,
            content: this.getMarkDownLink(part),
          }
        : {
            mark: mark.None,
            content: part,
          };
    });

    return output;
  };

  private getMarkDownLink(part: string): string | MarkDownLink {
    const { inParens, inBrackets } = this.patterns;

    const rawUrl = part.match(inParens)?.join();
    if (!rawUrl) {
      return "";
    }
    const properUrl = rawUrl.startsWith("https://")
      ? rawUrl
      : `https://${rawUrl}`;

    const title = part.match(inBrackets)?.join();

    if (!title) {
      return "";
    }

    return {
      title,
      url: properUrl,
    };
  }

  isHeading = (content: string) => content.startsWith(MarkDownMark.Heading);
  isBullet = (content: string) => content.startsWith(MarkDownMark.Bullet);
  isEmphasis = (content: string) => content.startsWith(MarkDownMark.Italic);
  isBold = (content: string) => content.startsWith(MarkDownMark.Bold);
  isLink = (mark: MarkDownMark) => mark === MarkDownMark.Link;
}
