<template>
  <div class="color-map-wrapper">
    <div class="color-map" v-if="isOpen">
      <div v-for="color in props.content" :key="color.description">
        <span class="color-map-rect" :style="{ background: color.color }">
          <span
            class="color-label"
            :class="{ 'text-bright': color.textBright }"
            >{{ color.description }}</span
          >
        </span>
      </div>
    </div>
    <button
      class="map-toggler"
      title="Color map"
      :style="{ background: `linear-gradient(${backgroundColors})` }"
      @click="isOpen = !isOpen"
    >
      <div class="toggler-state">{{ isOpen ? "&times;" : "i" }}</div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { MappedColor } from "@/types/MappedColor";
import { ref } from "vue";
const props = withDefaults(
  defineProps<{
    content: MappedColor[];
    brightTextColor?: "white" | "darkgray";
    fontSize?: `${number}px`;
    startOpen?: boolean;
  }>(),
  { brightTextColor: "white", fontSize: "12px", startOpen: false }
);
const isOpen = ref(props.startOpen);
const backgroundColors = props.content.map((c) => c.color).join(", ");
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.offcanvas-backdrop .color-map-wrapper {
  transform: translateX(-17px);
}

.color-map-wrapper {
  z-index: 1000;
  padding: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  bottom: 50vh;
  right: 19vw;
  transition: right 300ms ease, bottom 300ms ease;

  @media only screen and (max-width: 1600px) {
    right: 10vw;
    bottom: 20vw;
  }

  @media only screen and (max-height: 800px) {
    bottom: 8vw;
  }

  .color-map {
    padding: 2px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .map-toggler {
    position: absolute;
    bottom: -8px;
    right: -10px;
    border: 1px solid $color-black;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 2px;

    .toggler-state {
      line-height: 1;
      width: 1rem;
      margin: auto;
      border-radius: 50%;
      background: $color-white;
      font-weight: bold;
      font-family: Times, serif;

      &:hover,
      &:focus {
        background: $color-lightgrey;
      }
    }
  }
}

.color-map-rect {
  display: block;
  font-size: v-bind(fontSize);
  border: 1px solid $color-black;
  padding-bottom: 2px;

  .color-label {
    padding: 0 5px;
  }

  color: black;
  .text-bright {
    color: v-bind(brightTextColor);
  }
}
</style>
