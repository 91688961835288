import { Assignment } from "@/models/Assignment";
import { AssignmentCompetency } from "@/models/AssignmentCompetency";
import { Competency } from "@/models/Competency";
import { AssignmentCompetencyData } from "@/store/data/AssignmentCompetencyData";
import { AssignmentCompetencyDisplayable } from "../../AssignmentCompetencyDisplayable";
import { AssignmentDisplayable } from "../../AssignmentDisplayable";
import { CompetencyDisplayable } from "../../CompetencyDisplayable";
import { FieldUniqueManyToManyConnection } from "./FieldUniqueManyToManyConnection";

export function FieldUniqueAssignmentCompetency() {
  return FieldUniqueManyToManyConnection<
    Assignment,
    AssignmentCompetency,
    Competency,
    AssignmentDisplayable,
    AssignmentCompetencyDisplayable,
    CompetencyDisplayable
  >(
    "assignmentName",
    "assignmentId",
    "competencyId",
    () => AssignmentCompetencyData
  );
}
