import { IFormEditingContext } from "./FormEditingContext";
import { DropdownSource } from "./FormEditingContextParentKey";

export class FormEditingNavigator {
  private _history = new Array<IFormEditingContext>();

  constructor(public initial: IFormEditingContext) {}

  canBack() {
    return this._history.length > 0;
  }

  add(context: IFormEditingContext) {
    this._history.push(context);
  }

  addOption(
    dropdownKey: string | number | symbol,
    dropdownSource = DropdownSource.Main
  ) {
    const context = this.current.addDropdownData(dropdownKey, dropdownSource);
    this.add(context);
  }

  addOrEditOneToMany(key: string | number | symbol, childId?: number) {
    const context = this.current.addOrEditOneToMany(key, childId);
    this.add(context);
  }

  back() {
    this._history.pop()?.cancel();
  }

  cancel() {
    while (this._history.length > 0) {
      this._history.pop()?.cancel();
    }

    this.initial.cancel();
  }

  public get current() {
    return this._history[this._history.length - 1] ?? this.initial;
  }
}
