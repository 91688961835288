import store from "@/store";
import { MonthKey, dateKey } from "@/types/DateKey";
import { isInSpan, monthAsDateSpan } from "@/types/DateSpan";

export type MonthlyCertificatesCounts = {
  month: Date;
  noOfCertificates: number;
  certificateNames: Set<string>;
};

export function calculateNumberOfCertificates(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number
): MonthlyCertificatesCounts {
  const { consultantCertificateData, consultantGroupData, consultantData } =
    store.state;

  const monthSpan = monthAsDateSpan(month);

  if (corporationId <= 0 && (!consultantGroupId || consultantGroupId <= 0)) {
    const certificates = consultantCertificateData.rows.filter((c) =>
      isInSpan(dateKey(c.date), monthSpan)
    );
    return {
      month: new Date(month),
      noOfCertificates: certificates.length,
      certificateNames: new Set(certificates.map((c) => c.getName()).sort()),
    };
  }

  let consultantIds: Set<number>;
  if (consultantGroupId && consultantGroupId > 0) {
    consultantIds = consultantData.findIds(
      "consultantGroupId",
      consultantGroupId
    );
  } else {
    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      corporationId
    );
    consultantIds = consultantData.findWithValuesInSetIds(
      "consultantGroupId",
      consultantGroupIds
    );
  }

  const certificates = consultantCertificateData
    .findWithValuesInSet("consultantId", consultantIds)
    .filter((c) => isInSpan(dateKey(c.date), monthSpan));

  return {
    month: new Date(month),
    noOfCertificates: certificates.length,
    certificateNames: new Set(certificates.map((c) => c.getName()).sort()),
  };
}
