import { AbsenceCategoryData } from "@/store/data/AbsenceCategoryData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { AbsenceCategory } from "../AbsenceCategory";
import { DisplayableBase } from "./DisplayableBase";
import { ConditionalField } from "./fields/ConditionalField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class AbsenceCategoryDisplayable extends DisplayableBase<AbsenceCategory> {
  @ValidateNested()
  @FieldUniqueText<AbsenceCategory, AbsenceCategoryDisplayable>()
  name: UniqueTextField<AbsenceCategory, AbsenceCategoryDisplayable>;
  @ValidateNested()
  requiredRequest: ConditionalField;

  constructor(category: AbsenceCategory) {
    super();
    const absenceCategoryData = container.resolve(AbsenceCategoryData);
    const { headers } = absenceCategoryData;
    this.name = new UniqueTextField<
      AbsenceCategory,
      AbsenceCategoryDisplayable
    >(headers.name, category.name, absenceCategoryData);
    this.requiredRequest = new ConditionalField(
      headers.requiredRequest,
      category.requiredRequest
    );
  }

  toModel(absenceCategory: AbsenceCategory): void {
    absenceCategory.name = this.name.modelValue();
    absenceCategory.requiredRequest = this.requiredRequest.modelValue();
  }
}
