import { VeaError } from "./VeaError";

export class PhoneError extends VeaError<string> {
  valueError(input: string): string {
    return `${this.messageBase} ${input} is not a valid phone number.
    Phone numbers must start with country code (e.g. +46),
    be valid in the country specified by that code,
    and have no other seperators than -./()[]`;
  }
}
